import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { DatePicker } from '../forms/DatePicker';
import { TextInput } from '../forms/TextInput';
import { SelectInput } from '../forms/SelectInput';
import { epochSecondsToDate } from '../../functions/helpers';
import { SplitPaymentsFilter } from '../../hooks/useGetSplitPayments';
import { useHistory } from 'react-router-dom';

interface FilterPaymentsProps {
  setFilter: (filter: SplitPaymentsFilter) => void;
}

const FilterSplitPayments = ({
  setFilter,
}: FilterPaymentsProps): ReactElement => {
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [ascending, setAscending] = useState<boolean>(false);
  const [startsAfter, setStartsAfter] = useState<string>('');
  const [startTimestamp, setStartTimestamp] = useState<string>('');
  const [endTimestamp, setEndTimestamp] = useState<string>('');
  const [splitPaymentId, setSplitPaymentId] = useState<string>(
    searchParams.get('id') || ''
  );

  const handleApplyFilter = (): void => {
    const filter: SplitPaymentsFilter = {
      ascending,
      pageSize: 25,
      splitPaymentsStartAfter: startsAfter,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      splitPaymentId: splitPaymentId,
    };
    setFilter(filter);
  };

  useEffect(() => {
    handleApplyFilter();
  }, [ascending, startsAfter, startTimestamp, endTimestamp, splitPaymentId]);

  const handleClearFilter = (): void => {
    setAscending(false);
    setStartsAfter('');
    setStartTimestamp('');
    setEndTimestamp('');
    setSplitPaymentId('');
    history.push('/split-payments');
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <DatePicker
        type='START'
        name='Start Date'
        value={parseInt(startTimestamp)}
        notAfter={
          endTimestamp ? epochSecondsToDate(parseInt(endTimestamp)) : new Date()
        }
        label
        setValue={(e): void => {
          if (e) {
            setStartTimestamp(e.toString());
          }
        }}
      />

      <DatePicker
        label
        type='END'
        name='End Date'
        value={parseInt(endTimestamp)}
        notBefore={
          startTimestamp
            ? epochSecondsToDate(parseInt(startTimestamp))
            : undefined
        }
        notAfter={new Date()}
        setValue={(e): void => {
          if (e) {
            setEndTimestamp(e.toString());
          }
        }}
      />
      <TextInput
        name='Split payment id'
        label
        value={splitPaymentId}
        setValue={setSplitPaymentId}
        placeHolder='sp_'
      />
      <h2
        style={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
        }}
      >
        Sort order
      </h2>
      <SelectInput
        name='Sort Order'
        value={ascending ? 'true' : 'false'}
        setValue={(e): void => setAscending(e === 'true')}
        options={[
          { value: 'true', name: 'Ascending' },
          { value: 'false', name: 'Descending' },
        ]}
      />

      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};
export { FilterSplitPayments };
