import * as Sentry from '@sentry/react';
import {
  createContext,
  useContext,
  useState,
  ReactElement,
  useMemo,
  useEffect,
} from 'react';
import { StateShape, LayoutShape, ProfileShape } from '../interfaces/state';
import Auth from '@aws-amplify/auth';
import { Hub, HubCapsule, Logger } from '@aws-amplify/core';

interface PropsShape {
  children: ReactElement;
}

export const logger: Logger = new Logger('console-logger', 'INFO');

const initVal: StateShape = {
  layout: {
    isFooterVisible: true,
    isNavVisible: true,
    showFooterAlert: 'NONE',
    showNavAlert: 'NONE',
    isModalVisible: false,
    showModal: 'NONE',
    isCloseVisible: true,
  },
  setLayout: () => {},
  isSignedIn: localStorage.getItem('isSignedIn') === 'true',
  setIsSignedIn: () => {},
  profile: {
    webHookEvents: null,
    username: null,
    isEmailConfirmed: false,
    isCookiesAccepted: false,
    isTermsAccepted: false,
    isVerified: false,
    isAdmin: false,
    authToken: '',
    webHooks: {
      active: false,
      eventTypes: [],
      webhooks: [],
    },
    group: undefined,
    accountId: '',
    processingModel: undefined,
  },
  setProfile: () => {},
  cognito: () => {},
  cognitoUser: undefined,
  cognitoUserNotConfirmedOnSignIn: false,
  setCognitoUserNotConfirmedOnSignIn: () => {},
};

const GlobalContext = createContext<StateShape>(initVal);

const useGlobalState = (): StateShape => {
  return useContext(GlobalContext);
};

const GlobalState = ({ children }: PropsShape): ReactElement => {
  const [layout, setLayout] = useState<LayoutShape>(initVal.layout);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(initVal.isSignedIn);
  const [profile, setProfile] = useState<ProfileShape>(initVal.profile);
  const [cognitoUser, setCognitoUser] = useState<any>({});
  const [cognitoError, setCognitoError] = useState<string>('');
  const [cognitoUserNotConfirmedOnSignIn, setCognitoUserNotConfirmedOnSignIn] =
    useState<boolean>(false);

  useEffect(() => {
    const profileString = localStorage.getItem('profile');
    if (profileString) {
      const profileObj = JSON.parse(profileString);
      setProfile(profileObj);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('isSignedIn', `${isSignedIn}`);
  }, [isSignedIn]);

  useEffect(() => {
    localStorage.setItem('profile', JSON.stringify(profile));
  }, [profile]);

  useEffect(() => {
    if (!isSignedIn) {
      setProfile(initVal.profile);
      setCognitoUser(initVal.cognitoUser);
    }
  }, [isSignedIn]);

  useEffect(() => {
    Hub.listen('auth', cognitoEventListener);
  }, []);

  const cognitoEventListener = async (data: HubCapsule): Promise<void> => {
    switch (data.payload.event) {
      case 'autoSignIn':
        logger.info('user auto-signed in');
        await handleSignIn(data.payload.data);
        break;
      case 'signIn':
        logger.info('user signed in');
        break;
      case 'signOut':
        logger.info('user signed out');
        break;
      case 'tokenRefresh_failure':
        logger.error('token refresh failed');
        await cognito.signOut();
        location.href = '/';
        break;
      default:
        break;
    }
  };

  const handleCognitoError = (error: any): void => {
    Sentry.captureException(error);
    if (error.code === 'UserNotConfirmedException') {
      setCognitoUserNotConfirmedOnSignIn(true);
    } else if (error.code === 'UserNotFoundException') {
      setCognitoError('User not found');
    } else {
      setCognitoError(error.message);
    }
  };

  const auth = useMemo(() => {
    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      },
    });
    return Auth;
  }, []);

  useEffect(() => {
    cognito.update();
  }, []);

  const handleSignIn = async (cognitoUser: any): Promise<void> => {
    const authToken = cognitoUser.signInUserSession.idToken.jwtToken;
    const session = await auth.currentSession();
    const cognitoGroupsKey = 'cognito:groups';
    const idToken = session && session.getIdToken().decodePayload();
    const group =
      idToken && idToken[cognitoGroupsKey]
        ? idToken[cognitoGroupsKey][0].toUpperCase()
        : '';
    const accountId = cognitoUser.attributes['custom:account_id'];
    const processingModel = idToken['custom:processing_model'];
    setCognitoUser({ cognitoUser, authToken });
    setProfile((prevState) => ({
      ...prevState,
      authToken,
      group,
      accountId,
      processingModel,
    }));
  };

  const cognito = {
    user: cognitoUser,
    error: cognitoError,
    update: async (): Promise<any> => {
      try {
        const newUser = await auth.currentAuthenticatedUser();
        const session = await auth.currentSession();
        const authToken = session.getIdToken().getJwtToken();
        setCognitoUser({ newUser, authToken });
        setProfile((prevState) => ({
          ...prevState,
          authToken,
        }));
        return authToken;
      } catch (error: any) {
        setCognitoError(error.message);
      }
    },

    join: async (
      username: string,
      password: string,
      email: string,
      clientMetadata: any,
      phoneNumber?: string
    ): Promise<any> => {
      setCognitoError('');
      try {
        const newUser = await auth.signUp({
          username,
          password,
          clientMetadata,
          attributes: {
            email,
            phone_number: phoneNumber,
          },
          autoSignIn: {
            enabled: true,
          },
        });
        if (newUser) return true;
      } catch (error: any) {
        handleCognitoError(error);
      }
    },

    signOut: async (): Promise<void> => {
      const pulseShown = localStorage.getItem('pulseShown');
      const amexCapabilityViewed = localStorage.getItem('amexCapabilityViewed');
      localStorage.clear();
      if (pulseShown) {
        localStorage.setItem('pulseShown', pulseShown);
      }
      if (amexCapabilityViewed) {
        localStorage.setItem('amexCapabilityViewed', amexCapabilityViewed);
      }
      auth.signOut();
      setIsSignedIn(false);
    },

    resend: async (userName: string): Promise<any> => {
      setCognitoError('');
      try {
        return await auth.resendSignUp(userName);
      } catch (error: any) {
        handleCognitoError(error);
      }
    },

    changePassword: async (
      oldPassword: string,
      newPassword: string
    ): Promise<any> => {
      setCognitoError('');
      try {
        return await auth.currentAuthenticatedUser().then(async (user) => {
          return await auth.changePassword(user, oldPassword, newPassword);
        });
      } catch (error: any) {
        handleCognitoError(error);
      }
    },

    signIn: async (
      userName: string,
      password: string,
      metadata?: {
        [key: string]: any;
      }
    ): Promise<any> => {
      setCognitoError('');
      try {
        const cognitoUser = await auth.signIn(userName, password, metadata);
        await handleSignIn(cognitoUser);
        if (cognitoUser) return true;
      } catch (error: any) {
        handleCognitoError(error);
      }
    },
    confirm: async (code: string, userName: string): Promise<any> => {
      setCognitoError('');
      try {
        return await auth.confirmSignUp(code, userName);
      } catch (error: any) {
        handleCognitoError(error);
      }
    },
    forgot: async (username: string): Promise<any> => {
      try {
        const result = await auth.forgotPassword(username);
        if (result) {
          setCognitoError('');
        }
        return result;
      } catch (error: any) {
        handleCognitoError(error);
      }
    },
    setNewPassword: async (
      username: string,
      code: string,
      password: string
    ): Promise<any> => {
      try {
        const result = await auth.forgotPasswordSubmit(
          username,
          code,
          password
        );
        if (result) {
          setCognitoError('');
        }
        return result;
      } catch (error: any) {
        handleCognitoError(error);
      }
    },
  };

  const value = {
    layout,
    setLayout,
    isSignedIn,
    setIsSignedIn,
    profile,
    setProfile,
    cognito,
    cognitoUser,
    cognitoUserNotConfirmedOnSignIn,
    setCognitoUserNotConfirmedOnSignIn,
  };
  return (
    <>
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    </>
  );
};

export { GlobalState, useGlobalState };

// https://aws-amplify.github.io/amplify-js/api/classes/authclass.html
