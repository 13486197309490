import { useState, useEffect } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { Balances, balancesStateShape } from '../interfaces/balancesState';

interface UseGetBalancesReturnType {
  balancesData: Balances | null;
  isLoading: boolean;
  error: any;
  fetchBalances: (id?: string) => Promise<void>;
}

export const useGetBalances = (): UseGetBalancesReturnType => {
  const { setBalancesState } = useGlobalDataState();
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<Balances>();

  const [balancesData, setBalancesData] = useState<Balances | null>(null);

  const fetchBalances = async (id?: string): Promise<void> => {
    try {
      const url = id
        ? `/v1/payout-account/balance?accountId=${id}`
        : '/v1/payout-account/balance';
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while fetching the balances:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setBalancesData(apiResponse.data);
      setBalancesState((prevState: balancesStateShape) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: apiResponse.data.balances.items,
          payoutEligibility: apiResponse.data.payoutEligibility,
        },
      }));
    }
  }, [apiResponse]);

  return {
    balancesData,
    isLoading,
    error,
    fetchBalances,
  };
};
