const caStates = (): { value: string; name: string }[] => {
  const arr = [
    { name: 'Alberta', value: 'AB' },
    { name: 'British Columbia', value: 'BC' },
    { name: 'Manitoba', value: 'MB' },
    { name: 'New Brunswick', value: 'NB' },
    { name: 'Newfoundland and Labrador', value: 'NL' },
    { name: 'Northwest Territories', value: 'NT' },
    { name: 'Nova Scotia', value: 'NS' },
    { name: 'Nunavut', value: 'NU' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Prince Edward Island', value: 'PE' },
    { name: 'Quebec', value: 'QC' },
    { name: 'Saskatchewan', value: 'SK' },
    { name: 'Yukon Territory', value: 'YT' },
  ];
  return arr;
};
export { caStates };
