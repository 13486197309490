export interface IssuerResponseSummary {
  title: string;
  description: string;
}

function getIssuerResponseCodeSummary(code?: string): IssuerResponseSummary {
  if (code === '00' || code === '000') {
    return {
      title: 'Approved',
      description: '',
    };
  }
  if (code === '01') {
    return {
      title: 'Refer to card issuer',
      description: 'Shopper should contact their bank for clarification',
    };
  }
  if (code === '02') {
    return {
      title: "Refer to issuer's special conditions",
      description:
        "The customer's card issuer has indicated there is a problem with the card number. The customer should use an alternate credit card, or contact their bank.",
    };
  }
  if (code === '03') {
    return {
      title: 'Invalid Merchant',
      description: 'Contact us for assistance',
    };
  }
  if (code === '04') {
    return {
      title: 'Capture / Pick Up Card',
      description:
        'The transaction is declined by the issuing bank that requested for retaining the card as it could be reported as lost or stolen. Ask the customer to use a different card or contact their issuing bank to resolve the issue.',
    };
  }
  if (code === '05') {
    return {
      title: 'Do not honour',
      description:
        'The issuing bank is unwilling to accept the transaction. Ask the customer for a different card to complete the transaction or ask them to contact the bank for more details.',
    };
  }
  if (code === '06') {
    return {
      title: 'Error',
      description:
        'Payment could not be authorised and resulted in an error. The shopper can try again or use another payment method.',
    };
  }
  if (code === '07') {
    return {
      title: 'Pick up card special condition',
      description:
        'The card issuer requests to retain the card. This can be due to a suspected counterfeit or stolen card.',
    };
  }
  if (code === '09') {
    return {
      title: 'Request in Progress',
      description:
        'The request is still currently being processed. Please await a final response.',
    };
  }
  if (code === '10') {
    return {
      title: 'Partial Amount Approved',
      description:
        'The transaction was cancelled. Some card issuers support partial-approval authorization. This approves a part of the requested transaction, leaving the remainder to be paid with another form of tender.',
    };
  }
  if (code === '12') {
    return {
      title: 'Invalid transaction',
      description:
        'An error occurred while processing the card. Make sure that payments are correctly configured.',
    };
  }
  if (code === '13') {
    return {
      title: 'Invalid amount / bad message edit',
      description:
        "Double check what is entered and make sure it wasn't negative or included incorrect symbols.",
    };
  }
  if (code === '14') {
    return {
      title: 'Invalid card number/no such number',
      description:
        "The card issuing bank has declined the transaction because of an incorrectly entered credit card number or a number that doesn't exist. Double check the card details and try processing again.",
    };
  }
  if (code === '15') {
    return {
      title: 'Invalid issuer',
      description:
        "The customer's card issuer doesn't exist. Double check the card number and try processing the transaction again.",
    };
  }
  if (code === '17') {
    return {
      title: 'Customer cancellation',
      description:
        'The client has cancelled this transaction. Please reach out to your client for more information.',
    };
  }
  if (code === '18') {
    return {
      title: 'Customer dispute',
      description:
        'The customer has filed a dispute for this transaction. Please reach out to your client for more information.',
    };
  }
  if (code === '19') {
    return {
      title: 'Re-enter transaction',
      description:
        'There was an error processing this payment. Please attempt the transaction again.',
    };
  }
  if (code === '22') {
    return {
      title: 'Suspected malfunction',
      description:
        'The issuing bank is not responding during the transaction. The cardholder should check the card information and try processing again.',
    };
  }
  if (code === '30') {
    return {
      title: 'Format error',
      description:
        'Contact support to double check the setup on your merchant account, as it may be incorrect.',
    };
  }
  if (code === '31') {
    return {
      title: 'Bank not supported by switch',
      description:
        "The customer's bank has declined the transaction because it doesn't allow using the card for mail/telephone, fax, email or internet orders.",
    };
  }
  if (code === '33' || code === '54' || code === '101') {
    return {
      title: 'Expired Card',
      description:
        'The card is expired and no longer valid to use. Ask the customer to correct or switch the card.',
    };
  }
  if (code === '34') {
    return {
      title: 'Suspected Fraud',
      description:
        'The transaction is declined by the issuing bank as there is suspected fraud on this credit card number. Monitor subsequent transactions to check for fraudulent transactions on alternate cards. If there are multiple fraudulent transactions, please contact us.',
    };
  }
  if (code === '35') {
    return {
      title: 'Card Acceptor call acquirer security',
      description:
        'The issuing bank is requesting you pick up this card. Please contact the number on the back of the card for more information.',
    };
  }
  if (code === '36') {
    return {
      title: 'Restricted card pick up',
      description:
        'There are restrictions on this card that are not allowing the transaction to be processed. Please reach out to your client for updated billing information.',
    };
  }
  if (code === '37') {
    return {
      title: 'Suspected Fraud',
      description:
        "The customer's card issuer has declined the transaction and requested to retain your customer's card. The customer should use an alternate card or contact their bank. After receiving this particular error, monitor subsequent transactions for fraudulent transactions on alternate cards.",
    };
  }
  if (code === '40') {
    return {
      title: 'Requested function not supported',
      description:
        "The customer's card issuer has declined the transaction as it does not allow this type of transaction.",
    };
  }
  if (code === '41') {
    return {
      title: 'Lost card',
      description:
        "The card was reported as lost. Validate the shopper's authenticity and refer them to their bank.",
    };
  }
  if (code === '42') {
    return {
      title: 'No universal account',
      description:
        'The transaction is declined by the issuing bank as the account type is not valid for this card number. Ask the customer to use a separate card or to contact their bank.',
    };
  }
  if (code === '43') {
    return {
      title: 'Stolen card',
      description:
        "The card was reported as stolen. Validate the shopper's authenticity and refer them to their bank.",
    };
  }
  if (code === '46') {
    return {
      title: 'Closed account',
      description:
        'The account is closed. Re-validate the account number for accuracy and do not reattempt with the same PAN or token.',
    };
  }
  if (code === '49') {
    return {
      title: 'The Card Was Declined',
      description:
        "The customer's card issuer has declined the transaction as the credit card is not enabled for online transactions. The customer should use an alternate card or contact their bank.",
    };
  }
  if (code === '51') {
    return {
      title: 'Insufficient funds',
      description:
        "The transaction is denied by the issuing bank as there aren't enough funds in the associated bank account to complete this payment or the transaction will put the customer's credit card over the limit. Request a different card from a customer or ask them to contact their issuing bank. ",
    };
  }
  if (code === '56') {
    return {
      title: 'No card record',
      description:
        "The transaction is declined by the issuer, as the credit card number doesn't exist. The customer should use a separate credit card.",
    };
  }
  if (code === '57') {
    return {
      title: 'Transaction not permitted to issuer/cardholder',
      description:
        'The issuing bank has declined the transaction as this card cannot be used for this type of transaction. Ask the customer to use another card or contact their bank. When the bank confirms it will process correctly, attempt the transaction again.',
    };
  }
  if (code === '58') {
    return {
      title: 'Transaction not permitted by terminal',
      description:
        'The code appears when the card cannot be used for this type of transaction or when the merchant processing account is not properly configured. The response usually appears when test cards are used on the live gateway.',
    };
  }
  if (code === '59') {
    return {
      title: 'Suspected fraud',
      description:
        'The transaction is declined by the issuer because it appears fraudulent. Monitor all the transactions processed after the error occurred.',
    };
  }
  if (code === '61') {
    return {
      title: 'Exceeds withdrawal amount limit',
      description:
        "The issuer has declined the transaction because it will exceed the customer's card limit. Ask the customer to use another card.",
    };
  }
  if (code === '62') {
    return {
      title: 'Restricted card',
      description:
        "The card is invalid in a certain region or country or the customer tried to pay online with a card that doesn't support your online payments.",
    };
  }
  if (code === '63' || code == '106') {
    return {
      title: 'Security Violation',
      description:
        'The card issuer indicated a security issue with this card. The customer can use another payment method. Alternatively, the customer can try again after they resolved the issue with their bank.',
    };
  }
  if (code === '67') {
    return {
      title: 'Hard capture',
      description:
        "The transaction is declined by the issuing bank because the card is suspected to be counterfeit. The customer's card issuer has requested that your customer's credit card is retained by you. Ask the customer to use a different card or to contact their bank.",
    };
  }
  if (
    code === '69' ||
    code === '70' ||
    code === '71' ||
    code === '72' ||
    code === '73' ||
    code === '74'
  ) {
    return {
      title: 'Contact Card Issuer',
      description:
        'The card issuer indicated an issue with this card and requests contact from the customer. The customer can use another payment method. Alternatively the customer can try again after they resolved the issue with their bank.',
    };
  }
  if (code === '75') {
    return {
      title: 'Allowable number of PIN tries exceeded',
      description:
        'The transaction is from a new cardholder, and the card has not been properly unblocked.',
    };
  }
  if (code === '78') {
    return {
      title: 'Error',
      description:
        'The customer has entered an incorrect PIN more times than is allowed by the issuing bank. The customer should retry or use another payment method.',
    };
  }
  if (code === '79') {
    return {
      title: 'Life Cycle',
      description: 'The transaction is refused due to invalid card data.',
    };
  }
  if (code === '80') {
    return {
      title: 'Credit issuer unavailable',
      description:
        'The issuing bank cannot be contacted. The customer should retry or use another payment method.',
    };
  }
  if (code === '83') {
    return {
      title: 'Fraud / Security',
      description:
        'The transaction is refused because the card issuer suspects this payment to be fraudulent',
    };
  }
  if (code === '85') {
    return {
      title: 'Not declined (Valid for all zero amount transactions)',
      description: 'Zero-value auth request authorised.',
    };
  }
  if (code === '91') {
    return {
      title:
        'Authorization Platform or issuer system inoperative / Issuer not available',
      description:
        'There was a problem contacting the issuing bank to authorize the transaction. The customer should attempt to process this transaction again. If the problem persists, the cardholder should contact their bank or re-attept the payment using an alternative card.',
    };
  }
  if (code === '92') {
    return {
      title:
        'Destination cannot be found for routing / Unable to route transaction',
      description:
        'The shopper is using a test card number on live. The shopper should use another payment method.',
    };
  }
  if (code === '93') {
    return {
      title: 'Transaction cannot be completed; violation of law',
      description:
        'The issuing bank will not allow this transaction. The shopper should use another payment method.',
    };
  }
  if (code === '96') {
    return {
      title: 'System malfunction',
      description:
        'The issuing bank cannot be contacted. The customer should retry or use another payment method.',
    };
  }
  if (code === '97' || code === '98' || code === '99') {
    return {
      title: 'Error',
      description: 'Contact us for assistance',
    };
  }
  if (code === 'R1') {
    return {
      title: 'Revocation of Auth',
      description:
        'The customer has contacted their issuing bank and cancelled the continuous authorisation on their account. The customer should contact their bank to lift this block or use alternative payment details.',
    };
  }
  if (code === '1A' || code === '65' || code === '130') {
    return {
      title: 'Additional SCA Required',
      description:
        'Transaction is in scope of PSD2, Strong Customer Authentication (SCA) is required, retry with 3-D Secure mandated.',
    };
  }
  if (code === 'N7') {
    return {
      title: 'CVV2 failure',
      description:
        'The customer entered an invalid CVV value. The code provided does not match the one on file with the card issuer.',
    };
  }
  /**
   * Amex specific codes
   */
  if (code === '001') {
    return {
      title: 'Approve with ID',
      description:
        "The transaction was cancelled after the issuer requested that identification be established through telephone contact between the acquiring center's authoriser and the merchant.",
    };
  }
  if (code === '002') {
    return {
      title: 'Partial approval (prepaid card)',
      description:
        'Transaction was cancelled. Some card issuers support partial-approval authorization. This approves a part of the requested transaction, leaving the remainder to be paid with another form of tender.',
    };
  }
  if (code === '100') {
    return {
      title: 'Deny',
      description:
        'This is a generic refusal that can have several possible reasons. Shopper should contact their issuing bank for clarification.',
    };
  }
  if (code === '111') {
    return {
      title: 'Invalid account',
      description:
        'The card issuer is unable to validate the card/account number, possibly because an invalid character was used.',
    };
  }
  if (code === '115') {
    return {
      title: 'Unsupported function',
      description:
        'The card issuer does not allow this type of transaction on this card/account.',
    };
  }
  if (code === '117') {
    return {
      title: 'Incorrect PIN',
      description:
        'The shopper entered an invalid PIN. The shopper should try again or use another payment method.',
    };
  }
  if (code === '119') {
    return {
      title: 'Cardmember not enrolled / not permitted',
      description:
        "The card issuer doesn't permit the transaction on this card/account. The shopper can use another payment method.",
    };
  }
  if (code === '121') {
    return {
      title: 'Limit exceeded',
      description:
        'Transaction exceeded card limit or some other threshold on the account',
    };
  }
  if (code === '122') {
    return {
      title: 'Cardmember not enrolled / not permitted',
      description:
        'The card issuer indicated a security issue with this card. The shopper can use another payment method. Alternatively, the shopper can try again after they resolved the issue with their bank.',
    };
  }
  if (code === '125') {
    return {
      title: 'Invalid effective date',
      description:
        'The card effective date provided is not a valid date format. The shopper should correct or use another payment method.',
    };
  }
  if (code === '181') {
    return {
      title: 'Format error',
      description:
        'The card issuer does not recognize the transaction details being entered. This is due to a format error. The shopper should check the transaction information and try again.',
    };
  }
  if (code === '183') {
    return {
      title: 'Invalid currency code',
      description:
        "The currency code provided doesn't comply with standards. Contact us for immediate assistance",
    };
  }
  if (code === '187') {
    return {
      title: 'Deny - new card issued',
      description:
        'The card issuer supplied the cardholder with a new card. The shopper should try again with the new card, or use another payment method',
    };
  }
  if (code === '189') {
    return {
      title: 'Deny - cancelled or closed merchant/SE',
      description:
        'The merchant has stopped operations or is no longer accepting cards from this card scheme.',
    };
  }
  if (code === '190') {
    return {
      title: 'National ID mismatch',
      description:
        'The card issuer does not recognize the provided national ID.',
    };
  }
  if (code === '193') {
    return {
      title: 'Invalid country code',
      description:
        "The country code provided doesn't comply with standards. Contact us for immediate assistance.",
    };
  }
  if (code === '194') {
    return {
      title: 'Invalid region code',
      description:
        "The region code provided doesn't comply with standards. Contact us for immediate assistance.",
    };
  }
  if (code === '200') {
    return {
      title: 'Deny - pick up card',
      description:
        'The card issuer requests to retain the card. This can be due to a suspected counterfeit or stolen card.',
    };
  }
  if (code === '900') {
    return {
      title: 'Advice accepted',
      description:
        'The ExpressPay issuer is unable to respond to an ATC Synchronization Authorization Request.',
    };
  }
  if (code === '911') {
    return {
      title: 'Card issuer timed out',
      description:
        'The issuing bank cannot be contacted. The shopper should try again or use another payment method.',
    };
  }
  if (code === '912') {
    return {
      title: 'Isser not available',
      description:
        'The issuing bank cannot be contacted. The shopper should try again or use another payment method.',
    };
  }
  if (code === '977') {
    return {
      title: 'Invalid Payment Plan',
      description:
        'Issuer refused the transaction due to an issue with the payment plan.',
    };
  }
  if (code === '978') {
    return {
      title: 'Invalid Payment Times',
      description:
        'Issuer rejected the transaction due to the time of the transaction being deemed invalid for the payment plan.',
    };
  }
  if (code === '400') {
    return {
      title: 'Reversal Approved',
      description: 'Issuer approved the reversal',
    };
  }
  return {
    title: 'Unknown',
    description: 'Contact us for assistance',
  };
}

export { getIssuerResponseCodeSummary };
