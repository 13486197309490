import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import {
  EditProfileRequest,
  useEditProfile,
} from '../../../hooks/useEditProfile';
import { handleNotSignedIn } from '../../../functions/helpers';

const EditProfileRoute = (): ReactElement => {
  const returnTo = '/profile';
  const history = useHistory();
  const { profileState } = useGlobalDataState();
  const { isSignedIn } = useGlobalState();
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(
    profileState.data?.user?.firstName || ''
  );
  const [lastName, setLastName] = useState<string>(
    profileState.data?.user?.lastName || ''
  );
  const [editProfile, isLoading, isEdited, error] = useEditProfile();

  const updateProfile = async (): Promise<void> => {
    // eslint-disable-next-line no-useless-escape
    const nameRegex = /^[a-zA-Z\s\'\,\.\-]+$/;
    if (!nameRegex.test(firstName)) {
      setFirstNameError(
        "firstName must only contain letters, space and the following characters: ' , - ."
      );

      return;
    } else setFirstNameError('');

    if (!nameRegex.test(lastName)) {
      setLastNameError(
        "lastName must only contain letters, space and the following characters: ' , - ."
      );
      return;
    } else setLastNameError('');

    const editRequest: EditProfileRequest = {
      firstName,
      lastName,
    };
    await editProfile(editRequest);
  };

  useEffect(() => {
    isEdited && history.push(returnTo);
  }, [isLoading, isEdited, error]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  return (
    <>
      <LayoutIllustration
        title={<>Edit Profile </>}
        subTitle={<>Edit your details</>}
      >
        <form
          style={{ padding: '0 24px' }}
          onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
          }}
        >
          <TextInput
            label
            name='First Name'
            value={firstName}
            type={'String'}
            setValue={setFirstName}
            error={firstNameError}
          />
          <TextInput
            label
            name='Last Name'
            value={lastName}
            type={'String'}
            setValue={setLastName}
            error={lastNameError}
          />

          <div className='EditWebhookRoute--button-container'>
            <Button
              name={'Edit'}
              click={updateProfile}
              loading={isLoading}
              disabled={false}
            />
            <Button
              name='Cancel'
              click={(): void => history.push(returnTo)}
              color='GREY'
            />
          </div>
        </form>
      </LayoutIllustration>
    </>
  );
};

export { EditProfileRoute };
