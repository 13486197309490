import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { profileStateShape } from '../interfaces/profileState';
import { useGlobalDataState } from '../hooks/useGlobalDataState';

export interface EditProfileRequest {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

function useEditProfile(): any {
  const { setProfileState } = useGlobalDataState();
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setIsEdited(true);
      setProfileState((prevState: profileStateShape) => ({
        ...prevState,
        data: response.data,
      }));
    }
  }, [response]);

  const editProfile = (body: EditProfileRequest): void => {
    const url = `/v1/profile`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method, body });
  };

  return [editProfile, isLoading, isEdited, error];
}

export { useEditProfile };
