import { ReactElement } from 'react';

export interface PropsShape {
  warning?: string;
}

const Warning = ({ warning }: PropsShape): ReactElement => {
  return <>{warning && <div className='Warning'>{warning}</div>}</>;
};
export { Warning };
