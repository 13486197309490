import { ReactElement, useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { SelectInput } from '../forms/SelectInput';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { CheckBox } from '../forms/CheckBox';
import { DateRange } from 'react-date-range';
import { DefinedRange } from 'react-date-range';
import {
  dateToEndOfDayEpochSeconds,
  dateToStartOfDayEpochSeconds,
} from '../../functions/helpers';
import { DateTime } from './DateTime';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AccountSettingsProfile } from '../../interfaces/profileState';

export interface PropsShape {
  sessionIdValue?: string;
  group?: 'STANDARD' | 'SUB';
  accountSettings?: AccountSettingsProfile;
  filterFor:
    | 'PAYMENTS'
    | 'SPLIT-PAYMENTS'
    | 'DISPUTES'
    | 'FEES'
    | 'EVENTS'
    | 'SUB-ACCOUNTS'
    | 'PAYOUTS'
    | 'CUSTOMERS'
    | undefined;
  hideDate: boolean;
  setValue: (filterData: {
    report: boolean;
    feeId: string;
    eventId: string;
    accountId: string;
    sessionId: string;
    subAccountId: string;
    payoutId: string;
    status: string;
    startDate: number;
    endDate: number;
    customerId: string;
    email: string;
    currency: string;
  }) => {};
  customerIdValue?: string;
}

const Filter = ({
  customerIdValue,
  filterFor,
  group,
  accountSettings,
  hideDate,
  sessionIdValue,
  setValue,
}: PropsShape): ReactElement => {
  const filterTransition = useRef(null);
  const [isFilterMenuVisible, setIsFilterMenuVisible] =
    useState<boolean>(false);
  const [isDateRangeVisible, setDateRangeVisible] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [account, setAccount] = useState<string>('myAccount');
  const [accountId, setAccountId] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>(sessionIdValue || '');
  const [feeId, setFeeId] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [subAccountId, setSubAccountId] = useState<string>('');
  const [payoutId, setPayoutId] = useState<string>('');
  const [payoutIdError, setPayoutIdError] = useState<string>('');
  const [subAccountIdError, setSubAccountIdError] = useState<string>('');
  const [feeIdError, setFeeIdError] = useState<string>('');
  const [eventIdError, setEventIdError] = useState<string>('');
  const [accountIdError, setAccountIdError] = useState<string>('');
  const [paymentSessionError, setPaymentSessionError] = useState<string>('');
  const [report, setReport] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>('');
  const [clear, setClear] = useState<boolean>(true);
  const [filterBtnDisabled, setFilterBtnDisabled] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>(customerIdValue || '');
  const [email, setEmail] = useState<string>('');
  const [customerIdError, setCustomerIdError] = useState<string>('');

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (account === 'myAccount') {
      setAccountId('');
      setFilterBtnDisabled(false);
    } else {
      setFilterBtnDisabled(true);
    }
  }, [account]);

  useEffect(() => {
    setPayoutIdError('');
    if (eventId.length >= 3 && eventId.substring(0, 3) !== 'po_') {
      setPayoutIdError('Must start with po_');
      setFilterBtnDisabled(true);
    }
    if (payoutId.length >= 3 && payoutId.substring(0, 3) === 'po_') {
      setFilterBtnDisabled(false);
    }
    if (payoutId.length > 0 && payoutId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (payoutId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [payoutId]);

  useEffect(() => {
    setSubAccountIdError('');
    if (eventId.length >= 3 && eventId.substring(0, 3) !== 'ac_') {
      setSubAccountIdError('Must start with ac_');
      setFilterBtnDisabled(true);
    }
    if (subAccountId.length >= 3 && subAccountId.substring(0, 3) === 'ac_') {
      setFilterBtnDisabled(false);
    }
    if (subAccountId.length > 0 && subAccountId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (subAccountId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [subAccountId]);

  useEffect(() => {
    setEventIdError('');
    if (eventId.length >= 3 && eventId.substring(0, 3) !== 'ev_') {
      setEventIdError('Must start with ev_');
      setFilterBtnDisabled(true);
    }
    if (eventId.length >= 3 && eventId.substring(0, 3) === 'ev_') {
      setFilterBtnDisabled(false);
    }
    if (eventId.length > 0 && eventId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (eventId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [eventId]);

  useEffect(() => {
    setFeeIdError('');
    if (feeId.length >= 3 && feeId.substring(0, 3) !== 'pf_') {
      setFeeIdError('Must start with pf_');
      setFilterBtnDisabled(true);
    }
    if (feeId.length >= 3 && feeId.substring(0, 3) === 'pf_') {
      setFilterBtnDisabled(false);
    }
    if (feeId.length > 0 && feeId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (feeId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [feeId]);

  useEffect(() => {
    setCustomerIdError('');
    if (customerId.length >= 4 && customerId.substring(0, 4) !== 'cus_') {
      setCustomerIdError('Must start with cus_');
      setFilterBtnDisabled(true);
    }
    if (customerId.length >= 4 && customerId.substring(0, 4) === 'cus_') {
      setFilterBtnDisabled(false);
    }
    if (customerId.length > 0 && customerId.length < 4) {
      setFilterBtnDisabled(true);
    }
    if (customerId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [customerId]);

  useEffect(() => {
    setAccountIdError('');
    if (accountId.length >= 3 && accountId.substring(0, 3) !== 'ac_') {
      setAccountIdError('Must start with ac_');
      setFilterBtnDisabled(true);
    }
    if (accountId.length >= 3 && accountId.substring(0, 3) === 'ac_') {
      setFilterBtnDisabled(false);
    }
    if (accountId.length < 3 && account === 'subAccounts') {
      setFilterBtnDisabled(true);
    }
    if (accountId.length > 0 && accountId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (accountId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [accountId]);

  useEffect(() => {
    // filterFor
    setPaymentSessionError('');
    if (
      sessionId.length >= 3 &&
      sessionId.substring(0, 3) !== 'ps_' &&
      filterFor != 'SPLIT-PAYMENTS'
    ) {
      setPaymentSessionError('Must start with ps_');
    }
    if (
      sessionId.length >= 3 &&
      sessionId.substring(0, 3) === 'ps_' &&
      filterFor != 'SPLIT-PAYMENTS'
    ) {
      setFilterBtnDisabled(false);
    }

    if (
      sessionId.length >= 3 &&
      sessionId.substring(0, 3) !== 'sp_' &&
      filterFor === 'SPLIT-PAYMENTS'
    ) {
      setPaymentSessionError('Must start with sp_');
    }
    if (
      sessionId.length >= 3 &&
      sessionId.substring(0, 3) === 'sp_' &&
      filterFor === 'SPLIT-PAYMENTS'
    ) {
      setFilterBtnDisabled(false);
    }

    if (sessionId.length > 0 && sessionId.length < 3) {
      setFilterBtnDisabled(true);
    }
    if (sessionId.length === 0) {
      setFilterBtnDisabled(false);
    }
  }, [sessionId]);

  const doFilter = (): void => {
    let error = false;
    if (account === 'subAccounts' && accountId === '') {
      error = true;
      setAccountIdError('Account id is required');
    }
    if (account === 'subAccounts' && accountId.substring(0, 3) !== 'ac_') {
      error = true;
      setAccountIdError('Must start with ac_');
    }
    if (!error) {
      setIsFilterMenuVisible(false);
      const startTimestamp = dateToStartOfDayEpochSeconds(range[0].startDate);
      const endTimestamp = dateToEndOfDayEpochSeconds(range[0].endDate);

      const filterData = {
        report,
        feeId,
        eventId,
        sessionId,
        accountId,
        subAccountId,
        payoutId,
        status,
        startDate: clear ? 0 : startTimestamp,
        endDate: clear ? 0 : endTimestamp,
        customerId,
        email,
        currency,
      };
      setValue(filterData);
    }
  };
  const makeClear = (): void => {
    setFeeId('');
    setSessionId('');
    setCustomerId('');
    setAccountId('');
    setStatus('');
    setCurrency('');
    setClear(true);
    setIsFilterMenuVisible(false);
    setIsFilterMenuVisible(false);
    const filterData = {
      report: false,
      feeId: '',
      eventId: '',
      sessionId: '',
      accountId: '',
      subAccountId: '',
      payoutId: '',
      status: '',
      startDate: 0,
      endDate: 0,
      customerId: '',
      email: '',
      currency: '',
    };
    setValue(filterData);

    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  };
  const toggleFilterMenu = (): void => {
    setIsFilterMenuVisible(!isFilterMenuVisible);
    if (!isFilterMenuVisible) setDateRangeVisible(false);
  };

  const toggleDateRange = (): void => {
    setClear(false);
    setDateRangeVisible(!isDateRangeVisible);
  };

  const genFilterCount = (): number => {
    let count = 0;
    if (status !== '' && status !== 'All') count++;
    if (eventId !== '') count++;
    if (sessionId !== '') count++;
    if (accountId !== '') count++;
    if (feeId !== '') count++;
    if (customerId !== '') count++;
    if (email !== '') count++;
    if (!clear) count++;
    return count;
  };

  const calcHeightStyle = (): string => {
    if (filterFor === 'EVENTS' && !accountSettings?.subAccountsDisplayed) {
      return 'Filter--dropdown Filter--dropdown-short';
    }
    if (filterFor === 'EVENTS' && account !== 'subAccounts') {
      return 'Filter--dropdown Filter--dropdown-short-nodate';
    }
    if (filterFor === 'EVENTS' && account === 'subAccounts') {
      return 'Filter--dropdown Filter--dropdown-tall-nodate';
    }
    if (account === 'subAccounts') {
      return 'Filter--dropdown Filter--dropdown-tall';
    }

    if (account !== 'subAccounts' && filterFor === 'CUSTOMERS') {
      return 'Filter--dropdown Filter--dropdown-double';
    }
    if (account !== 'subAccounts' && filterFor === 'FEES') {
      return 'Filter--dropdown Filter--dropdown-short';
    }
    if (account !== 'subAccounts' && filterFor === 'SUB-ACCOUNTS') {
      return 'Filter--dropdown Filter--dropdown-short';
    }
    if (group === 'SUB' && filterFor === 'PAYMENTS') {
      {
        /* Temporarily updated to short for sub accounts due to a backend bug with email filtering */
      }
      return 'Filter--dropdown Filter--dropdown-short';
    }
    if (group === 'STANDARD' && filterFor === 'PAYMENTS') {
      if (accountSettings?.subAccountsDisplayed) {
        return 'Filter--dropdown Filter--dropdown-maximum';
      }
      return 'Filter--dropdown Filter--dropdown-tall';
    }
    if (
      account !== 'subAccounts' &&
      filterFor !== 'FEES' &&
      group === 'STANDARD'
    ) {
      return 'Filter--dropdown';
    }
    if (
      account !== 'subAccounts' &&
      filterFor !== 'FEES' &&
      group !== 'STANDARD'
    ) {
      return 'Filter--dropdown Filter--dropdown-short';
    }

    return ``;
  };

  return (
    <div className='Filter'>
      <button
        id={filterFor}
        onClick={toggleFilterMenu}
        className='Filter--button'
      >
        <div>Filter</div>
        <div>{genFilterCount()}</div>
      </button>
      <CSSTransition
        nodeRef={filterTransition}
        in={isFilterMenuVisible}
        timeout={300}
        classNames='Filter--MenuTransition'
        unmountOnExit
      >
        <CSSTransition
          nodeRef={filterTransition}
          in={isDateRangeVisible}
          timeout={300}
          classNames='Filter--DateTransition'
        >
          <div className={calcHeightStyle()} ref={filterTransition}>
            <div className='Filter--dropdown-title'>
              <span onClick={toggleDateRange} className='Filter--dropdown-link'>
                Filter
              </span>{' '}
              {isDateRangeVisible && (
                <span className='Filter--dropdown-date-range'>Date Range</span>
              )}
            </div>
            <div className='Filter--filter-container'>
              <div className='Filter--filter-view'>
                {!hideDate && (
                  <div className='Filter--dropdown-date'>
                    <div onClick={toggleDateRange}>
                      <DateTime value={range[0].startDate} />
                    </div>
                    <div onClick={toggleDateRange}>
                      <DateTime value={range[0].endDate} />
                    </div>
                  </div>
                )}

                {(filterFor === 'PAYMENTS' ||
                  filterFor === 'EVENTS' ||
                  filterFor === 'PAYOUTS') &&
                  group === 'STANDARD' &&
                  accountSettings?.subAccountsDisplayed && (
                    <div className='Filter--dropdown-account'>
                      <SelectInput
                        name='account'
                        value={account}
                        setValue={setAccount}
                        options={[
                          { value: 'myAccount', name: 'My Account' },
                          { value: 'subAccounts', name: 'Sub-accounts' },
                        ]}
                      />
                    </div>
                  )}

                {filterFor === 'PAYOUTS' && account === 'myAccount' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Payout Id'
                      value={payoutId}
                      setValue={setPayoutId}
                      placeHolder='po_'
                      error={payoutIdError}
                      optional={true}
                    />
                  </div>
                )}
                {filterFor === 'PAYOUTS' && account === 'subAccounts' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Account id'
                      value={accountId}
                      setValue={setAccountId}
                      placeHolder='ac_'
                      error={accountIdError}
                    />
                    <TextInput
                      label={true}
                      name='Payout id'
                      value={payoutId}
                      setValue={setPayoutId}
                      placeHolder='po_'
                      error={payoutIdError}
                      optional={true}
                    />
                  </div>
                )}
                {filterFor === 'SUB-ACCOUNTS' && account === 'myAccount' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Sub-account id'
                      value={subAccountId}
                      setValue={setSubAccountId}
                      placeHolder='ac_'
                      error={subAccountIdError}
                      optional={true}
                    />
                  </div>
                )}
                {filterFor === 'PAYMENTS' && account === 'myAccount' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Payment session id'
                      value={sessionId}
                      setValue={setSessionId}
                      placeHolder='ps_'
                      error={paymentSessionError}
                      optional={true}
                    />
                    {group === 'STANDARD' && (
                      <TextInput
                        label={true}
                        name='Customer id'
                        value={customerId}
                        setValue={setCustomerId}
                        placeHolder='cus_'
                        error={customerIdError}
                        optional={true}
                      />
                    )}
                    {/* Temporarily disabled for sub accounts due to a backend bug */}
                    {group === 'STANDARD' && (
                      <TextInput
                        label={true}
                        name='Email'
                        value={email}
                        setValue={setEmail}
                        optional={true}
                      />
                    )}
                  </div>
                )}
                {filterFor === 'SPLIT-PAYMENTS' && account === 'myAccount' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Split payment id'
                      value={sessionId}
                      setValue={setSessionId}
                      placeHolder='sp_'
                      error={paymentSessionError}
                      optional={true}
                    />
                    {/* Temporarily disabled for sub accounts due to a backend bug */}
                    {group === 'STANDARD' && (
                      <TextInput
                        label={true}
                        name='Email'
                        value={email}
                        setValue={setEmail}
                        optional={true}
                      />
                    )}
                  </div>
                )}
                {filterFor === 'CUSTOMERS' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Customer id'
                      value={customerId}
                      setValue={setCustomerId}
                      placeHolder='cus_'
                      error={customerIdError}
                      optional={true}
                    />
                    <TextInput
                      label={true}
                      name='Email'
                      value={email}
                      setValue={setEmail}
                      optional={true}
                    />
                  </div>
                )}
                {filterFor === 'FEES' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Fee id'
                      value={feeId}
                      setValue={setFeeId}
                      placeHolder='pf_'
                      error={feeIdError}
                      optional={true}
                    />
                  </div>
                )}
                {filterFor === 'EVENTS' && account === 'myAccount' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Event id'
                      value={eventId}
                      setValue={setEventId}
                      placeHolder='ev_'
                      error={eventIdError}
                      optional={true}
                    />
                  </div>
                )}
                {account === 'subAccounts' && filterFor === 'PAYMENTS' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Account id'
                      value={accountId}
                      setValue={setAccountId}
                      placeHolder='ac_'
                      error={accountIdError}
                    />
                    <TextInput
                      label={true}
                      name='Payment session id'
                      value={sessionId}
                      setValue={setSessionId}
                      placeHolder='ps_'
                      error={paymentSessionError}
                      optional={true}
                    />
                  </div>
                )}
                {account === 'subAccounts' && filterFor === 'EVENTS' && (
                  <div className='Filter--dropdown-session'>
                    <TextInput
                      label={true}
                      name='Account id'
                      value={accountId}
                      setValue={setAccountId}
                      placeHolder='ac_'
                      error={accountIdError}
                    />
                    <TextInput
                      label={true}
                      name='Event id'
                      value={eventId}
                      setValue={setEventId}
                      placeHolder='ev_'
                      error={eventIdError}
                      optional={true}
                    />
                  </div>
                )}
                <div className='Filter--dropdown-checkbox'>
                  <CheckBox
                    label='Generate report and save in my files'
                    value={report}
                    setValue={setReport}
                    id='checkbox'
                  />
                </div>
                <div className='Filter--dropdown-buttons'>
                  <Button
                    name='Filter'
                    color='BLUE'
                    click={doFilter}
                    disabled={filterBtnDisabled}
                  />
                  <Button name='Clear' color='GREY' click={makeClear} />
                </div>
              </div>

              <div className='Filter--range-view'>
                <DefinedRange
                  onChange={(item): any => {
                    if (
                      item &&
                      item.selection &&
                      item.selection.endDate &&
                      item.selection.startDate
                    ) {
                      setRange([
                        {
                          startDate: item.selection.startDate,
                          endDate:
                            item.selection.endDate > new Date()
                              ? new Date()
                              : item.selection.endDate,
                          key: 'selection',
                        },
                      ]);
                    }
                  }}
                  ranges={range}
                />
                <DateRange
                  maxDate={new Date()}
                  editableDateInputs={true}
                  onChange={(item): any => {
                    if (
                      item &&
                      item.selection &&
                      item.selection.endDate &&
                      item.selection.startDate
                    ) {
                      setRange([
                        {
                          startDate: item.selection.startDate,
                          endDate:
                            item.selection.endDate > new Date()
                              ? new Date()
                              : item.selection.endDate,
                          key: 'selection',
                        },
                      ]);
                    }
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                />
                <div className='Filter--range-view-container'>
                  <Button name='Select' color='BLUE' click={toggleDateRange} />
                  <Button name='Cancel' color='GREY' click={toggleDateRange} />
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </CSSTransition>
    </div>
  );
};
export { Filter };
