import {
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  ReactElement,
  useCallback,
} from 'react';
import { useApiRequest, HttpMethod } from '../../hooks/useApiRequest';
import { Customer } from '../../interfaces/customersState';

interface SearchComponentProps {
  title: string;
  customer?: Customer;
  setCustomer: (id: Customer) => void;
  setEmail?: ((email: string) => void) | undefined;
}

type CustomersApiResponse = {
  items: Customer[];
};

function EmailToCustomer({
  title,
  customer,
  setCustomer,
  setEmail,
}: SearchComponentProps): ReactElement {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [hasSelected, setHasSelected] = useState(false);

  const [response, isLoading, error, sendRequest] =
    useApiRequest<CustomersApiResponse>();
  const memoizedSendRequest = useCallback(sendRequest, []);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent): void {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setSearchResults([]);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return (): void => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (hasSelected) {
      return;
    }
    if (setEmail) {
      setEmail(searchQuery);
    }
    const searchTimer = setTimeout(() => {
      if (searchQuery.length > 2) {
        memoizedSendRequest({
          url: `/v1/customers?email=${encodeURIComponent(searchQuery)}`,
          method: HttpMethod.GET,
        });
      }
    }, 800);

    return (): void => clearTimeout(searchTimer);
  }, [searchQuery, memoizedSendRequest, hasSelected]);

  useEffect(() => {
    if (response) {
      setSearchResults(response.data.items.map((item) => item.email));
    }
  }, [response]);

  useEffect(() => {
    if (!customer) {
      setSearchQuery('');
    }
  }, [customer]);

  function handleSearchInput(event: ChangeEvent<HTMLInputElement>): void {
    setSearchQuery(event.target.value);
    setHasSelected(false);
  }

  function handleResultClick(result: string): void {
    const selectedCustomer = response?.data.items.find(
      (customer) => customer.email === result
    );
    if (selectedCustomer) {
      if (setEmail) {
        setEmail(selectedCustomer.email);
      }
      setCustomer(selectedCustomer);
    }
    setSearchQuery(result);
    setSearchResults([]);
    setHasSelected(true);
  }

  return (
    <div className='EmailToId'>
      <label htmlFor='searchInput'>{title}:</label>
      <br />
      <input
        className='EmailToId--input'
        type='text'
        id='searchInput'
        value={searchQuery}
        onChange={handleSearchInput}
      />
      <div className='EmailToId--anchor'>
        <div
          ref={dropdownRef}
          className={searchResults.length > 0 ? 'EmailToId--dropdown' : ''}
        >
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {searchResults.length > 0 && (
            <ul>
              {searchResults.map((result) => (
                <li
                  key={result}
                  onClick={(): void => handleResultClick(result)}
                >
                  {result}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export { EmailToCustomer };
