import { ReactElement } from 'react';
import { StatusPageComponentResponse } from '../../interfaces/statusPageState';
import { SystemStatusComponent } from './SystemStatusComponent';

export interface PropsShape {
  components: StatusPageComponentResponse[];
}

const SystemStatusGroup = ({ components }: PropsShape): ReactElement => {
  return (
    <div className='SystemStatusGroup'>
      <div className='SystemStatusGroup--items'>
        {components.map((c) => (
          <SystemStatusComponent
            name={c.name}
            status={c.status}
            showStatusDescription={true}
          />
        ))}
      </div>
    </div>
  );
};
export { SystemStatusGroup };
