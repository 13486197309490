import React from 'react';
import { ForgotPassword } from '../components/onboarding/Forgot';
import { LayoutIllustration } from '../layout-illustration';
import forgotPassword from '../images/illustrations/forgot-password.png';

const ForgotRoute = (): React.ReactElement => {
  return (
    <LayoutIllustration
      image={forgotPassword}
      title={
        <>
          Forgot your <span>password?</span>
        </>
      }
      subTitle={
        <>
          It happens to the best of us, we will send you a password reset link
          to <span>your email</span>
        </>
      }
    >
      <ForgotPassword />
    </LayoutIllustration>
  );
};

export { ForgotRoute };
