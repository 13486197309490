import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

const SignedOut = (): ReactElement => {
  const history = useHistory();
  return (
    <div className='SignedOut'>
      <div className='SignedOut--center'>
        <h1 className='SignedOut--name' onClick={(): void => history.push('/')}>
          {process.env.REACT_APP_NAME?.replace('-', ' ')}
        </h1>
      </div>
    </div>
  );
};
export { SignedOut };
