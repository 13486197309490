import { ReactElement } from 'react';

export interface PropsShape {
  steps: number;
  position: number;
}

const StepPosition = ({ steps, position }: PropsShape): ReactElement => {
  const _steps = Array.from(Array(steps).keys());
  return steps > 1 ? (
    <div
      className='StepPosition'
      style={{ gridTemplateColumns: `repeat(${steps}, 1fr)` }}
    >
      {_steps.map((item: number) => {
        return (
          <div
            key={item}
            className={
              position - 1 === item
                ? 'StepPosition--selected'
                : 'StepPosition--not-selected'
            }
          ></div>
        );
      })}
    </div>
  ) : (
    <div />
  );
};
export { StepPosition };
