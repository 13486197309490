import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { Button } from '../../components/forms/Button';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { useGetUsers } from '../../hooks/useGetUsers';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermission } from '../../interfaces/profileState';
import { User, UsersShape } from '../../interfaces/userState';
import { DateTime } from '../../components/layouts/DateTime';
import { epochSecondsToDate, handleNotSignedIn } from '../../functions/helpers';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';

const Users = (): ReactElement => {
  const history = useHistory();
  const { profileState, usersState } = useGlobalDataState();
  const { isSignedIn } = useGlobalState();
  const [showModifyUsers, setShowModifyUsers] = useState(false);
  const [isLoading, error] = useGetUsers();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      UsersModify: setShowModifyUsers,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  const calculateUserTitle = (user: User): string => {
    return `${user.firstName ? user.firstName : ''} ${
      user.lastName ? user.lastName : ''
    } ${user.me ? '(you)' : ''}`;
  };

  const canAddUser = (users: UsersShape): boolean =>
    showModifyUsers && users.items.length < users.settings.limit;

  const canEditActiveUser = (user: User): boolean => {
    const modifiableRoles = new Set(
      (profileState?.data?.user.modifiableRoles ?? []).map((r) => r.role)
    );
    return showModifyUsers && !user.me && modifiableRoles.has(user.role);
  };

  const editUser = (item: User): void => {
    history.push(`/settings/users/edit-user/${item.id}`);
  };

  return (
    <CardBlank>
      {isLoading && !usersState.data && (
        <SettingsItem border title='Loading' subtitle={'......'}></SettingsItem>
      )}
      {!isLoading && error && (
        <SettingsItem border title='Oops!' subtitle={error}></SettingsItem>
      )}
      {!error && usersState.data && (
        <>
          <SettingsItem
            border
            title='Active'
            subtitle={
              <>
                Users with access to your account
                <br />
                <br />
                {!canAddUser(usersState.data)
                  ? 'Contact support if you require more users.'
                  : ''}
              </>
            }
            button={canAddUser(usersState.data) ? 'Add Teammate' : ''}
            buttonClick={(): void => history.push('/settings/users/add-user')}
          >
            <CardBlank>
              {!usersState.data.items.length && (
                <SettingsItem
                  border
                  inner
                  title='No Users'
                  subtitle='Please add a user'
                />
              )}
              {usersState.data.items.length > 0 &&
                usersState.data.items.map((item: User) => {
                  if (!item.registered) return null;

                  return (
                    <div key={item.id}>
                      <SettingsItem
                        avatar={{
                          url: '/no-avatar.jpg',
                          highlighted: item.me,
                        }}
                        border
                        inner
                        role={item.role}
                        lastSeen={
                          item.lastLoginDetails && (
                            <>
                              Seen on{' '}
                              <DateTime
                                showTime
                                alwaysShowYear={true}
                                value={epochSecondsToDate(
                                  item.lastLoginDetails.timestamp
                                )}
                              />
                            </>
                          )
                        }
                        title={calculateUserTitle(item)}
                        subtitle={`${item.email}`}
                      >
                        {canEditActiveUser(item) && (
                          <Button
                            margin='0'
                            name='Edit'
                            click={(): any => editUser(item)}
                            disabled={item.me}
                          />
                        )}
                      </SettingsItem>
                    </div>
                  );
                })}
            </CardBlank>
          </SettingsItem>
          {usersState.data.items.filter((u) => !u.registered).length > 0 && (
            <>
              <SettingsItem
                title='Pending Invites'
                subtitle='Users who have been invited to your account'
              >
                <CardBlank>
                  {usersState.data.items
                    .filter((u) => !u.registered)
                    .map((item: User) => {
                      return (
                        <SettingsItem
                          avatar={{
                            url: '/no-avatar.jpg',
                            highlighted: item.me,
                          }}
                          role={item.role}
                          lastSeen={
                            <>
                              Invited on{' '}
                              <DateTime
                                showTime
                                alwaysShowYear={true}
                                value={epochSecondsToDate(
                                  item.createdTimestamp
                                )}
                              />
                            </>
                          }
                          border
                          inner
                          title={calculateUserTitle(item)}
                          subtitle={`${item.email} `}
                        >
                          {showModifyUsers && (
                            <Button
                              margin='0'
                              name='Edit'
                              click={(): any => editUser(item)}
                            />
                          )}
                        </SettingsItem>
                      );
                    })}
                </CardBlank>
              </SettingsItem>
            </>
          )}
        </>
      )}
    </CardBlank>
  );
};
export { Users };
