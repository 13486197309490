import {
  CredentialOnFileUsage,
  EntryMode,
  PaymentType,
} from '../interfaces/paymentState';

const getEntryModeMessage = (entryMode: EntryMode): string => {
  return entryMode === 'MOTO' ? 'MOTO (Mail / Telephone order)' : 'Online';
};

const getCofUsageMessage = (
  paymentType: PaymentType,
  cof: CredentialOnFileUsage
): string => {
  return cof.sequence === 'Initial'
    ? paymentType === 'Standard'
      ? 'initial transaction to store card'
      : 'initial transaction in series'
    : `subsequent transaction with stored card`;
};

export { getEntryModeMessage, getCofUsageMessage };
