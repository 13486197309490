import { Params } from '@sentry/react/types/types';
import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { User, UserRole } from '../../../interfaces/userState';
import { useAdjustUser } from '../../../hooks/useAdjustUser';
import { handleNotSignedIn } from '../../../functions/helpers';

const EditUserRoute = (): ReactElement => {
  interface EditedDetails {
    role?: string;
  }

  interface optionShape {
    value: string;
    name: string;
  }

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/users';
  const history = useHistory();
  const params: Params = useParams();
  const { isSignedIn } = useGlobalState();
  const { usersState, profileState } = useGlobalDataState();
  const [role, setRole] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const [editedDetails, setEditedDetails] = useState<EditedDetails>({});
  const [
    apiResendUserInvite,
    apiDeleteUser,
    apiEditUser,
    isLoading,
    isComplete,
    error,
  ] = useAdjustUser();

  const userId = params?.id;
  const user = usersState?.data?.items.find((u) => u.id == userId);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!params?.id || !user || user.me || !permissions.has('UsersModify')) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (user) {
      setRole(user.role);
    }
  }, [user]);

  useEffect(() => {
    setEditedDetails({
      role,
    });
  }, [role]);

  useEffect(() => {
    isComplete && history.push(returnTo);
  }, [isComplete]);

  const isBlank = (value?: string): boolean => {
    return (value?.trim().length || 0) <= 0;
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const detailsBlank = isBlank(editedDetails.role);
    const detailsDiffer = detailsHaveBeenEdited(user, editedDetails);
    setBtnDisabled(!detailsDiffer || detailsBlank);
  }, [editedDetails]);

  useEffect(() => {
    if (!profileState.data || !profileState.data.user.modifiableRoles) {
      return;
    }
    const rolePermissions = profileState.data.user.modifiableRoles.find(
      (r) => r.role === role
    );
    if (!rolePermissions) {
      return;
    }
    setPermissions(rolePermissions.permissions.map((p) => p.description));
  }, [role]);

  const getUserFullName = (): string => {
    if (!user?.firstName && !user?.lastName) {
      return 'User';
    }
    return `${user?.firstName ? user.firstName : ''} ${
      user.lastName ? user.lastName : ''
    }`;
  };

  const getSubtitle = (): string => {
    if (!user) {
      return '';
    }
    const name = user.firstName ?? 'User';
    return user.registered
      ? `Changing ${name}'s role will alter what they can view or edit`
      : `Resend an invite to ${name} or choose to remove their account entirely`;
  };

  const getRoleOptions = (): optionShape[] =>
    profileState?.data?.user.modifiableRoles.map((r) =>
      getRoleOption(r.role)
    ) ?? [];

  const getRoleOption = (role: UserRole): optionShape => ({
    value: role,
    name: role,
  });

  const getEditTitle = (): string =>
    !user ? '' : user.registered ? `Edit Role` : `Resend Invite`;

  const detailsHaveBeenEdited = (user: User, edited: EditedDetails): boolean =>
    edited.role != user.role;

  const editUser = async (): Promise<void> => {
    if (!user) {
      return;
    }
    apiEditUser({ role: editedDetails.role }, user.id);
  };

  const resendUserInvite = async (): Promise<void> => {
    if (!user) {
      return;
    }
    apiResendUserInvite(user.id);
  };

  const deleteUser = async (): Promise<void> => {
    if (!user?.id) {
      return;
    }
    setDeleteLoading(true);
    apiDeleteUser(user.id);
  };

  return (
    <>
      {!user && <div className='AppRoute--notfound'>Error 404</div>}
      {user && (
        <LayoutIllustration
          title={<>You're editing {getUserFullName()}</>}
          subTitle={<>{getSubtitle()}</>}
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={error} />
            <TextInput
              label
              name='User Id'
              value={user.id}
              type={'String'}
              disabled={true}
            />
            <TextInput
              label
              name='Email'
              value={user.email}
              type={'String'}
              disabled={true}
            />
            <TextInput
              label
              name='Name'
              value={getUserFullName()}
              type={'String'}
              disabled={true}
            />
            {user.registered && (
              <SelectInput
                label
                name='Role'
                value={role}
                setValue={(v): any => setRole(v)}
                options={getRoleOptions()}
                disabled={!user.registered}
              />
            )}
            {!user.registered && (
              <TextInput
                label
                name='Role'
                value={role}
                type={'String'}
                disabled={true}
              />
            )}
            {permissions.length > 0 && (
              <div className='ModifyUserRoute--permissions'>
                <label
                  className='ModifyUserRoute--permissions-label'
                  htmlFor={'permissions'}
                >
                  Permissions
                </label>
                <div className='ModifyUserRoute--permissions-grid'>
                  {permissions.map((permission: string, index: number) => {
                    return (
                      <div
                        className='ModifyUserRoute--permissions-grid-item'
                        key={index}
                      >
                        {permission}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className='EditWebhookRoute--button-container'>
              <Button
                name={getEditTitle()}
                click={user.registered ? editUser : resendUserInvite}
                loading={!deleteLoading && isLoading}
                disabled={user.registered ? btnDisabled : false}
              />
              <Button
                name='Cancel'
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
              <>
                <div className='EditWebhookRoute--delete'>
                  {!deletedSelected && (
                    <span>
                      I want to remove this user.
                      <a
                        onClick={(): any => {
                          setDeleteSelected(true);
                        }}
                      >
                        &nbsp;Delete User
                      </a>
                    </span>
                  )}
                  {deletedSelected && (
                    <div className='EditWebhookRoute--delete-button'>
                      <Button
                        name='Delete'
                        click={deleteUser}
                        color='RED'
                        loading={deleteLoading && isLoading}
                        disabled={!deletedSelected}
                      />
                    </div>
                  )}
                </div>
              </>
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditUserRoute };
