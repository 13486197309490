import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { Payouts, payoutsStateShape } from '../interfaces/payoutsState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
import { useGlobalDataState } from './useGlobalDataState';

interface UseListPayoutsValue {
  payouts?: Payouts;
  isLoading: boolean;
  error?: string;
  listPayouts: (request: ListPayoutsRequest) => Promise<void>;
}

interface ListPayoutsRequest {
  pageSize?: number;
  ascending?: boolean;
  startTimestamp?: number;
  endTimestamp?: number;
  payoutId?: string;
  subAccountId?: string;
  payoutsStartAfter?: string;
}

function useListPayouts(): UseListPayoutsValue {
  const { setPayoutsState } = useGlobalDataState();
  const [payouts, setPayouts] = useState<Payouts>();
  const [response, isLoading, error, sendRequest] = useApiRequest<Payouts>();

  const listPayouts = async (request: ListPayoutsRequest): Promise<void> => {
    let url = '/v1/account/payouts';
    if (request.pageSize) {
      url += `${url.includes('?') ? '&' : '?'}pageSize=${request.pageSize}`;
    }
    if (request.ascending !== undefined) {
      url += `${url.includes('?') ? '&' : '?'}ascending=${request.ascending}`;
    }
    if (request.startTimestamp || request.endTimestamp) {
      url += `${url.includes('?') ? '&' : '?'}startTimestamp=${
        request.startTimestamp || 0
      }&endTimestamp=${
        request.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }
    if (request.payoutId) {
      url += `${url.includes('?') ? '&' : '?'}payoutId=${request.payoutId}`;
    }
    if (request.subAccountId) {
      url += `${url.includes('?') ? '&' : '?'}accountId=${
        request.subAccountId
      }`;
    }
    if (request.payoutsStartAfter) {
      url += `${url.includes('?') ? '&' : '?'}payoutsStartAfter=${
        request.payoutsStartAfter
      }`;
    }
    try {
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`An error occurred listing payouts:`, e);
    }
  };

  useEffect(() => {
    if (response && response?.data) {
      setPayouts(response.data);
      setPayoutsState((prevState: payoutsStateShape) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: response.data.items,
          paginationToken: response.data.paginationToken,
          settings: response.data.settings,
          selected: response.data.items[0],
        },
      }));
    }
  }, [response]);

  return { payouts, isLoading, error, listPayouts };
}

export { useListPayouts };
