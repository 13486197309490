import { ReactElement, useEffect } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { PayoutDetail } from '../../../components/dataGrids/PayoutDetail';
import { isMobile } from '../../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';

const PayoutRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const params: any = useParams();
  useEffect(() => {
    if (!isMobile()) history.push(`/payouts?payoutId=${params.payoutId}`);
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('PayoutsView')) {
      history.push('/');
      return;
    }
  }, [profileState]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <PayoutDetail />
    </Layout>
  );
};

export { PayoutRoute };
