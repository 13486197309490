import React, {
  ReactElement,
  FormEvent,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { TextInput } from '../../../components/forms/TextInput';
import { Group } from '../../../components/forms/Group';
import { Button } from '../../../components/forms/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { StepPosition } from '../../../components/forms/StepPosition';
import { PhoneNumber } from '../../../components/forms/PhoneNumber';
import { isEmailNonAsync } from '../../../functions/validators';
import {
  CreatePersonRequest,
  useCreatePerson,
} from '../../../hooks/useCreatePerson';
import { AddIndividualDetails } from './add-individual';
import {
  AccountAddressInput,
  EditedAccountAddress,
} from '../../../components/forms/AccountAddressInput';
import { AccountAddress } from '../../../interfaces/accountState';
import { isEditedAddressValid } from '../../../functions/accountHelpers';
import {
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { businessRolesRequired } from '../../../functions/personHelpers';

export interface AddIndividualContactDetails {
  email: string;
  phoneNumber: string;
  phoneNumberValid: boolean;
  editedAddress?: EditedAccountAddress;
}

const AddIndividualContactRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { coBrandingState, profileState, setPersonsState, accountState } =
    useGlobalDataState();

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');

  const [countryError, setCountryError] = useState<string>('');
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [lineOneError, setLineOneError] = useState<string>('');
  const [lineTwoError, setLineTwoError] = useState<string>('');
  const [cityError, setCityError] = useState<string>('');
  const [regionError, setRegionError] = useState<string>('');
  const [localStorageAddress, setLocalStorageAddress] =
    useState<AccountAddress>();
  const [editedAddress, setEditedAddress] = useState<EditedAccountAddress>();

  const { personData, isLoading, error, apiCreatePerson } = useCreatePerson();

  const [addIndividualDetails, setAddIndividualDetails] =
    useState<AddIndividualDetails>();
  const [addIndividualContactDetails, setAddIndividualContactDetails] =
    useState<AddIndividualContactDetails>();

  useEffect(() => {
    const addIndividualContactDetailsString = localStorage.getItem(
      'addIndividualContactDetails'
    );
    if (addIndividualContactDetailsString) {
      setAddIndividualContactDetails(
        JSON.parse(addIndividualContactDetailsString)
      );
    }
    const addIndividualDetailsString = localStorage.getItem(
      'addIndividualDetails'
    );
    if (addIndividualDetailsString) {
      setAddIndividualDetails(JSON.parse(addIndividualDetailsString));
    } else {
      history.push('/settings/individuals/add-individual');
      return;
    }
  }, [localStorage]);

  useEffect(() => {
    if (email && phoneNumber?.number && isEditedAddressValid(editedAddress)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [email, phoneNumber, editedAddress]);

  useEffect(() => {
    if (addIndividualContactDetails) {
      setEmail(addIndividualContactDetails.email);
      setPhoneNumber({
        number: addIndividualContactDetails.phoneNumber,
        valid: addIndividualContactDetails.phoneNumberValid,
      });
      setLocalStorageAddress({
        lineOne: addIndividualContactDetails.editedAddress?.lineOne ?? '',
        lineTwo: addIndividualContactDetails.editedAddress?.lineTwo,
        city: addIndividualContactDetails.editedAddress?.city ?? '',
        region: addIndividualContactDetails.editedAddress?.region,
        postalCode: addIndividualContactDetails.editedAddress?.postalCode ?? '',
        country: addIndividualContactDetails.editedAddress?.country ?? '',
      });
    }
  }, [addIndividualContactDetails]);

  useEffect(() => {
    if (personData) {
      localStorage.removeItem('addIndividualDetails');
      localStorage.removeItem('addIndividualContactDetails');
      setPersonsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected: personData,
        },
      }));
      const docsRequired = !!personData.verification.requiredDocuments;
      const morePersonsRequired = businessRolesRequired(
        accountState.data?.verification.persons?.required ?? [],
        personData
      );
      if (!docsRequired && !morePersonsRequired) {
        return history.push(returnTo);
      }
      const nextPage = docsRequired
        ? `/settings/individuals/edit-individual/${personData.id}/docs`
        : '/settings/individuals/assign-roles';
      history.push(constructFullPath(nextPage, isOnboarding, returnTo));
    }
  }, [personData]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !permissions.has('PersonsModify') ||
      profile.processingModel === 'Iso'
    ) {
      history.push(returnTo);
      return;
    }
  }, [isSignedIn]);

  const validateIndividual = async (): Promise<void> => {
    if (!editedAddress || !addIndividualDetails) {
      return;
    }
    setEmailError('');
    setPhoneNumberError('');

    let valid = true;

    const emailValid = isEmailNonAsync(email);
    if (!emailValid) {
      valid = false;
      setEmailError('Email is invalid');
    }
    if (!phoneNumber.valid) {
      valid = false;
      setPhoneNumberError('Phone Number is invalid');
    }
    const editedAddressValid = isEditedAddressValid(
      editedAddress,
      setCountryError,
      setPostalCodeError,
      setLineOneError,
      setLineTwoError,
      setCityError,
      setRegionError
    );
    if (!editedAddressValid) {
      valid = false;
    }

    if (!valid) {
      return;
    }
    const request: CreatePersonRequest = {
      firstName: addIndividualDetails.firstName,
      middleNames: addIndividualDetails.middleNames,
      lastName: addIndividualDetails.lastName,
      email,
      dateOfBirth: addIndividualDetails.dateOfBirth,
      countryOfBirth: addIndividualDetails.countryOfBirth,
      gender: addIndividualDetails.gender,
      nationalities: addIndividualDetails.nationalities,
      address: {
        lineOne: editedAddress.lineOne,
        lineTwo: editedAddress.lineTwo || undefined,
        city: editedAddress.city,
        region: editedAddress.region || undefined,
        postalCode: editedAddress.postalCode,
        country: editedAddress.country,
      },
      phoneNumber: phoneNumber.number,
      businessRoles: addIndividualDetails.businessRoles,
    };
    apiCreatePerson(request);
  };

  return (
    <LayoutIllustration
      maxWidth='50%'
      title={
        <>
          Add an <span>individual</span>
        </>
      }
      coBrandedTitle={
        <>
          Add an individual for <span>{coBrandingState?.data?.name}</span>{' '}
          account
        </>
      }
      subTitle={
        <>
          Contact <span>details</span>
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <StepPosition steps={3} position={2} />
        <ServerError error={error} />

        <TextInput
          label
          name='Email'
          description="This is only used for contact and WON'T invite the user to Ryft"
          value={email}
          setValue={setEmail}
          error={emailError}
        />

        <PhoneNumber
          label
          name='Phone Number'
          value={phoneNumber.number}
          setValue={setPhoneNumber}
          error={phoneNumberError}
        />

        <Group name='Address'>
          <AccountAddressInput
            input={localStorageAddress}
            countryError={countryError}
            postalCodeError={postalCodeError}
            lineOneError={lineOneError}
            lineTwoError={lineTwoError}
            cityError={cityError}
            regionError={regionError}
            setEditedAddress={setEditedAddress}
          />
        </Group>

        <div className='ModifyUserRoute--button-container'>
          <Button
            name='Submit'
            click={validateIndividual}
            loading={isLoading}
            disabled={btnDisabled}
          />
          <Button
            name='Back'
            click={(): void => {
              const addIndividualContactDetails: AddIndividualContactDetails = {
                email,
                phoneNumber: phoneNumber.number,
                phoneNumberValid: phoneNumber.valid,
                editedAddress,
              };
              localStorage.setItem(
                'addIndividualContactDetails',
                JSON.stringify(addIndividualContactDetails)
              );
              history.push(
                constructFullPath(
                  '/settings/individuals/add-individual',
                  isOnboarding,
                  returnTo
                )
              );
            }}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { AddIndividualContactRoute };
