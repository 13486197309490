import React, { useEffect } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { Card } from '../components/layouts/Card';
import { Ticket } from '../components/onboarding/Ticket';
import { useHistory } from 'react-router-dom';
import { handleNotSignedIn } from '../functions/helpers';

const ContactRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='ContactRoute'>
        <div className='ContactRoute--center'>
          <div className='ContactRoute--title'>Contact</div>
          <div className='ContactRoute--support'>
            <Card title='Support'>
              <Ticket />
            </Card>
          </div>
          <div>
            <Card title='Reach Out'>
              <div className='ContactRoute--call-email'>
                <p>
                  <span>Phone: </span>
                  <a href='tel:+442088956678'>+44 (0)20 8895 6678</a>
                </p>
                <p>
                  <span>Support: </span>
                  <a href='mailto:support@ryftpay.com'>support@ryftpay.com</a>
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { ContactRoute };
