import { Dispute, DisputeStatus } from '../../interfaces/disputeState';
import React, { ReactElement, useState, useEffect } from 'react';
import { CreateStatus } from '../layouts/CreateStatus';
import { useHistory, useLocation } from 'react-router-dom';
import { SideMenu } from '../layouts/SideMenu';
import { Button } from '../forms/Button';
import { DateTime } from '../layouts/DateTime';
import {
  camelCaseToWords,
  epochSecondsToDate,
  formatPrice,
} from '../../functions/helpers';
import { Copy } from '../forms/Copy';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import {
  getReasonCodeDetail,
  ReasonCodeDetail,
} from '../../hooks/getReasonCodeDetail';
import Customer from '../sharedTabs/Customer';
import Payments from '../sharedTabs/Payments';
import useTransformDispute, {
  EvidenceItem,
} from '../../hooks/useTransformDispute';
import useGetFileById from '../../hooks/useGetFileById';
import { UserPermission } from '../../interfaces/profileState';
import SubAccount from '../sharedTabs/SubAccount';
import { RequiredActions } from '../layouts/RequiredActions';
import { getCurrency } from '../../functions/money-utility';
import { getDisputeStatusTitle } from '../../hooks/getDisputeDescriptions';

interface DisputeProps {
  disputeArr: Dispute[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  isFiltering: boolean;
}

const handleDisputeStatus = (
  status: DisputeStatus
): ReactElement | undefined => {
  switch (status) {
    case 'Open':
      return <CreateStatus title='Open' color='RED' icon='EXCLAMATION' />;
    case 'Cancelled':
      return (
        <CreateStatus title='Cancelled' color='DARKGREY' icon='CANCELLED' />
      );
    case 'Accepted':
      return <CreateStatus title='Accepted' color='GREEN' icon='EXCLAMATION' />;
    case 'Challenged':
      return <CreateStatus title='Challenged' color='YELLOW' icon='CLOCK' />;
    case 'Lost':
      return <CreateStatus title='Lost' color='RED' icon='CROSS' />;
    case 'Won':
      return <CreateStatus title='Won' color='GREEN' icon='CHECK' />;
    case 'Expired':
      return <CreateStatus title='Expired' color='DARKGREY' icon='CROSS' />;
    default:
      return undefined;
  }
};

interface DisputeDetailsProps {
  dispute: Dispute;
  canModifyDispute: boolean;
}

const DisputeDetails = ({
  dispute,
  canModifyDispute,
}: DisputeDetailsProps): any => {
  const history = useHistory();
  const reasonCodeDetail: ReasonCodeDetail | null = getReasonCodeDetail(
    dispute.paymentSession.paymentMethod.card.scheme,
    dispute.reason.code
  );

  const getActionView = (): ReactElement | undefined => {
    const title = getDisputeStatusTitle(dispute.status);
    if (
      dispute.status === 'Expired' ||
      dispute.status === 'Lost' ||
      dispute.status === 'Accepted'
    ) {
      return (
        <RequiredActions
          requiredActions={[]}
          title={title}
          subTitle={`The disputed amount of ${formatPrice({
            pence: dispute.amount,
            currency: getCurrency(dispute.currency),
          })} and scheme fee will not be returned to you. No further action is required.`}
          contactSupportLink='/contact'
          showButton={false}
          buttonDisabled={true}
          docsLink='htp'
          padTitle={false}
        />
      );
    }
    if (dispute.status === 'Won') {
      return (
        <RequiredActions
          requiredActions={[]}
          title={title}
          subTitle={`The disputed amount of ${formatPrice({
            pence: dispute.amount,
            currency: getCurrency(dispute.currency),
          })} will be returned to you minus any fees`}
          contactSupportLink='/contact'
          showButton={false}
          buttonDisabled={true}
          padTitle={false}
        />
      );
    }
    if (dispute.status === 'Challenged') {
      return (
        <RequiredActions
          requiredActions={[]}
          title={title}
          contactSupportLink='/contact'
          showButton={false}
          buttonDisabled={true}
          docsLink='htp'
          padTitle={false}
        />
      );
    }
  };

  return (
    <div>
      {getActionView()}
      <div className='DisputeTab--title-grid'>{dispute.category}</div>
      <table className='DisputeTab--table'>
        <tbody>
          <tr>
            <td>Status</td>
            <td>{handleDisputeStatus(dispute.status)}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>
              {formatPrice({
                pence: dispute.amount || 0,
                currency: getCurrency(dispute.currency),
              })}
            </td>
          </tr>
          <tr>
            <td>When</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(dispute.createdTimestamp)}
              />
            </td>
          </tr>
          <tr>
            <td>Respond By</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(dispute.respondBy)}
              />
            </td>
          </tr>
          <tr>
            <td>Reason Code</td>
            <td>{dispute.reason.code}</td>
          </tr>
          <tr>
            <td>Reason Description</td>
            <td>{dispute.reason.description}</td>
          </tr>
          <tr>
            <td>Last Updated</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(dispute.lastUpdatedTimestamp)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className='DisputeTab--title-grid'>Prevention Advice</div>
      <div className='DisputeTab--singleLine'>
        {reasonCodeDetail?.preventionSteps.map(
          (step: string, index: number) => (
            <div className='DisputeTab--singleLine-item' key={index}>
              {step}
            </div>
          )
        )}
      </div>
      {dispute.status === 'Open' && canModifyDispute && (
        <Button
          name='Accept Dispute'
          click={(): void => history.push(`/disputes/accept/${dispute.id}`)}
        />
      )}
    </div>
  );
};

interface EvidenceProps {
  dispute: Dispute;
  canModifyDispute: boolean;
}

const Evidence = ({ dispute, canModifyDispute }: EvidenceProps): any => {
  const history = useHistory();
  const data = useTransformDispute(dispute);
  const canAmendEvidence = canModifyDispute && dispute.status === 'Open';

  const getActionView = (): ReactElement | undefined => {
    if (dispute.status === 'Challenged') {
      return (
        <RequiredActions
          requiredActions={[]}
          title={
            'Dispute has been challenged. You can no longer make any amendments.'
          }
          contactSupportLink='/contact'
          showButton={false}
          buttonDisabled={true}
          padTitle={false}
        />
      );
    }
    if (dispute.status === 'Open') {
      const title = dispute.evidence
        ? 'Evidence attached. You may add further evidence or challenge this dispute now.'
        : 'To challenge this dispute, add the recommended evidence below.';
      return (
        <RequiredActions
          requiredActions={[]}
          title={title}
          contactSupportLink='/contact'
          showButton={true}
          buttonDisabled={!dispute.evidence}
          buttonText='Challenge'
          buttonLink={(): void =>
            history.push(`/disputes/challenge/${dispute.id}`)
          }
          docsLink='https://developer.ryftpay.com/docs/risk-management/disputes/responding-to-disputes/#challenging-a-dispute'
          padTitle={false}
        />
      );
    }
  };

  return (
    <div className='Evidence'>
      {getActionView()}
      <h2 className='Evidence--title'>Recommended Evidence</h2>
      <p className='Evidence--subtitle'>
        Attach the evidence below for a stronger case.
      </p>
      <div className='Evidence--section'>
        {data?.recommendedEvidence.map((evidence, idx) => (
          <EvidenceRow
            key={idx}
            evidence={evidence}
            disputeId={dispute.id}
            canAmendEvidence={canAmendEvidence}
          />
        ))}
      </div>

      <h2 className='Evidence--title'>Optional Evidence</h2>
      <p className='Evidence--subtitle'>
        Evidence below typically doesn't aid your case.
      </p>
      <div className='Evidence--section'>
        {data?.optionalEvidence.map((evidence, idx) => (
          <EvidenceRow
            key={idx}
            evidence={evidence}
            disputeId={dispute.id}
            canAmendEvidence={canAmendEvidence}
          />
        ))}
      </div>
    </div>
  );
};

type EvidenceRowProps = {
  evidence: EvidenceItem;
  disputeId: string;
  canAmendEvidence: boolean;
};

const EvidenceRow = ({
  evidence,
  disputeId,
  canAmendEvidence,
}: EvidenceRowProps): any => {
  const history = useHistory();
  const { file, isLoading, error } = useGetFileById(evidence.fileId);
  return (
    <div className='Evidence--row'>
      <div className='Evidence--col'>
        <span className='Evidence--col-name'>
          {camelCaseToWords(evidence.type)}
        </span>
        {!file && isLoading && (
          <span className='Evidence--col-filename'>Loading</span>
        )}
        {file && !isLoading && (
          <span className='Evidence--col-filename'>{file.name}</span>
        )}
        {evidence.text && (
          <span className='Evidence--col-txt'>{evidence.text}</span>
        )}
        {(error || (evidence.fileId && !file && !isLoading)) && (
          <span>{error || 'Failed to get file name'}</span>
        )}
      </div>
      {canAmendEvidence && (
        <Button
          name={evidence.fileId || evidence.text ? 'Edit' : 'Add'}
          margin='0'
          click={(): void => {
            history.push(
              `/disputes/add-evidence/${disputeId}?evidenceType=${
                evidence.type
              }&edit=${
                evidence.fileId || evidence.text ? 'Edit' : 'Add'
              }&allowed=${evidence.allowed}`
            );
          }}
        />
      )}
    </div>
  );
};

const Disputes = ({
  disputeArr,
  isMore,
  getMore,
  isLoading,
  isFiltering,
}: DisputeProps): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [canModifyDispute, setCanModifyDispute] = useState(false);
  const { setDisputeState, disputeState, profileState } = useGlobalDataState();
  const [activeTab, setActiveTab] = useState('Dispute Details');

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      DisputesModify: setCanModifyDispute,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const handleTabChange = (tabName: string): void => setActiveTab(tabName);

  const scrollToSelectedItem = (): void => {
    if (disputeState.data?.selected) {
      const selectedItemElement = document.getElementById(
        `dispute-${disputeState.data?.selected.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = disputeArr.findIndex(
      (dispute) => dispute.id === disputeState.data?.selected?.id
    );
    if (currentIndex > 0) {
      handleSelectedDispute(disputeArr[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = disputeArr.findIndex(
      (dispute) => dispute.id === disputeState.data?.selected?.id
    );
    if (currentIndex < disputeArr.length - 1) {
      handleSelectedDispute(disputeArr[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && disputeState.data?.selected) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [disputeState.data?.selected]);

  const setSelected = (id: string): void => {
    const dispute = disputeArr.find((dispute) => dispute.id === id);
    setDisputeState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: dispute,
      },
    }));
  };

  const handleCloseSideMenu = (): void => {
    setDisputeState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: null,
      },
    }));
    history.push(`/disputes`);
  };

  const handleSelectedDispute = (id: string): void => {
    setSelected(id);
    history.push(`/disputes?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setDisputeState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected: null,
        },
      }));
    }
  }, [location.search, disputeArr]);

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setDisputeState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected: null,
        },
      }));
    }
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab('Dispute Details');
    }
  }, [location.search]);

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='Disputes'>
      {disputeArr.length > 0 && (
        <>
          <div className='Disputes--heading'>
            <div>Status</div>
            <div>Amount</div>
            <div>Scheme</div>
            <div>Category</div>
            <div>Respond by</div>
            <div>When</div>
          </div>
          <div className='Disputes--scroll' id='scrollArea'>
            {disputeArr.map((dispute) => (
              <div
                id={`dispute-${dispute.id}`}
                key={dispute.id}
                onClick={(): void => handleSelectedDispute(dispute.id)}
                className={
                  dispute.id === disputeState.data?.selected?.id
                    ? 'Disputes--item Disputes--selected'
                    : 'Disputes--item'
                }
              >
                <div className='Disputes--status'>
                  {handleDisputeStatus(dispute.status)}
                </div>

                <div className='Disputes--txt'>
                  {formatPrice({
                    pence: dispute.amount || 0,
                    currency: getCurrency(dispute.currency),
                  })}
                </div>
                <div className='Disputes--txt'>
                  {dispute.paymentSession.paymentMethod.card.scheme}
                </div>
                <div className='Disputes--txt'>{dispute.category}</div>
                <div className='Disputes--txt'>
                  <DateTime value={epochSecondsToDate(dispute.respondBy)} />
                </div>
                <div className='Disputes--txt'>
                  <DateTime
                    value={epochSecondsToDate(dispute.createdTimestamp)}
                  />
                </div>
              </div>
            ))}
            {isLoading && (
              <div style={{ textAlign: 'center', padding: '12px' }}>
                Loading...
              </div>
            )}
          </div>
        </>
      )}

      {disputeArr.length === 0 && (
        <div className='Disputes--no-results'>
          {!isFiltering && !isLoading && (
            <>
              <h2>No disputes</h2>
              <p>You don't have any disputes yet.</p>
              <br />
              <p>
                When you receive your first dispute, it will be displayed here.
              </p>
              <a
                href='https://developer.ryftpay.com/docs/risk-management/disputes'
                target='_blank'
                rel='nofollow'
              >
                <Button name='View Ryft Docs' />
              </a>
            </>
          )}
          {isFiltering && !isLoading && (
            <>
              <h2>No results</h2>
              <p>Please clear or adjust your filter</p>
            </>
          )}
        </div>
      )}
      <SideMenu
        isOpen={!!disputeState.data?.selected}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div style={{ height: '100%' }}>
          <div className='Disputes--title-grid'>
            <div className='Disputes--title'>
              {disputeState.data?.selected?.reason.description}

              <br />
              <span className='DisputeTab--id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={disputeState.data?.selected?.id || ''}
                  text={disputeState.data?.selected?.id || ''}
                  direction='DOWN'
                />
              </span>
            </div>
            <div className='Disputes--esc'>Esc</div>
          </div>
          <div className='Disputes--nav-grid'>
            <div className='Disputes--tab-group'>
              <button
                className={
                  activeTab === 'Dispute Details'
                    ? 'Disputes--tab-active'
                    : 'Disputes--tab'
                }
                onClick={(): void => handleTabChange('Dispute Details')}
              >
                Details
              </button>
              <button
                className={
                  activeTab === 'Evidence'
                    ? 'Disputes--tab-active'
                    : 'Disputes--tab'
                }
                onClick={(): void => handleTabChange('Evidence')}
              >
                Evidence
              </button>
              {disputeState.data?.selected?.customer?.id && (
                <button
                  className={
                    activeTab === 'Customer'
                      ? 'Disputes--tab-active'
                      : 'Disputes--tab'
                  }
                  onClick={(): void => handleTabChange('Customer')}
                >
                  Customer
                </button>
              )}
              {disputeState.data?.selected?.paymentSession?.paymentMethod && (
                <button
                  className={
                    activeTab === 'Payment'
                      ? 'Disputes--tab-active'
                      : 'Disputes--tab'
                  }
                  onClick={(): void => handleTabChange('Payment')}
                >
                  Payment
                </button>
              )}
              {disputeState.data?.selected?.subAccount && (
                <button
                  className={
                    activeTab === 'Sub Account'
                      ? 'Disputes--tab-active'
                      : 'Disputes--tab'
                  }
                  onClick={(): void => handleTabChange('Sub Account')}
                >
                  Sub Account
                </button>
              )}
            </div>
            <div>
              <button
                className='Disputes--btn-back'
                onClick={handleBack}
                disabled={
                  !disputeState.data?.selected ||
                  disputeArr.findIndex(
                    (dispute) => dispute.id === disputeState.data?.selected?.id
                  ) === 0
                }
              />

              <button
                className='Disputes--btn-next'
                onClick={handleNext}
                disabled={
                  !disputeState.data?.selected ||
                  disputeArr.findIndex(
                    (dispute) => dispute.id === disputeState.data?.selected?.id
                  ) ===
                    disputeArr.length - 1
                }
              />
            </div>
          </div>

          <div className='Disputes--display-grid'>
            <div>
              {activeTab === 'Dispute Details' &&
                disputeState.data?.selected && (
                  <DisputeDetails
                    dispute={disputeState.data.selected}
                    canModifyDispute={canModifyDispute}
                  />
                )}

              {activeTab === 'Evidence' && disputeState.data?.selected && (
                <Evidence
                  dispute={disputeState.data.selected}
                  canModifyDispute={canModifyDispute}
                />
              )}

              {activeTab === 'Customer' &&
                disputeState.data?.selected?.customer && (
                  <Customer id={disputeState.data.selected.customer.id} />
                )}
              {activeTab === 'Payment' &&
                disputeState.data?.selected?.paymentSession && (
                  <Payments
                    id={disputeState.data.selected.paymentSession.id}
                    subAccountId={disputeState.data.selected.subAccount?.id}
                  />
                )}
              {activeTab === 'Sub Account' &&
                disputeState.data?.selected?.subAccount && (
                  <SubAccount id={disputeState.data.selected.subAccount.id} />
                )}
            </div>
          </div>
        </div>
      </SideMenu>
    </div>
  );
};

export { Disputes };
