import React, { ReactElement } from 'react';
import { PaginationButton } from './PaginationButton';
import { Filter } from './Filter';
import { DateFilter } from './DateFilter';
import { AccountSettingsProfile } from '../../interfaces/profileState';

export interface PropsShape {
  title?: string;
  group?: 'STANDARD' | 'SUB';
  accountSettings?: AccountSettingsProfile;
  titleRight?: 'BUTTON' | 'TEXT' | 'FILTER' | 'DATE' | 'NONE';
  titleRightText?: string;
  click?: (e: React.MouseEvent) => void;
  filterHideDate?: boolean;
  filterValue?: (filterData: {
    report: boolean;
    eventId: string;
    feeId: string;
    sessionId: string;
    accountId: string;
    status: string;
    startDate: number;
    endDate: number;
  }) => {};
  children: ReactElement | ReactElement[];
  filter?: 'PAYMENTS' | 'DISPUTES' | 'FEES' | 'EVENTS';
  footer?: 'MUST' | 'NONE' | 'PAGINATED' | 'TEXT' | 'BUTTON' | any;
  footerText?: string;
  footerClick?: (e: React.MouseEvent) => void;
  footerRight?: 'BUTTON' | 'TEXT' | 'NONE';
  footerRightText?: string;
  footerRightClick?: (e: React.MouseEvent) => void;
  footerRightClickEnabled?: boolean;
  paginationInfo?: {
    count: number;
    term: string;
    hasMore: boolean;
    clickPrev?: () => void;
    clickNext?: () => void;
    isLoadingPrev: boolean;
    isLoadingNext: boolean;
    prevDisabled: boolean;
    nextDisabled: boolean;
  };
  dateFilter?: Date;
  setDateFilter?: (d: Date) => void;
}

const Card = ({
  title,
  group,
  accountSettings,
  titleRight = 'NONE',
  titleRightText,
  click,
  children,
  footer = 'NONE',
  filter,
  filterHideDate = false,
  filterValue,
  footerText,
  footerClick,
  footerRight = 'NONE',
  footerRightText,
  footerRightClick,
  footerRightClickEnabled,
  paginationInfo,
  dateFilter,
  setDateFilter,
}: PropsShape): ReactElement => {
  return (
    <div className='Card'>
      <div className={title ? 'Card--title' : 'Card--title-none'}>
        <div className={title ? 'Card--title-left' : 'Card--title-left-none'}>
          {title}
        </div>
        <div className='Card--title-right'>
          {titleRight === 'DATE' && (
            <DateFilter value={dateFilter} setValue={setDateFilter} />
          )}
          {titleRight === 'FILTER' && filterValue && (
            <Filter
              filterFor={filter}
              setValue={filterValue}
              group={group}
              accountSettings={accountSettings}
              hideDate={filterHideDate || false}
            />
          )}
          {titleRight === 'TEXT' && titleRightText}
          {titleRight === 'BUTTON' && (
            <button
              id={titleRightText}
              onClick={click}
              className='Card--button'
            >
              {titleRightText}
            </button>
          )}
        </div>
      </div>
      {children}
      {footer !== 'NONE' && (
        <div className='Card--footer'>
          {footer === 'MUST' && (
            <div className='Card--footer-left'>
              <div className='Card--footer-must'>
                You must <b>change</b> your bank details
              </div>
            </div>
          )}
          {footer === 'TEXT' && footerText && (
            <div className='Card--footer-left'>
              <>{footerText}</>
            </div>
          )}
          {footer === 'BUTTON' && (
            <div className='Card--footer-left'>
              <button id={footerText} onClick={footerClick}>
                {footerText}
              </button>
            </div>
          )}
          {footer === 'PAGINATED' && paginationInfo && (
            <>
              <div className='Card--footer-paginated'>
                <div>
                  Showing {paginationInfo.count} {paginationInfo.term}
                </div>
                {paginationInfo.hasMore && (
                  <div className='Card--footer-navigation-container'>
                    <div className='Card--footer-navigation'>
                      <PaginationButton
                        type='prev'
                        click={paginationInfo.clickPrev}
                        loading={paginationInfo.isLoadingPrev}
                        disabled={paginationInfo.prevDisabled}
                      ></PaginationButton>
                      <PaginationButton
                        type='next'
                        click={paginationInfo.clickNext}
                        loading={paginationInfo.isLoadingNext}
                        disabled={paginationInfo.nextDisabled}
                      ></PaginationButton>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {footerRight !== 'NONE' && (
            <div className='Card--footer-right' style={{ margin: '12px' }}>
              {footerRight === 'TEXT' && footerRightText}
              {footerRight === 'BUTTON' && (
                <button
                  id={footerRightText}
                  onClick={footerRightClick}
                  disabled={!footerRightClickEnabled}
                >
                  {footerRightText}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export { Card };
