import { DashboardData } from '../interfaces/dashboardState';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface DashboardApi {
  isLoading: boolean;
  error: string | undefined;
  data: DashboardData | undefined;
  request: (startDate: Date, endDate: Date) => Promise<void>;
}

const useGetDashboardData = (): DashboardApi => {
  const [response, isLoading, error, sendRequest] =
    useApiRequest<DashboardData>();

  const formatDate = (date: Date): string => {
    const newDate = new Date(date.getTime());
    const dateOffset = date.getTimezoneOffset() * 60 * 1000;
    if (dateOffset < 0) {
      newDate.setTime(newDate.getTime() + Math.abs(dateOffset));
    }
    if (dateOffset > 0) {
      newDate.setTime(newDate.getTime() - dateOffset);
    }
    return newDate.toISOString().split('T')[0];
  };

  const fetchDashboardData = async (
    startDate: Date,
    endDate: Date
  ): Promise<void> => {
    // Update local time so it matches UTC
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const url = `/v1/dashboard?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const method = HttpMethod.GET;
    return await sendRequest({ url, method });
  };

  return {
    isLoading: isLoading,
    error: error,
    data: response?.data,
    request: fetchDashboardData,
  };
};

export { useGetDashboardData };
