import React from 'react';
import { SubmitPassword } from '../components/onboarding/Password';
import { LayoutIllustration } from '../layout-illustration';
import resetPassword from '../images/illustrations/reset-password.png';

const PasswordRoute = (): React.ReactElement => {
  return (
    <LayoutIllustration
      image={resetPassword}
      title={
        <>
          Reset your <span>password</span>
        </>
      }
      coBrandedTitle={
        <>
          Reset your <span>password</span>
        </>
      }
      subTitle={
        <>
          Enter your new password and the <span>code</span> we emailed you.
        </>
      }
    >
      <SubmitPassword />
    </LayoutIllustration>
  );
};

export { PasswordRoute };
