import { ReactElement, useEffect } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { PaymentDetail } from '../../../components/dataGrids/PaymentDetail';
import { isMobile } from '../../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { PaymentsShape } from '../../../interfaces/paymentState';
import { useGetTransactions } from '../../../hooks/useGetTransactions';

const PaymentRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState, paymentsState } = useGlobalDataState();
  const [, , , setFilter, , ,] = useGetTransactions();
  const params: any = useParams();
  useEffect(() => {
    if (!isMobile()) history.push(`/payments?id=${params.paymentId}`);
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('TransactionsView')) {
      history.push('/');
      return;
    }
  }, [profileState]);

  useEffect(() => {
    setFilter({
      pageSize: 1,
      ascending: false,
      paymentSessionsStartAfter: '',
      paymentSessionsStartTimestamp: '',
      paymentSessionsEndTimestamp: '',
      platformFeesStartAfter: '',
      platformFeesStartTimestamp: '',
      platformFeesEndTimestamp: '',
      paymentId: params.paymentId,
      platformFeeId: '',
      accountId: '',
      customerId: '',
    });
  }, []);

  return (
    <Layout isSignedIn={isSignedIn}>
      <PaymentDetail
        payment={paymentsState.data?.items.find(
          (payment: PaymentsShape) => payment.id === params.paymentId
        )}
        hasPrevious={false}
        handlePrevious={(): void => {}}
        hasNext={false}
        handleNext={(): void => {}}
      />
    </Layout>
  );
};

export { PaymentRoute };
