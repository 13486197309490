import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { DatePicker } from '../forms/DatePicker';
import { TextInput } from '../forms/TextInput';
import { SelectInput } from '../forms/SelectInput';
import { epochSecondsToDate } from '../../functions/helpers';
import { TransactionsFilter } from '../../hooks/useGetTransactions';
import { useGlobalState } from '../../hooks/useGlobalState';
import { EmailToCustomer } from '../forms/EmailToCustomer';
import { useHistory } from 'react-router-dom';
import { Customer } from '../../interfaces/customersState';

interface FilterPaymentsProps {
  setFilter: (filter: TransactionsFilter) => void;
}

const FilterPayments = ({ setFilter }: FilterPaymentsProps): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const searchParams = new URLSearchParams(location.search);
  const [ascending, setAscending] = useState<boolean>(false);
  const [startsAfter, setStartsAfter] = useState<string>('');
  const [startTimestamp, setStartTimestamp] = useState<string>('');
  const [endTimestamp, setEndTimestamp] = useState<string>('');
  const [paymentId, setPaymentId] = useState<string>(
    searchParams.get('id') || ''
  );
  const [accountId, setAccountId] = useState<string>(
    searchParams.get('accountId') || ''
  );
  const [customerId, setCustomerId] = useState<string>(
    searchParams.get('customerId') || ''
  );
  const [customer, setCustomer] = useState<Customer>();
  const [accountType, setAccountType] = useState<string>(
    searchParams.get('accountType') || 'myAccount'
  );

  const handleApplyFilter = (): void => {
    const filter: TransactionsFilter = {
      ascending,
      pageSize: 25,
      paymentSessionsStartAfter: startsAfter,
      paymentSessionsStartTimestamp: startTimestamp,
      paymentSessionsEndTimestamp: endTimestamp,
      platformFeesStartAfter: '',
      platformFeesStartTimestamp: '',
      platformFeesEndTimestamp: '',
      paymentId: paymentId,
      platformFeeId: '',
      accountId: accountId,
      customerId: customerId,
    };
    setFilter(filter);
  };

  useEffect(() => {
    handleApplyFilter();
  }, [
    ascending,
    startsAfter,
    startTimestamp,
    endTimestamp,
    paymentId,
    accountId,
    customerId,
  ]);

  useEffect(() => {
    if (customer) {
      setCustomerId(customer.id);
    }
  }, [customer]);

  const handleClearFilter = (): void => {
    setAscending(false);
    setStartsAfter('');
    setStartTimestamp('');
    setEndTimestamp('');
    setPaymentId('');
    setAccountId('');
    setCustomerId('');
    history.push('/payments');
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <DatePicker
        type='START'
        name='Start Date'
        value={parseInt(startTimestamp)}
        notAfter={
          endTimestamp ? epochSecondsToDate(parseInt(endTimestamp)) : new Date()
        }
        label
        setValue={(e): void => {
          if (e) {
            setStartTimestamp(e.toString());
          }
        }}
      />

      <DatePicker
        label
        type='END'
        name='End Date'
        value={parseInt(endTimestamp)}
        notBefore={
          startTimestamp
            ? epochSecondsToDate(parseInt(startTimestamp))
            : undefined
        }
        notAfter={new Date()}
        setValue={(e): void => {
          if (e) {
            setEndTimestamp(e.toString());
          }
        }}
      />
      {profile.group === 'STANDARD' && (
        <>
          <SelectInput
            label
            name='Account type'
            value={accountType}
            setValue={setAccountType}
            options={[
              { value: 'myAccount', name: 'My Account' },
              { value: 'subAccounts', name: 'Sub-accounts' },
            ]}
          />
          {accountType === 'myAccount' && (
            <>
              <TextInput
                name='Customer id'
                label
                value={customerId}
                setValue={setCustomerId}
                placeHolder='cus_'
              />
              <EmailToCustomer
                title='Customer email'
                customer={customer}
                setCustomer={setCustomer}
              />
            </>
          )}
          {accountType === 'subAccounts' && (
            <>
              <TextInput
                name='Account id'
                label
                value={accountId}
                setValue={setAccountId}
                placeHolder='ac_'
              />
            </>
          )}
        </>
      )}
      <TextInput
        name='Payment session id'
        label
        value={paymentId}
        setValue={setPaymentId}
        placeHolder='ps_'
      />
      <h2
        style={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
        }}
      >
        Sort order
      </h2>
      <SelectInput
        name='Sort Order'
        value={ascending ? 'true' : 'false'}
        setValue={(e): void => setAscending(e === 'true')}
        options={[
          { value: 'true', name: 'Ascending' },
          { value: 'false', name: 'Descending' },
        ]}
      />

      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};
export { FilterPayments };
