import { PaymentTransaction } from '../interfaces/paymentState';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface VoidPaymentSessionApi {
  isLoading: boolean;
  error: string | undefined;
  data: PaymentTransaction | undefined;
  request: (id: string) => Promise<void>;
}

const useVoidPaymentSession = (): VoidPaymentSessionApi => {
  const [response, isLoading, error, sendRequest] =
    useApiRequest<PaymentTransaction>();

  const voidPaymentSession = async (id: string): Promise<void> => {
    const url = `/v1/payment-sessions/${id}/voids`;
    const method = HttpMethod.POST;
    return await sendRequest({ url, method });
  };

  return {
    isLoading: isLoading,
    error: error,
    data: response?.data,
    request: voidPaymentSession,
  };
};

export { useVoidPaymentSession };
