import { ReactElement, useEffect, useState } from 'react';

export interface PropsShape {
  children?: ReactElement;
  fetchStringFileContents: () => Promise<string>;
  suffix?: 'cer' | 'csv' | 'json' | undefined;
  name: string;
}

const DownloadFile = ({
  children,
  fetchStringFileContents,
  suffix,
  name,
}: PropsShape): ReactElement => {
  const [stringFileContents, setStringFileContents] = useState<string>();

  useEffect(() => {
    if (stringFileContents) {
      doDownload(stringFileContents);
    }
  }, [stringFileContents]);

  let type: string;

  switch (suffix) {
    case 'cer':
      type = 'application/pkcs10';
      break;
    case 'csv':
      type = 'text/csv';
      break;
    case 'json':
      type = 'application/json';
      break;
    default:
      type = 'octet/stream';
      break;
  }

  const fetchFileContents = async (): Promise<void> => {
    setStringFileContents(await fetchStringFileContents());
  };

  const doDownload = (contents: string): void => {
    const blob = new Blob([contents], { type });
    const element = document.createElement('a');
    element.hidden = true;
    element.href = URL.createObjectURL(blob);
    element.download = suffix ? `${name}.${suffix}` : `${name}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className='FileInput' onClick={fetchFileContents}>
      {children}
    </div>
  );
};
export { DownloadFile };
