import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { Profile, profileStateShape } from '../interfaces/profileState';
import { useGlobalDataState } from './useGlobalDataState';
import { useGlobalState } from './useGlobalState';

interface UseEditProfileLoginReturnType {
  profileData: Profile | null;
  isLoading: boolean;
  error: string | undefined;
  apiEditProfileLogin: () => Promise<void>;
}

function useEditProfileLogin(): UseEditProfileLoginReturnType {
  const { setProfileState } = useGlobalDataState();
  const { setIsSignedIn } = useGlobalState();
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest<Profile>();

  const [profileData, setProfileData] = useState<Profile | null>(null);

  const apiEditProfileLogin = async (): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/profile',
        method: HttpMethod.PATCH,
        body: { loggedIn: true },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while editing the profile:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setProfileData(apiResponse.data);
      setProfileState((prevState: profileStateShape) => ({
        ...prevState,
        data: apiResponse.data,
      }));
      setIsSignedIn(true);
    }
  }, [apiResponse]);

  return {
    profileData,
    isLoading,
    error,
    apiEditProfileLogin,
  };
}

export { useEditProfileLogin };
