import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { Button } from '../../../components/forms/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { BusinessRole } from '../../../interfaces/accountState';
import {
  businessRoleDescription,
  businessRoleRequired,
  businessRoleToString,
  hasPersonWithRole,
} from '../../../functions/personHelpers';
import {
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { PersonShape } from '../../../interfaces/personsState';
import { RadioButtons } from '../../../components/forms/RadioButtons';
import { useEditPerson } from '../../../hooks/useEditPerson';
import { useGetPersons } from '../../../hooks/useGetPersons';
import { StepPosition } from '../../../components/forms/StepPosition';
import { OptionShape } from '../../../interfaces/state';

const AssignRolesRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const addNewIndividualValue: string = 'add';

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const steps: number = isOnboarding ? 2 : 3;
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { accountState, profileState, personsState } = useGlobalDataState();

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [requiredRole, setRequiredRole] = useState<BusinessRole>();
  const [requiredRoles, setRequiredRoles] = useState<BusinessRole[]>([]);
  const [position, setPosition] = useState<number>(0);
  const [selectedPersonId, setSelectedPersonId] = useState<string>();
  const [addNewIndividualSelected, setAddNewIndividualSelected] =
    useState<boolean>(false);

  const {
    personData: updatedPerson,
    isLoading: editPersonLoading,
    error: editPersonError,
    apiEditPerson,
  } = useEditPerson();

  const {
    isLoading: getPersonsLoading,
    error: getPersonsError,
    fetchPersons,
  } = useGetPersons();

  useEffect(() => {
    if (updatedPerson) {
      const newRequiredRoles = requiredRoles.filter(
        (r: BusinessRole) => r !== requiredRole
      );
      if (newRequiredRoles.length <= 0) {
        return history.push(returnTo);
      }
      setRequiredRoles(newRequiredRoles);
      setRequiredRole(newRequiredRoles[0]);
      setPosition(position + 1);
      return;
    }
  }, [updatedPerson]);

  useEffect(() => {
    setBtnDisabled(!selectedPersonId && !addNewIndividualSelected);
  }, [selectedPersonId, addNewIndividualSelected]);

  useEffect(() => {
    if (!personsState.data?.items) {
      fetchPersons();
    }
  }, []);

  useEffect(() => {
    const persons = personsState.data?.items;
    if (persons) {
      const requiredPersons =
        accountState.data?.verification.persons?.required ?? [];
      const requiredRoles: BusinessRole[] = [];
      if (
        businessRoleRequired(requiredPersons, 'BusinessContact') &&
        !hasPersonWithRole(persons, 'BusinessContact')
      ) {
        requiredRoles.push('BusinessContact');
      }
      if (
        businessRoleRequired(requiredPersons, 'Director') &&
        !hasPersonWithRole(persons, 'Director')
      ) {
        requiredRoles.push('Director');
      }
      if (
        businessRoleRequired(requiredPersons, 'UltimateBeneficialOwner') &&
        !hasPersonWithRole(persons, 'UltimateBeneficialOwner')
      ) {
        requiredRoles.push('UltimateBeneficialOwner');
      }
      if (requiredRoles.length <= 0) {
        return history.push(returnTo);
      }
      setRequiredRole(requiredRoles[0]);
      setRequiredRoles(requiredRoles);
      setPosition(steps - requiredRoles.length + 1);
    }
  }, [personsState]);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !permissions.has('PersonsModify') ||
      profile.processingModel === 'Iso'
    ) {
      history.push(returnTo);
      return;
    }
  }, [isSignedIn]);

  const submitAssignment = async (): Promise<void> => {
    if (!requiredRole) {
      return;
    }
    if (addNewIndividualSelected) {
      return history.push(
        constructFullPath(
          `/settings/individuals/add-individual`,
          isOnboarding,
          returnTo
        )
      );
    }
    const person = personsState.data?.items.find(
      (p: PersonShape) => p.id === selectedPersonId
    );
    if (!person) {
      return;
    }
    const businessRoles: BusinessRole[] = [...person.businessRoles];
    businessRoles.push(requiredRole);
    apiEditPerson(person.id, { businessRoles });
  };

  const getOptions = (): OptionShape[] => {
    const persons = personsState?.data?.items ?? [];
    const options = persons.map((p: PersonShape) => ({
      name: `${p.firstName} ${p.lastName} (${p.businessRoles
        .map((r: BusinessRole) => businessRoleToString(r))
        .join()})`,
      value: p.id,
    }));
    options.push({
      name: 'A new individual',
      value: addNewIndividualValue,
    });
    return options;
  };

  const getSubtitle = (isOnboarding: boolean): JSX.Element => {
    if (isOnboarding) {
      return (
        <>
          We require one <span>Director</span> and one{' '}
          <span>Ultimate Beneficial Owner</span>
        </>
      );
    }
    return (
      <>
        We require one <span>Business Contact</span>, <span>Director</span> and{' '}
        <span>Ultimate Beneficial Owner</span>
      </>
    );
  };

  return (
    <LayoutIllustration
      maxWidth='50%'
      title={<>Assign roles to individuals</>}
      subTitle={getSubtitle(isOnboarding)}
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        {getPersonsLoading && !getPersonsError && <>Loading...</>}
        {getPersonsLoading && getPersonsError && (
          <ServerError error={getPersonsError} />
        )}
        {!getPersonsLoading && (
          <>
            <StepPosition steps={steps} position={position} />
            <ServerError error={editPersonError} />
            <RadioButtons
              name={
                <>
                  I want to assign the role of{' '}
                  <b>
                    {requiredRole ? businessRoleToString(requiredRole) : ''}
                  </b>{' '}
                  to:
                </>
              }
              description={
                requiredRole
                  ? `${businessRoleToString(
                      requiredRole
                    )}: ${businessRoleDescription(requiredRole)}`
                  : undefined
              }
              options={getOptions()}
              onSelect={(value: string): void => {
                if (value === addNewIndividualValue) {
                  setSelectedPersonId(undefined);
                  setAddNewIndividualSelected(true);
                  return;
                }
                setAddNewIndividualSelected(false);
                setSelectedPersonId(value);
              }}
              layout='vertical'
            />
            <div className='ModifyUserRoute--button-container'>
              <Button
                name={position === steps ? 'Submit' : 'Next'}
                click={submitAssignment}
                loading={editPersonLoading}
                disabled={btnDisabled}
              />
              <Button
                name={isOnboarding ? 'Skip' : 'Cancel'}
                click={(): void => {
                  history.push(returnTo);
                }}
                color='GREY'
              />
            </div>
          </>
        )}
      </form>
    </LayoutIllustration>
  );
};

export { AssignRolesRoute };
