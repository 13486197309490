import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { isNNumbers } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';

const ConfirmEmail = (): ReactElement => {
  const history = useHistory();
  const {
    cognito,
    cognitoUser,
    cognitoUserNotConfirmedOnSignIn,
    setCognitoUserNotConfirmedOnSignIn,
  } = useGlobalState();
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const username = localStorage.getItem('email');

  const confirm = async (): Promise<void> => {
    setCodeError('');
    setIsLoading(true);
    const isValidCode = await isNNumbers(code, 6);
    if (isValidCode) {
      const confirmed = await cognito.confirm(username, code);
      if (confirmed === 'SUCCESS') {
        setIsConfirmed(true);
      }
    }
  };

  useEffect(() => {
    // Wait for autoSignIn before redirecting - it sets cognitoUser
    if (isConfirmed && cognitoUser) {
      setIsLoading(false);
      history.push('/almost');
    }
    // If the user signed in and was not confirmed, we either get
    // autoSignIn_failure events or nothing at all so we need them to sign in again
    if (isConfirmed && cognitoUserNotConfirmedOnSignIn) {
      setIsLoading(false);
      // reset this before redirecting as otherwise we trigger resendSignUp() again on that page
      setCognitoUserNotConfirmedOnSignIn(false);
      history.push('/signin');
    }
  }, [isConfirmed, cognitoUser, cognitoUserNotConfirmedOnSignIn]);

  const resend = async (): Promise<void> => {
    setCodeError('');
    setIsSending(true);
    await cognito.resend(username);

    setTimeout(() => {
      setIsSending(false);
    }, 2500);
  };

  useEffect(() => {
    if (code) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [code]);

  useEffect(() => {
    setIsLoading(false);
    if (cognito.error && cognito.error.includes('confirmed'))
      history.push('/signin');
    setServerError(cognito.error);
  }, [cognito.error]);
  return (
    <div className='ConfirmEmail'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          confirm();
        }}
      >
        <StepPosition steps={4} position={2} />
        <TextInput
          name='Confirmation Code'
          value={`${code}`}
          setValue={setCode}
          error={codeError}
          placeHolder='Confirmation code'
          type='number'
        />
        <Button
          name='Confirm'
          click={confirm}
          loading={isLoading}
          disabled={btnDisabled}
        />
        <Button
          name='Resend Code'
          click={resend}
          loading={isSending}
          color='GREY'
        />
      </form>
    </div>
  );
};
export { ConfirmEmail };
