import React, { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface PropsShape {
  getMore: () => void;
  isMore: boolean;
  error?: string;
  isLoading: boolean;
  children: ReactElement[];
}

// Tied to max-height in css - 570px
const maxItemsInView: number = 15;
const scrollThreshold: string = '150px';

const ScrollTable = ({
  getMore,
  isMore,
  error,
  isLoading,
  children,
}: PropsShape): ReactElement => (
  <div id='scroll-table' className='ScrollTable'>
    <InfiniteScroll
      dataLength={children.length}
      next={getMore}
      hasMore={isMore}
      loader={<></>}
      scrollableTarget={'scroll-table'}
      scrollThreshold={scrollThreshold}
      endMessage={
        !error && !isLoading && children.length > maxItemsInView ? (
          <div style={{ textAlign: 'center', padding: '12px' }}>
            No more results
          </div>
        ) : undefined
      }
    >
      {children}
      {!isLoading && !error && children.length === 0 && (
        <div style={{ textAlign: 'center', padding: '12px' }}>No results</div>
      )}
      {isLoading && !error && (
        <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
      )}
      {error && !isLoading && (
        <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
      )}
    </InfiniteScroll>
  </div>
);
export { ScrollTable };
