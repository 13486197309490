import { ReactElement, FormEvent, useState, useEffect, useMemo } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { TextInput } from '../../../components/forms/TextInput';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { isEmailNonAsync } from '../../../functions/validators';
import { useAddUser } from '../../../hooks/useAddUser';
import { UserRole } from '../../../interfaces/userState';
import { handleNotSignedIn } from '../../../functions/helpers';

interface optionShape {
  value: string;
  name: string;
}

const AddUserRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/users';
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { coBrandingState, profileState } = useGlobalDataState();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [role, setRole] = useState<string>('Auditor');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [addUser, isLoading, isComplete, error] = useAddUser();

  useEffect(() => {
    if (error) {
      setServerError(error);
    }
    if (isComplete) {
      history.push(returnTo);
    }
  }, [error, isComplete]);

  useEffect(() => {
    setBtnDisabled(false);
    if (isEmailNonAsync(email) && firstName && lastName) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [email, firstName, lastName]);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('UsersModify')) {
      history.push('/');
      return;
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (!profileState.data || !profileState.data.user.modifiableRoles) {
      return;
    }
    const rolePermissions = profileState.data.user.modifiableRoles.find(
      (r) => r.role === role
    );
    if (!rolePermissions) {
      return;
    }
    setPermissions(rolePermissions.permissions.map((p) => p.description));
  }, [role]);

  const getRoleOptions = (): optionShape[] =>
    profileState?.data?.user.modifiableRoles.map((r) =>
      getRoleOption(r.role)
    ) ?? [];

  const getRoleOption = (role: UserRole): optionShape => ({
    value: role,
    name: role,
  });

  const validateUser = async (): Promise<void> => {
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setServerError('');
    let validated = true;
    if (!email) {
      setEmailError('Email is required');
      validated = false;
    }
    const emailValid = isEmailNonAsync(email);
    if (!emailValid) {
      setEmailError('Email is invalid');
      validated = false;
    }
    if (!firstName) {
      setFirstNameError('First Name is required');
      validated = false;
    }
    if (!lastName) {
      setLastNameError('Last Name is required');
      validated = false;
    }
    if (!validated) {
      return;
    }
    addUser({ email, firstName, lastName, role });
  };

  return (
    <LayoutIllustration
      title={
        <>
          Add a <span>teammate</span>
        </>
      }
      coBrandedTitle={
        <>
          Add a teammate to your <span>{coBrandingState?.data?.name}</span>{' '}
          account
        </>
      }
      subTitle={
        <>
          Add a teammate and let the fun <span>collaboration</span> begin!
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <ServerError error={serverError} />
        <TextInput
          label
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          focus
        />
        <TextInput
          label
          name='First Name'
          value={firstName}
          setValue={setFirstName}
          error={firstNameError}
        />
        <TextInput
          label
          name='Last Name'
          value={lastName}
          setValue={setLastName}
          error={lastNameError}
        />

        <SelectInput
          label
          name='Role'
          value={role}
          setValue={setRole}
          options={getRoleOptions()}
        />

        {permissions.length > 0 && (
          <div className='ModifyUserRoute--permissions'>
            <label
              className='ModifyUserRoute--permissions-label'
              htmlFor={'permissions'}
            >
              Permissions
            </label>
            <div className='ModifyUserRoute--permissions-grid'>
              {permissions.map((permission: string, index: number) => {
                return (
                  <div
                    className='ModifyUserRoute--permissions-grid-item'
                    key={index}
                  >
                    {permission}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className='ModifyUserRoute--button-container'>
          <Button
            name='Add Teammate'
            click={validateUser}
            loading={isLoading}
            disabled={btnDisabled}
          />
          <Button
            name='Cancel'
            click={(): void => history.push(returnTo)}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { AddUserRoute };
