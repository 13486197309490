import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { epochSecondsToDate, formatPrice } from '../../functions/helpers';
import { getDisputeStatusTitle } from '../../hooks/getDisputeDescriptions';
import {
  getCofUsageMessage,
  getEntryModeMessage,
} from '../../hooks/getPaymentDescriptions';
import {
  DisputeDetail,
  PaymentsShape,
  splitPaymentDetailItems,
} from '../../interfaces/paymentState';
import { Button } from '../forms/Button';
import { Copy } from '../forms/Copy';
import { DateTime } from '../layouts/DateTime';
import { Expander } from '../layouts/Expander';
import { RequiredActions } from '../layouts/RequiredActions';
import { Status, StatusShape } from '../layouts/Status';

interface PaymentSessionDetailsProps {
  payment: PaymentsShape;
  canVoid: boolean;
  canCapture: boolean;
  canRefund: boolean;
  isStandardAccount: boolean;
  canViewDisputes: boolean;
  accountId: string;
}

const PaymentSessionDetails = ({
  payment,
  canVoid,
  canCapture,
  canRefund,
  isStandardAccount,
  canViewDisputes,
  accountId,
}: PaymentSessionDetailsProps): any => {
  const history = useHistory();

  const showDeductions = (): boolean =>
    showProcessingFee() ||
    showPlatformFee() ||
    showRefundedAmount() ||
    showNetSplitAmount() ||
    showNetChargebackAmount() ||
    showChargebackFeeAmount();
  const showProcessingFee = (): boolean =>
    !!payment?.processingFeeAmount && payment.processingFeeAmount > 0;
  const showPlatformFee = (): boolean =>
    !!payment?.appliedNetPlatformFeeAmount &&
    payment.appliedNetPlatformFeeAmount > 0;
  const showRefundedAmount = (): boolean =>
    !!payment?.refundedAmount && payment.refundedAmount > 0;
  const showNetSplitAmount = (): boolean =>
    !!payment?.splitPaymentDetail &&
    payment.splitPaymentDetail.items.length > 0;
  const showNetChargebackAmount = (): boolean =>
    !!payment?.disputeDetail && payment.disputeDetail!.netChargebackAmount > 0;
  const showChargebackFeeAmount = (): boolean =>
    !!payment?.disputeDetail && payment.disputeDetail!.chargebackFeeAmount > 0;
  const isLiableForChargeback = (): boolean =>
    payment?.disputeDetail?.liableAccountId === payment.accountId;
  const showFundsFlow = (): boolean => {
    const noFundsFlowStatuses = [
      'PendingPayment',
      'PendingAuth',
      'PendingAction',
      'Processing',
      'Failed',
      'CardVerified',
    ];
    return !noFundsFlowStatuses.includes(payment?.detailedStatus);
  };

  const requiredActionMessage = (type: string): string => {
    switch (type) {
      case 'Redirect':
        return '3ds authentication';
      case 'Identify':
        return '3ds identification';
      case 'Challenge':
        return '3ds challenge';
      case 'PayL8rRedirect':
        return 'PayL8r application form';
      case 'PayL8rPendingApproval':
        return 'PayL8r manual underwriting';
      default:
        return 'Unknown';
    }
  };

  const getDisputeCardTitle = (detail: DisputeDetail): string => {
    const status = detail.status;
    return !status
      ? 'This payment has been disputed'
      : getDisputeStatusTitle(status);
  };

  const getDisputeCardSubTitle = (detail: DisputeDetail): string => {
    const status = detail.status;
    const amount = formatPrice({
      pence: detail.chargebackAmount,
      currency: payment.currency,
    });
    const feeAmount = formatPrice({
      pence: detail.chargebackFeeAmount,
      currency: payment.currency,
    });
    if (status === 'Expired') {
      return `The deadline to defend this dispute has passed. The disputed amount of ${amount} and fee of ${feeAmount} will not be returned to you.`;
    }
    if (status === 'Cancelled') {
      return `The dispute was cancelled (withdrawn) by the cardholder. The disputed amount of ${amount} has been returned to you (minus the fee of ${feeAmount})`;
    }
    if (status === 'Won') {
      return `The disputed amount of ${amount} has been returned to you (minus the fee of ${feeAmount})`;
    }
    if (status === 'Lost') {
      return `The disputed amount of ${amount} and fee of ${feeAmount} will not be returned to you.`;
    }
    if (status === 'Accepted') {
      return `The disputed amount of ${amount} and fee of ${feeAmount} will not be returned. No further action is required.`;
    }
    return `The disputed amount of ${amount} and fee of ${feeAmount} have been debited from your account.`;
  };

  const disputeActionPane = (
    payment: PaymentsShape
  ): ReactElement | undefined => {
    if (!isStandardAccount) {
      return;
    }
    const disputeDetail = payment.disputeDetail;
    if (disputeDetail && disputeDetail.id) {
      const title = getDisputeCardTitle(disputeDetail);
      const message = getDisputeCardSubTitle(disputeDetail);
      return (
        <RequiredActions
          requiredActions={[]}
          title={title}
          subTitle={
            <>
              {message}
              <br />
              <br />
              <b>Reason</b>: {disputeDetail.reasonDescription}
              <br />
              <b>Dispute ID</b>: {disputeDetail.id}
            </>
          }
          contactSupportLink='/contact'
          showButton={canViewDisputes}
          buttonText={'View Dispute'}
          buttonLink={(): any =>
            window.open(`/disputes?id=${payment.disputeDetail?.id}`, '_blank')
          }
          buttonDisabled={false}
          padTitle={false}
          docsLink={
            'https://developer.ryftpay.com/docs/risk-management/disputes'
          }
        />
      );
    }
  };

  return (
    <div>
      <div className='PaymentSessionTab--title-grid'></div>
      {disputeActionPane(payment)}
      <table className='PaymentSessionTab--table'>
        <tbody>
          <tr>
            <td>Status</td>
            <td>
              <Status
                status={payment?.detailedStatus?.toUpperCase() as StatusShape}
              />
            </td>
          </tr>
          {payment?.requiredAction && (
            <tr>
              <td>Required action:</td>
              <td>{requiredActionMessage(payment.requiredAction.type)}</td>
            </tr>
          )}
          {isStandardAccount && payment.accountId !== accountId && (
            <>
              <tr>
                <td>Sub Account</td>
                <td>
                  <Copy
                    type='BUTTON_WITH_TEXT'
                    text={payment.accountId}
                    value={payment.accountId}
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>Type</td>
            <td>{payment.paymentType}</td>
          </tr>
          {payment.entryMode && (
            <tr>
              <td>Entry Mode</td>
              <td>{getEntryModeMessage(payment.entryMode)}</td>
            </tr>
          )}
          {payment.credentialOnFileUsage && (
            <>
              <tr>
                <td>Transaction initiator</td>
                <td>
                  {payment.credentialOnFileUsage.initiator} (
                  {getCofUsageMessage(
                    payment.paymentType,
                    payment.credentialOnFileUsage
                  )}
                  )
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>Amount</td>
            <td>
              {formatPrice({
                pence: payment?.totalAmount || 0,
                currency: payment?.currency,
              })}
            </td>
          </tr>
          {(payment?.voidedAmount ?? 0) > 0 && (
            <tr>
              <td>Voided</td>
              <td>
                {formatPrice({
                  pence: payment?.voidedAmount || 0,
                  currency: payment?.currency,
                })}
              </td>
            </tr>
          )}
          <tr>
            <td>Captured</td>
            <td>
              {formatPrice({
                pence: payment?.capturedAmount || 0,
                currency: payment?.currency,
              })}
            </td>
          </tr>
          <tr>
            <td>When</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(payment.createdTimestamp)}
              />
            </td>
          </tr>
          <tr>
            <td>Descriptor</td>
            <td>
              {payment?.statementDescriptor.descriptor} (
              {payment?.statementDescriptor.city})
            </td>
          </tr>
          {payment?.failureReason && (
            <tr>
              <td>Failed reason:</td>
              <td style={{ textTransform: 'capitalize' }}>
                {payment?.failureReason.replaceAll('_', ' ')}
              </td>
            </tr>
          )}
          {payment?.metadata &&
            Object.entries(payment.metadata).map(([key, value]) => (
              <tr className='meta' key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          {payment?.shippingDetails && (
            <>
              <tr>
                <td>Shipping</td>
                <td>
                  {payment.shippingDetails.address.firstName}{' '}
                  {payment.shippingDetails.address.lastName}
                  {(payment.shippingDetails.address.firstName ||
                    payment.shippingDetails.address.lastName) && <br />}
                  {payment.shippingDetails.address.lineOne}
                  {payment.shippingDetails.address.lineOne && <br />}
                  {payment.shippingDetails.address.lineTwo}
                  {payment.shippingDetails.address.lineTwo && <br />}
                  {payment.shippingDetails.address.city}
                  {payment.shippingDetails.address.city && <br />}
                  {payment.shippingDetails.address.region}
                  {payment.shippingDetails.address.region && <br />}
                  {payment.shippingDetails.address.postalCode} <br />
                  {payment.shippingDetails.address.country}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {payment.rebillingDetail && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>Rebilling</div>
            <div className='PaymentDetailLayout--title-id'></div>
          </div>
          <table className='PaymentSessionTab--table'>
            <tbody>
              <tr>
                <td>Amount Variance</td>
                <td>{payment.rebillingDetail.amountVariance}</td>
              </tr>
              <tr>
                <td>Min period between payments</td>
                <td>
                  {payment.rebillingDetail.numberOfDaysBetweenPayments} days
                </td>
              </tr>
              {payment.rebillingDetail.currentPaymentNumber && (
                <tr>
                  <td>Sequence</td>
                  <td>
                    {payment.rebillingDetail.currentPaymentNumber} /{' '}
                    {payment.rebillingDetail.totalNumberOfPayments || '∞'}
                  </td>
                </tr>
              )}
              {payment.rebillingDetail.expiry && (
                <tr>
                  <td>Recurring end date</td>
                  <td>
                    <DateTime
                      showTime
                      value={epochSecondsToDate(payment.rebillingDetail.expiry)}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
      {showFundsFlow() && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>Funds Flow</div>
            <div className='PaymentDetailLayout--title-id'></div>
          </div>
          <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-bottom'>
            <div>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  Captured:
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: payment?.capturedAmount || 0,
                    currency: payment?.currency,
                  })}
                </div>
              </div>
            </div>
            {showDeductions() && (
              <div className='PaymentDetailLayout--arrow-bottom' />
            )}
          </div>
          {showDeductions() && (
            <div className='PaymentDetailLayout--container'>
              {showProcessingFee() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Processing fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: payment.processingFeeAmount!,
                      currency: payment.currency,
                    })}
                  </div>
                </div>
              )}

              {showPlatformFee() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Platform fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: payment.appliedNetPlatformFeeAmount!,
                      currency: payment.currency,
                    })}
                  </div>
                </div>
              )}

              {showRefundedAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Refunds:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: payment.refundedAmount,
                      currency: payment.currency,
                    })}
                  </div>
                </div>
              )}
              {showNetSplitAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Total split payments:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: payment.splitPaymentDetail!.netSplitAmount,
                      currency: payment.currency,
                    })}
                  </div>
                </div>
              )}
              {showNetChargebackAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Chargeback amount:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: isLiableForChargeback()
                        ? payment.disputeDetail!.netChargebackAmount
                        : 0,
                      currency: payment.currency,
                    })}{' '}
                    {!isLiableForChargeback() &&
                      `(${formatPrice({
                        pence: payment.disputeDetail!.netChargebackAmount,
                        currency: payment.currency,
                      })} charged to platform)`}
                  </div>
                </div>
              )}
              {showChargebackFeeAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Chargeback fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: isLiableForChargeback()
                        ? payment.disputeDetail!.chargebackFeeAmount
                        : 0,
                      currency: payment.currency,
                    })}{' '}
                    {!isLiableForChargeback() &&
                      `(${formatPrice({
                        pence: payment.disputeDetail!.chargebackFeeAmount,
                        currency: payment.currency,
                      })} charged to platform)`}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-top'>
            <div>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  Net:
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: payment?.netAmount || 0,
                    currency: payment?.currency,
                  })}
                </div>
              </div>
            </div>
            <div className='PaymentDetailLayout--arrow-top' />
          </div>
        </>
      )}
      {payment?.splitPaymentDetail?.items && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>
              Split Payments
            </div>
            <div className='PaymentDetailLayout--title-id'></div>
          </div>

          {payment.splitPaymentDetail.items.map(
            (split: splitPaymentDetailItems, index: number) => {
              return (
                <div key={index}>
                  <div className='PaymentDetailLayout--title'>
                    <div className='PaymentDetailLayout--title-desc-light'>
                      {split.description || split.accountId}
                    </div>
                    <div className='PaymentDetailLayout--title-id'>
                      <Copy
                        type='BUTTON_WITH_TEXT'
                        text={split.id}
                        value={split.id}
                      />
                    </div>
                  </div>
                  <div className='PaymentDetailLayout--container'>
                    <Expander>
                      <div>
                        <div className='PaymentDetailLayout--container-pair'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Amount:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.amount || 0,
                              currency: payment?.currency,
                            })}
                          </div>
                        </div>
                      </div>

                      <div className='PaymentDetailLayout--expanded'>
                        <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Fee:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.fee?.amount || 0,
                              currency: payment?.currency,
                            })}
                          </div>
                        </div>
                        {split.refundedAmount && (
                          <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                            <div className='PaymentDetailLayout--container-pair-key'>
                              Refunded:
                            </div>
                            <div className='PaymentDetailLayout--container-pair-val'>
                              {formatPrice({
                                pence: split.refundedAmount || 0,
                                currency: payment?.currency,
                              })}
                            </div>
                          </div>
                        )}
                        {split.fee?.refundedAmount && (
                          <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                            <div className='PaymentDetailLayout--container-pair-key'>
                              Refunded fee:
                            </div>
                            <div className='PaymentDetailLayout--container-pair-val'>
                              {formatPrice({
                                pence: split.fee?.refundedAmount || 0,
                                currency: payment?.currency,
                              })}
                            </div>
                          </div>
                        )}

                        <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Net:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.netSplitAmount || 0,
                              currency: payment?.currency,
                            })}
                          </div>
                        </div>
                      </div>
                    </Expander>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {canCapture && (
        <Button
          name='Capture Payment'
          click={(): void => history.push(`/payments/${payment?.id}/captures`)}
        />
      )}
      {canVoid && (
        <Button
          name='Void Payment'
          click={(): void => history.push(`/payments/${payment?.id}/voids`)}
        />
      )}
      {canRefund && (
        <Button
          name='Refund Payment'
          click={(): void => history.push(`/payments/${payment?.id}/refunds`)}
        />
      )}
    </div>
  );
};

export { PaymentSessionDetails };
