import { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { FilterDisputes } from './FilterDisputes';
import { Disputes } from './Disputes';

import { useGetDisputes } from '../../hooks/useGetDisputes';
import { SideMenu } from '../layouts/SideMenu';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { DisputeFilterShape } from '../../interfaces/disputeState';
import { handleNotSignedIn } from '../../functions/helpers';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router';

const DisputeDetail = (): ReactElement => {
  const history = useHistory();
  const { disputeState, profileState } = useGlobalDataState();
  const { profile } = useGlobalState();
  const { isSignedIn } = useGlobalState();
  const [isLoading, error, getDisputes, isMore, getMore] = useGetDisputes();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const filter = disputeState.data?.filter;
    setIsFiltering(filterApplied(filter));
  }, [disputeState]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      profile.processingModel === 'Iso' ||
      profile.group === 'SUB' ||
      !permissions.has('DisputesView')
    ) {
      return history.push('/');
    }
    getDisputes();
  }, [isSignedIn]);

  const filterApplied = (filter?: DisputeFilterShape): boolean => {
    return (
      (filter &&
        (hasDefinedString(filter.disputeId) ||
          hasDefinedString(filter.status) ||
          hasDefinedString(filter.paymentSessionId) ||
          filter.startTimestamp !== undefined ||
          filter.endTimestamp !== undefined)) ??
      false
    );
  };

  const hasDefinedString = (value?: string | undefined): boolean => {
    return value !== undefined && value.length > 0;
  };

  return (
    <div className='LayoutMain'>
      <div className='LayoutMain--grid'>
        <div className='LayoutMain--button'></div>
        <div className='LayoutMain--mobile-filter'>
          <Button
            name='Filter'
            color='GREY'
            click={handleMobileFilter}
            margin='0px'
          />
        </div>
        <div className='LayoutMain--filter'>
          <FilterDisputes getDisputes={getDisputes} />
        </div>
        <div className='LayoutMain--data'>
          {error ? (
            <div>Error: {error}</div>
          ) : (
            <Disputes
              disputeArr={disputeState.data?.items ?? []}
              isMore={isMore}
              getMore={getMore}
              isLoading={isLoading}
              isFiltering={isFiltering}
            />
          )}
        </div>
        <SideMenu
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          position='LEFT'
          width='SUPER'
        >
          <div style={{ padding: '12px' }}>
            <FilterDisputes getDisputes={getDisputes} />
          </div>
        </SideMenu>
      </div>
    </div>
  );
};

export { DisputeDetail };
