import { ReactElement, Dispatch, SetStateAction } from 'react';

export interface PropsShape {
  label?: boolean;
  rows?: number;
  name: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeHolder?: string;
  error?: string;
  description?: string;
  autoComplete?: boolean;
  focus?: boolean;
  spellCheck?: boolean;
  info?: string;
  optional?: boolean;
}

const TextArea = ({
  label = false,
  name,
  rows = 3,
  value,
  setValue,
  placeHolder,
  error,
  description,
  autoComplete = false,
  focus = false,
  spellCheck = false,
  info,
  optional = false,
}: PropsShape): ReactElement => {
  return (
    <div className='TextArea'>
      {info && <>{info}</>}
      {error && <div className='TextInput--error'>{error}</div>}
      {optional && <div className='TextInput--optional'>Optional</div>}
      {label && (
        <label className='TextInput--label' htmlFor={name}>
          {name}
        </label>
      )}
      <textarea
        rows={rows}
        id={name}
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={(e): void => setValue(e.target.value)}
        className='TextArea--input'
        autoComplete={autoComplete === false ? 'false' : 'true'}
        autoFocus={focus}
        spellCheck={spellCheck}
      ></textarea>
      {description && (
        <small className='TextInput--description'>{description}</small>
      )}
    </div>
  );
};
export { TextArea };
