import React, { ReactElement } from 'react';
import { Button } from '../forms/Button';
import { ContactSupport } from './ContactSupport';
export interface ActionsShape {
  action: string;
  isComplete: boolean;
}

export interface PropsShape {
  title: string;
  subTitle?: string | ReactElement;
  requiredActions: ActionsShape[];
  contactSupportLink?: string;
  docsLink?: string;
  showButton?: boolean;
  buttonDisabled?: boolean;
  buttonText?: string;
  buttonFooterText?: string;
  showBorder?: boolean;
  buttonLink?: () => void;
  padTitle?: boolean;
}

const RequiredActions = ({
  title,
  subTitle,
  requiredActions,
  contactSupportLink,
  docsLink,
  showButton = false,
  buttonDisabled = false,
  buttonText,
  buttonFooterText,
  showBorder = true,
  buttonLink,
  padTitle = true,
}: PropsShape): ReactElement => {
  const getBorderStyle = (): string =>
    showBorder ? '1px solid #2581e3' : 'none';

  return (
    <div className='RequiredActions' style={{ border: getBorderStyle() }}>
      <div className='RequiredActions--container'>
        <div className='RequiredActions--top-split'>
          <div className='RequiredActions--top-left'>
            {' '}
            <div
              className={`RequiredActions--${
                padTitle ? 'title' : 'title-non-padded'
              }`}
            >
              {title}
            </div>
            {subTitle && (
              <div className={'RequiredActions--subtitle'}>{subTitle}</div>
            )}
            <ul>
              {requiredActions.map((actionItem, index) => (
                <li
                  key={index}
                  className={
                    actionItem.isComplete
                      ? 'RequiredActions--tick'
                      : 'RequiredActions--cross'
                  }
                >
                  {actionItem.action}
                </li>
              ))}
            </ul>
          </div>
          <div className='RequiredActions--top-right'>
            {showButton && buttonLink && (
              <>
                <Button
                  name={buttonText ?? 'Add'}
                  margin='0px'
                  click={buttonLink}
                  disabled={buttonDisabled}
                />
                <div className='RequiredActions--top-right-footer'>
                  {buttonFooterText}
                </div>
              </>
            )}
          </div>
        </div>
        {(contactSupportLink || docsLink) && (
          <div className='RequiredActions--container-bottom'>
            {contactSupportLink && (
              <ContactSupport supportLink={contactSupportLink} />
            )}
            {docsLink && (
              <div className='RequiredActions--docs'>
                For more detailed information and guidance, we invite you to
                check out our documentation{' '}
                {docsLink && (
                  <a
                    href={docsLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='RequiredActions--nolink'
                  >
                    <span className='RequiredActions--link'> here...</span>
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { RequiredActions };
