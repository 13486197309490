import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Csv from '../../images/csv.svg';
import { Report } from '../../interfaces/reportsState';

export interface PropsShape {
  selected: boolean;
  file?: Report;
  click: (i: { action: string; id: string }) => void;
  hideButtons?: boolean;
}

const FileItem = ({
  selected,
  file,
  click,
  hideButtons = false,
}: PropsShape): ReactElement => {
  const buttonTransition = useRef(null);
  const [fileLink, setFileLink] = useState<any>();
  const [fileType, setFileType] = useState<string>('');
  const [showButtons, setShowButtons] = useState<boolean>(false);

  useEffect(() => {
    const fileType = file?.parameters.fileType;
    if (fileType) {
      if (fileType === 'Csv') setFileLink(Csv);
      setFileType(fileType);
    }
  }, [file]);

  const calcStyle = (): any => {
    if (fileType === 'jpg' || fileType === 'png') {
      return {
        backgroundImage: `url('test.com/image.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      };
    }
  };

  const doMouseEnter = (): void => {
    !hideButtons && setShowButtons(true);
  };
  const doMouseLeave = (): void => {
    !hideButtons && setShowButtons(false);
  };
  const doClick = (): void => {
    click({ action: 'click', id: file?.id || '' });
  };
  const doDownload = (e: any): void => {
    e.stopPropagation();
    click({ action: 'download', id: file?.id || '' });
  };
  const doDelete = (e: any): void => {
    e.stopPropagation();
    click({ action: 'delete', id: file?.id || '' });
  };

  return (
    <div
      className={
        selected
          ? 'FileItem FileItem--selected'
          : 'FileItem FileItem--not-selected'
      }
      onClick={doClick}
      onMouseEnter={doMouseEnter}
      onMouseLeave={doMouseLeave}
      style={calcStyle()}
    >
      <CSSTransition
        nodeRef={buttonTransition}
        in={showButtons}
        timeout={220}
        classNames='FileItem--ButtonTransition'
      >
        <div className='FileItem--overlay' ref={buttonTransition}>
          <div className='FileItem--overlay-top'>
            <div
              className={
                selected
                  ? 'FileItem--overlay-corner-selected'
                  : 'FileItem--overlay-corner'
              }
            />
            <img src={fileLink} />
          </div>
          <div className='FileItem--overlay-bottom'>
            <div className='FileItem--overlay-bottom-name'>{file?.name}</div>

            {showButtons && (
              <div className='FileItem--overlay-bottom-buttons'>
                <div
                  onClick={doDownload}
                  className='FileItem--overlay-bottom-download'
                />

                <div
                  onClick={doDelete}
                  className='FileItem--overlay-bottom-delete'
                />
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { FileItem };
