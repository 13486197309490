import { useEffect, useState } from 'react';
import {
  DeveloperShape,
  webhooksStateShape,
} from '../interfaces/developerState';
import { useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';

const useGetDeveloper = (): [
  boolean,
  string | undefined,
  DeveloperShape | undefined,
  () => void
] => {
  const { setWebhooksState } = useGlobalDataState();
  const [developer, setDeveloper] = useState<DeveloperShape>();
  const [response, isLoading, error, sendRequest] =
    useApiRequest<DeveloperShape>();

  useEffect(() => {
    if (response && response.data) {
      setDeveloper(response.data);
      if (response.data.webhookDetail) {
        setWebhooksState((prevState: webhooksStateShape) => ({
          ...prevState,
          data: {
            ...prevState.data,
            eventTypes: response.data.webhookDetail!.eventTypes.sort(),
            webhooks: response.data.webhookDetail!.webhooks,
          },
        }));
      }
    }
  }, [response]);

  useEffect(() => {
    const url = `/v1/developer`;
    sendRequest({ url });
  }, []);

  const reFetchDeveloper = (): void => {
    const url = `/v1/developer`;
    setTimeout(() => {
      sendRequest({ url });
    }, 3000);
  };

  return [isLoading, error, developer, reFetchDeveloper];
};

export { useGetDeveloper };
