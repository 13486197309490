import { ReactElement } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export interface PropsShape {
  value: {
    isLoading: boolean;
    categories: string[];
    data: number[];
  };
}

const NewCustomersChart = ({ value }: PropsShape): ReactElement => {
  const options: ApexOptions = {
    title: {
      text: 'New Customers',
      align: 'center',
    },
    chart: {
      id: 'new-customers-line-chart',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      colors: ['#2581e3'],
      width: 3,
    },
    noData: value.isLoading
      ? {
          text: 'Loading...',
        }
      : {
          text: 'No data for selection',
        },
    xaxis: {
      categories: value.categories,
      tickAmount: 4,
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  const series = [
    {
      name: 'count',
      data: value.data,
    },
  ];

  return (
    <div className='NewCustomersChart'>
      <div id='chart'>
        <Chart
          options={options}
          series={series}
          type='line'
          height='300'
          width='100%'
        />
      </div>
    </div>
  );
};

export { NewCustomersChart };
