import React from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';

const CookieRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='Terms'>
        <div className='Terms--center'>
          <header>Terms & Policies</header>
          <div className='Terms--grid'>
            <nav>
              <ul>
                <li className='Terms--selected'>Cookie Policy</li>
                <li
                  className='Terms--option'
                  onClick={(): void => history.push('./terms')}
                >
                  Terms of Service
                </li>
                <li
                  className='Terms--option'
                  onClick={(): void => history.push('./privacy')}
                >
                  Privacy Policy
                </li>
              </ul>
            </nav>
            <aside>
              <h2>Cookie Policy</h2>
              <p>
                Our cookie policy can be found{' '}
                <a href='https://ryftpay.com/terms-conditions'>here</a>
              </p>
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { CookieRoute };
