import { ReactElement, useState, useEffect } from 'react';
import spinnerImage from '../../images/loading.svg';
import Auth from '@aws-amplify/auth';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';

export interface PropsShape {
  returnType: 'JOINED' | 'AUTHORIZED';
}

const Return = ({ returnType }: PropsShape): ReactElement => {
  const { coBrandingState } = useGlobalDataState();
  const [returnMessage, setReturnMessage] = useState<string>('');
  const linkRedirectUrl = localStorage.getItem('linkRedirectUrl');
  const defaultRedirectDelayInMs = 3000;

  const removeLinkDataFromStorage = (): void => {
    localStorage.removeItem('linkRedirectUrl');
  };

  useEffect(() => {
    const message = coBrandingState.data?.name
      ? `Returning to ${coBrandingState.data?.name} ...`
      : 'Returning ...';
    setReturnMessage(message);
  });

  const redirectToUrl = async (url: string): Promise<void> => {
    removeLinkDataFromStorage();
    const completeUrl = new URL(url);
    if (returnType === 'AUTHORIZED') {
      const { attributes } = await Auth.currentAuthenticatedUser();
      if (attributes && attributes['custom:account_id']) {
        const accountId = attributes['custom:account_id'];
        completeUrl.searchParams.append('account', accountId);
      }
    }
    window.setTimeout(function () {
      window.location.href = completeUrl.toString();
    }, defaultRedirectDelayInMs);
  };

  linkRedirectUrl && redirectToUrl(linkRedirectUrl);

  return (
    <div className='Return'>
      <div className='Return--loading'>
        <img src={spinnerImage} className='Return--loading' alt='loading' />
      </div>
      <div className='Return--body'>{returnMessage}</div>
    </div>
  );
};
export { Return };
