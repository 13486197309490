const isEmail = async (email: string): Promise<boolean | undefined> => {
  try {
    if (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};

const isEmailNonAsync = (email: string): boolean => {
  try {
    if (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  } catch (error) {
    return false;
  }
};

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/;

const isPassword = async (password: string): Promise<boolean | undefined> => {
  try {
    if (password) {
      return passwordRegex.test(String(password));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};

const isPasswordNonAsync = (password: string): boolean => {
  try {
    if (password) {
      return passwordRegex.test(String(password));
    }
    return false;
  } catch (error) {
    return false;
  }
};

const isNNumbers = async (
  code: string,
  length: number
): Promise<boolean | undefined> => {
  try {
    const re = /^[0-9]+$/;
    return re.test(code) && code.length === length;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};

const isNNumbersNonAsync = (code: string, length: number): boolean => {
  try {
    const re = /^[0-9]+$/;
    return re.test(code) && code.length === length;
  } catch (error) {
    return false;
  }
};

const isExpired = (epoch: number, maxMinutes: number): boolean => {
  const n: boolean = (new Date().getTime() / 1000 - epoch) / 60 < maxMinutes;
  return !n;
};

const isValidHttpsUrl = (value: string): boolean => {
  try {
    const url = new URL(value);
    return url.protocol === 'https:';
  } catch {
    return false;
  }
};

export {
  isEmail,
  isPassword,
  isNNumbers,
  isExpired,
  isPasswordNonAsync,
  isEmailNonAsync,
  isNNumbersNonAsync,
  isValidHttpsUrl,
};
