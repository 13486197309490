import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router';
import { PlatformFees } from '../components/dataGrids/PlatformFees';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { FilterPlatformFees } from '../components/dataGrids/FilterPlatformFees';
import { SideMenu } from '../components/layouts/SideMenu';
import { Button } from '../components/forms/Button';
import { useGetTransactions } from '../hooks/useGetTransactions';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const PlatformFeesRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState, feesState } = useGlobalDataState();
  const [
    isLoading,
    ,
    platformFeesError,
    setFilter,
    ,
    isMorePlatformFees,
    getMore,
  ] = useGetTransactions();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !permissions.has('TransactionsView') ||
      !profileState?.data?.account.settings.subAccountsDisplayed
    ) {
      history.push('/');
    }
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='LayoutMain'>
        <div className='LayoutMain--grid LayoutMain--grid-no-button'>
          <div className='LayoutMain--mobile-filter'>
            <Button
              name='Filter'
              color='GREY'
              click={handleMobileFilter}
              margin='0px'
            />
          </div>
          <div className='LayoutMain--filter'>
            <FilterPlatformFees setFilter={setFilter} />
          </div>
          <div className='LayoutMain--data'>
            <PlatformFees
              platformFees={feesState.data?.items ?? []}
              isMore={isMorePlatformFees}
              getMore={getMore}
              isLoading={isLoading}
              error={platformFeesError}
            />
          </div>
          <SideMenu
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            position='LEFT'
            width='SUPER'
          >
            <div style={{ padding: '12px' }}>
              <FilterPlatformFees setFilter={setFilter} />
            </div>
          </SideMenu>
        </div>
      </div>
    </Layout>
  );
};

export { PlatformFeesRoute };
