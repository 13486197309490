import { ReactElement, Dispatch, SetStateAction } from 'react';
import { CheckBox } from './CheckBox';
import { OptionShape } from '../../interfaces/state';

interface PropsShape {
  name: string;
  description?: string;
  grid: 'ONE_COLUMN' | 'TWO_COLUMN';
  allValues: OptionShape[];
  selectedValues: any[];
  setSelectedValues: Dispatch<SetStateAction<any[]>>;
  error?: string;
}

function SelectCheckBox({
  name,
  description,
  grid,
  allValues,
  selectedValues,
  setSelectedValues,
  error,
}: PropsShape): ReactElement {
  const setCheckedValue = (checked: any, value: string): boolean => {
    let updatedSelectedValues = selectedValues || [];
    if (checked) {
      updatedSelectedValues = Array.from(
        new Set(updatedSelectedValues).add(value)
      );
    } else {
      updatedSelectedValues = updatedSelectedValues.filter(
        (item) => item != value
      );
    }
    setSelectedValues(updatedSelectedValues);
    return checked;
  };

  const getGridClassName = (): string => {
    const className = 'SelectCheckBox--grid';
    switch (grid) {
      case 'ONE_COLUMN':
        return className;
      case 'TWO_COLUMN':
        return `${className} ${className}-two-col`;
    }
  };

  return (
    <div className='SelectCheckBox'>
      <label className='SelectCheckBox--label'>{name}</label>
      {description && (
        <small className='SelectInput--description'>{description}</small>
      )}
      <div className={getGridClassName()}>
        {allValues.map((option: OptionShape, index: number) => {
          return (
            <div key={index}>
              <CheckBox
                value={new Set(selectedValues).has(option.value)}
                id={`${index}`}
                key={`${index}`}
                label={option.name}
                setValue={(v): any => setCheckedValue(v, option.value)}
              ></CheckBox>
            </div>
          );
        })}
        {error && <div className='SelectCheckBox--error'>{error}</div>}
      </div>
    </div>
  );
}

export { SelectCheckBox };
