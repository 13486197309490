import { useState, ReactElement, useEffect } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { Tabs } from '../components/layouts/Tabs';
import { Reports } from '../components/dataGrids/Reports';
import { DropDown } from '../components/layouts/DropDown';
import { NewReport } from '../components/dropDowns/NewReport';
import { useHistory } from 'react-router-dom';
import { Progress } from '../components/layouts/Progress';
import { request } from '../functions/callApi';
import { formatBytes, handleNotSignedIn } from '../functions/helpers';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { UserPermission } from '../interfaces/profileState';
import { ServerError } from '../components/forms/ServerError';

const FilesRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const [selectedTab, setSelectedTab] = useState<string>('Reports');
  const [quota, setQuota] = useState<any>();
  const [canCreateReports, setCanCreateReports] = useState<any>();
  const [toggle, setToggle] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [reportCreated, setReportCreated] = useState<boolean>(false);
  const { reportsState, profileState } = useGlobalDataState();

  const tabClicked = (e: any): void => {
    const selected = e.target.id;
    if (selected) setSelectedTab(selected);
  };

  const callApi = async (): Promise<void> => {
    const apiUrl = '/v1/files-usage';
    const response = await request<any>(apiUrl);
    response.status === 200 && setQuota(response);
  };

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('ReportsView')) {
      history.push('/');
      return;
    }
  }, [isSignedIn]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      ReportsModify: setCanCreateReports,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    reportsState.data?.items && callApi();
  }, [JSON.stringify(reportsState.data?.items)]);

  const getButton = (): any => {
    if (
      canCreateReports &&
      quota?.reportsCountToday < quota?.reportsQuotaPerDay
    ) {
      return (
        <DropDown title='New Report' toggle={toggle}>
          <NewReport
            setToggle={setToggle}
            setError={setError}
            setReportCreated={setReportCreated}
          />
        </DropDown>
      );
    } else return <></>;
  };

  const getQuota = (): any => {
    if (quota) {
      return (
        <div
          className={
            quota?.reportsCountToday === quota?.reportsQuotaPerDay
              ? 'FilesRoute--quota-alert'
              : 'FilesRoute--quota'
          }
        >{`${quota?.reportsCountToday} of ${quota?.reportsQuotaPerDay} files created for today`}</div>
      );
    } else return <></>;
  };

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='FilesRoute'>
        <div className='FilesRoute--center'>
          <div className='FilesRoute--containers'>
            <div className='FilesRoute--title'>Files</div>
            <div className='FilesRoute--available'>
              {quota && (
                <Progress
                  text={`${formatBytes(
                    quota.fileUsageInBytes
                  )} of ${formatBytes(quota.fileUsageLimitInBytes)} used`}
                  percentage={
                    (quota.fileUsageInBytes / quota.fileUsageLimitInBytes) * 100
                  }
                  align='right'
                />
              )}
            </div>
          </div>
          {error && (
            <div className='FilesRoute--error'>
              <ServerError error={error} />
            </div>
          )}
          <Tabs
            click={tabClicked}
            tabs={['Reports']}
            selected={selectedTab}
            topRight={getButton()}
            bottomLeft={getQuota()}
          >
            <Reports
              reportCreated={reportCreated}
              setReportCreated={setReportCreated}
            />
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

export { FilesRoute };
