import { useEffect, useState } from 'react';
import { ApiOperation, HttpMethod, useApiRequest } from './useApiRequest';

const useAdjustDeveloper = (): [ApiOperation<any>, ApiOperation<any>] => {
  const [cycleRequestCompleted, setCycleRequestCompleted] =
    useState<boolean>(false);
  const [deleteRequestCompleted, setDeleteRequestCompleted] =
    useState<boolean>(false);
  const [cycleResponse, isCycling, cycleError, sendCycleRequest] =
    useApiRequest<any>();
  const [deleteResponse, isDeleting, deleteError, sendDeleteRequest] =
    useApiRequest<any>();

  useEffect(() => {
    if (cycleResponse) {
      setCycleRequestCompleted(true);
    }
  }, [cycleResponse]);

  useEffect(() => {
    if (deleteResponse) {
      setDeleteRequestCompleted(true);
    }
  }, [deleteResponse]);

  const cycleKeys = (): Promise<void> => {
    setCycleRequestCompleted(false);
    const url = `/v1/developer/api-keys/cycle`;
    const method = HttpMethod.POST;
    return sendCycleRequest({ url, method });
  };

  const deleteKeys = (): Promise<void> => {
    setDeleteRequestCompleted(false);
    const url = `/v1/developer/api-keys`;
    const method = HttpMethod.DELETE;
    return sendDeleteRequest({ url, method });
  };

  return [
    {
      isLoading: isCycling,
      error: cycleError,
      request: cycleKeys,
      response: cycleResponse,
      complete: cycleRequestCompleted,
    },
    {
      isLoading: isDeleting,
      error: deleteError,
      request: deleteKeys,
      response: deleteResponse,
      complete: deleteRequestCompleted,
    },
  ];
};

export { useAdjustDeveloper };
