import { ReactElement } from 'react';

export interface PropsShape {
  children: ReactElement;
  label?: boolean;
  name: string;
  error?: string;
}

const Group = ({
  children,
  label = true,
  name,
  error,
}: PropsShape): ReactElement => {
  return (
    <div className='Group'>
      {error && <div className='Group--error'>{error}</div>}
      {label && <div className='Group-name'>{name}</div>}
      <>{children}</>
    </div>
  );
};
export { Group };
