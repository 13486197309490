import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

export interface PropsShape {
  label?: boolean;
  name: string;
  value: any;
  setValue?: Dispatch<SetStateAction<any>>;
  error?: string;
  description?: string;
  info?: string;
  optional?: boolean;
}

const OneTimeCode = ({
  label = false,
  name,
  value,
  setValue,
  error,
  description,
  info,
  optional = false,
}: PropsShape): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (codeValue?: string): void => {
    if (setValue !== undefined) {
      setValue(codeValue);
    }
    if (codeValue && codeValue.length >= 6 && inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className='OneTimeCode'>
      {info && <>{info}</>}
      {error && <div className='TextInput--error'>{error}</div>}
      {optional && <div className='TextInput--optional'>Optional</div>}
      {label && (
        <label className='TextInput--label' htmlFor={name}>
          {name}
        </label>
      )}
      <input
        ref={inputRef}
        className='OneTimeCode--input'
        type='text'
        name={name}
        value={value}
        inputMode='numeric'
        maxLength={6}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>): void => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={(e: any): void => handleChange(e.target.value)}
        autoComplete='one-time-code'
      ></input>
      {description && (
        <small className='TextInput--description'>{description}</small>
      )}
    </div>
  );
};

export { OneTimeCode };
