import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import {
  Subscription,
  SubscriptionSettings,
} from '../interfaces/subscriptionState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
interface ApiResponse {
  items: Subscription[];
  paginationToken: string;
  settings: SubscriptionSettings;
}

const useFilterSubscriptions = (): [
  any[],
  boolean,
  string | undefined,
  (filter: any) => void,
  boolean,
  () => void,
  boolean
] => {
  const { subscriptionState, setSubscriptionState } = useGlobalDataState();
  const [subscriptionsArr, setSubscriptionsArr] = useState<any[]>([]);

  const [isMore, setIsMore] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [ascending, setAscending] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [response, isLoading, error, sendRequest] =
    useApiRequest<ApiResponse>();

  const setFilter = (filter: any): void => {
    setAction('applyFilter');
    setSubscriptionsArr([]);
    let url = '/v1/subscriptions';

    if (filter.customerId) {
      url += `?customerId=${filter.customerId}`;
    }

    if (filter.startTimestamp || filter.endTimestamp) {
      url += `${url.includes('?') ? '&' : '?'}startTimestamp=${
        filter.startTimestamp || 0
      }&endTimestamp=${
        filter.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter.startsAfter) {
      url += `${url.includes('?') ? '&' : '?'}startsAfter=${
        filter.startsAfter
      }`;
    }

    if (filter.pageSize) {
      url += `${url.includes('?') ? '&' : '?'}pageSize=${filter.pageSize}`;
    }

    if (filter.ascending !== undefined) {
      setAscending(filter.ascending);
      url += `${url.includes('?') ? '&' : '?'}ascending=${filter.ascending}`;
    }

    if (filter.status !== '') {
      url += `${url.includes('?') ? '&' : '?'}status=${filter.status}`;
    }

    if (filter.subscriptionId !== '') {
      url += `${url.includes('?') ? '&' : '?'}subscriptionId=${
        filter.subscriptionId
      }`;
    }

    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('savedUrl', url);
  };

  const getMore = (): any => {
    setAction('getMore');
    const savedUrl = localStorage.getItem('savedUrl');
    const paginationToken = localStorage.getItem('paginationToken');
    if (savedUrl && paginationToken) {
      sendRequest({
        url: `${savedUrl}&subscriptionsStartAfter=${paginationToken}`,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    function sortItems(items: Subscription[], asc: boolean): Subscription[] {
      return items.sort((a, b) => {
        return asc
          ? a.billingDetail.billingCycleTimestamp -
              b.billingDetail.billingCycleTimestamp
          : b.billingDetail.billingCycleTimestamp -
              a.billingDetail.billingCycleTimestamp;
      });
    }
    if (response) {
      localStorage.setItem(
        'paginationToken',
        response.data.paginationToken || ''
      );

      setIsMore(!!response.data.paginationToken);
      const items = response.data.items;
      const updatedItems = sortItems(
        action === 'getMore' && subscriptionState.data?.items
          ? subscriptionState.data.items.concat(items)
          : items,
        ascending
      );

      setSubscriptionsArr(updatedItems);
      setEnabled(response.data.settings.enabled);
      setSubscriptionState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: response.data.paginationToken,
          items: updatedItems,
          settings: response.data.settings,
        },
      }));
    }
  }, [response]);

  return [
    subscriptionsArr,
    isLoading,
    error,
    setFilter,
    isMore,
    getMore,
    enabled,
  ];
};

export { useFilterSubscriptions };
