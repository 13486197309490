import React, {
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { request } from '../../functions/callApi';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { Heading } from '../layouts/Heading';
import { TextInput } from '../forms/TextInput';
import { DownloadFile } from '../forms/DownloadFile';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Copy } from '../forms/Copy';

export interface PropsShape {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setApplePayWebDomainsChange?: Dispatch<SetStateAction<boolean>>;
}

const ApplePayWebDomainsAdd = ({
  setIsOpen,
  setApplePayWebDomainsChange,
}: PropsShape): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [domainName, setDomainName] = useState<string>('');
  const placeholderDomainName = 'www.your-domain.com';

  useEffect(() => {
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/settings');
    }
  }, [profile]);

  const closeModal = (): void => {
    setIsOpen ? setIsOpen(false) : history.push('/developer/apple-pay');
  };

  const fetchVerificationFile = async (): Promise<string> => {
    const result = await fetch(
      '/.well-known/apple-developer-merchantid-domain-association'
    );
    return await result.text();
  };

  const addDomain = async (): Promise<void> => {
    setServerError('');
    setLoading(true);
    const apiUrl = '/v1/developer/apple-pay/web-domains';
    try {
      const response = await request<any>(
        apiUrl,
        'POST',
        JSON.stringify({ domainName })
      );
      setLoading(false);
      if (response.status === 200) {
        setApplePayWebDomainsChange?.(true);
        closeModal();
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      setLoading(false);
      setServerError(err.message);
    }
  };

  const hostedFileLocation = `https://${
    domainName ? domainName : placeholderDomainName
  }/.well-known/apple-developer-merchantid-domain-association`;

  return (
    <div className='ApplePayWebDomains--add'>
      <div className='ApplePayWebDomains--modal-header'>
        <Heading
          title='Add Domain'
          subtitle={
            <>
              Add your web domain to start processing Apple Pay payments via
              Ryft on the web.
              <br />
              You can add up to a maximum of 99 domains against your account.
              <br />
              <br />
              <strong>Note</strong> that if you are processing payments directly
              under sub accounts, use our{' '}
              <a
                href='https://api-reference.ryftpay.com/#tag/Apple-Pay/operation/applePayWebDomainRegister'
                target={'_blank'}
              >
                API
              </a>{' '}
              to register the domain instead.
            </>
          }
        />
      </div>
      {serverError && (
        <div className='ApplePayWebDomains--modal-server-error'>
          <ServerError error={serverError} />
        </div>
      )}
      <div className='ApplePayWebDomains--modal-body'>
        <div className='ApplePayWebDomains--modal-body-side-title'>
          Domain to verify
        </div>
        <TextInput
          name='domain'
          value={domainName}
          setValue={setDomainName}
          placeHolder={placeholderDomainName}
          description='Enter the domain you wish to enable for Apple Pay'
          focus
        />
      </div>
      <div className='ApplePayWebDomains--modal-body'>
        <div className='ApplePayWebDomains--modal-body-side-title'>
          Download
        </div>
        <DownloadFile
          fetchStringFileContents={fetchVerificationFile}
          name='apple-developer-merchantid-domain-association'
        >
          <Button name='Download Verification File' color='GREY' />
        </DownloadFile>
      </div>
      <div className='ApplePayWebDomains--modal-body'>
        <div className='ApplePayWebDomains--modal-body-side-title'>Host</div>
        <div className='ApplePayWebDomains--modal-body-hosted-file-location'>
          <div className='ApplePayWebDomains--modal-body-hosted-file-location-title'>
            Host the verification file at this location:{'  '}
          </div>
          <div className='ApplePayWebDomains--modal-body-hosted-file-location-url'>
            <Copy
              type='BUTTON_WITH_TEXT'
              buttonPosition='RIGHT'
              buttonSize='MEDIUM'
              textStyle='BOLD'
              text={hostedFileLocation}
              value={hostedFileLocation}
            />
          </div>
          <div className='ApplePayWebDomains--modal-body-hosted-file-requirements'>
            <b>Important:</b> The <span>Content-Type</span> of the file must be
            <span> application/octet-stream</span>
          </div>
        </div>
      </div>
      <div className='ApplePayWebDomains--modal-footer'>
        <Button name='Cancel' color='GREY' click={(): void => closeModal()} />
        <Button name='Add' loading={loading} click={addDomain} />
      </div>
    </div>
  );
};
export { ApplePayWebDomainsAdd };
