import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface EditSubscriptionRequest {
  price?: {
    amount?: number;
    interval?: {
      unit: string;
      count: number;
      times?: number;
    };
  };
  paymentMethod?: {
    id: string;
  };
  description?: string;
  billingCycleTimestamp?: number;
  metadata?: { [key: string]: string };
  shippingDetails?: {
    address: {
      firstName?: string;
      lastName?: string;
      lineOne?: string;
      lineTwo?: string;
      city?: string;
      country: string;
      postalCode: string;
      region?: string;
    };
  };
  paymentSettings?: {
    statementDescriptor: {
      descriptor: string;
      city: string;
    };
  };
}

function useEditSubscription(): any {
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setIsEdited(true);
    }
  }, [response]);

  const editSubscription = (
    body: EditSubscriptionRequest,
    subscriptionId: string
  ): void => {
    const url = `/v1/subscriptions/${subscriptionId}`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method, body });
  };

  return [editSubscription, isLoading, isEdited, error];
}

export { useEditSubscription };
