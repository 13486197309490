import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { useEditAccount } from '../../../hooks/useEditAccount';
import { countries } from '../../../functions/iso3166-alpha2';
import { DateOfBirth } from '../../../components/forms/DateOfBirth';
import SelectTags from '../../../components/forms/SelectTags';
import { isEmailNonAsync } from '../../../functions/validators';
import { RequiredField } from '../../../interfaces/accountState';
import { handleNotSignedIn } from '../../../functions/helpers';
import { PhoneNumber } from '../../../components/forms/PhoneNumber';

const EditBusinessRoute = (): ReactElement => {
  interface EditedDetails {
    name?: string;
    type?: string;
    registrationNumber?: string;
    registrationDate?: string;
    contactEmail?: string;
    phoneNumber?: string;
    tradingName?: string;
    tradingCountries?: string[];
    websiteUrl?: string;
  }

  const registrationNumberFieldName: string = 'business.registrationNumber';
  const registrationDateFieldName: string = 'business.registrationDate';
  const tradingNameFieldName: string = 'business.tradingName';
  const tradingCountriesFieldName: string = 'business.tradingCountries';
  const websiteUrlFieldName: string = 'business.websiteUrl';

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountState, profileState } = useGlobalDataState();
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [typeError, setTypeError] = useState<string>('');
  const [registrationNumber, setRegistrationNumber] = useState<string>('');
  const [registrationNumberError, setRegistrationNumberError] =
    useState<string>('');
  const [registrationDate, setRegistrationDate] = useState<string>('');
  const [registrationDateError, setRegistrationDateError] =
    useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactEmailError, setContactEmailError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [tradingName, setTradingName] = useState<string>('');
  const [tradingNameError, setTradingNameError] = useState<string>('');
  const [tradingCountries, setTradingCountries] = useState<string[]>([]);
  const [tradingCountriesError, setTradingCountriesError] =
    useState<string>('');
  const [websiteUrl, setWebsiteUrl] = useState<string>('');
  const [websiteUrlError, setWebsiteUrlError] = useState<string>('');
  const [detailsDiffer, setDetailsDiffer] = useState<boolean>(true);
  const [editedDetails, setEditedDetails] = useState<EditedDetails>();
  const { accountData, isLoading, error, apiEditAccount } = useEditAccount();

  const account = accountState?.data;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !account ||
      account.verification.status === 'PendingVerification' ||
      !permissions.has('AccountModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (account?.business) {
      setName(account.business.name);
      setType(account.business.type);
      setRegistrationNumber(account.business.registrationNumber ?? '');
      setRegistrationDate(account.business.registrationDate ?? '');
      setContactEmail(account.business.contactEmail);
      setPhoneNumber({ number: account.business.phoneNumber, valid: true });
      setTradingName(account.business.tradingName ?? '');
      setTradingCountries(account.business.tradingCountries ?? []);
      setWebsiteUrl(account.business.websiteUrl ?? '');
    }
  }, [account]);

  useEffect(() => {
    const isEditedName = name != account?.business?.name;
    const isEditedType = type != account?.business?.type;
    const isEditedRegistrationNumber =
      registrationNumber.length > 0 &&
      registrationNumber != account?.business?.registrationNumber;
    const isEditedRegistrationDate =
      registrationDate.length > 0 &&
      registrationDate != account?.business?.registrationDate;
    const isEditedContactEmail =
      contactEmail != account?.business?.contactEmail;
    const isEditedPhoneNumber =
      phoneNumber.number != account?.business?.phoneNumber;
    const isEditedTradingName =
      tradingName.length > 0 && tradingName != account?.business?.tradingName;
    const isEditedTradingCountries =
      tradingCountries.length > 0 &&
      tradingCountries != account?.business?.tradingCountries;
    const isEditedWebsiteUrl =
      websiteUrl.length > 0 && websiteUrl != account?.business?.websiteUrl;
    const detailsDiffer: boolean =
      isEditedName ||
      isEditedType ||
      isEditedRegistrationNumber ||
      isEditedRegistrationDate ||
      isEditedContactEmail ||
      isEditedPhoneNumber ||
      isEditedTradingName ||
      isEditedTradingCountries ||
      isEditedWebsiteUrl;
    setDetailsDiffer(detailsDiffer);
    setEditedDetails({
      name: isEditedName ? name : undefined,
      type: isEditedType ? type : undefined,
      registrationNumber: isEditedRegistrationNumber
        ? registrationNumber
        : undefined,
      registrationDate: isEditedRegistrationDate ? registrationDate : undefined,
      contactEmail: isEditedContactEmail ? contactEmail : undefined,
      phoneNumber: isEditedPhoneNumber ? phoneNumber.number : undefined,
      tradingName: isEditedTradingName ? tradingName : undefined,
      tradingCountries: isEditedTradingCountries ? tradingCountries : undefined,
      websiteUrl: isEditedWebsiteUrl ? websiteUrl : undefined,
    });
  }, [
    name,
    type,
    registrationNumber,
    registrationDate,
    contactEmail,
    phoneNumber,
    tradingName,
    tradingCountries,
    websiteUrl,
    account,
  ]);

  useEffect(() => {
    accountData && history.push(returnTo);
  }, [accountData]);

  const isFieldRequiredForVerification = (fieldName: string): boolean =>
    account?.verification.requiredFields?.some(
      (field: RequiredField) => field.name === fieldName
    ) === true;

  const verifyNoChanges = (): void => history.push(returnTo);

  const handleUpdate = async (): Promise<void> => {
    if (!editedDetails || !account) {
      return;
    }
    setNameError('');
    setTypeError('');
    setRegistrationNumberError('');
    setRegistrationDateError('');
    setContactEmailError('');
    setPhoneNumberError('');
    setTradingNameError('');
    setTradingCountriesError('');
    setWebsiteUrlError('');

    let valid = true;
    if (name.trim().length < 1) {
      valid = false;
      setNameError('Name cannot be empty');
    }
    if (type.trim().length < 1) {
      valid = false;
      setTypeError('Type cannot be empty');
    }
    if (
      registrationNumber.trim().length < 1 &&
      isFieldRequiredForVerification(registrationNumberFieldName)
    ) {
      valid = false;
      setRegistrationNumberError(
        'Registration Number is required for verification'
      );
    }
    if (
      registrationDate.trim().length < 1 &&
      isFieldRequiredForVerification(registrationDateFieldName)
    ) {
      valid = false;
      setRegistrationDateError(
        'Registration Date is required for verification'
      );
    }
    const emailValid = isEmailNonAsync(contactEmail);
    if (!emailValid) {
      valid = false;
      setContactEmailError('Contact Email is invalid');
    }
    if (!phoneNumber.valid) {
      valid = false;
      setPhoneNumberError('Phone number is invalid');
    }
    if (
      tradingName.trim().length < 1 &&
      isFieldRequiredForVerification(tradingNameFieldName)
    ) {
      valid = false;
      setTradingNameError('Trading Name is required for verification');
    }
    if (
      tradingCountries.length < 1 &&
      isFieldRequiredForVerification(tradingCountriesFieldName)
    ) {
      valid = false;
      setTradingCountriesError(
        'At least one Trading Country is required for verification'
      );
    }
    if (
      websiteUrl.length < 1 &&
      isFieldRequiredForVerification(websiteUrlFieldName)
    ) {
      valid = false;
      setWebsiteUrlError('Website Url is required for verification');
    }

    if (!valid) {
      return;
    }

    if (!detailsDiffer && isVerificationRequired()) {
      verifyNoChanges();
      return;
    }

    apiEditAccount({
      business: {
        name: editedDetails.name,
        type: editedDetails.type,
        registrationNumber: editedDetails.registrationNumber,
        registrationDate: editedDetails.registrationDate,
        contactEmail: editedDetails.contactEmail,
        phoneNumber: editedDetails.phoneNumber,
        tradingName: editedDetails.tradingName,
        tradingCountries: editedDetails.tradingCountries,
        websiteUrl: editedDetails.websiteUrl,
      },
    });
  };

  const isVerificationRequired = (): boolean =>
    account?.verification.status === 'Required';

  const getTitle = (): string =>
    isVerificationRequired()
      ? 'Verify business details'
      : 'Edit business details';

  const getSubTitle = (): string =>
    isVerificationRequired()
      ? 'Please review all your business details so we can verify you'
      : '';

  return (
    <>
      {!account && <div className='AppRoute--notfound'>Error 404</div>}
      {account && (
        <LayoutIllustration
          title={<>{getTitle()}</>}
          subTitle={<>{getSubTitle()}</>}
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={error} />
            <>
              <TextInput
                label
                name='Name'
                description='The legal name of your business'
                value={name}
                setValue={setName}
                error={nameError}
              />
              <SelectInput
                label
                name='Type'
                value={type}
                setValue={setType}
                options={[
                  { value: 'Corporation', name: 'Corporation' },
                  { value: 'PublicCompany', name: 'Public Company' },
                  { value: 'PrivateCompany', name: 'Private Limited Company' },
                  { value: 'LimitedPartnership', name: 'Limited Partnership' },
                  { value: 'Charity', name: 'Charity' },
                  { value: 'GovernmentEntity', name: 'Government Entity' },
                ]}
                error={typeError}
              />
              <TextInput
                label
                name='Registration Number'
                value={registrationNumber}
                setValue={setRegistrationNumber}
                error={registrationNumberError}
                optional={
                  !account?.business?.registrationNumber &&
                  !isFieldRequiredForVerification(registrationNumberFieldName)
                }
              />
              <DateOfBirth
                label
                name='Registration Date'
                value={registrationDate}
                setValue={setRegistrationDate}
                error={registrationDateError}
                minAge={0}
                optional={
                  !account?.business?.registrationDate &&
                  !isFieldRequiredForVerification(registrationDateFieldName)
                }
              />
              <TextInput
                label
                name='Contact Email'
                description="This is only used for contact and WON'T update your login details"
                value={contactEmail}
                setValue={setContactEmail}
                error={contactEmailError}
              />
              <PhoneNumber
                label
                name='Phone Number'
                description='The primary phone number for your business'
                value={phoneNumber.number}
                setValue={setPhoneNumber}
                error={phoneNumberError}
                optional={!account?.business?.phoneNumber}
              />
              <TextInput
                label
                name='Trading Name'
                description='The name that your business operates/trades under'
                value={tradingName}
                setValue={setTradingName}
                error={tradingNameError}
                optional={
                  !account?.business?.tradingName &&
                  !isFieldRequiredForVerification(tradingNameFieldName)
                }
              />
              <SelectTags
                name='Trading Countries'
                description='The countries that your business operates/trades in'
                selectedArray={tradingCountries}
                optionsArray={countries()}
                setSelectedArray={setTradingCountries}
                optional={
                  !account?.business?.tradingCountries &&
                  !isFieldRequiredForVerification(tradingCountriesFieldName)
                }
              />
              <div>{tradingCountriesError}</div>
              <TextInput
                label
                name='Website Url'
                value={websiteUrl}
                setValue={setWebsiteUrl}
                error={websiteUrlError}
                optional={
                  !account?.business?.websiteUrl &&
                  !isFieldRequiredForVerification(websiteUrlFieldName)
                }
              />
            </>
            <div className='EditWebhookRoute--button-container'>
              <Button
                name={isVerificationRequired() ? 'Verify' : 'Update'}
                click={handleUpdate}
                loading={isLoading}
                disabled={!detailsDiffer && !isVerificationRequired()}
              />
              <Button
                name='Cancel'
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditBusinessRoute };
