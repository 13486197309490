import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermission } from '../../interfaces/profileState';
import { camelCaseToWords, handleNotSignedIn } from '../../functions/helpers';
import { useGetAccount } from '../../hooks/useGetAccount';
import {
  AccountCapability,
  AccountShape,
  CapabilityStatus,
  EntityType,
  RequiredField,
} from '../../interfaces/accountState';
import visaBrandLogo from '../../images/visa-brand-logo.svg';
import mastercardBrandLogo from '../../images/mastercard-brand-logo.svg';
import amexBrandLogo from '../../images/amex-brand-logo.svg';
import { Button } from '../../components/forms/Button';
import { useEditAccount } from '../../hooks/useEditAccount';
import { ServerError } from '../../components/forms/ServerError';
import {
  ActionsShape,
  RequiredActions,
} from '../../components/layouts/RequiredActions';
import { ContactSupport } from '../../components/layouts/ContactSupport';

const Capabilities = (): ReactElement => {
  const history = useHistory();
  const { profileState } = useGlobalDataState();
  const { profile, isSignedIn } = useGlobalState();
  const [showCapabilities, setShowCapabilities] = useState(false);
  const [showModifyCapabilities, setshowModifyCapabilities] = useState(false);
  const [showModifyPersons, setShowModifyPersons] = useState(false);
  const [account, setAccount] = useState<AccountShape | null>();
  const [showAddUboLink, setShowAddUboLink] = useState<boolean>(false);

  const {
    accountData,
    isLoading: isAccountLoading,
    error: accountError,
    fetchAccount,
  } = useGetAccount();

  const {
    accountData: editedAccountData,
    isLoading: isEditAccountLoading,
    error: editAccountError,
    apiEditAccount,
  } = useEditAccount();

  useEffect(() => {
    localStorage.setItem('amexCapabilityViewed', 'true');
  }, []);

  useEffect(() => {
    if (showCapabilities && profile.processingModel !== 'Iso') {
      fetchAccount();
    }
  }, [showCapabilities]);

  useEffect(() => {
    setAccount(accountData);
  }, [accountData]);

  useEffect(() => {
    setAccount(editedAccountData);
  }, [editedAccountData]);

  useEffect(() => {
    const showLink =
      editAccountError &&
      editAccountError.includes('Ultimate Beneficial Owner');
    setShowAddUboLink(showLink === true);
  }, [editAccountError]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      CapabilitiesView: setShowCapabilities,
      CapabilitiesModify: setshowModifyCapabilities,
      PersonsModify: setShowModifyPersons,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !permissions.has('CapabilitiesView') ||
      profile.processingModel === 'Iso'
    ) {
      history.push('/');
    }
  }, [isSignedIn, profile]);

  const showFieldRequiredActions = (
    requiredFields?: RequiredField[]
  ): boolean => (requiredFields?.length ?? 0) > 0;

  const getFieldRequiredActions = (
    requiredFields: RequiredField[]
  ): ActionsShape[] =>
    requiredFields.map((field: RequiredField) => ({
      action: camelCaseToWords(field.name.split('.')[1]),
      isComplete: false,
    }));

  const getFieldRequiredActionsTitle = (): string =>
    'We require the following information before we can enable this capability for you';

  const editAccount = (entityType: EntityType): (() => void) =>
    entityType === 'Business' ? editBusiness : editIndividual;

  const editBusiness = (): void => {
    history.push(
      `/settings/account/edit-business?returnTo=/settings/capabilities`
    );
  };

  const editIndividual = (): void => {
    history.push(
      `/settings/account/edit-individual?returnTo=/settings/capabilities`
    );
  };

  const addIndividual = (): void => {
    history.push(
      `/settings/individuals/add-individual?returnTo=/settings/capabilities`
    );
  };

  const requestCapability = async (): Promise<void> => {
    await apiEditAccount({
      capabilities: {
        amexPayments: {
          requested: true,
        },
      },
    });
  };

  const capabilityHasStatus = (
    capability: AccountCapability,
    status: CapabilityStatus
  ): boolean => capability.available && capability.status === status;

  const capabilityIsEnabled = (capability: AccountCapability): boolean =>
    capabilityHasStatus(capability, 'Enabled');

  const capabilityIsDisabled = (capability: AccountCapability): boolean =>
    capabilityHasStatus(capability, 'Disabled');

  const capabilityIsNotRequested = (capability: AccountCapability): boolean =>
    capabilityHasStatus(capability, 'NotRequested');

  const hasAnyCapabilityInStatus = (
    account: AccountShape,
    status: CapabilityStatus
  ): boolean =>
    capabilityHasStatus(account.capabilities.amexPayments, status) ||
    capabilityHasStatus(account.capabilities.visaPayments, status) ||
    capabilityHasStatus(account.capabilities.mastercardPayments, status);

  const capabilityItem = (
    logoUrl: string,
    title: string,
    brandName: string,
    disabledReason?: string
  ): ReactElement => (
    <SettingsItem
      avatar={{
        url: logoUrl,
        highlighted: false,
      }}
      border
      inner
      title={title}
      subtitle={
        <>
          Accept online payments from <strong>{brandName}</strong> customers
          {disabledReason && (
            <>
              <br />
              <br />
              <strong>{disabledReason}</strong>
            </>
          )}
        </>
      }
      layoutSetting='SIMPLE'
    ></SettingsItem>
  );

  return (
    <CardBlank>
      {isAccountLoading && !account && (
        <SettingsItem border title='Loading' subtitle={'......'}></SettingsItem>
      )}
      {!isAccountLoading && accountError && (
        <SettingsItem
          border
          title='Oops!'
          subtitle={accountError}
        ></SettingsItem>
      )}
      {!accountError && account && (
        <>
          <SettingsItem
            border
            title='Enabled'
            subtitle={'Capabilities that are enabled and ready to use'}
          >
            <CardBlank>
              {capabilityIsEnabled(account.capabilities.visaPayments) &&
                capabilityItem(visaBrandLogo, 'Visa Payments', 'Visa')}
              {capabilityIsEnabled(account.capabilities.mastercardPayments) &&
                capabilityItem(
                  mastercardBrandLogo,
                  'Mastercard Payments',
                  'Mastercard'
                )}
              {capabilityIsEnabled(account.capabilities.amexPayments) &&
                capabilityItem(
                  amexBrandLogo,
                  'Amex Payments',
                  'American Express'
                )}
            </CardBlank>
          </SettingsItem>
          {hasAnyCapabilityInStatus(account, 'Disabled') && (
            <SettingsItem
              border
              title={'Disabled'}
              subtitle={
                <>
                  These capabilities have been temporarily disabled. Please
                  contact support for details on re-enabling them
                  <br />
                  <br />
                  <ContactSupport supportLink='/contact' />
                </>
              }
            >
              <CardBlank>
                {capabilityIsDisabled(account.capabilities.visaPayments) &&
                  capabilityItem(
                    visaBrandLogo,
                    'Visa Payments',
                    'Visa',
                    account.capabilities.visaPayments.disabledReason
                  )}
                {capabilityIsDisabled(
                  account.capabilities.mastercardPayments
                ) &&
                  capabilityItem(
                    mastercardBrandLogo,
                    'Mastercard Payments',
                    'Mastercard',
                    account.capabilities.mastercardPayments.disabledReason
                  )}
                {capabilityIsDisabled(account.capabilities.amexPayments) &&
                  capabilityItem(
                    amexBrandLogo,
                    'Amex Payments',
                    'American Express',
                    account.capabilities.amexPayments.disabledReason
                  )}
              </CardBlank>
            </SettingsItem>
          )}
          {showModifyCapabilities && (
            <>
              <SettingsItem
                title='Available'
                subtitle='Additional functionality that you can request to enhance account'
              >
                {!hasAnyCapabilityInStatus(account, 'NotRequested') && (
                  <CardBlank>
                    <SettingsItem
                      title
                      inner
                      subtitle='No additional capabilities available at this time'
                      layoutSetting='SIMPLE'
                    ></SettingsItem>
                  </CardBlank>
                )}
                {capabilityIsNotRequested(
                  account.capabilities.amexPayments
                ) && (
                  <CardBlank>
                    <SettingsItem
                      avatar={{
                        url: amexBrandLogo,
                        highlighted: false,
                      }}
                      border={true}
                      borderStyle={
                        showFieldRequiredActions(
                          account.capabilities.amexPayments.requiredFields
                        )
                          ? 'DASHED'
                          : 'LINE'
                      }
                      inner
                      title={'Amex Payments'}
                      subtitle={
                        <>
                          Accept online payments from{' '}
                          <strong>American Express</strong> customers
                        </>
                      }
                      layoutSetting='SIMPLE'
                    >
                      <Button
                        margin='0'
                        name='Request'
                        click={requestCapability}
                        disabled={
                          showAddUboLink ||
                          showFieldRequiredActions(
                            account.capabilities.amexPayments.requiredFields
                          )
                        }
                        loading={isEditAccountLoading}
                      />
                    </SettingsItem>
                    {showFieldRequiredActions(
                      account.capabilities.amexPayments.requiredFields
                    ) && (
                      <RequiredActions
                        requiredActions={getFieldRequiredActions(
                          account.capabilities.amexPayments.requiredFields!
                        )}
                        title={getFieldRequiredActionsTitle()}
                        contactSupportLink='/contact'
                        showButton={showModifyCapabilities}
                        buttonDisabled={
                          account.verification.status === 'PendingVerification'
                        }
                        buttonLink={editAccount(account.entityType)}
                        buttonFooterText={
                          account.verification.status === 'PendingVerification'
                            ? 'Your account is being verified, please try again later'
                            : ''
                        }
                        showBorder={false}
                      />
                    )}
                  </CardBlank>
                )}
              </SettingsItem>
              {editAccountError && !showAddUboLink && (
                <div style={{ padding: '15px' }}>
                  <ServerError error={editAccountError} />
                </div>
              )}
              {showAddUboLink && (
                <div style={{ padding: '15px' }}>
                  <RequiredActions
                    requiredActions={[
                      {
                        action:
                          'We require the details of at least one UBO in your business before Amex can be enabled',
                        isComplete: false,
                      },
                    ]}
                    title={'Ultimate Beneficial Owner required'}
                    contactSupportLink='/contact'
                    showButton={true}
                    buttonDisabled={!showModifyPersons}
                    buttonFooterText={
                      showModifyPersons
                        ? ''
                        : 'The Owner of your Ryft account must supply this information'
                    }
                    buttonText='Add'
                    buttonLink={addIndividual}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </CardBlank>
  );
};
export { Capabilities };
