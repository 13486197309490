import { ReactElement } from 'react';
import { useGlobalState } from '../hooks/useGlobalState';
import { Redirect, useHistory } from 'react-router-dom';

const HomeRoute = (): ReactElement => {
  const { isSignedIn } = useGlobalState();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);

  return (
    <>
      {isSignedIn && <Redirect to={queryParams?.returnTo ?? '/payments'} />}
      {!isSignedIn && <Redirect to={'/signin'} />}
    </>
  );
};

export { HomeRoute };
