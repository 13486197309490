import { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../layout-illustration';
import { MetaData, KeyValue } from '../../components/forms/MetaData';
import { TextInput } from '../../components/forms/TextInput';
import { Button } from '../../components/forms/Button';
import { CheckBox } from '../../components/forms/CheckBox';
import { DatePicker } from '../../components/forms/DatePicker';
import { SelectInput } from '../../components/forms/SelectInput';
import { useHistory } from 'react-router-dom';
import { useGetCustomerWithPaymentMethodsById } from '../../hooks/useGetCustomerWithPaymentMethodsById';
import { countries } from '../../functions/iso3166-alpha2';
import { usStates } from '../../functions/usStates';
import { caStates } from '../../functions/caStates';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { CurrencyInput } from '../../components/forms/CurrencyInput';
import { SelectCard } from '../../components/forms/SelectCard';
import { useLocation } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';
import {
  EditSubscriptionRequest,
  useEditSubscription,
} from '../../hooks/useEditSubscription';
import { Currency } from '../../interfaces/state';
import { formatPrice, handleNotSignedIn } from '../../functions/helpers';

const EditSubscriptionRoute = (): ReactElement => {
  const { subscriptionState, profileState } = useGlobalDataState();
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const [customerId, setCustomerId] = useState<any>();
  const [, , paymentMethods] = useGetCustomerWithPaymentMethodsById(customerId);
  const [paymentMethod, setPaymentMethod] = useState<string>('customer');
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [currency, setCurrency] = useState<Currency>();
  const [pence, setPence] = useState<number>(0);
  const [unit, setUnit] = useState<string>('Months');
  const [count, setCount] = useState<string>('');
  const [times, setTimes] = useState<string>('');
  const [billingCycleTimestamp, setBillingCycleTimestamp] = useState<
    number | undefined
  >();
  const [metaData, setMetaData] = useState<KeyValue[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lineOne, setLineOne] = useState<string>('');
  const [lineTwo, setLineTwo] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [descriptor, setDescriptor] = useState<string>('');
  const [descriptorCity, setDescriptorCity] = useState<string>('');
  const [canEditPrice, setCanEditPrice] = useState<boolean>(false);
  const [canEditStartDate, setCanEditStartDate] = useState<boolean>(false);
  const [isShippingAddrVis, setIsShippingAddrVis] = useState<boolean>(false);
  const [isStatementDescVis, setIsStatementDescVis] = useState<boolean>(false);
  const [isMetaDataVis, setIsMetaDataVis] = useState<boolean>(false);
  const [editSubscription, isLoading, isEdited, error] = useEditSubscription();

  const [penceError, setPenceError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');

  const useQuery = (): any => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const idFromUrl = query.get('id');

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('SubscriptionsModify')) {
      history.push('/subscriptions');
    }
  }, [isSignedIn]);

  useEffect(() => {
    isEdited && history.push('/subscriptions');
    // eslint-disable-next-line no-console
    console.log({ isLoading, isEdited, error });
  }, [isLoading, isEdited, error]);

  useEffect(() => {
    if (subscriptionState?.data?.items) {
      const subscription = subscriptionState?.data?.items.find(
        (item) => item.id === idFromUrl
      );
      if (subscription) {
        setCanEditStartDate(subscription.status === 'Pending');
        setCanEditPrice(
          subscription.status === 'Pending' || subscription.status === 'Active'
        );
        setCustomerId(subscription.customer.id);
        if (subscription.paymentMethod) {
          setPaymentMethod('card');
          setPaymentMethodId(subscription.paymentMethod.id);
        }
        setDescription(subscription.description || '');
        setCurrency(subscription.price.currency);
        setPence(subscription.price.amount);
        setUnit(subscription.price.interval.unit);
        setCount(String(subscription.price.interval.count));
        setTimes(String(subscription.price.interval.times || ''));
        setBillingCycleTimestamp(
          subscription.billingDetail.billingCycleTimestamp
        );

        if (subscription?.paymentSettings) {
          setIsStatementDescVis(true);
          setDescriptor(
            subscription.paymentSettings.statementDescriptor.descriptor
          );
          setDescriptorCity(
            subscription.paymentSettings.statementDescriptor.city
          );
        }

        if (subscription?.shippingDetails) {
          setIsShippingAddrVis(true);
          setFirstName(subscription.shippingDetails.address.firstName || '');
          setLastName(subscription.shippingDetails.address.lastName || '');
          setLineOne(subscription.shippingDetails.address.lineOne || '');
          setLineTwo(subscription.shippingDetails.address.lineTwo || '');
          setCity(subscription.shippingDetails.address.city || '');
          setRegion(subscription.shippingDetails.address.region || '');
          setPostalCode(subscription.shippingDetails.address.postalCode);
          setCountry(subscription.shippingDetails.address.country);
        }

        if (subscription?.metadata) {
          setIsMetaDataVis(true);
          setMetaData(
            Object.entries(subscription.metadata).map(([key, value]) => ({
              key,
              value,
            }))
          );
        }
      }
    }
  }, [subscriptionState]);

  const maxStartDate = (): Date => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
  };

  const handleEditSubscription = async (): Promise<void> => {
    let valid = true;
    const minPence = 30;
    const maxPence = 100000;
    if (!currency) return;
    if (pence < minPence || pence > maxPence) {
      const minPrice = formatPrice({ pence: minPence, currency: currency });
      const maxPrice = formatPrice({ pence: maxPence, currency: currency });
      setPenceError(`Amount must be between ${minPrice} and ${maxPrice}`);
      valid = false;
    }
    if (description.length < 5) {
      setDescriptionError('description must contain at least 5 characters');
      valid = false;
    }
    if (!valid) return;
    const editRequest: EditSubscriptionRequest = {
      price: canEditPrice
        ? {
            amount: pence,
            interval: {
              unit,
              count: parseInt(count),
              times: parseInt(times),
            },
          }
        : undefined,
      paymentMethod:
        paymentMethod === 'card' ? { id: paymentMethodId } : undefined,
      description: description || undefined,
      billingCycleTimestamp:
        canEditStartDate && billingCycleTimestamp
          ? billingCycleTimestamp
          : undefined,
      metadata:
        metaData && metaData.length
          ? Object.fromEntries(metaData.map((item) => [item.key, item.value]))
          : undefined,
      shippingDetails: isShippingAddrVis
        ? {
            address: {
              firstName: firstName || undefined,
              lastName: lastName || undefined,
              lineOne: lineOne || undefined,
              lineTwo: lineTwo || undefined,
              city: city || undefined,
              country,
              postalCode,
              region: region || undefined,
            },
          }
        : undefined,
      paymentSettings:
        isStatementDescVis && descriptor && descriptorCity
          ? {
              statementDescriptor: {
                descriptor,
                city: descriptorCity,
              },
            }
          : undefined,
    };
    await editSubscription(editRequest, idFromUrl);
  };

  return (
    <LayoutIllustration
      title={
        <>
          Edit a <span>Ryft</span> subscription
        </>
      }
      coBrandedTitle={<>Edit a Subscription</>}
    >
      <div style={{ padding: '0 12px' }}>
        {customerId && (
          <>
            {paymentMethods && paymentMethods?.length > 0 && !paymentMethodId && (
              <SelectInput
                label
                name='Payment method'
                value={paymentMethod}
                setValue={setPaymentMethod}
                options={[
                  { value: 'card', name: 'Select a saved card' },
                  {
                    value: 'customer',
                    name: 'Customer will enter payment details',
                  },
                ]}
              />
            )}
            {paymentMethod === 'card' && (
              <SelectCard
                paymentMethods={paymentMethods}
                selected={paymentMethodId}
                setSelected={setPaymentMethodId}
              />
            )}

            <TextInput
              name='Description'
              value={description}
              setValue={setDescription}
              label
              description={
                'The description of a subscription can serve as its unique identifier'
              }
            />
            {descriptionError && (
              <div
                style={{ color: '#ec3f3f', position: 'relative', top: '-6px' }}
              >
                {descriptionError}
              </div>
            )}
            {canEditPrice && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 120px',
                    gridGap: '12px',
                  }}
                >
                  <TextInput
                    label
                    name='Currency'
                    value={currency?.name ?? ''}
                    disabled={true}
                  />
                  <CurrencyInput
                    currency={currency}
                    pence={pence}
                    setPence={setPence}
                  />
                  {penceError && (
                    <div
                      style={{
                        color: '#ec3f3f',
                        position: 'relative',
                        top: '-6px',
                      }}
                    >
                      {penceError}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '12px',
                  }}
                >
                  <SelectInput
                    label
                    name='Interval'
                    value={unit}
                    setValue={setUnit}
                    options={[
                      { value: 'Days', name: 'Daily' },
                      { value: 'Months', name: 'Monthly' },
                    ]}
                    selectPrompt='Interval...'
                  />
                  <TextInput
                    name='Count'
                    value={count}
                    setValue={setCount}
                    label
                    placeHolder='0'
                  />
                  <TextInput
                    name='Times'
                    value={times != undefined ? times : ''}
                    setValue={setTimes}
                    label
                    optional
                  />
                </div>
                <small
                  style={{
                    fontSize: '11px',
                    marginBottom: '24px',
                    display: 'block',
                    color: '#909090',
                  }}
                >
                  Billed every {+count > 1 ? count : ''}{' '}
                  {unit === 'Months' ? 'month' : 'day'}
                  {+count > 1 ? 's' : ''}{' '}
                  {times === ''
                    ? 'until canceled'
                    : 'for a total of ' + times + ' payments'}
                </small>
              </>
            )}
            {canEditStartDate && (
              <>
                <p>Start date</p>
                <DatePicker
                  type='START'
                  name='Start Date'
                  value={billingCycleTimestamp}
                  setValue={setBillingCycleTimestamp}
                  notBefore={new Date()}
                  notAfter={maxStartDate()}
                />
              </>
            )}
            <div style={{ margin: '22px 0' }}>
              <p style={{ marginBottom: '12px' }}>Additional options</p>
              <CheckBox
                setValue={setIsShippingAddrVis}
                value={isShippingAddrVis}
                id='1'
                label='Shipping details'
              />
              <CheckBox
                setValue={setIsStatementDescVis}
                value={isStatementDescVis}
                id='2'
                label='Statement descriptor'
              />
              <CheckBox
                setValue={setIsMetaDataVis}
                value={isMetaDataVis}
                id='3'
                label='Metadata'
              />
            </div>
            {isMetaDataVis && (
              <MetaData
                metaData={metaData}
                setMetaData={setMetaData}
                optional
              />
            )}
            {isStatementDescVis && (
              <>
                <div>
                  <TextInput
                    name='Statement Descriptor'
                    value={descriptor}
                    setValue={setDescriptor}
                    label
                    optional
                  />
                  <TextInput
                    name='City'
                    value={descriptorCity}
                    setValue={setDescriptorCity}
                    label
                    description='Statement descriptor and city are shown on the customers bank statement'
                  />
                </div>
              </>
            )}
            {isShippingAddrVis && (
              <div>
                <SelectInput
                  label
                  name='Country'
                  value={country}
                  setValue={setCountry}
                  options={countries()}
                />

                <TextInput
                  name='Postal Code'
                  value={postalCode}
                  setValue={setPostalCode}
                  label
                />

                <TextInput
                  name='First Name'
                  value={firstName}
                  setValue={setFirstName}
                  label
                />
                <TextInput
                  name='Last Name'
                  value={lastName}
                  setValue={setLastName}
                  label
                />

                <TextInput
                  name='Address Line 1'
                  value={lineOne}
                  setValue={setLineOne}
                  label
                />

                <TextInput
                  name='Address Line 2'
                  value={lineTwo}
                  setValue={setLineTwo}
                  label
                />

                <TextInput
                  name='Town / City'
                  value={city}
                  setValue={setCity}
                  label
                />

                {country !== 'US' && country !== 'CA' && (
                  <TextInput
                    name='Region'
                    value={region}
                    setValue={setRegion}
                    label
                  />
                )}
                {country === 'US' && (
                  <SelectInput
                    label
                    name='US State'
                    value={region}
                    setValue={setRegion}
                    options={usStates()}
                  />
                )}
                {country === 'CA' && (
                  <SelectInput
                    label
                    name='CA Province'
                    value={region}
                    setValue={setRegion}
                    options={caStates()}
                  />
                )}
              </div>
            )}
            {error && (
              <div style={{ color: '#ec3f3f', position: 'relative' }}>
                {error}
              </div>
            )}
            <Button name='Edit Subscription' click={handleEditSubscription} />
          </>
        )}
        <Button
          name='Cancel'
          color='GREY'
          click={(): void => history.push(`/subscriptions?id=${idFromUrl}`)}
        />
      </div>
    </LayoutIllustration>
  );
};

export { EditSubscriptionRoute };
