import React, { useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { ConfirmEmail } from '../components/onboarding/Confirm';
import confirmCode from '../images/illustrations/confirm-code.png';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';

const ConfirmRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    if (isSignedIn) {
      history.push('/');
    }
  }, []);

  return (
    <LayoutIllustration
      image={confirmCode}
      title={
        <>
          Create your <span>Ryft</span> account
        </>
      }
      subTitle={
        <>
          We sent you an email to <span>{localStorage.getItem('email')}</span>,
          please enter the confirmation code below
        </>
      }
    >
      <ConfirmEmail />
    </LayoutIllustration>
  );
};

export { ConfirmRoute };
