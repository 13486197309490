import React, { useEffect } from 'react';
import InlineMenu from '../layouts/InlineMenu';
import { DateTime } from '../layouts/DateTime';
import { Copy } from '../forms/Copy';
import { useGetSubAccountById } from '../../hooks/useGetSubAccountById';
import { epochSecondsToDate } from '../../functions/helpers';

interface SubAccountProps {
  id: string;
}

const SubAccount: React.FC<SubAccountProps> = ({ id }) => {
  const { subAccount, isLoading, error, fetchSubAccount } =
    useGetSubAccountById();

  useEffect(() => {
    fetchSubAccount(id);
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
    );
  }
  if (error || !subAccount) {
    return <div>{error || 'Failed to get sub account details'}</div>;
  }

  return (
    <div className='SubAccountSharedTab'>
      <div className='SubAccountSharedTab--header'>
        <h2>{subAccount.name || '(unregistered sub account)'}</h2>
        <InlineMenu
          items={[
            {
              label: 'View Sub Account',
              url: `/accounts?id=${subAccount.id}`,
            },
            {
              label: 'View Payments',
              url: `/payments?accountId=${subAccount.id}&accountType=subAccounts`,
            },
          ]}
        />
      </div>
      <div className='SubAccountSharedTab--middle'>
        <div className='SubAccountSharedTab--middle-item'>
          <div>Type</div>
          <div>{subAccount.type || '(unregistered)'}</div>
        </div>
        <div className='SubAccountSharedTab--middle-item'>
          <div>Email</div>
          <div>{subAccount.email || '(unregistered)'}</div>
        </div>
        <div className='SubAccountSharedTab--middle-item'>
          <div>Created</div>
          <div>
            <DateTime
              showTime
              value={epochSecondsToDate(subAccount.createdTimestamp)}
            />
          </div>
        </div>
      </div>
      <div
        className='SubAccountSharedTab--footer'
        style={{ marginTop: '12px' }}
      >
        <div>Id</div>
        <div>
          <Copy
            type='BUTTON_WITH_TEXT'
            buttonSize='MEDIUM'
            value={subAccount.id}
            text={subAccount.id}
          />
        </div>
      </div>
    </div>
  );
};

export default SubAccount;
