import { ReactElement, useEffect, useState } from 'react';
import { LayoutIllustration } from '../../layout-illustration';
import { Button } from '../../components/forms/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { RadioButtons } from '../../components/forms/RadioButtons';
import { TextInput } from '../../components/forms/TextInput';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { SelectInput } from '../../components/forms/SelectInput';
import { epochToDateString, handleNotSignedIn } from '../../functions/helpers';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useAdjustSubscription } from '../../hooks/useAdjustSubscription';
import { OptionShape } from '../../interfaces/state';

const AdjustSubscriptionRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { subscriptionState, profileState } = useGlobalDataState();
  const [cancelSubscription, pauseSubscription, isLoading, isComplete, error] =
    useAdjustSubscription();
  const [selectedValue, setSelectedValue] = useState<string | number>('pause');
  const [canPause, setCanPause] = useState<boolean>(false);
  const [canCancel, setCanCancel] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [reasonError, setReasonError] = useState<string>('');
  const [resumeDateOptions, setResumeDateOptions] = useState<any>([]);
  const [scheduledPauseDate, setScheduledPauseDate] = useState<string>();
  const [isEditingPause, setIsEditingPause] = useState<boolean>();
  const [isPauseScheduled, setIsPauseScheduled] = useState<boolean>();
  const [resumeAtTimestamp, setResumeAtTimestamp] = useState<string>('');

  const useQuery = (): any => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const idFromUrl = query.get('id');

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('SubscriptionsModify')) {
      history.push('/subscriptions');
    }
  }, [isSignedIn]);

  useEffect(() => {
    isComplete && history.push('/subscriptions');
    // eslint-disable-next-line no-console
    console.log({ isLoading, isComplete, error });
  }, [isLoading, isComplete, error]);

  useEffect(() => {
    if (subscriptionState?.data?.items) {
      const subscription = subscriptionState.data.items.find(
        (item) => item.id === idFromUrl
      );
      if (subscription) {
        const isActive = subscription.status === 'Active';
        const canPause = isActive || subscription.status === 'Paused';
        setCanPause(canPause);
        setReason(subscription.pausePaymentDetail?.reason || '');
        if (subscription.pausePaymentDetail?.resumeAtTimestamp) {
          setResumeAtTimestamp(
            `${subscription.pausePaymentDetail.resumeAtTimestamp}`
          );
        }
        const hasPauseDetail = !!subscription.pausePaymentDetail;
        setIsEditingPause(hasPauseDetail);
        const pauseScheduled = isActive && hasPauseDetail;
        setIsPauseScheduled(pauseScheduled);
        if (pauseScheduled) {
          setSelectedValue('unschedulePause');
        }
        const pauseAtTimestamp =
          subscription.pausePaymentDetail?.pausedAtTimestamp ??
          subscription.billingDetail.nextBillingTimestamp;
        if (isActive && pauseAtTimestamp) {
          setScheduledPauseDate(epochToDateString(pauseAtTimestamp));
        }
        const epochs = subscription.pauseSettings?.eligibleResumeAtTimestamps;
        if (epochs) {
          const options = epochs.map((epoch) => {
            return {
              value: epoch.toString(),
              name: epochToDateString(epoch),
            };
          });
          options.push({
            value: '',
            name: 'None',
          });
          setResumeDateOptions(options);
        }
        setCanCancel(
          subscription.status === 'Pending' ||
            subscription.status === 'Active' ||
            subscription.status === 'PastDue'
        );
      }
    }
  }, [subscriptionState]);

  const handlePause = (): void => {
    let valid = true;
    if (reason && reason.length < 5) {
      setReasonError('reason must contain at least 5 characters');
      valid = false;
    }
    if (!valid) return;
    pauseSubscription(
      { reason: reason || undefined, resumeAtTimestamp },
      idFromUrl
    );
  };

  const handleUnschedulePause = (): void => {
    pauseSubscription({ unschedule: true }, idFromUrl);
  };

  const handleCancel = (): void => {
    let valid = true;
    if (reason && reason.length < 5) {
      setReasonError('reason must contain at least 5 characters');
      valid = false;
    }
    if (!valid) return;
    cancelSubscription({ reason: reason || undefined }, idFromUrl);
  };

  const actionChanged = (value: string | number): void => {
    if (value === 'cancel') {
      setReason('');
    }
    setSelectedValue(value);
  };

  const calcActionOptions = (): OptionShape[] => {
    const arr: OptionShape[] = [];
    if (isPauseScheduled) {
      arr.push({ name: 'Unschedule Pause', value: 'unschedulePause' });
    }
    if (canPause && isEditingPause) {
      arr.push({ name: 'Edit Pause', value: 'editPause' });
    }
    if (canPause && !isEditingPause) {
      arr.push({ name: 'Schedule Pause', value: 'pause' });
    }
    if (canCancel) {
      arr.push({ name: 'Cancel Subscription', value: 'cancel' });
    }
    if (arr.length === 1 && selectedValue !== arr[0].value) {
      setSelectedValue(arr[0].value);
    }

    return arr as OptionShape[];
  };

  return (
    <LayoutIllustration
      title={
        <>
          Adjust a <span>Ryft</span> subscription
        </>
      }
      subTitle={
        <p>
          Depending on the subscriptions state you may be able to Pause or
          Cancel it.
        </p>
      }
      coBrandedTitle={<>Adjust a subscription</>}
    >
      <div style={{ padding: '0 12px' }}>
        <RadioButtons
          name='Available actions'
          options={calcActionOptions()}
          onSelect={actionChanged}
          layout='horizontal'
        />
        {selectedValue !== 'unschedulePause' && (
          <TextInput
            name='Description'
            value={reason}
            setValue={setReason}
            label
            optional
            description={
              'A description as a reminder about why this action was taken'
            }
          />
        )}
        {reasonError && (
          <div style={{ color: '#ec3f3f', position: 'relative', top: '-6px' }}>
            {reasonError}
          </div>
        )}
        {(selectedValue === 'pause' || selectedValue === 'editPause') && (
          <>
            {scheduledPauseDate && (
              <TextInput
                label
                name='Date to pause'
                value={scheduledPauseDate}
                disabled={true}
                description='This subscription will be paused at the start of the next cycle'
              />
            )}
            <SelectInput
              label
              optional
              name='Date to resume'
              value={resumeAtTimestamp}
              setValue={setResumeAtTimestamp}
              options={resumeDateOptions}
              description='Leave this blank or select None to pause indefinitely'
            />
          </>
        )}
        <Button
          name={
            selectedValue === 'pause'
              ? 'Schedule Pause'
              : selectedValue === 'editPause'
              ? 'Edit Pause'
              : selectedValue === 'unschedulePause'
              ? 'Unschedule Pause'
              : 'Cancel Subscription'
          }
          click={
            selectedValue === 'pause' || selectedValue === 'editPause'
              ? handlePause
              : selectedValue === 'unschedulePause'
              ? handleUnschedulePause
              : handleCancel
          }
        />
        {error && (
          <div style={{ color: '#ec3f3f', position: 'relative' }}>{error}</div>
        )}
        <Button
          name='Cancel'
          color='GREY'
          click={(): void => history.push(`/subscriptions?id=${idFromUrl}`)}
        />
      </div>
    </LayoutIllustration>
  );
};

export { AdjustSubscriptionRoute };
