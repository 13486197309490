import { ReactElement } from 'react';
import { ApplePayWebDomains } from '../dataGrids/ApplePayWebDomains';
import { ApplePayCertificates } from '../dataGrids/ApplePayCertificates';

const ApplePaySettings = (): ReactElement => {
  return (
    <>
      <ApplePayWebDomains />
      <ApplePayCertificates />
    </>
  );
};
export { ApplePaySettings };
