import { CardScheme } from '../interfaces/state';

function getEciDescription(eci?: string): string {
  if (eci == '00' || eci == '07') {
    return 'Unsuccessful or not attempted. The credit card is either a non-3D card or card issuing bank does not handle it as a 3D transaction';
  }
  if (eci == '01' || eci == '06') {
    return 'Authentication was attempted but was not or could not be completed; possible reasons being either the card or its Issuing Bank has yet to participate in 3DS';
  }
  if (eci == '02' || eci == '05') {
    return 'Both cardholder and card issuing bank are 3D enabled. 3D card authentication is successful';
  }
  return '';
}

function getTransactionStatusReasonDescription(
  reason?: string,
  scheme?: CardScheme
): string {
  if (reason == '01') {
    return 'Card authentication failed';
  }
  if (reason == '02') {
    return 'Unknown device';
  }
  if (reason == '03') {
    return 'Unsupported device';
  }
  if (reason == '04') {
    return 'Exceeds authentication frequency limit';
  }
  if (reason == '05') {
    return 'Expired card';
  }
  if (reason == '06') {
    return 'Invalid card number';
  }
  if (reason == '07') {
    return 'Invalid transaction';
  }
  if (reason == '08') {
    return 'No card record';
  }
  if (reason == '09') {
    return 'Security Failure';
  }
  if (reason == '10') {
    return 'Stolen card';
  }
  if (reason == '11') {
    return 'Suspected fraud';
  }
  if (reason == '12') {
    return 'Transaction not permitted to cardholder';
  }
  if (reason == '13') {
    return 'Cardholder not enrolled in service';
  }
  if (reason == '14') {
    return 'Transaction timed out at ACS';
  }
  if (reason == '15') {
    return 'Low confidence';
  }
  if (reason == '16') {
    return 'Medium confidence';
  }
  if (reason == '17') {
    return 'High confidence';
  }
  if (reason == '18') {
    return 'Very high confidence';
  }
  if (reason == '19') {
    return 'Exceeds ACS maximum challenges';
  }
  if (reason == '20') {
    return 'Non-payment transaction not supported';
  }
  if (reason == '21') {
    return '3RI transaction not supported';
  }
  if (reason == '22') {
    return 'ACS technical issue';
  }
  if (reason == '23') {
    return 'Decoupled Authentication required by ACS but not requested by 3DS Requestor';
  }
  if (reason == '24') {
    return '3DS Requestor decoupled max expiry time exceeded';
  }
  if (reason == '25') {
    return 'Decoupled Authentication was provided insufficient time to authenticate cardholder. ACS will not make attempt';
  }
  if (reason == '26') {
    return 'Authentication attempted but not performed by the cardholder';
  }
  if (reason == '80' && scheme == 'Visa') {
    return 'Error connecting to ACS';
  }
  if (reason == '80' && scheme == 'Mastercard') {
    return 'Returned on all Data Only authentications';
  }
  if (reason == '80' && scheme == 'Amex') {
    return 'Safekey is not available for this type of card';
  }
  if (reason == '81' && scheme == 'Visa') {
    return 'ACS Timed out';
  }
  if (reason == '81' && scheme == 'Mastercard') {
    return 'Challenge exemption accepted';
  }
  if (reason == '82' && scheme == 'Visa') {
    return 'Invalid response from ACS';
  }
  if (reason == '82' && scheme == 'Mastercard') {
    return 'Challenge Mandate requested but could not be performed';
  }
  if (reason == '83' && scheme == 'Visa') {
    return 'System Error response from ACS';
  }
  if (reason == '83' && scheme == 'Mastercard') {
    return 'DS dropped reason code received from DS';
  }
  if (reason == '84' && scheme == 'Visa') {
    return 'Internal Error While Generating CAVV';
  }
  if (reason == '84' && scheme == 'Mastercard') {
    return 'ChallengeCancel populated therefore did not route to Smart Authentication Stand-In (Makes authentication decision when ACS is not available)';
  }
  if (reason == '85' && scheme == 'Visa') {
    return 'VMID not eligible for requested program';
  }
  if (reason == '86' && scheme == 'Visa') {
    return 'Protocol version not supported by ACS.';
  }
  if (reason == '87' && scheme == 'Visa') {
    return 'Transaction is excluded from Attempts Processing (includes non- reloadable pre-paid cards and non-payments (NPA))';
  }
  if (reason == '88' && scheme == 'Visa') {
    return 'Requested program not supported by ACS';
  }
  return '';
}

function getTransStatusDescription(transStatus?: string): string {
  if (transStatus == 'Y') {
    return 'Authentication Successful';
  }
  if (transStatus == 'N') {
    return 'Not authenticated; Transaction denied';
  }
  if (transStatus == 'U') {
    return 'Authentication could not be performed';
  }
  if (transStatus == 'A') {
    return 'Attempts Processing Performed; Not authenticated';
  }
  if (transStatus == 'C') {
    return 'Challenge Required';
  }
  if (transStatus == 'D') {
    return 'Challenge Required; Decoupled Authentication confirmed';
  }
  if (transStatus == 'R') {
    return 'Authentication Rejected; Issuer is rejecting authentication/verification and request that authorisation not be attempted';
  }
  if (transStatus == 'I') {
    return 'Informational Only; 3DS Requestor challenge preference acknowledged';
  }
  return '';
}

export {
  getEciDescription,
  getTransactionStatusReasonDescription,
  getTransStatusDescription,
};
