import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { AlmostDone } from '../components/onboarding/Almost';
import yourAccount from '../images/illustrations/your-account.png';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';

const AlmostRoute = (): React.ReactElement => {
  const history = useHistory();
  const [accountType, setAccountType] = useState<string>(
    localStorage.getItem('accountType') || ''
  );
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    async function checkUser(): Promise<void> {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        Sentry.captureException(err);
        history.push('/');
      }
    }
    if (isSignedIn) {
      history.push('/');
    } else {
      checkUser();
    }
  }, []);

  return (
    <LayoutIllustration
      image={yourAccount}
      title={
        <>
          Create your <span>Ryft</span> account
        </>
      }
      subTitle={
        <>
          Tell us a bit about{' '}
          {`${
            accountType === 'Business'
              ? 'your business'
              : accountType === 'Individual'
              ? 'yourself'
              : 'you or your business'
          }`}
        </>
      }
    >
      <AlmostDone accountType={accountType} setAccountType={setAccountType} />
    </LayoutIllustration>
  );
};

export { AlmostRoute };
