import { useEffect, useState } from 'react';
import { PaymentsShape } from '../interfaces/paymentState';
import {
  RebillingDetailRequest,
  ShippingDetailsRequest,
} from '../interfaces/requestState';
import { ApiOperation, HttpMethod, useApiRequest } from './useApiRequest';

export type CaptureFlow = 'Manual' | 'Automatic';

export interface CreatePaymentSessionRequest {
  amount: number;
  currency: string;
  customerEmail?: string;
  customerDetails?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    metadata?: any;
    createdTimestamp?: number;
  };
  shippingDetails?: ShippingDetailsRequest;
  captureFlow?: CaptureFlow;
  paymentType?: string;
  metadata?: { [key: string]: string };
  rebillingDetail?: RebillingDetailRequest;
}

export interface CreatePaymentSessionResponse {
  paymentSession: PaymentsShape;
  publicApiKey: string;
}

const useCreatePaymentSession = (): ApiOperation<any> => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] =
    useApiRequest<CreatePaymentSessionRequest>();

  useEffect(() => {
    if (!isLoading) {
      setIsCompleted(true);
    }
  }, [isLoading]);

  const createPaymentSession = (
    body: CreatePaymentSessionRequest
  ): Promise<void> => {
    setIsCompleted(false);
    const url = `/v1/payment-sessions`;
    const method = HttpMethod.POST;
    return sendRequest({ url, method, body });
  };

  return {
    isLoading: isLoading,
    error: error,
    request: createPaymentSession,
    response: response,
    complete: isCompleted,
  };
};

export { useCreatePaymentSession };
