import React, { ReactElement, useEffect, useState } from 'react';
import { Pagination } from '../layouts/Pagination';
import { isMobile } from '../../functions/helpers';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { request } from '../../functions/callApi';
import { PlatformFeesShape } from '../../interfaces/feesState';
import { PlatformFeeDetailLayout } from '../layouts/PlatformFeeDetailLayout';
import { PaymentDetailLayout } from '../layouts/PaymentDetailLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { PaymentsShape } from '../../interfaces/paymentState';

interface PlatformFeeDetailProps {
  platformFee?: PlatformFeesShape | null;
  hasPrevious: boolean;
  handlePrevious: () => void;
  hasNext: boolean;
  handleNext: () => void;
}

const PlatformFeeDetail = ({
  platformFee,
  hasPrevious,
  handlePrevious,
  hasNext,
  handleNext,
}: PlatformFeeDetailProps): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const [payment, setPayment] = useState<PaymentsShape>();

  useEffect(() => {
    const callApi = async (
      paymentSessionId: string,
      subAccountId: string
    ): Promise<void> => {
      const apiUrl = `/v1/transactions?paymentSessionId=${paymentSessionId}&accountId=${subAccountId}`;
      const response = await request<any>(apiUrl);
      if (response?.status === 200) {
        setPayment(response.paymentSessions.items[0]);
      }
    };
    platformFee?.paymentSessionId &&
      platformFee?.paymentSessionId &&
      callApi(platformFee.paymentSessionId, platformFee.subAccountId);
  }, [platformFee]);

  return (
    <div className='PlatformFeeDetail'>
      <div className='PlatformFeeDetail--top'>
        <div className='PlatformFeeDetail--title-container'>
          <div className='PlatformFeeDetail--title'>Platform Fee</div>
          {!isMobile() && <div className='PlatformFeeDetail--esc'>Esc</div>}
        </div>
        {!isMobile() && (
          <div className='PlatformFeeDetail--pagination'>
            <Pagination
              labels
              goPrevious={(): any => handlePrevious()}
              goNext={(): any => handleNext()}
              previousDisabled={!platformFee || !hasPrevious}
              nextDisabled={!platformFee || !hasNext}
            />
          </div>
        )}
        <div className='PlatformFeeDetail--data-container'>
          {platformFee && <PlatformFeeDetailLayout data={platformFee} />}
          {payment && (
            <>
              <PaymentDetailLayout
                data={payment}
                isStandardAccount={profile.group === 'STANDARD'}
                accountId={profile.accountId}
              />
            </>
          )}
        </div>
      </div>
      <div className='PlatformFeeDetail--middle'></div>
      <div className='PlatformFeeDetail--bottom'>
        <div className='PlatformFeeDetail--buttons'>
          {isMobile() && (
            <Button
              name='Back'
              color='GREY'
              click={(): any => history.push(`/platform-fees`)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { PlatformFeeDetail };
