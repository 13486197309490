import { ReactElement, FormEvent, useState, useEffect, useMemo } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { TextInput } from '../../../components/forms/TextInput';
import { DateOfBirth } from '../../../components/forms/DateOfBirth';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { Warning } from '../../../components/forms/Warning';
import SelectTags from '../../../components/forms/SelectTags';
import { StepPosition } from '../../../components/forms/StepPosition';
import { countries } from '../../../functions/iso3166-alpha2';
import { BusinessRole } from '../../../interfaces/accountState';
import {
  availableBusinessRoles,
  getLetterOfAuthWarningText,
} from '../../../functions/personHelpers';
import {
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { OptionShape } from '../../../interfaces/state';
import { SelectCheckBox } from '../../../components/forms/SelectCheckBox';

export interface AddIndividualDetails {
  businessRoles: BusinessRole[];
  firstName: string;
  middleNames?: string;
  lastName: string;
  dateOfBirth: string;
  countryOfBirth?: string;
  gender: string;
  nationalities: string[];
}

const AddIndividualRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { coBrandingState, profileState, personsState } = useGlobalDataState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [businessRoles, setBusinessRoles] = useState<BusinessRole[]>([]);
  const [businessRolesError, setBusinessRolesError] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [middleNames, setMiddleNames] = useState<string>('');
  const [middleNamesError, setMiddleNamesError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [countryOfBirth, setCountryOfBirth] = useState<string>('');
  const [countryOfBirthError, setCountryOfBirthError] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [nationalities, setNationalities] = useState<any[]>([]);
  const [nationalitiesError, setNationalitiesError] = useState<string>('');
  const [showLetterOfAuthWarning, setShowLetterOfAuthWarning] =
    useState<boolean>(false);
  const [addIndividualDetails, setAddIndividualDetails] =
    useState<AddIndividualDetails>();

  useEffect(() => {
    const addIndividualDetailsString = localStorage.getItem(
      'addIndividualDetails'
    );
    if (addIndividualDetailsString) {
      setAddIndividualDetails(JSON.parse(addIndividualDetailsString));
    }
  }, [localStorage]);

  useEffect(() => {
    if (
      businessRoles.length > 0 &&
      firstName &&
      lastName &&
      dateOfBirth &&
      gender &&
      nationalities.length > 0
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [businessRoles, firstName, lastName, dateOfBirth, gender, nationalities]);

  useEffect(() => {
    const businessRolesSet = new Set(businessRoles);
    setShowLetterOfAuthWarning(
      businessRolesSet.has('BusinessContact') &&
        !businessRolesSet.has('Director')
    );
  }, [businessRoles]);

  useEffect(() => {
    if (addIndividualDetails) {
      setFirstName(addIndividualDetails.firstName);
      setMiddleNames(addIndividualDetails.middleNames ?? '');
      setLastName(addIndividualDetails.lastName);
      setDateOfBirth(addIndividualDetails.dateOfBirth);
      setCountryOfBirth(addIndividualDetails.countryOfBirth ?? '');
      setGender(addIndividualDetails.gender);
      setNationalities(addIndividualDetails.nationalities);
      const availableRoles = new Set(
        availableBusinessRoles(isOnboarding, personsState?.data?.items).map(
          (option: OptionShape) => option.value
        )
      );
      setBusinessRoles(
        addIndividualDetails.businessRoles.filter((role: BusinessRole) =>
          availableRoles.has(role)
        )
      );
    }
  }, [addIndividualDetails]);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !permissions.has('PersonsModify') ||
      profile.processingModel === 'Iso'
    ) {
      history.push(returnTo);
      return;
    }
  }, [isSignedIn]);

  const validateIndividual = async (): Promise<void> => {
    setBusinessRolesError('');
    setFirstNameError('');
    setMiddleNamesError('');
    setLastNameError('');
    setDateOfBirthError('');
    setServerError('');
    setCountryOfBirthError('');
    setGenderError('');
    setNationalitiesError('');

    setIsLoading(false);

    let valid = true;

    if (businessRoles.length < 1) {
      valid = false;
      setBusinessRolesError('You must add at least one nationality');
    }
    if (firstName.trim().length < 1) {
      valid = false;
      setFirstNameError('First Name cannot be empty');
    }
    if (lastName.trim().length < 1) {
      valid = false;
      setLastNameError('Last Name cannot be empty');
    }
    if (dateOfBirth.trim().length < 1) {
      valid = false;
      setDateOfBirthError('Date Of Birth cannot be empty');
    }
    if (gender.trim().length < 1) {
      valid = false;
      setDateOfBirthError('You must select a gender');
    }
    if (nationalities.length < 1) {
      valid = false;
      setNationalitiesError('You must add at least one nationality');
    }
    if (!valid) {
      return;
    }
    const addIndividualDetails: AddIndividualDetails = {
      businessRoles,
      firstName,
      middleNames: middleNames || undefined,
      lastName,
      dateOfBirth,
      countryOfBirth: countryOfBirth || undefined,
      gender,
      nationalities,
    };
    localStorage.setItem(
      'addIndividualDetails',
      JSON.stringify(addIndividualDetails)
    );
    history.push(
      constructFullPath(
        '/settings/individuals/add-individual-contact',
        isOnboarding,
        returnTo
      )
    );
  };

  return (
    <LayoutIllustration
      maxWidth='50%'
      title={
        <>
          Add an <span>individual</span>
        </>
      }
      coBrandedTitle={
        <>
          Add an individual for <span>{coBrandingState?.data?.name}</span>{' '}
          account
        </>
      }
      subTitle={
        <>
          About the <span>business person</span>
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <StepPosition steps={3} position={1} />
        <ServerError error={serverError} />
        <SelectCheckBox
          name='Business roles'
          grid='ONE_COLUMN'
          description='Please select all that apply'
          allValues={availableBusinessRoles(
            isOnboarding,
            personsState?.data?.items
          )}
          selectedValues={businessRoles}
          setSelectedValues={setBusinessRoles}
          error={businessRolesError}
        />
        <Warning
          warning={getLetterOfAuthWarningText(showLetterOfAuthWarning)}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            gridGap: '12px',
            alignItems: 'end',
          }}
        >
          <TextInput
            label
            name='Name'
            value={firstName}
            placeHolder='First'
            setValue={setFirstName}
            error={firstNameError}
          />
          <TextInput
            name='Middle Name'
            value={middleNames}
            placeHolder='Middle'
            setValue={setMiddleNames}
            error={middleNamesError}
            optional
          />
          <TextInput
            name='Last Name'
            value={lastName}
            placeHolder='Last'
            setValue={setLastName}
            error={lastNameError}
          />
        </div>
        <DateOfBirth
          value={dateOfBirth}
          setValue={setDateOfBirth}
          error={dateOfBirthError}
          label
        />
        <SelectInput
          label
          name='Country of birth'
          value={countryOfBirth}
          setValue={setCountryOfBirth}
          options={countries()}
          error={countryOfBirthError}
          optional
        />
        <SelectInput
          label
          name='Gender at birth'
          description='International regulations require either Female or Male'
          value={gender}
          setValue={setGender}
          selectPrompt='Select...'
          options={[
            { value: 'Male', name: 'Male' },
            { value: 'Female', name: 'Female' },
          ]}
          error={genderError}
        />
        <SelectTags
          name='Nationalities'
          description='Please select all that apply'
          selectedArray={nationalities}
          optionsArray={countries()}
          setSelectedArray={setNationalities}
          error={nationalitiesError}
        />

        <div className='ModifyUserRoute--button-container'>
          <Button
            name='Next'
            click={validateIndividual}
            loading={isLoading}
            disabled={btnDisabled}
          />
          <Button
            name={isOnboarding ? 'Skip' : 'Cancel'}
            click={(): void => {
              history.push(returnTo);
            }}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { AddIndividualRoute };
