import { ReactElement } from 'react';

export interface PropsShape {
  title: string;
  subtitle?: ReactElement | string;
  warning?: ReactElement | string;
  showWarning?: boolean;
  imageRight?: ReactElement;
}

const Heading = ({
  title,
  subtitle,
  warning,
  showWarning,
  imageRight,
}: PropsShape): ReactElement => {
  return (
    <div className='Heading'>
      {imageRight ? (
        <h2 className='Heading--titleimage'>
          <>
            <div className='Heading--titleimage-title'>{title}</div>
            <div className='Heading--titleimage-image'>{imageRight}</div>
          </>
        </h2>
      ) : (
        <h2 className='Heading--title'>{title}</h2>
      )}
      {subtitle && <p className='Heading--subtitle'>{subtitle}</p>}
      {showWarning ? (
        <div className='Heading--warning'>
          <div className='Heading--warning-image' />
          <div className='Heading--warning-text-container'>{warning}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export { Heading };
