import React from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { Return } from '../components/onboarding/Return';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import success from '../images/illustrations/success.png';

const JoinedRoute = (): React.ReactElement => {
  const { coBrandingState } = useGlobalDataState();

  return (
    <LayoutIllustration
      image={success}
      title={<>Success!</>}
      coBrandedTitle={<>Success!</>}
      subTitle={
        <>
          Welcome to <span>{coBrandingState?.data?.name || 'Ryft'}</span>!
        </>
      }
    >
      <Return returnType='JOINED' />
    </LayoutIllustration>
  );
};

export { JoinedRoute };
