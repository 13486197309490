import React from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';

const AboutRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='AboutRoute'>AboutRoute</div>
    </Layout>
  );
};

export { AboutRoute };
