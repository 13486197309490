import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { AccountShape, accountStateShape } from '../interfaces/accountState';
import { useGlobalDataState } from './useGlobalDataState';

interface UseVerifyAccountReturnType {
  accountData: AccountShape | null;
  isLoading: boolean;
  error: string | undefined;
  apiVerifyAccount: () => Promise<void>;
}

function useVerifyAccount(): UseVerifyAccountReturnType {
  const { setAccountState } = useGlobalDataState();
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<AccountShape>();

  const [accountData, setAccountData] = useState<AccountShape | null>(null);

  const apiVerifyAccount = async (): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/account/verify',
        method: HttpMethod.POST,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while verifying the account:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setAccountData(apiResponse.data);
      setAccountState((prevState: accountStateShape) => ({
        ...prevState,
        data: apiResponse.data,
      }));
    }
  }, [apiResponse]);

  return {
    accountData,
    isLoading,
    error,
    apiVerifyAccount,
  };
}

export { useVerifyAccount };
