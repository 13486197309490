import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

interface FileDetail {
  id: string;
}

export interface TextInterface {
  [key: string]: string;
}

export interface AddDisputeEvidenceRequest {
  text: TextInterface;
  files: {
    [key: string]: FileDetail;
  };
}

export function useAddDisputeEvidence(): any {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [updatedDispute, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (updatedDispute && updatedDispute.data) {
      setIsComplete(true);
      timerId = setTimeout(() => {
        setIsComplete(false);
      }, 5000);
    }

    return (): void => {
      clearTimeout(timerId);
    };
  }, [updatedDispute]);

  const addEvidence = (
    body: AddDisputeEvidenceRequest,
    disputeId: string
  ): void => {
    const url = `/v1/disputes/${disputeId}/evidence`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method, body });
  };

  return [addEvidence, isLoading, isComplete, error, updatedDispute];
}
