import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { CheckBox } from '../../../components/forms/CheckBox';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { EmailToCustomer } from '../../../components/forms/EmailToCustomer';
import { KeyValue, MetaData } from '../../../components/forms/MetaData';
import { SelectInput } from '../../../components/forms/SelectInput';
import { TextInput } from '../../../components/forms/TextInput';
import { formatPrice, handleNotSignedIn } from '../../../functions/helpers';
import { countries } from '../../../functions/iso3166-alpha2';
import { usStates } from '../../../functions/usStates';
import { caStates } from '../../../functions/caStates';
import {
  CreatePaymentSessionRequest,
  useCreatePaymentSession,
} from '../../../hooks/useCreatePaymentSession';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Currency } from '../../../interfaces/state';
import { LayoutIllustration } from '../../../layout-illustration';
import { StepPosition } from '../../../components/forms/StepPosition';
import { PaymentType } from '../../../interfaces/paymentState';
import { SuccessMessage } from '../../../components/forms/SuccessMessage';
import { Customer } from '../../../interfaces/customersState';
import { stopReplayIfActive } from '../../../functions/sessionReplayHelpers';
import { DatePicker } from '../../../components/forms/DatePicker';

const CreatePaymentRoute = (): ReactElement => {
  interface SelectOption {
    value: string;
    name: string;
  }
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState, paymentsState } = useGlobalDataState();
  const [paymentTypeOptions, setPaymentTypeOptions] =
    useState<SelectOption[]>();
  const [paymentType, setPaymentType] = useState<string>('');
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [currency, setCurrency] = useState<string>('');
  const [currencyObj, setCurrencyObj] = useState<Currency>();
  const [pence, setPence] = useState<number>(0);
  const [customer, setCustomer] = useState<Customer>();
  const [customerEmail, setEmail] = useState<string>('');
  const [amountVariance, setAmountVariance] = useState<string>('Variable');
  const [noOfDaysBetweenPayments, setNoOfDaysBetweenPayments] = useState<
    number | undefined
  >(1);
  const [totalNumberOfPayments, setTotalNumberOfPayments] = useState<number>();
  const [rebillingEndDate, setRebillingEndDate] = useState<number>();
  const [metadata, setMetadata] = useState<KeyValue[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lineOne, setLineOne] = useState<string>('');
  const [lineTwo, setLineTwo] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [isManualCapture, setIsManualCapture] = useState<boolean>(false);
  const [isShippingAddrVis, setIsShippingAddrVis] = useState<boolean>(false);
  const [isMetaDataVis, setIsMetaDataVis] = useState<boolean>(false);
  const [sdkInitialised, setSdkInitialised] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [paymentSessionId, setPaymentSessionId] = useState<string>('');
  const [publicApiKey, setPublicApiKey] = useState<string>('');
  const [collectBillingAddress, setCollectBillingAddress] =
    useState<boolean>(false);

  const createPaymentSessionOperation = useCreatePaymentSession();

  const [paymentTypeError, setPaymentTypeError] = useState<string>('');
  const [penceError, setPenceError] = useState<string>('');
  const [currencyError, setCurrencyError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [noOfDaysBetweenPaymentsError, setNoOfDaysBetweenPaymentsError] =
    useState<string>('');
  const [totalNumberOfPaymentsError, setTotalNumberOfPaymentsError] =
    useState<string>('');
  const [attemptingPayment, setAttemptingPayment] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');
  const [cardValid, setCardValid] = useState<boolean>(false);
  const [billingAddressValid, setBillingAddressValid] =
    useState<boolean>(false);

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  const loadScript = (publicApiKey: string, clientSecret: string): void => {
    let embeddedScript = document.getElementById(
      'ryft-embedded-v2'
    ) as HTMLScriptElement;
    if (embeddedScript) {
      initialiseEmbeddedSdk(publicApiKey, clientSecret);
      return;
    }
    embeddedScript = document.createElement('script');
    embeddedScript.id = 'ryft-embedded-v2';
    embeddedScript.src = process.env.REACT_APP_EMBEDDED_URL!.replace(
      'v1',
      'v2'
    );
    embeddedScript.onload = function (): void {
      initialiseEmbeddedSdk(publicApiKey, clientSecret);
    };
    document.head.appendChild(embeddedScript);
  };

  const initialiseEmbeddedSdk = (
    publicApiKey: string,
    clientSecret: string
  ): void => {
    setCardValid(false);
    setBillingAddressValid(false);
    const initRequest: any = {
      publicKey: publicApiKey,
      clientSecret: clientSecret,
      usage: 'payment',
      manuallyHandleActions: true,
      customerPaymentMethods: {
        allowStorage: false,
      },
      paymentType: paymentType,
      style: {
        bodyColor: 'rgb(241, 240, 245)',
      },
    };
    if (collectBillingAddress) {
      initRequest.fieldCollection = {
        billingAddress: {
          display: 'full',
        },
      };
    }
    // @ts-ignore
    Ryft.init(initRequest);
    // @ts-ignore
    Ryft.addEventHandler('cardValidationChanged', (e: any) => {
      setCardValid(e.isValid);
    });
    // @ts-ignore
    Ryft.addEventHandler('billingAddressValidationChanged', (e: any) => {
      setBillingAddressValid(e.isValid);
    });
    setSdkInitialised(true);
  };

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/');
      return;
    }
    if (
      !permissions.has('PaymentsModify') ||
      !paymentsState?.data?.settings?.createEnabled
    ) {
      history.push('/payments');
    }
  }, [isSignedIn, profile]);

  useEffect(() => {
    const enabledCurrencies = paymentsState?.data?.settings.enabledCurrencies;
    const selectedCurrency = currency;
    const matchingCurrency = enabledCurrencies?.find(
      (c) => c.code === selectedCurrency
    );
    setCurrencyObj(matchingCurrency);
  }, [currency]);

  useEffect(() => {
    const enabledCurrencies =
      paymentsState?.data?.settings.enabledCurrencies.map(
        (currency: Currency) => ({
          name: currency.name,
          value: currency.code,
        })
      ) ?? [];
    setCurrencyOptions(enabledCurrencies);
    enabledCurrencies.length === 1 && setCurrency(enabledCurrencies[0].value);
  }, []);

  useEffect(() => {
    function getPaymentTypeDescription(paymentType: PaymentType): string {
      switch (paymentType) {
        case 'Recurring':
          return 'Recurring - setup and store customer details for future scheduled transactions';
        case 'Unscheduled':
          return 'Unscheduled - setup and store customer details for future transactions';
        default:
          return 'Standard - for one-off payments';
      }
    }
    const enabledPaymentTypes =
      paymentsState?.data?.settings.enabledPaymentTypes
        .filter((v) => {
          return paymentsState.data?.settings.tokenizationEnabled
            ? v !== 'MOTO'
            : v === 'Standard';
        })
        .map(
          (paymentType: PaymentType): SelectOption => ({
            value: paymentType,
            name: getPaymentTypeDescription(paymentType),
          })
        ) ?? [];
    setPaymentTypeOptions(enabledPaymentTypes);
    enabledPaymentTypes.length === 1 &&
      setPaymentType(enabledPaymentTypes[0].value);
  }, []);

  useEffect(() => {
    if (createPaymentSessionOperation.response) {
      const response = createPaymentSessionOperation.response.data;
      setPublicApiKey(response.publicApiKey);
      setClientSecret(response.paymentSession.clientSecret);
      setPaymentSessionId(response.paymentSession.id);
      loadScript(response.publicApiKey, response.paymentSession.clientSecret);
    }
    if (createPaymentSessionOperation.error) {
      setPaymentError(createPaymentSessionOperation.error);
    }
  }, [createPaymentSessionOperation.complete]);

  const handleCancelClicked = (): void => {
    if (attemptingPayment || createPaymentSessionOperation.isLoading) {
      return;
    }
    history.push('/payments');
  };

  useEffect(() => {
    if (publicApiKey && clientSecret) {
      loadScript(publicApiKey, clientSecret);
    }
  }, [collectBillingAddress]);

  const setNumberOfDaysBetweenPayments = (total: string): void => {
    const daysBetween = Number(total);
    if (daysBetween && daysBetween >= 1) {
      setNoOfDaysBetweenPayments(daysBetween);
    } else {
      setNoOfDaysBetweenPayments(undefined);
    }
  };

  const setTotalNoOfPayments = (total: string): void => {
    const totalNumber = Number(total);
    if (totalNumber) {
      setTotalNumberOfPayments(totalNumber);
    } else {
      setTotalNumberOfPayments(undefined);
    }
  };

  const minRebillingEndDate = (): Date => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleCollectPaymentClick = async (): Promise<void> => {
    setPaymentTypeError('');
    setPenceError('');
    setCurrencyError('');
    setEmailError('');
    setPaymentError('');
    setTotalNumberOfPaymentsError('');
    setNoOfDaysBetweenPaymentsError('');
    let valid = true;
    const minPence = 30;
    const maxPence = 300000;
    if (!paymentType) {
      setPaymentTypeError('Payment Type must be selected');
      valid = false;
    }
    if (currency && currencyObj && (pence < minPence || pence > maxPence)) {
      const minPrice = formatPrice({ pence: minPence, currency: currencyObj });
      const maxPrice = formatPrice({ pence: maxPence, currency: currencyObj });
      setPenceError(`Amount must be between ${minPrice} and ${maxPrice}`);
      valid = false;
    }
    if (!currency) {
      setCurrencyError('Currency must be selected');
      valid = false;
    }
    if (!customerEmail) {
      setEmailError('You must provide a customer email');
      valid = false;
    }
    if (paymentType === 'Recurring') {
      if (!noOfDaysBetweenPayments) {
        setNoOfDaysBetweenPaymentsError(
          'A value must be provided for this field'
        );
        valid = false;
      } else if (
        noOfDaysBetweenPayments < 1 ||
        noOfDaysBetweenPayments > 9999
      ) {
        setNoOfDaysBetweenPaymentsError('Must be between 1 and 9999');
        valid = false;
      }
      if (
        totalNumberOfPayments &&
        (totalNumberOfPayments < 2 || totalNumberOfPayments > 9999)
      ) {
        setTotalNumberOfPaymentsError('Must be between 2 and 9999');
        valid = false;
      }
    }
    if (!valid) {
      return;
    }
    const requestBody: CreatePaymentSessionRequest = {
      amount: pence,
      currency: currency,
      paymentType: paymentType,
      captureFlow: isManualCapture ? 'Manual' : 'Automatic',
      customerEmail: customerEmail,
      customerDetails: customer
        ? {
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            metadata: customer.metadata,
            createdTimestamp: customer.createdTimestamp,
          }
        : undefined,
      metadata:
        metadata && metadata.length
          ? Object.fromEntries(metadata.map((item) => [item.key, item.value]))
          : undefined,
      shippingDetails: isShippingAddrVis
        ? {
            address: {
              firstName: firstName || undefined,
              lastName: lastName || undefined,
              lineOne: lineOne || undefined,
              lineTwo: lineTwo || undefined,
              city: city || undefined,
              country,
              postalCode,
              region: region || undefined,
            },
          }
        : undefined,
      rebillingDetail:
        paymentType === 'Recurring'
          ? {
              amountVariance: amountVariance,
              numberOfDaysBetweenPayments: noOfDaysBetweenPayments!,
              totalNumberOfPayments: totalNumberOfPayments,
              currentPaymentNumber: 1,
              expiry: rebillingEndDate,
            }
          : undefined,
    };
    await createPaymentSessionOperation.request(requestBody);
  };

  const handleNewPayment = (): void => {
    setPublicApiKey('');
    setClientSecret('');
    setPaymentSuccess(false);
    setSdkInitialised(false);
    setPaymentType('');
    setEmail('');
    setCustomer(undefined);
    setPence(0);
    setMetadata([]);
    setFirstName('');
    setLastName('');
    setLineOne('');
    setLineTwo('');
    setCity('');
    setCountry('');
    setPostalCode('');
    setRegion('');
    setIsMetaDataVis(false);
    setIsShippingAddrVis(false);
    setCollectBillingAddress(false);
    history.push('/payments/create');
  };

  const handlePayment = async (): Promise<void> => {
    setPaymentError('');
    setPaymentSuccess(false);
    setAttemptingPayment(true);
    // @ts-ignore
    Ryft.attemptPayment()
      .then((paymentSession: any) => {
        handlePaymentSessionResult(paymentSession);
      })
      .catch((error: unknown) => {
        setPaymentError(error instanceof Error ? error.message : String(error));
        setAttemptingPayment(false);
      });
  };

  const handlePaymentSessionResult = (paymentSession: any): void => {
    if (paymentSession.requiredAction) {
      // @ts-ignore
      Ryft.handleRequiredAction(paymentSession)
        .then((paymentSession: any) => {
          handlePaymentSessionApprovalOrError(paymentSession);
        })
        .catch((error: string) => {
          setPaymentError(error);
          setAttemptingPayment(false);
        });
      return;
    }
    handlePaymentSessionApprovalOrError(paymentSession);
  };

  const handlePaymentSessionApprovalOrError = (paymentSession: any): void => {
    if (
      paymentSession.status === 'Approved' ||
      paymentSession.status === 'Captured'
    ) {
      setPaymentSuccess(true);
    }
    if (paymentSession.lastError) {
      // @ts-ignore
      setPaymentError(Ryft.getUserFacingErrorMessage(paymentSession.lastError));
    }
    setAttemptingPayment(false);
  };

  const getStepPosition = (): number => {
    if (paymentSuccess) {
      return 3;
    }
    if (sdkInitialised) {
      return 2;
    }
    return 1;
  };

  return (
    <LayoutIllustration
      title={<>Create a payment</>}
      subTitle={<>Manually enter payment information for your customer</>}
    >
      <>
        <div style={{ padding: '0 12px' }}>
          <StepPosition steps={3} position={getStepPosition()} />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridGap: '12px',
            }}
          >
            {sdkInitialised && !paymentSuccess && (
              <>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    paddingBottom: '10px',
                  }}
                >
                  Payment Method
                </p>
                <div>
                  <p style={{ marginBottom: '20px' }}>Additional options</p>
                  <CheckBox
                    setValue={setCollectBillingAddress}
                    value={collectBillingAddress}
                    id='1'
                    label='Billing Address (may improve authorisation rates)'
                  />
                </div>
              </>
            )}
            {!paymentSuccess && (
              <div id='ryft-pay-form'>
                {sdkInitialised && (
                  <>
                    <Button
                      name='Create Payment'
                      click={handlePayment}
                      loading={attemptingPayment}
                      disabled={
                        !cardValid ||
                        (collectBillingAddress && !billingAddressValid)
                      }
                    />
                  </>
                )}
              </div>
            )}
            {paymentError && (
              <div
                style={{
                  color: '#ec3f3f',
                  position: 'relative',
                  top: '-6px',
                }}
              >
                {paymentError}
              </div>
            )}
          </div>
          {paymentSuccess && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <SuccessMessage
                  message={
                    <>
                      Payment Successful - we've processed your{' '}
                      <a
                        target={'_blank'}
                        href={`/payments/${paymentSessionId}`}
                      >
                        {formatPrice({ pence, currency: currencyObj! })} payment
                      </a>
                    </>
                  }
                />
                <Button name='New Payment' click={handleNewPayment} />
              </div>
            </>
          )}
          {!sdkInitialised && (
            <>
              {paymentTypeOptions && currencyOptions && (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      gridGap: '12px',
                    }}
                  >
                    <SelectInput
                      label
                      name='Payment Type'
                      value={paymentType}
                      setValue={setPaymentType}
                      options={paymentTypeOptions}
                      selectPrompt='Select...'
                    />
                  </div>
                  {paymentTypeError && (
                    <div
                      style={{
                        color: '#ec3f3f',
                        position: 'relative',
                        top: '-6px',
                      }}
                    >
                      {paymentTypeError}
                    </div>
                  )}
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 120px',
                      gridGap: '12px',
                    }}
                  >
                    <SelectInput
                      label
                      name='Currency'
                      value={currency}
                      setValue={setCurrency}
                      options={currencyOptions}
                      selectPrompt='Select...'
                    />
                    {currency && (
                      <CurrencyInput
                        currency={currencyObj}
                        pence={pence}
                        setPence={setPence}
                      />
                    )}
                  </div>
                  {penceError && currency && (
                    <div
                      style={{
                        color: '#ec3f3f',
                        position: 'relative',
                        top: '-6px',
                      }}
                    >
                      {penceError}
                    </div>
                  )}
                  {currencyError && (
                    <div
                      style={{
                        color: '#ec3f3f',
                        position: 'relative',
                        top: '-6px',
                      }}
                    >
                      {currencyError}
                    </div>
                  )}
                  <EmailToCustomer
                    title='Customer email'
                    customer={customer}
                    setCustomer={setCustomer}
                    setEmail={setEmail}
                  />
                  {emailError && (
                    <div
                      style={{
                        color: '#ec3f3f',
                        position: 'relative',
                        top: '-6px',
                      }}
                    >
                      {emailError}
                    </div>
                  )}
                  {paymentType === 'Recurring' && (
                    <>
                      <SelectInput
                        label
                        name='Amount Variance'
                        value={amountVariance}
                        setValue={setAmountVariance}
                        options={[
                          {
                            name: 'Variable (amount charged may change)',
                            value: 'Variable',
                          },
                          {
                            name: 'Fixed (amount charged remains the same)',
                            value: 'Fixed',
                          },
                        ]}
                      />
                      <TextInput
                        name='(Minimum) Days between payments (1-9999)'
                        type='number'
                        value={`${noOfDaysBetweenPayments}`}
                        setValue={setNumberOfDaysBetweenPayments}
                        label
                      />
                      {noOfDaysBetweenPaymentsError && (
                        <div
                          style={{
                            color: '#ec3f3f',
                            position: 'relative',
                            top: '-6px',
                          }}
                        >
                          {noOfDaysBetweenPaymentsError}
                        </div>
                      )}
                      <TextInput
                        name='Total Number of Payments (2-9999)'
                        type='number'
                        value={`${totalNumberOfPayments}`}
                        setValue={setTotalNoOfPayments}
                        label
                        optional={true}
                      />
                      {totalNumberOfPaymentsError && (
                        <div
                          style={{
                            color: '#ec3f3f',
                            position: 'relative',
                            top: '-6px',
                          }}
                        >
                          {totalNumberOfPaymentsError}
                        </div>
                      )}
                      <DatePicker
                        type='END'
                        name='End Date (after which no further payments are expected)'
                        value={rebillingEndDate}
                        setValue={setRebillingEndDate}
                        notBefore={minRebillingEndDate()}
                        label
                        optional={true}
                      />
                    </>
                  )}
                  <div>
                    <p style={{ margin: '20px 0' }}>Additional options</p>
                    <CheckBox
                      setValue={setIsManualCapture}
                      value={isManualCapture}
                      id='1'
                      label='Capture Funds later (held for up to 7 days)'
                    />
                    <CheckBox
                      setValue={setIsMetaDataVis}
                      value={isMetaDataVis}
                      id='2'
                      label='Metadata'
                    />
                    <CheckBox
                      setValue={setIsShippingAddrVis}
                      value={isShippingAddrVis}
                      id='3'
                      label='Shipping details'
                    />
                  </div>
                  {isMetaDataVis && (
                    <MetaData
                      metaData={metadata}
                      setMetaData={setMetadata}
                      optional
                    />
                  )}
                  {isShippingAddrVis && (
                    <div>
                      <SelectInput
                        label
                        name='Country'
                        value={country}
                        setValue={setCountry}
                        options={countries()}
                      />

                      <TextInput
                        name='Postal Code'
                        value={postalCode}
                        setValue={setPostalCode}
                        label
                      />

                      <TextInput
                        name='First Name'
                        value={firstName}
                        setValue={setFirstName}
                        label
                      />
                      <TextInput
                        name='Last Name'
                        value={lastName}
                        setValue={setLastName}
                        label
                      />

                      <TextInput
                        name='Address Line 1'
                        value={lineOne}
                        setValue={setLineOne}
                        label
                      />

                      <TextInput
                        name='Address Line 2'
                        value={lineTwo}
                        setValue={setLineTwo}
                        label
                      />

                      <TextInput
                        name='Town / City'
                        value={city}
                        setValue={setCity}
                        label
                      />

                      {country !== 'US' && country !== 'CA' && (
                        <TextInput
                          name='Region'
                          value={region}
                          setValue={setRegion}
                          label
                        />
                      )}
                      {country === 'US' && (
                        <SelectInput
                          label
                          name='US State'
                          value={region}
                          setValue={setRegion}
                          options={usStates()}
                        />
                      )}
                      {country === 'CA' && (
                        <SelectInput
                          label
                          name='CA Province'
                          value={region}
                          setValue={setRegion}
                          options={caStates()}
                        />
                      )}
                    </div>
                  )}
                  <Button
                    name='Collect Payment Details'
                    click={handleCollectPaymentClick}
                    loading={createPaymentSessionOperation.isLoading}
                  />
                </>
              )}
            </>
          )}
          <Button
            name={paymentSuccess ? 'View Payments' : 'Cancel'}
            color='GREY'
            click={handleCancelClicked}
          />
        </div>
      </>
    </LayoutIllustration>
  );
};

export { CreatePaymentRoute };
