import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { Group } from '../../../components/forms/Group';
import { Button } from '../../../components/forms/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import StatusTabs from '../../../components/forms/StatusTabs';
import SelectTags from '../../../components/forms/SelectTags';
import { Bullets } from '../../../components/layouts/Bullets';
import { DocumentUpload } from '../../../components/forms/DocumentUpload';
import { AccountShape } from '../../../interfaces/accountState';
import { OptionShape } from '../../../interfaces/state';
import {
  getDocuments,
  getDocumentDataRequirements,
  getDocumentFileRequirements,
  getDocumentDataRequirementsTitle,
  getDocumentFileRequirementsTitle,
} from '../../../functions/accountHelpers';
import { handleNotSignedIn } from '../../../functions/helpers';
import { useEditAccount } from '../../../hooks/useEditAccount';
import {
  calculateCompletedDocumentCategories,
  calculateSelectedDocumentTypes,
  EditedDocument,
  editedDocumentToDocumentRequest,
  enableSubmitDocuments,
  getDocumentCategories,
  isEditedDocumentValid,
  updateEditedDocuments,
  getAvailableDocumentTypes,
  documentTypeRequiresBack,
  documentTypeRequiresCountry,
  getDocumentsPageSubTitle,
  getCategorySubTitle,
  getSelectedDocumentTypeLimit,
  onFirstOfMultipleTabs,
  onSubsequentTab,
  getInvalidDocumentError,
} from '../../../functions/documentHelpers';

const EditAccountDocsRoute = (): ReactElement => {
  const history = useHistory();
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const { isSignedIn } = useGlobalState();
  const { coBrandingState, profileState, accountState } = useGlobalDataState();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [invalidDocumentError, setInvalidDocumentError] = useState<string>();
  const [documentCategories, setDocumentCategories] = useState<string[]>([]);
  const [completedDocumentCategories, setCompletedDocumentCategories] =
    useState<string[]>([]);
  const [availableDocumentTypes, setAvailableDocumentTypes] = useState<
    OptionShape[]
  >([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>(
    []
  );
  const [editedDocuments, setEditedDocuments] = useState<EditedDocument[]>([]);
  const {
    accountData: editedData,
    isLoading: IsEditLoading,
    error: editError,
    apiEditAccount,
  } = useEditAccount();

  const account: AccountShape | undefined = accountState?.data;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !account ||
      !(account.verification.requiredDocuments || getDocuments(account)) ||
      account.verification.status === 'PendingVerification' ||
      !permissions.has('AccountModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (account) {
      const accountDocuments = getDocuments(account);
      const newDocumentCategories = getDocumentCategories(
        account.verification.requiredDocuments,
        accountDocuments
      );
      setDocumentCategories(Array.from(new Set(newDocumentCategories)));
      setSelectedCategory(newDocumentCategories[0]);
    }
  }, [account]);

  useEffect(() => {
    if (selectedCategory) {
      setInvalidDocumentError(
        getInvalidDocumentError(selectedCategory, getDocuments(account))
      );
      const availableDocumentTypes =
        getAvailableDocumentTypes(selectedCategory);
      setAvailableDocumentTypes(availableDocumentTypes);
      setSelectedDocumentTypes(
        calculateSelectedDocumentTypes(
          availableDocumentTypes,
          editedDocuments,
          selectedCategory
        )
      );
    }
  }, [selectedCategory, editedDocuments, account]);

  useEffect(() => {
    editedData && history.push(returnTo);
  }, [editedData]);

  const handleFileUploaded = (editedDocument: EditedDocument): void => {
    setEditedDocuments(updateEditedDocuments(editedDocuments, editedDocument));
  };

  useEffect(() => {
    setBtnDisabled(
      !enableSubmitDocuments(
        editedDocuments,
        completedDocumentCategories,
        documentCategories,
        account?.verification.requiredDocuments
      )
    );
  }, [
    account,
    editedDocuments,
    completedDocumentCategories,
    documentCategories,
  ]);

  useEffect(() => {
    setCompletedDocumentCategories(
      calculateCompletedDocumentCategories(editedDocuments)
    );
  }, [editedDocuments]);

  const handleUpdate = async (): Promise<void> => {
    if (!account || editedDocuments.length === 0) {
      return;
    }

    const documents = editedDocuments.map((d: EditedDocument) =>
      editedDocumentToDocumentRequest(d)
    );
    apiEditAccount({
      business: account.business ? { documents } : undefined,
      individual: account.individual ? { documents } : undefined,
    });
  };

  return (
    <LayoutIllustration
      maxWidth='50%'
      title={
        <>
          {account?.entityType === 'Business' && (
            <>
              Upload documents for your <span>business</span>
            </>
          )}
          {account?.entityType === 'Individual' && (
            <>
              Upload your <span>personal</span> documents
            </>
          )}
        </>
      }
      coBrandedTitle={
        <>
          {account?.entityType === 'Business' && (
            <>
              Upload documents for your business under{' '}
              <span>{coBrandingState?.data?.name}</span> account
            </>
          )}
          {account?.entityType === 'Individual' && (
            <>
              Upload your personal documents under{' '}
              <span>{coBrandingState?.data?.name}</span> account
            </>
          )}
        </>
      }
      subTitle={
        <>
          {getDocumentsPageSubTitle(
            account?.verification.requiredDocuments,
            account?.verification.errors
          )}
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <ServerError error={editError} />
        <StatusTabs
          titleArray={documentCategories}
          completedTitleArray={completedDocumentCategories}
          setSelectedTab={setSelectedCategory}
          selectedTab={selectedCategory}
        />
        <Group name=''>
          <>
            <ServerError error={invalidDocumentError} />
            <div style={{ marginBottom: '24px', fontSize: '16px' }}>
              {getCategorySubTitle(
                selectedCategory,
                account?.verification.requiredDocuments
              )}
            </div>
            <SelectTags
              selectedArray={selectedDocumentTypes}
              optionsArray={availableDocumentTypes}
              selectedLimit={getSelectedDocumentTypeLimit(
                selectedCategory,
                account?.verification.requiredDocuments,
                getDocuments(account),
                editedDocuments
              )}
              setSelectedArray={setSelectedDocumentTypes}
              handleTagDeselected={(deselectedTagValue: any): void =>
                setEditedDocuments(
                  editedDocuments.filter((d) => d.type !== deselectedTagValue)
                )
              }
              multiSelect={false}
            />
            <Bullets
              title={getDocumentDataRequirementsTitle(selectedCategory)}
              bullets={getDocumentDataRequirements(selectedCategory, account)}
            />
            <Bullets
              type='Text'
              title={getDocumentFileRequirementsTitle(selectedCategory)}
              bullets={getDocumentFileRequirements(selectedCategory)}
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '12px',
              }}
            >
              {selectedDocumentTypes.map((type: string) => {
                const existingEdit = editedDocuments.find(
                  (d: EditedDocument) => d.type === type
                );
                return (
                  <DocumentUpload
                    key={type}
                    type={type}
                    isReadyToSubmit={isEditedDocumentValid(existingEdit)}
                    backRequired={documentTypeRequiresBack(type)}
                    countryRequired={documentTypeRequiresCountry(type)}
                    onFrontUploaded={(
                      fileId: string,
                      type: string,
                      country?: string
                    ): void =>
                      handleFileUploaded({
                        type,
                        front: fileId,
                        back: undefined,
                        country,
                      })
                    }
                    onBackUploaded={(fileId: string, type: string): void =>
                      handleFileUploaded({
                        type,
                        front: undefined,
                        back: fileId,
                      })
                    }
                  />
                );
              })}
            </div>
          </>
        </Group>
        <div className='ModifyUserRoute--button-container'>
          <Button
            name={
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? 'Next'
                : 'Submit'
            }
            click={(): void => {
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? setSelectedCategory(documentCategories[1])
                : handleUpdate();
            }}
            loading={IsEditLoading}
            disabled={
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? false
                : btnDisabled
            }
          />
          <Button
            name={isOnboarding ? 'Skip' : 'Cancel'}
            click={(): void => {
              if (onSubsequentTab(documentCategories, selectedCategory)) {
                setSelectedCategory(documentCategories[0]);
                return;
              }
              history.push(returnTo);
            }}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { EditAccountDocsRoute };
