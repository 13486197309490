/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react';
import { DateTime } from './DateTime';
import { Button } from '../forms/Button';
import { Copy } from '../forms/Copy';
import { useHistory } from 'react-router-dom';
import { cardExpiryAsDate, getCardSchemeIcon } from '../../functions/helpers';
import { UserPermission } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { PaymentMethod } from '../../interfaces/paymentMethodsState';

export interface PropsShape {
  data: PaymentMethod;
}

const PaymentMethodsLayout = ({ data }: PropsShape): ReactElement => {
  const history = useHistory();
  const { profileState } = useGlobalDataState();
  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      PaymentMethodsModify: setShowRemove,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  return (
    <>
      <div className='PaymentMethods'>
        <div
          className='PaymentMethods--card'
          style={{
            backgroundImage: `url(${getCardSchemeIcon(data.card.scheme)})`,
          }}
        >
          {data.card.last4}
        </div>
        <div className='PaymentMethods--details'>
          <div className='PaymentMethods--details-key'>Id</div>
          <div className='PaymentMethods--details-value'>
            <Copy
              type='BUTTON_WITH_TEXT'
              text={`${data.id.substring(0, 10)}...${data.id.substring(
                data.id.length - 4,
                data.id.length
              )}`}
              value={data.id}
            />
          </div>

          <div className='PaymentMethods--details-key'>Expires</div>
          <div className='PaymentMethods--details-value'>
            <DateTime
              value={cardExpiryAsDate(
                data.card.expiryMonth,
                data.card.expiryYear
              )}
              showDay={false}
              alwaysShowYear={true}
            />
          </div>

          {data.billingAddress && (
            <>
              <div className='PaymentMethods--details-key'>Address</div>
              <div className='PaymentMethods--details-value'>
                {data.billingAddress?.firstName} {data.billingAddress?.lastName}
                {(data.billingAddress?.firstName ||
                  data.billingAddress?.lastName) && <br />}
                {data.billingAddress?.lineOne}
                {data.billingAddress?.lineOne && <br />}
                {data.billingAddress?.lineTwo}
                {data.billingAddress?.lineTwo && <br />}
                {data.billingAddress?.city}
                {data.billingAddress?.city && <br />}
                {data.billingAddress?.region}
                {data.billingAddress?.region && <br />}
                {data.billingAddress?.postalCode} <br />
                {data.billingAddress?.country}
              </div>
            </>
          )}
        </div>
        {showRemove && (
          <div>
            <Button
              name='Remove'
              color='GREY'
              click={(): void => {
                history.push(
                  `/customers/${data.customerId}/payment-methods/${data.id}/delete`
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export { PaymentMethodsLayout };
