import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { Payout } from '../interfaces/payoutsState';

interface UseCreatePayoutReturnType {
  payout: Payout | null;
  isLoading: boolean;
  error: any;
  createPayout: (body: CreatePayoutRequest) => Promise<void>;
}

export interface CreatePayoutRequest {
  amount: number;
  currency: string;
  payoutMethodId: string;
}

function useCreatePayout(): UseCreatePayoutReturnType {
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest<Payout>();

  const [payoutData, setPayoutData] = useState<Payout | null>(null);

  const apiCreatePayout = async (body: CreatePayoutRequest): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/account/payouts',
        method: HttpMethod.POST,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while creating the payout:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setPayoutData(apiResponse.data);
    }
  }, [apiResponse]);

  return {
    payout: payoutData,
    isLoading,
    error,
    createPayout: apiCreatePayout,
  };
}

export { useCreatePayout };
