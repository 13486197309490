import { ReactElement, useEffect, useState } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { DetailsTab } from '../../../components/dataGrids/SubAccounts';
import { isMobile } from '../../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { AccountShape } from '../../../interfaces/accountState';
import { Button } from '../../../components/forms/Button';

const SubAccountRoute = (): ReactElement => {
  const { accountsState, setAccountsState } = useGlobalDataState();
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const params: any = useParams();
  const [subAccount, setSubAccount] = useState<AccountShape | null>();

  const style = {
    margin: '12px',
  };
  useEffect(() => {
    const subAccount = accountsState?.data?.items.find(
      (subAccount: any) => subAccount.id === params.subAccountId
    );
    setAccountsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: subAccount,
      },
    }));
    setSubAccount(subAccount);

    if (!isMobile())
      history.push(`/accounts?subAccountId=${params.subAccountId}`);
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('SubAccountsView')) {
      history.push('/');
      return;
    }
  }, [profileState]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div style={style}>
        {subAccount && <DetailsTab selectedSubAccount={subAccount} />}
        {isMobile() && (
          <Button
            name='Back'
            color='GREY'
            click={(): any => history.push(`/accounts`)}
          />
        )}
      </div>
    </Layout>
  );
};

export { SubAccountRoute };
