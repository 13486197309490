import * as Sentry from '@sentry/react';
import { useState, useRef } from 'react';
import Auth from '@aws-amplify/auth';

export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

interface ApiResponse<T> {
  data: T;
  status: number;
}

interface ApiRequestOptions {
  url: string;
  method?: HttpMethod;
  body?: any;
  rawBody?: boolean;
  isExternalApi?: boolean;
}

type UseApiRequest<T> = [
  ApiResponse<T> | undefined,
  boolean,
  string | undefined,
  (options?: ApiRequestOptions, signal?: AbortSignal) => Promise<void>,
  () => void,
  number
];

export interface ApiOperation<T> {
  isLoading: boolean;
  error?: string | undefined;
  request: (...args: any[]) => Promise<T>;
  response?: T;
  complete: boolean;
}

async function getAuthToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

async function getAccountId(): Promise<string> {
  const { attributes } = await Auth.currentAuthenticatedUser();
  return attributes['custom:account_id'];
}

function useApiRequest<T = any>(): UseApiRequest<T> {
  const [response, setResponse] = useState<ApiResponse<T>>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const cleanupRef = useRef<() => void>(() => {});

  const sendRequest = async (
    {
      url,
      method = HttpMethod.GET,
      body,
      rawBody = false,
      isExternalApi = false,
    }: ApiRequestOptions = { url: '' }
  ): Promise<void> => {
    let isMounted = true;

    setError(undefined);
    setIsLoading(true);

    try {
      const authToken = isExternalApi ? null : await getAuthToken();
      const accountId = isExternalApi ? null : await getAccountId();

      const requestUrl = isExternalApi
        ? url
        : `${process.env.REACT_APP_API_BASE_URL}${url}`;
      const headersConfig: Record<string, string> = {};
      if (authToken) {
        headersConfig['Authorization'] = authToken;
      }
      if (accountId) {
        headersConfig['AccountId'] = accountId;
      }
      if (rawBody) {
        const xhr = new XMLHttpRequest();
        xhr.open(method, requestUrl);
        Object.entries(headersConfig).forEach(([key, value]) => {
          xhr.setRequestHeader(key, value);
        });
        xhr.upload.onprogress = (event): void => {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            setUploadProgress(percentComplete);
          }
        };

        xhr.onload = (): void => {
          if (!isMounted) return;
          if (xhr.status >= 200 && xhr.status < 300) {
            setResponse({
              data: JSON.parse(xhr.responseText),
              status: xhr.status,
            });
          } else {
            setError(
              xhr.statusText ||
                'An unexpected error occurred, please try again.'
            );
          }
        };

        xhr.onerror = (): void => {
          if (!isMounted) return;
          setError('An unexpected error occurred, please try again.');
        };

        xhr.send(body);
        cleanupRef.current = (): void => {
          isMounted = false;
          xhr.abort();
        };
      } else {
        headersConfig['Content-Type'] = 'application/json';
        const headers = new Headers(headersConfig);
        const response = await fetch(requestUrl, {
          method,
          headers,
          redirect: 'follow',
          body: rawBody ? body : JSON.stringify(body),
        });
        const data = await response.json();
        if (!response.ok) {
          setError(data.message);
          cleanupRef.current = (): void => {};
          return;
        }
        setResponse({ data, status: response.status });
        cleanupRef.current = (): void => {};
      }
    } catch (err: any) {
      Sentry.captureException(err);
      setError('An unexpected error occurred, please try again.');
      cleanupRef.current = (): void => {};
    } finally {
      setIsLoading(false);
    }
  };

  return [
    response,
    isLoading,
    error,
    sendRequest,
    cleanupRef.current,
    uploadProgress,
  ];
}

export { useApiRequest };
