import { useState, useEffect } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';

import {
  PayoutMethod,
  PayoutMethods,
  PayoutMethodSettings,
} from '../interfaces/payoutMethodsState';

interface UseGetPayoutMethodsReturnType {
  payoutMethods: PayoutMethod[] | null;
  payoutMethodSettings: PayoutMethodSettings | undefined;
  isLoading: boolean;
  error: string | undefined;
  fetchPayoutMethods: (id?: string) => Promise<void>;
}

const useGetPayoutMethods = (): UseGetPayoutMethodsReturnType => {
  const { setPayoutMethodsState } = useGlobalDataState();
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[] | null>(
    null
  );
  const [payoutMethodSettings, setPayoutMethodSettings] =
    useState<PayoutMethodSettings>();
  const [response, isLoading, error, sendRequest] =
    useApiRequest<PayoutMethods>();

  const fetchPayoutMethods = async (id?: string): Promise<void> => {
    try {
      const url = id
        ? `/v1/account/payout-methods?accountId=${id}`
        : '/v1/account/payout-methods';
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while fetching the payout methods:', e);
    }
  };

  useEffect(() => {
    if (response && response.data) {
      setPayoutMethods(response.data.items);
      setPayoutMethodSettings(response.data.settings);
      setPayoutMethodsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: response.data.items,
          settings: response.data.settings,
        },
      }));
    }
  }, [response]);

  return {
    payoutMethods,
    payoutMethodSettings,
    isLoading,
    error,
    fetchPayoutMethods,
  };
};

export { useGetPayoutMethods };
