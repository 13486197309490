import { ReactElement } from 'react';
import { PaymentMethodShape } from '../../interfaces/state';
import unknownIcon from '../../images/unknown.svg';
import payl8rIcon from '../../images/payl8r.svg';
import { createMaskedPan, getCardSchemeIcon } from '../../functions/helpers';
import { PaymentMethodWallet } from './PaymentMethodWallet';

export interface PropsShape {
  value: PaymentMethodShape;
  maskPan?: boolean;
  showWallet?: boolean;
  fontWeight?: 'MEDIUM' | 'LIGHT';
}

const PaymentMethod = ({
  value,
  maskPan,
  showWallet = false,
  fontWeight = 'MEDIUM',
}: PropsShape): ReactElement => {
  return (
    <div className='PaymentMethod'>
      {value.type === 'PayL8r' && <img src={payl8rIcon}></img>}
      {value.type === 'Card' && !value.card && <img src={unknownIcon}></img>}
      {value.type === 'Card' && value.card && (
        <>
          <div
            className={
              showWallet
                ? 'PaymentMethod--card-with-wallet'
                : 'PaymentMethod--card'
            }
          >
            {value.wallet && showWallet && (
              <PaymentMethodWallet value={value.wallet} />
            )}
            <img src={getCardSchemeIcon(value.card.scheme)}></img>
            <span
              className={
                fontWeight === 'LIGHT'
                  ? 'PaymentMethod--last4-light'
                  : 'PaymentMethod--last4'
              }
            >
              {maskPan
                ? createMaskedPan(value.card.scheme, value.card.last4)
                : value.card.last4}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export { PaymentMethod };
