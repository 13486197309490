import React, { ReactElement, useEffect, useState } from 'react';
import {
  epochSecondsToDate,
  formatPrice,
  isMobile,
} from '../../functions/helpers';
import { Status, StatusShape } from '../layouts/Status';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { useHistory } from 'react-router-dom';
import { PaymentsShape } from '../../interfaces/paymentState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Copy } from '../forms/Copy';
import { PaymentSessionDetails } from './PaymentSessionDetails';
import { useGlobalState } from '../../hooks/useGlobalState';
import PaymentMethodTab from '../sharedTabs/PaymentMethod';
import ThreeDsDetailsTab from '../sharedTabs/ThreeDsDetail';
import Customer from '../sharedTabs/Customer';
import PaymentTransactionsTab from '../sharedTabs/PaymentTransactions';
import {
  canBeCaptured,
  canBeRefunded,
  canBeVoided,
  refundsSupported,
  voidsAndCapturesSupported,
} from '../../functions/paymentHelpers';
import { PaymentMethod } from '../layouts/PaymentMethod';
import { UserPermission } from '../../interfaces/profileState';
import { ScrollTable } from '../layouts/ScrollTable';

interface PaymentsProps {
  payments: PaymentsShape[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  error?: string | undefined;
}

const Payments = ({
  payments,
  isMore,
  getMore,
  isLoading,
  error,
}: PaymentsProps): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const [showRefund, setShowRefund] = useState(false);
  const [showVoid, setShowVoid] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [showDisputes, setShowDisputes] = useState(false);
  const [activeTab, setActiveTab] = useState('Detail');
  const { setPaymentsState, paymentsState, profileState } =
    useGlobalDataState();
  const [selectedPayment, setSelectedPayment] = useState<PaymentsShape | null>(
    null
  );

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      DisputesView: setShowDisputes,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    const paymentCanBeVoided = canBeVoided(selectedPayment);
    const paymentCanBeCaptured = canBeCaptured(selectedPayment);
    const voidsAndCapturesCurrentlySupported = voidsAndCapturesSupported(
      selectedPayment,
      profile
    );
    const paymentCanBeRefunded = canBeRefunded(selectedPayment);
    const refundsCurrentlySupported = refundsSupported(
      selectedPayment,
      profile,
      profileState
    );
    setShowVoid(paymentCanBeVoided && voidsAndCapturesCurrentlySupported);
    setShowCapture(paymentCanBeCaptured && voidsAndCapturesCurrentlySupported);
    setShowRefund(paymentCanBeRefunded && refundsCurrentlySupported);
  }, [profile, selectedPayment]);

  const handleTabChange = (tabName: string): void => setActiveTab(tabName);

  const scrollToSelectedItem = (): void => {
    if (selectedPayment) {
      const selectedItemElement = document.getElementById(
        `payment-${selectedPayment.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const getSubAccountId = (): string | undefined => {
    return selectedPayment?.accountId !== profile.accountId
      ? selectedPayment?.accountId
      : undefined;
  };

  const handleBack = (): void => {
    const currentIndex = payments.findIndex(
      (payment) => payment.id === selectedPayment?.id
    );
    if (currentIndex > 0) {
      handleSelectedPayment(payments[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = payments.findIndex(
      (payment) => payment.id === selectedPayment?.id
    );
    if (currentIndex < payments.length - 1) {
      handleSelectedPayment(payments[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedPayment) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedPayment]);

  const setSelected = (id: string): void => {
    const payment = payments.find((payment) => payment.id === id);
    setSelectedPayment(payment || null);
    setPaymentsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: payment,
      },
    }));
  };

  const handleCloseSideMenu = (): void => {
    setSelectedPayment(null);
    history.push(`/payments`);
  };

  const handleSelectedPayment = (id: string): void => {
    if (isMobile()) {
      history.push(`/payments/${id}`);
      return;
    }
    setSelected(id);
    history.push(`/payments?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedPayment(null);
    }
  }, [location.search, payments]);

  const getPaymentHeaderClassName = (): string => {
    return profile.group === 'STANDARD'
      ? 'Payments--heading'
      : 'Payments--heading Payments--heading--sub-account';
  };

  const getPaymentItemClassName = (
    payment: PaymentsShape,
    selectedPayment: PaymentsShape | null
  ): string => {
    const subAccountClass =
      profile.group === 'STANDARD' ? '' : ' Payments--item--sub-account';
    return selectedPayment?.id === payment.id
      ? `Payments--item Payments--selected${subAccountClass}`
      : `Payments--item${subAccountClass}`;
  };

  return (
    <div className='Payments'>
      <div className={getPaymentHeaderClassName()}>
        <div>Status</div>
        <div>Gross</div>
        <div>Net</div>
        <div className='Payments--heading-mobile'>Email</div>
        {profile.group === 'STANDARD' && <div>Payment Method</div>}
        <div>When</div>
      </div>
      <ScrollTable
        getMore={getMore}
        isMore={isMore}
        error={error}
        isLoading={isLoading}
      >
        {payments.map((payment) => (
          <div
            id={`payment-${payment.id}`}
            key={payment.id}
            onClick={(): void => handleSelectedPayment(payment.id)}
            className={getPaymentItemClassName(payment, selectedPayment)}
          >
            <div className='Payments--status'>
              <Status
                status={payment.detailedStatus?.toUpperCase() as StatusShape}
              />
            </div>
            <div className='Payments--txt'>
              {formatPrice({
                pence: +payment.totalAmount,
                currency: payment.currency,
              })}
            </div>
            <div className='Payments--txt'>
              {formatPrice({
                pence: +payment.netAmount,
                currency: payment.currency,
              })}
            </div>
            <div className='Payments--txt Payments--txt-mobile'>
              {payment.customerEmail || ''}
            </div>
            {profile.group === 'STANDARD' && (
              <div className='Payments-txt Payments--txt-payment-method'>
                {payment.paymentMethod?.card && (
                  <PaymentMethod
                    value={{
                      type: 'Card',
                      card: payment.paymentMethod.card,
                      wallet: payment.paymentMethod.wallet,
                    }}
                    maskPan={false}
                    showWallet={true}
                    fontWeight='LIGHT'
                  />
                )}
              </div>
            )}
            <div className='Payments--txt'>
              <DateTime
                showTime
                value={epochSecondsToDate(payment.createdTimestamp)}
              />
            </div>
          </div>
        ))}
      </ScrollTable>
      <SideMenu
        isOpen={!!selectedPayment}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div style={{ height: '100%' }}>
          <div className='Payments--title-grid'>
            <div className='Payments--title'>
              Payment
              <br />
              <span className='SideBar--id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={paymentsState.data?.selected?.id || ''}
                  text={paymentsState.data?.selected?.id || ''}
                  direction='DOWN'
                />
              </span>
            </div>
            <div className='Payments--esc'>Esc</div>
          </div>
          <div className='Payments--nav-grid'>
            <div className='Payments--tab-group'>
              <button
                className={
                  activeTab === 'Detail'
                    ? 'Payments--tab-active'
                    : 'Payments--tab'
                }
                onClick={(): void => handleTabChange('Detail')}
              >
                Detail
              </button>
              {(paymentsState.data?.selected?.customerDetails ||
                paymentsState.data?.selected?.customerEmail) && (
                <button
                  className={
                    activeTab === 'Customer'
                      ? 'Payments--tab-active'
                      : 'Payments--tab'
                  }
                  onClick={(): void => handleTabChange('Customer')}
                >
                  Customer
                </button>
              )}
              {paymentsState.data?.selected?.paymentMethod &&
                profile.group === 'STANDARD' && (
                  <button
                    className={
                      activeTab === 'Payment Method'
                        ? 'Payments--tab-active'
                        : 'Payments--tab'
                    }
                    onClick={(): void => handleTabChange('Payment Method')}
                  >
                    Payment Method
                  </button>
                )}
              {paymentsState.data?.selected?.threeDsDetails &&
                profile.group === 'STANDARD' && (
                  <button
                    className={
                      activeTab === '3D Secure'
                        ? 'Payments--tab-active'
                        : 'Payments--tab'
                    }
                    onClick={(): void => handleTabChange('3D Secure')}
                  >
                    3D Secure
                  </button>
                )}
              {profile.group === 'STANDARD' && (
                <button
                  className={
                    activeTab === 'Transactions'
                      ? 'Payments--tab-active'
                      : 'Payments--tab'
                  }
                  onClick={(): void => handleTabChange('Transactions')}
                >
                  Transactions
                </button>
              )}
            </div>
            <div>
              <button
                className='Payments--btn-back'
                onClick={handleBack}
                disabled={
                  !paymentsState.data?.selected ||
                  payments.findIndex(
                    (payment) => payment.id === paymentsState.data?.selected?.id
                  ) === 0
                }
              />

              <button
                className='Payments--btn-next'
                onClick={handleNext}
                disabled={
                  !paymentsState.data?.selected ||
                  payments.findIndex(
                    (payment) => payment.id === paymentsState.data?.selected?.id
                  ) ===
                    payments.length - 1
                }
              />
            </div>
          </div>

          <div className='Payments--display-grid'>
            <div>
              {activeTab === 'Detail' && paymentsState.data?.selected && (
                <PaymentSessionDetails
                  payment={paymentsState.data.selected}
                  canVoid={showVoid}
                  canCapture={showCapture}
                  canRefund={showRefund}
                  isStandardAccount={profile.group === 'STANDARD'}
                  canViewDisputes={showDisputes}
                  accountId={profile.accountId}
                />
              )}

              {activeTab === 'Customer' &&
                (paymentsState.data?.selected?.customerDetails ||
                  paymentsState.data?.selected?.customerEmail) && (
                  <Customer
                    data={{
                      email: paymentsState.data.selected.customerEmail,
                      firstName:
                        paymentsState.data.selected.customerDetails?.firstName,
                      lastName:
                        paymentsState.data.selected.customerDetails?.lastName,
                      homePhoneNumber:
                        paymentsState.data.selected.customerDetails
                          ?.homePhoneNumber,
                      mobilePhoneNumber:
                        paymentsState.data.selected.customerDetails
                          ?.mobilePhoneNumber,
                      id:
                        profile.group === 'STANDARD'
                          ? paymentsState.data.selected.customerDetails?.id
                          : undefined,
                    }}
                  />
                )}

              {activeTab === 'Payment Method' &&
                paymentsState.data?.selected?.paymentMethod?.card && (
                  <PaymentMethodTab
                    data={{
                      scheme:
                        paymentsState.data.selected.paymentMethod.card.scheme,
                      last4:
                        paymentsState.data.selected.paymentMethod.card.last4,
                      expiryMonth:
                        paymentsState.data.selected.paymentMethod.card
                          .expiryMonth,
                      expiryYear:
                        paymentsState.data.selected.paymentMethod.card
                          .expiryYear,
                      name: paymentsState.data.selected.paymentMethod.card.name,
                      billingAddress:
                        paymentsState.data.selected.paymentMethod
                          .billingAddress,
                      stored:
                        paymentsState.data.selected.paymentMethod
                          .storedPaymentMethod?.stored,
                      wallet: paymentsState.data.selected.paymentMethod.wallet,
                      id: paymentsState.data.selected.paymentMethod
                        .storedPaymentMethod?.id,
                      checks: paymentsState.data.selected.paymentMethod.checks,
                    }}
                  />
                )}

              {activeTab === '3D Secure' &&
                paymentsState.data?.selected?.threeDsDetails && (
                  <ThreeDsDetailsTab
                    data={{
                      eci: paymentsState.data.selected.threeDsDetails.eci,
                      authenticationFlow:
                        paymentsState.data.selected.threeDsDetails
                          .authenticationFlow,
                      protocolVersion:
                        paymentsState.data.selected.threeDsDetails
                          .protocolVersion,
                      threeDsServerTransactionId:
                        paymentsState.data.selected.threeDsDetails
                          .threeDsServerTransactionId,
                      dsTransactionId:
                        paymentsState.data.selected.threeDsDetails
                          .dsTransactionId,
                      acsTransactionId:
                        paymentsState.data.selected.threeDsDetails
                          .acsTransactionId,
                      transactionStatus:
                        paymentsState.data.selected.threeDsDetails
                          .transactionStatus,
                      transactionStatusReason:
                        paymentsState.data.selected.threeDsDetails
                          .transactionStatusReason,
                      scheme:
                        paymentsState.data.selected.paymentMethod?.card?.scheme,
                    }}
                  />
                )}

              {activeTab === 'Transactions' && paymentsState.data?.selected && (
                <PaymentTransactionsTab
                  paymentSessionId={paymentsState.data.selected.id}
                  subAccountId={getSubAccountId()}
                />
              )}
            </div>
          </div>
        </div>
      </SideMenu>
    </div>
  );
};
export { Payments };
