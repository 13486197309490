import React from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';

const AppsRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='AppsRoute'>AppsRoute</div>
    </Layout>
  );
};

export { AppsRoute };
