import { ReactElement, Dispatch, SetStateAction } from 'react';

interface PropsShape {
  setValue: Dispatch<SetStateAction<number>>;
  setFocus?: Dispatch<SetStateAction<boolean>>;
  value: number;
  minValue: number;
  maxValue: number;
  label?: string;
  id: string;
}

function RangeInput({
  setValue,
  setFocus,
  value,
  minValue,
  maxValue,
  label,
  id,
}: PropsShape): ReactElement {
  return (
    <p className='RangeInput'>
      <input
        className='RangeInput--input'
        type='range'
        min={minValue}
        max={maxValue}
        name={id}
        id={id}
        value={value}
        onChange={(e: any): void => {
          setValue(e.target.valueAsNumber);
        }}
        onFocus={(): void => {
          if (setFocus) setFocus(true);
        }}
        onBlur={(): void => {
          if (setFocus) setFocus(false);
        }}
      />
      {label && (
        <label className='RangeInput--label' htmlFor={id}>
          {label}
        </label>
      )}
    </p>
  );
}

export { RangeInput };
