import { ReactElement } from 'react';

export interface BulletItemKeyValue {
  key: string;
  value: string;
}

export interface BulletItemText {
  text?: string;
  linkUrl?: string;
}

export type BulletItemType = 'KeyValue' | 'Text';

export interface PropsShape {
  title: string;
  bullets: BulletItemKeyValue[] | BulletItemText[];
  type?: BulletItemType;
}

const Bullets = ({
  title,
  bullets,
  type = 'KeyValue',
}: PropsShape): ReactElement => {
  return (
    <div className='Bullets'>
      <h2 className='Bullets--title'>{title}</h2>
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}
      >
        {type === 'Text' &&
          (bullets as BulletItemText[]).map((bullet, index) => (
            <div key={index} className='Bullets--bullet'>
              <div className='Bullets--value'>
                {bullet.linkUrl ? (
                  <a
                    href={bullet.linkUrl}
                    download={bullet.linkUrl.replace(/^.*[\\/]/, '')}
                  >
                    {bullet.text}
                  </a>
                ) : (
                  bullet.text
                )}
              </div>
            </div>
          ))}
        {type === 'KeyValue' &&
          (bullets as BulletItemKeyValue[]).map((bullet) => (
            <div key={bullet.key} className='Bullets--grid'>
              <div className='Bullets--bullet'>
                <strong className='Bullets--key'>{bullet.key}: </strong>
                <span className='Bullets--value'>{bullet.value}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export { Bullets };
