import { useState, useEffect, useRef } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { FileResponse } from './useFileUpload';

interface UseGetFileReturnType {
  file: FileResponse | null;
  isLoading: boolean;
  error?: string;
}

const useGetFileById = (id: string | undefined): UseGetFileReturnType => {
  const [file, setFile] = useState<FileResponse | null>(null);
  const [response, isLoading, error, sendRequest, cleanup] =
    useApiRequest<FileResponse>();

  const isMountedRef = useRef(true);

  useEffect(() => {
    return (): void => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (id && !file) {
      const fetchFile = async (): Promise<void> => {
        try {
          await sendRequest({
            url: `/v1/files/${id}`,
            method: HttpMethod.GET,
          });
        } catch (err) {
          if (isMountedRef.current) {
            // eslint-disable-next-line no-console
            console.error(`Error fetching file ${id}:`, err);
          }
        }
      };
      fetchFile();
    }
    return cleanup;
  }, [id, file, cleanup]);

  useEffect(() => {
    if (response?.data?.name && isMountedRef.current) {
      setFile(response.data);
    }
  }, [response]);

  return {
    file,
    isLoading,
    error,
  };
};

export default useGetFileById;
