import { useEffect, useState } from 'react';
import { PaymentMethod } from '../interfaces/paymentMethodsState';
import { HttpMethod, useApiRequest } from './useApiRequest';

interface UseGetPaymentMethodByIdReturnType {
  paymentMethod: PaymentMethod | null;
  isLoading: boolean;
  error?: string;
  fetchPaymentMethod: (id: string) => Promise<void>;
}

function useGetPaymentMethodById(): UseGetPaymentMethodByIdReturnType {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [response, isLoading, error, sendRequest] =
    useApiRequest<PaymentMethod>();

  const fetchPaymentMethod = async (id: string): Promise<void> => {
    try {
      const url = `/v1/payment-methods/${id}`;
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`An error occurred fetching payment method ${id}:`, e);
    }
  };

  useEffect(() => {
    if (response && response?.data) {
      setPaymentMethod(response.data);
    } else {
      setPaymentMethod(null);
    }
  }, [response]);

  return { paymentMethod, isLoading, error, fetchPaymentMethod };
}

export { useGetPaymentMethodById };
