import React from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';

const AppRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='AppRoute'>
        <div className='AppRoute--notfound'>Error 404</div>
      </div>
    </Layout>
  );
};

export { AppRoute };
