import { ReactElement, useEffect, useState } from 'react';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { RangeInput } from '../forms/RangeInput';
import { formatPrice } from '../../functions/helpers';
import { ServerError } from '../forms/ServerError';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { useHistory } from 'react-router-dom';
import { SelectInput } from '../forms/SelectInput';
import { PayoutFeeShape } from '../../interfaces/payoutsState';
import { Currency } from '../../interfaces/state';
import { getCurrency } from '../../functions/money-utility';
import { useCreatePayout } from '../../hooks/useCreatePayout';

const PayMe = (): ReactElement => {
  const history = useHistory();
  const { balancesState, payoutsState } = useGlobalDataState();
  const [available, setAvailable] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [take, setTake] = useState<string>('0.00');
  const [leaveLeft, setLeaveLeft] = useState<string>('0.00');
  const [feesEnabled, setFeesEnabled] = useState<boolean>(false);
  const [fee, setFee] = useState<any>({});
  const [feeAmountString, setFeeAmountString] = useState<string>('0.00 GBP');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(10);
  const [minimumAmount, setMinimumAmount] = useState<number>(0);
  const [minimumAmountString, setMinimumAmountString] =
    useState<string>('0.00 GBP');
  const [currency, setCurrency] = useState<Currency>();
  const [payoutMethodId, setPayoutMethodId] = useState<string>('');
  const [rangeFocused, setRangeFocused] = useState<boolean>(false);
  const { payout, isLoading, error, createPayout } = useCreatePayout();

  const formatPriceWithoutCurrency = (formattedPrice: string): string =>
    formattedPrice.substring(0, formattedPrice.length - 4);

  const getTakeAmount = (): number => +take;

  useEffect(() => {
    if (payout) {
      history.push('/payouts');
    }
  }, [payout]);

  useEffect(() => {
    const takeAmount = getTakeAmount();
    if (
      available &&
      !isNaN(available) &&
      take &&
      !isNaN(takeAmount) &&
      currency
    ) {
      const leaveNumPence = available;
      const takeNumPence = Math.round(takeAmount * 100);
      const percent =
        leaveNumPence !== 0
          ? 100 - ((leaveNumPence - takeNumPence) / leaveNumPence) * 100
          : 0;
      setPercentage(percent);

      const feePence =
        takeNumPence < fee?.minimumFreePayoutAmount
          ? calculateFeePence(takeNumPence, currency, fee)
          : 0;
      setFeeAmountString(
        formatPrice({ pence: feePence, currency }).substring(1)
      );
      const canPayFee = totalBalance - takeNumPence >= feePence;

      takeNumPence >= minimumAmount && canPayFee
        ? setBtnDisabled(false)
        : setBtnDisabled(true);
      const pence = leaveNumPence;
      const price = formatPrice({ pence, currency });

      if (takeNumPence > leaveNumPence)
        handleSetTake(formatPriceWithoutCurrency(price));
      if (takeNumPence < 0) handleSetTake('0.00');
      const calcLeaveLeft = (): void => {
        const pence = leaveNumPence - Math.round(takeAmount * 100);
        const amount = formatPrice({ pence, currency });
        setLeaveLeft(amount);
      };
      calcLeaveLeft();
    }
  }, [take, available, fee, totalBalance, currency]);

  useEffect(() => {
    if (currency) {
      const percentageRound = +percentage.toFixed(2);
      const pence = Math.round((available / 100) * percentageRound);
      const price = formatPrice({ pence, currency });
      if (rangeFocused) handleSetTake(formatPriceWithoutCurrency(price));
    }
  }, [percentage, available, currency]);

  useEffect(() => {
    if (payoutMethodId) {
      const payoutMethod =
        balancesState.data?.payoutEligibility?.eligiblePayoutMethods?.find(
          (pm) => pm.id === payoutMethodId
        );
      const currency = getCurrency(payoutMethod!.currency);
      setCurrency(currency);
    }
  }, [payoutMethodId]);

  useEffect(() => {
    if (currency) {
      const balance = balancesState.data?.items?.find(
        (b) => b.currency.code === currency.code
      );
      setAvailable(balance?.available.amount || 0);
      setTotalBalance(balance?.total ?? 0);
      const minimumAmount =
        payoutsState.data?.settings?.minimumAmounts.find(
          (b: any) => b.currency.code === currency.code
        )?.amount ?? 0;
      setMinimumAmount(minimumAmount);
      setMinimumAmountString(
        formatPrice({ pence: minimumAmount, currency }) + ' Minimum withdrawal'
      );
      const feesEnabled = payoutsState.data?.settings?.fees?.enabled ?? false;
      setFeesEnabled(feesEnabled);
      if (feesEnabled) {
        const fee = payoutsState?.data?.settings?.fees?.fees.find(
          (f: PayoutFeeShape) => f.currency.code === currency.code
        );
        setFee(fee);
      }
    }
  }, [currency, balancesState]);

  const calculateFeePence = (
    amount: number,
    currency: Currency,
    fee: PayoutFeeShape
  ): number => {
    const percentagePence =
      fee.percentage > 0
        ? Math.max(
            1,
            Math.round(
              amount * (fee.percentage / Math.pow(10, currency.minorDigits))
            )
          )
        : 0;
    return percentagePence + fee.addedAmount;
  };

  const withdraw = async (): Promise<void> => {
    if (!currency) {
      return;
    }
    createPayout({
      amount: Math.round(getTakeAmount() * 100),
      currency: currency.code,
      payoutMethodId,
    });
  };

  const getPayoutMethods = (): any => {
    const payoutMethods: any[] = [];
    balancesState.data?.payoutEligibility?.eligiblePayoutMethods?.forEach(
      (p) => {
        const currency = getCurrency(p.currency);
        payoutMethods.push({
          value: p.id,
          name: `${p.bankAccount?.bankName ?? ''} (${p.displayName}) - ${
            currency.symbol
          } - ${currency.code}`,
        });
      }
    );
    if (payoutMethods.length === 1 && !payoutMethodId) {
      setPayoutMethodId(payoutMethods[0].value);
    }
    return payoutMethods;
  };

  const handleSetTake = (value: string): void => {
    const updatedValue = value.replace(/,/g, '');
    setTake(updatedValue);
  };

  const feeDescription = (): string => {
    let description = 'The fee will be deducted from your remaining balance.';
    if (fee && currency) {
      description += ` It will apply to all payouts below ${formatPrice({
        pence: fee?.minimumFreePayoutAmount,
        currency,
      })}.`;
    }
    return description;
  };

  return (
    <div className='PayMe'>
      <ServerError error={error} />
      <SelectInput
        label
        name='Account'
        value={payoutMethodId}
        setValue={setPayoutMethodId}
        options={getPayoutMethods()}
      />
      {payoutMethodId && (
        <>
          <TextInput
            label
            name='Leave'
            value={leaveLeft.replace(/,/g, '')}
            disabled
            size='LARGE'
          />
          <TextInput
            label
            name='Take'
            value={take}
            setValue={handleSetTake}
            type='text'
            description={minimumAmountString}
            focus
            size='LARGE'
          />
          <RangeInput
            value={percentage}
            setValue={setPercentage}
            minValue={0}
            maxValue={100}
            id='percentage'
            setFocus={setRangeFocused}
          />
          <div className='PayMe--center'>{percentage.toFixed(2)}%</div>
          {feesEnabled && (
            <TextInput
              label
              name='Fee'
              value={feeAmountString}
              type='text'
              size='LARGE'
              disabled={true}
              readOnly={true}
              description={feeDescription()}
            />
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '200px 100px',
              gridGap: '12px',
            }}
          >
            <Button
              name='Create Payout'
              click={withdraw}
              loading={isLoading}
              disabled={btnDisabled}
            />
            <Button
              name='Cancel'
              click={(): void => history.push('/payouts')}
              color='GREY'
            />
          </div>
        </>
      )}
    </div>
  );
};
export { PayMe };
