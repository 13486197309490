import React from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { Return } from '../components/onboarding/Return';
import success from '../images/illustrations/success.png';

const AuthorizedRoute = (): React.ReactElement => {
  return (
    <LayoutIllustration
      image={success}
      title={<>Success!</>}
      coBrandedTitle={<>Success!</>}
    >
      <Return returnType='AUTHORIZED' />
    </LayoutIllustration>
  );
};

export { AuthorizedRoute };
