import { ReactElement, useRef, Dispatch, SetStateAction } from 'react';
import { CSSTransition } from 'react-transition-group';
import x from '../../images/close-x.svg';

export interface PropsShape {
  children: ReactElement;
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => {};
  onRequestClose?: (close: boolean) => void;
  position?: 'LEFT' | 'CENTER' | 'RIGHT';
  width?: 'NARROW' | 'REGULAR' | 'SUPER';
  backgroundColor?: string;
}

const SideMenu = ({
  children,
  isOpen,
  setIsOpen,
  onClose,
  onRequestClose,
  position = 'CENTER',
  width = 'NARROW',
  backgroundColor = '#FFFFFF',
}: PropsShape): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const lowerPosition = position.toLowerCase();
  const lowerWidth = width.toLowerCase();
  const doClose = (): void => {
    if (setIsOpen) setIsOpen(false);
    if (onClose) onClose();
    if (onRequestClose) onRequestClose(false);
  };
  const doKeyPress = (e: any): void => {
    e.key === 'Escape' && doClose();
  };

  return (
    <CSSTransition
      nodeRef={menuTransition}
      in={isOpen}
      timeout={width === 'NARROW' ? 300 : 400}
      classNames={`SideMenu--transition`}
      unmountOnExit
    >
      <div
        id='SideMenu'
        className='SideMenu'
        ref={menuTransition}
        onClick={(): void => doClose()}
        onKeyUp={doKeyPress}
        tabIndex={-1}
        style={{ height: '100%' }}
      >
        <div
          className={`SideMenu--menu-${lowerPosition}-${lowerWidth}`}
          style={{ backgroundColor }}
        >
          <div className='SideMenu--menu-close' onClick={(): void => doClose()}>
            <img src={x} alt='Close Menu' />
          </div>
          <div
            onClick={(e): void => e.stopPropagation()}
            style={{ cursor: 'default', height: '100%' }}
          >
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
export { SideMenu };
