import { ReactElement } from 'react';

export interface PropsShape {
  children?: any;
  header?: boolean;
}

const CardBlank = ({ children, header }: PropsShape): ReactElement => {
  const getClassName = (): string => {
    const className = 'CardBlank';
    return !header ? className : className + ' CardBlank--header';
  };

  return (
    <>
      <div className={getClassName()}>{children}</div>
    </>
  );
};
export { CardBlank };
