import React, { useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { PayMe } from '../components/layouts/PayMe';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const PayMeRoute = (): React.ReactElement => {
  const history = useHistory();
  const { coBrandingState } = useGlobalDataState();
  const { balancesState, profileState } = useGlobalDataState();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    } else if (
      !balancesState?.data?.payoutEligibility?.enableCreatePayout ||
      !permissions.has('PayoutsModify')
    ) {
      history.push('/payouts');
      return;
    }
  }, [isSignedIn]);

  return (
    <LayoutIllustration
      title={
        <>
          Create a <span>Ryft</span> payout
        </>
      }
      coBrandedTitle={
        <>
          Create a <span>{coBrandingState?.data?.name}</span> payout
        </>
      }
    >
      <PayMe />
    </LayoutIllustration>
  );
};

export { PayMeRoute };
