import { PaymentsShape } from '../interfaces/paymentState';
import { ProfileShape } from '../interfaces/state';
import { profileStateShape } from '../interfaces/profileState';

const canBeVoided = (payment: PaymentsShape | null | undefined): boolean =>
  payment?.status === 'Approved' &&
  payment.captureFlow === 'Manual' &&
  (payment.voidedAmount ?? 0) <= 0 &&
  !payment.pendingTransactionType;

const canBeRefunded = (payment: PaymentsShape | null | undefined): boolean =>
  payment?.status === 'Captured' &&
  payment.unRefundedAmount > 0 &&
  !payment.refundPending &&
  (!payment.disputeDetail ||
    payment.disputeDetail.status === 'Won' ||
    payment.disputeDetail.status === 'Cancelled');

const canBeCaptured = (payment: PaymentsShape | null | undefined): boolean =>
  (payment?.status === 'Approved' || payment?.status === 'Captured') &&
  payment.captureFlow === 'Manual' &&
  capturableAmount(payment) > 0 &&
  !payment.pendingTransactionType;

const refundsSupported = (
  payment: PaymentsShape | null | undefined,
  profile: ProfileShape,
  profileState: profileStateShape
): boolean =>
  !!payment &&
  !payment.splitPaymentDetail &&
  !!payment.captureDetail &&
  !payment.captureDetail.hasMultipleCaptures &&
  payment.accountId === profile.accountId &&
  profile.group === 'STANDARD' &&
  new Set(profileState.data?.user.permissions.map((p) => p.permission)).has(
    'RefundsModify'
  );

const voidsAndCapturesSupported = (
  payment: PaymentsShape | null | undefined,
  profile: ProfileShape
): boolean =>
  !!payment &&
  !payment.splitPaymentDetail &&
  payment.accountId === profile.accountId &&
  profile.group === 'STANDARD';

const uncapturedAmount = (payment: PaymentsShape): number =>
  payment.totalAmount - (payment.capturedAmount ?? 0);

const capturableAmount = (payment: PaymentsShape): number =>
  uncapturedAmount(payment) - (payment.voidedAmount ?? 0);

const canAmountBeCaptured = (
  payment: PaymentsShape,
  amount: number
): boolean => {
  const capAmount = capturableAmount(payment);
  return capAmount >= amount;
};

export {
  canBeVoided,
  canBeRefunded,
  canBeCaptured,
  refundsSupported,
  voidsAndCapturesSupported,
  uncapturedAmount,
  capturableAmount,
  canAmountBeCaptured,
};
