import { ReactElement, useEffect } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { CustomerDetail } from '../../../components/dataGrids/CustomerDetail';
import { handleNotSignedIn, isMobile } from '../../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGetCustomers } from '../../../hooks/useGetCustomers';
import { Customer } from '../../../interfaces/customersState';

const CustomerRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState, customersState } = useGlobalDataState();
  const [, , setFilter, ,] = useGetCustomers();
  const params: any = useParams();
  useEffect(() => {
    if (!isMobile()) history.push(`/customers?id=${params.customerId}`);
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('CustomersView') || profile.group === 'SUB') {
      history.push('/');
    }
  }, [isSignedIn]);

  useEffect(() => {
    setFilter({
      pageSize: 1,
      ascending: false,
      customersStartAfter: '',
      startTimestamp: '',
      endTimestamp: '',
      customerId: params?.customerId,
      email: '',
    });
  }, []);

  return (
    <Layout isSignedIn={isSignedIn}>
      <CustomerDetail
        customer={customersState.data?.items.find(
          (customer: Customer) => customer.id === params.customerId
        )}
      />
    </Layout>
  );
};

export { CustomerRoute };
