import { useState, useEffect } from 'react';

export interface TimeLimit {
  issuerCardholder: string;
  acquirerMerchant:
    | {
        initial: string;
        updated?: string;
      }
    | string;
}

export interface ReasonCodeDetail {
  code: string;
  legacyCode?: number | string;
  title: string;
  type?: string;
  description: string;
  timeLimit: TimeLimit;
  typicalCauses: string | string[];
  preventionSteps: string[];
  evidence?: any[];
}

export interface CardSchemeCodes {
  cardScheme: string;
  codes: ReasonCodeDetail[];
}

const chargebackReasonCodes: CardSchemeCodes[] = [
  {
    cardScheme: 'Visa',
    codes: [
      {
        code: `10.1`,
        title: 'EMV Liability Shift Counterfeit Fraud',
        type: 'Fraud',
        description:
          'The cardholder claims a counterfeit chip card was used at a POS system where the transaction was not processed as a chip transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          "Either a) the merchant's card processor did not transmit the full chip data to Visa;",
          'b) a chip-reading terminal was not actually used.',
        ],
        preventionSteps: [
          'Upgrade to compliant terminals',
          'Make sure to obtain the correct Cardholder Verification Method (CVM), such as a signature or PIN',
          'Take an imprint (either electronic or manual) for every card-present transaction',
          'Train staff on the proper handling of terminal issues',
        ],
        evidence: [
          {
            title: 'Online Authentication Proof',
            description:
              'Proof of any online authentication methods used, like 3D Secure verification.',
          },
          {
            title: 'Transaction Logs',
            description:
              'Logs showing the transaction was authenticated, authorized, and processed correctly.',
          },
          {
            title: 'Invoice/Receipt',
            description:
              'The invoice or receipt that was provided to the customer at the time of purchase.',
          },
          {
            title: 'Customer Communication',
            description:
              'Any correspondence or communication (emails, chat logs, etc.) with the customer regarding the purchase.',
          },
          {
            title: 'Delivery Proof',
            description:
              'Proof of digital delivery or tracking information for physical goods, specifically indicating the customer’s correct address.',
          },
        ],
      },
      {
        code: `10.2`,
        title: 'EMV Liability Shift Non-Counterfeit Fraud',
        type: 'Fraud',
        description:
          'The cardholder claims this activity was fraudulent, but blames the merchant’s EMV terminal (card-present).',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'In a card-present transaction, either: a) a chip-reading terminal was not used;',
          'b) a chip-reading terminal was used, but the machine was not PIN-compliant.',
        ],
        preventionSteps: [
          'Upgrade to EMV PIN-compliant terminals',
          'Always obtain the appropriate Cardholder Verification Method (CVM)',
          'Take an imprint (either electronic or manual) for every card-present transaction',
          'Train staff on the proper handling of terminal issues',
        ],
        evidence: [
          {
            title: 'Online Authentication Details',
            description:
              'Proof and details of any online authentication methods used, such as 3D Secure verification.',
          },
          {
            title: 'Transaction Logs and Details',
            description:
              'Logs and details showing the online transaction was authenticated, authorized, and processed correctly.',
          },
          {
            title: 'Customer Correspondence',
            description:
              'Any correspondence or communication (emails, chat logs, etc.) with the customer regarding the online purchase.',
          },
          {
            title: 'Invoice or Purchase Receipt',
            description:
              'The digital invoice or receipt provided to the customer at the time of the online purchase.',
          },
          {
            title: 'Delivery Confirmation',
            description:
              'Proof of delivery for digital goods or tracking information for physical goods showing it was sent to the customer’s correct address.',
          },
        ],
      },
      {
        code: `10.3`,
        title: 'Other Fraud: Card-Present Environment/Condition',
        type: 'Fraud',
        description:
          'A fraudulent transaction was made using the actual credit card, according to the cardholder.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'Merchant did not ensure that the card was either swiped or that the chip was read;',
          'Merchant did not obtain manual card imprint on a key-entered transaction;',
          'A card-not-present transaction was not identified as such.',
        ],
        preventionSteps: [
          'Upgrade to compliant terminals',
          'Make sure all card-present transactions are either chip-read or magnetic stripe-read',
          'Obtain an imprint or perform additional validation, such as PIN',
        ],
        evidence: [
          {
            title: 'Online Authentication Details',
            description:
              'Proof and details of any online authentication methods used, such as 3D Secure verification.',
          },
          {
            title: 'Transaction Logs and Details',
            description:
              'Logs and details showing the online transaction was authenticated, authorized, and processed correctly.',
          },
          {
            title: 'Customer Correspondence',
            description:
              'Any correspondence or communication (emails, chat logs, etc.) with the customer regarding the online purchase.',
          },
          {
            title: 'Invoice or Purchase Receipt',
            description:
              'The digital invoice or receipt provided to the customer at the time of the online purchase.',
          },
          {
            title: 'Delivery Confirmation',
            description:
              'Proof of delivery for digital goods or tracking information for physical goods showing it was sent to the customer’s correct address.',
          },
        ],
      },
      {
        code: `10.4`,
        title: 'Other Fraud: Card-absent Environment/Condition',
        type: 'Fraud',
        description:
          'The cardholder claims an unauthorized transaction occurred without the physical card involved.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'In a card-absent environment (online/phone/direct mail sales, etc.), either: a) an unauthorized (fraudulent) claim was processed using an invalid or stolen account number;',
          'b) the cardholder believes the transaction to be fraudulent due to an unclear or confusing merchant descriptor.',
        ],
        preventionSteps: [
          'Always request authorization for mail order, telephone order, internet, and recurring transactions, regardless of the dollar amount',
          'Obtain additional validation, such as an imprint or PIN',
          'Clearly and properly identify card present and card absent transactions',
        ],
        evidence: [
          {
            title: 'Authorization Records',
            description:
              'Proof of the authorization received for the transaction, especially for high-risk or high-value transactions.',
          },
          {
            title: 'Transaction Details and Logs',
            description:
              'Detailed logs showing how the transaction was processed, and if any security mechanisms were triggered.',
          },
          {
            title: 'Customer Correspondence',
            description:
              'Any communication (emails, chat logs, phone call recordings) with the customer related to the transaction.',
          },
          {
            title: 'Invoice or Digital Receipt',
            description:
              'A digital copy of the invoice or receipt given to the customer after the purchase.',
          },
          {
            title: 'Delivery Confirmation for Digital Goods',
            description:
              'Proof of delivery for digital goods or services, or tracking information for physical goods sent to the customer’s provided address.',
          },
          {
            title: 'Verification Methods Used',
            description:
              'Evidence showing any extra verification methods used, such as security questions, OTPs, or two-factor authentication.',
          },
        ],
      },
      {
        code: `10.5`,
        title: 'Visa Fraud Monitoring Program',
        type: 'Fraud',
        description: 'Visa’s Fraud Monitoring Program flagged the transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'For merchants in the Visa Fraud Monitoring Program only: Visa identifies a potentially fraudulent transaction and informs the issuer, AND the issuer has not successfully filed a chargeback under another reason code.',
        ],
        preventionSteps: ['Keep accurate records'],
        evidence: [
          {
            title: 'Delivery Confirmation',
            description:
              'Evidence showing the service or product was delivered to the customer’s provided address, especially relevant for digital goods or services.',
          },
          {
            title: 'Transaction Receipt',
            description:
              'A detailed digital receipt or invoice showing the specifics of the transaction, including the amount, date, and merchant descriptor.',
          },
          {
            title: 'Customer Correspondence',
            description:
              'Records of all communication (such as emails, chat logs, or phone call recordings) with the customer related to the transaction. This may include any authorization or confirmation received from the customer.',
          },
          {
            title: 'Proof of Authorization',
            description:
              'Evidence that shows the transaction was authorized by the customer, such as OTPs, security questions, or other two-factor authentication mechanisms.',
          },
          {
            title: 'Verification Details',
            description:
              'Any extra security verification methods used for the transaction, including logs that show these processes were followed.',
          },
        ],
      },
      {
        code: `11.1`,
        title: 'Card Recovery Bulletin',
        type: 'Authorisation',
        description:
          'The merchant apparently did not get authorization for the transaction through an account number listed in the Card Recovery Bulletin.',
        timeLimit: {
          issuerCardholder:
            'Consumers have 75 days from transaction in which to file',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          "A transaction is processed for an amount beyond the merchant's floor limit, and despite the account number being listed in the Card Recovery Bulletin for that day and region.",
        ],
        preventionSteps: [
          'Always check the Card Recovery Bulletin (CRB) when required',
        ],
        evidence: [
          {
            title: 'Transaction Records',
            description:
              'Detailed logs or records that show the transaction was processed, including date, time, and any authorization attempts made.',
          },
          {
            title: 'CRB Check Confirmation',
            description:
              'Evidence (like system logs or protocols) that demonstrates the merchant checked the Card Recovery Bulletin before processing the transaction.',
          },
          {
            title: 'Customer Authentication',
            description:
              'Records showing any authentication methods used for the customer, such as OTPs, security questions, or other verification methods, especially if the transaction was approved.',
          },
          {
            title: 'Previous Transactions',
            description:
              'Historical records of transactions related to the same customer or card number, which can provide context or patterns of behavior.',
          },
          {
            title: 'Communication Logs',
            description:
              'Any correspondence between the merchant and the customer related to the transaction in question, potentially showing acknowledgment or dispute from the customer’s side.',
          },
        ],
      },
      {
        code: `11.2`,
        title: 'Declined Authorization',
        type: 'Authorisation',
        description:
          'The authorization was declined, but the transaction was processed anyway.',
        timeLimit: {
          issuerCardholder:
            'Consumers have 75 days from transaction in which to file',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant does not request authorization; the merchant makes multiple attempts on a card that is declined, or otherwise attempts to force, circumvent, or override a declined authorization.',
        ],
        preventionSteps: [
          'Obtain authorization',
          'Discontinue the transaction if a card has been declined',
          'Ask for an alternate form of payment',
        ],
        evidence: [
          {
            title: 'Authorization Logs',
            description:
              'Provide logs that show all the authorization attempts made for the transaction. This should highlight the timestamp, the requested amount, and the response received.',
          },
          {
            title: 'Transaction Details',
            description:
              'Evidence showing the transaction details such as transaction date, amount, item or service description, and any additional charges.',
          },
          {
            title: 'Previous Successful Transactions',
            description:
              'If the cardholder has had successful transactions in the past without any issues, these can be presented to showcase the history of positive interactions.',
          },
          {
            title: 'Cardholder Communication',
            description:
              "Any correspondence with the cardholder that may indicate their knowledge of the transaction or any special circumstances leading to the transaction's processing after a declined authorization.",
          },
          {
            title: 'Reason for Continuing the Transaction',
            description:
              'Provide a clear explanation for why the transaction was continued after the authorization was declined. For example, there might have been a system glitch, or there was a miscommunication with the payment processor.',
          },
        ],
      },
      {
        code: `11.3`,
        title: 'No Authorization',
        type: 'Authorisation',
        description:
          'The transaction was processed without any attempt to gain authorization, or authorization was requested after the fact or for a different amount.',
        timeLimit: {
          issuerCardholder:
            'Consumers have 75 days from transaction in which to file',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant does not request authorization; the merchant waits a day or more before requesting authorization; the merchant requests authorization for one amount, then adds a tip to the total before processing.',
        ],
        preventionSteps: [
          'Always authorize every transaction in accordance with the Visa Rules',
          'Obtain authorization on the day of the transaction',
          "Don't include the tip on a previously authorized amount",
        ],
        evidence: [
          {
            title: 'Authorization Log',
            description:
              'Provide a record or log of the authorization request and approval, with timestamps. This should clearly show that authorization was requested and obtained for the exact amount charged to the cardholder.',
          },
          {
            title: 'Transaction Timestamp',
            description:
              'Show the exact date and time of the transaction to prove that authorization was obtained on the same day as the transaction.',
          },
          {
            title: 'Record of Previous Successful Authorizations',
            description:
              'Provide historical data that highlights a consistent pattern of obtaining authorizations, emphasizing the merchant’s good practices.',
          },
        ],
      },
      {
        code: `12.1`,
        title: 'Late Presentment',
        type: 'Processing Error',
        description:
          'The transaction was not sent to Visa within the required time limits.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant does not process a transaction in a timely manner;',
          'the account was no longer in good standing at the time of processing.',
        ],
        preventionSteps: [
          'Send completed transactions to your card processor as soon as possible (preferably on day of the sale)',
        ],
        evidence: [
          {
            title: 'Date of Transaction',
            description:
              'Provide evidence of the exact date and time the cardholder approved the transaction. This can be a transaction receipt, order form, or any other form of record.',
          },
          {
            title: 'Submission Record',
            description:
              'Evidence that shows when the transaction was submitted to the payment processor or acquirer. This will typically be a timestamp or a batch processing record.',
          },
          {
            title: 'Acquirer/Merchant Processor Communication',
            description:
              'Any communication or logs from your merchant processing system that shows when batches of transactions were submitted. This can show consistent submission patterns.',
          },
          {
            title: 'System or Network Outages',
            description:
              'If there was a valid reason for delay like a system or network outage, provide evidence of such incidents and how they affected transaction processing times.',
          },

          {
            title: 'Correspondence with the Cardholder',
            description:
              'If there was any communication with the cardholder explaining a delay or any other issue, provide those details.',
          },
          {
            title: 'Account Standing Information',
            description:
              'If the claim states that the account was not in good standing, provide evidence that the account was indeed in good standing at the time of processing, if applicable.',
          },
        ],
      },
      {
        code: `12.2`,
        title: 'Incorrect Transaction Code',
        type: 'Processing Error',
        description: 'The wrong transaction code was used.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant processed a debit instead of a credit, processed a credit instead of processing a reversal, or in some other way processed a transaction that differed from the obtained authorization.',
        ],
        preventionSteps: [
          'Double check calculations and the final transaction amount before processing',
          'Train staff on the proper procedures for processing credits, debits, and reversals',
        ],
        evidence: [
          {
            title: 'Transaction Receipt or Record',
            description:
              'The original transaction receipt or record, which shows the exact transaction code used, the transaction amount, date, and time.',
          },
          {
            title: 'Authorization Log or Confirmation',
            description:
              'A record of the obtained authorization for the transaction, including any authorization codes, timestamps, and related transaction details.',
          },
          {
            title: 'Website Details',
            description:
              'Documentation or screenshots showing how the website requires the user to input transaction details and how it ensures correct transaction codes are applied.',
          },
          {
            title: 'Follow-up Actions',
            description:
              'Any subsequent actions taken post-transaction, such as a reversal, refund, or any other rectification measures taken upon realizing a mistake had occurred.',
          },
          {
            title: 'Correspondence with the Cardholder',
            description:
              'Any communication with the cardholder discussing the transaction, especially if there were clarifications regarding the transaction code, credits, or debits.',
          },
          {
            title: 'Internal Transaction Review Procedures',
            description:
              'Your policies or procedures regarding transaction reviews, audits, or verifications that are done to ensure the accuracy of transaction details, including codes.',
          },
        ],
      },
      {
        code: `12.3`,
        title: 'Incorrect Currency',
        type: 'Processing Error',
        description:
          'The merchant made one or more errors related to the transaction currency.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The transaction currency differs from the currency transmitted through Visa;',
          'the cardholder was not advised or did not agree that Dynamic Currency Conversion (DCC) would occur.',
        ],
        preventionSteps: [
          'Inform cardholders of the use of currency conversion and applicable fees',
          'Always give the cardholder the option to make the purchase using local currency',
          'Train your sales staff on proper procedures for transactions using a different currency',
        ],
        evidence: [
          {
            title: 'Currency Selection and Display Screenshots',
            description:
              'Screenshots or records showing the transaction currency displayed to the cardholder during the checkout process, clearly indicating the currency in which the transaction was to be made.',
          },
          {
            title: 'Dynamic Currency Conversion (DCC) Notification',
            description:
              'Documentation or logs showcasing that the cardholder was notified and consented to the use of DCC, including potential fees or charges associated with the conversion.',
          },
          {
            title: 'Communication Records with Cardholder',
            description:
              'Any correspondence or communication between the merchant and the cardholder discussing the transaction currency, especially if there were clarifications or adjustments related to currency conversion.',
          },
          {
            title: 'Refund/Credit Policy on Currency',
            description:
              'Your policies or guidelines on how refunds or credits are processed when dealing with currency discrepancies. This can clarify your stance and practices regarding such situations.',
          },
          {
            title: "Website or Application's Currency Settings",
            description:
              'Details or overviews of how your website or application handles currency settings, especially if there are default settings or if the system detects and offers the currency based on the customer’s location.',
          },
          {
            title: 'Currency Conversion Confirmation',
            description:
              'Any form of confirmation, such as receipts, emails, or notifications, sent to the cardholder post-transaction, showing the currency in which the transaction was finalized and any conversion rates applied.',
          },
        ],
      },
      {
        code: `12.4`,
        title: 'Incorrect Account Number',
        type: 'Processing Error',
        description:
          'The account number in the authorization does not match the account number used in the transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The transaction was processed to an incorrect account number;',
          'the adjustment was processed more than 45 days from the transaction date;',
          'an adjustment was posted to a "closed" or "non-sufficient funds" account more than 10 days after the transaction date;',
          'an adjustment was processed more than once for the same transaction;',
          'the cardholder disputes the validity of the adjustment.',
        ],
        preventionSteps: [
          'As far as possible, swipe card or read chip for transactions',
          'Process all transactions and adjustments in a timely manner',
          'Double-check numbers before processing',
          'Train staff on the proper procedures for processing transactions',
        ],
        evidence: [
          {
            title: 'Original Transaction Details',
            description:
              'The detailed record of the original transaction, demonstrating the account number and other transaction-specific details. This can clarify if there was any mismatch during the process.',
          },
          {
            title: 'Payment Gateway/Processor Logs',
            description:
              'Logs or records from the payment gateway or processor showing the account number details submitted for the transaction, and any subsequent adjustments made.',
          },
          {
            title: 'Communication Records with Cardholder',
            description:
              'Any correspondence or communication between the merchant and the cardholder regarding the transaction, especially if any modifications were made after the initial transaction.',
          },

          {
            title: 'Website Screenshots',
            description:
              'Screenshots or an overview of the merchant’s website to demonstrate the processes in place for verifying account numbers. This can highlight steps taken to prevent errors.',
          },
          {
            title: 'Adjustment Documentation',
            description:
              'Any records or receipts related to adjustments made to the transaction, which can clarify if an adjustment was erroneously made or processed multiple times.',
          },
          {
            title: 'Cardholder Authorization Evidence',
            description:
              'Any proof that the cardholder authorized the transaction with the given account number, such as a signed receipt or online transaction confirmation.',
          },
        ],
      },
      {
        code: `12.5`,
        title: 'Incorrect Amount',
        type: 'Processing Error',
        description:
          'The amount in the authorization does not match the amount from the transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'An error was made entering numbers manually;',
          "the amount of the transaction was changed without the cardholder's consent.",
        ],
        preventionSteps: [
          'As far as possible, swipe card or read chip for transactions',
          'Double check calculations and the final transaction amount before processing',
          "Don't change the transaction amount without the cardholder's consent",
        ],
        evidence: [
          {
            title: 'Original Transaction Receipt',
            description:
              "The original transaction receipt which should detail the transaction amount agreed upon at the time of sale. It's especially useful if signed or acknowledged by the cardholder.",
          },
          {
            title: 'Communication Records with Cardholder',
            description:
              'Any correspondence or communication between the merchant and the cardholder discussing the transaction amount, especially if the cardholder acknowledged or approved any change in the amount.',
          },

          {
            title: 'Itemized Breakdown of Transaction',
            description:
              'A detailed breakdown of the items or services purchased, their individual costs, and the total amount. This can provide clarity if the cardholder disputes a particular item’s price or the total sum.',
          },
          {
            title: 'Authorization Records',
            description:
              'The records that show the authorization request and response. This can prove what amount was authorized versus what was finally charged.',
          },
          {
            title: 'Refund/Credit Documentation',
            description:
              'Evidence that if any discrepancy in the transaction amount was identified, it was immediately rectified by issuing a credit or refund to the cardholder.',
          },
        ],
      },
      {
        code: `12.6.1`,
        title: 'Duplicate Processing',
        type: 'Processing Error',
        description: 'A single transaction was processed two or more times.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant tries to submit multiple batches at one time;',
          'the transaction has multiple receipts;',
          "the transaction is duplicated in the merchant's system;",
          'the transaction was processed but the cardholder paid for the same merchandise or service by other means.',
        ],
        preventionSteps: [
          'Review transaction receipts before depositing',
          'Submit a batch only one time',
          'Void/credit any duplicate transactions',
          'Deposit receipts with one acquirer',
          'Train staff on the proper procedures for handling credits',
        ],
        evidence: [
          {
            title: 'Transaction History Log',
            description:
              'A detailed log showcasing every transaction processed, helping establish that each transaction was unique or showcasing actions taken if a duplicate was identified.',
          },
          {
            title: 'Batch Submission Records',
            description:
              'Documentation showing the batch submissions by the merchant. This can confirm that each batch was submitted only once and not duplicated.',
          },
          {
            title: 'Original Transaction Receipts',
            description:
              'All transaction receipts associated with the disputed charge. Demonstrating only one legitimate receipt can help counter the claim of a duplicate transaction.',
          },
          {
            title: 'Void/Credit Confirmations',
            description:
              'Evidence that if any duplicate transactions were identified, they were immediately voided or credited back to the cardholder.',
          },
          {
            title: 'Communication Records with Cardholder',
            description:
              'Any correspondence between the merchant and the cardholder discussing the transaction, especially if the cardholder acknowledges the payment or mentions any confusion regarding duplicates.',
          },
          {
            title: 'Internal Audit or Verification Logs',
            description:
              'Logs or results from internal audits that review transaction data, proving measures were taken to ensure transactions weren’t processed multiple times.',
          },
        ],
      },
      {
        code: `12.6.2`,
        title: 'Paid by Other Means',
        type: 'Processing Error',
        description: 'A single transaction was processed two or more times.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant tries to submit multiple batches at one time;',
          'the transaction has multiple receipts;',
          "the transaction is duplicated in the merchant's system;",
          'the transaction was processed but the cardholder paid for the same merchandise or service by other means.',
        ],
        preventionSteps: [
          'Review transaction receipts before depositing',
          'Submit a batch only one time',
          'Void/credit any duplicate transactions',
          'Deposit receipts with one acquirer',
          'Train staff on the proper procedures for handling credits',
        ],
        evidence: [
          {
            title: 'Original Transaction Record',
            description:
              'A complete record of the original disputed transaction showcasing all transaction details. This helps establish the legitimacy and details of the initial transaction.',
          },
          {
            title: 'Transaction Receipts',
            description:
              'All receipts associated with the disputed transaction. Demonstrating only one legitimate receipt can help counter the claim of multiple receipts for the same transaction.',
          },
          {
            title: 'Payment Records',
            description:
              'Proof of all payment methods utilized by the cardholder for the transaction, indicating if and how the cardholder paid by other means.',
          },
          {
            title: 'Batch Submission Records',
            description:
              'Records showing the batch submissions by the merchant, demonstrating that each batch was submitted only once and not duplicated.',
          },
          {
            title: 'Void/Credit Confirmation',
            description:
              'Evidence that any duplicate transactions identified were promptly voided or credited back to the cardholder.',
          },
          {
            title: 'Employee Training Documentation',
            description:
              'Records or guidelines showcasing training provided to employees on correctly processing transactions, avoiding duplications, and handling credits.',
          },
          {
            title: 'System and Process Documentation',
            description:
              'Screenshots or a detailed explanation of the merchant’s processing system, illustrating how transactions are input, verified, and batched. This can showcase the measures in place to prevent duplications and errors.',
          },
          {
            title: 'Communication Records',
            description:
              'Any correspondence between the merchant and the cardholder discussing the details of the transaction, especially if the cardholder mentions paying by other means or acknowledges the payment process.',
          },
          {
            title: 'Internal Audit or Verification Logs',
            description:
              'Logs from internal systems that review and verify transaction data, indicating that measures were taken to ensure transactions weren’t duplicated.',
          },
        ],
      },
      {
        code: `12.7`,
        title: 'Invalid Data',
        type: 'Processing Error',
        description:
          'An authorization was obtained using invalid or incorrect data.',
        timeLimit: {
          issuerCardholder:
            'Consumers have 75 days from transaction in which to file',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant submits an authorization request containing an incorrect transaction date, MCC, merchant or transaction type indicator, country or state code, or other required field.',
        ],
        preventionSteps: ['Double-check all required fields'],
        evidence: [
          {
            title: 'Transaction Record',
            description:
              'A detailed record of the disputed transaction showcasing all the transaction details, including the transaction date, MCC, merchant type, country or state code, and other fields. This can help establish that the correct information was provided during the transaction process.',
          },
          {
            title: 'Authorization Request & Response',
            description:
              'Proof of the authorization request sent by the merchant and the authorization response received from the issuer or processing bank. This can demonstrate that the transaction was authorized correctly and with the correct data.',
          },
          {
            title: 'System and Process Documentation',
            description:
              "Screenshots or a detailed explanation of the merchant’s processing system, highlighting how data fields are filled and validated during a transaction. This can showcase the merchant's diligent efforts to ensure data accuracy.",
          },
          {
            title: 'Communication Records',
            description:
              'Any correspondence between the merchant and the cardholder that might discuss the details of the transaction or any concerns related to the transaction data.',
          },
          {
            title: 'Previous Transaction History',
            description:
              'A record of previous successful transactions with the cardholder, showcasing the merchant’s track record of correctly processing transactions.',
          },
          {
            title: 'Internal Audit or Verification Logs',
            description:
              'Logs from internal systems that verify and validate transaction data, if applicable. This can show that the data was reviewed and deemed accurate before submission.',
          },
        ],
      },
      {
        code: `13.1`,
        title: 'Merchandise / Services Not Received',
        type: 'Customer Disputes',
        description:
          'Cardholder claims goods or services were not received or not received by the agreed upon date.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant delays delivery;',
          'the merchant charges the cardholder prior to shipping or delivery;',
          'the merchant ships on time or has the product available for pick-up but does not inform the customer;',
          'the cardholder fraudulently claims the goods or service did not arrive.',
        ],
        preventionSteps: [
          'Provide goods and services as promised',
          "Don't charge the cardholder's account until after the product has been shipped",
          'Let cardholders know when the product has been shipped and the estimated date for delivery',
          'Use tracking and delivery confirmation',
          'Make sure products are available for pick up when specified',
        ],
        evidence: [
          {
            title: 'Order Confirmation',
            description:
              'A copy of the order confirmation sent to the cardholder which details the purchased items, quantities, prices, and the expected delivery date or timeframe.',
          },
          {
            title: 'Shipping & Tracking Information',
            description:
              "Evidence that clearly shows when the goods were shipped, the shipment's tracking number, the carrier's details, and the delivery status. Ideally, this includes a delivery confirmation with the recipient's signature or acknowledgment.",
          },
          {
            title: 'Communication Records',
            description:
              'Any correspondence between the merchant and the cardholder discussing the order, especially any communication about shipment delays, delivery estimates, or changes in the delivery schedule.',
          },
          {
            title: 'Transaction & Payment Records',
            description:
              'Detailed records of the transaction, indicating when the payment was processed in relation to the shipment date.',
          },
          {
            title: 'Product Availability Documentation',
            description:
              'For services or products available for pick-up, evidence that showcases when and where the item was available, including any correspondence notifying the customer of the same.',
          },
          {
            title: 'Website or Platform Documentation',
            description:
              'Screenshots or records of the merchant’s platform (e.g., website or app) which displays delivery terms, conditions, or estimated delivery timeframes at the time of purchase.',
          },
          {
            title: 'Return & Refund Policies',
            description:
              "A copy of the merchant's return and refund policies, especially if they address situations where goods are not received or there are delays in delivery.",
          },
          {
            title: 'Proof of Digital Delivery',
            description:
              'For digital goods, evidence such as IP logs, access records, or download histories that show the cardholder received and accessed the digital product or service.',
          },
        ],
      },
      {
        code: `13.2`,
        title: 'Canceled Recurring Transaction',
        type: 'Customer Disputes',
        description:
          'The cardholder claims to have been charged for a canceled recurring transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The cardholder withdraws permission to charge the account or cancels payment for subscription;',
          'cardholder or issuing bank cancels the account;',
          'merchant neglects to cancel a recurring transaction;',
          "merchant processes a transaction after being notified the cardholder's account was closed.",
        ],
        preventionSteps: [
          'Terminate recurring transactions when the cardholder requests cancellation',
          'Consider a no-strings-attached cancellation policy',
          "Use Visa Account Updater to gain access to new card information so transactions aren't attempted on closed accounts",
          "Don't increase the transaction amount without the cardholder's consent",
          "Don't prematurely bill the cardholder",
          'Send notifications for upcoming charges',
        ],
        evidence: [
          {
            title: 'Cancellation Policy',
            description:
              'A detailed description of the merchant’s cancellation policy, highlighting any terms that pertain to timing, notifications, or other conditions required for valid cancellation.',
          },
          {
            title: 'Confirmation of Subscription',
            description:
              'The original confirmation provided to the customer when they agreed to the recurring transaction. This can often include terms, billing frequency, amount, etc.',
          },
          {
            title: 'Communication Logs',
            description:
              'Records of all correspondence between the merchant and the cardholder, especially those that might confirm or refute a cancellation request. This can include emails, chat transcripts, and other forms of communication.',
          },
          {
            title: 'Cancellation Confirmation',
            description:
              'If the cardholder did request a cancellation, any communication or documentation showing when the cancellation was confirmed and processed by the merchant.',
          },
          {
            title: 'Billing Notifications',
            description:
              'Evidence that the merchant provided advance notice to the cardholder of upcoming charges or renewals, as per the agreed terms.',
          },
          {
            title: 'Account Status',
            description:
              "Screenshots or records showing the current status of the customer’s account or subscription, which might indicate if it's active, canceled, or in another state.",
          },
          {
            title: 'Technical Logs',
            description:
              'If the merchant believes the charge occurred due to a technical error, any logs or records that can substantiate this claim.',
          },
          {
            title: 'Proof of Services Rendered',
            description:
              'If services were provided even after the claimed cancellation, evidence that the cardholder received and/or used these services during the disputed billing period.',
          },
          {
            title: 'Authorization Records',
            description:
              'Any records indicating that the cardholder initially gave explicit permission for the recurring charges, potentially including signed agreements or online consent forms.',
          },
        ],
      },
      {
        code: `13.3`,
        title: 'Not as Described or Defective Merchandise / Services',
        type: 'Customer Disputes',
        description:
          'The cardholder claims that the goods or services were defective or not as described prior to the transaction.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchandise was damaged in transit;',
          "the merchandise does not match the merchant's description;",
          'the cardholder disputes the quality of the product;',
          "the cardholder fraudulently claims the merchandise is damaged. (Note: Merchant may not direct the customer to the manufacturer for resolution. Also, this reason code supersedes merchants' return policy.)",
        ],
        preventionSteps: [
          'Double check orders to ensure the correct item is shipped',
          'Package items carefully to avoid damage during shipping',
          'Provide honest, detailed product descriptions',
          'Accept returns from cardholders and issue credit promptly',
          'Never sell counterfeit products',
        ],
        evidence: [
          {
            title: 'Original Product Descriptions',
            description:
              'The original and detailed product description provided on the website or platform where the cardholder made the purchase. This helps in illustrating what was represented to the customer.',
          },
          {
            title: 'Photos or Videos of the Product',
            description:
              "High-resolution photos or videos that clearly show the item's condition when it was shipped. This evidence can indicate if the item matches the description and if it was free from defects at the time of shipping.",
          },
          {
            title: 'Quality Assurance Procedures',
            description:
              "Documentation or a description of the procedures followed to ensure the product's quality before shipping. This might include checks, tests, or certifications.",
          },
          {
            title: 'Communication Logs',
            description:
              'Records of correspondence between the customer and the merchant where product details, conditions, or any potential issues might have been discussed or confirmed.',
          },
          {
            title: 'Shipping and Handling Documentation',
            description:
              'Documentation regarding how the item was packaged and shipped, potentially including photos or descriptions of the packaging. This can help determine if an item was damaged during transit.',
          },
          {
            title: 'Return or Inspection Reports',
            description:
              "If the customer returned the item, a report or assessment on the item's condition upon return. This can help ascertain if and when a defect occurred.",
          },
          {
            title: 'Third-Party Expert Evaluations',
            description:
              'Statements, assessments, or certifications from neutral third-party experts who have examined the product (if applicable).',
          },
          {
            title: 'Customer Reviews or Testimonials',
            description:
              'Positive reviews or testimonials from other customers who purchased the same product. This can indicate a pattern of satisfaction and counteract one individual’s negative experience.',
          },
          {
            title: 'Manufacturer Information',
            description:
              'Details about the manufacturer, including any warranties, guarantees, or quality assurances they might provide for their products.',
          },
        ],
      },
      {
        code: `13.4`,
        title: 'Counterfeit Merchandise',
        type: 'Customer Disputes',
        description:
          'The merchandise was identified as counterfeit by the owner of the intellectual property, a government agency, or a neutral third-party expert.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant is unaware of the counterfeit merchandise;',
          "the customer fraudulently claims the goods are not genuine. (Note: This reason code supersedes merchants' return policy.)",
        ],
        preventionSteps: ['Certify the authenticity of all goods'],
        evidence: [
          {
            title: 'Proof of Authenticity',
            description:
              "Documentation that can prove the product's authenticity. This could be certificates of authenticity, official branding, licensing agreements, or purchase invoices from reputable suppliers.",
          },
          {
            title: 'Detailed Product Descriptions',
            description:
              'The original and detailed product description provided on the website or platform where the cardholder made the purchase. This shows exactly what was represented to the customer and can indicate if the product was explicitly labeled as authentic.',
          },
          {
            title: 'Supplier Information',
            description:
              'Details and credentials of the suppliers from whom the merchandise was purchased. If they are reputable and known for supplying authentic goods, this can be a strong defense.',
          },
          {
            title: 'Communication Logs',
            description:
              'Records of any correspondence between the customer and the merchant where product authenticity might have been discussed or confirmed.',
          },
          {
            title: 'Visual Documentation',
            description:
              'High-quality photos or videos showing clear details and branding of the product that was provided to the customer. This might help demonstrate the authenticity of the merchandise.',
          },
          {
            title: 'Third-Party Verification',
            description:
              'If available, statements or certifications from neutral third-party experts who can vouch for the product’s authenticity.',
          },
          {
            title: 'Return or Refund Policy',
            description:
              "Documentation highlighting the merchant's return or refund policy, especially if the policy allows customers to return products they believe are not genuine.",
          },
          {
            title: 'Customer Reviews or Testimonials',
            description:
              'Positive reviews or testimonials regarding the authenticity of the product from other customers who have made similar purchases.',
          },
          {
            title: 'Educational or Support Materials',
            description:
              'Any educational materials, perhaps showing how to verify the authenticity of the product, provided post-purchase. This can be a testament to the merchant’s transparency and confidence in their goods.',
          },
        ],
      },
      {
        code: `13.5`,
        title: 'Misrepresentation',
        type: 'Customer Disputes',
        description:
          'The cardholder claims a purchased item or service was misrepresented and alleges false advertising.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          "The merchant's product descriptions are vague, inaccurate, or insufficient. Examples of typical merchant types for this dispute include: timeshare reseller, debt consolidation, credit repair, foreclosure relief services, investment products, and the like. (Note: This reason code supersedes merchants' return policy.)",
        ],
        preventionSteps: [
          'Create honest, detailed product descriptions',
          'Add photos/videos to descriptions',
          'Never sell counterfeit products',
        ],
        evidence: [
          {
            title: 'Product or Service Description',
            description:
              'The original and detailed product or service description that was available to the customer at the time of purchase. This should be as detailed as possible, outlining features, specifications, and any other pertinent details.',
          },
          {
            title: 'Visual Representations',
            description:
              'Clear, unedited photos or videos of the product or service that was provided on the website or platform where the cardholder made the purchase. This helps show exactly what was represented to the customer.',
          },
          {
            title: 'Communication Logs',
            description:
              'Any correspondence between the customer and the merchant before the purchase which may include clarifications about the product or service. This can show whether the customer asked specific questions and how the merchant responded.',
          },
          {
            title: 'Reviews or Testimonials',
            description:
              'Positive reviews or testimonials from other customers who purchased the same product or service. This can demonstrate that other customers were satisfied and did not feel misled.',
          },
          {
            title: 'Comparative Analysis',
            description:
              'A side-by-side comparison of what was promised in the description versus what was delivered. This can be particularly useful if there is a subjective element to the claim of misrepresentation.',
          },
          {
            title: 'Return or Refund Policy',
            description:
              'Documentation clearly indicating the return or refund policy, especially if the customer could have returned the product or canceled the service but chose not to.',
          },
          {
            title: 'Authenticity Proof',
            description:
              "If the dispute involves claims about authenticity (e.g., allegations of counterfeit products), provide documentation that can prove the product's authenticity, such as certificates, purchase invoices from reputable suppliers, or other verifying documents.",
          },
          {
            title: 'Educational or Support Materials',
            description:
              'Any educational or support materials provided post-purchase to help the customer understand or use the product or service. This might include user guides, online tutorials, FAQs, etc.',
          },
        ],
      },
      {
        code: `13.6`,
        title: 'Credit Not Processed',
        type: 'Customer Disputes',
        description:
          'The customer claims a credit was due but never processed.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          "The merchant didn't credit the account;",
          "the merchant issued the credit but not in time to be posted to the customer's most recent statement;",
          'the customer misunderstood the return policy;',
          'the merchant processed a transaction receipt that should have been voided.',
        ],
        preventionSteps: [
          'Share the return policy with the cardholder before completing the transaction',
          'Issue credits promptly',
          'Notify the cardholder when the credit has been issued',
        ],
        evidence: [
          {
            title: 'Transaction Records',
            description:
              'Detailed records of the original transaction, showing the amount, date, time, unique transaction ID, and card details used. This will validate the original transaction details.',
          },
          {
            title: 'Return or Cancellation Policy',
            description:
              'Documentation clearly stating the return or cancellation policy at the time of the transaction. If there are conditions where credits would not be issued, they should be clearly indicated.',
          },
          {
            title: 'Communication Logs',
            description:
              'Records of any communication between the customer and the merchant regarding the expected credit. This may include emails, chats, or other correspondence showing acknowledgment or any instructions provided by the merchant.',
          },
          {
            title: 'Proof of Credit Issuance',
            description:
              "Documentation or system logs confirming that the credit was issued on the merchant's end, and the date of issuance. This can confirm whether the credit was processed after the customer's claim.",
          },
          {
            title: 'API Request and Response Logs',
            description:
              'Logs capturing the request made to process the credit and the response received. This can show if there were any system errors or if the credit was successfully processed but did not appear on the customer’s end.',
          },
          {
            title: 'Receipt or Invoice',
            description:
              'The original receipt or invoice given to the customer at the time of transaction, highlighting the purchased items or availed services. If a voided transaction took place, the receipt/invoice could indicate that.',
          },
          {
            title: 'Voided Transaction Proof',
            description:
              "If the transaction was voided instead of being credited, proof that the transaction was voided on the merchant's end.",
          },
          {
            title: 'Expected Credit Amount vs. Actual',
            description:
              'A breakdown showing the expected credit amount by the customer versus the actual credited amount (in cases where partial credits were provided due to return policies or other conditions).',
          },
        ],
      },
      {
        code: `13.7`,
        title: 'Cancelled Merchandise / Services',
        type: 'Customer Disputes',
        description:
          'Cardholder claims to have returned merchandise or cancelled services, but the expected credit did not appear on the monthly statement.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          'The merchant did not issue a due credit;',
          'issued the credit but it didn’t post to the customer’s account;',
          'the customer misunderstood or was unclear on the return policy.',
        ],
        preventionSteps: [
          'Clearly share the return policy with the cardholder before completing the transaction',
          'Use "no returns" or similar wording on transaction receipts if applicable',
          'Issue credits promptly',
          'Notify the cardholder when the credit has been issued',
        ],
        evidence: [
          {
            title: 'Transaction Records',
            description:
              'Detailed records of the original transaction, showing the amount, date, time, unique transaction ID, and card details used. This will validate the original transaction details.',
          },
          {
            title: 'Return or Cancellation Policy',
            description:
              'Documentation clearly stating the return or cancellation policy at the time of the transaction, especially if "no returns" or other such conditions apply.',
          },
          {
            title: 'Communication Logs',
            description:
              'Records of any communication between the customer and the merchant regarding the return or cancellation. This may include emails, chats, or other correspondence showing acknowledgment or instructions provided.',
          },
          {
            title: 'Proof of Credit Issuance',
            description:
              "Documentation or system logs confirming that the credit was issued on the merchant's end, and the date of issuance.",
          },
          {
            title: 'Shipping and Handling Records',
            description:
              'If applicable, tracking numbers, shipping confirmations, or proof of delivery for returned merchandise.',
          },
          {
            title: 'API Request and Response Logs',
            description:
              'Logs capturing the request made to process the credit and the response received. This will display any system errors or highlight if the credit was successfully processed but did not appear on the customer’s end.',
          },
          {
            title: 'Receipt or Invoice',
            description:
              'The original receipt or invoice given to the customer at the time of transaction, highlighting the purchased items or availed services.',
          },
          {
            title: 'Restocking or Cancellation Fees',
            description:
              'If applicable, documentation or communication informing the customer of any restocking or cancellation fees deducted from the returned amount.',
          },
        ],
      },
      {
        code: `13.8`,
        title: 'Original Credit Transaction Not Accepted',
        type: 'Customer Disputes',
        description:
          'A voided transaction receipt was not processed (applies to Europe and inter-regional areas only).',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: [
          "Either the cardholder does not accept the credit, or the issuer does not allow Original Credit Transactions (OCT's) on certain types of cards due to local law.",
        ],
        preventionSteps: [
          'Maintain accurate records',
          'Familiarize yourself with usage laws for areas you serve',
        ],
        evidence: [
          {
            title: 'Transaction Records',
            description:
              'Detailed records of the original transaction, showing the amount, date, time, unique transaction ID, and card details used. This will prove the validity of the original transaction.',
          },
          {
            title: 'Voided Transaction Receipt',
            description:
              'Proof of the voided transaction, detailing the amount that was attempted to be credited back to the cardholder.',
          },
          {
            title: 'API Request and Response Logs',
            description:
              'Logs capturing the request made to process the Original Credit Transaction (OCT) and the response received. This will highlight any system errors or reasons for the transaction decline.',
          },
          {
            title: 'Customer Communication',
            description:
              'Any correspondence between the customer and the merchant discussing the issue, especially any acknowledgment of the transaction or resolution attempts.',
          },
          {
            title: 'Issuer Restrictions',
            description:
              "Documentation or correspondence highlighting the issuer's restrictions or policies that do not allow OCTs on certain types of cards.",
          },
          {
            title: 'Reconciliation Reports',
            description:
              "Reports showing the merchant's transaction reconciliations for the day, detailing all processed and voided transactions. This can prove the transaction was voided on the merchant's end.",
          },
          {
            title: 'Previous Transactions',
            description:
              'A history of previous transactions by the same cardholder with the merchant, providing context and potentially highlighting any recurring issues or patterns.',
          },
        ],
      },
      {
        code: `13.9`,
        title: 'Non-Receipt of Cash or Load Transaction Value',
        type: 'Customer Disputes',
        description:
          'Cardholder did not receive the full cash withdrawal at an ATM.',
        timeLimit: {
          issuerCardholder: '120 Days',
          acquirerMerchant: {
            initial: '30 days',
            updated: 'cut to 20 days in 2019',
          },
        },
        typicalCauses: ['Faulty ATM'],
        preventionSteps: [
          'Reconcile ATMs in a timely manner',
          'Adjust any out of balance machines promptly',
          'In case of a discrepancy, process a credit/reversal as soon as possible',
        ],
        evidence: [
          {
            title: 'Transaction Logs',
            description:
              'Detailed logs of the transaction, capturing the exact amount requested, the amount dispensed, date, time, unique transaction ID, and any error codes or messages generated during the transaction.',
          },
          {
            title: 'ATM Camera Footage',
            description:
              "Timestamped footage from the ATM's camera, showing the cardholder during the transaction. This helps in establishing the cardholder's presence and the physical aspects of the transaction.",
          },
          {
            title: 'Reconciliation Reports',
            description:
              "Reports showing the daily reconciliations of the ATM, detailing any discrepancies between the cash dispensed and the transaction records. This can provide a direct link to the cardholder's claim of non-receipt of the full cash withdrawal.",
          },
          {
            title: 'Error and System Logs',
            description:
              'Logs from the ATM or the payment gateway detailing any system errors, glitches, or inconsistencies at the time of the transaction.',
          },
          {
            title: 'Communication with Service Provider',
            description:
              'Any communication or service reports between the merchant (or the ATM owner) and the ATM maintenance service provider, especially if the machine was reported as faulty around the time of the transaction.',
          },
          {
            title: 'Customer Communication',
            description:
              'Correspondence between the cardholder and the merchant or ATM owner, especially any acknowledgment, complaint resolution, or updates provided to the cardholder post-transaction.',
          },
          {
            title: 'Previous Transactions',
            description:
              'A history of previous transactions by the same cardholder at the same ATM, providing context and potentially highlighting any recurring issues or patterns.',
          },
        ],
      },
    ],
  },
  {
    cardScheme: 'Mastercard',
    codes: [
      {
        code: `4808`,
        type: 'Authorisation-Related Chargebacks',
        legacyCode: 4807,
        title: 'Warning Bulletin File',
        description:
          'Issuer is unable to verify that an authorization code was obtained at the time of the transaction.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: 'n/a',
        preventionSteps: ['See Code 4808'],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        title: 'Authorization-Related Chargeback',
        description:
          'The merchant didn’t obtain authorization or got it too late.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The merchant didn’t obtain authorization or attempted to obtain authorization after the transaction date;',
          'the merchant attempted to “force” the transaction;',
          'the merchant re-swiped the card multiple times after an initial authorization decline.',
        ],
        preventionSteps: [
          'Confirm authorization on all transactions',
          'Make sure terminals are chip-compliant',
          'Do not override declined transactions',
        ],
        evidence: [
          {
            title: 'Authorization Records',
            description:
              'Provide complete details of the authorization that was obtained for the transaction. This should include the timestamp, requested amount, and the authorization code received.',
          },
          {
            title: 'Transaction Details',
            description:
              'Evidence showing the transaction details such as transaction date, amount, item or service description, and the associated authorization code.',
          },
          {
            title: 'System Logs',
            description:
              'Logs that show the system processes during the time of the transaction. This can help demonstrate that proper procedures were followed during authorization.',
          },
          {
            title: 'Communication with Payment Processor',
            description:
              'Any correspondence or communication logs with the payment processor that can verify that an authorization was indeed sought and received.',
          },
          {
            title: 'Previous Successful Transactions',
            description:
              'If the cardholder has had successful transactions in the past with valid authorizations, these can be presented to showcase the history of following correct authorization procedures.',
          },
          {
            title: 'Warning Bulletin File Verification',
            description:
              'If the merchant has access to or checks the Warning Bulletin File (or other such lists that track lost/stolen cards), they should provide evidence of this check and its outcome.',
          },
          {
            title: 'Training Protocols',
            description:
              'Documentation that explains the merchant’s training procedures for ensuring employees understand and follow the correct process for obtaining transaction authorizations.',
          },
        ],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        legacyCode: 4812,
        title: 'Account Number Not on File',
        description:
          'The issuing bank can’t find an account with the number used.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The transaction was below the applicable limit and authorized offline by the chip;',
          'account number was not entered on Electronic Warning Bulletin prior to the transaction;',
          'transaction was processed using a retired account number, but later received authorization.',
        ],
        preventionSteps: [
          'This code, while currently functional, is being consolidated into 4808, and therefore should not be used.',
        ],
        evidence: [
          {
            title: 'Transaction Receipt or Invoice',
            description:
              'Provide a detailed receipt or invoice showing the transaction details, including the account number used, date, amount, and description of the items or services purchased.',
          },
          {
            title: 'Authorization Confirmation',
            description:
              'Provide evidence that the transaction was authorized, including the authorization code, timestamp, and amount.',
          },
          {
            title: 'Communication Logs',
            description:
              'Logs or records that detail any communication or correspondence with the payment processor regarding the transaction. This can help verify that the correct account number was used and that it was authorized.',
          },
          {
            title: 'Previous Transactions',
            description:
              'Evidence of previous successful transactions with the same account number, if any exist. This can help in establishing that the account number has been used correctly in the past.',
          },
          {
            title: 'Customer Verification',
            description:
              "Any records or correspondence that verifies the customer's identity and their consent to the transaction, especially if the customer provided the account details for the purchase.",
          },
        ],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        title: 'Required Authorization Not Obtained',
        description: 'The merchant failed to request necessary authorization.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses: [
          'The merchant was required to request authorization for the transaction, but did not.',
        ],
        preventionSteps: [
          'Always request authorization request before processing a transaction',
          'Do not exceed 20% when adding gratuities',
          'Adhere to Mastercard regulations and best practices',
        ],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        title: 'Expired Chargeback Protection Period',
        description:
          'The transaction was not settled until after its expiration deadline, at which point the cardholder’s account was no longer active/in good standing.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses: [
          'The merchant waited too long to settle the transaction.',
        ],
        preventionSteps: [
          'Always request authorization before processing a transaction',
          'Settle pre-authorized transactions within 30 calendar days of the latest authorization date',
          'Settle any transactions that are not pre-authorized within seven calendar days of the authorization date',
        ],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        title: 'Multiple Authorization Requests',
        description:
          'A card-not-present transaction was declined by the issuer but later approved using Stand-In processing or X-Code.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses: [
          'The original attempt at authorization was not approved, but the merchant swiped over and over to receive authorization.',
          'An alternative authorization method was used (key entering, voice authorization).',
        ],
        preventionSteps: [
          'Always request authorization before processing a transaction',
          'Terminate any declined transactions and request an alternate method of payment',
          'Adhere to Mastercard rules and regulations',
        ],
      },
      {
        code: '4808',
        type: 'Authorisation-Related Chargebacks',
        legacyCode:
          '4807 (Warning Bulletin) or 4812 (Account Number Not on File)',
        title: 'Cardholder-Activated Terminal (CAT) 3 Device',
        description:
          'Unauthorized transactions processed at a cardholder-activated terminal (CAT).',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses: [
          'The amount of a CAT 3 transaction exceeded the acceptable limit;',
          'a card used in a CAT 3 transaction was not valid at the time.',
        ],
        preventionSteps: [
          'Always request authorization before processing a transaction',
          'Terminate any declined transactions and request an alternate method of payment',
          'Adhere to Mastercard rules and regulations',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        title: 'Point of Interaction Error',
        description: 'A single transaction was processed more than one time.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The merchant submitted a single batch of transactions multiple times;',
          'the merchant deposited both copies of the sales receipt (merchant and sales copy);',
          'the merchant created two sales receipts for a single transaction;',
          'the merchant deposited a receipt with more than one acquirer;',
          'the merchant processed a single transaction multiple times.',
        ],
        preventionSteps: [
          'Review transaction receipts before depositing',
          'Submit a batch only one time',
          'Credit any duplicate transactions',
          'Deposit receipts with only one acquirer',
          'Train staff on the proper procedures for handling credits',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: '4831',
        title: 'Transaction Amount Differs',
        description:
          'The amount the cardholder was charged doesn’t match what’s on the receipt.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The merchant made an error when calculating the transaction amount;',
          'the merchant increased or otherwise altered the amount after the transaction was completed (without the cardholder’s permission).',
        ],
        preventionSteps: [
          'Double check calculations and the final transaction amount before processing',
          'Don’t change the transaction amount without the cardholder’s consent',
          'Void any transactions if the cardholder wishes to use a different payment method',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: '4842',
        title: 'Late Presentment',
        description:
          'The transaction was not deposited within 30 days of the date a valid authorization code was obtained.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The transaction wasn’t processed in a timely fashion and the account has since been closed;',
          'transaction was posted after 180 days of the original transaction date.',
        ],
        preventionSteps: [
          'Send completed transactions to your card processor as soon as possible (preferably on day of the sale)',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: '4846',
        title: 'Point-of-Interaction Currency Conversion',
        description:
          'The merchant failed to provide adequate currency information or properly process a credit.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The transaction currency differs from the currency transmitted through Mastercard;',
          'the cardholder was not advised or did not agree that Dynamic Currency Conversion (DCC) would occur.',
        ],
        preventionSteps: [
          'Inform cardholders of the use of currency conversion and applicable fees',
          'Always give the cardholder the option to make the purchase using local currency',
          'Train your sales staff on proper procedures for transactions using a different currency',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: 'n/a',
        title: 'Duplication/Paid by Other Means',
        description: 'A single transaction was processed more than one time.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The merchant submitted a single batch of transactions multiple times;',
          'the merchant deposited both copies of the sales receipt (merchant and sales copy);',
          'the merchant created two sales receipts for a single transaction;',
          'the merchant deposited a receipt with more than one acquirer;',
          'the merchant processed a single transaction multiple times on the same card or to another payment method.',
        ],
        preventionSteps: [
          'Review transaction receipts before depositing',
          'Submit a batch only one time',
          'Credit any duplicate transactions',
          'Deposit receipts with only one acquirer',
          'Train staff on the proper procedures for handling credits',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: 'n/a',
        title: 'ATM Disputes',
        description:
          'The cardholder charges that an inaccurate amount of cash was dispensed by an ATM, or that the ATM withdrawal had been debited to the account multiple times.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: ['ATM issues.'],
        preventionSteps: [
          'Reconcile ATMs in a timely manner',
          'Report out-of-balance ATMs',
        ],
      },
      {
        code: '4834',
        type: 'Point of Interaction Error',
        legacyCode: '4859 (Addendum, No-show, or ATM Dispute)',
        title: 'Loss, Theft, or Damages',
        description:
          'The cardholder claims an unauthorized charge for loss, theft, or damage was added to the transaction up front.',
        timeLimit: {
          issuerCardholder: '90 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The merchant processed a transaction which included an upfront charge for loss, theft, or damage, without the cardholder’s approval.',
        ],
        preventionSteps: [
          'Do not add damage charges to a transaction without the knowledge and agreement of the cardholder',
          'Clearly explain any circumstances which might trigger a charge for loss, theft, or damage',
          'If loss, theft, or damage charge is to be added, process the original transaction, then process a second transaction for any additional fees',
        ],
      },
      {
        code: '4837',
        type: 'No Cardholder Authorization/Fraud-related Chargebacks',
        legacyCode: 'n/a',
        title: 'No Cardholder Authorization',
        description:
          'The cardholder claims he or she did not authorize the transaction.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The transaction was made by someone who had fraudulently obtained the account information; the cardholder didn’t recognize the transaction (billing descriptor) on the credit card statement; a family member used the account without the primary cardholder’s knowledge.',
        ],
        preventionSteps: [
          'Create clear billing descriptors',
          'Do not accept expired cards under any circumstances',
          'Obtain secondary validation (PIN, signature, imprint etc.)',
          'Do not override declined transactions',
          'Train staff on the proper procedures for handling terminal issues',
        ],
      },
      {
        code: '4849',
        type: 'No Cardholder Authorization/Fraud-related Chargebacks',
        legacyCode: 'n/a',
        title: 'Questionable Merchant Activity',
        description:
          'This transaction violates Mastercard’s rules or the merchant was on a Mastercard security list.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The acquirer processed a transaction for a merchant that later was listed in a MasterCard Global Security Bulletin for violating QMAP',
        ],
        preventionSteps: [
          'Maintain accurate records',
          'Check transactions against the Mastercard Global Security Bulletin list',
        ],
      },
      {
        code: '4870',
        type: 'No Cardholder Authorization/Fraud-related Chargebacks',
        legacyCode: 'n/a',
        title: 'Chip Liability Shift',
        description:
          'A fraudulent transaction was processed using a counterfeit EMV chip card, but the chip was not used, which allowed fraud.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The use of an EMV chip card resulted in a fraudulent transaction at a non-hybrid terminal;',
          'The use of an EMV chip card resulted in a fraudulent transaction at a hybrid terminal (with or without a PIN pad).',
        ],
        preventionSteps: [
          'Upgrade to compliant terminals',
          'Make sure to obtain the correct Cardholder Verification Method (CVM), such as a signature or PIN',
          'Take an imprint (either electronic or manual) for every card-present transaction where a chip card is not used',
        ],
      },
      {
        code: '4871',
        type: 'No Cardholder Authorization/Fraud-related Chargebacks',
        legacyCode: 'n/a',
        title:
          'Chip/PIN Liability Shift--Lost/Stolen/Never Received Issue (NRI) Fraud',
        description:
          'A fraudulent transaction was processed using a lost, stolen, or never received EMV chip card, but the chip was not used, which allowed the fraud.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: [
          'The use of an EMV chip card resulted in a fraudulent transaction at a non-hybrid terminal;',
          'The use of an EMV chip card resulted in a fraudulent transaction at a hybrid terminal (with or without a PIN pad).',
        ],
        preventionSteps: [
          'Upgrade to compliant terminals',
          'Make sure to obtain the correct Cardholder Verification Method (CVM), such as a signature or PIN',
          'Take an imprint (either electronic or manual) for every card-present transaction where a chip card is not used',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 4841,
        title: 'Cardholder Dispute of a Recurring Transaction',
        description:
          'Cardholder claims the merchant continued to bill the card after a recurring transaction was cancelled, or without explicit consent from the cardholder.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'Cardholder did not understand the original terms of the transaction; cardholder received goods/used services after cancelling the recurring transaction; cardholder canceled too late to stop the transaction; merchant did not cancel the transaction.',
        preventionSteps: [
          'Immediately fulfill qualified cancellation and/or non-renewal requests',
          'Confirm cancellations via email or text',
          'If a chargeback is issued, immediately cancel future transactions',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 4855,
        title: 'Goods or Services Not Provided',
        description:
          'The product never arrived, or the service wasn’t completed.',
        timeLimit: {
          issuerCardholder:
            'The timeline may vary based on the start date:\n\nUnspecified delivery date or service performance date: Issuer must wait a minimum of 30—but no more than 120—calendar days past the transaction processing date to initiate a chargeback; this 30-day waiting period may be waived if the merchant has gone out of business.\n\nDelivery/service date was specified but has passed: The chargeback must be initiated no later than 120 calendar days past the transaction processing date.\n\nTerminated ongoing services: The chargeback must be initiated within 120 calendar days past the date services ended (not to exceed 540 days of the original transaction processing date).\n\nPrepaid gift cards where the merchants has since gone out of business: In these situations, the chargeback must be initiated within 120 calendar days of the card’s expiration date if the card has no expiration date, the chargeback must be initiated within 540 calendar days of the transaction processing date.',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'The merchant failed to provide goods or services; the merchant has gone out of business or can no longer provide the goods or services for some other reason.',
        preventionSteps: [
          'Adhere to promised delivery dates',
          'Accurately describe the services that will be provided, including availability window',
          'Do not process the charge prior to order shipment',
          'Immediately advise customers of unforeseen delays (merchandise is out of stock, shipping delays, etc.)',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 4859,
        title: 'No-Show Hotel Charge',
        description:
          'The cardholder claims to have been charged a no show fee when they cancelled with the hotel participating in the Mastercard Guaranteed Reservation Service.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'The lodging merchant (typically a hotel) added a “no-show” fee to the original transaction without informing the cardholder.',
        preventionSteps: [
          'Clearly explain all no-show/cancellation terms and costs prior to finalizing the reservation',
          'Respond to cancellation requests in a timely manner, and confirm with the cardholder',
          'Train staff members on the correct ways to handle cancellations or reservation changes',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 4859,
        title: 'Addendum Dispute',
        description:
          'The cardholder claims an additional, unauthorized charge was added after a valid transaction without their consent.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'The merchant added an additional charge without informing the cardholder.',
        preventionSteps: [
          'Clearly explain all term and conditions and costs prior to finalizing',
          'Respond to cancellation requests in a timely manner, and confirm with the cardholder',
          'Train staff members on the correct ways to handle cancellations or reservation changes',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 4860,
        title: 'Credit Not Processed',
        description: 'The cardholder did not receive an expected credit.',
        timeLimit: {
          issuerCardholder:
            'Chargebacks must be processed within 120 calendar days of either the date on the credit documentation, the date of service cancellation, or the date merchandise was returned.',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'The merchant did not acknowledge the return or cancellation; the merchant failed to process the credit or reversal; the merchant failed to fully explain the return/cancellation policy.',
        preventionSteps: [
          'Process all qualified requests for cancellation or refund as soon as possible',
          'Clearly advise customers on no-return or limited return policies, including any fees',
          'For card-not-present transactions, require customer acknowledgment and confirmation action prior to final purchase',
          'Refuse delivery of any returns that do not adhere to your stated policy',
          'Advise the customer if the credit will be for a lesser amount, and explain why',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 'n/a',
        title: 'Goods/Services not as Described or Defective',
        description:
          'The cardholder claims the merchandise received (or service provided) differs greatly from the description provided at the time of purchase.',
        timeLimit: {
          issuerCardholder:
            'Disputes must be processed within 120 calendar days of either the transaction processing date, the date the merchandise was delivered, the service cancellation date, or the date services ended (not to exceed 540 days of the original transaction processing date).',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses:
          'The merchant shipped the wrong merchandise to the cardholder; the merchandise was damaged during shipment due to improper packing; the merchandise was defective in some way before shipping; the merchant inaccurately described the merchandise or services; the merchant did not perform the services as described.',
        preventionSteps: [
          'Check all service and product descriptions for clarity and accuracy',
          'Immediately fulfill all valid requests for replacements or refunds',
          'Do not refer customers to the manufacturer in cases of damaged or defective goods',
          'Employ best practices for packing/shipping',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: '4841',
        title: 'Digital Goods $25 or less',
        description:
          'The cardholder claims that card information used to create an account for digital purchases lacked necessary purchase controls, leading to unauthorized purchases.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses:
          'The merchant did not ensure that sufficient purchase controls were in place for the account, and digital goods were purchased (valued at less than $25).',
        preventionSteps: [
          'Disable purchases by default and require cardholders to opt-in for purchasing',
          'Require cardholders to provide authentication information before each purchase',
          'Time-out purchase ability after a set period of inactivity.',
          'Clearly show total transaction amounts for pending purchases and require cardholder confirmation',
          'Confirm purchases immediately via email or text',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: 'n/a',
        title: 'Counterfeit Goods',
        description: 'The cardholder received counterfeit merchandise.',
        timeLimit: {
          issuerCardholder:
            'Disputes must be processed within 120 calendar days of either the transaction processing date, or the date the goods or services were received (in cases of delayed delivery).',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses:
          'The merchant knowingly or unknowingly shipped goods that were not what they were claimed to be.',
        preventionSteps: [
          'Order and sell only genuine merchandise',
          'Check to ensure that inventory is what it claims to be',
          'Double-check all product descriptions for accuracy',
        ],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: '4855',
        title: 'Transaction Did Not Complete',
        description:
          'Merchandise or services were not used by the purchaser, who believed the transaction was not actually processed.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses:
          'The cardholder believed that goods or services were not provided (and therefore were not used) because the transaction was never processed.',
        preventionSteps: ['Always confirm transactions via text or email'],
      },
      {
        code: '4853',
        type: 'Cardholder Disputes',
        legacyCode: '4860',
        title: 'Credit Posted as a Purchase',
        description: 'A credit was processed as a debit.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days*',
        },
        typicalCauses:
          'The merchant knowingly or unknowingly processed a sale which should have been processed as a credit.',
        preventionSteps: [
          'Train staff on the proper procedures for processing credits',
        ],
      },
      {
        code: '4850',
        type: 'Installment Billing Dispute',
        legacyCode: 'n/a',
        title: 'Installment Billing Dispute',
        description:
          'The cardholder claims an installment charge made to their account was incorrect.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'The merchant charged the cardholder prematurely; the merchant charged the cardholder the incorrect amount, or the transaction was not an installment.',
        preventionSteps: [
          'Make sure you bill the cardholder installment transactions correctly as agreed',
          'Train staff on the importance of accuracy',
        ],
      },
      {
        code: '4854',
        type: 'Cardholder Dispute Not Classified Elsewhere',
        legacyCode: 'n/a',
        title: 'Cardholder Dispute Not Classified Elsewhere (US)',
        description:
          'The cardholder has made an unsuccessful good-faith effort to resolve a dispute with the merchant.',
        timeLimit: {
          issuerCardholder:
            '120 calendar days, or 60 calendar days from first learning about the dispute from the cardholder',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses:
          'Merchant did not resolve the issue, or did not resolve the issue in a timely manner; the cardholder is making a fraudulent claim.',
        preventionSteps: [
          'Resolve all customer complaints as quickly as possible',
        ],
      },
      {
        code: '4807',
        type: 'Legacy Codes',
        legacyCode: 'n/a',
        title: 'Warning Bulletin File',
        description:
          'Issuer is unable to verify that an authorization code was obtained at the time of the transaction.',
        timeLimit: {
          issuerCardholder: '45 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: 'n/a',
        preventionSteps: [
          'This code, while currently functional, is being consolidated into 4808, and therefore should not be used.',
        ],
      },
      {
        code: '4812',
        type: 'Legacy Codes',
        legacyCode: 'n/a',
        title: 'Account Number Not on File',
        description:
          'The issuing bank can’t find an account with the number used.',
        timeLimit: {
          issuerCardholder: '45 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: 'n/a',
        preventionSteps: [
          'This code, while currently functional, is being consolidated into 4808, and therefore should not be used. Use Code 4808 instead.',
        ],
      },
      {
        code: '4859',
        type: 'Legacy Codes',
        legacyCode: 'n/a',
        title: 'No Show/Addendum/ATM Dispute',
        description:
          'The cardholder claims to have been charged a no-show fee when they cancelled with a hotel participating in the Mastercard Guaranteed Reservation Service.',
        timeLimit: {
          issuerCardholder: '120 calendar days',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: 'n/a',
        preventionSteps: [
          'This code, while currently functional, is being consolidated into 4853, and therefore should not be used. Use Code 4853 instead.',
        ],
      },
      {
        code: '4860',
        type: 'Legacy Codes',
        legacyCode: 'n/a',
        title: 'Credit Not Processed',
        description: 'The cardholder did not receive an expected a credit.',
        timeLimit: {
          issuerCardholder: '120 calendar days*',
          acquirerMerchant: '45 calendar days',
        },
        typicalCauses: 'n/a',
        preventionSteps: [
          'This code, while currently functional, is being consolidated into 4853, and therefore should not be used. Use Code 4853 instead.',
        ],
      },
    ],
  },
  {
    cardScheme: 'Amex',
    codes: [
      {
        code: 'A01',
        type: 'Authorization',
        title: 'Charge amount exceeds authorization amount',
        description:
          'The amount of the authorization approval was less than the amount of the charge submitted by the merchant.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "A transaction is processed for an amount beyond the merchant's floor limit; an error was made entering numbers manually.",
        preventionSteps: [
          'Do not process a transaction when a declined authorization has been received',
          'Do not process a transaction for more than the allowed tolerance level above the authorization amount',
        ],
      },
      {
        code: 'A02',
        type: 'Authorization',
        title: 'No valid authorization',
        description:
          'The charge submitted by the merchant was either declined or the card was expired.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Transaction was made after the authorization code and transaction date; the limited authorization index was present in the authorization request; the transaction amount exceeds the amount in the limited authorization response; the vendor did not exclude the tip in the authorization amount; the card was expired and/or the account was closed.',
        preventionSteps: [
          'Always authorize every transaction in accordance with merchant agreement',
          'Obtain authorization on the day of the transaction',
          "Don't include the tip on a previously authorized amount",
          'Obtain confirmation of authorization before completing all transactions',
        ],
      },
      {
        code: 'A08',
        type: 'Authorization',
        title: 'Authorization approval expired',
        description:
          'Transaction submission was made after the authorization approval had expired.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The transaction was not approved; the transaction was submitted after the expiration of the authorization approval; the authorization request was denied; or the transaction was made using an expired card.',
        preventionSteps: [
          'Process all transactions in a timely manner',
          'Do not process a transaction when a declined authorization has been received',
          'Do not process a transaction made with an expired card',
          'Train staff on the proper handling of authorization approvals',
        ],
      },
      {
        code: 'C02',
        type: 'Cardmember Dispute',
        title: 'Credit not processed',
        description:
          'American Express has not received an expected credit (or partial credit) from the merchant.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The merchant didn't credit the account; the merchant issued the credit but not in time to be posted to the customer's most recent statement; the customer misunderstood the return policy; the merchant processed a transaction receipt that should have been voided.",
        preventionSteps: [
          'Issue credits promptly',
          'Share the return policy with the cardholder before completing the transaction',
          'Notify the cardholder when the credit has been issued',
          'Consider simplifying/clarifying your return policy',
        ],
      },
      {
        code: 'C04',
        type: 'Cardmember Dispute',
        title: 'Goods / services returned or refused',
        description:
          'The cardholder refused delivery of goods or services or returned merchandise, but a credit was not processed.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Cardholder refused delivery of goods or services and has not yet received a credit; cardholder returned goods and has not yet received a credit.',
        preventionSteps: [
          'Process all credit vouchers promptly',
          'Notify the cardholder when the credit has been issued',
          'Properly disclose special refund policies on the sales draft, prominently near the cardholder’s signature',
        ],
      },
      {
        code: 'C05',
        type: 'Cardmember Dispute',
        title: 'Goods / services canceled',
        description:
          'The cardholder claims that the order for goods or services was canceled.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses: 'Cancellation not received; cancellation not processed.',
        preventionSteps: [
          'Create and post a clear cancellation policy',
          'Acknowledge canceled orders as soon as possible',
        ],
      },
      {
        code: 'C08',
        type: 'Cardmember Dispute',
        title: 'Goods/Services Not Received or Only Partially Received',
        description:
          'The cardholder claims to have not received (or only partially received) the goods or services ordered.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Merchant did not ship the goods/service by the agreed delivery date or shipped only a partial order; merchant did not clearly explain the expected shipping/arrival date; merchant billed the cardholder before delivery of merchandise; merchant did not make order available for pick-up.',
        preventionSteps: [
          'In the case of a delayed order, always notify the customer',
          'Provide cardholder the option of either continuing with the order or canceling it',
          'Clearly display the expected shipping date and delivery date on the invoice or order receipt',
          'Provide a signed order receipt or invoice showing receipt of services or merchandise',
        ],
      },
      {
        code: 'C14',
        type: 'Cardmember Dispute',
        title: 'Paid by Other Means',
        description:
          'Cardholder was charged for a transaction that was paid using an alternate form of payment.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The merchant didn't void multiple transactions; the transaction was processed but the cardholder paid for the same merchandise or service by other means.",
        preventionSteps: [
          'Double check calculations and the final transaction amount before processing',
          'Void any transactions if the cardholder wishes to use a different payment method',
        ],
      },
      {
        code: 'C18',
        type: 'Cardmember Dispute',
        title: '"No Show" or CARDeposit Canceled',
        description:
          'The cardholder claims a lodging reservation was canceled, or an expected credit for a CARDeposit charge was not received.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Cardholder was billed for lodging services that were allegedly canceled.',
        preventionSteps: [
          'Always inform the cardholder of your cancellation policy at the time of the reservation',
          'Log all confirmation and cancellation numbers as required by the CARDeposit programs',
        ],
      },
      {
        code: 'C28',
        type: 'Cardmember Dispute',
        title: 'Canceled Recurring Billing',
        description:
          'The cardholder claims to have been charged for a canceled recurring transaction.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The cardholder withdraws permission to change the account, or canceled payment for subscription; cardholder or issuing bank cancels the account; merchant neglects to cancel a recurring transaction; merchant processes a transaction after being notified the cardholder's account was closed.",
        preventionSteps: [
          'Terminate recurring transactions when the cardholder requests cancelation',
          'Consider a no-strings-attached cancelation policy',
          "Don't increase the transaction amount without the cardholder's consent",
          "Don't prematurely bill the cardholder",
          'Send notifications for upcoming charges',
        ],
      },
      {
        code: 'C31',
        type: 'Cardmember Dispute',
        title: 'Goods/Services Not as Described',
        description:
          'The cardholder claims that the goods or services were defective or not as described prior to the transaction.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The merchandise was damaged upon its arrival; the merchandise does not match the merchant's description; the cardholder disputes the quality of the product; the cardholder fraudulently claims the merchandise is damaged or not as described.",
        preventionSteps: [
          'Double check orders to ensure the correct item is shipped',
          'Package items carefully to avoid damage during shipping',
          'Provide honest, detailed product descriptions',
          'Accept returns from cardholders and issue credit promptly',
          'Never sell counterfeit products',
        ],
      },
      {
        code: 'C32',
        type: 'Cardmember Dispute',
        title: 'Goods/Services Damaged or Defective',
        description:
          'The cardholder claims to have received damaged or defective merchandise or services.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The merchandise was damaged upon its arrival; the merchandise does not match the merchant's description; the cardholder fraudulently claims the merchandise is damaged.",
        preventionSteps: [
          'Package items carefully to avoid damage during shipping',
          'Provide honest, detailed product descriptions',
          'Accept returns from cardholders and issue credit promptly',
          'Never sell counterfeit products',
        ],
      },
      {
        code: 'M10',
        type: 'Cardmember Dispute',
        title: 'Vehicle Rental - Capital Damages',
        description:
          'The cardholder claims to have been incorrectly billed for capital damages to a rented vehicle.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Cardholder misunderstood responsibility for capital damages; Cardholder disagrees with charges; Total damages exceed 110% of the agreed-upon amount; The merchant made an error in calculating the total.',
        preventionSteps: [
          'Maintain proof that the cardholder agreed to and signed an acknowledgment of responsibility for capital damages',
          'Make sure total charges do not exceed 110% of the agreed-upon amount',
        ],
      },
      {
        code: 'M49',
        type: 'Cardmember Dispute',
        title: 'Vehicle Rental - Theft or Loss of Use',
        description:
          'The cardholder claims to have been incorrectly charged for theft or loss of use of a rental vehicle.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant bills the cardholder for AMX-covered costs.',
        preventionSteps: [
          'Maintain proof that charges were valid and not for theft, loss of use, or other fees',
          'Maintain proof that a credit which directly offsets the disputed charge has already been processed.',
        ],
      },
      {
        code: 'FR2',
        type: 'Fraud',
        title: 'Fraud Full Recourse Program',
        description:
          "American Express' Fraud Full Recourse Program flagged the transaction.",
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Merchant falls under high-risk category; The issuer receives an unusual number of inquiries and chargebacks compared to processing history; merchant account has been disabled or cancelled due to suspicious activities or violation of the agreement.',
        preventionSteps: [
          'Always obtain authorized approval for e-commerce and recurring transactions regardless of the dollar amount',
          'Always verify the account number to prevent errors or misunderstandings',
          'Properly identify the type of transaction, such as card-not-present',
          'Use security codes and AVS.',
          'Simplify/clarify billing descriptors',
        ],
      },
      {
        code: 'FR4',
        type: 'Fraud',
        title: 'Immediate Chargeback Program',
        description:
          'This is a program that allows American Express to process any dispute immediately as the cardholder files a chargeback for any reason other than actual fraud.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Merchant enrolled in the program to avoid receiving inquiries and disputes; Amex put the merchant in the program due to an odd number of disputes; merchant’s business falls under a high chargeback ratio industry.',
        preventionSteps: [
          'Always obtain authorized approval for e-commerce and recurring transactions regardless of the dollar amount.',
          'Always verify account numbers.',
          'Use security codes and AVS.',
          'Set billing descriptor correctly.',
          'Train staff on the proper handling of terminal issues.',
        ],
      },
      {
        code: 'FR6',
        type: 'Fraud',
        title: 'Partial Immediate Chargeback Program',
        description:
          'The cardholder has disputed the charge and the merchant has been placed in the Partial Immediate Chargeback Program by American Express.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Merchant enrolled in the program to avoid receiving inquiries and disputes; Amex put the merchant in the program due to an odd number of disputes; merchant’s business falls under a high chargeback ratio industry.',
        preventionSteps: [
          'Always obtain authorized approval for e-commerce and recurring transactions regardless of the dollar amount.',
          'Always verify account numbers.',
          'Use security codes and AVS.',
          'Set billing descriptor correctly.',
        ],
      },
      {
        code: 'F10',
        type: 'Fraud',
        title: 'Missing Imprint',
        description:
          'Cardholder did not participate in a purchase and was not provided a copy of the card imprint (card-present environments only).',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant did not obtain correct card information from the cardholder or processed a transaction erroneously; transaction was processed on an account not associated with a valid card; a family member used the primary cardholder’s account without permission.',
        preventionSteps: [
          'Always request authorization for mail order, telephone order, internet, and recurring transactions, regardless of the dollar amount.',
          'Obtain additional validation, such as an imprint or PIN, or through CDCVM.',
          'Set billing descriptor correctly.',
        ],
      },
      {
        code: 'F14',
        type: 'Fraud',
        title: 'Missing Signature',
        description:
          'The cardholder claims to not have been involved in a transaction that was processed (card-present environments only).',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant neglected to give the cardholder a copy of the signed receipt; signature on the receipt is invisible or not prominent due to light ink, ribbed or colored paper, or fading; the original order receipt is mishandled or is scanned at a smaller size.',
        preventionSteps: [
          'Scan or make copies of the order receipt at actual size.',
          'Maintain full ink/toner in machines.',
          'Print and keep a copy of the order receipt.',
          'Always provide cardholder with a copy of the receipt.',
        ],
      },
      {
        code: 'F24',
        type: 'Fraud',
        title: 'No Cardmember Authorization',
        description:
          'The cardholder denies participation in the charge submitted by the merchant, who cannot prove otherwise.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant processed an invalid account number; a family member ordered from the primary cardholder’s account without permission; the cardholder is not able to recognize purchase on the billing statement; criminal fraud was committed.',
        preventionSteps: [
          'Always obtain authorized approval.',
          'Use secondary validation methods such as PIN.',
          'Set clear billing descriptors.',
        ],
      },
      {
        code: 'F29',
        type: 'Fraud',
        title: 'Card Not Present',
        description:
          'The cardholder claims an unauthorized transaction occurred without the physical card involved.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'In a card-absent environment (online/phone/direct mail sales, etc.), either a) an unauthorized (fraudulent) claim was processed using an invalid or stolen account number; or b) Due to an unclear or a confusing merchant name the cardholder believes the transaction to be fraudulent.',
        preventionSteps: [
          'Always request authorization for mail order, telephone order, internet, and recurring transactions, regardless of the dollar amount.',
          'Obtain additional validation, such as an imprint or PIN.',
          'Clearly and properly identify card present and card absent transactions.',
        ],
      },
      {
        code: 'F30',
        type: 'Fraud',
        title: 'EMV Counterfeit',
        description:
          'The cardholder denies participation in a situation where a counterfeit chip card was used at a POS system, but the transaction was not processed as a chip transaction.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The POS system was not an enabled for chip, or the transaction was manually keyed; the merchant's card processor did not transmit the full chip data to Visa; a chip-reading terminal was not actually used.",
        preventionSteps: [
          'Upgrade to compliant terminals.',
          'Make sure to obtain additional validation, such as an imprint or PIN.',
          'Take an imprint (either electronic or manual) for every card-present transaction.',
          'Train staff on the proper handling of terminal issues.',
        ],
      },
      {
        code: 'F31',
        type: 'Fraud',
        title: 'EMV List/Stolen/Non-received',
        description:
          'A fraudulent transaction was made using the actual credit card, according to the cardholder.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'Merchant did not ensure that the card was either swiped or that the chip was read; merchant did not obtain manual card imprint on a key-entered transaction; a card-not-present transaction was not identified as such; fraud was committed.',
        preventionSteps: [
          'Upgrade to compliant terminals.',
          'Make sure to obtain additional validation, such as an imprint or PIN.',
          'Take an imprint (either electronic or manual) for every card-present transaction.',
          'Train staff on the proper handling of terminal issues.',
        ],
      },
      {
        code: 'R03',
        type: 'Inquiry / Miscellaneous',
        title: 'Insufficient Reply',
        description:
          'Complete support and/or documentation were requested but not provided.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant did not supply documents requested. The merchant supplied only partial documentation.',
        preventionSteps: [
          'Read requests carefully and provide full documentation.',
        ],
      },
      {
        code: 'R13',
        type: 'Inquiry / Miscellaneous',
        title: 'No Reply',
        description:
          'American Express did not receive a response to an inquiry within the specified timeframe.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant did not respond to an American Express inquiry. The merchant responded to an American Express inquiry, but not within 20 days.',
        preventionSteps: [
          'Maintain proof you responded to the original inquiry within the specified timeframe.',
          'Maintain proof that a credit which directly offsets the disputed charge has already been processed.',
        ],
      },
      {
        code: 'M01',
        type: 'Inquiry / Miscellaneous',
        title: 'Chargeback Authorization',
        description:
          'The merchant authorized American Express to process a chargeback for the charge.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses: 'The merchant authorized the chargeback.',
        preventionSteps: [
          'Maintain proof that a credit which directly offsets the disputed charge has already been processed.',
        ],
      },
      {
        code: 'P01',
        type: 'Processing Errors',
        title: 'Unassigned Card Number',
        description:
          'The card number used for the transaction is not assigned to a valid account, or not assigned to the cardholder.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant erred, either in entering or calculating information; the cardholder supplied an incorrect or invalid card number.',
        preventionSteps: [
          'Double check numbers',
          'Obtain secondary validation, such as PIN',
        ],
      },
      {
        code: 'P03',
        type: 'Processing Errors',
        title: 'Credit Processed as Charge',
        description:
          'The cardholder was debited for a transaction that should have been a credit.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant processed a debit instead of a credit, processed a credit instead of processing a reversal, or in some other way processed a transaction that differed from the obtained authorization.',
        preventionSteps: [
          'Double check calculations, amount, and type of transaction before processing',
          'Train staff on the proper procedures for processing credits, debits, and reversals',
        ],
      },
      {
        code: 'P04',
        type: 'Processing Errors',
        title: 'Charge Processed as Credit',
        description:
          'The cardholder was credited for a transaction that should have been a debit.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant processed a credit instead of a debit, or in some other way processed a transaction that differed from the obtained authorization.',
        preventionSteps: [
          'Double check calculations, amount, and type of transaction before processing',
          'Train staff on the proper procedures for processing credits, debits, and reversals',
        ],
      },
      {
        code: 'P05',
        type: 'Processing Errors',
        title: 'Incorrect Charge Amount',
        description:
          'The amount in the authorization does not match the amount from the transaction.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The merchant erred, either in entering or calculating information; the merchant changed the amount of the transaction for some reason, such as adding a tip; exchanged the amount post-transaction without cardholder permission.',
        preventionSteps: [
          'Double check calculations and the final transaction amount before processing',
          "Don't alter the transaction amount without the cardholder's consent",
        ],
      },
      {
        code: 'P07',
        type: 'Processing Errors',
        title: 'Late Submission',
        description:
          'The charge was not submitted within the required timeframe.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The transaction wasn't processed in a timely fashion and the account has since been closed; transaction was posted after 181 days of the original transaction date.",
        preventionSteps: [
          'Deposit order receipt with your acquirer as soon as possible, preferably on the day of the sale',
          'Complete payment batches as soon as possible',
          'Process transactions within 180 days of the original purchase',
          'Be aware that paper receipts typically take longer to process',
        ],
      },
      {
        code: 'P08',
        type: 'Processing Errors',
        title: 'Duplicate Charge',
        description: 'A single transaction processed two or more times.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The merchant tries to submit multiple batches at one time; the transaction has multiple receipts; the transaction is duplicated in the merchant's system; the transaction was processed but the cardholder paid for the same merchandise or service by other means.",
        preventionSteps: [
          'Review transaction receipts before depositing',
          'Submit a batch only one time',
          'Void and credit any duplicate transactions',
          'Train staff on the proper procedures for handling credits',
        ],
      },
      {
        code: 'P22',
        type: 'Processing Errors',
        title: 'Non-Matching Card Number',
        description:
          'The card number used for the transaction is not assigned to a valid account, or not assigned to the cardholder.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          "The processed the transaction to an incorrect account number; The merchant did not authorize the transaction and it was processed to an account number not on the issuer's master file.",
        preventionSteps: [
          'As far as possible, swipe card or read chip for transactions',
          'Double-check numbers before processing',
          'Confirm account numbers with customers for card-not-present transactions',
          'Train staff on the proper procedures for processing transactions',
        ],
      },
      {
        code: 'P23',
        type: 'Processing Errors',
        title: 'Currency Discrepancy',
        description:
          'The merchant made one or more errors related to the transaction currency.',
        timeLimit: {
          issuerCardholder: 'n/a',
          acquirerMerchant: '20 days',
        },
        typicalCauses:
          'The transaction currency differs from the currency transmitted through Visa; the cardholder was not advised or did not agree that Dynamic Currency Conversion (DCC) would occur.',
        preventionSteps: [
          'Inform cardholders of the use of currency conversion and applicable fees',
          'Always give the cardholder the option to make the purchase using local currency',
          'Train your sales staff on proper procedures for transactions using a different currency',
        ],
      },
    ],
  },
];

function getReasonCodeDetail(
  cardScheme: string,
  reasonCode: string
): ReasonCodeDetail | null {
  const [result, setResult] = useState<ReasonCodeDetail | null>(null);

  useEffect(() => {
    const cardSchemeCodes = chargebackReasonCodes.find(
      (n) => n.cardScheme === cardScheme
    );

    if (cardSchemeCodes) {
      const codeDetail = cardSchemeCodes.codes.find(
        (c) => c.code === reasonCode
      );
      setResult(codeDetail ?? null);
    } else {
      setResult(null);
    }
  }, [cardScheme, reasonCode]);

  return result;
}

export { getReasonCodeDetail };
