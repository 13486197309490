import React, { useEffect, useRef, useState } from 'react';
import { AccountDocumentStatus } from '../../interfaces/accountState';
import {
  ApiResponse,
  FileResponse,
  UploadStatus,
  useFileUpload,
} from '../../hooks/useFileUpload';
import { SelectInput } from './SelectInput';
import { countries } from '../../functions/iso3166-alpha2';
import { pascalCaseToWords } from '../../functions/helpers';
import {
  getMaxFileSizeForType,
  getMinFileSizeForType,
} from '../../functions/documentHelpers';

interface DocumentUploadProps {
  type: string;
  isReadyToSubmit: boolean;
  status?: AccountDocumentStatus;
  backRequired: boolean;
  countryRequired: boolean;
  invalidReason?: string;
  existingCountry?: string;
  onFrontUploaded: (fileId: string, type: string, country?: string) => void;
  onBackUploaded: (fileId: string, type: string) => void;
}

const DocumentUpload = ({
  type,
  isReadyToSubmit,
  status,
  backRequired,
  countryRequired,
  invalidReason,
  existingCountry,
  onFrontUploaded,
  onBackUploaded,
}: DocumentUploadProps): React.ReactElement => {
  const inputFrontRef = useRef<HTMLInputElement | null>(null);
  const inputBackRef = useRef<HTMLInputElement | null>(null);
  const [frontError, setFrontError] = useState<string>('');
  const [backError, setBackError] = useState<string>('');
  const [frontLabel, setFrontLabel] = useState<string>('');
  const [backLabel, setBackLabel] = useState<string>('');
  const [isFrontComplete, setIsFrontComplete] = useState<boolean>(false);
  const [isBackComplete, setIsBackComplete] = useState<boolean>(false);
  const [isCountryComplete, setIsCountryComplete] = useState<boolean>(false);
  const [country, setCountry] = useState<string>('');
  const {
    uploadStatus: frontUploadStatus,
    uploadFile: frontUploadFile,
    uploadError: frontUploadError,
    response: frontUploadResponse,
  } = useFileUpload(
    'VerificationDocument',
    getMinFileSizeForType(type),
    getMaxFileSizeForType(type)
  );
  const {
    uploadStatus: backUploadStatus,
    uploadFile: backUploadFile,
    uploadError: backUploadError,
    response: backUploadResponse,
  } = useFileUpload(
    'VerificationDocument',
    getMinFileSizeForType(type),
    getMaxFileSizeForType(type)
  );

  useEffect(() => {
    if (invalidReason) {
      setFrontError(invalidReason);
    }
  }, [invalidReason]);

  useEffect(() => {
    setIsCountryComplete(!countryRequired || !!existingCountry);
    setCountry(existingCountry || '');
  }, [countryRequired, existingCountry]);

  useEffect(() => {
    if (isReadyToSubmit) {
      setFrontLabel('Ready to submit!');
      setBackLabel('Ready to submit!');
      setIsFrontComplete(true);
      setIsBackComplete(true);
      return;
    }
    switch (status) {
      case 'Pending':
        setFrontLabel('Under Review');
        setBackLabel('Under Review');
        setIsFrontComplete(true);
        setIsBackComplete(true);
        return;
      case 'Invalid':
        setFrontLabel('Attach new file...');
        setBackLabel('Attach new file...');
        setIsBackComplete(!backRequired);
        return;
      case 'Valid':
        setFrontLabel('Valid');
        setBackLabel('Valid');
        setIsFrontComplete(true);
        setIsBackComplete(true);
        return;
      default:
        setFrontLabel('Attach file...');
        setBackLabel('Attach file...');
        setIsBackComplete(!backRequired);
    }
  }, [status, backRequired, isReadyToSubmit]);

  const handleFrontChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(e, setIsFrontComplete, frontUploadFile);
  };

  const handleBackChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(e, setIsBackComplete, backUploadFile);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setIsComplete: (isComplete: boolean) => void,
    uploadFile: (file: File) => void
  ): void => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      setIsComplete(false);
      setFrontError('');
      setBackError('');
      uploadFile(newFile);
    }
  };

  useEffect(() => {
    handleStatusUpdate(
      frontUploadStatus,
      setFrontLabel,
      setFrontError,
      setIsFrontComplete
    );
  }, [frontUploadStatus.status]);

  useEffect(() => {
    handleStatusUpdate(
      backUploadStatus,
      setBackLabel,
      setBackError,
      setIsBackComplete
    );
  }, [backUploadStatus.status]);

  const handleStatusUpdate = (
    uploadStatus: UploadStatus,
    setLabel: (label: string) => void,
    setError: (error: string) => void,
    setIsComplete: (isComplete: boolean) => void
  ): void => {
    switch (uploadStatus.status) {
      case 'uploading':
        setLabel('Uploading...');
        setIsComplete(false);
        break;
      case 'error':
        setLabel('Attach new file...');
        setError('Error attaching file');
        setIsComplete(false);
        break;
      case 'tooLarge':
        setLabel('Attach new file...');
        setError('File is too large');
        setIsComplete(false);
        break;
      case 'tooSmall':
        setLabel('Attach new file...');
        setError('File is too small');
        setIsComplete(false);
        break;
      case 'completed':
        setLabel('Ready to submit!');
        setIsComplete(true);
        break;
    }
  };

  useEffect(() => {
    if (country) {
      setIsCountryComplete(true);
    }
  }, [country]);

  useEffect(() => {
    handleError(frontUploadError, setFrontError);
  }, [frontUploadError]);

  useEffect(() => {
    handleError(backUploadError, setBackError);
  }, [backUploadError]);

  const handleError = (
    uploadError: string | undefined,
    setError: (label: string) => void
  ): void => {
    if (uploadError) {
      setError(uploadError);
    }
  };

  useEffect(() => {
    if (!countryRequired || isCountryComplete) {
      handleResponse(frontUploadResponse, onFrontUploaded, country);
    }
  }, [frontUploadResponse, countryRequired, isCountryComplete]);

  useEffect(() => {
    handleResponse(backUploadResponse, onBackUploaded);
  }, [backUploadResponse]);

  const handleResponse = (
    response: ApiResponse<FileResponse> | undefined,
    onFileUploaded: (fileId: string, type: string, country?: string) => void,
    country?: string
  ): void => {
    if (response) {
      onFileUploaded(response.data.id, type, country);
    }
  };

  return (
    <div className='DocumentUpload' id={type}>
      <div
        className={
          isFrontComplete && isBackComplete && isCountryComplete
            ? 'DocumentUpload--complete'
            : 'DocumentUpload--title'
        }
      >
        <div
          className={
            isFrontComplete && isBackComplete && isCountryComplete
              ? 'DocumentUpload--name-complete'
              : 'DocumentUpload--name-not-complete'
          }
        >
          {pascalCaseToWords(type)}
        </div>
        <div>Front</div>
        {frontError && (
          <div className='DocumentUpload--error'>{frontError}</div>
        )}
        <div>
          <label
            htmlFor={`${type}-frontUpload`}
            className='DocumentUpload--upload'
          >
            {frontLabel}
          </label>
          <input
            id={`${type}-frontUpload`}
            type='file'
            onChange={handleFrontChange}
            accept='.jpg, .jpeg, .png, .pdf'
            ref={inputFrontRef}
            disabled={status === 'Pending'}
          />
        </div>
        {backRequired && (
          <>
            <div>Back </div>
            {backError && (
              <div className='DocumentUpload--error'>{backError}</div>
            )}
            <div>
              <label
                htmlFor={`${type}-backUpload`}
                className='DocumentUpload--upload'
              >
                {backLabel}
              </label>
              <input
                id={`${type}-backUpload`}
                type='file'
                onChange={handleBackChange}
                accept='.jpg, .jpeg, .png, .pdf'
                ref={inputBackRef}
              />
            </div>
          </>
        )}

        {countryRequired && (
          <>
            <div>
              <SelectInput
                label
                name='Country'
                value={country}
                setValue={setCountry}
                options={countries()}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { DocumentUpload };
