import { ReactElement, FormEvent, useState, useEffect, useMemo } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { TextInput } from '../../../components/forms/TextInput';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { countries } from '../../../functions/iso3166-alpha2';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import {
  BankAccountNumberType,
  BankIdType,
} from '../../../interfaces/payoutMethodsState';
import { handleNotSignedIn } from '../../../functions/helpers';
import { useGetPayoutMethods } from '../../../hooks/useGetPayoutMethods';
import {
  CreatePayoutMethodRequest,
  useCreatePayoutMethod,
} from '../../../hooks/useCreatePayoutMethod';
import {
  getAccountNumberTypeName,
  getBankIdTypeName,
  validateAccountNumber,
  validateBankId,
} from '../../../functions/payoutMethodHelpers';

interface optionsShape {
  value: string;
  name: string;
}

const AddBankAccountRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const { fetchPayoutMethods } = useGetPayoutMethods();
  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/banking';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { coBrandingState, payoutMethodsState, profileState } =
    useGlobalDataState();
  const [accountName, setAccountName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<string>('');
  const [bankId, setBankId] = useState<string>('');
  const [bankIdError, setBankIdError] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryOptions, setCountryOptions] = useState<optionsShape[]>();
  const [currency, setCurrency] = useState<string>(query.get('currency') || '');
  const [currencyOptions, setCurrencyOptions] = useState<optionsShape[]>();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [accountNumberType, setAccountNumberType] =
    useState<BankAccountNumberType>();
  const [bankIdType, setBankIdType] = useState<BankIdType>();

  const { payoutMethod, isLoading, error, createPayoutMethod } =
    useCreatePayoutMethod();

  useEffect(() => {
    if (payoutMethod) {
      history.push(returnTo);
    }
  }, [payoutMethod]);

  useEffect(() => {
    setBtnDisabled(false);
    if (accountName && accountNumber && bankId && country && currency) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [accountName, accountNumber, bankId, country, currency]);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    } else if (!permissions.has('PayoutMethodsModify')) {
      history.push('/settings/banking');
      return;
    } else if (!payoutMethodsState?.data) {
      fetchPayoutMethods();
      return;
    } else if (
      !payoutMethodsState.data.settings.enabled ||
      !payoutMethodsState.data.settings.supportedCurrencies
    ) {
      history.push(returnTo);
      return;
    }
  }, [isSignedIn, payoutMethodsState]);

  useEffect(() => {
    if (currency) {
      const allowedCountries: any = [];
      const possibleCountries = countries();
      const supportedCountries =
        payoutMethodsState.data?.settings.supportedCurrencies.find(
          (c) => c.code === currency
        )?.supportedCountries;
      supportedCountries?.forEach((i: any) => {
        possibleCountries.forEach((obj) => {
          if (i === obj.value) {
            allowedCountries.push({
              name: obj.name,
              value: i,
            });
          }
        });
      });
      setCountryOptions(allowedCountries);
      setCountry(
        allowedCountries.length === 1 ? allowedCountries[0].value : ''
      );
    }
  }, [currency, payoutMethodsState]);

  useEffect(() => {
    if (country) {
      const selectedCurrency =
        payoutMethodsState.data?.settings.supportedCurrencies.find(
          (c) => c.code === currency
        );
      setAccountNumberType(selectedCurrency?.accountNumberType);
      setBankIdType(selectedCurrency?.bankIdType);
    }
  }, [country, payoutMethodsState]);

  useEffect(() => {
    const supportedCurrencies =
      payoutMethodsState.data?.settings.supportedCurrencies.map(
        (currency: any) => ({
          name: `${currency.symbol} - ${currency.name} - ${currency.code}`,
          value: currency.code,
        })
      ) ?? [];
    setCurrencyOptions(supportedCurrencies);
    supportedCurrencies.length === 1 &&
      setCurrency(supportedCurrencies[0].value);
  }, [payoutMethodsState]);

  const addAccount = async (): Promise<void> => {
    setAccountNumberError('');
    setBankIdError('');
    if (!accountNumber) {
      setAccountNumberError(`Account Number / IBAN must be provided`);
      return;
    }
    if (!bankId) {
      setBankIdError(`${bankIdType} must be provided`);
      return;
    }
    if (
      !validateAccountNumber(
        accountNumber,
        accountNumberType!,
        setAccountNumberError
      )
    ) {
      return;
    }
    if (!validateBankId(bankId, bankIdType!, setBankIdError)) {
      return;
    }
    const body: CreatePayoutMethodRequest = {
      type: 'BankAccount',
      displayName: accountName,
      country: country,
      currency: currency,
      bankAccount: {
        bankIdType: bankIdType!,
        bankId: bankId,
        accountNumberType: accountNumberType!,
        accountNumber: accountNumber,
      },
    };
    createPayoutMethod(body);
  };

  return (
    <LayoutIllustration
      title={
        <>
          Add your <span>bank account</span>
        </>
      }
      coBrandedTitle={
        <>
          Add a bank account so you can receive your payouts from{' '}
          <span>{coBrandingState?.data?.name}</span>
        </>
      }
      subTitle={
        <>
          Add a bank account so you can receive your <span>payouts</span>
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <ServerError error={error} />
        <TextInput
          label
          name='Account Name'
          value={accountName}
          setValue={setAccountName}
          description='A friendly name to help you recognise this account'
          focus
        />

        {currencyOptions && (
          <SelectInput
            label
            name='Currency'
            description="The bank account's currency - it can only receive payouts in this currency"
            value={currency}
            setValue={setCurrency}
            options={currencyOptions}
          />
        )}

        {currency && countryOptions && (
          <SelectInput
            label
            name='Country'
            value={country}
            setValue={setCountry}
            options={countryOptions.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        )}

        {country && currency && (
          <>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                gridGap: '12px',
              }}
            >
              <TextInput
                label
                name={getAccountNumberTypeName(accountNumberType)}
                value={accountNumber}
                setValue={setAccountNumber}
                type={accountNumberType === 'Iban' ? 'String' : 'number'}
              />
              <TextInput
                label
                name={getBankIdTypeName(bankIdType)}
                value={bankId}
                setValue={setBankId}
                type={bankIdType === 'SwiftCode' ? 'String' : 'number'}
              />
            </div>
            <div
              style={{
                color: '#ec3f3f',
                fontSize: '11px',
                position: 'relative',
                top: '-6px',
              }}
            >
              {accountNumberError || bankIdError}
            </div>
          </>
        )}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '200px 100px',
            gridGap: '12px',
          }}
        >
          <Button
            name='Add Account'
            click={addAccount}
            loading={isLoading}
            disabled={btnDisabled}
          />
          <Button
            name={isOnboarding ? 'Skip' : 'Cancel'}
            click={(): void => history.push(returnTo)}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { AddBankAccountRoute };
