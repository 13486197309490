import { useState, useRef, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';

export interface UploadStatus {
  percentage: number;
  uploadedBytes: number;
  totalBytes: number;
  status:
    | 'uploading'
    | 'completed'
    | 'idle'
    | 'error'
    | 'aborted'
    | 'tooLarge'
    | 'tooSmall';
}

interface UseFileUploadReturn {
  file: File | null;
  uploadStatus: UploadStatus;
  uploadError: string | undefined;
  isLoading: boolean;
  uploadFile: (selectedFile: File) => Promise<void>;
  response: ApiResponse<FileResponse> | undefined;
  abortUpload: () => void;
}

export interface FileResponse {
  id: string;
  name: string;
  type: string;
  category: FileCategory;
  sizeInBytes: number;
  createdTimestamp: number;
  lastUpdatedTimestamp: number;
}

export interface ApiResponse<FileResponse> {
  data: FileResponse;
  status: number;
}

export type FileCategory = 'Evidence' | 'VerificationDocument';

export const useFileUpload = (
  category: FileCategory,
  minFileSize: number,
  maxFileSize: number
): UseFileUploadReturn => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    percentage: 0,
    uploadedBytes: 0,
    totalBytes: 0,
    status: 'idle',
  });
  const progressCompletePercentage = 100;

  const [
    response,
    isLoading,
    uploadError,
    sendRequest,
    abortFunction,
    uploadProgress,
  ] = useApiRequest<FileResponse>();

  const isMounted = useRef(true);

  useEffect(() => {
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  const uploadFile = async (selectedFile: File): Promise<void> => {
    setFile(selectedFile);

    if (selectedFile.size < minFileSize) {
      setUploadStatus({
        percentage: 0,
        uploadedBytes: 0,
        totalBytes: selectedFile.size,
        status: 'tooSmall',
      });
      return;
    }

    if (selectedFile.size > maxFileSize) {
      setUploadStatus({
        percentage: 0,
        uploadedBytes: 0,
        totalBytes: selectedFile.size,
        status: 'tooLarge',
      });
      return;
    }

    setUploadStatus((prevState) => ({
      ...prevState,
      totalBytes: selectedFile.size,
      status: 'uploading',
    }));

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    formData.append('category', category);

    await sendRequest({
      url: '/v1/files',
      method: HttpMethod.POST,
      body: formData,
      rawBody: true,
    });
  };

  useEffect(() => {
    if (file && uploadError) {
      setUploadStatus((prev) => ({
        ...prev,
        status: 'error',
      }));
    }
    if (file && !uploadError && uploadProgress) {
      const updatedStatus =
        uploadProgress === progressCompletePercentage
          ? 'completed'
          : 'uploading';
      setUploadStatus((prev) => ({
        ...prev,
        percentage: uploadProgress,
        uploadedBytes:
          (uploadProgress / progressCompletePercentage) * file.size,
        status: updatedStatus,
      }));
    }
  }, [uploadProgress, file, uploadError]);

  const abortUpload = (): void => {
    abortFunction();
    if (isMounted.current) {
      setUploadStatus((prev) => ({ ...prev, status: 'aborted' }));
    }
  };

  return {
    file,
    uploadStatus,
    uploadError,
    isLoading,
    uploadFile,
    response,
    abortUpload,
  };
};
