import { ReactElement } from 'react';
import { createMaskedPan, getCardSchemeIcon } from '../../functions/helpers';
import { CardScheme } from '../../interfaces/state';

export interface PropsShape {
  card: {
    scheme: CardScheme;
    last4: string;
    expiryMonth: string;
    expiryYear: string;
  };
}

const CardPayment = ({ card }: PropsShape): ReactElement => {
  return (
    <div className='CardPayment'>
      <div
        className='card'
        style={{
          backgroundImage: `url(${getCardSchemeIcon(card.scheme)})`,
        }}
      >
        <div className='last'>{createMaskedPan(card.scheme, card.last4)}</div>

        <div className='expires'>
          <div className='title'>Expires End</div>
          <div className='value'>
            {card.expiryMonth} / {card.expiryYear}
          </div>
        </div>
      </div>
    </div>
  );
};
export { CardPayment };
