import React, { ReactElement, useRef } from 'react';

export interface PropsShape {
  height?: number;
  gap?: number;
  children: ReactElement[];
}

const ScrollBox = ({
  height = 300,
  gap = 12,
  children,
}: PropsShape): ReactElement => {
  const state = useRef({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });
  const box = useRef<HTMLInputElement>(null);

  const mouseUp = (): any => {
    // TODO: allow clicking items
    state.current.isScrolling = false;
  };
  const mouseDown = (e: any): any => {
    e.preventDefault();
    state.current.isScrolling = true;
    state.current.clientX = e.clientX;
  };

  const mouseMove = (e: any): any => {
    e.preventDefault();
    if (state.current.isScrolling) {
      const initPos = box.current?.scrollLeft!;
      const maxWidth = box.current?.scrollWidth!;
      const moved = state.current.clientX - e.clientX;
      const change = initPos + moved;
      const newPos = change < 0 ? 0 : change > maxWidth ? maxWidth : change;
      box.current?.scrollTo(newPos, 0);
    }
  };
  const onScroll = (): any => {
    state.current.scrollX = box.current?.scrollLeft!;
  };
  return (
    <div
      className='ScrollBox'
      onMouseUp={mouseUp}
      onMouseDown={mouseDown}
      onMouseMove={mouseMove}
      onScroll={onScroll}
      ref={box}
      style={{
        minHeight: `${height}px`,
        gridGap: `${gap}px`,
      }}
    >
      {children?.map((item: any, index: number) => (
        <div key={index} className='ScrollBox--item'>
          {item}
        </div>
      ))}
    </div>
  );
};
export { ScrollBox };
