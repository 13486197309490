import { Customer } from '../interfaces/customersState';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface CreateCustomerRequest {
  email: string;
  firstName?: string;
  lastName?: string;
  homePhoneNumber?: string;
  mobilePhoneNumber?: string;
  metadata?: { [key: string]: string };
}

export interface CreateCustomerApi {
  isLoading: boolean;
  error: string | undefined;
  data: Customer | undefined;
  request: (request: CreateCustomerRequest) => Promise<void>;
}

const useCreateCustomer = (): CreateCustomerApi => {
  const [response, isLoading, error, sendRequest] = useApiRequest<Customer>();

  const createCustomer = async (body: CreateCustomerRequest): Promise<void> => {
    const url = '/v1/customers';
    const method = HttpMethod.POST;
    return await sendRequest({ url, method, body });
  };

  return {
    isLoading: isLoading,
    error: error,
    data: response?.data,
    request: createCustomer,
  };
};

export { useCreateCustomer };
