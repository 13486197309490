import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { PersonShape } from '../interfaces/personsState';
import { BusinessRole } from '../interfaces/accountState';
import { AccountAddressRequest } from './useEditAccount';
import { useGlobalDataState } from './useGlobalDataState';

interface UseCreatePersonReturnType {
  personData: PersonShape | null;
  isLoading: boolean;
  error: any;
  apiCreatePerson: (body: CreatePersonRequest) => Promise<void>;
}

export interface CreatePersonRequest {
  firstName: string;
  middleNames?: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  countryOfBirth?: string;
  gender: string;
  nationalities: string[];
  address: AccountAddressRequest;
  phoneNumber: string;
  businessRoles: BusinessRole[];
}

function useCreatePerson(): UseCreatePersonReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PersonShape>();
  const { personsState, setPersonsState } = useGlobalDataState();
  const [personData, setPersonData] = useState<PersonShape | null>(null);

  const apiCreatePerson = async (body: CreatePersonRequest): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/account/persons',
        method: HttpMethod.POST,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while creating the person:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const createdPerson = apiResponse.data;
      setPersonData(createdPerson);
      const existingPersons = personsState.data?.items ?? [];
      existingPersons.push(createdPerson);
      setPersonsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: existingPersons,
        },
      }));
    }
  }, [apiResponse]);

  return {
    personData: personData,
    isLoading,
    error,
    apiCreatePerson,
  };
}

export { useCreatePerson };
