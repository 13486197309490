import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomersChart } from '../components/dataGrids/CustomersChart';
import {
  DashboardDataType,
  DashboardFilter,
  FilterDashboard,
} from '../components/dataGrids/FilterDashboard';
import { PaymentsChart } from '../components/dataGrids/PaymentsChart';
import { VolumeChart } from '../components/dataGrids/VolumeChart';
import { Button } from '../components/forms/Button';
import { ServerError } from '../components/forms/ServerError';
import { SideMenu } from '../components/layouts/SideMenu';
import {
  dateFromYYYYMMDDString,
  epochToDateString,
  handleNotSignedIn,
} from '../functions/helpers';
import { useGetDashboardData } from '../hooks/useGetDashboardData';
import { useGlobalState } from '../hooks/useGlobalState';
import { DashboardItem } from '../interfaces/dashboardState';
import { Currency } from '../interfaces/state';
import { Layout } from '../layout';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const DashboardRoute = (): ReactElement => {
  const dateNow = new Date();
  const startDate = new Date();
  startDate.setDate(dateNow.getDate() - 15);

  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const [currencyObj, setCurrencyObj] = useState<Currency>({
    code: 'GBP',
    symbol: '£',
    minorDigits: 2,
    name: 'British Pound Sterling',
  });
  const [filter, setFilter] = useState<DashboardFilter>({
    selectedData: ['Volume', 'Payments', 'Customers'],
    startDate: startDate,
    endDate: dateNow,
    currency: 'GBP',
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const apiOperation = useGetDashboardData();
  const [dataPoints, setDataPoints] = useState<DashboardItem[]>([]);
  const [xAxisCategories, setXAxisCategories] = useState<string[]>([]);
  const [visibleData, setVisibleData] = useState<Set<DashboardDataType>>(
    new Set(filter.selectedData)
  );

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (!apiOperation.isLoading) {
      apiOperation.request(filter.startDate, filter.endDate);
    }
  }, [filter.startDate, filter.endDate]);

  useEffect(() => {
    setVisibleData(new Set(filter.selectedData));
  }, [filter.selectedData]);

  useEffect(() => {
    const matchingCurrency = apiOperation.data?.enabledCurrencies.find(
      (c) => c.code === filter.currency
    );
    if (matchingCurrency) {
      setCurrencyObj(matchingCurrency);
    }
    const dataForSelection = dataForSelectedCurrency(filter.currency);
    setDataPoints(dataForSelection);
    setXAxisCategories(getDatesForXAxis(dataForSelection));
  }, [filter.currency]);

  useEffect(() => {
    if (apiOperation.data) {
      const dataForSelection = dataForSelectedCurrency(filter.currency);
      setDataPoints(dataForSelection);
      setXAxisCategories(getDatesForXAxis(dataForSelection));
    }
  }, [apiOperation.data]);

  const dataForSelectedCurrency = (selected: string): DashboardItem[] => {
    const selectedDates = getSelectedDatesWithinRange();
    const selectedCurrency = apiOperation.data?.enabledCurrencies.find(
      (c) => c.code === selected
    );
    if (!selectedCurrency) {
      return [];
    }
    const items =
      apiOperation.data?.items ??
      selectedDates.map((date) => emptyDataPoint(selectedCurrency, date));
    return selectedDates.map(
      (date) =>
        items.find(
          (i) => i.date === date && i.currency.code === selectedCurrency.code
        ) ?? emptyDataPoint(selectedCurrency, date)
    );
  };

  const getSelectedDatesWithinRange = (): string[] => {
    const dates = [];
    const start = filter.startDate.toISOString().split('T')[0];
    const end = filter.endDate.toISOString().split('T')[0];
    const dateMove = new Date(start);
    let mutableStart = start;
    // eslint-disable-next-line no-loops/no-loops
    while (mutableStart < end) {
      mutableStart = dateMove.toISOString().slice(0, 10);
      dates.push(mutableStart);
      dateMove.setDate(dateMove.getDate() + 1);
    }
    return dates;
  };

  const emptyDataPoint = (currency: Currency, date: string): DashboardItem => {
    return {
      date: date,
      currency: currency,
      netTotalVolume: 0,
      totalVolume: 0,
      totalBalance: 0,
      totalRevenueCaptured: 0,
      totalRevenueRefunded: 0,
      averageSpendPerCustomer: 0,
      numberOfExistingCustomers: 0,
      numberOfNewCustomers: 0,
      platform: {
        netCommissionCollected: 0,
      },
    };
  };

  const getDatesForXAxis = (dataPoints: DashboardItem[]): string[] =>
    getSelectedDateRange(dataPoints).map((date) =>
      epochToDateString(date.getTime() / 1000, false, true, true)
    );

  const getSelectedDateRange = (dataPoints: DashboardItem[]): Date[] =>
    dataPoints.map((i) => dateFromYYYYMMDDString(i.date));

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='LayoutMain'>
        <div className='LayoutMain--grid LayoutMain--grid-no-button'>
          <div className='LayoutMain--mobile-filter'>
            <Button
              name='Filter'
              color='GREY'
              click={handleMobileFilter}
              margin='0px'
            />
          </div>
          <div className='LayoutMain--filter'>
            <FilterDashboard
              availableCurrencies={
                apiOperation.data?.enabledCurrencies?.map((c) => c.code) ?? []
              }
              setFilter={setFilter}
            />
          </div>
          <div className='LayoutMain--data'>
            {apiOperation.error && <ServerError error={apiOperation.error} />}
            <div className='DashboardRoute'>
              {visibleData.has('Volume') && (
                <>
                  <div className='DashboardRoute--volume'>
                    <VolumeChart
                      value={{
                        isLoading: apiOperation.isLoading,
                        categories: xAxisCategories,
                        data: {
                          currency: currencyObj,
                          values: dataPoints.map((i) => i.totalVolume),
                        },
                      }}
                    />
                  </div>
                </>
              )}
              {visibleData.has('Payments') && (
                <div className='DashboardRoute--payments'>
                  <PaymentsChart
                    isLoading={apiOperation.isLoading}
                    currency={currencyObj}
                    captured={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.totalRevenueCaptured),
                    }}
                    refunded={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.totalRevenueRefunded),
                    }}
                    netVolume={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.netTotalVolume),
                    }}
                  />
                </div>
              )}
              {visibleData.has('Customers') && (
                <div>
                  <CustomersChart
                    isLoading={apiOperation.isLoading}
                    currency={currencyObj}
                    newCustomers={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.numberOfNewCustomers),
                    }}
                    returningCustomers={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.numberOfExistingCustomers),
                    }}
                    spendPerCustomer={{
                      categories: xAxisCategories,
                      data: dataPoints.map((i) => i.averageSpendPerCustomer),
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <SideMenu
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            position='LEFT'
            width='SUPER'
          >
            <div style={{ padding: '12px' }}>
              <FilterDashboard
                availableCurrencies={
                  apiOperation.data?.enabledCurrencies?.map((c) => c.code) ?? []
                }
                setFilter={setFilter}
              />
            </div>
          </SideMenu>
        </div>
      </div>
    </Layout>
  );
};

export { DashboardRoute };
