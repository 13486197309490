import React, { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { IndividualItem } from '../../components/layouts/IndividualItem';
import { Copy } from '../../components/forms/Copy';
import { useHistory } from 'react-router-dom';
import { useGetPersons } from '../../hooks/useGetPersons';
import { useGetAccount } from '../../hooks/useGetAccount';
import { useVerifyAccount } from '../../hooks/useVerifyAccount';
import { Button } from '../../components/forms/Button';
import { PersonShape } from '../../interfaces/personsState';
import {
  AccountDocument,
  AccountDocumentCategory,
  AccountShape,
  EntityType,
  RequiredVerificationDocument,
  RequiredField,
  RequiredVerificationPerson,
  Verification,
  VerificationStatus,
  VerificationError,
} from '../../interfaces/accountState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermission } from '../../interfaces/profileState';
import {
  businessRoleRequired,
  hasPersonWithRole,
  personName,
} from '../../functions/personHelpers';
import {
  getAddress,
  getDocuments,
  individualName,
  isPendingVerification,
} from '../../functions/accountHelpers';
import { useGlobalState } from '../../hooks/useGlobalState';
import { startReplay } from '../../functions/sessionReplayHelpers';
import { ServerError } from '../../components/forms/ServerError';
import {
  camelCaseToWords,
  epochToDateString,
  pascalCaseToWords,
} from '../../functions/helpers';
import {
  ActionsShape,
  RequiredActions,
} from '../../components/layouts/RequiredActions';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getCategoryFriendlyName } from '../../functions/documentHelpers';

const Account = (): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const { profileState, setPersonsState } = useGlobalDataState();
  const [showAccount, setShowAccount] = useState(false);
  const [showModifyAccount, setShowModifyAccount] = useState(false);
  const [showPersons, setShowPersons] = useState(false);
  const [showModifyPersons, setShowModifyPersons] = useState(false);
  const [account, setAccount] = useState<AccountShape | null>();

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      AccountView: setShowAccount,
      AccountModify: setShowModifyAccount,
      PersonsView: setShowPersons,
      PersonsModify: setShowModifyPersons,
    };
    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const assignIndividualRoles = (): void => {
    history.push(`/settings/individuals/assign-roles`);
  };

  const addIndividual = (): void => {
    history.push(`/settings/individuals/add-individual`);
  };

  const editPerson = (person: PersonShape): void => {
    setPersonsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: person,
      },
    }));
    let url = `/settings/individuals/edit-individual/${person.id}`;
    if (person.verification.status === 'Required') {
      url += '/docs';
    }
    history.push(url);
  };
  const editDocs = (): void => {
    history.push(`/settings/account/edit-docs`);
  };
  const editAddress = (): void => {
    history.push(`/settings/account/edit-address`);
  };

  const editBusiness = (): void => {
    history.push(`/settings/account/edit-business`);
  };

  const editIndividual = (): void => {
    history.push(`/settings/account/edit-individual`);
  };

  const {
    personsData,
    isLoading: isPersonsLoading,
    error: personsError,
    fetchPersons,
  } = useGetPersons();
  const {
    accountData,
    isLoading: isAccountLoading,
    error: accountError,
    fetchAccount,
  } = useGetAccount();
  const {
    accountData: verifyAccountData,
    isLoading: isVerifyLoading,
    error: verifyError,
    apiVerifyAccount,
  } = useVerifyAccount();

  useEffect(() => {
    if (personsEnabled(account?.entityType) && showPersons) {
      fetchPersons();
    }
  }, [account, showPersons]);

  useEffect(() => {
    setAccount(accountData);
    if (
      accountData?.verification.status === 'Required' ||
      accountData?.verification.persons?.status === 'Required'
    ) {
      startReplay();
    }
  }, [accountData]);

  useEffect(() => {
    setAccount(verifyAccountData);
  }, [verifyAccountData]);

  useEffect(() => {
    if (showAccount) {
      fetchAccount();
    }
  }, [showAccount]);

  const getEntityTypeTitleSuffix = (entityType: EntityType): string =>
    entityType === 'Business' ? 'your business' : 'you';

  const showSubmitVerification = (account: AccountShape): boolean =>
    account.entityType === 'Business' &&
    showModifyAccount &&
    account.verification.status === 'Required';

  const submitVerificationEnabled = (
    accountVerification: Verification
  ): boolean =>
    accountVerification.status === 'Required' &&
    !accountVerification.requiredFields &&
    !accountVerification.requiredDocuments &&
    !accountVerification.errors &&
    accountVerification.persons?.status !== 'Required';

  const getEntityTitle = (account: AccountShape): string =>
    account.business?.name ?? individualName(account.individual!);

  const getEntitySubtitle = (account: AccountShape): ReactElement => {
    return account.business ? (
      <>
        {camelCaseToWords(account.business.type)}
        {account.business.registrationNumber && (
          <>
            <br />
            <br />
            Reg No: {account.business?.registrationNumber}
            <br />
            Email: {account.business.contactEmail}
            {account.business.phoneNumber && (
              <>
                <br />
                Phone: {formatPhoneNumberIntl(account.business.phoneNumber)}
              </>
            )}
            {account.business.websiteUrl && (
              <>
                <br />
                URL: {account.business.websiteUrl}
              </>
            )}
          </>
        )}
      </>
    ) : (
      <>
        Individual / Sole Trader <br />{' '}
        {account.individual?.email && (
          <>
            Email: {account.individual.email} <br />
          </>
        )}
        {account.individual?.phoneNumber && (
          <>
            Phone: {formatPhoneNumberIntl(account.individual.phoneNumber)}
            <br />
          </>
        )}
        {account.individual?.dateOfBirth && (
          <>Date of birth: {account.individual.dateOfBirth} </>
        )}
      </>
    );
  };

  const getEntityDetailsTitle = (entityType: EntityType): string =>
    entityType === 'Business' ? 'Business' : 'Personal Details';

  const getEntityDetailsSubtitle = (entityType: EntityType): string =>
    entityType === 'Business'
      ? 'Update your business details' +
        (profile.processingModel === 'Iso'
          ? ''
          : ' for compliance with KYB checks.')
      : 'Update your personal details' +
        (profile.processingModel === 'Iso'
          ? ''
          : ' for compliance with KYC checks.');

  const showFieldRequiredActions = (
    requiredFields?: RequiredField[]
  ): boolean => !!requiredFields;

  const getFieldRequiredActions = (
    requiredFields: RequiredField[]
  ): ActionsShape[] =>
    requiredFields.map((field: RequiredField) => ({
      action: camelCaseToWords(field.name.split('.')[1]),
      isComplete: false,
    }));

  const getFieldRequiredActionsTitle = (entityType: EntityType): string =>
    `We require the following information to verify ${getEntityTypeTitleSuffix(
      entityType
    )}`;

  const personsEnabled = (entityType?: EntityType): boolean =>
    entityType === 'Business' && profile.processingModel !== 'Iso';

  const verificationEnabled = (): boolean => profile.processingModel !== 'Iso';

  const getPersonsSubTitle = (): ReactElement => (
    <>
      Add or update Directors, Ultimate Beneficial Owners, and a Business{' '}
      Contact.
      <br />
      <br />
      <a
        href='https://ryftpay.freshdesk.com/support/solutions/articles/103000271206-business-roles-directors-ubos-business-contact-'
        target='_blank'
      >
        Click here
      </a>{' '}
      to find out more about these roles
    </>
  );

  const getAccountVerificationFaqUrl = (entityType: EntityType): string => {
    let faqUrl =
      'https://ryftpay.freshdesk.com/support/solutions/articles/103000271202-account-verification';
    switch (entityType) {
      case 'Business':
        return (faqUrl += '#Business-Verification-(KYB)');
      case 'Individual':
        return (faqUrl += '#Individual-Verification-(KYC)');
    }
  };

  const getAccountVerificationSubTitle = (
    entityType: EntityType
  ): ReactElement => (
    <>
      Ensure your account adheres to KYB/KYC regulations. Review and update your{' '}
      information to maintain compliance and security.{' '}
      <a href={getAccountVerificationFaqUrl(entityType)} target='_blank'>
        Click here
      </a>{' '}
      to find out more about the verification process
    </>
  );

  const getAccountVerificationHeaderTitle = (
    entityType: EntityType,
    verificationStatus: VerificationStatus,
    personsVerificationStatus?: VerificationStatus
  ): string => {
    if (entityType === 'Business') {
      if (verificationStatus === 'Required') {
        return 'Once all sections are accurately filled, submit your account for verification';
      }
      if (personsVerificationStatus === 'Required') {
        return 'Once documents have been uploaded for all persons, we will verify your account';
      }
      if (personsVerificationStatus === 'PendingVerification') {
        return 'We are currently verifying your account, please check back in 24 hours or contact support if you need help';
      }
    }
    switch (verificationStatus) {
      case 'NotRequired':
        return 'You do not need to provide any additional information just yet, we will contact you when this is required.';
      case 'PendingVerification':
        return 'We are currently verifying your account, please check back in 24 hours or contact support if you need help';
      case 'Verified':
        return 'Your account has been verified';
      default:
        return 'Once all sections are accurately filled, we will verify your account';
    }
  };

  const getAddressTitle = (entityType: EntityType): string =>
    entityType === 'Business' ? 'Registered Address' : 'Address';

  const editAccount = (entityType: EntityType): (() => void) =>
    entityType === 'Business' ? editBusiness : editIndividual;

  const getDocumentsTitle = (entityType: EntityType): string =>
    entityType === 'Business' ? 'Business Documents' : 'Personal Documents';

  const getDocumentsSubtitle = (entityType: EntityType): string =>
    entityType === 'Business'
      ? 'Add or update your business documents for compliance with KYB checks.'
      : 'Add or update your personal documents for compliance with KYC checks.';

  const getDocumentsHeaderTitle = (
    verificationStatus: VerificationStatus
  ): string => {
    switch (verificationStatus) {
      case 'NotRequired':
        return 'No documents are required yet';
      case 'PendingVerification':
        return 'We are currently reviewing your documents';
      case 'Required':
        return 'We will review your documents once your account is submitted for verification';
      default:
        return 'Your documents have been verified';
    }
  };

  const getPersonsHeaderTitleWhenNoActionsRequired = (
    personsVerificationStatus: VerificationStatus
  ): string => {
    switch (personsVerificationStatus) {
      case 'NotRequired':
        return 'Tell us about the individuals in your business';
      case 'PendingVerification':
        return 'We are currently verifying your individuals';
      default:
        return 'Your individuals have been verified, please keep us updated if there are any changes';
    }
  };

  const getIndividualsRequiredActions = (
    persons: PersonShape[],
    requiredPersons?: RequiredVerificationPerson[]
  ): ActionsShape[] => {
    if (!requiredPersons) {
      return [];
    }
    const requiredActions: ActionsShape[] = [];
    const hasBusinessContact: boolean = hasPersonWithRole(
      persons,
      'BusinessContact'
    );
    if (
      businessRoleRequired(requiredPersons, 'BusinessContact') ||
      hasBusinessContact
    ) {
      requiredActions.push({
        action: 'Business Contact',
        isComplete: hasBusinessContact,
      });
    }
    const hasDirector: boolean = hasPersonWithRole(persons, 'Director');
    if (businessRoleRequired(requiredPersons, 'Director') || hasDirector) {
      requiredActions.push({
        action: 'Director',
        isComplete: hasDirector,
      });
    }
    const hasUbo: boolean = hasPersonWithRole(
      persons,
      'UltimateBeneficialOwner'
    );
    if (
      businessRoleRequired(requiredPersons, 'UltimateBeneficialOwner') ||
      hasUbo
    ) {
      requiredActions.push({
        action: 'Ultimate Beneficial Owner',
        isComplete: hasUbo,
      });
    }
    if (requiredActions.some((action: ActionsShape) => !action.isComplete)) {
      return requiredActions;
    }
    return [];
  };

  const showDocumentsRequiredActions = (account: AccountShape): boolean =>
    !!account.verification.requiredDocuments ||
    (!!account.verification.errors &&
      account.verification.errors.some(
        (e: VerificationError) => e.code === 'InvalidDocument'
      ));

  const getDocumentsRequiredActions = (
    account: AccountShape
  ): ActionsShape[] => {
    const requiredDocuments = account.verification.requiredDocuments;
    if (requiredDocuments) {
      const completedCategories =
        getDocuments(account)?.map(
          (document: AccountDocument) => document.category
        ) ?? [];
      const allCategories = requiredDocuments
        .map((document: RequiredVerificationDocument) => document.category)
        .concat(completedCategories);
      return allCategories.map((category: AccountDocumentCategory) => ({
        action: getCategoryFriendlyName(category),
        isComplete: completedCategories.some(
          (completedCategory: AccountDocumentCategory) =>
            completedCategory == category
        ),
      }));
    }
    const invalidDocuments = getDocuments(account)?.filter(
      (document: AccountDocument) => document.status === 'Invalid'
    );
    if (invalidDocuments && invalidDocuments.length > 0) {
      return invalidDocuments?.map((document: AccountDocument) => ({
        action: `${pascalCaseToWords(document.type)} - ${
          document.invalidReason
        }`,
        isComplete: false,
      }));
    }
    return [];
  };

  const getDocumentsRequiredActionsTitle = (account: AccountShape): string =>
    account.verification.requiredDocuments
      ? `We require the following document(s) to verify ${getEntityTypeTitleSuffix(
          account.entityType
        )}`
      : 'The following documents are invalid:';

  const showTermsOfService = (account?: AccountShape | null): boolean =>
    showAccount && !accountError && !!account?.termsOfService;

  function getIndividualRequiredActions(
    persons: PersonShape[],
    accountEntityType: EntityType,
    requiredPersons?: RequiredVerificationPerson[]
  ): JSX.Element {
    const requiredActions = getIndividualsRequiredActions(
      persons,
      requiredPersons
    );
    return (
      <RequiredActions
        requiredActions={requiredActions}
        title={
          requiredActions.length > 0
            ? 'One or more individuals who fulfill the following roles are required for your account verification:'
            : 'You need to upload documents for all individuals who require verification below'
        }
        contactSupportLink='/contact'
        docsLink={getAccountVerificationFaqUrl(accountEntityType)}
        showButton={showModifyPersons && requiredActions.length > 0}
        buttonDisabled={persons.length >= 9}
        buttonText={persons.length > 0 ? 'Assign' : 'Add'}
        buttonLink={persons.length > 0 ? assignIndividualRoles : addIndividual}
      />
    );
  }

  return (
    <>
      <CardBlank>
        <SettingsItem border title='Account' subtitle=''>
          <CardBlank header={showTermsOfService(account)}>
            <SettingsItem
              inner
              title='Id'
              subtitle={
                <Copy
                  type='BUTTON_WITH_TEXT'
                  text={profile.accountId}
                  value={profile.accountId}
                ></Copy>
              }
            />
          </CardBlank>
          {showTermsOfService(account) && (
            <CardBlank>
              <SettingsItem
                inner
                title='Terms of service'
                subtitle={
                  <div>
                    Accepted on{' '}
                    {epochToDateString(
                      account!.termsOfService!.acceptance.when,
                      true,
                      true,
                      true
                    )}
                  </div>
                }
              />
            </CardBlank>
          )}
        </SettingsItem>
        {showAccount && isAccountLoading && !account && (
          <SettingsItem
            border
            title='Loading'
            subtitle={'......'}
          ></SettingsItem>
        )}
        {showAccount && !isAccountLoading && accountError && (
          <SettingsItem
            border
            title='Oops!'
            subtitle={accountError}
          ></SettingsItem>
        )}
        {showAccount && !accountError && account && (
          <>
            <SettingsItem
              border
              title={getEntityDetailsTitle(account.entityType)}
              subtitle={getEntityDetailsSubtitle(account.entityType)}
            >
              {showFieldRequiredActions(
                account.verification.requiredFields
              ) && (
                <RequiredActions
                  requiredActions={getFieldRequiredActions(
                    account.verification.requiredFields!
                  )}
                  title={getFieldRequiredActionsTitle(account.entityType)}
                  showButton={showModifyAccount}
                  buttonLink={editAccount(account.entityType)}
                />
              )}
              <CardBlank header>
                <>
                  <SettingsItem
                    inner
                    title={getEntityTitle(account)}
                    subtitle={getEntitySubtitle(account)}
                  >
                    {showModifyAccount && (
                      <Button
                        margin='0'
                        name='Edit'
                        click={editAccount(account.entityType)}
                        disabled={isPendingVerification(
                          account.verification.status
                        )}
                      />
                    )}
                  </SettingsItem>
                </>
              </CardBlank>
              <CardBlank>
                <>
                  <SettingsItem
                    inner
                    title={getAddressTitle(account.entityType)}
                    subtitle={
                      <>
                        <div>{getAddress(account)?.lineOne}</div>
                        <div>{getAddress(account)?.lineTwo}</div>
                        <div>{getAddress(account)?.city}</div>
                        <div>{getAddress(account)?.region}</div>
                        <div>{getAddress(account)?.postalCode}</div>
                        <div>{getAddress(account)?.country}</div>
                      </>
                    }
                  >
                    {showModifyAccount && (
                      <Button
                        margin='0'
                        name='Edit'
                        click={editAddress}
                        disabled={isPendingVerification(
                          account.verification.status
                        )}
                      />
                    )}
                  </SettingsItem>
                </>
              </CardBlank>
            </SettingsItem>
            {verificationEnabled() && (
              <SettingsItem
                border
                title={getDocumentsTitle(account.entityType)}
                subtitle={getDocumentsSubtitle(account.entityType)}
              >
                {showDocumentsRequiredActions(account) ? (
                  <RequiredActions
                    requiredActions={getDocumentsRequiredActions(account)}
                    title={getDocumentsRequiredActionsTitle(account)}
                    contactSupportLink='/contact'
                    docsLink={getAccountVerificationFaqUrl(account.entityType)}
                    showButton={showModifyAccount}
                    buttonText={
                      account.verification.requiredDocuments ? 'Add' : 'Edit'
                    }
                    buttonLink={editDocs}
                  />
                ) : (
                  <CardBlank>
                    <SettingsItem
                      inner
                      title={getDocumentsHeaderTitle(
                        account.verification.status
                      )}
                      subtitle=''
                    />
                  </CardBlank>
                )}
              </SettingsItem>
            )}
            {personsEnabled(account.entityType) && showPersons && (
              <>
                {isPersonsLoading && !personsData && (
                  <SettingsItem
                    border
                    title='Loading'
                    subtitle={'......'}
                  ></SettingsItem>
                )}
                {!isPersonsLoading && personsError && (
                  <SettingsItem
                    border
                    title='Oops!'
                    subtitle={personsError}
                  ></SettingsItem>
                )}
                {!personsError && personsData && account.verification.persons && (
                  <>
                    <SettingsItem
                      border
                      title='Individuals'
                      subtitle={getPersonsSubTitle()}
                    >
                      {account.verification.persons.status === 'Required' ? (
                        getIndividualRequiredActions(
                          personsData,
                          account.entityType,
                          account.verification.persons.required
                        )
                      ) : (
                        <CardBlank header>
                          <SettingsItem
                            border
                            inner
                            title={getPersonsHeaderTitleWhenNoActionsRequired(
                              account.verification.persons.status
                            )}
                            subtitle=''
                          >
                            {showModifyPersons &&
                              (account.verification.persons.status ===
                                'Verified' ||
                                account.verification.persons.status ===
                                  'NotRequired') && (
                                <Button
                                  margin='0'
                                  name='Add'
                                  click={addIndividual}
                                  disabled={personsData.length >= 9}
                                />
                              )}
                          </SettingsItem>
                        </CardBlank>
                      )}
                      {personsData.length > 0 && (
                        <CardBlank>
                          {personsData.map((person: PersonShape) => (
                            <IndividualItem
                              key={person.id}
                              showButton={showModifyPersons}
                              buttonText='Edit'
                              buttonClicked={(): void => {
                                editPerson(person);
                              }}
                              buttonDisabled={isPendingVerification(
                                person.verification.status
                              )}
                              name={personName(person)}
                              email={person.email}
                              status={person.verification.status}
                              roles={person.businessRoles}
                              documents={person.documents}
                              verificationErrors={person.verification.errors}
                            />
                          ))}
                        </CardBlank>
                      )}
                    </SettingsItem>
                  </>
                )}
              </>
            )}
            {verificationEnabled() && (
              <SettingsItem
                border
                title='Account Verification'
                subtitle={getAccountVerificationSubTitle(account.entityType)}
              >
                <CardBlank>
                  <>
                    <SettingsItem
                      inner
                      title={getAccountVerificationHeaderTitle(
                        account.entityType,
                        account.verification.status,
                        account.verification.persons?.status
                      )}
                      subtitle=''
                    >
                      {showSubmitVerification(account) && (
                        <>
                          <Button
                            margin='0'
                            name='Submit'
                            click={apiVerifyAccount}
                            disabled={
                              !submitVerificationEnabled(account.verification)
                            }
                            loading={isVerifyLoading}
                          />
                          {verifyError && <ServerError error={verifyError} />}
                        </>
                      )}
                    </SettingsItem>
                  </>
                </CardBlank>
              </SettingsItem>
            )}
          </>
        )}
      </CardBlank>
    </>
  );
};
export { Account };
