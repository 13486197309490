import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { Group } from '../../../components/forms/Group';
import { Button } from '../../../components/forms/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { StepPosition } from '../../../components/forms/StepPosition';
import StatusTabs from '../../../components/forms/StatusTabs';
import SelectTags from '../../../components/forms/SelectTags';
import { DocumentUpload } from '../../../components/forms/DocumentUpload';
import { PersonShape } from '../../../interfaces/personsState';
import { OptionShape } from '../../../interfaces/state';
import { AccountDocument } from '../../../interfaces/accountState';
import {
  getDocumentDataRequirements,
  getDocumentDataRequirementsTitle,
  getDocumentFileRequirements,
  getDocumentFileRequirementsTitle,
} from '../../../functions/accountHelpers';
import {
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { useEditPerson } from '../../../hooks/useEditPerson';
import {
  calculateCompletedDocumentCategories,
  calculateSelectedDocumentTypes,
  EditedDocument,
  editedDocumentToDocumentRequest,
  enableSubmitDocuments,
  getDocumentCategories,
  isEditedDocumentValid,
  updateEditedDocuments,
  getAvailableDocumentTypes,
  documentTypeRequiresBack,
  documentTypeRequiresCountry,
  getCategorySubTitle,
  getDocumentsPageSubTitle,
  getSelectedDocumentTypeLimit,
  onFirstOfMultipleTabs,
  onSubsequentTab,
  getInvalidDocumentError,
} from '../../../functions/documentHelpers';
import { Bullets } from '../../../components/layouts/Bullets';
import {
  businessRolesRequired,
  personName,
} from '../../../functions/personHelpers';
import { useDeletePerson } from '../../../hooks/useDeletePerson';

const EditPersonDocsRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { coBrandingState, profileState, personsState, accountState } =
    useGlobalDataState();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [invalidDocumentError, setInvalidDocumentError] = useState<string>();
  const [documentCategories, setDocumentCategories] = useState<string[]>([]);
  const [completedDocumentCategories, setCompletedDocumentCategories] =
    useState<string[]>([]);
  const [availableDocumentTypes, setAvailableDocumentTypes] = useState<
    OptionShape[]
  >([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>(
    []
  );
  const [editedDocuments, setEditedDocuments] = useState<EditedDocument[]>([]);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const {
    personData: editedData,
    isLoading: IsEditLoading,
    error: editError,
    apiEditPerson,
  } = useEditPerson();
  const {
    personData: deletedPersonData,
    isLoading: IsDeleteLoading,
    error: deleteError,
    apiDeletePerson,
  } = useDeletePerson();

  const person: PersonShape | undefined = personsState?.data?.selected;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !person ||
      !(person.verification.requiredDocuments || person.documents) ||
      person.verification.status === 'PendingVerification' ||
      !permissions.has('PersonsModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (person) {
      const newDocumentCategories = getDocumentCategories(
        person.verification.requiredDocuments,
        person.documents
      );
      setDocumentCategories(newDocumentCategories);
      setSelectedCategory(newDocumentCategories[0]);
    }
  }, [person]);

  useEffect(() => {
    if (selectedCategory) {
      setInvalidDocumentError(
        getInvalidDocumentError(selectedCategory, person?.documents)
      );
      const availableDocumentTypes =
        getAvailableDocumentTypes(selectedCategory);
      setAvailableDocumentTypes(availableDocumentTypes);
      setSelectedDocumentTypes(
        calculateSelectedDocumentTypes(
          availableDocumentTypes,
          editedDocuments,
          selectedCategory
        )
      );
    }
  }, [selectedCategory, editedDocuments, person]);

  useEffect(() => {
    if (editedData) {
      const morePersonsRequired = businessRolesRequired(
        accountState.data?.verification.persons?.required ?? [],
        editedData
      );
      if (!morePersonsRequired) {
        return history.push(returnTo);
      }
      history.push(
        constructFullPath(
          '/settings/individuals/assign-roles',
          isOnboarding,
          returnTo
        )
      );
    }
  }, [editedData]);

  useEffect(() => {
    deletedPersonData && history.push(returnTo);
  }, [deletedPersonData]);

  const handleFileUploaded = (editedDocument: EditedDocument): void => {
    setEditedDocuments(updateEditedDocuments(editedDocuments, editedDocument));
  };

  useEffect(() => {
    setBtnDisabled(
      !enableSubmitDocuments(
        editedDocuments,
        completedDocumentCategories,
        documentCategories,
        person?.verification.requiredDocuments
      )
    );
  }, [
    person,
    editedDocuments,
    completedDocumentCategories,
    documentCategories,
  ]);

  useEffect(() => {
    setCompletedDocumentCategories(
      calculateCompletedDocumentCategories(editedDocuments)
    );
  }, [editedDocuments]);

  const handleUpdate = async (): Promise<void> => {
    if (!person || editedDocuments.length === 0) {
      return;
    }

    apiEditPerson(person.id, {
      documents: editedDocuments.map((d: EditedDocument) =>
        editedDocumentToDocumentRequest(d)
      ),
    });
  };

  return (
    <LayoutIllustration
      maxWidth='50%'
      title={
        <>
          Upload documents for <span>{personName(person)}</span>
        </>
      }
      coBrandedTitle={
        <>
          Upload documents for {personName(person)} under{' '}
          <span>{coBrandingState?.data?.name}</span> account
        </>
      }
      subTitle={
        <>
          {getDocumentsPageSubTitle(
            person?.verification.requiredDocuments,
            person?.verification.errors
          )}
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <StepPosition steps={3} position={3} />
        <ServerError error={editError || deleteError} />
        <StatusTabs
          titleArray={documentCategories}
          completedTitleArray={completedDocumentCategories}
          setSelectedTab={setSelectedCategory}
          selectedTab={selectedCategory}
        />
        <Group name=''>
          <>
            <ServerError error={invalidDocumentError} />
            <div style={{ marginBottom: '24px', fontSize: '16px' }}>
              {getCategorySubTitle(
                selectedCategory,
                person?.verification.requiredDocuments
              )}
            </div>
            <SelectTags
              selectedArray={selectedDocumentTypes}
              optionsArray={availableDocumentTypes}
              selectedLimit={getSelectedDocumentTypeLimit(
                selectedCategory,
                person?.verification.requiredDocuments,
                person?.documents,
                editedDocuments
              )}
              setSelectedArray={setSelectedDocumentTypes}
              handleTagDeselected={(deselectedTagValue: any): void =>
                setEditedDocuments(
                  editedDocuments.filter((d) => d.type !== deselectedTagValue)
                )
              }
              multiSelect={false}
            />
            <Bullets
              title={getDocumentDataRequirementsTitle(selectedCategory)}
              bullets={getDocumentDataRequirements(
                selectedCategory,
                undefined,
                person
              )}
            />
            <Bullets
              type='Text'
              title={getDocumentFileRequirementsTitle(selectedCategory)}
              bullets={getDocumentFileRequirements(selectedCategory)}
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '12px',
              }}
            >
              {selectedDocumentTypes.map((type: string) => {
                const existingDocument = person?.documents?.find(
                  (d: AccountDocument) => d.type === type
                );
                const existingEdit = editedDocuments.find(
                  (d: EditedDocument) => d.type === type
                );
                const existingCountry =
                  existingEdit?.country ?? existingDocument?.country;
                const existingInvalidReason = existingEdit
                  ? undefined
                  : existingDocument?.invalidReason;
                return (
                  <DocumentUpload
                    key={type}
                    type={type}
                    isReadyToSubmit={isEditedDocumentValid(existingEdit)}
                    status={existingDocument?.status}
                    backRequired={documentTypeRequiresBack(type)}
                    countryRequired={documentTypeRequiresCountry(type)}
                    invalidReason={existingInvalidReason}
                    existingCountry={existingCountry}
                    onFrontUploaded={(
                      fileId: string,
                      type: string,
                      country?: string
                    ): void =>
                      handleFileUploaded({
                        type,
                        front: fileId,
                        back: undefined,
                        country,
                      })
                    }
                    onBackUploaded={(fileId: string, type: string): void =>
                      handleFileUploaded({
                        type,
                        front: undefined,
                        back: fileId,
                      })
                    }
                  />
                );
              })}
            </div>
          </>
        </Group>
        <div className='EditIndividualRoute--button-container'>
          <Button
            name={
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? 'Next'
                : 'Submit'
            }
            click={(): void => {
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? setSelectedCategory(documentCategories[1])
                : handleUpdate();
            }}
            loading={IsEditLoading}
            disabled={
              onFirstOfMultipleTabs(documentCategories, selectedCategory)
                ? !new Set(completedDocumentCategories).has(selectedCategory)
                : btnDisabled
            }
          />
          <Button
            name='Back'
            click={(): void => {
              if (onSubsequentTab(documentCategories, selectedCategory)) {
                setSelectedCategory(documentCategories[0]);
                return;
              }
              history.push(
                constructFullPath(
                  `/settings/individuals/edit-individual/${person?.id}/contact`,
                  isOnboarding,
                  returnTo
                )
              );
            }}
            color='GREY'
          />
          {(person?.verification.status === 'NotRequired' ||
            person?.verification.status === 'Required') && (
            <>
              <div className='EditIndividualRoute--delete'>
                {!deletedSelected && (
                  <span>
                    I want to remove {person.firstName}.
                    <a
                      onClick={(): any => {
                        setDeleteSelected(true);
                      }}
                    >
                      &nbsp;Delete {person.firstName}
                    </a>
                  </span>
                )}
                {deletedSelected && (
                  <div className='EditIndividualRoute--delete-button'>
                    <Button
                      name='Delete'
                      click={(): void => {
                        apiDeletePerson(person.id);
                      }}
                      color='RED'
                      loading={IsDeleteLoading && !IsEditLoading}
                      disabled={!deletedSelected}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { EditPersonDocsRoute };
