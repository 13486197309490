import { ReactElement } from 'react';

export interface PropsShape {
  text: string;
  percentage: number;
  align: 'left' | 'center' | 'right';
}

const Progress = ({ text, percentage, align }: PropsShape): ReactElement => {
  return (
    <div className='Progress'>
      <div className='Progress--background'>
        <div
          className={
            percentage >= 90
              ? 'Progress--percent Progress--percent-alert'
              : 'Progress--percent'
          }
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className='Progress--text' style={{ textAlign: `${align}` }}>
        {text}
      </div>
    </div>
  );
};

export { Progress };
