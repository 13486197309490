import React from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { SendInvite } from '../components/layouts/SendInvite';

const InviteRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <SendInvite />
    </Layout>
  );
};

export { InviteRoute };
