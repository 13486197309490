function getCvvResponseCodeDescription(code?: string): string {
  switch (code) {
    case 'M':
    case 'Y':
      return 'Match';
    case 'N':
      return 'No Match';
    case 'S':
      return 'Should be on card';
    case 'P':
      return 'Not processed';
    case 'U':
      return 'Issuer does not participate';
    default:
      return 'Unknown - contact us for assistance';
  }
}

export { getCvvResponseCodeDescription };
