import { ReactElement, useEffect } from 'react';
import { SignIn } from '../components/onboarding/SignIn';
import { LayoutIllustration } from '../layout-illustration';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import signIn from '../images/illustrations/sign-in.png';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';

const SignInRoute = (): ReactElement => {
  const { coBrandingState } = useGlobalDataState();
  const { isSignedIn, cognito } = useGlobalState();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const isAuthLink = queryParams && queryParams.atl;

  useEffect(() => {
    if (isSignedIn) {
      cognito.signOut();
    }
  }, []); // isSignedIn should not be a dependency here, as we only want to do this on page load

  return (
    <>
      <LayoutIllustration
        image={signIn}
        title={
          <>
            {isAuthLink ? (
              'Sign In'
            ) : (
              <>
                Delivering a <span>compliant</span> payment system in an
                entirely new way.
              </>
            )}
          </>
        }
        subTitle={
          <>
            {isAuthLink ? (
              <>
                Sign in to your <span>Ryft</span> account with{' '}
                <a
                  className='SignIn--link'
                  target='_blank'
                  href={coBrandingState.data?.websiteUrl}
                >
                  {coBrandingState.data?.name}
                </a>{' '}
              </>
            ) : coBrandingState.data ? (
              <>
                Sign in to your <span>Ryft</span> account
              </>
            ) : (
              <>
                ryft unlocks a new level of <span>payments experience</span>{' '}
                when working with sub accounts thanks to its intuitive API's and
                SDK's
              </>
            )}
          </>
        }
      >
        <SignIn />
      </LayoutIllustration>
    </>
  );
};

export { SignInRoute };
