import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { LastOne } from '../components/onboarding/Last';
import Auth from '@aws-amplify/auth';
import yourAddress from '../images/illustrations/your-address.png';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';

const LastRoute = (): React.ReactElement => {
  const accountType = localStorage.getItem('accountType');
  const history = useHistory();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    async function checkUser(): Promise<void> {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        Sentry.captureException(err);
        history.push('/');
      }
    }
    if (isSignedIn) {
      history.push('/');
    } else {
      checkUser();
    }
  }, []);

  return (
    <LayoutIllustration
      image={yourAddress}
      title={
        <>
          Create your <span>Ryft</span> account
        </>
      }
      subTitle={
        <>
          Whats your{' '}
          <span>
            {accountType === 'Individual' ? 'home' : 'registered business'}
          </span>{' '}
          address?
        </>
      }
    >
      <LastOne />
    </LayoutIllustration>
  );
};

export { LastRoute };
