import { ReactElement, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  value: string;
  type?: 'BUTTON' | 'BUTTON_WITH_TEXT';
  text?: string;
  textStyle?: 'NORMAL' | 'BOLD';
  buttonPosition?: 'LEFT' | 'RIGHT';
  buttonSize?: 'SMALL' | 'MEDIUM';
  direction?: 'UP' | 'DOWN';
  customTextClass?: string;
}

const Copy = ({
  value,
  type = 'BUTTON',
  text,
  textStyle = 'NORMAL',
  buttonPosition = 'LEFT',
  buttonSize = 'SMALL',
  direction = 'UP',
  customTextClass,
}: PropsShape): ReactElement => {
  const copyNotification = useRef(null);
  const [isCopyNotificationVisible, setIsCopyNotificationVisible] =
    useState<boolean>(false);

  const doCopy = (): void => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value);
    }
    setIsCopyNotificationVisible(true);
    setTimeout(function () {
      setIsCopyNotificationVisible(false);
    }, 400);
  };
  return (
    <div className='Copy' onClick={doCopy}>
      {type === 'BUTTON' ? (
        <div className='Copy--button' />
      ) : (
        <div
          className={`Copy--container-with-button-${buttonSize?.toLowerCase()}-${buttonPosition?.toLowerCase()}`}
        >
          {buttonPosition === 'LEFT' ? (
            <div
              className={`Copy--button-${buttonSize?.toLowerCase()}-${buttonPosition?.toLowerCase()}`}
            />
          ) : undefined}
          <div
            className={`Copy--text-${textStyle?.toLowerCase()} ${customTextClass}`}
          >
            {text ? text : value}
          </div>
          {buttonPosition === 'RIGHT' ? (
            <div
              className={`Copy--button-${buttonSize?.toLowerCase()}-${buttonPosition?.toLowerCase()}`}
            />
          ) : undefined}
        </div>
      )}
      <CSSTransition
        nodeRef={copyNotification}
        in={isCopyNotificationVisible}
        timeout={400}
        classNames={`Copy--NotificationTransition`}
        unmountOnExit
      >
        <div
          className={`Copy--notification${direction}`}
          ref={copyNotification}
        >
          <div className={`Copy--notification${direction}-title`}>
            Copied to clipboard
          </div>
          <div className={`Copy--notification${direction}-divider`} />
          <div className={`Copy--notification${direction}-value`}>{value}</div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { Copy };
