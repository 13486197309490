import { CardScheme, Currency } from '../interfaces/state';
import visaIcon from '../images/visa.svg';
import mastercardIcon from '../images/mastercard.svg';
import amexIcon from '../images/americanexpress.svg';
import discoverIcon from '../images/discover.svg';
import unknownIcon from '../images/unknown.svg';

const isMobile = (): boolean => {
  return (
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <
      900 || (false as boolean)
  );
};

interface FormatPriceShape {
  pence: number;
  currency: Currency;
}

const formatPrice = ({ pence, currency }: FormatPriceShape): string => {
  const naMessage = `N/A ${currency.code}`;
  if (currency) {
    try {
      const calcPrice = (pence / Math.pow(10, currency.minorDigits)).toFixed(
        currency.minorDigits
      );
      const res = calcPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',') as string;
      return isNaN(+calcPrice) ? naMessage : `${res} ${currency.code}`;
    } catch {
      return naMessage;
    }
  }
  return 'N/A';
};

const constructFullPath = (
  path: string,
  includeOnboardingParam?: boolean,
  returnTo?: string
): string => {
  let full = path;
  if (returnTo) {
    full += `?returnTo=${encodeURIComponent(returnTo)}`;
  }
  if (includeOnboardingParam) {
    full += `${returnTo ? '&' : '?'}onboarding=true`;
  }
  return full;
};

const epochSecondsToLocalDate = (epoch: number): Date => {
  const d = new Date(0);
  d.setUTCSeconds(epoch);
  return d;
};

const epochSecondsToDate = (epoch: number): Date => {
  const date = epochSecondsToLocalDate(epoch);
  const offsetInSeconds = date.getTimezoneOffset() * 60;
  if (!offsetInSeconds) {
    return date;
  }
  let offsetEpoch;
  if (offsetInSeconds < 0) {
    offsetEpoch = epoch - Math.abs(offsetInSeconds);
  } else {
    offsetEpoch = epoch + offsetInSeconds;
  }
  return epochSecondsToLocalDate(offsetEpoch);
};

const dateToEpochSeconds = (
  date: Date,
  applyOffsetAdjustment: boolean
): number => {
  const seconds = Math.trunc(date.getTime() / 1000);
  if (!applyOffsetAdjustment) {
    return seconds;
  }
  const offsetInSeconds = date.getTimezoneOffset() * 60;
  if (offsetInSeconds < 0) {
    return seconds + Math.abs(offsetInSeconds);
  }
  if (offsetInSeconds > 0) {
    return seconds - offsetInSeconds;
  }
  return seconds;
};

const secondsInDay = (): number => 86400;

const dateToStartOfDayEpochSeconds = (date: Date): number => {
  const epochSeconds = dateToEpochSeconds(date, true);
  return Math.floor(epochSeconds / secondsInDay()) * secondsInDay();
};

const dateToEndOfDayEpochSeconds = (date: Date): number => {
  return dateToStartOfDayEpochSeconds(date) + secondsInDay() - 1;
};

const locale = (): string => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.language || 'en-gb';
  }
};

const cardExpiryAsDate = (expiryMonth: string, expiryYear: string): Date => {
  return new Date(parseInt(expiryYear), parseInt(expiryMonth), 0, 23, 59, 59);
};

const epochToDateString = (
  epoch: number,
  showTime = false,
  showDay = true,
  alwaysShowYear = false,
  locale = undefined
): string => {
  const addZeroBefore = (n: number): string => {
    return (n < 10 ? '0' : '') + n;
  };

  const value = new Date(epoch * 1000);

  const month = value?.toLocaleDateString(locale, {
    month: 'short',
  });
  const day = value?.toLocaleDateString(locale, {
    day: 'numeric',
  });
  const isCurrentYear =
    `${new Date().getFullYear()}` ===
    value?.toLocaleDateString(locale, { year: 'numeric' });
  const year =
    alwaysShowYear || !isCurrentYear
      ? `${value?.toLocaleDateString(locale, { year: 'numeric' })}`
      : '';

  let dateString = `${month}${showDay ? ` ${day}` : ''}${
    showDay && year ? ',' : ''
  }${year ? ` ${year}` : ''}`;

  if (showTime) {
    dateString += ` - ${addZeroBefore(value?.getHours())}:${addZeroBefore(
      value?.getMinutes()
    )}`;
  }

  return dateString;
};

const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const truncateWord = (
  s: string,
  n: number = 10,
  charsToShow: number = 8
): string => {
  if (s.length <= n) {
    return s;
  }
  const separator = '...';
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return s.substr(0, frontChars) + separator + s.substr(s.length - backChars);
};

const asteriskWord = (word?: string): string => {
  if (!word) {
    return '';
  }
  const wordLength = word.length;
  const asterisks = '*'.repeat(wordLength - 6);
  const firstSix = word.substring(0, 6);
  return `${firstSix}${asterisks}`;
};

const monthFromDate = (date: Date): string => {
  const monthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[+String(date.getMonth()).padStart(2, '0')];
};
const dateFromDate = (date: Date): number =>
  +String(date.getDate()).padStart(2, '0');

const dateFromYYYYMMDDString = (value: string): Date => {
  const parts = value.split('-');
  return new Date(
    parseInt(parts[0]),
    parseInt(parts[1]) - 1,
    parseInt(parts[2])
  );
};

const timeFromDate = (date: Date): string =>
  `${String(date.getHours()).padStart(2, '0')}:${String(
    date.getMinutes()
  ).padStart(2, '0')}`;

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${day} ${month} ${year} at ${hour}:${minute}`;
};

const arraysAllEqual = (array1?: any[], array2?: any[]): boolean => {
  const array1Defaulted = array1 ?? [];
  const array2Defaulted = array2 ?? [];
  const set1 = new Set(array1Defaulted);
  const set2 = new Set(array2Defaulted);
  return set1.size == set2.size && array1Defaulted.every((x) => set2.has(x));
};

const camelCaseToWords = (s: string): string => {
  const result = s.replace(/([A-Z])/g, ' $1').trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

function toCamelCase(str: string): string {
  return str[0].toLowerCase() + str.slice(1);
}

const pascalCaseToWords = (s: string): string =>
  s.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').trim();

const sum = (numbers?: number[]): number =>
  numbers?.reduce((a, b) => a + b, 0) ?? 0;

const distinctBy = (array: any[] | undefined, keySelector: any): any[] => {
  if (!array) {
    return [];
  }
  return array.reduce((result, i) => {
    const index = result.findIndex(
      (j: any) => keySelector(j) === keySelector(i)
    );
    if (index === -1) {
      result.push(i);
    }
    return result;
  }, []);
};

const handleNotSignedIn = (history: any): void => {
  const currentPath = window.location.pathname;
  const returnTo =
    currentPath !== '/'
      ? `?returnTo=${currentPath + window.location.search}`
      : '';
  history.push(`/signin${returnTo}`);
};

const getCardSchemeIcon = (scheme: CardScheme): any => {
  switch (scheme) {
    case 'Visa':
      return visaIcon;
    case 'Mastercard':
      return mastercardIcon;
    case 'Amex':
      return amexIcon;
    case 'Discover':
      return discoverIcon;
    default:
      return unknownIcon;
  }
};

const createMaskedPan = (scheme: CardScheme, last4: string): string =>
  scheme === 'Amex' ? `**** ****** *${last4}` : `**** **** **** ${last4}`;

export {
  timeFromDate,
  dateFromDate,
  dateFromYYYYMMDDString,
  monthFromDate,
  isMobile,
  formatPrice,
  epochSecondsToDate,
  dateToStartOfDayEpochSeconds,
  dateToEndOfDayEpochSeconds,
  truncateWord,
  asteriskWord,
  formatBytes,
  locale,
  cardExpiryAsDate,
  epochToDateString,
  arraysAllEqual,
  camelCaseToWords,
  toCamelCase,
  pascalCaseToWords,
  sum,
  distinctBy,
  handleNotSignedIn,
  formatDate,
  getCardSchemeIcon,
  createMaskedPan,
  constructFullPath,
};
