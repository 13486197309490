import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { isEmail, isPassword } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { get } from '../../functions/callApi';
import { Password } from '../forms/Password';
import { useHistory } from 'react-router-dom';
import { Profile, profileStateShape } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermissionDetail } from '../../interfaces/userState';

const SignIn = (): ReactElement => {
  const { setProfileState, coBrandingState } = useGlobalDataState();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const { cognito, setIsSignedIn, cognitoUserNotConfirmedOnSignIn } =
    useGlobalState();
  const authLinkId = queryParams?.atl;
  const emailFromStorage = localStorage.getItem('email');
  const [email, setEmail] = useState<string>(emailFromStorage || '');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (email && password && !emailError && !passwordError) {
      setBtnDisabled(false);
    }
    if (email.length === 0) setEmailError('');
    if (password.length === 0) setPasswordError('');
    if (!email || !password) setBtnDisabled(true);
  }, [email, password]);

  const signIn = async (): Promise<void> => {
    setEmailError('');
    setPasswordError('');
    setServerError('');
    setIsLoading(true);
    let valid: boolean = true;
    if (!(await isEmail(email.trim()))) {
      setEmailError('Invalid Email');
      valid = false;
      setIsLoading(false);
    }
    if (!(await isPassword(password))) {
      setPasswordError('Invalid Password');
      valid = false;
      setIsLoading(false);
    }
    if (valid) {
      const metadata = authLinkId
        ? {
            authLink: authLinkId,
          }
        : undefined;
      const signInResponse = await cognito.signIn(
        email.trim(),
        password,
        metadata
      );
      if (!signInResponse) {
        return;
      }
      const profileResponse = await get(
        '/v1/profile',
        'PATCH',
        JSON.stringify({ loggedIn: true })
      );
      setIsLoading(false);
      if (!profileResponse || profileResponse.status !== 200) {
        setServerError('oops - an unexpected error occurred, please try again');
        return;
      }
      localStorage.setItem('linkRedirectUrl', queryParams?.r || '');
      const profile: Profile = profileResponse;
      if (!profile.account.isOnboarded) {
        history.push('/almost');
        return;
      }
      if (
        !profile.account.hasAcceptedTermsOfService &&
        profile.user.permissions.some(
          (p: UserPermissionDetail) => p.permission === 'AccountModify'
        )
      ) {
        let url = '/terms-updated';
        if (authLinkId) {
          url += `?authLink=true`;
        }
        history.push(url);
        return;
      }
      setProfileState((prevState: profileStateShape) => ({
        ...prevState,
        data: profile,
      }));
      setIsSignedIn(true);
      const returnTo = queryParams?.returnTo;
      history.push(authLinkId ? '/authorized' : returnTo ? returnTo : '/');
    }
  };

  const handleUserNotConfirmed = async (): Promise<void> => {
    const trimmedEmail = email.trim();
    localStorage.setItem('email', trimmedEmail);
    const response = await cognito.resend(trimmedEmail);
    switch (response?.CodeDeliveryDetails?.AttributeName) {
      case 'phone_number':
        history.push('/invite/confirm');
        break;
      case 'email':
        history.push('/confirm');
        break;
    }
  };

  useEffect(() => {
    setServerError(cognito.error);
    if (cognito.error) {
      setIsLoading(false);
    }
  }, [cognito.error]);

  useEffect(() => {
    if (cognitoUserNotConfirmedOnSignIn) {
      setIsLoading(false);
      handleUserNotConfirmed();
    }
  }, [cognitoUserNotConfirmedOnSignIn]);

  useEffect(() => {
    const accountType = localStorage.getItem('accountType');
    const localEmail = localStorage.getItem('email');
    const isSignedIn = localStorage.getItem('isSignedIn');
    localStorage.setItem('accountType', accountType || '');
    localStorage.setItem('isSignedIn', isSignedIn || '');
    if (localEmail) localStorage.setItem('email', localEmail || '');
  }, []);
  return (
    <div className='SignIn'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          signIn();
        }}
      >
        <TextInput
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          placeHolder='Email address'
          focus
        />
        <Password
          name='Password'
          value={password}
          setValue={setPassword}
          error={passwordError}
          placeHolder='Password'
        />
        <Button
          name='Sign In'
          click={signIn}
          loading={isLoading}
          disabled={btnDisabled}
        />
        {authLinkId && (
          <p className='SignIn--permission'>
            By proceeding you are granting permission to connect your Ryft
            account to {coBrandingState.data?.name}.
          </p>
        )}
      </form>
      <div
        onClick={(): void => history.push('/forgot')}
        className='SignIn--center'
      >
        <a>Forgot your password?</a>
      </div>
    </div>
  );
};
export { SignIn };
