import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

export interface PropsShape {
  links: {
    heading: string;
    links: { value: string; name: string }[];
  }[];
  social?: {
    facebook?: string;
    twitter?: number;
    instragram?: string;
    linkedin?: string;
  };
  apps?: {
    appstore?: string;
    googleplay?: number;
  };
  email: string;
  setEmail: () => {};
  copyright?: string;
}

const Footer = ({
  links,
  // social,
  // apps,
  // email,
  // setEmail,
  copyright,
}: PropsShape): ReactElement => {
  const history = useHistory();

  const goTo = (v: any): any => {
    history.push(v);
  };
  return (
    <div className='Footer'>
      <div className='Footer--center'>
        <div className='Footer--top'>
          {links.map((section: any, index: number) => {
            return (
              <div key={`link-${index}`}>
                <div className='Footer--link-title'>{section.heading}</div>
                {section.links.map((link: any, index: number) => {
                  return (
                    <div
                      className='Footer--link-item'
                      key={`link-${index}`}
                      onClick={(): void => goTo(link.value)}
                    >
                      {link.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div></div>
          <div className='Footer--link-title'>
            {/* News & Updates */}
            <div className='Footer--updates'>
              {/* Keep up to date with all the latest news at Ryft{' '} */}
            </div>
            <div className='Footer--form'>
              {/* <div className='Footer--form-input'>
                <input
                  type='text'
                  value={email}
                  onChange={(): any => setEmail()}
                  placeholder='Email address'
                />
              </div>
              <div>
                <button className='Footer--form-buttons'>Sign Up</button>
              </div> */}
            </div>
          </div>
        </div>
        <div className='Footer--bottom'>
          <div className='Footer--bottom-copyright'>{copyright}</div>
          {/* <div>{JSON.stringify(social)}</div> */}
          {/* <div>{JSON.stringify(apps)}</div> */}
        </div>
      </div>
    </div>
  );
};
export { Footer };
