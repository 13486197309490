import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { SubAccounts } from '../components/dataGrids/SubAccounts';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { FilterSubAccounts } from '../components/dataGrids/FilterSubAccounts';
import { SideMenu } from '../components/layouts/SideMenu';
import { Button } from '../components/forms/Button';
import { useGetSubAccounts } from '../hooks/useGetSubAccounts';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';
import { useHistory } from 'react-router';

const SubAccountsRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountsState, profileState } = useGlobalDataState();
  const [isLoading, error, getSubAccounts, isMore, getMore] =
    useGetSubAccounts();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !profileState?.data?.account.settings.subAccountsDisplayed ||
      !permissions.has('SubAccountsView')
    ) {
      return history.push('/');
    }
    getSubAccounts();
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='LayoutMain'>
        <div className='LayoutMain--grid'>
          <div className='LayoutMain--mobile-filter'>
            <Button
              name='Filter'
              color='GREY'
              click={handleMobileFilter}
              margin='0px'
            />
          </div>
          <div className='LayoutMain--filter'>
            <FilterSubAccounts getSubAccounts={getSubAccounts} />
          </div>
          <div className='LayoutMain--data'>
            <SubAccounts
              subAccounts={accountsState.data?.items ?? []}
              isMore={isMore}
              getMore={getMore}
              isLoading={isLoading}
              error={error}
            />
          </div>
          <SideMenu
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            position='LEFT'
            width='SUPER'
          >
            <div style={{ padding: '12px' }}>
              <FilterSubAccounts getSubAccounts={getSubAccounts} />
            </div>
          </SideMenu>
        </div>
      </div>
    </Layout>
  );
};

export { SubAccountsRoute };
