import Select, { MultiValue, SingleValue, StylesConfig } from 'react-select';
import React, { FC, useEffect, useRef, useState } from 'react';
import { OptionShape } from '../../interfaces/state';

interface SelectTagsProps {
  name?: string;
  description?: string;
  selectedArray: any[];
  setSelectedArray: (selected: any[]) => void;
  selectedLimit?: number;
  optionsArray: OptionShape[];
  removeOnSelect?: boolean;
  optional?: boolean;
  clearable?: boolean;
  allowBackspace?: boolean;
  error?: string;
  handleTagDeselected?: (deselectedTagValue: any) => void;
  multiSelect?: boolean;
}

interface ReactSelectOption {
  value: string;
  label: string;
}

const SelectTags: FC<SelectTagsProps> = ({
  name,
  description,
  selectedArray,
  setSelectedArray,
  selectedLimit,
  optionsArray,
  optional = false,
  clearable = false,
  allowBackspace = true,
  error,
  multiSelect = true,
}) => {
  const defaultSelectLimit = Number.MAX_SAFE_INTEGER;
  const [options, setOptions] = useState<readonly ReactSelectOption[]>([]);
  const [limitReached, setLimitReached] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatedOptions = optionsArray.map((option) => ({
      value: option.value,
      label: option.name,
    }));
    setOptions(updatedOptions);
  }, [optionsArray]);

  useEffect(() => {
    setLimitReached(
      selectedArray.length >= (selectedLimit ?? defaultSelectLimit)
    );
  }, [selectedLimit, selectedArray]);

  const handleSelectChange = (
    newValue: MultiValue<ReactSelectOption> | SingleValue<ReactSelectOption>
  ): void => {
    if (Array.isArray(newValue)) {
      let newMultiValue = newValue as MultiValue<ReactSelectOption>;
      if (newMultiValue.length > (selectedLimit ?? defaultSelectLimit)) {
        newMultiValue = newMultiValue.slice(1);
      }
      const updated = newMultiValue.map((o) => o.value);
      setSelectedArray(updated);
      return;
    }
    const newSingleValue = newValue as SingleValue<ReactSelectOption>;
    const updated = [newSingleValue!.value];
    setSelectedArray(updated);
    return;
  };

  const styles: StylesConfig<ReactSelectOption, true> = {
    multiValue: (base) => {
      return {
        ...base,
        backgroundImage: base.backgroundImage,
        backgroundColor: '#e4e4e5',
        border: '1px solid #cdcdce',
        fontSize: '14px',
      };
    },
    multiValueRemove: (base) => {
      return base;
    },
    control: (baseStyles, state): any => ({
      ...baseStyles,
      backgroundColor: state.menuIsOpen ? 'white' : '#ebeced',
      minHeight: '45px',
    }),
  };

  return (
    <div className='SelectTags' ref={containerRef}>
      <div className='SelectTags--margin'>
        {optional && <div className='SelectTags--optional'>Optional</div>}
        {error && <div className='SelectTags--error'>{error}</div>}
        {name && <label>{name}</label>}
        {options.length > 0 && (
          <Select
            isDisabled={false}
            isClearable={clearable}
            styles={styles}
            value={options!.filter((o) => selectedArray.includes(o.value))}
            backspaceRemovesValue={allowBackspace}
            options={options}
            isMulti={multiSelect ? true : undefined}
            closeMenuOnSelect={limitReached || !multiSelect}
            onChange={handleSelectChange}
          />
        )}
        {description && (
          <div className='SelectTags--description'>{description}</div>
        )}
      </div>
    </div>
  );
};

export default SelectTags;
