import { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { Button } from '../../../components/forms/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Params } from '@sentry/react/types/types';
import { PaymentsShape } from '../../../interfaces/paymentState';
import { handleNotSignedIn } from '../../../functions/helpers';
import { StepPosition } from '../../../components/forms/StepPosition';
import { SuccessMessage } from '../../../components/forms/SuccessMessage';
import { useVoidPaymentSession } from '../../../hooks/useVoidPaymentSession';
import {
  canBeVoided,
  voidsAndCapturesSupported,
} from '../../../functions/paymentHelpers';

const VoidPaymentRoute = (): ReactElement => {
  const { paymentsState, profileState } = useGlobalDataState();
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const [payment, setPayment] = useState<PaymentsShape>();
  const voidPaymentApi = useVoidPaymentSession();

  const params: Params = useParams();
  const idFromUrl = params?.paymentId;

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('VoidsModify')) {
      history.push('/payments');
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (paymentsState?.data?.items && profile.group) {
      const payment = paymentsState?.data?.items.find(
        (item) => item.id === idFromUrl
      );
      setPayment(payment);
      const paymentCanBeVoided = canBeVoided(payment);
      const voidsCurrentlySupported = voidsAndCapturesSupported(
        payment,
        profile
      );
      const canVoid = paymentCanBeVoided && voidsCurrentlySupported;
      if (!canVoid) {
        history.push(`/payments?id=${payment?.id}`);
      }
    }
  }, [paymentsState, profile]);

  const handleVoid = async (): Promise<void> => {
    if (!payment) {
      return;
    }
    await voidPaymentApi.request(payment.id);
  };

  const handleCancelClicked = (): void => {
    if (voidPaymentApi.isLoading) {
      return;
    }
    history.push('/payments');
  };

  const getStepPosition = (): number => (voidPaymentApi.data ? 2 : 1);

  return (
    <LayoutIllustration
      title={<>Void a payment</>}
      coBrandedTitle={<>Void a payment</>}
      subTitle={<>Cancel a transaction before the funds have been cleared</>}
    >
      <div style={{ padding: '0 12px' }}>
        <div
          style={{
            marginBottom: '25px',
          }}
        >
          Remember that once voided, all funds will be returned to the
          cardholder. This cannot be undone!
        </div>
        <StepPosition steps={2} position={getStepPosition()} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '12px',
          }}
        >
          {voidPaymentApi.data && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <SuccessMessage
                  message={`Void Processed - we've processed your request, the payment will be updated shortly.`}
                />
              </div>
            </>
          )}
          {!voidPaymentApi.data && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                {voidPaymentApi.error && (
                  <div style={{ color: '#ec3f3f', position: 'relative' }}>
                    {voidPaymentApi.error}
                  </div>
                )}
                <Button
                  name='Void Payment'
                  click={handleVoid}
                  loading={voidPaymentApi.isLoading}
                />
              </div>
            </>
          )}
        </div>
        <Button
          name={voidPaymentApi.data ? 'Back to Payment' : 'Cancel'}
          color='GREY'
          click={(): void => history.push(`/payments?id=${idFromUrl}`)}
        />
        <Button
          name={'View Payments'}
          color='GREY'
          click={handleCancelClicked}
        />
      </div>
    </LayoutIllustration>
  );
};

export { VoidPaymentRoute };
