import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { Button } from '../forms/Button';
import { OneTimeCode } from '../forms/OneTimeCode';
import { ServerError } from '../forms/ServerError';
import { useGlobalState } from '../../hooks/useGlobalState';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';
import { get } from '../../functions/callApi';
import { Profile, profileStateShape } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';

const ConfirmUser = (): ReactElement => {
  const history = useHistory();
  const {
    cognito,
    cognitoUser,
    setIsSignedIn,
    cognitoUserNotConfirmedOnSignIn,
    setCognitoUserNotConfirmedOnSignIn,
  } = useGlobalState();
  const { setProfileState } = useGlobalDataState();
  const [oneTimeCode, setOneTimeCode] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const username = localStorage.getItem('email');

  const confirmUser = async (): Promise<void> => {
    setIsLoading(true);
    if (oneTimeCode.length !== 6) {
      setIsLoading(false);
      return;
    }
    const confirmed = await cognito.confirm(username, oneTimeCode);
    if (confirmed === 'SUCCESS') {
      setIsConfirmed(true);
    }
  };

  const resend = async (): Promise<void> => {
    setServerError('');
    setIsSending(true);
    await cognito.resend(username);

    setTimeout(() => {
      setIsSending(false);
    }, 2500);
  };

  const handleConfirmedAfterAutoSignIn = async (): Promise<void> => {
    const profileResponse = await get(
      '/v1/profile',
      'PATCH',
      JSON.stringify({ loggedIn: true })
    );
    setIsLoading(false);
    if (!profileResponse || profileResponse.status !== 200) {
      history.push('/');
      return;
    }
    const profile: Profile = profileResponse;
    setProfileState((prevState: profileStateShape) => ({
      ...prevState,
      data: profile,
    }));
    setIsSignedIn(true);
    history.push('/');
  };

  useEffect(() => {
    // Wait for autoSignIn before redirecting - it sets cognitoUser
    if (isConfirmed && cognitoUser) {
      handleConfirmedAfterAutoSignIn();
    }
    // If the user signed in and was not confirmed, we either get
    // autoSignIn_failure events or nothing at all so we need them to sign in again
    if (isConfirmed && cognitoUserNotConfirmedOnSignIn) {
      setIsLoading(false);
      // reset this before redirecting as otherwise we trigger resendSignUp() again on that page
      setCognitoUserNotConfirmedOnSignIn(false);
      history.push('/signin');
    }
  }, [isConfirmed, cognitoUser, cognitoUserNotConfirmedOnSignIn]);

  useEffect(() => {
    if (oneTimeCode && oneTimeCode.length === 6) {
      setBtnDisabled(false);
    }
    if (!oneTimeCode || oneTimeCode.length !== 6) {
      setBtnDisabled(true);
    }
  }, [oneTimeCode]);

  useEffect(() => {
    setIsLoading(false);
    setServerError(cognito.error);
  }, [cognito.error]);

  return (
    <div className='Join'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          confirmUser();
        }}
      >
        <StepPosition steps={2} position={2} />
        <OneTimeCode
          name='Phone Number'
          value={oneTimeCode}
          setValue={setOneTimeCode}
        />

        <Button
          name='Confirm Code'
          type={'submit'}
          loading={isLoading}
          disabled={btnDisabled}
        />
        <Button
          name='Resend Code'
          click={resend}
          loading={isSending}
          color='GREY'
        />
      </form>
      <div className='Join--small'>
        By joining you are agreeing to our{' '}
        <a onClick={(): void => history.push(`/about/terms`)}>Terms</a> and{' '}
        <a onClick={(): void => history.push(`/about/privacy`)}>
          Privacy Policy
        </a>
      </div>
      <div
        onClick={(): void => history.push(`/signin`)}
        className='Join--center'
      >
        Already have an account? <a>Sign in</a>
      </div>
    </div>
  );
};
export { ConfirmUser };
