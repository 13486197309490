import { ReactElement } from 'react';

export interface PropsShape {
  title?: string;
  topRight?: ReactElement;
  bottomRight?: ReactElement;
  bottomLeft?: ReactElement;
  children?: ReactElement;
  minHeight?: string;
}

const CardCorners = ({
  title,
  topRight,
  bottomRight,
  bottomLeft,
  children,
  minHeight = '350px',
}: PropsShape): ReactElement => {
  return (
    <>
      <div className='CardCorners'>
        <div
          className={title ? 'CardCorners--header' : 'CardCorners--header-none'}
        >
          <div className='CardCorners--header-title'>{title}</div>
          <div>{topRight}</div>
        </div>
        <div className='CardCorners--body' style={{ minHeight }}>
          {children}
        </div>

        <div className={bottomLeft || bottomRight ? 'CardCorners--footer' : ''}>
          <div>{bottomLeft}</div>
          <div>{bottomRight}</div>
        </div>
      </div>
    </>
  );
};
export { CardCorners };
