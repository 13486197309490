import { ReactElement, Dispatch, SetStateAction } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

export interface PropsShape {
  label?: boolean;
  name: string;
  value: any; // external type - E164Number
  setValue?: Dispatch<SetStateAction<any>>;
  error?: string;
  description?: string;
  info?: string;
  optional?: boolean;
  placeholder?: string;
  defaultCountry?: any;
  international?: boolean;
}

const PhoneNumber = ({
  label = false,
  name,
  value,
  setValue,
  error,
  description,
  info,
  optional = false,
  placeholder = 'Enter phone number',
  defaultCountry = 'GB',
  international = false,
}: PropsShape): ReactElement => {
  const handleChange = (phoneValue?: string): any => {
    if (setValue && phoneValue) {
      const valid = isValidPhoneNumber(phoneValue as string) || false;
      const number = phoneValue;
      setValue({ number, valid });
    }
    if (setValue && optional) {
      const valid =
        (phoneValue && isValidPhoneNumber(phoneValue as string)) || false;
      const number = phoneValue;
      setValue({ number, valid });
    }
  };

  return (
    <div className='PhoneNumber'>
      {info && <>{info}</>}
      {error && <div className='TextInput--error'>{error}</div>}
      {optional && <div className='TextInput--optional'>Optional</div>}
      {label && (
        <label className='PhoneNumber--label' htmlFor={name}>
          {name}
        </label>
      )}
      <PhoneInput
        international={international}
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        style={{ height: '50px' }}
      />
      {description && (
        <small className='TextInput--description'>{description}</small>
      )}
    </div>
  );
};

export { PhoneNumber };
