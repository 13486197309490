import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { FilterPayments } from '../components/dataGrids/FilterPayments';
import { useGetTransactions } from '../hooks/useGetTransactions';
import { TransactionsDash } from '../components/dataGrids/TransactionsDash';
import { Payments } from '../components/dataGrids/Payments';
import { SideMenu } from '../components/layouts/SideMenu';
import { Button } from '../components/forms/Button';
import { UserPermission } from '../interfaces/profileState';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const PaymentsRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState, paymentsState } = useGlobalDataState();
  const [isLoading, paymentsError, , setFilter, isMorePayments, , getMore] =
    useGetTransactions();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [canModifyPayments, setCanModifyPayments] = useState<boolean>(false);

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('TransactionsView')) {
      history.push('/');
    }
  }, [isSignedIn]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      PaymentsModify: setCanModifyPayments,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='PayoutsRoute'>
        <div className='PayoutsRoute--center'>
          <div className='PayoutsRoute--filter'>
            {paymentsState.data?.settings?.createEnabled && canModifyPayments && (
              <div className='LayoutMain--button'>
                <Button
                  name='Create Payment'
                  click={(): void => history.push('/payments/create')}
                />
              </div>
            )}
            <div className='LayoutMain--mobile-filter'>
              <Button
                name='Filter'
                color='GREY'
                click={handleMobileFilter}
                margin='0px'
              />
            </div>
            <div className='LayoutMain--filter'>
              <FilterPayments setFilter={setFilter} />
            </div>
          </div>
          <div className='LayoutMain--mobile-filter'>
            <Button
              name='Filter'
              color='GREY'
              click={handleMobileFilter}
              margin='0px'
            />
          </div>

          <div className='PayoutsRoute--pending'>
            <TransactionsDash />
          </div>

          <div className='PayoutsRoute--payouts'>
            {
              <Payments
                payments={paymentsState.data?.items ?? []}
                isMore={isMorePayments}
                getMore={getMore}
                isLoading={isLoading && !paymentsState.data}
                error={paymentsError}
              />
            }
          </div>
          <SideMenu
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            position='LEFT'
            width='SUPER'
          >
            <div style={{ padding: '12px' }}>
              <FilterPayments setFilter={setFilter} />
            </div>
          </SideMenu>
        </div>
      </div>
    </Layout>
  );
};

export { PaymentsRoute };
