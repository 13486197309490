import React, { ReactElement, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

export interface OptionObj {
  value: string;
  name: string;
}

export interface PropsShape {
  options: OptionObj[];
}

const MiniMenu = ({ options }: PropsShape): ReactElement => {
  const history = useHistory();
  const miniMenuRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setIsOpen(!isOpen);
  };
  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    history.push((e.target as HTMLElement).id);
  };
  return (
    <div className='MiniMenu' onClick={handleOpen}>
      {isOpen && <div className='MiniMenu--overlay' onClick={handleOpen} />}
      <CSSTransition
        nodeRef={miniMenuRef}
        in={isOpen}
        timeout={400}
        classNames={`MiniMenu--MenuTransition`}
        unmountOnExit
      >
        <div className='MiniMenu--dropdown' ref={miniMenuRef}>
          {options.map((option: OptionObj, index: number) => (
            <div
              key={index}
              id={option.value}
              onClick={handleClick}
              className='MiniMenu--item'
            >
              {option.name}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};
export { MiniMenu };
