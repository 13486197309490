import { ReactElement, useEffect } from 'react';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { useEditAccount } from '../../hooks/useEditAccount';
import { ServerError } from '../forms/ServerError';
import { useEditProfileLogin } from '../../hooks/useEditProfileLogin';

const TermsUpdated = (): ReactElement => {
  const history = useHistory();
  const {
    accountData,
    isLoading: isEditAccountLoading,
    error: editAccountError,
    apiEditAccount,
  } = useEditAccount();
  const {
    isLoading: isEditProfileLoading,
    error: editProfileError,
    apiEditProfileLogin,
  } = useEditProfileLogin();

  useEffect(() => {
    if (accountData) {
      signIn();
    }
  }, [accountData]);

  const signIn = async (): Promise<void> => {
    await apiEditProfileLogin();
  };

  const accept = async (): Promise<void> => {
    await apiEditAccount({
      termsOfService: {
        acceptance: {
          ipAddress: '', // Populated by API, must be non-null though
          userAgent: window.navigator.userAgent,
        },
      },
    });
  };

  return (
    <div className='TermsUpdated'>
      <ServerError error={editAccountError || editProfileError}></ServerError>
      <div className='TermsUpdated--description'>
        We've updated our{' '}
        <a href='https://ryftpay.com/terms-conditions' target='_blank'>
          Terms of Service
        </a>{' '}
        to provide you with better services.
      </div>
      <div className='TermsUpdated--description'>
        We encourage you to read them carefully before accepting.
      </div>
      <div className='TermsUpdated--description'>
        The updates are effective from today.
      </div>
      <Button
        name='Accept'
        click={accept}
        loading={isEditAccountLoading || isEditProfileLoading}
      />
      <div
        onClick={(): void => history.push('/signin')}
        className='TermsUpdated--center'
      >
        <a>Back</a>
      </div>
    </div>
  );
};
export { TermsUpdated };
