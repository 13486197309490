import { useState, useEffect } from 'react';
import { StatusPageSummaryResponse } from '../interfaces/statusPageState';
import { useApiRequest, HttpMethod } from './useApiRequest';

interface GetStatusPageSummaryApi {
  summary: StatusPageSummaryResponse | undefined;
  isLoading: boolean;
  error: string | undefined;
  getStatusPageSummary: () => Promise<void>;
}

function useGetStatusPageSummary(): GetStatusPageSummaryApi {
  const [summary, setSummary] = useState<StatusPageSummaryResponse | undefined>(
    undefined
  );
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest();

  const getStatusPageSummary = async (): Promise<void> => {
    try {
      await sendRequest({
        url: `${process.env.REACT_APP_STATUS_PAGE_API_URL}`,
        method: HttpMethod.GET,
        isExternalApi: true,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'An error occurred while fetching the status page summary:',
        e
      );
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse?.data) {
      setSummary(apiResponse.data);
    }
  }, [apiResponse]);

  return { summary, isLoading, error, getStatusPageSummary };
}

export { useGetStatusPageSummary };
