import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

export interface PulseShape {
  title: string;
  body: any;
  link?: string;
}

export interface PropsShape {
  name: string;
  click?: (click: string) => void;
  loading?: boolean;
  color?: 'BLUE' | 'GREEN' | 'RED' | 'GREY';
  size?: 'SMALL' | 'NORMAL';
  type?: 'submit' | 'button' | 'reset';
  show?: boolean;
  card?: boolean;
  enabled?: boolean;
  margin?: string;
  conditionalText?: string;
  pulse?: PulseShape[];
}

const ConditionalButton = ({
  name,
  click,
  loading,
  color = 'BLUE',
  size = 'NORMAL',
  type = 'button',
  show = false,
  card = true,
  enabled = false,
  margin = '12px 0',
  conditionalText,
  pulse,
}: PropsShape): ReactElement => {
  const history = useHistory();
  const calcStyle = (): string => {
    const style = `ConditionalButton ConditionalButton--${color.toLowerCase()} ConditionalButton--${size.toLowerCase()}`;
    if (loading) {
      return `${style} ConditionalButton--loading`;
    }
    if (enabled) {
      return style;
    }
    return `${style} ConditionalButton--disabled`;
  };
  const doClick = (): void => {
    if (click && enabled) {
      click(name);
    }
  };
  const hasPulse: boolean = (pulse?.length || 0) > 0;
  return (
    <div className={hasPulse && card ? 'ConditionalButton--card' : ''}>
      {show && (
        <button
          id={name}
          type={type}
          name={name}
          className={calcStyle()}
          onClick={doClick}
          style={{ margin: margin }}
        >
          {loading ? '' : name}
        </button>
      )}
      {conditionalText && (
        <div className='ConditionalButton--conditional-text'>
          {conditionalText}
        </div>
      )}
      {hasPulse && (
        <div className='ConditionalButton--pulse'>
          {pulse &&
            pulse.map((pulseItem: PulseShape, index: number) => (
              <div
                key={`a-${index}`}
                className='ConditionalButton--pulse-item'
                style={{ cursor: pulseItem.link ? 'pointer' : 'default' }}
              >
                <div className='ConditionalButton--pulse-icon' />
                <div className='ConditionalButton--pulse-title'>
                  {pulseItem.title}
                </div>
                <div
                  className='ConditionalButton--pulse-body'
                  onClick={
                    pulseItem.link
                      ? (): void => history.push(pulseItem.link!)
                      : undefined
                  }
                >
                  {pulseItem.body}
                </div>
              </div>
            ))}
        </div>
      )}
      {!hasPulse && !card && (
        <div className='ConditionalButton--activecard'>
          <div>
            <div className='ConditionalButton--activeicon'> </div>
          </div>
          <div>
            <div className='ConditionalButton--pulse-title'>
              Payouts Now Active
            </div>
            <div className='ConditionalButton--pulse-body'>
              All requirements for receiving payouts have been met by this
              sub-account.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export { ConditionalButton };
