import React, { useState } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { TextInput } from '../../components/forms/TextInput';
import { Button } from '../../components/forms/Button';
import { SuccessMessage } from '../../components/forms/SuccessMessage';

const EmbeddedThreeDsRyftRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  const [accountId, setAccountId] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('pk_sandbox...');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [requiredActionJson, setRequiredActionJson] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [userFacingError, setUserFacingError] = useState<string>('');
  const [threeDsSuccess, setThreeDsSuccess] = useState<boolean>(false);

  const handleError = (e: any): void => {
    setError(e.detail.error);
  };

  const loadScript = (): void => {
    let embeddedScript = document.getElementById(
      'ryft-embedded-v1'
    ) as HTMLScriptElement;
    if (embeddedScript) {
      handleRequiredAction();
      return;
    }
    embeddedScript = document.createElement('script');
    embeddedScript.id = 'ryft-embedded-v1';
    embeddedScript.src = process.env.REACT_APP_EMBEDDED_URL!;
    embeddedScript.onload = function (): void {
      handleRequiredAction();
    };
    document.head.appendChild(embeddedScript);
  };

  const handleRequiredAction = (): void => {
    setError('');
    setUserFacingError('');
    setThreeDsSuccess(false);
    window.addEventListener('error', handleError);
    // @ts-ignore
    Ryft.handleRequiredAction(
      JSON.parse(requiredActionJson),
      publicKey,
      clientSecret,
      accountId
    )
      .then((paymentSession: any) => {
        handlePaymentSessionApprovalOrError(paymentSession);
      })
      .catch((error: string) => {
        setError(error);
      });
  };

  const handlePaymentSessionApprovalOrError = (paymentSession: any): void => {
    if (
      paymentSession.status === 'Approved' ||
      paymentSession.status === 'Captured'
    ) {
      setThreeDsSuccess(true);
      return;
    }
    if (paymentSession.lastError) {
      setError(paymentSession.lastError);
      setUserFacingError(
        // @ts-ignore
        Ryft.getUserFacingErrorMessage(paymentSession.lastError)
      );
      return;
    }
    setError(`Unexpected 3ds result: ${paymentSession}`);
  };
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='Ryft'>
        <div className='Ryft--center'>
          <section className='Ryft--payment-session'>
            <TextInput
              label
              name='Account ID'
              value={accountId}
              setValue={setAccountId}
            />
            <TextInput
              label
              name='Public Key'
              value={publicKey}
              setValue={setPublicKey}
            />
            <TextInput
              label
              name='Client Secret'
              value={clientSecret}
              setValue={setClientSecret}
              description='Can be obtained from API: /v1/payment-sessions'
            />
            <TextInput
              label
              name='Required Action JSON'
              value={requiredActionJson}
              setValue={setRequiredActionJson}
              description='JSON of the requiredAction from the payment session in PendingAction'
            />
            <Button name='Handle Required Action' click={loadScript} />
          </section>
          {threeDsSuccess && (
            <div id='ryft-pay-success' className='Ryft--paysuccess'>
              <SuccessMessage message='3ds Successful!' />
            </div>
          )}
          {error && (
            <div id='ryft-pay-error' className='Ryft--error'>
              Error: {error}
            </div>
          )}
          {userFacingError && (
            <div id='ryft-pay-error' className='Ryft--error'>
              User Facing Error: {userFacingError}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export { EmbeddedThreeDsRyftRoute };
