import Auth from '@aws-amplify/auth';

const getAuthToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

const getAccountId = async (): Promise<string> => {
  const { attributes } = await Auth.currentAuthenticatedUser();
  return attributes['custom:account_id'];
};

// TODO: Set the corect return type based on the url
// TODO: get all the onboarding to use this function
const get = async (
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
  body: null | string = null
): Promise<any> => {
  try {
    const authToken = await getAuthToken();
    if (authToken) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', authToken);
      myHeaders.append('Content-Type', 'application/json');
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${url}`,
        {
          method,
          headers: myHeaders,
          redirect: 'follow',
          body,
        }
      );

      const json = await response.json();
      json.status = response.status;
      return json;
    } else {
      throw new Error('No Auth Token');
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

async function request<T>(
  url: string,
  method: 'DELETE' | 'GET' | 'POST' | 'PATCH' = 'GET',
  body: null | string = null
): Promise<T | undefined> {
  try {
    const authToken = await getAuthToken();
    const accountId = await getAccountId();
    if (authToken) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', authToken);
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('AccountId', accountId);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${url}`,
        {
          method,
          headers: myHeaders,
          redirect: 'follow',
          body,
        }
      );

      const json = await response.json();
      json.status = response.status;
      return json;
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

export { get, request };
