import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { ServerError } from '../components/forms/ServerError';
import { Payouts } from '../components/dataGrids/Payouts';
import { Balances } from '../components/dataGrids/Balances';
import { FilterPayouts } from '../components/dataGrids/FilterPayouts';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const PayoutsRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();

  const [createPayoutError] = useState<string>('');

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !profileState?.data?.account.settings.payoutsDisplayed ||
      !permissions.has('PayoutsView')
    ) {
      history.push('/');
      return;
    }
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='PayoutsRoute'>
        <div className='PayoutsRoute--center'>
          <div className='PayoutsRoute--filter'>
            <FilterPayouts />
          </div>

          <div className='PayoutsRoute--pending'>
            <ServerError error={createPayoutError} />
            <Balances />
          </div>

          <div className='PayoutsRoute--payouts'>
            <Payouts />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { PayoutsRoute };
