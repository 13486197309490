import { ReactElement } from 'react';
import { Currency } from '../../interfaces/state';
import { CardCorners } from '../layouts/CardCorners';
import { NewCustomersChart } from './charts/NewCustomersChart';
import { ReturningCustomersChart } from './charts/ReturningCustomersChart';
import { SpendPerCustomerChart } from './charts/SpendPerCustomerChart';

export interface PropsShape {
  isLoading: boolean;
  currency: Currency;
  newCustomers: {
    categories: string[];
    data: number[];
  };
  returningCustomers: {
    categories: string[];
    data: number[];
  };
  spendPerCustomer: {
    categories: string[];
    data: number[];
  };
}

const CustomersChart = ({
  isLoading,
  currency,
  newCustomers,
  returningCustomers,
  spendPerCustomer,
}: PropsShape): ReactElement => {
  return (
    <div>
      <CardCorners title='Customers' minHeight='120px'>
        <>
          <div className='CustomersCharts'>
            <NewCustomersChart
              value={{
                isLoading,
                categories: newCustomers.categories,
                data: newCustomers.data,
              }}
            />
            <ReturningCustomersChart
              value={{
                isLoading,
                categories: returningCustomers.categories,
                data: returningCustomers.data,
              }}
            />
            <SpendPerCustomerChart
              value={{
                isLoading,
                categories: spendPerCustomer.categories,
                data: spendPerCustomer.data,
                currency: currency,
              }}
            />
          </div>
        </>
      </CardCorners>
    </div>
  );
};

export { CustomersChart };
