import { ReactElement } from 'react';
import { Heading } from '../layouts/Heading';

const Expired = (): ReactElement => {
  return (
    <div className='Expired'>
      <Heading
        title='Link Expired!'
        subtitle='The invitation link has expired'
      />
    </div>
  );
};
export { Expired };
