import * as Sentry from '@sentry/react';
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { logger } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { StepPosition } from '../forms/StepPosition';
import Auth from '@aws-amplify/auth';
import {
  AccountAddressInput,
  EditedAccountAddress,
} from '../forms/AccountAddressInput';
import { isEditedAddressValid } from '../../functions/accountHelpers';
import { AccountAddress } from '../../interfaces/accountState';
import { useEditAccount } from '../../hooks/useEditAccount';
import { useEditProfileLogin } from '../../hooks/useEditProfileLogin';
import { constructFullPath } from '../../functions/helpers';

const LastOne = (): ReactElement => {
  const history = useHistory();
  const [countryError, setCountryError] = useState<string>('');
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [lineOneError, setLineOneError] = useState<string>('');
  const [lineTwoError, setLineTwoError] = useState<string>('');
  const [cityError, setCityError] = useState<string>('');
  const [regionError, setRegionError] = useState<string>('');
  const [localStorageAddress, setLocalStorageAddress] =
    useState<AccountAddress>();
  const [editedAddress, setEditedAddress] = useState<EditedAccountAddress>();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const {
    accountData,
    isLoading: isEditAccountLoading,
    error: editAccountError,
    apiEditAccount,
  } = useEditAccount();
  const {
    profileData,
    isLoading: isEditProfileLoading,
    error: editProfileError,
    apiEditProfileLogin,
  } = useEditProfileLogin();

  useEffect(() => {
    setBtnDisabled(!isEditedAddressValid(editedAddress));
  }, [editedAddress]);

  useEffect(() => {
    if (accountData) {
      handleEditAccountSuccess();
    }
  }, [accountData]);

  useEffect(() => {
    if (profileData) {
      clearLocalStorageValues();
      const verificationRequired =
        accountData?.verification.status === 'Required';
      const onboardingCompleteReturnTo = localStorage.getItem('linkRedirectUrl')
        ? '/joined'
        : '/';
      if (
        !profileData.account.settings.payoutMethodsDisplayed &&
        !verificationRequired
      ) {
        history.push(onboardingCompleteReturnTo);
        return;
      }
      const addBankAccountReturnTo = verificationRequired
        ? constructFullPath(
            '/settings/account/verify',
            true,
            onboardingCompleteReturnTo
          )
        : onboardingCompleteReturnTo;
      history.push(
        constructFullPath(
          '/settings/banking/add-account',
          true,
          addBankAccountReturnTo
        )
      );
    }
  }, [profileData]);

  const handleEditAccountSuccess = async (): Promise<void> => {
    try {
      // Refresh cognito tokens so we populate the payout account data on them via the cognito trigger handler
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      await apiEditProfileLogin();
    } catch (err) {
      Sentry.captureException(err);
      logger.error(err);
    }
  };

  const back = (): void => {
    localStorage.setItem('editedAddress', JSON.stringify(editedAddress));
    history.push('/almost');
  };

  useEffect(() => {
    const editedAddressString = localStorage.getItem('editedAddress');
    if (editedAddressString) {
      setLocalStorageAddress(JSON.parse(editedAddressString));
    }
  }, [localStorage]);

  const clearLocalStorageValues = (): void => {
    localStorage.removeItem('editedAddress');
    localStorage.removeItem('accountEmail');
    localStorage.removeItem('email');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('businessType');
    localStorage.removeItem('accountType');
    localStorage.removeItem('firstName');
    localStorage.removeItem('middleName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('dateOfBirth');
    localStorage.removeItem('gender');
    localStorage.removeItem('nationalities');
    localStorage.removeItem('businessName');
    localStorage.removeItem('businessRegistrationNumber');
  };

  const join = async (): Promise<void> => {
    if (isEditAccountLoading || isEditProfileLoading || !editedAddress) {
      return;
    }
    const editedAddressValid = isEditedAddressValid(
      editedAddress,
      setCountryError,
      setPostalCodeError,
      setLineOneError,
      setLineTwoError,
      setCityError,
      setRegionError
    );
    if (!editedAddressValid) {
      return;
    }
    const callApi = async (): Promise<void> => {
      const accountType = localStorage.getItem('accountType');
      const accountEmail = localStorage.getItem('accountEmail');
      const phoneNumber = localStorage.getItem('phoneNumber') || undefined;
      await apiEditAccount({
        entityType: accountType === 'Business' ? 'Business' : 'Individual',
        business:
          accountType === 'Business'
            ? {
                name: localStorage.getItem('businessName'),
                type: localStorage.getItem('businessType'),
                registrationNumber: localStorage.getItem(
                  'businessRegistrationNumber'
                ),
                phoneNumber: phoneNumber,
                registeredAddress: {
                  lineOne: editedAddress.lineOne,
                  lineTwo: editedAddress.lineTwo,
                  city: editedAddress.city,
                  region: editedAddress.region,
                  postalCode: editedAddress.postalCode,
                  country: editedAddress.country,
                },
                contactEmail: accountEmail,
              }
            : undefined,
        individual:
          accountType === 'Individual'
            ? {
                firstName: localStorage.getItem('firstName'),
                middleNames: localStorage.getItem('middleName') || undefined,
                lastName: localStorage.getItem('lastName'),
                dateOfBirth: localStorage.getItem('dateOfBirth'),
                gender: localStorage.getItem('gender'),
                nationalities: localStorage
                  .getItem('nationalities')
                  ?.split(','),
                address: {
                  lineOne: editedAddress.lineOne,
                  lineTwo: editedAddress.lineTwo,
                  city: editedAddress.city,
                  region: editedAddress.region,
                  postalCode: editedAddress.postalCode,
                  country: editedAddress.country,
                },
                phoneNumber: phoneNumber,
                email: accountEmail,
              }
            : undefined,
        termsOfService: {
          acceptance: {
            ipAddress: '', // Populated by API, must be non-null though,
            userAgent: window.navigator.userAgent,
          },
        },
      });
    };
    // redirect user to sign in if they've been auto signed out during registration
    const checkUser = async (): Promise<void> => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        Sentry.captureException(err);
        history.push('/');
      }
    };
    checkUser();
    callApi();
  };
  return (
    <div className='LastOne'>
      <ServerError error={editAccountError || editProfileError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          join();
        }}
      >
        <StepPosition steps={4} position={4} />
        <AccountAddressInput
          input={localStorageAddress}
          countryError={countryError}
          postalCodeError={postalCodeError}
          lineOneError={lineOneError}
          lineTwoError={lineTwoError}
          cityError={cityError}
          regionError={regionError}
          setEditedAddress={setEditedAddress}
        />
        <Button
          name='Join'
          color='GREEN'
          click={join}
          loading={isEditAccountLoading || isEditProfileLoading}
          disabled={btnDisabled}
        />
      </form>
      <div onClick={back} className='ForgotPassword--center'>
        <a>Back</a>
      </div>
    </div>
  );
};
export { LastOne };
