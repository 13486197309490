import React from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';

const GeneralInquiriesRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='HelpCenter'>
        <div className='HelpCenter--center'>
          <header>Help Center</header>
          <div className='HelpCenter--grid'>
            <nav>
              <ul>
                <li
                  className='HelpCenter--option'
                  onClick={(): void => history.push('./getting-started')}
                >
                  Getting Started
                </li>
                <li
                  className='HelpCenter--option'
                  onClick={(): void => history.push('./profile-account')}
                >
                  Profile & Account
                </li>
                <li
                  className='HelpCenter--option'
                  onClick={(): void => history.push('./sub-accounts')}
                >
                  Sub Accounts
                </li>
                <li
                  className='HelpCenter--selected'
                  onClick={(): void => history.push('./general-inquiry')}
                >
                  General Enquiries
                </li>
              </ul>
            </nav>
            <aside>
              <h2>General Inquiries</h2>
              <p>Do you have a customer service phone number?</p>
              <p>How can I delete my Ryft account?</p>
              <p>Can I get some Ryft Merch?</p>
              <p>Not sure where to find the answer to your question?</p>
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { GeneralInquiriesRoute };
