import { useApiRequest, HttpMethod } from './useApiRequest';

export type AllowedType = 'text' | 'files' | 'both';
export type EvidenceType =
  | 'billingAddress'
  | 'shippingAddress'
  | 'duplicateTransaction'
  | 'uncategorised'
  | 'customerSignature'
  | 'receipt'
  | 'shippingConfirmation'
  | 'customerCommunication'
  | 'refundPolicy'
  | 'recurringPaymentAgreement';

interface DeleteRequestBody {
  text?: EvidenceType[];
  files?: EvidenceType[];
}

interface DeleteDisputeEvidenceReturn {
  isLoading: boolean;
  error: string | undefined;
  deleteEvidence: (
    disputeId: string,
    evidenceType: EvidenceType,
    allowed: AllowedType
  ) => Promise<void>;
  response?: { data: any; status: number };
  abortRequest: () => void;
}

function useDeleteDisputeEvidence(): DeleteDisputeEvidenceReturn {
  const [response, isLoading, error, sendRequest, abortRequest] =
    useApiRequest<any>();

  const deleteEvidence = async (
    disputeId: string,
    evidenceType: EvidenceType,
    allowed: AllowedType
  ): Promise<void> => {
    const body: DeleteRequestBody = {};

    if (allowed === 'text' || allowed === 'both') {
      body.text = [evidenceType];
    }

    if (allowed === 'files' || allowed === 'both') {
      body.files = [evidenceType];
    }

    await sendRequest({
      url: `/v1/disputes/${disputeId}/evidence`,
      method: HttpMethod.DELETE,
      body,
    });
  };

  return {
    isLoading,
    error,
    deleteEvidence,
    response,
    abortRequest,
  };
}

export default useDeleteDisputeEvidence;
