import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DateTime } from '../layouts/DateTime';
import { CSSTransition } from 'react-transition-group';
import { DateRange, DefinedRange } from 'react-date-range';
import { Button } from '../forms/Button';
import { SelectInput } from '../forms/SelectInput';
import {
  dateToEndOfDayEpochSeconds,
  dateToStartOfDayEpochSeconds,
} from '../../functions/helpers';
import { request } from '../../functions/callApi';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { ReportSupportedDataType } from '../../interfaces/reportsState';

export interface PropsShape {
  setToggle: Dispatch<SetStateAction<any>>;
  setError: Dispatch<SetStateAction<string>>;
  setReportCreated: Dispatch<SetStateAction<boolean>>;
}
export interface reportShape {
  startTimestamp: number;
  endTimestamp: number;
  report: string;
}
export interface RangeShape {
  startDate: Date;
  endDate: Date;
  key: string;
}
const NewReport = ({
  setToggle,
  setError,
  setReportCreated,
}: PropsShape): ReactElement => {
  const transitionRef = useRef(null);
  const [report, setReport] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [isDateRangeVisible, setDateRangeVisible] = useState<boolean>(false);
  const { reportsState, setReportsState } = useGlobalDataState();
  const [range, setRange] = useState<RangeShape[]>([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 0)),
      key: 'selection',
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (report) {
      setBtnDisabled(false);
    }
  }, [report]);
  const toggleDateRange = (): void => {
    setDateRangeVisible(!isDateRangeVisible);
  };

  const options =
    reportsState?.data?.settings.supportedDataTypes.map(
      (supportedDataType: ReportSupportedDataType) => ({
        value: supportedDataType.dataType,
        name: supportedDataType.name,
      })
    ) ?? [];

  const setReportData = async ({
    report,
    startTimestamp,
    endTimestamp,
  }: reportShape): Promise<void> => {
    const alreadyCreated = reportsState.data?.items.filter(
      (x) =>
        x.parameters.fromTimestamp === startTimestamp &&
        x.parameters.toTimestamp === endTimestamp &&
        x.parameters.dataTypeDefinitions[0].dataType === report
    );
    if (alreadyCreated?.length) {
      setReportsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected: alreadyCreated[0],
        },
      }));
      setToggle(Date.now());
    } else {
      const apiUrl = '/v1/reports';
      const body = {
        fromTimestamp: startTimestamp,
        toTimestamp: endTimestamp,
        type: reportsState?.data?.settings.supportedDataTypes.find(
          (dataType: ReportSupportedDataType) => dataType.dataType === report
        )?.reportType,
        dataTypes: [
          {
            dataType: report,
          },
        ],
      };
      setIsLoading(true);
      const createReportResponse = await request<any>(
        apiUrl,
        'POST',
        JSON.stringify(body)
      );
      setIsLoading(false);
      if (createReportResponse.code) {
        setError(createReportResponse.message);
      } else {
        setReportCreated(true);
      }
      setToggle(Date.now());
    }
  };

  const requestReport = (): void => {
    const startTimestamp = dateToStartOfDayEpochSeconds(range[0].startDate);
    const endTimestamp = dateToEndOfDayEpochSeconds(range[0].endDate);
    setReportData({ report, startTimestamp, endTimestamp });
    setDateRangeVisible(false);
  };

  return (
    <div className='NewReport'>
      {isDateRangeVisible && (
        <div className='NewReport--title'>Select Range</div>
      )}
      {!isDateRangeVisible && (
        <div className='NewReport--title'>New Report</div>
      )}
      <CSSTransition
        nodeRef={transitionRef}
        in={isDateRangeVisible}
        timeout={300}
        classNames='NewReport--transition'
        unmountOnExit
      >
        <div className='NewReport--transition' ref={transitionRef}>
          <div className='NewReport--range-view'>
            <DefinedRange
              onChange={(item): any => {
                if (item?.selection?.endDate && item?.selection?.startDate) {
                  setRange([
                    {
                      startDate: item.selection.startDate,
                      endDate:
                        item.selection.endDate > new Date()
                          ? new Date()
                          : item.selection.endDate,
                      key: 'selection',
                    },
                  ]);
                }
              }}
              ranges={range}
            />
            <DateRange
              maxDate={new Date()}
              editableDateInputs={true}
              onChange={(item): any => {
                if (item?.selection?.endDate && item?.selection?.startDate) {
                  setRange([
                    {
                      startDate: item.selection.startDate,
                      endDate:
                        item.selection.endDate > new Date()
                          ? new Date()
                          : item.selection.endDate,
                      key: 'selection',
                    },
                  ]);
                }
              }}
              moveRangeOnFirstSelection={false}
              ranges={range}
            />
            <div className='NewReport--range-view-buttons'>
              <Button name='Select' color='BLUE' click={toggleDateRange} />
              <Button name='Cancel' color='GREY' click={toggleDateRange} />
            </div>
          </div>
        </div>
      </CSSTransition>
      {!isDateRangeVisible && (
        <>
          <div className='NewReport--dates'>
            <div onClick={toggleDateRange} className='NewReport--date'>
              <DateTime value={range[0].startDate} />
            </div>
            <div onClick={toggleDateRange} className='NewReport--date'>
              <DateTime value={range[0].endDate} />
            </div>
          </div>
          <div className='NewReport--form'>
            <SelectInput
              name='Report'
              value={report}
              setValue={setReport}
              options={options}
            />
            <Button
              name='Request Report'
              click={requestReport}
              disabled={btnDisabled}
              loading={isLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};
export { NewReport };
