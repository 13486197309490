import { ReactElement, useEffect, useState } from 'react';
import {
  epochSecondsToDate,
  formatPrice,
  isMobile,
} from '../../functions/helpers';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { useHistory } from 'react-router-dom';
import { PlatformFeesShape } from '../../interfaces/feesState';
import { PlatformFeeDetail } from './PlatformFeeDetail';

interface PlatformFeesProps {
  platformFees: PlatformFeesShape[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  error?: string | undefined;
}

const PlatformFees = ({
  platformFees,
  isMore,
  getMore,
  isLoading,
  error,
}: PlatformFeesProps): ReactElement => {
  const history = useHistory();
  const [selectedPlatformFee, setSelectedPlatformFee] =
    useState<PlatformFeesShape | null>(null);

  const scrollToSelectedItem = (): void => {
    if (selectedPlatformFee) {
      const selectedItemElement = document.getElementById(
        `platform-fee-${selectedPlatformFee.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = platformFees.findIndex(
      (platformFee) => platformFee.id === selectedPlatformFee?.id
    );
    if (currentIndex > 0) {
      handleSelectedPlatformFee(platformFees[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = platformFees.findIndex(
      (platformFee) => platformFee.id === selectedPlatformFee?.id
    );
    if (currentIndex < platformFees.length - 1) {
      handleSelectedPlatformFee(platformFees[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedPlatformFee) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedPlatformFee]);

  const setSelected = (id: string): void => {
    const platformFee = platformFees.find(
      (platformFee) => platformFee.id === id
    );
    setSelectedPlatformFee(platformFee || null);
  };

  const handleCloseSideMenu = (): void => {
    setSelectedPlatformFee(null);
    history.push(`/platform-fees`);
  };

  const handleSelectedPlatformFee = (id: string): void => {
    if (isMobile()) {
      history.push(`/platform-fees/${id}`);
      return;
    }
    setSelected(id);
    history.push(`/platform-fees?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedPlatformFee(null);
    }
  }, [location.search, platformFees]);

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='PlatformFees'>
      <div className='PlatformFees--heading'>
        <div>Platform</div>
        <div className='PlatformFees--heading-mobile'>Processing</div>
        <div className='PlatformFees--heading-mobile'>Refunds</div>
        <div>Net</div>
        <div>When</div>
      </div>
      <div className='PlatformFees--scroll' id='scrollArea'>
        {platformFees.map((platformFee) => (
          <div
            id={`platform-fee-${platformFee.id}`}
            key={platformFee.id}
            onClick={(): void => handleSelectedPlatformFee(platformFee.id)}
            className={
              platformFee.id === selectedPlatformFee?.id
                ? 'PlatformFees--item PlatformFees--selected'
                : 'PlatformFees--item'
            }
          >
            <div className='PlatformFees--txt'>
              {formatPrice({
                pence: +platformFee.platformFeeAmount,
                currency: platformFee.currency,
              })}
            </div>
            <div className='PlatformFees--txt PlatformFees--txt-mobile'>
              {formatPrice({
                pence: +(platformFee.processingFeeAmount || 0),
                currency: platformFee.currency,
              })}
            </div>
            <div className='PlatformFees--txt PlatformFees--txt-mobile'>
              {formatPrice({
                pence: +platformFee.platformFeeRefundedAmount,
                currency: platformFee.currency,
              })}
            </div>
            <div className='PlatformFees--txt'>
              {formatPrice({
                pence: +platformFee.netAmount,
                currency: platformFee.currency,
              })}
            </div>
            <div className='PlatformFees--txt'>
              <DateTime
                showTime
                value={epochSecondsToDate(platformFee.createdTimestamp)}
              />
            </div>
          </div>
        ))}
        {isLoading && !error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
        )}
        {!isLoading && error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
        )}
      </div>
      <SideMenu
        isOpen={!!selectedPlatformFee}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <PlatformFeeDetail
          platformFee={selectedPlatformFee}
          hasPrevious={
            platformFees.findIndex(
              (platformFee) => platformFee.id === selectedPlatformFee?.id
            ) > 0
          }
          handlePrevious={handleBack}
          hasNext={
            platformFees.findIndex(
              (platformFee) => platformFee.id === selectedPlatformFee?.id
            ) <
            platformFees.length - 1
          }
          handleNext={handleNext}
        />
      </SideMenu>
    </div>
  );
};
export { PlatformFees };
