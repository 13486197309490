import { ReactElement, useEffect, useState } from 'react';
import { isMobile } from '../../functions/helpers';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { CustomerDetailLayout } from '../layouts/CustomerDetailLayout';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Customer } from '../../interfaces/customersState';
import { ScrollBox } from '../layouts/ScrollBox';
import { PaymentMethodsLayout } from '../layouts/PaymentMethodsLayout';
import { useGetCustomerWithPaymentMethodsById } from '../../hooks/useGetCustomerWithPaymentMethodsById';

interface CustomerDetailProps {
  customer?: Customer | null;
}

const CustomerDetail = ({ customer }: CustomerDetailProps): ReactElement => {
  const history = useHistory();
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { profileState } = useGlobalDataState();
  const [, , paymentMethods] = useGetCustomerWithPaymentMethodsById(
    customer?.id
  );

  const getPaymentMethods = (): ReactElement[] => {
    const kids = paymentMethods?.map((paymentMethod, index) => (
      <div key={index}>
        <PaymentMethodsLayout data={paymentMethod} />
      </div>
    ));
    return kids as ReactElement[];
  };

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    setShowDelete(permissions.has('CustomersModify'));
    setShowPaymentMethods(permissions.has('PaymentMethodsView'));
  }, [profileState]);

  return (
    <div className='CustomerDetail'>
      <div className='CustomerDetail--top'>
        <div className='CustomerDetail--title-container'></div>
        <div className='CustomerDetail--data-container'>
          {isMobile() && (
            <div className='CustomerDetail--data-title'>Customer Details</div>
          )}
          {customer && <CustomerDetailLayout data={customer} />}

          {showPaymentMethods &&
            isMobile() &&
            paymentMethods &&
            paymentMethods.length > 0 && (
              <div className='CustomerDetail--data-title'>Payment Methods</div>
            )}

          {isMobile() && showPaymentMethods && (
            <ScrollBox height={100} gap={12}>
              {getPaymentMethods()}
            </ScrollBox>
          )}
        </div>
      </div>
      <div className='CustomerDetail--middle'></div>
      <div className='CustomerDetail--bottom'>
        <div className='CustomerDetail--buttons'>
          {isMobile() && (
            <Button
              name='Back'
              color='GREY'
              click={(): any => history.push(`/customers`)}
            />
          )}
          <Button
            name='View Payments'
            click={(): any =>
              history.push(`/payments?customerId=${customer?.id}`)
            }
          />
          {showDelete && (
            <Button
              name='Delete Customer'
              color='RED'
              click={(): void =>
                history.push(`/customers/${customer?.id}/delete`)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { CustomerDetail };
