import { ReactElement } from 'react';

export interface PropsShape {
  supportLink: string;
}

const ContactSupport = ({ supportLink }: PropsShape): ReactElement => {
  return (
    <div className='ContactSupport'>
      <a
        href={supportLink}
        target='_blank'
        rel='noopener noreferrer'
        className='ContactSupport--nolink'
      >
        Contact Support
      </a>
    </div>
  );
};

export { ContactSupport };
