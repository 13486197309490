import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { useEditAccount } from '../../../hooks/useEditAccount';
import { countries } from '../../../functions/iso3166-alpha2';
import { DateOfBirth } from '../../../components/forms/DateOfBirth';
import { PhoneNumber } from '../../../components/forms/PhoneNumber';
import SelectTags from '../../../components/forms/SelectTags';
import { isEmailNonAsync } from '../../../functions/validators';
import { arraysAllEqual, handleNotSignedIn } from '../../../functions/helpers';
import { RequiredField } from '../../../interfaces/accountState';

const EditIndividualRoute = (): ReactElement => {
  interface EditedDetails {
    firstName?: string;
    middleNames?: string;
    lastName?: string;
    email?: string;
    dateOfBirth?: string;
    countryOfBirth?: string;
    gender?: string;
    nationalities?: string[];
    phoneNumber?: string;
  }

  const countryOfBirthFieldName: string = 'individual.countryOfBirth';
  const genderFieldName: string = 'individual.gender';
  const nationalitiesFieldName: string = 'individual.nationalities';
  const phoneNumberFieldName: string = 'individual.phoneNumber';

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountState, profileState } = useGlobalDataState();
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [middleNames, setMiddleNames] = useState<string>('');
  const [middleNamesError, setMiddleNamesError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [countryOfBirth, setCountryOfBirth] = useState<string>('');
  const [countryOfBirthError, setCountryOfBirthError] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [nationalities, setNationalities] = useState<string[]>([]);
  const [nationalitiesError, setNationalitiesError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [detailsDiffer, setDetailsDiffer] = useState<boolean>(true);
  const [editedDetails, setEditedDetails] = useState<EditedDetails>();
  const { accountData, isLoading, error, apiEditAccount } = useEditAccount();

  const account = accountState?.data;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !account ||
      account.verification.status === 'PendingVerification' ||
      !permissions.has('AccountModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (account?.individual) {
      setFirstName(account.individual.firstName);
      setMiddleNames(account.individual.middleNames ?? '');
      setLastName(account.individual.lastName);
      setEmail(account.individual.email);
      setDateOfBirth(account.individual.dateOfBirth);
      setCountryOfBirth(account.individual.countryOfBirth ?? '');
      setGender(account.individual.gender ?? '');
      setNationalities(account.individual.nationalities ?? []);
      setPhoneNumber({ number: account.individual.phoneNumber, valid: true });
    }
  }, [account]);

  useEffect(() => {
    const isEditedFirstName = firstName != account?.individual?.firstName;
    const isEditedMiddleNames =
      middleNames.length > 0 && middleNames != account?.individual?.middleNames;
    const isEditedLastName = lastName != account?.individual?.lastName;
    const isEditedEmail = email != account?.individual?.email;
    const isEditedDateOfBirth = dateOfBirth != account?.individual?.dateOfBirth;
    const isEditedCountryOfBirth =
      countryOfBirth.length > 0 &&
      countryOfBirth != account?.individual?.countryOfBirth;
    const isEditedGender = gender != (account?.individual?.gender ?? '');
    const isEditedNationalities =
      nationalities.length > 0 &&
      !arraysAllEqual(nationalities, account?.individual?.nationalities);
    const isEditedPhoneNumber =
      phoneNumber.number != account?.individual?.phoneNumber;
    const detailsDiffer: boolean =
      isEditedFirstName ||
      isEditedMiddleNames ||
      isEditedLastName ||
      isEditedEmail ||
      isEditedDateOfBirth ||
      isEditedCountryOfBirth ||
      isEditedGender ||
      isEditedNationalities ||
      isEditedPhoneNumber;
    setDetailsDiffer(detailsDiffer);
    setEditedDetails({
      firstName: isEditedFirstName ? firstName : undefined,
      middleNames: isEditedMiddleNames ? middleNames : undefined,
      lastName: isEditedLastName ? lastName : undefined,
      email: isEditedEmail ? email : undefined,
      dateOfBirth: isEditedDateOfBirth ? dateOfBirth : undefined,
      countryOfBirth: isEditedCountryOfBirth ? countryOfBirth : undefined,
      gender: isEditedGender ? gender : undefined,
      nationalities: isEditedNationalities ? nationalities : undefined,
      phoneNumber: isEditedPhoneNumber ? phoneNumber.number : undefined,
    });
  }, [
    firstName,
    middleNames,
    lastName,
    email,
    dateOfBirth,
    countryOfBirth,
    gender,
    nationalities,
    phoneNumber,
    account,
  ]);

  useEffect(() => {
    accountData && history.push(returnTo);
  }, [accountData]);

  const isFieldRequiredForVerification = (fieldName: string): boolean =>
    account?.verification.requiredFields?.some(
      (field: RequiredField) => field.name === fieldName
    ) === true;

  const verifyNoChanges = (): void => history.push(returnTo);

  const handleUpdate = async (): Promise<void> => {
    if (!editedDetails || !account) {
      return;
    }
    setFirstNameError('');
    setMiddleNamesError('');
    setLastNameError('');
    setEmailError('');
    setDateOfBirthError('');
    setCountryOfBirthError('');
    setGenderError('');
    setNationalitiesError('');
    setPhoneNumberError('');

    let valid = true;
    if (firstName.trim().length < 1) {
      valid = false;
      setFirstNameError('First Name cannot be empty');
    }
    if (lastName.trim().length < 1) {
      valid = false;
      setLastNameError('Last Name cannot be empty');
    }
    const emailValid = isEmailNonAsync(email);
    if (!emailValid) {
      valid = false;
      setEmailError('Email is invalid');
    }
    if (dateOfBirth.trim().length < 1) {
      valid = false;
      setDateOfBirthError('Date Of Birth cannot be empty');
    }
    if (
      countryOfBirth.trim().length < 1 &&
      isFieldRequiredForVerification(countryOfBirthFieldName)
    ) {
      valid = false;
      setCountryOfBirthError('Country Of Birth is required for verification');
    }
    if (
      gender.trim().length < 1 &&
      isFieldRequiredForVerification(genderFieldName)
    ) {
      valid = false;
      setGenderError('Gender is required for verification');
    }
    if (
      nationalities.length < 1 &&
      isFieldRequiredForVerification(nationalitiesFieldName)
    ) {
      valid = false;
      setNationalitiesError(
        'At least one nationality is required for verification'
      );
    }
    if (!phoneNumber && isFieldRequiredForVerification(phoneNumberFieldName)) {
      valid = false;
      setPhoneNumberError('Phone Number is required for verification');
    }
    if (!phoneNumber.valid) {
      valid = false;
      setPhoneNumberError('Phone number is invalid');
    }

    if (!valid) {
      return;
    }

    if (!detailsDiffer && isVerificationRequired()) {
      verifyNoChanges();
      return;
    }

    apiEditAccount({
      individual: {
        firstName: editedDetails.firstName,
        middleNames: editedDetails.middleNames,
        lastName: editedDetails.lastName,
        email: editedDetails.email,
        dateOfBirth: editedDetails.dateOfBirth,
        countryOfBirth: editedDetails.countryOfBirth,
        gender: editedDetails.gender,
        nationalities: editedDetails.nationalities,
        phoneNumber: editedDetails.phoneNumber,
      },
    });
  };

  const isVerificationRequired = (): boolean =>
    account?.verification.status === 'Required';

  const getTitle = (): string =>
    isVerificationRequired()
      ? 'Verify personal details'
      : 'Edit personal details';

  const getSubTitle = (): string =>
    isVerificationRequired()
      ? 'Please review all your personal details so we can verify you'
      : '';

  return (
    <>
      {!account && <div className='AppRoute--notfound'>Error 404</div>}
      {account && (
        <LayoutIllustration
          title={<>{getTitle()}</>}
          subTitle={<>{getSubTitle()}</>}
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={error} />
            <>
              <div
                style={{
                  display: 'flex',
                  gridTemplateColumns: 'auto auto auto',
                  gridGap: '12px',
                  alignItems: 'end',
                }}
              >
                <TextInput
                  label
                  name='Name'
                  value={firstName}
                  placeHolder='First'
                  setValue={setFirstName}
                  error={firstNameError}
                />
                <TextInput
                  name='Middle Name'
                  value={middleNames}
                  placeHolder='Middle'
                  setValue={setMiddleNames}
                  error={middleNamesError}
                  optional
                />
                <TextInput
                  name='Last Name'
                  value={lastName}
                  placeHolder='Last'
                  setValue={setLastName}
                  error={lastNameError}
                />
              </div>
              <TextInput
                label
                name='Email'
                description="This is only used for contact and WON'T update your login details"
                value={email}
                setValue={setEmail}
                error={emailError}
              />
              <DateOfBirth
                label
                value={dateOfBirth}
                setValue={setDateOfBirth}
                error={dateOfBirthError}
              />
              <SelectInput
                label
                name='Country of birth'
                value={countryOfBirth}
                setValue={setCountryOfBirth}
                options={countries()}
                error={countryOfBirthError}
                optional={
                  !account?.individual?.countryOfBirth &&
                  !isFieldRequiredForVerification(countryOfBirthFieldName)
                }
              />
              <SelectInput
                label
                name='Gender at birth'
                description='International regulations require either Female or Male'
                value={gender}
                setValue={setGender}
                selectPrompt='Select...'
                options={[
                  { value: 'Male', name: 'Male' },
                  { value: 'Female', name: 'Female' },
                ]}
                error={genderError}
              />
              <SelectTags
                name='Nationalities'
                description='Please select all that apply'
                selectedArray={nationalities}
                optionsArray={countries()}
                setSelectedArray={setNationalities}
                error={nationalitiesError}
              />
              <PhoneNumber
                label
                name='Phone Number'
                value={phoneNumber.number}
                setValue={setPhoneNumber}
                error={phoneNumberError}
                optional={
                  !account?.individual?.phoneNumber &&
                  !isFieldRequiredForVerification(phoneNumberFieldName)
                }
              />
            </>
            <div className='EditWebhookRoute--button-container'>
              <Button
                name={isVerificationRequired() ? 'Verify' : 'Update'}
                click={handleUpdate}
                loading={isLoading}
                disabled={!detailsDiffer && !isVerificationRequired()}
              />
              <Button
                name='Cancel'
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditIndividualRoute };
