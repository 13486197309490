import { CardScheme } from '../interfaces/state';

function getAvsResponseCodeSummary(
  code: string,
  cardScheme: CardScheme
): string {
  if (code === 'F' && cardScheme === 'Amex') {
    return 'Partial Match - address matches but name is incorrect';
  }
  if (code === 'W' && cardScheme === 'Amex') {
    return 'No Match - name, address and postal/zip code are all incorrect';
  }

  if (code === 'A') {
    return 'Partial Match - street address matches, but postal/zip code does not match';
  }
  if (code === 'B') {
    return 'Partial Match - street address matches, postal/zip code not verified';
  }
  if (code === 'C') {
    return 'No Match - street address and postal/zip code not verified';
  }
  if (
    code === 'D' ||
    code === 'F' ||
    code === 'M' ||
    code === 'X' ||
    code === 'Y'
  ) {
    return 'Full Match - street address and postal/zip code match';
  }
  if (code === 'G') {
    return 'Not Supported - address information not verified';
  }
  if (code === 'I') {
    return 'No Match - address information not verified';
  }
  if (code === 'N') {
    return 'No Match - neither street address nor postal/zip code match';
  }
  if (code === 'P') {
    return 'Partial Match - postal code matches, street address not verified';
  }
  if (code === 'R') {
    return 'System unavailable - Unable to perform verification';
  }
  if (code === 'S') {
    return 'Not supported - AVS currently not supported by issuer';
  }
  if (code === 'U') {
    return 'System unavailable - Address not verified due to no data from issuer';
  }
  if (code === 'W' || code === 'Z') {
    return 'Partial Match - postal/zip code matches, but street address does not match';
  }
  /*
    Amex specific codes
  */
  if (code === 'L') {
    return 'Partial Match - name and postal code match, but address does not';
  }
  if (code === 'O') {
    return 'Partial Match - name and address match, but postal/zip code does not';
  }
  if (code === 'K') {
    return 'Partial Match - name matches, but postal/zip code and address do not';
  }
  if (code === 'E') {
    return 'Partial Match - address and postal/zip code match, but name is incorrect';
  }
  return 'Unknown - contact us for assistance';
}

export { getAvsResponseCodeSummary };
