import { ReactElement, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Card } from '../../components/layouts/Card';
import { get } from '../../functions/callApi';
import { SideMenu } from '../../components/layouts/SideMenu';
import { DateTime } from '../../components/layouts/DateTime';
import { epochSecondsToDate, formatPrice } from '../../functions/helpers';
import { Status, StatusShape } from '../../components/layouts/Status';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { getCurrency } from '../../functions/money-utility';

interface PageTrackerShape {
  eventsStartAfter: string;
}
interface FilterShape {
  eventId: string;
  accountId: string;
}

const EventsRoute = (): ReactElement => {
  const { isSignedIn, setProfile, profile } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isLoadingPrevEvents, setIsLoadingPrevEvents] =
    useState<boolean>(false);
  const [isLoadingNextEvents, setIsLoadingNextEvents] =
    useState<boolean>(false);
  const [pageTracker, setPageTracker] = useState<PageTrackerShape[]>([]);
  const [filterEventData, setFilterEventData] = useState<FilterShape>();
  const [serverError, setServerError] = useState<string>('');
  interface getEventsPropsShape {
    page?: 'BACK' | 'NEXT';
  }
  const [selectedEvent, setSelectedEvent] = useState<any>({});

  const doSelectedEvent = (e: any): any => {
    setSelectedEvent(e);
    setIsSideMenuOpen(!isSideMenuOpen);
  };
  const doFilterEvents = (filterData: FilterShape): any => {
    setFilterEventData(filterData);
  };

  useEffect(() => {
    getEvents({});
  }, [filterEventData]);

  const getEvents = async ({ page }: getEventsPropsShape): Promise<void> => {
    setServerError('');
    let apiUrl: string = '/v1/developer/events?pageSize=10&ascending=false';
    filterEventData?.eventId &&
      (apiUrl += `&eventId=${filterEventData?.eventId}`);
    filterEventData?.accountId &&
      (apiUrl += `&accountId=${filterEventData?.accountId}`);
    if (page === 'NEXT') {
      apiUrl += `&eventsStartAfter=${
        pageTracker[pageTracker.length - 1].eventsStartAfter
      }`;
    }
    if (page === 'BACK') {
      const tempPageTracker = [...pageTracker];
      tempPageTracker.pop();
      if (tempPageTracker.length > 1) {
        apiUrl += `&eventsStartAfter=${
          tempPageTracker[tempPageTracker.length - 2].eventsStartAfter
        }`;
      }
      setPageTracker(tempPageTracker);
    }
    try {
      const response = await get(apiUrl);
      if (response?.status === 200) {
        setProfile((prevState) => ({
          ...prevState,
          webHookEvents: {
            items: response.items,
            paginationToken: response.paginationToken,
          },
        }));
        page ??
          setPageTracker([{ eventsStartAfter: response.paginationToken }]);
        if (page === 'NEXT') {
          const tempPageTracker = [...pageTracker];
          tempPageTracker.push({
            eventsStartAfter: response.paginationToken,
          });
          setPageTracker(tempPageTracker);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log(response.message);
        setServerError(response.message);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const loadPreviousEventsPage = async (): Promise<void> => {
    setIsLoadingPrevEvents(true);
    await getEvents({ page: 'BACK' });
    setIsLoadingPrevEvents(false);
  };
  const loadNextEventsPage = async (): Promise<void> => {
    setIsLoadingNextEvents(true);
    await getEvents({ page: 'NEXT' });
    setIsLoadingNextEvents(false);
  };

  return (
    <>
      {!isSignedIn && <Redirect to={'/'} />}
      {isSignedIn && (
        <div>
          <div className='DeveloperRoute--events'>
            <Card
              filterHideDate
              group={profile.group}
              accountSettings={profileState?.data?.account.settings}
              title='Events'
              titleRight='FILTER'
              filter='EVENTS'
              filterValue={doFilterEvents}
              footer={'PAGINATED'}
              paginationInfo={{
                count: profile?.webHookEvents?.items?.length || 0,
                term: 'events',
                hasMore: true,
                clickPrev: loadPreviousEventsPage,
                clickNext: loadNextEventsPage,
                isLoadingPrev: isLoadingPrevEvents,
                isLoadingNext: isLoadingNextEvents,
                prevDisabled: pageTracker.length === 1,
                nextDisabled:
                  profile?.webHookEvents?.paginationToken === undefined,
              }}
            >
              <>
                <SideMenu
                  isOpen={isSideMenuOpen}
                  setIsOpen={setIsSideMenuOpen}
                  position='RIGHT'
                  width='REGULAR'
                >
                  <div id='test'>
                    <div className='DeveloperRoute--side-title'>Event</div>
                    <div className='DeveloperRoute--side-sub-title'>
                      {selectedEvent?.id}
                    </div>
                    <div className='DeveloperRoute--side-menu'>
                      <div>Event type</div>
                      <div>{selectedEvent?.eventType}</div>
                      <div>Delivery Status</div>
                      <div>{selectedEvent?.deliveryStatus}</div>
                      <div>Event Data Id</div>
                      <div>{selectedEvent?.data?.id}</div>
                      {selectedEvent?.accountId && (
                        <>
                          <div>Account Id</div>
                          <div>{selectedEvent?.accountId}</div>
                        </>
                      )}
                      {!isNaN(selectedEvent?.data?.amount) && (
                        <>
                          <div>Amount</div>
                          <div>
                            {formatPrice({
                              pence: selectedEvent?.data?.amount,
                              currency: getCurrency(
                                selectedEvent?.data?.currency
                              ),
                            })}
                          </div>
                        </>
                      )}
                      {selectedEvent?.data?.platformFee && (
                        <>
                          <div>Platform Fee</div>
                          <div>
                            {formatPrice({
                              pence: selectedEvent?.data?.platformFee,
                              currency: getCurrency(
                                selectedEvent?.data?.currency
                              ),
                            })}
                          </div>
                        </>
                      )}
                      {selectedEvent?.data?.status && (
                        <>
                          <div>Status</div>
                          <div>{selectedEvent?.data?.status}</div>
                        </>
                      )}
                      {selectedEvent?.data?.email && (
                        <>
                          <div>Email</div>
                          <div>{selectedEvent?.data?.email}</div>
                        </>
                      )}
                      <div>Event Created</div>
                      <div>
                        <DateTime
                          showTime
                          value={epochSecondsToDate(
                            selectedEvent?.createdTimestamp
                          )}
                        />
                      </div>
                      <div>Event Last Updated</div>
                      <div>
                        <DateTime
                          showTime
                          value={epochSecondsToDate(
                            selectedEvent?.lastUpdatedTimestamp
                          )}
                        />
                      </div>
                    </div>

                    {selectedEvent?.endpoints?.length > 0 && (
                      <div className='DeveloperRoute--side-menu-endpoints'>
                        <div>Webhook Id</div>
                        <div>{selectedEvent?.endpoints[0]?.webhookId}</div>
                        <div>Acknowledged</div>
                        <div>
                          {JSON.stringify(
                            selectedEvent?.endpoints[0].acknowledged
                          )}
                        </div>
                        <div>Attempts</div>
                        <div>{selectedEvent?.endpoints[0].attempts}</div>
                        <div>Delivery status</div>
                        <div>{selectedEvent?.endpoints[0].deliveryStatus}</div>
                      </div>
                    )}

                    <div className='DeveloperRoute--side-menu-json'>
                      <ReactJson
                        src={selectedEvent}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={true}
                      />
                    </div>
                  </div>
                </SideMenu>
                {serverError && (
                  <>
                    <div className='DeveloperRoute--no-results'>
                      <h2>Error</h2>
                      <p>{serverError}</p>
                    </div>
                  </>
                )}
                {!serverError && (
                  <div>
                    <div className='DeveloperRoute--event-key'>
                      <div>Status</div>
                      <div>Event</div>
                      <div>When</div>
                    </div>
                    {profile?.webHookEvents?.items?.map((item, index) => (
                      <div
                        key={index}
                        className='DeveloperRoute--event-items'
                        onClick={(): void => doSelectedEvent(item)}
                      >
                        <div>
                          <Status
                            status={
                              item.deliveryStatus.toUpperCase() as StatusShape
                            }
                          />
                        </div>
                        <div>{item.eventType}</div>
                        <DateTime
                          showTime
                          value={epochSecondsToDate(item?.createdTimestamp)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export { EventsRoute };
