import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { PersonShape } from '../interfaces/personsState';
import { BusinessRole } from '../interfaces/accountState';
import {
  AccountAddressRequest,
  AccountDocumentRequest,
} from './useEditAccount';
import { useGlobalDataState } from './useGlobalDataState';

interface UseEditPersonReturnType {
  personData: PersonShape | null;
  isLoading: boolean;
  error: any;
  apiEditPerson: (id: string, body: EditPersonRequest) => Promise<void>;
}

export interface EditPersonRequest {
  firstName?: string;
  middleNames?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  countryOfBirth?: string;
  gender?: string;
  nationalities?: string[];
  address?: AccountAddressRequest;
  phoneNumber?: string;
  businessRoles?: BusinessRole[];
  documents?: AccountDocumentRequest[];
}

function useEditPerson(): UseEditPersonReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PersonShape>();
  const { personsState, setPersonsState } = useGlobalDataState();
  const [personData, setPersonData] = useState<PersonShape | null>(null);

  const apiEditPerson = async (
    id: string,
    body: EditPersonRequest
  ): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/account/persons/${id}`,
        method: HttpMethod.PATCH,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while updating the person:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const updatedPerson = apiResponse.data;
      setPersonData(updatedPerson);
      const existingPersons = personsState.data?.items ?? [];
      const existingPersonIndex = existingPersons.findIndex(
        (p: PersonShape) => p.id == updatedPerson.id
      );
      if (existingPersonIndex > -1) {
        existingPersons[existingPersonIndex] = updatedPerson;
      } else {
        existingPersons.push(updatedPerson);
      }
      setPersonsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: existingPersons,
          selected: updatedPerson,
        },
      }));
    }
  }, [apiResponse]);

  return {
    personData,
    isLoading,
    error,
    apiEditPerson,
  };
}

export { useEditPerson };
