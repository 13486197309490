import { ReactElement, useEffect } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { DisputeDetail } from '../../components/dataGrids/DisputeDetail';
import { handleNotSignedIn } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';

const DisputesRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState } = useGlobalDataState();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !permissions.has('DisputesView') ||
      (profile.group && profile.group !== 'STANDARD')
    ) {
      history.push('/');
    }
  }, [isSignedIn, profile]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <DisputeDetail />
    </Layout>
  );
};

export { DisputesRoute };
