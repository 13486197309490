import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { Reports, reportsStateShape } from '../interfaces/reportsState';

interface UseListReportsValue {
  reports?: Reports;
  isLoading: boolean;
  error?: string;
  listReports: () => Promise<void>;
}

function useListReports(): UseListReportsValue {
  const { setReportsState } = useGlobalDataState();
  const [reports, setReports] = useState<Reports>();
  const [response, isLoading, error, sendRequest] = useApiRequest<Reports>();

  const listReports = async (): Promise<void> => {
    const url = '/v1/reports';
    try {
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`An error occurred listing reports:`, e);
    }
  };

  useEffect(() => {
    if (response && response?.data) {
      setReports(response.data);
      setReportsState((prevState: reportsStateShape) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: response.data.reports.items,
          paginationToken: response.data.reports.paginationToken,
          settings: response.data.settings,
          selected: response.data.reports.items[0],
        },
      }));
    }
  }, [response]);

  return { reports, isLoading, error, listReports };
}

export { useListReports };
