import { ReactElement, useEffect, useState, useRef } from 'react';
import { CardCorners } from '../layouts/CardCorners';
import { request } from '../../functions/callApi';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Button } from '../forms/Button';
import { SideMenu } from '../layouts/SideMenu';
import { DateTime } from '../layouts/DateTime';
import { Copy } from '../forms/Copy';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { ApplePayWebDomainsAdd } from './ApplePayWebDomainsAdd';
import { ApplePayWebDomainsDelete } from './ApplePayWebDomainsDelete';
import { Pagination } from '../layouts/Pagination';

interface getApplePayWebDomainsShape {
  page?: 'PREVIOUS' | 'NEXT';
}
interface PageTrackerShape {
  startAfter: string;
}

const ApplePayWebDomains = (): ReactElement => {
  const history = useHistory();
  const { applePayWebDomainState, setApplePayWebDomainState } =
    useGlobalDataState();
  const scrollContainer = useRef<null | HTMLDivElement>(null);
  const [pageTracker, setPageTracker] = useState<PageTrackerShape[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [applePayWebDomainsChange, setApplePayWebDomainsChange] =
    useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<string>('');

  useEffect(() => {
    applePayWebDomainsChange && getApplePayWebDomains({});
  }, [applePayWebDomainsChange]);

  const getApplePayWebDomains = async ({
    page,
  }: getApplePayWebDomainsShape): Promise<void> => {
    setLoading(true);
    let apiUrl =
      '/v1/developer/apple-pay/web-domains?pageSize=10&ascending=false';
    if (page === 'NEXT') {
      apiUrl += `&applePayWebDomainsStartAfter=${
        pageTracker[pageTracker.length - 1].startAfter
      }`;
    }
    if (page === 'PREVIOUS') {
      const tempPageTracker = [...pageTracker];
      tempPageTracker.pop();
      if (tempPageTracker.length > 1) {
        apiUrl += `&applePayWebDomainsStartAfter=${
          tempPageTracker[tempPageTracker.length - 2].startAfter
        }`;
      }
      setPageTracker(tempPageTracker);
    }

    try {
      const response = await request<any>(apiUrl);
      setLoading(false);
      if (response?.status === 200) {
        const items = response.items;
        setApplePayWebDomainState((prevState: any) => ({
          ...prevState,
          data: { items },
        }));
        if (!page) {
          setPageTracker([
            {
              startAfter: response.paginationToken,
            },
          ]);
        } else if (page === 'NEXT') {
          const tempPageTracker = [...pageTracker];
          tempPageTracker.push({
            startAfter: response.paginationToken,
          });
          setPageTracker(tempPageTracker);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log(response.message);
      }
    } catch (err: any) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(err);
    }
    setApplePayWebDomainsChange(false);
  };

  const deleteThis = (id: string): void => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setDeleteId(id);
  };

  return (
    <div className='ApplePayWebDomains'>
      <CardCorners
        title='Web Domains'
        topRight={
          <Button
            name='Add Domain'
            size='SMALL'
            color='GREY'
            click={(): void =>
              isMobile()
                ? history.push(`/developer/apple-pay/web-domains/add`)
                : setIsAddModalOpen(!isAddModalOpen)
            }
          />
        }
        bottomLeft={
          <div>
            Showing {applePayWebDomainState.data?.items?.length} of 99 Web
            Domains
          </div>
        }
        bottomRight={
          <Pagination
            goPrevious={(): Promise<void> =>
              getApplePayWebDomains({ page: 'PREVIOUS' })
            }
            previousDisabled={pageTracker.length === 1}
            goNext={(): Promise<void> =>
              getApplePayWebDomains({ page: 'NEXT' })
            }
            nextDisabled={
              pageTracker[pageTracker.length - 1]?.startAfter === undefined
            }
          />
        }
      >
        <>
          {loading && !applePayWebDomainState?.data ? (
            <>
              <div className='ApplePayWebDomains--no-results'>
                <h2>Loading</h2>
                <p>Fetching your domains, one second...</p>
              </div>
            </>
          ) : (
            <>
              {applePayWebDomainState?.data?.items &&
                applePayWebDomainState?.data?.items.length !== 0 && (
                  <div>
                    <div className='ApplePayWebDomains--titles'>
                      <div>Domain</div>
                      <div className='ApplePayWebDomains--titles-mobile'>
                        Id
                      </div>
                      <div className='ApplePayWebDomains--titles-mobile'>
                        Added
                      </div>
                    </div>
                    <div
                      className='ApplePayWebDomains--container'
                      ref={scrollContainer}
                    >
                      {applePayWebDomainState?.data?.items?.map(
                        (item, index) => (
                          <div
                            className='ApplePayWebDomains--items'
                            key={index}
                          >
                            <div className='ApplePayWebDomains--items-item'>
                              {item.domainName}
                            </div>
                            <div className='ApplePayWebDomains--items-item ApplePayWebDomains--items-item-mobile'>
                              <Copy
                                type='BUTTON_WITH_TEXT'
                                buttonSize='MEDIUM'
                                value={item.id}
                                text={item.id}
                              />
                            </div>
                            <div className='ApplePayWebDomains--items-item ApplePayWebDomains--items-item-mobile'>
                              <DateTime
                                showTime
                                value={epochSecondsToDate(
                                  item.createdTimestamp
                                )}
                              />
                            </div>
                            <div
                              className='ApplePayWebDomains--items-item ApplePayWebDomains--items-item-close'
                              onClick={(): void =>
                                isMobile()
                                  ? history.push(
                                      `/developer/apple-pay/web-domains/delete/${item.id}`
                                    )
                                  : deleteThis(item.id)
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              {applePayWebDomainState?.data?.items?.length === 0 && (
                <div className='ApplePayWebDomains--no-results'>
                  <>
                    <h2>No web domains yet</h2>
                    <p>We couldn't find any web domains</p>
                  </>
                </div>
              )}
            </>
          )}
          <SideMenu
            isOpen={isAddModalOpen}
            setIsOpen={setIsAddModalOpen}
            position='CENTER'
            width='REGULAR'
          >
            <ApplePayWebDomainsAdd
              setIsOpen={setIsAddModalOpen}
              setApplePayWebDomainsChange={setApplePayWebDomainsChange}
            />
          </SideMenu>
          <SideMenu
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            position='CENTER'
            width='NARROW'
          >
            <ApplePayWebDomainsDelete
              id={deleteId}
              setIsOpen={setIsDeleteModalOpen}
              setApplePayWebDomainsChange={setApplePayWebDomainsChange}
            />
          </SideMenu>
        </>
      </CardCorners>
    </div>
  );
};
export { ApplePayWebDomains };
