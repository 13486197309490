import { ReactElement } from 'react';
import { ComponentStatus } from '../../interfaces/statusPageState';

export interface PropsShape {
  name: string;
  status: ComponentStatus;
  showStatusDescription?: boolean;
}

const SystemStatusComponent = ({
  name,
  status,
  showStatusDescription,
}: PropsShape): ReactElement => {
  const getStatusDescription = (status: ComponentStatus): string => {
    switch (status) {
      case 'operational':
        return 'operational';
      case 'degraded_performance':
        return 'degraged';
      case 'partial_outage':
        return 'partial outage';
      case 'major_outage':
        return 'major outage';
      case 'under_maintenance':
        return 'under maintenance';
    }
  };

  return (
    <div className={`SystemStatusComponent--${status}`}>
      {showStatusDescription
        ? `${name} - ${getStatusDescription(status)}`
        : `${name}`}
    </div>
  );
};
export { SystemStatusComponent };
