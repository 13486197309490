import { ReactElement, useEffect, useState } from 'react';
import { request } from '../../functions/callApi';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { CardCorners } from '../layouts/CardCorners';
import { DateFilter } from '../layouts/DateFilter';
import { formatPrice } from '../../functions/helpers';

const TransactionsDash = (): ReactElement => {
  const { profileState, transactionsDashState, setTransactionsDashState } =
    useGlobalDataState();
  const [date, setDate] = useState<Date>(new Date());
  const [serverError, setServerError] = useState<String>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [
    isStandardAccountWithPlatformSettings,
    setIsStandardAccountWithPlatformSettings,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsStandardAccountWithPlatformSettings(
      profileState?.data?.account.settings.subAccountsDisplayed === true
    );
  }, [profileState]);

  useEffect(() => {
    let isMounted = true;
    const getTransactionsBalance = async (
      date: Date,
      isStandardAccountWithPlatformSettings: boolean
    ): Promise<void> => {
      try {
        setServerError('');
        const utcDate = date.toISOString().split('T')[0];
        const endpoint = `/v1/transactions/balance?paymentsDate=${utcDate}`;
        setLoading(true);
        const result = await request<any>(endpoint);
        setLoading(false);
        if (result.status !== 200) {
          setServerError('Error loading dashboard, please try again later');
        }
        const firstBalance = result[0];
        const currency = firstBalance.currency;
        const revenue = formatPrice({
          pence: firstBalance.totalRevenueCaptured,
          currency: currency,
        });
        const refunds = formatPrice({
          pence: firstBalance.totalRevenueRefunded,
          currency: currency,
        });
        const fees = isStandardAccountWithPlatformSettings
          ? formatPrice({
              pence: firstBalance.netCommissionCollectedAmount,
              currency: currency,
            })
          : formatPrice({
              pence: firstBalance.totalFeesPaidAmount,
              currency: currency,
            });
        if (isMounted) {
          setTransactionsDashState((prevState: any) => ({
            ...prevState,
            data: {
              revenue,
              refunds,
              fees,
              currency,
              transactions: firstBalance.numberOfIncomingTransactions,
              customers: firstBalance.numberOfCustomers,
            },
          }));
        }
      } catch (err: any) {
        setLoading(false);
        setServerError('Error loading dashboard, please try again later');
      }
    };
    getTransactionsBalance(date, isStandardAccountWithPlatformSettings);
    return (): void => {
      isMounted = false;
    };
  }, [date, isStandardAccountWithPlatformSettings]);

  return (
    <div>
      <CardCorners
        title='Overview'
        topRight={<DateFilter value={date} setValue={setDate} />}
        bottomLeft={
          <div>
            {transactionsDashState.data?.transactions} transactions from{' '}
            {transactionsDashState.data?.customers} customers
          </div>
        }
        bottomRight={<div />}
        minHeight='120px'
      >
        <>
          {serverError && (
            <div className='TransactionsDash--center'>{serverError}</div>
          )}
          {!serverError && (
            <div className='TransactionsDash'>
              <div className='TransactionsDash--item'>
                <div className='TransactionsDash--item-title'>
                  Total revenue captured
                </div>
                {loading ? (
                  <div className='TransactionsDash--item-loading'>
                    Loading...
                  </div>
                ) : (
                  <div className='TransactionsDash--item-value'>
                    {transactionsDashState.data?.revenue}
                  </div>
                )}
              </div>
              <div className='TransactionsDash--item'>
                <div className='TransactionsDash--item-title'>
                  Refunds value
                </div>
                {loading ? (
                  <div className='TransactionsDash--item-loading'>
                    Loading...
                  </div>
                ) : (
                  <div className='TransactionsDash--item-value'>
                    {transactionsDashState.data?.refunds}
                  </div>
                )}
              </div>
              <div className='TransactionsDash--item'>
                {isStandardAccountWithPlatformSettings ? (
                  <div className='TransactionsDash--item-title'>
                    Net sub account fees
                  </div>
                ) : (
                  <div className='TransactionsDash--item-title'>
                    Total Fees Deducted
                  </div>
                )}
                {loading ? (
                  <div className='TransactionsDash--item-loading'>
                    Loading...
                  </div>
                ) : (
                  <div className='TransactionsDash--item-value'>
                    {transactionsDashState.data?.fees}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </CardCorners>
    </div>
  );
};
export { TransactionsDash };
