import { ReactElement } from 'react';
import { Status, StatusShape } from './Status';
import {
  epochSecondsToDate,
  formatPrice,
  truncateWord,
} from '../../functions/helpers';
import { PaymentMethodWallet } from './PaymentMethodWallet';
import { PaymentMethod } from './PaymentMethod';
import { DateTime } from './DateTime';
import { PaymentsShape } from '../../interfaces/paymentState';
import { Copy } from '../forms/Copy';
import { splitPaymentDetailItems } from '../../interfaces/paymentState';
import { Expander } from './Expander';
import { getAvsResponseCodeSummary } from '../../hooks/getAvsResponseCodeDescriptions';
import { getCvvResponseCodeDescription } from '../../hooks/getCvvResponseCodeDescriptions';
import {
  getCofUsageMessage,
  getEntryModeMessage,
} from '../../hooks/getPaymentDescriptions';

export interface PropsShape {
  data: PaymentsShape;
  isStandardAccount: boolean;
  accountId: string;
}

const PaymentDetailLayout = ({
  data,
  isStandardAccount,
  accountId,
}: PropsShape): ReactElement => {
  const showDeductions = (): boolean =>
    showProcessingFee() ||
    showPlatformFee() ||
    showRefundedAmount() ||
    showNetSplitAmount() ||
    showNetChargebackAmount() ||
    showChargebackFeeAmount();
  const showProcessingFee = (): boolean =>
    !!data?.processingFeeAmount && data.processingFeeAmount > 0;
  const showPlatformFee = (): boolean =>
    !!data?.appliedNetPlatformFeeAmount && data.appliedNetPlatformFeeAmount > 0;
  const showRefundedAmount = (): boolean =>
    !!data?.refundedAmount && data.refundedAmount > 0;
  const showNetSplitAmount = (): boolean =>
    !!data?.splitPaymentDetail && data.splitPaymentDetail.items.length > 0;
  const showNetChargebackAmount = (): boolean =>
    !!data?.disputeDetail && data.disputeDetail!.netChargebackAmount > 0;
  const showChargebackFeeAmount = (): boolean =>
    !!data?.disputeDetail && data.disputeDetail!.chargebackFeeAmount > 0;
  const isLiableForChargeback = (): boolean =>
    data?.disputeDetail?.liableAccountId === data.accountId;
  const showFundsFlow = (): boolean => {
    const noFundsFlowStatuses = [
      'PendingPayment',
      'PendingAuth',
      'PendingAction',
      'Processing',
      'Failed',
      'CardVerified',
    ];
    return !noFundsFlowStatuses.includes(data?.detailedStatus);
  };
  const requiredActionMessage = (type: string): string => {
    switch (type) {
      case 'Redirect':
        return '3ds authentication';
      case 'Identify':
        return '3ds identification';
      case 'Challenge':
        return '3ds challenge';
      case 'PayL8rRedirect':
        return 'PayL8r application form';
      case 'PayL8rPendingApproval':
        return 'PayL8r manual underwriting';
      default:
        return 'Unknown';
    }
  };
  return (
    <>
      <div className='PaymentDetailLayout'>
        <div className='PaymentDetailLayout--title'>
          <div className='PaymentDetailLayout--title-desc'>Payment</div>
          <div className='PaymentDetailLayout--title-id'>
            <Copy type='BUTTON_WITH_TEXT' text={data?.id} value={data?.id} />
          </div>
        </div>
        <div className='PaymentDetailLayout--container'>
          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>
              Status:
            </div>
            <div className='PaymentDetailLayout--container-pair-val PaymentDetailLayout--container-pair-status'>
              <Status
                status={data?.detailedStatus?.toUpperCase() as StatusShape}
              />
            </div>
          </div>
          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>Type:</div>
            <div className='PaymentDetailLayout--container-pair-val'>
              {data?.paymentType}
            </div>
          </div>
          {data.entryMode && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Entry Mode:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {getEntryModeMessage(data.entryMode)}
              </div>
            </div>
          )}
          {data.credentialOnFileUsage && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Transaction initiator:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {data.credentialOnFileUsage.initiator} (
                {getCofUsageMessage(
                  data.paymentType,
                  data.credentialOnFileUsage
                )}
                )
              </div>
            </div>
          )}
          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>
              Amount:
            </div>
            <div className='PaymentDetailLayout--container-pair-val'>
              {formatPrice({
                pence: data?.totalAmount || 0,
                currency: data?.currency,
              })}
            </div>
          </div>

          {data?.voidedAmount! > 0 && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Voided:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {formatPrice({
                  pence: data?.voidedAmount || 0,
                  currency: data?.currency,
                })}
              </div>
            </div>
          )}
          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>
              Captured:
            </div>
            <div className='PaymentDetailLayout--container-pair-val'>
              {formatPrice({
                pence: data?.capturedAmount || 0,
                currency: data?.currency,
              })}
            </div>
          </div>

          {data?.failureReason && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Failed reason:
              </div>
              <div
                className='PaymentDetailLayout--container-pair-val'
                style={{ textTransform: 'capitalize' }}
              >
                {data?.failureReason.replaceAll('_', ' ')}
              </div>
            </div>
          )}

          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>When:</div>
            <div className='PaymentDetailLayout--container-pair-val'>
              <DateTime
                showTime
                value={epochSecondsToDate(data?.createdTimestamp)}
              />
            </div>
          </div>

          <div className='PaymentDetailLayout--container-pair'>
            <div className='PaymentDetailLayout--container-pair-key'>
              Descriptor:
            </div>
            <div className='PaymentDetailLayout--container-pair-val'>
              {data?.statementDescriptor.descriptor}
            </div>
          </div>
          {data?.threeDsDetails?.authenticationFlow && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                3D Secure:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {data?.threeDsDetails?.authenticationFlow} /{' '}
                {data?.threeDsDetails?.version}
              </div>
            </div>
          )}
          {data?.metadata &&
            Object.entries(data.metadata).map(([key, value]) => (
              <div className='PaymentDetailLayout--container-pair' key={key}>
                <div className='PaymentDetailLayout--container-pair-key'>
                  <div className='PaymentDetailLayout--meta'>{key}:</div>
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {truncateWord(value, 40, 40)}
                </div>
              </div>
            ))}
          {data?.requiredAction && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Required action:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {requiredActionMessage(data.requiredAction.type)}
              </div>
            </div>
          )}
        </div>
        {isStandardAccount && data.accountId !== accountId && (
          <>
            <div className='PaymentDetailLayout--title'>
              <div className='PaymentDetailLayout--title-desc'>Sub Account</div>
              <div className='PaymentDetailLayout--title-id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  text={data.accountId}
                  value={data.accountId}
                />
              </div>
            </div>

            <div className='PaymentDetailLayout--container'>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  Name:
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {data?.statementDescriptor.descriptor}
                </div>
              </div>
            </div>
          </>
        )}
        {data?.paymentMethod && (
          <>
            <div className='PaymentDetailLayout--title'>
              <div className='PaymentDetailLayout--title-desc'>
                Payment Method:
              </div>
              {data.paymentMethod?.storedPaymentMethod?.id && (
                <div className='PaymentDetailLayout--title-id'>
                  <Copy
                    type='BUTTON_WITH_TEXT'
                    text={data.paymentMethod?.storedPaymentMethod?.id}
                    value={data.paymentMethod?.storedPaymentMethod?.id}
                  />
                </div>
              )}
            </div>

            <div className='PaymentDetailLayout--container'>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  {data.paymentMethod?.type}:
                </div>
                <div className='PaymentDetailLayout--container-pair-card'>
                  <PaymentMethod value={data?.paymentMethod!} />
                </div>
              </div>
              {data?.paymentMethod?.wallet && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Wallet:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-wallet'>
                    <PaymentMethodWallet value={data?.paymentMethod?.wallet} />
                  </div>
                </div>
              )}
              {data.paymentMethod?.billingAddress && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Address:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-address'>
                    {data.paymentMethod.billingAddress.firstName}{' '}
                    {data.paymentMethod.billingAddress.lastName}
                    {(data.paymentMethod.billingAddress.firstName ||
                      data.paymentMethod.billingAddress.lastName) && <br />}
                    {data.paymentMethod.billingAddress.lineOne}
                    {data.paymentMethod.billingAddress.lineOne && <br />}
                    {data.paymentMethod.billingAddress.lineTwo}
                    {data.paymentMethod.billingAddress.lineTwo && <br />}
                    {data.paymentMethod.billingAddress.city}
                    {data.paymentMethod.billingAddress.city && <br />}
                    {data.paymentMethod.billingAddress.region}
                    {data.paymentMethod.billingAddress.region && <br />}
                    {data.paymentMethod.billingAddress.postalCode} <br />
                    {data.paymentMethod.billingAddress.country}
                  </div>
                </div>
              )}
              {data.paymentMethod.card &&
                data.paymentMethod?.checks?.avsResponseCode && (
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      AVS Response Code:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {data.paymentMethod.checks.avsResponseCode} (
                      {getAvsResponseCodeSummary(
                        data.paymentMethod.checks.avsResponseCode,
                        data.paymentMethod.card.scheme
                      )}
                      )
                    </div>
                  </div>
                )}
              {data.paymentMethod?.checks?.cvvResponseCode && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    CVV Response Code:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {data.paymentMethod.checks.cvvResponseCode} (
                    {getCvvResponseCodeDescription(
                      data.paymentMethod.checks.cvvResponseCode
                    )}
                    )
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className='PaymentDetailLayout--title'>
          <div className='PaymentDetailLayout--title-desc'>Customer</div>
          <div className='PaymentDetailLayout--title-id'>
            {data.customerDetails?.id && (
              <Copy
                type='BUTTON_WITH_TEXT'
                text={data.customerDetails?.id}
                value={data.customerDetails?.id}
              />
            )}
          </div>
        </div>
        <div className='PaymentDetailLayout--container'>
          {data?.customerEmail && (
            <div className='PaymentDetailLayout--container-pair '>
              <div className='PaymentDetailLayout--container-pair-key'>
                Email:
              </div>
              <div className='PaymentDetailLayout--container-pair-val PaymentDetailLayout--container-pair-email'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  text={data?.customerEmail}
                  value={data?.customerEmail}
                />
              </div>
            </div>
          )}
          {data?.customerDetails?.firstName && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                First name:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {data.customerDetails.firstName}
              </div>
            </div>
          )}
          {data?.customerDetails?.lastName && (
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Last name:
              </div>
              <div className='PaymentDetailLayout--container-pair-val'>
                {data.customerDetails.lastName}
              </div>
            </div>
          )}
          {data?.customerDetails?.metadata && (
            <>
              {Object.entries(data.customerDetails.metadata).map(
                ([key, value]) => (
                  <div
                    className='PaymentDetailLayout--container-pair'
                    key={key}
                  >
                    <div className='PaymentDetailLayout--container-pair-key'>
                      <div className='PaymentDetailLayout--meta'>{key}:</div>
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {value}
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
      {data?.shippingDetails && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>Shipping</div>
          </div>
          <div className='PaymentDetailLayout--container'>
            <div className='PaymentDetailLayout--container-pair'>
              <div className='PaymentDetailLayout--container-pair-key'>
                Address:
              </div>
              <div className='PaymentDetailLayout--container-pair-address'>
                {data.shippingDetails.address.firstName}{' '}
                {data.shippingDetails.address.lastName}
                {(data.shippingDetails.address.firstName ||
                  data.shippingDetails.address.lastName) && <br />}
                {data.shippingDetails.address.lineOne}
                {data.shippingDetails.address.lineOne && <br />}
                {data.shippingDetails.address.lineTwo}
                {data.shippingDetails.address.lineTwo && <br />}
                {data.shippingDetails.address.city}
                {data.shippingDetails.address.city && <br />}
                {data.shippingDetails.address.region}
                {data.shippingDetails.address.region && <br />}
                {data.shippingDetails.address.postalCode} <br />
                {data.shippingDetails.address.country}
              </div>
            </div>
          </div>
        </>
      )}
      {showFundsFlow() && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>Funds Flow</div>
            <div className='PaymentDetailLayout--title-id'></div>
          </div>
          <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-bottom'>
            <div>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  Captured:
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: data?.capturedAmount || 0,
                    currency: data?.currency,
                  })}
                </div>
              </div>
            </div>
            {showDeductions() && (
              <div className='PaymentDetailLayout--arrow-bottom' />
            )}
          </div>
          {showDeductions() && (
            <div className='PaymentDetailLayout--container'>
              {showProcessingFee() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Processing fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: data.processingFeeAmount!,
                      currency: data.currency,
                    })}
                  </div>
                </div>
              )}

              {showPlatformFee() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Platform fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: data.appliedNetPlatformFeeAmount!,
                      currency: data.currency,
                    })}
                  </div>
                </div>
              )}

              {showRefundedAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Refunds:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: data.refundedAmount,
                      currency: data.currency,
                    })}
                  </div>
                </div>
              )}
              {showNetSplitAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Total split payments:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: data.splitPaymentDetail!.netSplitAmount,
                      currency: data.currency,
                    })}
                  </div>
                </div>
              )}
              {showNetChargebackAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Chargeback amount:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: isLiableForChargeback()
                        ? data.disputeDetail!.netChargebackAmount
                        : 0,
                      currency: data.currency,
                    })}{' '}
                    {!isLiableForChargeback() &&
                      `(${formatPrice({
                        pence: data.disputeDetail!.netChargebackAmount,
                        currency: data.currency,
                      })} charged to platform)`}
                  </div>
                </div>
              )}
              {showChargebackFeeAmount() && (
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Chargeback fee:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: isLiableForChargeback()
                        ? data.disputeDetail!.chargebackFeeAmount
                        : 0,
                      currency: data.currency,
                    })}{' '}
                    {!isLiableForChargeback() &&
                      `(${formatPrice({
                        pence: data.disputeDetail!.chargebackFeeAmount,
                        currency: data.currency,
                      })} charged to platform)`}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-top'>
            <div>
              <div className='PaymentDetailLayout--container-pair'>
                <div className='PaymentDetailLayout--container-pair-key'>
                  Net:
                </div>
                <div className='PaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: data?.netAmount || 0,
                    currency: data?.currency,
                  })}
                </div>
              </div>
            </div>
            <div className='PaymentDetailLayout--arrow-top' />
          </div>
        </>
      )}

      {data?.splitPaymentDetail?.items && (
        <>
          <div className='PaymentDetailLayout--title'>
            <div className='PaymentDetailLayout--title-desc'>
              Split Payments
            </div>
            <div className='PaymentDetailLayout--title-id'></div>
          </div>

          {data.splitPaymentDetail.items.map(
            (split: splitPaymentDetailItems, index: number) => {
              return (
                <div key={index}>
                  <div className='PaymentDetailLayout--title'>
                    <div className='PaymentDetailLayout--title-desc-light'>
                      {split.description || split.accountId}
                    </div>
                    <div className='PaymentDetailLayout--title-id'>
                      <Copy
                        type='BUTTON_WITH_TEXT'
                        text={split.id}
                        value={split.id}
                      />
                    </div>
                  </div>
                  <div className='PaymentDetailLayout--container'>
                    <Expander>
                      <div>
                        <div className='PaymentDetailLayout--container-pair'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Amount:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.amount || 0,
                              currency: data?.currency,
                            })}
                          </div>
                        </div>
                      </div>

                      <div className='PaymentDetailLayout--expanded'>
                        <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Fee:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.fee?.amount || 0,
                              currency: data?.currency,
                            })}
                          </div>
                        </div>
                        {split.refundedAmount && (
                          <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                            <div className='PaymentDetailLayout--container-pair-key'>
                              Refunded:
                            </div>
                            <div className='PaymentDetailLayout--container-pair-val'>
                              {formatPrice({
                                pence: split.refundedAmount || 0,
                                currency: data?.currency,
                              })}
                            </div>
                          </div>
                        )}
                        {split.fee?.refundedAmount && (
                          <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                            <div className='PaymentDetailLayout--container-pair-key'>
                              Refunded fee:
                            </div>
                            <div className='PaymentDetailLayout--container-pair-val'>
                              {formatPrice({
                                pence: split.fee?.refundedAmount || 0,
                                currency: data?.currency,
                              })}
                            </div>
                          </div>
                        )}

                        <div className='PaymentDetailLayout--container-pair PaymentDetailLayout--container-pair-short'>
                          <div className='PaymentDetailLayout--container-pair-key'>
                            Net:
                          </div>
                          <div className='PaymentDetailLayout--container-pair-val'>
                            {formatPrice({
                              pence: split.netSplitAmount || 0,
                              currency: data?.currency,
                            })}
                          </div>
                        </div>
                      </div>
                    </Expander>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
    </>
  );
};
export { PaymentDetailLayout };
