import { ReactElement } from 'react';
import { Status, StatusShape } from './Status';
import { epochSecondsToDate, formatPrice } from '../../functions/helpers';
import { DateTime } from './DateTime';
import { SplitPaymentsShape } from '../../interfaces/splitPaymentState';
import ReactJson from 'react-json-view';
import { Copy } from '../forms/Copy';

export interface PropsShape {
  data: SplitPaymentsShape;
}

const SplitPaymentDetailLayout = ({ data }: PropsShape): ReactElement => {
  const showDeductions = (): boolean =>
    (data?.netPlatformFeeAmount != undefined &&
      data?.netPlatformFeeAmount > 0) ||
    (data?.refundedAmount != undefined && data?.refundedAmount > 0);

  return (
    <>
      <div className='SplitPaymentDetailLayout'>
        <div className='SplitPaymentDetailLayout--title'>
          <div className='SplitPaymentDetailLayout--title-desc'>Payment</div>
          <div className='SplitPaymentDetailLayout--title-id'>
            <Copy type='BUTTON_WITH_TEXT' text={data?.id} value={data?.id} />
          </div>
        </div>
        <div className='SplitPaymentDetailLayout--container'>
          <div className='SplitPaymentDetailLayout--container-pair'>
            <div className='SplitPaymentDetailLayout--container-pair-key'>
              Status:
            </div>
            <div className='SplitPaymentDetailLayout--container-pair-val SplitPaymentDetailLayout--container-pair-status'>
              <Status status={data?.status?.toUpperCase() as StatusShape} />
            </div>
          </div>
          <div className='SplitPaymentDetailLayout--container-pair'>
            <div className='SplitPaymentDetailLayout--container-pair-key'>
              Amount:
            </div>
            <div className='SplitPaymentDetailLayout--container-pair-val'>
              {formatPrice({
                pence: data?.totalAmount || 0,
                currency: data?.currency,
              })}
            </div>
          </div>

          <div className='SplitPaymentDetailLayout--container-pair'>
            <div className='SplitPaymentDetailLayout--container-pair-key'>
              When:
            </div>
            <div className='SplitPaymentDetailLayout--container-pair-val'>
              <DateTime
                showTime
                value={epochSecondsToDate(data?.createdTimestamp)}
              />
            </div>
          </div>
          {data.description && (
            <div className='SplitPaymentDetailLayout--container-pair'>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                Description:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val'>
                {data.description}
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        <div className='SplitPaymentDetailLayout--title'>
          <div className='SplitPaymentDetailLayout--title-desc'>Funds Flow</div>
          <div className='SplitPaymentDetailLayout--title-id'></div>
        </div>
        <div className='SplitPaymentDetailLayout--container SplitPaymentDetailLayout--container-title-bottom'>
          <div>
            <div className='SplitPaymentDetailLayout--container-pair'>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                Captured:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val'>
                {formatPrice({
                  pence: data?.totalAmount,
                  currency: data?.currency,
                })}
              </div>
            </div>
          </div>
          {showDeductions() && (
            <div className='SplitPaymentDetailLayout--arrow-bottom' />
          )}
        </div>

        {showDeductions() && (
          <div className='SplitPaymentDetailLayout--container'>
            {data?.netPlatformFeeAmount && (
              <div className='SplitPaymentDetailLayout--container-pair'>
                <div className='SplitPaymentDetailLayout--container-pair-key'>
                  Platform Fee:
                </div>
                <div className='SplitPaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: data?.netPlatformFeeAmount,
                    currency: data?.currency,
                  })}
                </div>
              </div>
            )}

            {data?.refundedAmount > 0 && (
              <div className='SplitPaymentDetailLayout--container-pair'>
                <div className='SplitPaymentDetailLayout--container-pair-key'>
                  Refunds:
                </div>
                <div className='SplitPaymentDetailLayout--container-pair-val'>
                  {formatPrice({
                    pence: data?.refundedAmount,
                    currency: data?.currency,
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        <div className='SplitPaymentDetailLayout--container SplitPaymentDetailLayout--container-title-top'>
          <div>
            <div className='SplitPaymentDetailLayout--container-pair'>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                Net:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val'>
                {formatPrice({
                  pence: data?.netAmount || 0,
                  currency: data?.currency,
                })}
              </div>
            </div>
          </div>
          <div className='SplitPaymentDetailLayout--arrow-top' />
        </div>

        <div className='SplitPaymentDetailLayout--title'>
          <div className='SplitPaymentDetailLayout--title-desc'>Customer</div>
          <div className='SplitPaymentDetailLayout--title-id'>
            <Copy
              type='BUTTON_WITH_TEXT'
              text={data?.paymentSessionDetail?.customerDetails?.id}
              value={data?.paymentSessionDetail?.customerDetails?.id || ''}
            />
          </div>
        </div>
        <div className='SplitPaymentDetailLayout--container'>
          {data?.paymentSessionDetail.customerEmail && (
            <div className='SplitPaymentDetailLayout--container-pair '>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                Email:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val SplitPaymentDetailLayout--container-pair-email'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  text={data?.paymentSessionDetail.customerEmail}
                  value={data?.paymentSessionDetail.customerEmail}
                />
              </div>
            </div>
          )}
          {data?.paymentSessionDetail.customerDetails.firstName && (
            <div className='SplitPaymentDetailLayout--container-pair'>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                First name:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val'>
                {data?.paymentSessionDetail.customerDetails.firstName}
              </div>
            </div>
          )}
          {data?.paymentSessionDetail.customerDetails.lastName && (
            <div className='SplitPaymentDetailLayout--container-pair'>
              <div className='SplitPaymentDetailLayout--container-pair-key'>
                Last name:
              </div>
              <div className='SplitPaymentDetailLayout--container-pair-val'>
                {data?.paymentSessionDetail.customerDetails.lastName}
              </div>
            </div>
          )}
        </div>
      </>

      {data?.metadata && (
        <>
          <br />
          <br />
          <div className='SplitPaymentDetail--data-title'>Payment Metadata</div>
          <div className='SplitPaymentDetail--data-metadata'>
            <ReactJson
              src={data?.metadata}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          </div>
        </>
      )}
      {data?.paymentSessionDetail?.customerDetails?.metadata && (
        <>
          <br />
          <br />
          <div className='SplitPaymentDetail--data-title'>
            Customer Metadata
          </div>
          <div className='SplitPaymentDetail--data-metadata'>
            <ReactJson
              src={data?.paymentSessionDetail?.customerDetails?.metadata}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
            />
          </div>
        </>
      )}
    </>
  );
};
export { SplitPaymentDetailLayout };
