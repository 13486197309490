import { ReactElement } from 'react';
import { CardCorners } from '../layouts/CardCorners';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { formatPrice } from '../../functions/helpers';
import { Currency } from '../../interfaces/state';

export interface PropsShape {
  value: {
    isLoading: boolean;
    categories: string[];
    data: {
      currency: Currency;
      values: number[];
    };
  };
}

const VolumeChart = ({ value }: PropsShape): ReactElement => {
  const options: ApexOptions = {
    chart: {
      id: 'volume-chart-area',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      colors: ['#2581e3'],
      width: 3,
      curve: 'smooth',
    },
    noData: value.isLoading
      ? {
          text: 'Loading...',
        }
      : {
          text: 'No data for selection',
        },
    xaxis: {
      categories: value.categories,
      labels: {
        rotateAlways: false,
        rotate: 0,
      },
      tickAmount: 3,
    },
    yaxis: {
      labels: {
        formatter: (val: number): string => {
          return formatPrice({
            pence: val,
            currency: value.data.currency,
          });
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  const series = [
    {
      name: 'total',
      data: value.data.values,
    },
  ];

  return (
    <div>
      <CardCorners
        title='Volume'
        minHeight='120px'
        topRight={
          <>
            Total:{' '}
            {formatPrice({
              pence: value.data.values.reduce((a, b) => a + b, 0),
              currency: value.data.currency,
            })}
          </>
        }
      >
        <>
          <div id='volume-chart-area-section'>
            <Chart
              options={options}
              series={series}
              type='area'
              height='300'
              width='100%'
            />
          </div>
        </>
      </CardCorners>
    </div>
  );
};

export { VolumeChart };
