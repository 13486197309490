import { ReactElement, useRef } from 'react';
import { useGlobalState } from './hooks/useGlobalState';
import { NavAlert } from './components/alerts/NavAlert';
import { NavSignedIn } from './components/nav/SignedIn';
import { SignedOut } from './components/nav/SignedOut';
import { CSSTransition } from 'react-transition-group';
import { LayoutShape } from './interfaces/state';
import { useHistory } from 'react-router-dom';
import { Footer } from './components/layouts/Footer';
import { SendInvite } from './components/layouts/SendInvite';
import { PayMe } from './components/layouts/PayMe';

export interface PropsShape {
  children: ReactElement;
  isSignedIn: boolean;
  state?: LayoutShape;
}

const Layout = ({ children, isSignedIn, state }: PropsShape): ReactElement => {
  const history = useHistory();
  const modalTransition = useRef(null);
  const { layout, setLayout } = useGlobalState();
  const {
    showNavAlert,
    isNavVisible,
    isModalVisible,
    isFooterVisible,
    showFooterAlert,
    showModal,
    isCloseVisible,
  }: LayoutShape = state ? state : layout;

  const closeModal = (goTo?: any): void => {
    if (typeof goTo === 'string' && goTo !== 'TRANSACTIONS')
      history.push(`about/${goTo.toLowerCase()}`);
    if (typeof goTo === 'string' && goTo === 'TRANSACTIONS')
      history.push(`/${goTo.toLowerCase()}`);
    setLayout((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  };

  return (
    <>
      <CSSTransition
        nodeRef={modalTransition}
        in={isModalVisible}
        timeout={300}
        classNames='Layout--ModalTransition'
        unmountOnExit
        onExited={(): void => {
          setLayout((prevState) => ({
            ...prevState,
            showModal: 'NONE',
          }));
        }}
      >
        <div
          ref={modalTransition}
          className='Layout--modal'
          data-testid='showModal'
        >
          {isCloseVisible && (
            <div className='Layout--center'>
              <div className='Layout--modal-close' onClick={closeModal}></div>
            </div>
          )}
          <div
            className='Layout--modal-window'
            onClick={(e: any): void => e.stopPropagation()}
          >
            {showModal === 'INVITE' && <SendInvite />}
            {showModal === 'PAYME' && <PayMe />}
          </div>
        </div>
      </CSSTransition>

      <div className='Layout--screen'>
        <section>
          {showNavAlert != 'NONE' && (
            <div data-testid='showNavAlert'>
              <NavAlert />
            </div>
          )}
        </section>
        <header>
          {isNavVisible && isSignedIn && (
            <div data-testid='showNavSignedIn'>
              <NavSignedIn />
            </div>
          )}
          {isNavVisible && !isSignedIn && (
            <div data-testid='showNavSignedOut'>
              <SignedOut />
            </div>
          )}
        </header>
        <main className='Layout--body'>
          <>{children}</>
          {isFooterVisible && (
            <footer data-testid='isFooterVisible'>
              <Footer
                links={[
                  {
                    heading: 'Support',
                    links: [{ value: '/about/contact', name: 'Contact Us' }],
                  },
                  {
                    heading: 'Legal',
                    links: [
                      // { value: '/about/cookie', name: 'Cookie Policy' },
                      { value: '/about/terms', name: 'Terms and Conditions' },
                      { value: '/about/privacy', name: 'Privacy Policy' },
                    ],
                  },
                ]}
                social={{
                  facebook: 'www.facebook.com/this',
                }}
                apps={{
                  appstore: 'www.facebook.com/this',
                }}
                email=''
                setEmail={(): any => {}}
                copyright={`${new Date().getFullYear()} Ryft. All Rights Reserved.`}
              />
            </footer>
          )}
        </main>
        <section>
          {showFooterAlert != 'NONE' && (
            <div data-testid='showFooterAlert'>Footer Alert</div>
          )}
        </section>
      </div>
    </>
  );
};
export { Layout };
