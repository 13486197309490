import { ReactElement, useEffect } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { Button } from '../../components/forms/Button';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { DateTime } from '../../components/layouts/DateTime';
import { UserProfile } from '../../interfaces/profileState';
import { Copy } from '../../components/forms/Copy';
import { epochSecondsToDate, handleNotSignedIn } from '../../functions/helpers';

const ProfileScreen = (): ReactElement => {
  const history = useHistory();
  const { profileState } = useGlobalDataState();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  const editProfile = (): void => {
    history.push(`/profile/edit/`);
  };

  const fullName = (user: UserProfile): string => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.firstName || user.lastName || '';
  };

  return (
    <CardBlank>
      {!profileState.data && (
        <SettingsItem
          border
          title='Oops!'
          subtitle={'An unexpected error occurred'}
        ></SettingsItem>
      )}
      {profileState.data && (
        <>
          <SettingsItem
            border
            title='Your Profile'
            subtitle={<>All about you</>}
          >
            <CardBlank>
              <>
                <SettingsItem
                  inner
                  title='Name'
                  subtitle={`${fullName(profileState.data.user) || '-'}`}
                >
                  <Button
                    margin='0'
                    name='Edit'
                    click={(): any => editProfile()}
                  />
                </SettingsItem>
                <SettingsItem
                  inner
                  title='Email'
                  subtitle={profileState.data.user.email}
                ></SettingsItem>
                <SettingsItem
                  inner
                  title='User Id'
                  subtitle={
                    <Copy
                      type='BUTTON_WITH_TEXT'
                      text={profileState.data.user?.id}
                      value={profileState.data.user?.id}
                    ></Copy>
                  }
                ></SettingsItem>
                <SettingsItem
                  inner
                  title='Telephone'
                  subtitle={`${profileState.data.user?.phoneNumber || '-'}`}
                ></SettingsItem>
                <SettingsItem
                  inner
                  title='Role'
                  subtitle={`${profileState.data.user?.role}`}
                ></SettingsItem>
                <SettingsItem
                  inner
                  title='Member since'
                  subtitle={
                    <>
                      {' '}
                      <DateTime
                        showTime
                        value={epochSecondsToDate(
                          profileState.data.user.registeredTimestamp
                        )}
                      />
                    </>
                  }
                ></SettingsItem>
              </>
            </CardBlank>
          </SettingsItem>
        </>
      )}
    </CardBlank>
  );
};
export { ProfileScreen };
