import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { get } from '../../../functions/callApi';
import { isValidHttpsUrl } from '../../../functions/validators';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { handleNotSignedIn } from '../../../functions/helpers';
import { SelectCheckBox } from '../../../components/forms/SelectCheckBox';
import { OptionShape } from '../../../interfaces/state';

const AddWebhookRoute = (): ReactElement => {
  const history = useHistory();
  const returnTo = '/developer/webhooks';
  const { isSignedIn } = useGlobalState();
  const { webhooksState, profileState } = useGlobalDataState();
  const [serverError, setServerError] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [eventTypesError, setEventTypesError] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [active, setActive] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const allEventTypes: OptionShape[] = (
    webhooksState.data?.eventTypes || []
  ).map((eventType: string) => ({ name: eventType, value: eventType }));

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!webhooksState?.data || !permissions.has('DeveloperSettingsModify')) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (url && eventTypes) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [url, eventTypes]);

  const addWebhook = async (): Promise<void> => {
    setServerError('');
    setUrlError('');
    setEventTypesError('');
    let validated;
    const isValidUrl = isValidHttpsUrl(url);
    validated = isValidUrl;
    if (!isValidUrl) {
      setUrlError(
        `The URL you have provided is not valid. Please check if is well formed and has protocol 'https'`
      );
    }
    if (eventTypes.length <= 0) {
      validated = false;
      setEventTypesError(
        'Webhook must have at least one type of event. Please check you have selected one or more events.'
      );
    }
    if (validated) {
      setIsAdding(true);
      const apiUrl = `/v1/developer/webhooks`;
      const body = JSON.stringify({
        url: url,
        active: active,
        eventTypes: eventTypes,
      });
      try {
        const response = await get(apiUrl, 'POST', body);
        if (response?.status === 200) {
          history.push(returnTo);
        } else {
          setServerError(response.message);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsAdding(false);
      }
    }
  };

  return (
    <>
      <LayoutIllustration
        title={
          <>
            Add a new <span>Webhook</span>
          </>
        }
        subTitle={<>Add a new webhook to receive real-time events</>}
      >
        <form
          style={{ padding: '0 24px' }}
          onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
          }}
        >
          <ServerError error={serverError} />
          <TextInput
            label
            name='URL'
            value={url}
            setValue={setUrl}
            description='The URL at which to receive events (must be https)'
            focus
          />
          <div
            style={{
              color: '#ec3f3f',
              fontSize: '11px',
              position: 'relative',
              top: '-6px',
            }}
          >
            {urlError}
          </div>
          <SelectInput
            label
            name='Status'
            value={active ? 'Active' : 'Disabled'}
            setValue={(v): any => setActive(v === 'Active')}
            options={[
              { value: 'Active', name: 'Active' },
              { value: 'Disabled', name: 'Disabled' },
            ]}
          />
          <SelectCheckBox
            name='Events'
            grid='TWO_COLUMN'
            allValues={allEventTypes}
            selectedValues={eventTypes}
            setSelectedValues={setEventTypes}
            error={eventTypesError}
          />
          <div className='AddWebhookRoute--button-container'>
            <Button
              name='Add Webhook'
              click={addWebhook}
              loading={isAdding}
              disabled={btnDisabled}
            />
            <Button
              name='Cancel'
              click={(): void => history.push(returnTo)}
              color='GREY'
            />
          </div>
        </form>
      </LayoutIllustration>
    </>
  );
};

export { AddWebhookRoute };
