import React, { useState } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { TextInput } from '../../components/forms/TextInput';
import { Button } from '../../components/forms/Button';
import { SuccessMessage } from '../../components/forms/SuccessMessage';
import { Toggle } from '../../components/forms/Toggle';
import { SelectInput } from '../../components/forms/SelectInput';

const EmbeddedV2RyftRoute = (): React.ReactElement => {
  const { isSignedIn } = useGlobalState();
  const [accountId, setAccountId] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('pk_sandbox...');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [usage, setUsage] = useState<string>('');
  const [paymentType, setPaymentType] = useState('');
  const [nameOnCardCollected, setNameOnCardCollected] =
    useState<boolean>(false);
  const [billingAddressCollected, setBillingAddressCollected] =
    useState<boolean>(false);
  const [billingAddressDisplay, setBillingAddressDisplay] =
    useState<string>('hidden');
  const [billingAddressJson, setBillingAddressJson] = useState<string>('');
  const [subscriptionJson, setSubscriptionJson] = useState<string>('');
  const [enabledPaymentMethods, setEnabledPaymentMethods] =
    useState<string>('');
  const [clientSecretInInit, setClientSecretInInit] = useState<boolean>(true);
  const [manuallyHandleActions, setManuallyHandleActions] =
    useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [googlePayOn, setGooglePayOn] = useState<boolean>(false);
  const [googlePayMerchantName, setGooglePayMerchantName] =
    useState<string>('');
  const [googlePayMerchantId, setGooglePayMerchantId] = useState<string>('');
  const [googlePayCountryCode, setGooglePayCountryCode] = useState<string>('');
  const [googlePayButtonHeight, setGooglePayButtonHeight] =
    useState<string>('');
  const [googlePayButtonType, setGooglePayButtonType] = useState<string>('');
  const [googlePayButtonColor, setGooglePayButtonColor] = useState<string>('');

  const [applePayOn, setApplePayOn] = useState<boolean>(false);
  const [applePayMerchantName, setApplePayMerchantName] = useState<string>('');
  const [applePayCountryCode, setApplePayCountryCode] = useState<string>('');
  const [applePayButtonHeight, setApplePayButtonHeight] = useState<string>('');
  const [applePayButtonType, setApplePayButtonType] = useState<string>('');
  const [applePayButtonColor, setApplePayButtonColor] = useState<string>('');

  const [paymentMethodStorageOn, setPaymentMethodStorageOn] =
    useState<boolean>(true);
  const [paymentMethodsJson, setPaymentMethodsJson] = useState<string>('');

  const [customLocalisation, setCustomLocalisation] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('');
  const [cardNumberPlaceholder, setCardNumberPlaceholder] =
    useState<string>('');
  const [expiryMonthPlaceholder, setExpiryMonthPlaceholder] =
    useState<string>('');
  const [expiryYearPlaceholder, setExpiryYearPlaceholder] =
    useState<string>('');
  const [cvvPlaceholder, setCvvPlaceholder] = useState<string>('');
  const [nameOnCardPlaceholder, setNameOnCardPlaceholder] =
    useState<string>('');

  const [customStyles, setCustomStyles] = useState<boolean>(false);
  const [borderRadius, setBorderRadius] = useState<string>('');
  const [backgroundColor, setBackgroundColor] = useState<string>('');
  const [borderColor, setBorderColor] = useState<string>('');
  const [padding, setPadding] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [focusColor, setFocusColor] = useState<string>('');
  const [bodyColor, setBodyColor] = useState<string>('');
  const [dividerColor, setDividerColor] = useState<string>('');
  const [dividerLineColor, setDividerLineColor] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [userFacingError, setUserFacingError] = useState<string>('');
  const [cardValid, setCardValid] = useState<boolean>(false);
  const [paymentMethodValid, setPaymentMethodValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [billingAddressValid, setBillingAddressValid] =
    useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);

  const handleError = (e: any): void => {
    setError(e.detail.error);
  };

  const loadScript = (): void => {
    let embeddedScript: HTMLScriptElement = document.getElementById(
      'ryft-embedded-v2'
    ) as HTMLScriptElement;
    if (embeddedScript) {
      doInit();
      return;
    }
    embeddedScript = document.createElement('script');
    embeddedScript.id = 'ryft-embedded-v2';
    embeddedScript.src = process.env.REACT_APP_EMBEDDED_URL!.replace(
      'v1',
      'v2'
    );
    embeddedScript.onload = function (): void {
      doInit();
    };
    document.head.appendChild(embeddedScript);
  };

  const doInit = (): void => {
    setError('');
    setUserFacingError('');
    setCardValid(false);
    setBillingAddressValid(false);
    setPaymentSuccess(false);
    const initRequest: any = {
      accountId,
      publicKey,
    };
    if (usage) {
      initRequest.usage = usage;
    }
    if (enabledPaymentMethods) {
      initRequest.enabledPaymentMethods = enabledPaymentMethods.split(',');
    }
    if (customLocalisation) {
      initRequest.localisation = {
        language,
        cardNumberPlaceholder,
        expiryMonthPlaceholder,
        expiryYearPlaceholder,
        cvvPlaceholder,
        nameOnCardPlaceholder,
      };
    }
    if (customStyles) {
      initRequest.style = {
        borderRadius,
        backgroundColor,
        borderColor,
        padding,
        color,
        focusColor,
        bodyColor,
        dividerColor,
        dividerLineColor,
      };
    }
    if (billingAddressCollected) {
      initRequest.fieldCollection = {
        billingAddress: {
          display: billingAddressDisplay,
        },
      };
      if (billingAddressJson) {
        initRequest.fieldCollection.billingAddress.value =
          JSON.parse(billingAddressJson);
      }
    }
    if (nameOnCardCollected) {
      if (initRequest.fieldCollection) {
        initRequest.fieldCollection.nameOnCard = true;
      } else {
        initRequest.fieldCollection = {
          nameOnCard: true,
        };
      }
    }
    if (paymentType) {
      initRequest.paymentType = paymentType;
      if (paymentType === 'Recurring' && subscriptionJson) {
        initRequest.subscription = {
          rawJson: subscriptionJson,
        };
      }
    }
    if (clientSecretInInit) {
      initRequest.clientSecret = clientSecret;
    }
    if (manuallyHandleActions) {
      initRequest.manuallyHandleActions = manuallyHandleActions;
    }
    if (paymentMethodStorageOn || paymentMethodsJson) {
      initRequest.customerPaymentMethods = {
        allowStorage: paymentMethodStorageOn,
        rawJson: paymentMethodsJson,
      };
    }
    if (googlePayOn) {
      initRequest.googlePay = {
        merchantName: googlePayMerchantName,
        merchantIdentifier: googlePayMerchantId,
        merchantCountryCode: googlePayCountryCode,
      };
      if (
        googlePayButtonHeight ||
        googlePayButtonType ||
        googlePayButtonColor
      ) {
        initRequest.googlePay.buttonConfiguration = {
          height: googlePayButtonHeight,
          type: googlePayButtonType,
          color: googlePayButtonColor,
        };
      }
    }
    if (applePayOn) {
      initRequest.applePay = {
        merchantName: applePayMerchantName,
        merchantCountryCode: applePayCountryCode,
      };
      if (applePayButtonHeight || applePayButtonType || applePayButtonColor) {
        initRequest.applePay.buttonConfiguration = {
          height: applePayButtonHeight,
          type: applePayButtonType,
          color: applePayButtonColor,
        };
      }
    }
    window.addEventListener('error', handleError);
    // @ts-ignore
    Ryft.init(initRequest);
    // @ts-ignore
    Ryft.addEventHandler('cardValidationChanged', (e: any) => {
      setCardValid(e.isValid);
    });
    // @ts-ignore
    Ryft.addEventHandler('billingAddressValidationChanged', (e: any) => {
      setBillingAddressValid(e.isValid);
    });
    // @ts-ignore
    Ryft.addEventHandler('paymentMethodSelectionChanged', (e: any) => {
      const valid = e.paymentMethod && e.validation.expirationValid;
      setPaymentMethodValid(valid);
    });
    // @ts-ignore
    Ryft.addEventHandler('walletPaymentSessionResult', (e: any) => {
      handlePaymentSessionResult(e.paymentSession);
    });
  };

  const handlePaymentSessionResult = (paymentSession: any): void => {
    if (manuallyHandleActions) {
      handlePaymentSessionResultActionsManually(paymentSession);
      return;
    }
    handlePaymentSessionApprovalOrError(paymentSession);
  };

  const handlePaymentSessionResultActionsManually = (
    paymentSession: any
  ): void => {
    if (paymentSession.requiredAction) {
      // @ts-ignore
      Ryft.handleRequiredAction(paymentSession)
        .then((paymentSession: any) => {
          handlePaymentSessionApprovalOrError(paymentSession);
        })
        .catch((error: string) => {
          setError(error);
          setLoading(false);
        });
    }
    handlePaymentSessionApprovalOrError(paymentSession);
  };

  const handlePaymentSessionApprovalOrError = (paymentSession: any): void => {
    if (
      paymentSession.status === 'Approved' ||
      paymentSession.status === 'Captured'
    ) {
      setPaymentSuccess(true);
    }
    if (paymentSession.lastError) {
      setError(paymentSession.lastError);
      setUserFacingError(
        // @ts-ignore
        Ryft.getUserFacingErrorMessage(paymentSession.lastError)
      );
    }
    setLoading(false);
  };

  const canPay = (): boolean => {
    const ignoreBillingAddress = billingAddressDisplay === 'hidden';
    return (
      (cardValid && (ignoreBillingAddress || billingAddressValid)) ||
      paymentMethodValid
    );
  };

  const checkForm = async (e: any): Promise<void> => {
    e.preventDefault();
    if (!canPay()) {
      return;
    }
    setError('');
    setUserFacingError('');
    setPaymentSuccess(false);
    setLoading(true);
    const attemptPaymentRequest =
      !clientSecretInInit || customerEmail
        ? {
            clientSecret: clientSecretInInit ? undefined : clientSecret,
            customerEmail: customerEmail ? customerEmail : undefined,
          }
        : undefined;
    // @ts-ignore
    Ryft.attemptPayment(attemptPaymentRequest)
      .then((paymentSession: any) => {
        handlePaymentSessionResult(paymentSession);
      })
      .catch((error: unknown) => {
        setError(error instanceof Error ? error.message : String(error));
        setLoading(false);
      });
  };
  const buttonStyle = (): string => {
    if (loading) {
      return 'Ryft--paybutton-loading';
    }
    if (canPay()) {
      return 'Ryft--paybutton';
    }
    return 'Ryft--paybutton-disabled';
  };
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='Ryft'>
        <div className='Ryft--center'>
          <section className='Ryft--payment-session'>
            <TextInput
              label
              name='Account ID'
              value={accountId}
              setValue={setAccountId}
            />
            <TextInput
              label
              name='Public Key'
              value={publicKey}
              setValue={setPublicKey}
            />
            <TextInput
              label
              name='Client Secret'
              value={clientSecret}
              setValue={setClientSecret}
              description='Can be obtained from API: /v1/payment-sessions'
            />
            <div>Where to supply client secret</div>
            <Toggle
              value={clientSecretInInit}
              setValue={setClientSecretInInit}
              onText='Ryft.Init()'
              offtext='Ryft.attemptPayment() - wallet payments unavailable'
              marginBottom={12}
            />
            <SelectInput
              label
              name='Usage'
              value={usage}
              setValue={setUsage}
              description='Whether the SDK is being used for payments or setting up a card without charging the customer, (payment or setupCard)'
              options={[
                { name: 'none', value: '' },
                { name: 'payment', value: 'payment' },
                { name: 'setupCard', value: 'setupCard' },
              ]}
            />
            <Toggle
              value={nameOnCardCollected}
              setValue={setNameOnCardCollected}
              onText='Name on card will be collected'
              offtext='Name on card will not be collected'
              marginBottom={12}
            />
            <Toggle
              value={billingAddressCollected}
              setValue={setBillingAddressCollected}
              onText='Billing address will be collected (if displayed or present)'
              offtext='Billing address will not be collected'
              marginBottom={12}
            />
            {billingAddressCollected && (
              <>
                <SelectInput
                  label
                  name='Billing Address Display'
                  value={billingAddressDisplay}
                  setValue={setBillingAddressDisplay}
                  description='How to display the billing address collection'
                  options={[
                    { name: 'hidden', value: 'hidden' },
                    { name: 'minimum', value: 'minimum' },
                    { name: 'full', value: 'full' },
                  ]}
                />
                <TextInput
                  label
                  name='Billing Address JSON'
                  value={billingAddressJson}
                  setValue={setBillingAddressJson}
                  description='JSON of a billing address to pass to API (if display is hidden) or pre-populate (if display is full/minimum)'
                />
              </>
            )}
            <SelectInput
              label
              name='Payment Type'
              value={paymentType}
              setValue={setPaymentType}
              description='The type of transaction the SDK is being used to facilitate'
              options={[
                { name: 'None', value: '' },
                { name: 'Standard', value: 'Standard' },
                { name: 'Recurring', value: 'Recurring' },
                { name: 'Unscheduled', value: 'Unscheduled' },
              ]}
            />
            {paymentType === 'Recurring' && (
              <TextInput
                label
                name='Subscription JSON'
                value={subscriptionJson}
                setValue={setSubscriptionJson}
                description='The JSON of the subscription belonging to the customer, Can be obtained from API: /v1/subscriptions/{id}'
              />
            )}
            <SelectInput
              label
              name='Enabled Payment Methods'
              value={enabledPaymentMethods}
              setValue={setEnabledPaymentMethods}
              description='The payment methods that the drop-in will show. Must match those on the payment session'
              options={[
                { name: 'Card', value: 'Card' },
                { name: 'Card, PayL8r', value: 'Card,PayL8r' },
              ]}
            />
            <div>Whether to manually handle payment result actions (3ds)</div>
            <Toggle
              value={manuallyHandleActions}
              setValue={setManuallyHandleActions}
              onText='yes'
              offtext='no'
              marginBottom={12}
            />
            <TextInput
              label
              name='Customer Email'
              value={customerEmail}
              setValue={setCustomerEmail}
              description='The email of the customer - required for card payments if not provided when creating the payment session'
            />
            <div>Allow card storage</div>
            <Toggle
              value={paymentMethodStorageOn}
              setValue={setPaymentMethodStorageOn}
              marginBottom={12}
            />
            <TextInput
              label
              name='Payment Methods JSON'
              value={paymentMethodsJson}
              setValue={setPaymentMethodsJson}
              description='The JSON of any payment methods belonging to the customer, Can be obtained from API: /v1/customers/{id}/payment-methods'
            />
            <div>Google Pay</div>
            <Toggle
              value={googlePayOn}
              setValue={setGooglePayOn}
              marginBottom={12}
            />
            {googlePayOn && (
              <>
                <TextInput
                  label
                  name='Merchant Name'
                  value={googlePayMerchantName}
                  setValue={setGooglePayMerchantName}
                />
                <TextInput
                  label
                  name='Merchant ID'
                  value={googlePayMerchantId}
                  setValue={setGooglePayMerchantId}
                />
                <TextInput
                  label
                  name='Country'
                  value={googlePayCountryCode}
                  setValue={setGooglePayCountryCode}
                />
                <TextInput
                  label
                  name='Button Height'
                  value={googlePayButtonHeight}
                  setValue={setGooglePayButtonHeight}
                />
                <SelectInput
                  label
                  name='Button Type'
                  value={googlePayButtonType}
                  setValue={setGooglePayButtonType}
                  options={[
                    { name: 'Book', value: 'book' },
                    { name: 'Buy', value: 'buy' },
                    { name: 'Checkout', value: 'checkout' },
                    { name: 'Donate', value: 'donate' },
                    { name: 'Long', value: 'long' },
                    { name: 'Order', value: 'order' },
                    { name: 'Pay', value: 'pay' },
                    { name: 'Plain', value: 'plain' },
                    { name: 'Short', value: 'short' },
                    { name: 'Subscribe', value: 'subscribe' },
                  ]}
                />
                <SelectInput
                  label
                  name='Button Color'
                  value={googlePayButtonColor}
                  setValue={setGooglePayButtonColor}
                  options={[
                    { name: 'Black', value: 'black' },
                    { name: 'White', value: 'white' },
                  ]}
                />
              </>
            )}
            <div>Apple Pay</div>
            <Toggle
              value={applePayOn}
              setValue={setApplePayOn}
              marginBottom={12}
            />
            {applePayOn && (
              <>
                <TextInput
                  label
                  name='Merchant Name'
                  value={applePayMerchantName}
                  setValue={setApplePayMerchantName}
                />
                <TextInput
                  label
                  name='Country'
                  value={applePayCountryCode}
                  setValue={setApplePayCountryCode}
                />
                <TextInput
                  label
                  name='Button Height'
                  value={applePayButtonHeight}
                  setValue={setApplePayButtonHeight}
                />
                <SelectInput
                  label
                  name='Button Type'
                  value={applePayButtonType}
                  setValue={setApplePayButtonType}
                  options={[
                    { name: 'Add Money', value: 'add-money' },
                    { name: 'Book', value: 'book' },
                    { name: 'Buy', value: 'buy' },
                    { name: 'Checkout', value: 'check-out' },
                    { name: 'Continue', value: 'continue' },
                    { name: 'Contribute', value: 'contribute' },
                    { name: 'Donate', value: 'donate' },
                    { name: 'Order', value: 'order' },
                    { name: 'Plain', value: 'plain' },
                    { name: 'Reload', value: 'reload' },
                    { name: 'Rent', value: 'rent' },
                    { name: 'Set up', value: 'set-up' },
                    { name: 'Subscribe', value: 'subscribe' },
                    { name: 'Support', value: 'support' },
                    { name: 'Tip', value: 'tip' },
                    { name: 'Top up', value: 'top-up' },
                  ]}
                />
                <SelectInput
                  label
                  name='Button Color'
                  value={applePayButtonColor}
                  setValue={setApplePayButtonColor}
                  options={[
                    { name: 'Black', value: 'black' },
                    { name: 'White', value: 'white' },
                    { name: 'White with outline', value: 'white-outline' },
                  ]}
                />
              </>
            )}
            <Toggle
              value={customLocalisation}
              setValue={setCustomLocalisation}
              onText='Localisation customised'
              offtext='Default localisation'
              marginBottom={12}
            />
            {customLocalisation && (
              <>
                <TextInput
                  label
                  name='Language'
                  value={language}
                  setValue={setLanguage}
                />
                <TextInput
                  label
                  name='Card Number Placeholder'
                  value={cardNumberPlaceholder}
                  setValue={setCardNumberPlaceholder}
                />
                <TextInput
                  label
                  name='Expiry Month Placeholder'
                  value={expiryMonthPlaceholder}
                  setValue={setExpiryMonthPlaceholder}
                />
                <TextInput
                  label
                  name='Expiry Year Placeholder'
                  value={expiryYearPlaceholder}
                  setValue={setExpiryYearPlaceholder}
                />
                <TextInput
                  label
                  name='CVV Placeholder'
                  value={cvvPlaceholder}
                  setValue={setCvvPlaceholder}
                />
                <TextInput
                  label
                  name='Name on card Placeholder'
                  value={nameOnCardPlaceholder}
                  setValue={setNameOnCardPlaceholder}
                />
              </>
            )}
            <Toggle
              value={customStyles}
              setValue={setCustomStyles}
              onText='Styles customised'
              offtext='Default styles'
              marginBottom={12}
            />
            {customStyles && (
              <>
                <TextInput
                  label
                  name='Border Radius'
                  value={borderRadius}
                  setValue={setBorderRadius}
                />
                <TextInput
                  label
                  name='Background Color'
                  value={backgroundColor}
                  setValue={setBackgroundColor}
                />
                <TextInput
                  label
                  name='Border Color'
                  value={borderColor}
                  setValue={setBorderColor}
                />
                <TextInput
                  label
                  name='Padding'
                  value={padding}
                  setValue={setPadding}
                />
                <TextInput
                  label
                  name='Color'
                  value={color}
                  setValue={setColor}
                />
                <TextInput
                  label
                  name='Focus Color'
                  value={focusColor}
                  setValue={setFocusColor}
                />
                <TextInput
                  label
                  name='Body Color'
                  value={bodyColor}
                  setValue={setBodyColor}
                />
                <TextInput
                  label
                  name='Divider Color'
                  value={dividerColor}
                  setValue={setDividerColor}
                />
                <TextInput
                  label
                  name='Divider Line Color'
                  value={dividerLineColor}
                  setValue={setDividerLineColor}
                />
              </>
            )}
            <Button name='Initialize Ryft' click={loadScript} />
          </section>
          {paymentSuccess && (
            <div id='ryft-pay-success' className='Ryft--paysuccess'>
              <SuccessMessage message='Payment Successful!' />
            </div>
          )}
          <div className='Ryft--paysection'>
            <form
              id='ryft-pay-form'
              className='Ryft--payform'
              style={{ backgroundColor: bodyColor || 'white' }}
              onSubmit={checkForm}
            >
              <button id='pay-btn' className={buttonStyle()}>
                {paymentType === 'Recurring' ? 'Subscribe' : 'PAY GBP 24.99'}
              </button>
              {error && (
                <div id='ryft-pay-error' className='Ryft--error'>
                  Error: {error}
                </div>
              )}
              {userFacingError && (
                <div id='ryft-pay-error' className='Ryft--error'>
                  User Facing Error: {userFacingError}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { EmbeddedV2RyftRoute };
