import React from 'react';
import {
  getEciDescription,
  getTransactionStatusReasonDescription,
  getTransStatusDescription,
} from '../../hooks/getThreeDsDescriptions';
import { CardScheme } from '../../interfaces/state';
import { Copy } from '../forms/Copy';

interface ThreeDsData {
  eci?: string;
  authenticationFlow?: string;
  version?: string;
  protocolVersion?: string;
  threeDsServerTransactionId?: string;
  dsTransactionId?: string;
  acsTransactionId?: string;
  transactionStatus?: string;
  transactionStatusReason?: string;
  id?: string;
  scheme?: CardScheme;
}

interface ThreeDsDetailProps {
  id?: string;
  data: ThreeDsData;
}

const ThreeDsDetailsTab: React.FC<ThreeDsDetailProps> = ({ data }) => {
  return (
    <div className='ThreeDsTab'>
      <div className='ThreeDsTab--header'></div>
      <div className='ThreeDsTab--middle'>
        {data.threeDsServerTransactionId && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>3DS Transaction Id</div>
              <div>{data.threeDsServerTransactionId}</div>
            </>
          </div>
        )}
        {data.dsTransactionId && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>DS Transaction Id</div>
              <div>{data.dsTransactionId}</div>
            </>
          </div>
        )}
        {data.acsTransactionId && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>ACS Transaction Id</div>
              <div>{data.acsTransactionId}</div>
            </>
          </div>
        )}
        {data.protocolVersion && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>Protocol Version</div>
              <div>{data.protocolVersion}</div>
            </>
          </div>
        )}
        {data.transactionStatus && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>Status</div>
              <div>
                {data.transactionStatus} (
                {getTransStatusDescription(data.transactionStatus)})
              </div>
            </>
          </div>
        )}
        {data.transactionStatusReason && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>Status Reason</div>
              <div>
                {data.transactionStatusReason} (
                {getTransactionStatusReasonDescription(
                  data.transactionStatusReason,
                  data.scheme
                )}
                )
              </div>
            </>
          </div>
        )}
        {data.authenticationFlow && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>Authentication Flow</div>
              <div>{data.authenticationFlow}</div>
            </>
          </div>
        )}
        {data.eci && (
          <div className='ThreeDsTab--middle-item'>
            <>
              <div>ECI</div>
              <div>
                {data.eci} ({getEciDescription(data.eci)})
              </div>
            </>
          </div>
        )}
      </div>
      {data.id && (
        <div className='ThreeDsTab--footer' style={{ marginTop: '12px' }}>
          <div>Id</div>
          <div>
            <Copy
              type='BUTTON_WITH_TEXT'
              buttonSize='MEDIUM'
              value={data.id}
              text={data.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreeDsDetailsTab;
