import { ReactElement } from 'react';

export interface CreateStatusPropsShape {
  title: string;
  color?:
    | 'BLUE'
    | 'GREEN'
    | 'RED'
    | 'LIGHTGREY'
    | 'DARKGREY'
    | 'ORANGE'
    | 'YELLOW';
  icon?:
    | 'TICK'
    | 'MINUS'
    | 'TIME'
    | 'BACK'
    | 'EXCLAMATION'
    | 'CROSS'
    | 'CHECK'
    | 'CANCELLED'
    | 'CALENDAR'
    | 'COG'
    | 'RETURNED'
    | 'CLOCK';
  size?: 'default' | 'mini';
}

const CreateStatus = ({
  title,
  color,
  icon,
  size,
}: CreateStatusPropsShape): ReactElement => {
  const shouldShowText = size !== 'mini';
  return (
    <div
      className={`CreateStatus ${size === 'mini' ? 'CreateStatus--mini' : ''}`}
    >
      <div
        className={`CreateStatus--icon CreateStatus--${color} CreateStatus--${icon}`}
      >
        {shouldShowText && title}
      </div>
    </div>
  );
};
export { CreateStatus };
