import { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../layout-illustration';
import { MetaData, KeyValue } from '../../components/forms/MetaData';
import { Button } from '../../components/forms/Button';
import { useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { useGlobalState } from '../../hooks/useGlobalState';
import {
  CreateCustomerRequest,
  useCreateCustomer,
} from '../../hooks/useCreateCustomer';
import { isEmailNonAsync } from '../../functions/validators';
import { TextInput } from '../../components/forms/TextInput';
import { CheckBox } from '../../components/forms/CheckBox';
import { ServerError } from '../../components/forms/ServerError';
import { handleNotSignedIn } from '../../functions/helpers';
import { PhoneNumber } from '../../components/forms/PhoneNumber';

const CreateCustomerRoute = (): ReactElement => {
  const returnTo = '/customers';
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const createCustomerApi = useCreateCustomer();
  const [email, setEmail] = useState<string>('');
  const [metaData, setMetaData] = useState<KeyValue[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<any>();
  const [homePhoneNumber, setHomePhoneNumber] = useState<any>();
  const [isMetaDataVis, setIsMetaDataVis] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [mobilePhoneNumberError, setMobilePhoneNumberError] =
    useState<string>('');
  const [homePhoneNumberError, setHomePhoneNumberError] = useState<string>('');

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/');
      return;
    } else if (!permissions.has('CustomersModify')) {
      history.push(returnTo);
      return;
    }
  }, [isSignedIn, profile]);

  useEffect(() => {
    createCustomerApi.data && history.push(returnTo);
  }, [createCustomerApi.data]);

  const handleCreateCustomer = async (): Promise<void> => {
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setMobilePhoneNumberError('');
    setHomePhoneNumberError('');
    let valid = true;
    if (!isEmailNonAsync(email)) {
      setEmailError('Email is not valid');
      valid = false;
    }
    if (firstName && (firstName.length < 1 || firstName.length > 80)) {
      setFirstNameError('Must be between 1 and 80 characters');
      valid = false;
    }
    if (lastName && (lastName.length < 1 || lastName.length > 80)) {
      setLastNameError('Must be between 1 and 80 characters');
      valid = false;
    }
    const mobileNumberValid = mobilePhoneNumber?.valid ?? true;
    const homeNumberValid = homePhoneNumber?.valid ?? true;
    if (!mobileNumberValid) {
      setMobilePhoneNumberError('Mobile phone number is invalid');
      valid = false;
    }
    if (!homeNumberValid) {
      setHomePhoneNumberError('Home phone number is invalid');
      valid = false;
    }
    if (!valid) {
      return;
    }
    const requestBody: CreateCustomerRequest = {
      email: email,
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
      homePhoneNumber: homePhoneNumber?.number,
      mobilePhoneNumber: mobilePhoneNumber?.number,
      metadata:
        metaData && metaData.length
          ? Object.fromEntries(metaData.map((item) => [item.key, item.value]))
          : undefined,
    };
    await createCustomerApi.request(requestBody);
  };

  return (
    <LayoutIllustration
      title={<>Create a customer</>}
      subTitle={<>You can add a new customer</>}
    >
      <div style={{ padding: '0 24px' }}>
        {createCustomerApi.error && (
          <ServerError error={createCustomerApi.error} />
        )}
        <TextInput
          label
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          focus
        />
        <TextInput
          label
          name='First Name'
          value={firstName}
          setValue={setFirstName}
          error={firstNameError}
          optional={true}
        />
        <TextInput
          label
          name='Last Name'
          value={lastName}
          setValue={setLastName}
          error={lastNameError}
          optional={true}
        />
        <PhoneNumber
          label
          name='Mobile Phone Number'
          value={mobilePhoneNumber?.number}
          setValue={setMobilePhoneNumber}
          error={mobilePhoneNumberError}
          optional={true}
          international={true}
        />
        <PhoneNumber
          label
          name='Home Phone Number'
          value={homePhoneNumber?.number}
          setValue={setHomePhoneNumber}
          error={homePhoneNumberError}
          optional={true}
          international={true}
        />
        <div>
          <p style={{ margin: '20px 0' }}>Additional options</p>
          <CheckBox
            setValue={setIsMetaDataVis}
            value={isMetaDataVis}
            id='3'
            label='Metadata'
          />
        </div>
        {isMetaDataVis && (
          <MetaData metaData={metaData} setMetaData={setMetaData} optional />
        )}
        <div className='ModifyUserRoute--button-container'>
          <Button
            name='Create Customer'
            click={handleCreateCustomer}
            loading={createCustomerApi.isLoading}
          />
          <Button
            name='Cancel'
            click={(): void => history.push(returnTo)}
            color='GREY'
          />
        </div>
      </div>
    </LayoutIllustration>
  );
};

export { CreateCustomerRoute };
