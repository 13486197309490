import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { useEditPerson } from '../../../hooks/useEditPerson';
import { useDeletePerson } from '../../../hooks/useDeletePerson';
import { PersonShape } from '../../../interfaces/personsState';
import { isEmailNonAsync } from '../../../functions/validators';
import { PhoneNumber } from '../../../components/forms/PhoneNumber';
import { Group } from '../../../components/forms/Group';
import {
  AccountAddressInput,
  EditedAccountAddress,
} from '../../../components/forms/AccountAddressInput';
import { isEditedAddressValid } from '../../../functions/accountHelpers';
import {
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { personName } from '../../../functions/personHelpers';

const EditPersonContactRoute = (): ReactElement => {
  interface EditedDetails {
    email?: string;
    phoneNumber?: string;
    address?: EditedAccountAddress;
  }

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { personsState, profileState } = useGlobalDataState();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [countryError, setCountryError] = useState<string>('');
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [lineOneError, setLineOneError] = useState<string>('');
  const [lineTwoError, setLineTwoError] = useState<string>('');
  const [cityError, setCityError] = useState<string>('');
  const [regionError, setRegionError] = useState<string>('');
  const [editedAddress, setEditedAddress] = useState<EditedAccountAddress>();
  const [editedDetails, setEditedDetails] = useState<EditedDetails>();
  const [detailsDiffer, setDetailsDiffer] = useState<boolean>(true);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const {
    personData: editedPersonData,
    isLoading: IsEditLoading,
    error: editError,
    apiEditPerson,
  } = useEditPerson();
  const {
    personData: deletedPersonData,
    isLoading: IsDeleteLoading,
    error: deleteError,
    apiDeletePerson,
  } = useDeletePerson();

  const person: PersonShape | undefined = personsState?.data?.selected;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !person ||
      person.verification.status === 'PendingVerification' ||
      !permissions.has('PersonsModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (person) {
      setEmail(person.email ?? '');
      setPhoneNumber({ number: person.phoneNumber, valid: true });
    }
  }, [person]);

  useEffect(() => {
    const isEditedEmail = email != (person?.email || '');
    const isEditedPhoneNumber = phoneNumber.number != person?.phoneNumber;
    const isEditedAddress =
      editedAddress?.lineOne != person?.address.lineOne ||
      (editedAddress?.lineTwo &&
        editedAddress.lineTwo.length > 0 &&
        editedAddress.lineTwo != person?.address?.lineTwo) ||
      editedAddress?.city != person?.address?.city ||
      (editedAddress?.region &&
        editedAddress.region.length > 0 &&
        editedAddress.region != person?.address?.region) ||
      editedAddress?.postalCode != person?.address?.postalCode ||
      editedAddress?.country != person?.address?.country;
    const detailsDiffer: boolean =
      isEditedEmail || isEditedPhoneNumber || isEditedAddress;
    setDetailsDiffer(detailsDiffer);
    setEditedDetails({
      email: isEditedEmail ? email : undefined,
      phoneNumber: isEditedPhoneNumber ? phoneNumber.number : undefined,
      address: isEditedAddress ? editedAddress : undefined,
    });
  }, [email, phoneNumber, editedAddress, person]);

  useEffect(() => {
    if (editedPersonData) {
      onEditCompleted(editedPersonData);
    }
  }, [editedPersonData]);

  useEffect(() => {
    deletedPersonData && history.push(returnTo);
  }, [deletedPersonData]);

  const onEditCompleted = (editedPerson: PersonShape): void => {
    if (canEditDocuments(editedPerson)) {
      history.push(
        constructFullPath(
          `/settings/individuals/edit-individual/${person?.id}/docs`,
          isOnboarding,
          returnTo
        )
      );
      return;
    }
    history.push(returnTo);
  };

  const canEditDocuments = (person: PersonShape): boolean =>
    !!person?.verification.requiredDocuments || !!person?.documents;

  const handleUpdate = async (): Promise<void> => {
    if (!person || !editedDetails) {
      return;
    }

    setEmailError('');
    setPhoneNumberError('');

    let valid = true;
    if (!email && person.email) {
      valid = false;
      setEmailError('Email is required');
    }
    if (email) {
      const emailValid = isEmailNonAsync(email);
      if (!emailValid) {
        valid = false;
        setEmailError('Email is invalid');
      }
    }
    if (!phoneNumber.valid) {
      valid = false;
      setPhoneNumberError('Phone number is invalid');
    }
    if (editedDetails.address) {
      const editedAddressValid = isEditedAddressValid(
        editedDetails.address,
        setCountryError,
        setPostalCodeError,
        setLineOneError,
        setLineTwoError,
        setCityError,
        setRegionError
      );
      if (!editedAddressValid) {
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    if (!detailsDiffer) {
      onEditCompleted(person);
      return;
    }

    apiEditPerson(person.id, {
      email: editedDetails.email,
      phoneNumber: editedDetails.phoneNumber,
      address: editedDetails.address,
    });
  };

  return (
    <>
      {!person && <div className='AppRoute--notfound'>Error 404</div>}
      {person && (
        <LayoutIllustration
          title={<>You're editing {personName(person)}</>}
          subTitle={
            <>
              Update {person?.firstName}'s contact details, or choose to remove{' '}
              them entirely from the business
            </>
          }
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={editError || deleteError} />
            <TextInput
              label
              name='Person Id'
              value={person.id}
              type={'String'}
              disabled={true}
            />
            <>
              <TextInput
                label
                name='Email'
                description="This is only used for contact and WON'T update your login details"
                value={email}
                setValue={setEmail}
                error={emailError}
              />
              <PhoneNumber
                label
                name='Phone Number'
                value={phoneNumber.number}
                setValue={setPhoneNumber}
                error={phoneNumberError}
              />
              <Group name='Address'>
                <AccountAddressInput
                  input={person?.address}
                  countryError={countryError}
                  postalCodeError={postalCodeError}
                  lineOneError={lineOneError}
                  lineTwoError={lineTwoError}
                  cityError={cityError}
                  regionError={regionError}
                  setEditedAddress={setEditedAddress}
                />
              </Group>
            </>
            <div className='EditIndividualRoute--button-container'>
              <Button
                name={canEditDocuments(person) ? 'Next' : 'Update'}
                click={handleUpdate}
                loading={!IsDeleteLoading && IsEditLoading}
                disabled={false}
              />
              <Button
                name='Back'
                click={(): void =>
                  history.push(
                    constructFullPath(
                      `/settings/individuals/edit-individual/${person?.id}`,
                      isOnboarding,
                      returnTo
                    )
                  )
                }
                color='GREY'
              />
              {(person?.verification.status === 'NotRequired' ||
                person?.verification.status === 'Required') && (
                <>
                  <div className='EditIndividualRoute--delete'>
                    {!deletedSelected && (
                      <span>
                        I want to remove {person.firstName}.
                        <a
                          onClick={(): any => {
                            setDeleteSelected(true);
                          }}
                        >
                          &nbsp;Delete {person.firstName}
                        </a>
                      </span>
                    )}
                    {deletedSelected && (
                      <div className='EditIndividualRoute--delete-button'>
                        <Button
                          name='Delete'
                          click={(): void => {
                            apiDeletePerson(person.id);
                          }}
                          color='RED'
                          loading={IsDeleteLoading && !IsEditLoading}
                          disabled={!deletedSelected}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditPersonContactRoute };
