import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import folderIcon from '../../images/folder.svg';
import { isMobile } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { SideMenu } from '../layouts/SideMenu';
import { ReportDetail } from './ReportDetail';
import { useListReports } from '../../hooks/useListReports';
import { Report } from '../../interfaces/reportsState';

export interface PropsShape {
  reportCreated: boolean;
  setReportCreated: Dispatch<SetStateAction<boolean>>;
}

const Reports = ({
  reportCreated,
  setReportCreated,
}: PropsShape): ReactElement => {
  const pollingLimit: number = 12;
  const history = useHistory();
  const params = window.location.search.split('=')[1];
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [pollingCount, setPollingCount] = useState<number>(0);
  const { reportsState, setReportsState } = useGlobalDataState();
  const { reports, isLoading, error, listReports } = useListReports();

  const setSelected = (index: any): void => {
    const mobile = isMobile();
    const selected = reportsState?.data?.items[index];
    if (!mobile) {
      setIsOpen(true);
      history.push(`/files?reportId=${selected?.id}`);
    } else history.push(`/files/reports/${selected?.id}`);
    setReportsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected,
      },
    }));
  };

  useEffect(() => {
    params && setIsOpen(true);
  }, []);

  useEffect(() => {
    if (
      reports?.reports.items.some(
        (report: Report) => report.status === 'Generating'
      ) &&
      pollingCount < pollingLimit
    ) {
      setPollingCount(pollingCount + 1);
      setTimeout(() => listReports(), 5000);
    }
  }, [reports]);

  useEffect(() => {
    setPollingCount(0);
    listReports();
  }, []);

  useEffect(() => {
    if (reportCreated) {
      listReports();
      setReportCreated(false);
    }
  }, [reportCreated]);

  useEffect(() => {
    if (reportsState.data?.remoteControl) {
      setIsOpen(false);
      history.push(`/files`);
      const tempReportsState = [...reportsState?.data?.items!];
      const items = tempReportsState.filter(function (el) {
        return el !== reportsState.data?.selected!;
      });

      setReportsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items,
        },
      }));
    }
  }, [reportsState.data?.remoteControl]);

  const doClosed = (): any => {
    history.push(`/files`);
  };
  return (
    <>
      <div className='Reports'>
        {isLoading && !error && !reports && (
          <>
            <div className='Reports--no-results'>
              <h2>Loading</h2>
              <p>Fetching your reports, one second...</p>
            </div>
          </>
        )}
        {!isLoading && error && (
          <>
            <div className='Reports--no-results'>
              <h2>Error</h2>
              <p>{error}</p>
            </div>
          </>
        )}
        {reports && (
          <>
            {reports.reports.items.map((report, index) => (
              <div
                key={index}
                onClick={(): void => setSelected(index)}
                className={
                  reportsState?.data?.selected?.id === report.id
                    ? 'Reports--folder Reports--folder-selected'
                    : 'Reports--folder'
                }
              >
                <div className='Reports--folder-top'>
                  <img src={folderIcon} />
                </div>
                <div className='Reports--folder-bottom'>
                  <div className='Reports--bottom-name'>{report?.name}</div>
                  <div>
                    {report?.fileIds.length > 0 && (
                      <>{report?.fileIds.length}</>
                    )}{' '}
                    {report?.fileIds?.length === 1 && <>File </>}
                    {report?.fileIds?.length! > 1 && <>Files </>}
                    {report?.status !== 'Generated' && <>{report?.status}</>}
                  </div>
                </div>
              </div>
            ))}
            {reports.reports.items.length === 0 && (
              <div className='Reports--no-results'>
                <>
                  <h2>No Reports yet</h2>
                  <p>Create your first report via the button above</p>
                </>
              </div>
            )}
          </>
        )}
      </div>
      <SideMenu
        onClose={doClosed}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position='RIGHT'
        width='REGULAR'
      >
        <ReportDetail folder={reportsState.data?.selected} />
      </SideMenu>
    </>
  );
};
export { Reports };
