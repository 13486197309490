import { ReactElement, useEffect, useState } from 'react';
import { Pagination } from '../layouts/Pagination';
import { isMobile } from '../../functions/helpers';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { PaymentDetailLayout } from '../layouts/PaymentDetailLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { PaymentsShape } from '../../interfaces/paymentState';
import {
  canBeCaptured,
  canBeRefunded,
  canBeVoided,
  refundsSupported,
  voidsAndCapturesSupported,
} from '../../functions/paymentHelpers';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';

interface PaymentDetailProps {
  payment?: PaymentsShape | null;
  hasPrevious: boolean;
  handlePrevious: () => void;
  hasNext: boolean;
  handleNext: () => void;
}

const PaymentDetail = ({
  payment,
  hasPrevious,
  handlePrevious,
  hasNext,
  handleNext,
}: PaymentDetailProps): ReactElement => {
  const history = useHistory();
  const [showRefund, setShowRefund] = useState(false);
  const [showVoid, setShowVoid] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const { profile } = useGlobalState();
  const { profileState } = useGlobalDataState();

  useEffect(() => {
    const paymentCanBeVoided = canBeVoided(payment);
    const paymentCanBeCaptured = canBeCaptured(payment);
    const voidsAndCapturesCurrentlySupported = voidsAndCapturesSupported(
      payment,
      profile
    );

    const paymentCanBeRefunded = canBeRefunded(payment);
    const refundsCurrentlySupported = refundsSupported(
      payment,
      profile,
      profileState
    );

    setShowVoid(paymentCanBeVoided && voidsAndCapturesCurrentlySupported);
    setShowCapture(paymentCanBeCaptured && voidsAndCapturesCurrentlySupported);
    setShowRefund(paymentCanBeRefunded && refundsCurrentlySupported);
  }, [profile, payment]);

  return (
    <div className='PaymentDetail'>
      <div className='PaymentDetail--top'>
        <div className='PaymentDetail--title-container'>
          <div className='PaymentDetail--title'>Payment</div>
          {!isMobile() && <div className='PaymentDetail--esc'>Esc</div>}
        </div>
        {!isMobile() && (
          <div className='PaymentDetail--pagination'>
            <Pagination
              labels
              goPrevious={(): any => handlePrevious()}
              goNext={(): any => handleNext()}
              previousDisabled={!payment || !hasPrevious}
              nextDisabled={!payment || !hasNext}
            />
          </div>
        )}
        <div className='PaymentDetail--data-container'>
          {payment && (
            <PaymentDetailLayout
              data={payment}
              isStandardAccount={profile.group === 'STANDARD'}
              accountId={profile.accountId}
            />
          )}
        </div>
      </div>
      <div className='PaymentDetail--middle'></div>
      <div className='PaymentDetail--bottom'>
        <div className='PaymentDetail--buttons'>
          {isMobile() && (
            <Button
              name='Back'
              color='GREY'
              click={(): any => history.push(`/payments`)}
            />
          )}
          {showCapture && (
            <Button
              name='Capture Payment'
              click={(): void =>
                history.push(`/payments/${payment?.id}/captures`)
              }
            />
          )}
          {showVoid && (
            <Button
              name='Void Payment'
              click={(): void => history.push(`/payments/${payment?.id}/voids`)}
            />
          )}
          {showRefund && (
            <Button
              name='Refund Payment'
              click={(): void =>
                history.push(`/payments/${payment?.id}/refunds`)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { PaymentDetail };
