import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { Button } from '../forms/Button';
import { PhoneNumber } from '../forms/PhoneNumber';
import { ServerError } from '../forms/ServerError';
import { isPasswordNonAsync } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Password } from '../forms/Password';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const JoinUser = (): ReactElement => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const { cognito } = useGlobalState();
  const [password, setPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const joinUser = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (btnDisabled) {
      return;
    }
    setIsLoading(true);
    const clientMetadata = {
      userInviteLink: queryParams?.l,
    };
    const email = queryParams?.ue;
    Sentry.addBreadcrumb({
      category: 'signup',
      message: `clientMetadata: ${JSON.stringify(clientMetadata)}`,
      level: 'info',
    });
    Sentry.addBreadcrumb({
      category: 'signup',
      message: `history search: ${history.location.search}`,
      level: 'info',
    });
    const result = await cognito.join(
      email,
      password,
      email,
      clientMetadata,
      phoneNumber.number
    );
    setIsLoading(false);
    localStorage.setItem('email', email || '');
    if (result) history.push('/invite/confirm');
  };

  useEffect(() => {
    const enableButton =
      phoneNumber.number &&
      phoneNumber.valid &&
      password &&
      isPasswordNonAsync(password);
    setBtnDisabled(!enableButton);
  }, [phoneNumber, password]);

  useEffect(() => {
    setServerError(cognito.error);
  }, [cognito.error]);

  return (
    <div className='Join'>
      <ServerError error={serverError} />
      <form onSubmit={joinUser}>
        <StepPosition steps={2} position={1} />
        <PhoneNumber
          name='Phone Number'
          value={phoneNumber.number}
          setValue={setPhoneNumber}
        />
        <input
          type='text'
          style={{
            display: 'none',
          }}
        ></input>
        <Password
          name='Password'
          value={password}
          setValue={setPassword}
          placeHolder='Enter a memorable password'
          strong
          autoComplete='new-password'
        />
        <Button
          name='Accept Invitation'
          type={'submit'}
          loading={isLoading}
          disabled={btnDisabled}
        />
      </form>
      <div className='Join--small'>
        By joining you are agreeing to our{' '}
        <a onClick={(): void => history.push(`/about/terms`)}>Terms</a> and{' '}
        <a onClick={(): void => history.push(`/about/privacy`)}>
          Privacy Policy
        </a>
      </div>
      <div
        onClick={(): void => history.push(`/signin`)}
        className='Join--center'
      >
        Already have an account? <a>Sign in</a>
      </div>
    </div>
  );
};
export { JoinUser };
