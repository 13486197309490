import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { DatePicker } from '../forms/DatePicker';
import { TextInput } from '../forms/TextInput';
import { SelectInput } from '../forms/SelectInput';
import { epochSecondsToDate } from '../../functions/helpers';
import { SubAccountFilter } from '../../hooks/useGetSubAccounts';
import { useHistory } from 'react-router-dom';

interface FilterSubAccountsProps {
  getSubAccounts: (filter?: SubAccountFilter) => void;
}

const FilterSubAccounts = ({
  getSubAccounts,
}: FilterSubAccountsProps): ReactElement => {
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [ascending, setAscending] = useState<boolean>();
  const [startsAfter, setStartsAfter] = useState<string>('');
  const [startTimestamp, setStartTimestamp] = useState<string>('');
  const [endTimestamp, setEndTimestamp] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [subAccountId, setSubAccountId] = useState<string>(
    searchParams.get('id') || ''
  );
  const [cleared, setCleared] = useState<boolean>(false);

  const handleApplyFilter = (): void => {
    const filter: SubAccountFilter = {
      ascending: ascending,
      subAccountsStartAfter: startsAfter,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      subAccountId: subAccountId,
      email: customerEmail,
    };
    getSubAccounts(filter);
  };

  useEffect(() => {
    // call API on change but not on page load
    if (
      ascending !== undefined ||
      startsAfter ||
      startTimestamp ||
      endTimestamp ||
      subAccountId ||
      customerEmail.length > 3 ||
      cleared
    ) {
      setCleared(false);
      handleApplyFilter();
    }
  }, [
    ascending,
    startsAfter,
    startTimestamp,
    endTimestamp,
    subAccountId,
    customerEmail,
    cleared,
  ]);

  const handleClearFilter = (): void => {
    setAscending(undefined);
    setStartsAfter('');
    setStartTimestamp('');
    setEndTimestamp('');
    setSubAccountId('');
    setCustomerEmail('');
    history.push('/accounts');
    setCleared(true);
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <DatePicker
        type='START'
        name='Start Date'
        value={parseInt(startTimestamp)}
        notAfter={
          endTimestamp ? epochSecondsToDate(parseInt(endTimestamp)) : new Date()
        }
        label
        setValue={(e): void => {
          if (e) {
            setStartTimestamp(e.toString());
          }
        }}
      />

      <DatePicker
        label
        type='END'
        name='End Date'
        value={parseInt(endTimestamp)}
        notBefore={
          startTimestamp
            ? epochSecondsToDate(parseInt(startTimestamp))
            : undefined
        }
        notAfter={new Date()}
        setValue={(e): void => {
          if (e) {
            setEndTimestamp(e.toString());
          }
        }}
      />
      <TextInput
        name='Sub-account id'
        label
        value={subAccountId}
        setValue={setSubAccountId}
        placeHolder='ac_'
      />
      <TextInput
        name='Sub-account email'
        label
        value={customerEmail}
        setValue={setCustomerEmail}
      />
      <h2
        style={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
        }}
      >
        Sort order
      </h2>
      <SelectInput
        name='Sort Order'
        value={ascending ? 'true' : 'false'}
        setValue={(e): void => setAscending(e === 'true')}
        options={[
          { value: 'true', name: 'Ascending' },
          { value: 'false', name: 'Descending' },
        ]}
      />

      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};
export { FilterSubAccounts };
