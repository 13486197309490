import { ReactElement, useEffect, useState, useRef } from 'react';
import { CardCorners } from '../layouts/CardCorners';
import { request } from '../../functions/callApi';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Button } from '../forms/Button';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { Copy } from '../forms/Copy';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { ApplePayCertificatesAdd } from './ApplePayCertificatesAdd';
import { ApplePayCertificatesDelete } from './ApplePayCertificatesDelete';

const ApplePayCertificates = (): ReactElement => {
  const history = useHistory();
  const { applePayCertificateState, setApplePayCertificateState } =
    useGlobalDataState();
  const scrollContainer = useRef<null | HTMLDivElement>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [applePayCertificatesChange, setApplePayCertificatesChange] =
    useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const applePayCertificatesMaximumCount = 3;

  const [deleteId, setDeleteId] = useState<string>('');

  useEffect(() => {
    applePayCertificatesChange && getApplePayCertificates();
  }, [applePayCertificatesChange]);

  const getApplePayCertificates = async (): Promise<void> => {
    setLoading(true);
    const apiUrl = '/v1/developer/apple-pay/certificates';
    try {
      const response = await request<any>(apiUrl);
      setLoading(false);
      if (response?.status === 200) {
        const items = response.items;
        setApplePayCertificateState((prevState: any) => ({
          ...prevState,
          data: { items },
        }));
      } else {
        // eslint-disable-next-line no-console
        console.log(response.message);
      }
    } catch (err: any) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(err);
    }
    setApplePayCertificatesChange(false);
  };

  const deleteThis = (id: string): void => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setDeleteId(id);
  };

  return (
    <div className='ApplePayCertificates'>
      <CardCorners
        minHeight='120px'
        title='iOS Certificates'
        topRight={
          (applePayCertificateState?.data?.items.length ?? 0) <
          applePayCertificatesMaximumCount ? (
            <Button
              name='Add Certificate'
              size='SMALL'
              color='GREY'
              click={(): void =>
                isMobile()
                  ? history.push(`/developer/apple-pay/ios-certificates/add`)
                  : setIsAddModalOpen(!isAddModalOpen)
              }
            />
          ) : undefined
        }
        bottomLeft={
          <div>
            Showing {applePayCertificateState.data?.items?.length} of {''}
            {applePayCertificatesMaximumCount} iOS Certificates
          </div>
        }
      >
        <>
          {loading && !applePayCertificateState?.data ? (
            <>
              <div className='ApplePayCertificates--no-results'>
                <h2>Loading</h2>
                <p>Fetching your certificates, one second...</p>
              </div>
            </>
          ) : (
            <>
              {applePayCertificateState?.data?.items &&
                applePayCertificateState?.data?.items.length !== 0 && (
                  <div>
                    <div className='ApplePayCertificates--titles'>
                      <div>Certificate</div>
                      <div className='ApplePayCertificates--titles-mobile'>
                        Id
                      </div>
                      <div className='ApplePayCertificates--titles-mobile'>
                        Valid From
                      </div>
                      <div className='ApplePayCertificates--titles-mobile'>
                        Expires
                      </div>
                    </div>
                    <div
                      className='ApplePayCertificates--container'
                      ref={scrollContainer}
                    >
                      {applePayCertificateState?.data?.items?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className='ApplePayCertificates--items'
                          >
                            <div className='ApplePayCertificates--items-item'>
                              {item.merchantIdentifier}
                            </div>
                            <div className='ApplePayCertificates--items-item ApplePayCertificates--items-item-mobile'>
                              <Copy
                                type='BUTTON_WITH_TEXT'
                                buttonSize='MEDIUM'
                                value={item.id}
                                text={item.id}
                              />
                            </div>
                            <div className='ApplePayCertificates--items-item ApplePayCertificates--items-item-mobile'>
                              <DateTime
                                showTime
                                value={epochSecondsToDate(item.validFrom)}
                                alwaysShowYear={true}
                              />
                            </div>
                            <div className='ApplePayCertificates--items-item ApplePayCertificates--items-item-mobile'>
                              <DateTime
                                showTime
                                value={epochSecondsToDate(item.validUntil)}
                                alwaysShowYear={true}
                              />
                            </div>
                            <div
                              className='ApplePayCertificates--items-item ApplePayCertificates--items-item-close'
                              onClick={(): void => {
                                isMobile()
                                  ? history.push(
                                      `/developer/apple-pay/ios-certificates/delete/${item.id}`
                                    )
                                  : deleteThis(item.id);
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              {applePayCertificateState?.data?.items?.length === 0 && (
                <div className='ApplePayCertificates--no-results'>
                  <>
                    <h2>No iOS certificates yet</h2>
                    <p>We couldn't find any iOS certificates</p>
                  </>
                </div>
              )}
            </>
          )}
          <SideMenu
            isOpen={isAddModalOpen}
            setIsOpen={setIsAddModalOpen}
            position='CENTER'
            width='REGULAR'
          >
            <ApplePayCertificatesAdd
              setIsOpen={setIsAddModalOpen}
              setApplePayCertificatesChange={setApplePayCertificatesChange}
            />
          </SideMenu>
          <SideMenu
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            position='CENTER'
            width='NARROW'
          >
            <ApplePayCertificatesDelete
              id={deleteId}
              setIsOpen={setIsDeleteModalOpen}
              setApplePayCertificatesChange={setApplePayCertificatesChange}
            />
          </SideMenu>
        </>
      </CardCorners>
    </div>
  );
};
export { ApplePayCertificates };
