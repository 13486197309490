import React, { useEffect } from 'react';
import { Layout } from '../../../../layout';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import { ApplePayCertificatesDelete } from '../../../../components/dataGrids/ApplePayCertificatesDelete';
import { useGlobalDataState } from '../../../../hooks/useGlobalDataState';
import { useHistory } from 'react-router-dom';

const ApplePayCertificatesDeleteRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('DeveloperSettingsModify')) {
      history.push('/');
      return;
    }
  }, [profileState]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <ApplePayCertificatesDelete />
    </Layout>
  );
};

export { ApplePayCertificatesDeleteRoute };
