import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import {
  AccountAddressInput,
  EditedAccountAddress,
} from '../../../components/forms/AccountAddressInput';
import { ServerError } from '../../../components/forms/ServerError';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import {
  AccountAddressRequest,
  EditBusinessRequest,
  EditIndividualRequest,
  useEditAccount,
} from '../../../hooks/useEditAccount';
import { AccountShape } from '../../../interfaces/accountState';
import {
  getAddress,
  isEditedAddressValid,
} from '../../../functions/accountHelpers';
import { handleNotSignedIn } from '../../../functions/helpers';

const EditAddressRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountState, profileState } = useGlobalDataState();
  const [countryError, setCountryError] = useState<string>('');
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [lineOneError, setLineOneError] = useState<string>('');
  const [lineTwoError, setLineTwoError] = useState<string>('');
  const [cityError, setCityError] = useState<string>('');
  const [regionError, setRegionError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [editedAddress, setEditedAddress] = useState<EditedAccountAddress>();
  const { accountData, isLoading, error, apiEditAccount } = useEditAccount();

  const account = accountState?.data;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !account ||
      account.verification.status === 'PendingVerification' ||
      !permissions.has('AccountModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    accountData && history.push(returnTo);
  }, [accountData]);

  const getTitle = (account: AccountShape): string =>
    account.entityType === 'Business'
      ? 'Edit Business address'
      : 'Edit Personal address';

  const getSubtitle = (account: AccountShape): string =>
    account.entityType === 'Business'
      ? 'Make changes to the address where your business is registered'
      : 'Make changes to your address';

  useEffect(() => {
    if (!account) {
      return;
    }
    const detailsDiffer = detailsHaveBeenEdited(account, editedAddress);
    setBtnDisabled(!detailsDiffer);
  }, [editedAddress]);

  const detailsHaveBeenEdited = (
    account: AccountShape,
    edited?: EditedAccountAddress
  ): boolean => {
    const address = getAddress(account);
    return (
      edited?.lineOne != address?.lineOne ||
      edited?.lineTwo != address?.lineTwo ||
      edited?.city != address?.city ||
      edited?.region != address?.region ||
      edited?.postalCode != address?.postalCode ||
      edited?.country != address?.country
    );
  };

  const updateAccount = async (): Promise<void> => {
    if (!editedAddress || !account) {
      return;
    }
    const editedAddressValid = isEditedAddressValid(
      editedAddress,
      setCountryError,
      setPostalCodeError,
      setLineOneError,
      setLineTwoError,
      setCityError,
      setRegionError
    );
    if (!editedAddressValid) {
      return;
    }
    const addressRequest: AccountAddressRequest = {
      lineOne: editedAddress.lineOne,
      lineTwo: editedAddress.lineTwo,
      city: editedAddress.city,
      region: editedAddress.region,
      postalCode: editedAddress.postalCode,
      country: editedAddress.country,
    };
    const editBusinessRequest: EditBusinessRequest | undefined =
      account.entityType === 'Business'
        ? {
            registeredAddress: addressRequest,
          }
        : undefined;
    const editIndividualRequest: EditIndividualRequest | undefined =
      account.entityType === 'Individual'
        ? {
            address: addressRequest,
          }
        : undefined;
    apiEditAccount({
      business: editBusinessRequest,
      individual: editIndividualRequest,
    });
  };

  return (
    <>
      {!account && <div className='AppRoute--notfound'>Error 404</div>}
      {account && (
        <LayoutIllustration
          title={<>{getTitle(account)}</>}
          subTitle={<>{getSubtitle(account)}</>}
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={error} />
            <AccountAddressInput
              input={getAddress(account)}
              countryError={countryError}
              postalCodeError={postalCodeError}
              lineOneError={lineOneError}
              lineTwoError={lineTwoError}
              cityError={cityError}
              regionError={regionError}
              setEditedAddress={setEditedAddress}
            />
            <div className='EditWebhookRoute--button-container'>
              <Button
                name='Save'
                click={updateAccount}
                loading={isLoading}
                disabled={btnDisabled}
              />
              <Button
                name='Cancel'
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditAddressRoute };
