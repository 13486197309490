import { ReactElement, useState, ReactChild } from 'react';

export interface PropsShape {
  children: ReactChild[];
  initOpen?: boolean;
}

const Expander = ({ children, initOpen = false }: PropsShape): ReactElement => {
  const [open, setOpen] = useState<boolean>(initOpen);

  return (
    <>
      <div className='Expander-top'>
        <div
          className={open ? 'Expander--toggle-down' : 'Expander--toggle-right'}
          onClick={(): void => setOpen(!open)}
        />
        {children[0]}
      </div>
      {open && <div className='Expander-bottom'>{children[1]}</div>}
    </>
  );
};
export { Expander };
