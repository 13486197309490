import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
import { Customer } from '../interfaces/customersState';

export interface CustomersFilter {
  pageSize: number;
  ascending: boolean;
  customersStartAfter: string;
  startTimestamp: string;
  endTimestamp: string;
  customerId: string;
  email: string;
}

interface ApiResponse {
  items: Customer[];
  paginationToken: string;
}

const useGetCustomers = (): [
  boolean,
  string | undefined,
  (filter: CustomersFilter) => void,
  boolean,
  () => void
] => {
  const { customersState, setCustomersState } = useGlobalDataState();

  const [isMore, setIsMore] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [response, isLoading, apiError, sendRequest] =
    useApiRequest<ApiResponse>();

  const setFilter = (filter: CustomersFilter): void => {
    setAction('applyFilter');
    let url = '/v1/customers';

    if (filter.startTimestamp || filter.endTimestamp) {
      url += `${url.includes('?') ? '&' : '?'}startTimestamp=${
        filter.startTimestamp || 0
      }&endTimestamp=${
        filter.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter.pageSize) {
      url += `${url.includes('?') ? '&' : '?'}pageSize=${filter.pageSize}`;
    }

    if (filter.ascending !== undefined) {
      url += `${url.includes('?') ? '&' : '?'}ascending=${filter.ascending}`;
    }

    if (filter.customerId !== '') {
      url += `${url.includes('?') ? '&' : '?'}customerId=${filter.customerId}`;
    }

    if (filter.email !== '') {
      url += `${url.includes('?') ? '&' : '?'}email=${filter.email}`;
    }

    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('lastCustomersUrl', url);
  };

  const getMore = (): any => {
    setAction('getMore');
    const lastUrl = localStorage.getItem('lastCustomersUrl');
    const lastPaginationToken = localStorage.getItem(
      'lastCustomersPaginationToken'
    );
    if (lastUrl && lastPaginationToken) {
      sendRequest({
        url: `${lastUrl}&customersStartAfter=${lastPaginationToken}`,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    if (response) {
      const paginationToken = response.data.paginationToken;
      localStorage.setItem(
        'lastCustomersPaginationToken',
        paginationToken || ''
      );
      setIsMore(!!paginationToken);
      let updated = response.data.items;
      if (action === 'getMore' && customersState.data?.items) {
        updated = customersState.data.items.concat(updated);
      }
      setCustomersState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: paginationToken,
          items: updated,
        },
      }));
    }
  }, [response]);

  return [isLoading, apiError, setFilter, isMore, getMore];
};

export { useGetCustomers };
