import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { SelectInput } from '../forms/SelectInput';
import { ServerError } from '../forms/ServerError';
import { isEmail, isPassword } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Password } from '../forms/Password';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { entityTypes } from '../../functions/accountHelpers';

const Join = (): ReactElement => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const { cognito } = useGlobalState();
  const [entityType, setEntityType] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [processingFeePercentage, setProcessingFeePercentage] =
    useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const updateEntityType = (entityType: any): void => {
    setEntityType(entityType);
    if (entityType !== '') {
      localStorage.setItem('businessType', entityType);
      localStorage.setItem('accountType', entityTypes[entityType].accountType);
    }
  };

  const join = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (btnDisabled) {
      return;
    }
    setIsLoading(true);
    setEmailError('');
    setPasswordError('');
    setServerError('');
    let valid: boolean = true;
    if (!(await isEmail(email))) {
      setEmailError('Invalid Email');
      valid = false;
      setIsLoading(false);
    }
    if (!(await isPassword(password))) {
      setPasswordError('Invalid Password');
      valid = false;
      setIsLoading(false);
    }
    const accountType = entityTypes[entityType].accountType;
    if (valid) {
      const clientMetadata = {
        accountLink: queryParams?.l,
        accountType: accountType,
        ...(processingFeePercentage !== '' && {
          processingFeePercentage: processingFeePercentage,
        }),
      };
      Sentry.addBreadcrumb({
        category: 'signup',
        message: `clientMetadata: ${JSON.stringify(clientMetadata)}`,
        level: 'info',
      });
      Sentry.addBreadcrumb({
        category: 'signup',
        message: `history search: ${history.location.search}`,
        level: 'info',
      });
      const result = await cognito.join(email, password, email, clientMetadata);
      setIsLoading(false);
      localStorage.setItem('accountType', accountType);
      localStorage.setItem('email', email || '');
      localStorage.setItem('linkRedirectUrl', queryParams?.r || '');
      if (result) history.push('/confirm');
    }
  };

  useEffect(() => {
    if (email && password && entityType) {
      setBtnDisabled(false);
    }
    if (!email || !password || !entityType) {
      setBtnDisabled(true);
    }
  }, [email, password, entityType]);

  useEffect(() => {
    const localEmail = localStorage.getItem('email') || '';
    if (localEmail !== 'undefined') {
      setEmail(localEmail);
    }
  }, []);

  useEffect(() => {
    const queryProcessingFeePercentage = queryParams?.p;
    if (queryProcessingFeePercentage) {
      setProcessingFeePercentage(atob(decodeURI(queryProcessingFeePercentage)));
    }
  }, []);

  useEffect(() => {
    setServerError(cognito.error);
  }, [cognito.error]);

  return (
    <div className='Join'>
      <ServerError error={serverError} />
      <form onSubmit={join}>
        <StepPosition steps={4} position={1} />
        <SelectInput
          name='entity'
          value={entityType}
          setValue={updateEntityType}
          selectPrompt='Please choose an entity type:'
          options={Object.entries(entityTypes).map((v: any) => ({
            value: v[0],
            name: v[1].displayName,
          }))}
        />
        <TextInput
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          description='We will send you an email verification code'
          placeHolder='Your email address'
        />
        <Password
          name='Password'
          value={password}
          setValue={setPassword}
          error={passwordError}
          placeHolder='Enter a memorable password'
          strong
        />

        <Button
          name='Join'
          type={'submit'}
          loading={isLoading}
          disabled={btnDisabled}
        />
      </form>
      <div className='Join--small'>
        By joining you are agreeing to our{' '}
        <a
          onClick={(): void =>
            history.push(`https://ryftpay.com/terms-conditions`)
          }
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          onClick={(): void =>
            history.push(`https://ryftpay.com/privacy-policy`)
          }
        >
          Privacy Policy
        </a>
      </div>
      <div
        onClick={(): void => history.push(`/signin`)}
        className='Join--center'
      >
        Already have an account? <a>Sign in</a>
      </div>
    </div>
  );
};
export { Join };
