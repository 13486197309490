import { ReactElement } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
// import { SelectInput } from '../../components/forms/SelectInput';
// import { TextInput } from '../../components/forms/TextInput';
// import { TextArea } from '../../components/forms/TextArea';
// import { Button } from '../../components/forms/Button';
import { isMobile } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';

const ContactUsRoute = (): ReactElement => {
  const history = useHistory();
  const { setLayout } = useGlobalState();
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [enquiry, setEnquiry] = useState<string>('');
  // const [enquiryError, setEnquiryError] = useState<string>('');
  // const [name, setName] = useState<string>('');
  // const [nameError, setNameError] = useState<string>('');
  // const [email, setEmail] = useState<string>('');
  // const [emailError, setEmailError] = useState<string>('');
  // const [order, setOrder] = useState<string>('');
  // const [orderError] = useState<string>('');
  // const [subject, setSubject] = useState<string>('');
  // const [subjectError, setSubjectError] = useState<string>('');
  // const [message, setMessage] = useState<string>('');
  // const [messageError, setMessageError] = useState<string>('');
  const { isSignedIn } = useGlobalState();

  // const submitForm = (): void => {
  //   setIsLoading(true);
  //   if (!enquiry) setEnquiryError('Please select One');
  //   if (!name) setNameError('Name Required');
  //   if (!email) setEmailError('Email Required');
  //   if (!subject) setSubjectError('Subject Required');
  //   if (!message) setMessageError('Message Required');
  //   // TODO: submit to api and show complete message
  //   setIsLoading(false);
  // };
  const doSignIn = (): void => {
    const mobile = isMobile();
    if (mobile) {
      setLayout((prevState) => ({
        ...prevState,
        showModal: 'NONE',
        isModalVisible: false,
      }));
      history.push(`/signin`);
    } else {
      setLayout((prevState) => ({
        ...prevState,
        showModal: 'SIGNIN',
        isModalVisible: true,
      }));
    }
  };
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='AboutContactRoute'>
        <div className='AboutContactRoute--center'>
          <header>We're here to help.</header>
          <div className='AboutContactRoute--body'>
            <nav className='AboutContactRoute--content'>
              <div className='AboutContactRoute--title'>
                <span className='AboutContactRoute--title-hello'>Hello </span>
                <span>
                  {' '}
                  Have an account? <a onClick={doSignIn}>Sign In</a>
                </span>
              </div>
              <div>
                <p>
                  Email us at{' '}
                  <a href='mailto:support@ryftpay.com'>support@ryftpay.com</a>
                  {/* or fill out the form below. */}
                </p>
                <p>
                  Ryft Customer Service hours are M-F, 9:00am-6:00pm GMT. We do
                  our best to respond to all requests within one business day.
                </p>
              </div>
              {/* <div className='AboutContactRoute--ticket'>
                <div className='AboutContactRoute--ticket-title'>
                  Please select a topic
                </div>
                <SelectInput
                  label
                  name='Enquiry Category'
                  value={enquiry}
                  setValue={setEnquiry}
                  error={enquiryError}
                  options={[
                    { value: 'MyAccount', name: 'My Account' },
                    { value: 'ForgotPassword', name: 'Forgot Password' },
                    { value: 'PaymentEnquiry', name: 'Payment Enquiry' },
                    { value: 'NewFeature', name: 'New Feature Suggestion' },
                    { value: 'EmailChange', name: 'Email Change Request' },
                    { value: 'BankDetails', name: 'Bank Details Issue' },
                    { value: 'PayoutIssue', name: 'Where is my money' },
                    {
                      value: 'PartnershipOpportunities',
                      name: 'Partnership Opportunities',
                    },
                    { value: 'Other', name: 'Other' },
                  ]}
                />
                <TextInput
                  name='Name'
                  value={name}
                  setValue={setName}
                  error={nameError}
                  label
                  placeHolder='Name'
                />
                <TextInput
                  name='Email'
                  value={email}
                  setValue={setEmail}
                  error={emailError}
                  label
                  placeHolder='Email'
                />
                <TextInput
                  name='Order Number'
                  value={order}
                  setValue={setOrder}
                  error={orderError}
                  label
                  placeHolder='Order number if applicable'
                />
                <div className='AboutContactRoute--ticket-title'>Inquiry</div>
                <TextInput
                  name='Subject'
                  value={subject}
                  setValue={setSubject}
                  error={subjectError}
                  label
                  placeHolder='Subject'
                />
                <TextArea
                  name='Message'
                  value={message}
                  setValue={setMessage}
                  error={messageError}
                  label
                />
                <Button
                  name='Send'
                  type={'submit'}
                  loading={isLoading}
                  click={submitForm}
                />
              </div> */}
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { ContactUsRoute };
