import { PaymentTransaction } from '../interfaces/paymentState';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface CapturePaymentRequest {
  amount?: number;
  captureType?: 'NotFinal' | 'Final';
}

export interface CapturePaymentSessionApi {
  isLoading: boolean;
  error: string | undefined;
  data: PaymentTransaction | undefined;
  request: (id: string, body: CapturePaymentRequest) => Promise<void>;
}

const useCapturePaymentSession = (): CapturePaymentSessionApi => {
  const [response, isLoading, error, sendRequest] =
    useApiRequest<PaymentTransaction>();

  const capturePaymentSession = async (
    id: string,
    body: CapturePaymentRequest
  ): Promise<void> => {
    const url = `/v1/payment-sessions/${id}/captures`;
    const method = HttpMethod.POST;
    return await sendRequest({ url, method, body });
  };

  return {
    isLoading,
    error,
    data: response?.data,
    request: capturePaymentSession,
  };
};

export { useCapturePaymentSession };
