import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { request } from '../../functions/callApi';
import { Button } from '../forms/Button';
import { Heading } from '../layouts/Heading';
import { FileInput } from '../forms/FileInput';
import { ServerError } from '../forms/ServerError';
import * as x509 from '@peculiar/x509';
import { X509Certificate } from '@peculiar/x509';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';

export interface PropsShape {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setApplePayCertificatesChange?: Dispatch<SetStateAction<boolean>>;
}

const ApplePayCertificatesAdd = ({
  setIsOpen,
  setApplePayCertificatesChange,
}: PropsShape): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const [csrLoading, setCsrLoading] = useState<boolean>(false);
  const [certificateLoading, setCertificateLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [hasDownloadedCsr, setHasDownloadedCsr] = useState<boolean>(false);
  const [certificatePem, setCertificatePem] = useState<string>();

  useEffect(() => {
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/settings');
    }
  }, [profile]);

  const closeModal = (): void => {
    setIsOpen ? setIsOpen(false) : history.push('/developer/apple-pay');
  };

  const downloadCsr = async (): Promise<void> => {
    setServerError('');
    setCsrLoading(true);
    const apiUrl = '/v1/developer/apple-pay/csr';
    try {
      const response = await request<any>(apiUrl, 'POST');
      setCsrLoading(false);
      if (response.status === 200) {
        setServerError('');
        const blob = new Blob([response.csr], { type: 'application/pkcs10' });
        const element = document.createElement('a');
        element.hidden = true;
        element.href = URL.createObjectURL(blob);
        element.download = 'ryft-ios-certificate.csr';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setHasDownloadedCsr(true);
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      setCsrLoading(false);
      setServerError(err.message);
    }
  };

  const addCert = async (): Promise<void> => {
    setServerError('');
    setCertificateLoading(true);
    const apiUrl = '/v1/developer/apple-pay/certificates';
    try {
      const response = await request<any>(
        apiUrl,
        'POST',
        JSON.stringify({ certificatePem })
      );
      setCertificateLoading(false);
      if (response.status === 200) {
        setApplePayCertificatesChange?.(true);
        closeModal();
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      setCertificateLoading(false);
      setServerError(err.message);
    }
  };

  const validateCertificate = async (
    files: File[],
    setAreFilesValid: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    setServerError('');
    const file = files[0];
    const reader = new FileReader();
    reader.onload = async (e: any): Promise<void> => {
      let cert: X509Certificate;
      try {
        cert = new x509.X509Certificate(e.target.result);
      } catch (err: any) {
        setAreFilesValid(false);
        setServerError(
          'Invalid certificate - please provide a valid .cer file from Apple'
        );
        setCertificatePem('');
        return;
      }
      const expires = Date.parse(`${cert.notAfter}`) / 1000;
      const now = Math.floor(Date.now() / 1000);
      if (now > expires) {
        setAreFilesValid(false);
        setServerError('Certificate has expired');
        setCertificatePem('');
        return;
      }
      const issuerArr = cert.issuer.split('=');
      if (!issuerArr[3].includes('Apple Inc')) {
        setAreFilesValid(false);
        setServerError('Certificate not issued by Apple Inc');
        setCertificatePem('');
        return;
      }
      setAreFilesValid(true);
      setCertificatePem(cert.toString('pem'));
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <div className='ApplePayCertificates--modal-header'>
        <Heading
          title='Add Certificate'
          subtitle='Swap a CSR for a certificate to start processing Apple Pay payments via Ryft on iOS'
        />
      </div>
      {serverError && (
        <div className='ApplePayCertificates--modal-server-error'>
          <ServerError error={serverError} />
        </div>
      )}
      <div className='ApplePayCertificates--modal-body'>
        <div className='ApplePayCertificates--modal-body-side-title'>
          Download
        </div>
        <Button
          name='Download CSR'
          color='GREY'
          click={downloadCsr}
          loading={csrLoading}
        />
      </div>
      <div className='ApplePayCertificates--modal-body'>
        <div className='ApplePayCertificates--modal-body-side-title'>
          Create
        </div>
        <ul>
          <li>
            <span>Login to your Apple Developers account with </span>
            <a
              href='https://developer.apple.com/account/ios/certificate/'
              target='_blank'
              rel='noopener noreferrer'
            >
              this link
            </a>
            .
          </li>
          <li>Select App ID’s then the merchant you want the cert for.</li>
          <li>
            On the merchant ID you have selected, click create certificate and
            upload the CSR.
          </li>
          <li>Activate and Download the certificate</li>
        </ul>
      </div>
      <div className='ApplePayCertificates--modal-body'>
        <div className='ApplePayCertificates--modal-body-side-title'>
          Upload
        </div>

        <FileInput
          validateFiles={validateCertificate}
          multiple={false}
          accept='.cer'
          disabled={!hasDownloadedCsr}
        >
          <Button
            name={certificatePem ? 'Update Certificate' : 'Upload Certificate'}
            color='GREY'
            disabled={!hasDownloadedCsr}
          />
        </FileInput>
      </div>
      <div className='ApplePayCertificates--modal-footer'>
        <Button name='Cancel' color='GREY' click={(): void => closeModal()} />
        <Button
          name='Add'
          click={addCert}
          loading={certificateLoading}
          disabled={!certificatePem}
        />
      </div>
    </div>
  );
};
export { ApplePayCertificatesAdd };
