import IBAN from 'iban';
import {
  BankAccount,
  BankAccountNumberType,
  BankIdType,
} from '../interfaces/payoutMethodsState';
import { Dispatch, SetStateAction } from 'react';
import { PayoutBankAccount } from '../interfaces/payoutsState';
import { pascalCaseToWords } from './helpers';

const validateAccountNumber = (
  accountNumber: string,
  accountNumberType: BankAccountNumberType,
  setAccountNumberError: Dispatch<SetStateAction<string>>
): boolean => {
  switch (accountNumberType) {
    case 'UnitedKingdom':
      if (!/^\d+$/.test(accountNumber)) {
        setAccountNumberError('Account Number can only contain digits');
        return false;
      }
      if (accountNumber.length !== 8) {
        setAccountNumberError('Account Number must contain exactly 8 digits');
        return false;
      }
      return true;
    case 'Iban':
      if (!IBAN.isValid(accountNumber)) {
        setAccountNumberError('IBAN is invalid');
        return false;
      }
      return true;
    case 'UnitedStates':
      if (accountNumber.length < 6 || accountNumber.length > 17) {
        setAccountNumberError(
          'Account Number must contain between 6 and 17 digits'
        );
        return false;
      }
      return true;
  }
};

const validateBankId = (
  bankId: string,
  bankIdType: BankIdType,
  setBankIdError: Dispatch<SetStateAction<string>>
): boolean => {
  switch (bankIdType) {
    case 'SortCode':
      if (!/^\d+$/.test(bankId)) {
        setBankIdError('Sort Code can only contain digits (no hyphens)');
        return false;
      }
      if (bankId.length !== 6) {
        setBankIdError('Sort Code must contain exactly 6 digits');
        return false;
      }
      return true;
    case 'SwiftCode':
      if (bankId.length !== 8 && bankId.length !== 11) {
        setBankIdError('Swift Code must contain 8 or 11 characters');
        return false;
      }
      return true;
    case 'RoutingNumber':
      if (!/^\d+$/.test(bankId)) {
        setBankIdError('Routing Number can only contain digits');
        return false;
      }
      if (bankId.length !== 9) {
        setBankIdError('Routing Number must contain exactly 9 digits');
        return false;
      }
      return true;
  }
};

const obfuscatedAccountNumber = (
  bankAccount: BankAccount | PayoutBankAccount
): string => `****${bankAccount.last4}`;

const getBankIdTypeName = (bankIdType: BankIdType | undefined): string =>
  pascalCaseToWords(bankIdType || '');

const getAccountNumberTypeName = (
  accountNumberType: BankAccountNumberType | undefined
): string => {
  switch (accountNumberType) {
    case 'Iban':
      return 'IBAN';
    default:
      return 'Account Number';
  }
};

export {
  validateAccountNumber,
  validateBankId,
  obfuscatedAccountNumber,
  getBankIdTypeName,
  getAccountNumberTypeName,
};
