import { useEffect } from 'react';
import { UsersShape, usersStateShape } from '../interfaces/userState';
import { useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';

const useGetUsers = (): [boolean, string | undefined] => {
  const { setUsersState } = useGlobalDataState();

  const [response, isLoading, error, sendRequest] = useApiRequest<UsersShape>();

  useEffect(() => {
    if (response && response.data) {
      setUsersState((prevState: usersStateShape) => ({
        ...prevState,
        data: response.data,
      }));
    }
  }, [response]);

  useEffect(() => {
    const url = `/v1/users`;
    sendRequest({ url });
  }, []);

  return [isLoading, error];
};

export { useGetUsers };
