import { useEffect, useState } from 'react';
import { PaymentTransactions } from '../interfaces/paymentState';
import { HttpMethod, useApiRequest } from './useApiRequest';

interface UseListPaymentTransactionsValue {
  transactions: PaymentTransactions;
  isLoading: boolean;
  error?: string;
  listPaymentTransactions: (
    paymentSessionId: string,
    subAccountId?: string
  ) => Promise<void>;
}

function useListPaymentTransactions(): UseListPaymentTransactionsValue {
  const [transactions, setTransactions] = useState<PaymentTransactions>({
    items: [],
  });
  const [response, isLoading, error, sendRequest] =
    useApiRequest<PaymentTransactions>();

  const listPaymentTransactions = async (
    paymentSessionId: string,
    subAccountId?: string
  ): Promise<void> => {
    try {
      const url = subAccountId
        ? `/v1/payment-sessions/${paymentSessionId}/transactions?accountId=${subAccountId}`
        : `/v1/payment-sessions/${paymentSessionId}/transactions`;
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `An error occurred listing payment transactions for ${paymentSessionId}:`,
        e
      );
    }
  };

  useEffect(() => {
    if (response && response?.data) {
      setTransactions(response.data);
    } else {
      setTransactions({
        items: [],
      });
    }
  }, [response]);

  return { transactions, isLoading, error, listPaymentTransactions };
}

export { useListPaymentTransactions };
