import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { ServerError } from '../../../components/forms/ServerError';
import { Warning } from '../../../components/forms/Warning';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import { useEditPerson } from '../../../hooks/useEditPerson';
import { useDeletePerson } from '../../../hooks/useDeletePerson';
import { PersonShape } from '../../../interfaces/personsState';
import { BusinessRole } from '../../../interfaces/accountState';
import { SelectInput } from '../../../components/forms/SelectInput';
import { countries } from '../../../functions/iso3166-alpha2';
import { DateOfBirth } from '../../../components/forms/DateOfBirth';
import SelectTags from '../../../components/forms/SelectTags';
import {
  availableBusinessRoles,
  getLetterOfAuthWarningText,
  personName,
} from '../../../functions/personHelpers';
import {
  arraysAllEqual,
  constructFullPath,
  handleNotSignedIn,
} from '../../../functions/helpers';
import { RequiredField } from '../../../interfaces/accountState';
import { SelectCheckBox } from '../../../components/forms/SelectCheckBox';

const EditPersonRoute = (): ReactElement => {
  interface EditedDetails {
    businessRoles?: BusinessRole[];
    firstName?: string;
    middleNames?: string;
    lastName?: string;
    dateOfBirth?: string;
    countryOfBirth?: string;
    gender?: string;
    nationalities?: string[];
  }

  const countryOfBirthFieldName: string = 'countryOfBirth';

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { personsState, profileState } = useGlobalDataState();
  const [businessRoles, setBusinessRoles] = useState<BusinessRole[]>([]);
  const [businessRolesError, setBusinessRolesError] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [middleNames, setMiddleNames] = useState<string>('');
  const [middleNamesError, setMiddleNamesError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [countryOfBirth, setCountryOfBirth] = useState<string>('');
  const [countryOfBirthError, setCountryOfBirthError] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [nationalities, setNationalities] = useState<any[]>([]);
  const [nationalitiesError, setNationalitiesError] = useState<string>('');
  const [showLetterOfAuthWarning, setShowLetterOfAuthWarning] =
    useState<boolean>(false);
  const [editedDetails, setEditedDetails] = useState<EditedDetails>();
  const [detailsDiffer, setDetailsDiffer] = useState<boolean>(true);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const {
    personData: editedPersonData,
    isLoading: IsEditLoading,
    error: editError,
    apiEditPerson,
  } = useEditPerson();
  const {
    personData: deletedPersonData,
    isLoading: IsDeleteLoading,
    error: deleteError,
    apiDeletePerson,
  } = useDeletePerson();

  const person: PersonShape | undefined = personsState?.data?.selected;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (
      !person ||
      person.verification.status === 'PendingVerification' ||
      !permissions.has('PersonsModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (person) {
      setBusinessRoles(person.businessRoles);
      setFirstName(person.firstName);
      setMiddleNames(person.middleNames ?? '');
      setLastName(person.lastName);
      setDateOfBirth(person.dateOfBirth);
      setCountryOfBirth(person.countryOfBirth ?? '');
      setGender(person.gender ?? '');
      setNationalities(person.nationalities ?? []);
    }
  }, [person]);

  useEffect(() => {
    const businessRolesSet = new Set(businessRoles);
    setShowLetterOfAuthWarning(
      businessRolesSet.has('BusinessContact') &&
        !businessRolesSet.has('Director')
    );
  }, [businessRoles, person]);

  useEffect(() => {
    const isEditedBusinessRoles = !arraysAllEqual(
      businessRoles,
      person?.businessRoles
    );
    const isEditedFirstName = firstName != person?.firstName;
    const isEditedMiddleNames =
      middleNames.length > 0 && middleNames != person?.middleNames;
    const isEditedLastName = lastName != person?.lastName;
    const isEditedDateOfBirth = dateOfBirth != person?.dateOfBirth;
    const isEditedCountryOfBirth =
      countryOfBirth.length > 0 && countryOfBirth != person?.countryOfBirth;
    const isEditedGender = gender.length > 0 && gender != person?.gender;
    const isEditedNationalities = !arraysAllEqual(
      nationalities,
      person?.nationalities
    );
    const detailsDiffer: boolean =
      isEditedBusinessRoles ||
      isEditedFirstName ||
      isEditedMiddleNames ||
      isEditedLastName ||
      isEditedDateOfBirth ||
      isEditedCountryOfBirth ||
      isEditedGender ||
      isEditedNationalities;
    setDetailsDiffer(detailsDiffer);
    setEditedDetails({
      businessRoles: isEditedBusinessRoles ? businessRoles : undefined,
      firstName: isEditedFirstName ? firstName : undefined,
      middleNames: isEditedMiddleNames ? middleNames : undefined,
      lastName: isEditedLastName ? lastName : undefined,
      dateOfBirth: isEditedDateOfBirth ? dateOfBirth : undefined,
      countryOfBirth: isEditedCountryOfBirth ? countryOfBirth : undefined,
      gender: isEditedGender ? gender : undefined,
      nationalities: isEditedNationalities ? nationalities : undefined,
    });
  }, [
    businessRoles,
    firstName,
    middleNames,
    lastName,
    dateOfBirth,
    countryOfBirth,
    gender,
    nationalities,
    person,
  ]);

  useEffect(() => {
    editedPersonData && nextPage();
  }, [editedPersonData]);

  useEffect(() => {
    deletedPersonData && history.push(returnTo);
  }, [deletedPersonData]);

  const isFieldRequiredForVerification = (fieldName: string): boolean =>
    person?.verification.requiredFields?.some(
      (field: RequiredField) => field.name === fieldName
    ) === true;

  const nextPage = (): void => {
    history.push(
      constructFullPath(
        `/settings/individuals/edit-individual/${person?.id}/contact`,
        isOnboarding,
        returnTo
      )
    );
  };

  const handleUpdate = async (): Promise<void> => {
    if (!person || !editedDetails) {
      return;
    }

    setBusinessRolesError('');
    setFirstNameError('');
    setMiddleNamesError('');
    setLastNameError('');
    setDateOfBirthError('');
    setCountryOfBirthError('');
    setGenderError('');
    setNationalitiesError('');

    let valid = true;
    if (businessRoles.length < 1) {
      valid = false;
      setBusinessRolesError('You must add at least one business role');
    }
    if (firstName.trim().length < 1) {
      valid = false;
      setFirstNameError('First Name cannot be empty');
    }
    if (lastName.trim().length < 1) {
      valid = false;
      setLastNameError('Last Name cannot be empty');
    }
    if (dateOfBirth.trim().length < 1) {
      valid = false;
      setDateOfBirthError('Date Of Birth cannot be empty');
    }
    if (
      countryOfBirth.trim().length < 1 &&
      isFieldRequiredForVerification(countryOfBirthFieldName)
    ) {
      valid = false;
      setCountryOfBirthError('Country Of Birth is required for verification');
    }
    if (nationalities.length < 1) {
      valid = false;
      setNationalitiesError('You must add at least one nationality');
    }

    if (!valid) {
      return;
    }

    if (!detailsDiffer) {
      nextPage();
      return;
    }

    apiEditPerson(person.id, {
      businessRoles: editedDetails.businessRoles,
      firstName: editedDetails.firstName,
      middleNames: editedDetails.middleNames,
      lastName: editedDetails.lastName,
      dateOfBirth: editedDetails.dateOfBirth,
      countryOfBirth: editedDetails.countryOfBirth,
      gender: editedDetails.gender,
      nationalities: editedDetails.nationalities,
    });
  };

  return (
    <>
      {!person && <div className='AppRoute--notfound'>Error 404</div>}
      {person && (
        <LayoutIllustration
          title={<>You're editing {personName(person)}</>}
          subTitle={
            <>
              Update {person?.firstName}'s personal details, or choose to remove{' '}
              them entirely from the business
            </>
          }
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={editError || deleteError} />
            <TextInput
              label
              name='Person Id'
              value={person.id}
              type={'String'}
              disabled={true}
            />
            <SelectCheckBox
              name='Business roles'
              grid='ONE_COLUMN'
              description='Please select all that apply'
              allValues={availableBusinessRoles(
                isOnboarding,
                personsState?.data?.items,
                person
              )}
              selectedValues={businessRoles}
              setSelectedValues={setBusinessRoles}
              error={businessRolesError}
            />
            <Warning
              warning={getLetterOfAuthWarningText(showLetterOfAuthWarning)}
            />
            <>
              <div
                style={{
                  display: 'flex',
                  gridTemplateColumns: 'auto auto auto',
                  gridGap: '12px',
                  alignItems: 'end',
                }}
              >
                <TextInput
                  label
                  name='Name'
                  value={firstName}
                  placeHolder='First'
                  setValue={setFirstName}
                  error={firstNameError}
                />
                <TextInput
                  name='Middle Name'
                  value={middleNames}
                  placeHolder='Middle'
                  setValue={setMiddleNames}
                  error={middleNamesError}
                  optional
                />
                <TextInput
                  name='Last Name'
                  value={lastName}
                  placeHolder='Last'
                  setValue={setLastName}
                  error={lastNameError}
                />
              </div>
              <DateOfBirth
                label
                value={dateOfBirth}
                setValue={setDateOfBirth}
                error={dateOfBirthError}
              />
              <SelectInput
                label
                name='Country of birth'
                value={countryOfBirth}
                setValue={setCountryOfBirth}
                options={countries()}
                error={countryOfBirthError}
                optional={
                  !person?.countryOfBirth &&
                  !isFieldRequiredForVerification(countryOfBirthFieldName)
                }
              />
              <SelectInput
                label
                name='Gender at birth'
                description='International regulations require either Female or Male'
                value={gender}
                setValue={setGender}
                selectPrompt='Select...'
                options={[
                  { value: 'Male', name: 'Male' },
                  { value: 'Female', name: 'Female' },
                ]}
                error={genderError}
              />
              <SelectTags
                name='Nationalities'
                description='Please select all that apply'
                selectedArray={nationalities}
                optionsArray={countries()}
                setSelectedArray={setNationalities}
                error={nationalitiesError}
              />
            </>
            <div className='EditIndividualRoute--button-container'>
              <Button
                name={'Next'}
                click={handleUpdate}
                loading={!IsDeleteLoading && IsEditLoading}
                disabled={false}
              />
              <Button
                name={isOnboarding ? 'Skip' : 'Cancel'}
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
              {(person?.verification.status === 'NotRequired' ||
                person?.verification.status === 'Required') && (
                <>
                  <div className='EditIndividualRoute--delete'>
                    {!deletedSelected && (
                      <span>
                        I want to remove {person.firstName}.
                        <a
                          onClick={(): any => {
                            setDeleteSelected(true);
                          }}
                        >
                          &nbsp;Delete {person.firstName}
                        </a>
                      </span>
                    )}
                    {deletedSelected && (
                      <div className='EditIndividualRoute--delete-button'>
                        <Button
                          name='Delete'
                          click={(): void => {
                            apiDeletePerson(person.id);
                          }}
                          color='RED'
                          loading={IsDeleteLoading && !IsEditLoading}
                          disabled={!deletedSelected}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditPersonRoute };
