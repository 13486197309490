import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

function useAdjustUser(): any {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response) {
      setIsComplete(true);
    }
  }, [response]);

  const resendUserInvite = (id: string): void => {
    const url = `/v1/users/${id}/resend-invite`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method });
  };

  const deleteUser = (id: string): void => {
    const url = `/v1/users/${id}`;
    const method = HttpMethod.DELETE;
    sendRequest({ url, method });
  };

  const editUser = (body: any, id: string): void => {
    const url = `/v1/users/${id}`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method, body });
  };

  return [resendUserInvite, deleteUser, editUser, isLoading, isComplete, error];
}

export { useAdjustUser };
