import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/forms/Button';
import { ServerError } from '../../components/forms/ServerError';
import { get } from '../../functions/callApi';
import { useGlobalState } from '../../hooks/useGlobalState';
import { LayoutIllustration } from '../../layout-illustration';
import { handleNotSignedIn } from '../../functions/helpers';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Dispute } from '../../interfaces/disputeState';

const ChallengeDisputeRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState, disputeState } = useGlobalDataState();
  const [serverError, setServerError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispute: Dispute | undefined = disputeState?.data?.selected;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (profile.group && profile.group !== 'STANDARD') {
      return history.push('/');
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!dispute) {
      return history.push('/disputes');
    }
    if (
      dispute.status !== 'Open' ||
      !dispute.evidence ||
      !permissions.has('DisputesModify')
    ) {
      history.push(`/disputes?id=${dispute.id}`);
    }
  }, [isSignedIn, profile]);

  const challengeDispute = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const apiUrl = `/v1/disputes/${dispute?.id}/challenge`;
      const response = await get(apiUrl, 'POST');
      if (response?.status === 200) {
        history.push(`/disputes?id=${dispute?.id}`);
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <LayoutIllustration
        title={
          <>
            Are you <span>sure</span> you’re ready?
          </>
        }
        subTitle={
          <>
            Once you submit your challenge you can no longer update the{' '}
            evidence.
          </>
        }
      >
        <form
          style={{ padding: '0 24px' }}
          onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
          }}
        >
          <ServerError error={serverError} />
          <Button
            name='Submit Now'
            click={challengeDispute}
            loading={isLoading}
          />
          <Button
            name='Cancel'
            click={(): void =>
              history.push(`/disputes?id=${dispute?.id}&tab=Evidence`)
            }
            color='GREY'
          />
        </form>
      </LayoutIllustration>
    </>
  );
};

export { ChallengeDisputeRoute };
