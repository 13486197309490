import {
  Subscription,
  SubscriptionStatus,
} from '../../interfaces/subscriptionState';
import { ReactElement, useState, useEffect, ReactNode } from 'react';
import { CreateStatus } from '../layouts/CreateStatus';
import { useHistory, useLocation } from 'react-router-dom';
import { SideMenu } from '../layouts/SideMenu';
import { Button } from '../forms/Button';
import { DateTime } from '../layouts/DateTime';
import { epochSecondsToDate, formatPrice } from '../../functions/helpers';
import { useGetSubscriptionPayments } from '../../hooks/useGetSubscriptionPayments';
import { useGetCustomerWithPaymentMethodsById } from '../../hooks/useGetCustomerWithPaymentMethodsById';
import { CardPayment } from '../layouts/CardPayment';
import { Copy } from '../forms/Copy';
import { MiniMenu } from '../forms/MiniMenu';
import { UserPermission } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { PaymentsShape } from '../../interfaces/paymentState';
import PaymentMethodTab from '../sharedTabs/PaymentMethod';

interface SubscriptionsProps {
  subscriptionsArr: Subscription[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
}

interface SubscriptionDetailsProps {
  selectedSubscription: Subscription | null;
}

const handleSubscriptionStatus = (
  status: SubscriptionStatus
): ReactElement | undefined => {
  switch (status) {
    case 'Ended':
      return <CreateStatus title='Ended' color='GREEN' icon='TICK' />;
    case 'Active':
      return <CreateStatus title='Active' color='GREEN' icon='TICK' />;
    case 'PastDue':
      return <CreateStatus title='Past Due' color='RED' icon='EXCLAMATION' />;
    case 'Cancelled':
      return <CreateStatus title='Cancelled' color='DARKGREY' icon='MINUS' />;
    case 'Pending':
      return <CreateStatus title='Pending' color='LIGHTGREY' icon='TIME' />;
    case 'Paused':
      return <CreateStatus title='Paused' color='LIGHTGREY' icon='TIME' />;
    default:
      return undefined;
  }
};

const SubscriptionDetails = ({
  selectedSubscription,
}: SubscriptionDetailsProps): ReactElement => {
  function renderShippingDetails(address: {
    firstName?: string;
    lastName?: string;
    lineOne?: string;
    lineTwo?: string;
    city?: string;
    country: string;
    postalCode: string;
    region?: string;
  }): ReactNode {
    const {
      firstName,
      lastName,
      lineOne,
      lineTwo,
      city,
      country,
      postalCode,
      region,
    } = address;
    return (
      <tr>
        <td>Shipping</td>
        <td style={{ padding: '10px' }}>
          {firstName} {lastName} <br />
          {lineOne}
          <br />
          {lineTwo}
          <br />
          {city}
          <br />
          {country}
          <br />
          {postalCode}
          <br />
          {region}
        </td>
      </tr>
    );
  }

  return (
    <div className='SubscriptionTab'>
      <div className='SubscriptionTab--title-grid'></div>
      <table className='SubscriptionTab--table'>
        {selectedSubscription && (
          <tr>
            <td>Status</td>
            <td>{handleSubscriptionStatus(selectedSubscription.status)}</td>
          </tr>
        )}
        {selectedSubscription && (
          <tr>
            <td>Created</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  selectedSubscription.createdTimestamp
                )}
              />
            </td>
          </tr>
        )}
        {selectedSubscription && (
          <tr>
            <td>Start date</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  selectedSubscription.billingDetail.firstCycleStartTimestamp
                )}
              />
            </td>
          </tr>
        )}
        {selectedSubscription?.cancelDetail && (
          <tr>
            <td>Cancelled date</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  selectedSubscription.cancelDetail.cancelledAtTimestamp
                )}
              />
            </td>
          </tr>
        )}
        {selectedSubscription && (
          <tr>
            <td>Statement descriptor</td>
            <td>
              {
                selectedSubscription.paymentSettings.statementDescriptor
                  .descriptor
              }
            </td>
          </tr>
        )}

        {selectedSubscription?.shippingDetails &&
          renderShippingDetails(selectedSubscription.shippingDetails.address)}

        {selectedSubscription?.metadata &&
          Object.entries(selectedSubscription.metadata).map(([key, value]) => (
            <tr className='meta' key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
      </table>
      <div className='SubscriptionTab--title-grid'>
        <div className='SubscriptionTab--title'>Billing </div>
        <div></div>
      </div>
      <table className='SubscriptionTab--table'>
        {selectedSubscription && (
          <tr>
            <td>Amount</td>
            <td>
              {formatPrice({
                pence: selectedSubscription.price.amount || 0,
                currency: selectedSubscription.price.currency,
              })}
            </td>
          </tr>
        )}

        <tr>
          <td>Cycle</td>
          <td>
            {selectedSubscription?.billingDetail.currentCycle} /{' '}
            {selectedSubscription?.billingDetail.totalCycles || '∞'}
          </td>
        </tr>
        {selectedSubscription?.pausePaymentDetail && (
          <>
            <tr>
              <td>
                {selectedSubscription.status === 'Paused' ? 'Paused' : 'Pauses'}
              </td>
              <td>
                <DateTime
                  showTime
                  value={epochSecondsToDate(
                    selectedSubscription.pausePaymentDetail.pausedAtTimestamp
                  )}
                />
              </td>
            </tr>
            {selectedSubscription.pausePaymentDetail.reason && (
              <tr>
                <td>
                  {selectedSubscription.status === 'Paused'
                    ? 'Paused Reason'
                    : 'Pause Reason'}
                </td>
                <td>{selectedSubscription.pausePaymentDetail.reason}</td>
              </tr>
            )}
          </>
        )}
        {selectedSubscription?.billingDetail.nextBillingTimestamp && (
          <tr>
            <td>Next Bill</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  selectedSubscription.billingDetail.nextBillingTimestamp
                )}
              />
            </td>
          </tr>
        )}
        {selectedSubscription && (
          <tr>
            <td>Balance</td>
            <td>
              {formatPrice({
                pence: selectedSubscription.balance.amount || 0,
                currency: selectedSubscription.price.currency,
              })}
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

const Payments = ({
  selectedSubscription,
}: SubscriptionDetailsProps): ReactElement => {
  const [isLoading, payments] = useGetSubscriptionPayments(
    selectedSubscription?.id || null
  );

  return (
    <div className='PaymentsTab'>
      <div className='PaymentsTab--payments-grid'>
        {isLoading === false && !payments.length && <div>No Payments</div>}
        {isLoading === false &&
          payments &&
          payments.map((payment: PaymentsShape, index: number) => (
            <div className='PaymentsTab--payment' key={index}>
              <div className='PaymentsTab--menu'>
                <MiniMenu
                  options={[
                    {
                      value: `payments/${payment.id}`,
                      name: 'View Payment in Transactions',
                    },
                  ]}
                />
              </div>
              <div className='status'>
                <div className='title'>Status</div>
                <div className='value'>{payment.status}</div>
              </div>
              <div className='amount'>
                <div className='title'>Amount</div>
                <div className='value'>
                  {formatPrice({
                    pence: payment.totalAmount || 0,
                    currency: payment.currency,
                  })}
                </div>
              </div>
              <div className='when'>
                <div className='title'>When</div>
                <div className='value'>
                  <DateTime
                    showTime
                    value={epochSecondsToDate(payment.createdTimestamp)}
                  />
                </div>
              </div>
              <div className='descriptor'>
                <div className='title'>Statement Descriptor</div>
                <div className='value'>
                  {payment.statementDescriptor.descriptor} (
                  {payment.statementDescriptor.city})
                </div>
              </div>
              <div className='id'>
                <div className='title'>Id</div>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={payment.id}
                  text={payment.id}
                  textStyle='BOLD'
                />
              </div>
              {payment.paymentMethod?.card && (
                <div className='card'>
                  <CardPayment card={payment.paymentMethod.card} />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

const Customer = ({
  selectedSubscription,
}: SubscriptionDetailsProps): ReactElement => {
  const [isLoading, customer] = useGetCustomerWithPaymentMethodsById(
    selectedSubscription?.customer.id
  );
  return (
    <div className='CustomerTab'>
      {!isLoading && (
        <div className='CustomerTab--grid'>
          <div className='CustomerTab--customer'>
            <div className='CustomerTab--menu'>
              {customer && (
                <MiniMenu
                  options={[
                    {
                      value: `/customers/${customer.id}`,
                      name: 'View Customer',
                    },
                  ]}
                />
              )}
            </div>

            {customer?.firstName && (
              <div className='name'>
                <div className='value'>
                  {customer.firstName} {customer.lastName}
                </div>
              </div>
            )}
            {customer && (
              <div className='email'>
                <div className='title'>Email</div>
                <div className='value'>{customer.email}</div>
              </div>
            )}
            {customer && (
              <div className='joined'>
                <div className='title'>Joined</div>
                <div className='value'>
                  <DateTime
                    showTime
                    value={epochSecondsToDate(customer.createdTimestamp)}
                  />
                </div>
              </div>
            )}
            {customer && (
              <div className='updated'>
                <div className='title'>Last Updated</div>
                <div className='value'>
                  <DateTime
                    showTime
                    value={epochSecondsToDate(customer.lastUpdatedTimestamp)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Subscriptions = ({
  subscriptionsArr,
  isMore,
  getMore,
  isLoading,
}: SubscriptionsProps): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { profileState } = useGlobalDataState();
  const [showEditSubscription, setShowEditSubscription] = useState(false);
  const [activeTab, setActiveTab] = useState('Subscription Details');
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      SubscriptionsModify: setShowEditSubscription,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const handleEdit = (): void => {
    if (selectedSubscription) {
      history.push(`/subscriptions/edit?id=${selectedSubscription.id}`);
    }
  };

  const handlePauseCancel = (): void => {
    if (selectedSubscription) {
      history.push(`/subscriptions/adjust?id=${selectedSubscription.id}`);
    }
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
  };

  const scrollToSelectedItem = (): void => {
    if (selectedSubscription) {
      const selectedItemElement = document.getElementById(
        `subscription-${selectedSubscription.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = subscriptionsArr.findIndex(
      (subscription) => subscription.id === selectedSubscription?.id
    );
    if (currentIndex > 0) {
      handleSelectedSubscription(subscriptionsArr[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = subscriptionsArr.findIndex(
      (subscription) => subscription.id === selectedSubscription?.id
    );
    if (currentIndex < subscriptionsArr.length - 1) {
      handleSelectedSubscription(subscriptionsArr[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedSubscription) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSubscription]);

  const setSelected = (id: string): void => {
    const subscription = subscriptionsArr.find(
      (subscription) => subscription.id === id
    );
    if (subscription) {
      setSelectedSubscription(subscription || null);
    } else {
      history.push(`/subscriptions?id=${id}&filter=true`);
    }
  };

  const handleCloseSideMenu = (): void => {
    setSelectedSubscription(null);
    history.push(`/subscriptions`);
  };

  const handleSelectedSubscription = (id: string): void => {
    setSelected(id);
    history.push(`/subscriptions?id=${id}`);
  };

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedSubscription(null);
    }
  }, [location.search, subscriptionsArr]);

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='Subscriptions'>
      <div className='Subscriptions--heading'>
        <div>Status</div>
        <div>Description</div>
        <div>Amount</div>
        <div>Interval</div>
        <div>Cycle</div>
      </div>
      <div className='Subscriptions--scroll' id='scrollArea'>
        {subscriptionsArr.map((subscription) => (
          <div
            id={`subscription-${subscription.id}`}
            key={subscription.id}
            onClick={(): void => handleSelectedSubscription(subscription.id)}
            className={
              subscription.id === selectedSubscription?.id
                ? 'Subscriptions--item Subscriptions--selected'
                : 'Subscriptions--item'
            }
          >
            <div className='Subscriptions--status'>
              {handleSubscriptionStatus(subscription.status)}
            </div>

            <div className='Subscriptions--txt'>
              {subscription.description || ''}
            </div>
            <div className='Subscriptions--txt'>
              {formatPrice({
                pence: subscription.price.amount || 0,
                currency: subscription.price.currency,
              })}
            </div>
            <div className='Subscriptions--txt'>
              {subscription.price.interval.count}{' '}
              {subscription.price.interval.unit}
            </div>
            <div className='Subscriptions--txt'>
              <DateTime
                value={epochSecondsToDate(
                  subscription.billingDetail.currentCycleStartTimestamp
                )}
              />{' '}
              -{' '}
              <DateTime
                value={epochSecondsToDate(
                  subscription.billingDetail.currentCycleEndTimestamp
                )}
              />
            </div>
          </div>
        ))}
        {isLoading && (
          <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
        )}
      </div>
      <SideMenu
        isOpen={!!selectedSubscription}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div style={{ height: '100%' }}>
          <div className='Subscriptions--title-grid'>
            <div className='Subscriptions--title'>
              {selectedSubscription?.description || 'Subscription'}

              <br />
              <span className='SubscriptionTab--id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={selectedSubscription?.id || ''}
                  text={selectedSubscription?.id || ''}
                  direction='DOWN'
                />
              </span>
            </div>
            <div className='Subscriptions--esc'>Esc</div>
          </div>
          <div className='Subscriptions--nav-grid'>
            <div className='Subscriptions--tab-group'>
              <button
                className={
                  activeTab === 'Subscription Details'
                    ? 'Subscriptions--tab-active'
                    : 'Subscriptions--tab'
                }
                onClick={(): void => handleTabChange('Subscription Details')}
              >
                Subscription Details
              </button>
              <button
                className={
                  activeTab === 'Payments'
                    ? 'Subscriptions--tab-active'
                    : 'Subscriptions--tab'
                }
                onClick={(): void => handleTabChange('Payments')}
              >
                Payments
              </button>
              <button
                className={
                  activeTab === 'Customer'
                    ? 'Subscriptions--tab-active'
                    : 'Subscriptions--tab'
                }
                onClick={(): void => handleTabChange('Customer')}
              >
                Customer
              </button>
              <button
                className={
                  activeTab === 'Payment Method'
                    ? 'Subscriptions--tab-active'
                    : 'Subscriptions--tab'
                }
                onClick={(): void => handleTabChange('Payment Method')}
              >
                Payment Method
              </button>
            </div>
            <div>
              <button
                className='Subscriptions--btn-back'
                onClick={handleBack}
                disabled={
                  !selectedSubscription ||
                  subscriptionsArr.findIndex(
                    (subscription) =>
                      subscription.id === selectedSubscription?.id
                  ) === 0
                }
              />

              <button
                className='Subscriptions--btn-next'
                onClick={handleNext}
                disabled={
                  !selectedSubscription ||
                  subscriptionsArr.findIndex(
                    (subscription) =>
                      subscription.id === selectedSubscription?.id
                  ) ===
                    subscriptionsArr.length - 1
                }
              />
            </div>
          </div>

          <div className='Subscriptions--display-grid'>
            <div>
              {activeTab === 'Subscription Details' && (
                <SubscriptionDetails
                  selectedSubscription={selectedSubscription}
                />
              )}
              {activeTab === 'Payments' && (
                <Payments selectedSubscription={selectedSubscription} />
              )}
              {activeTab === 'Customer' && (
                <Customer selectedSubscription={selectedSubscription} />
              )}
              {activeTab === 'Payment Method' && (
                <PaymentMethodTab
                  id={selectedSubscription?.paymentMethod?.id}
                />
              )}
            </div>

            <div className='Subscriptions--btn-group'>
              {showEditSubscription &&
                selectedSubscription &&
                ['Active', 'Pending', 'PastDue'].includes(
                  selectedSubscription.status
                ) && <Button name='Edit' click={(): void => handleEdit()} />}

              {showEditSubscription &&
                selectedSubscription &&
                ['Active', 'Paused', 'Pending', 'PastDue'].includes(
                  selectedSubscription.status
                ) && (
                  <Button
                    color='GREY'
                    name='Adjust'
                    click={(): void => handlePauseCancel()}
                  />
                )}
            </div>
          </div>
        </div>
      </SideMenu>
    </div>
  );
};

export { Subscriptions };
