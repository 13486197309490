import { ReactElement, useEffect, useState } from 'react';
import { Card } from '../layouts/Card';
import {
  formatPrice,
  monthFromDate,
  dateFromDate,
  timeFromDate,
  epochSecondsToDate,
} from '../../functions/helpers';
import { isMobile } from '../../functions/helpers';
import { Currency } from '../../interfaces/state';
import { Balance, PendingShape } from '../../interfaces/balancesState';
import { useGetBalances } from '../../hooks/useGetBalances';

export interface AmountShape {
  currency: Currency;
  amount: string;
}

export interface availableArrShape {
  day: string;
  date: number;
  month: string;
  time: string;
  available: AmountShape[];
}

const Balances = (): ReactElement => {
  const [availableArr, setAvailableArr] = useState<availableArrShape[]>();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const monthArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const { balancesData, isLoading, error, fetchBalances } = useGetBalances();

  useEffect(() => {
    fetchBalances();
  }, []);

  useEffect(() => {
    if (!balancesData) {
      return;
    }
    const val: availableArrShape[] = [];
    const today = {} as availableArrShape;
    const next = {} as availableArrShape;
    const nextNext = {} as availableArrShape;

    const d = epochSecondsToDate(new Date().getTime() / 1000);
    today.day = 'Available Now';
    today.date = dateFromDate(d);
    today.month = monthFromDate(d);
    today.time = timeFromDate(d);
    today.available = [];
    balancesData.balances.items.forEach((item: Balance) => {
      today.available.push({
        currency: item.currency,
        amount: formatPrice({
          pence: +item.available.amount,
          currency: item.currency,
        }) as string,
      });
    });
    const availableTimestamps = Array.from(
      new Set(
        balancesData.balances.items
          .map((balance: Balance) =>
            balance.pending.map(
              (pending: PendingShape) => pending.availableTimestamp
            )
          )
          .flat(1)
      )
    ).sort();
    availableTimestamps &&
      availableTimestamps.length > 0 &&
      availableTimestamps.forEach(
        (availableTimestamp: number, index: number) => {
          const d = epochSecondsToDate(availableTimestamp);
          if (index == 0) {
            next.day = 'On:';
            next.date = dateFromDate(d);
            next.month = monthFromDate(d);
            next.time = timeFromDate(d);
            if (next.available === undefined) next.available = [];
            balancesData.balances.items.forEach((item: Balance) => {
              const nextPending = item.pending.find(
                (pending: PendingShape) =>
                  pending.availableTimestamp === availableTimestamp
              );
              if (nextPending) {
                next.available.push({
                  currency: item.currency,
                  amount: formatPrice({
                    pence: +nextPending.calculation.totalAmount,
                    currency: item.currency,
                  }) as string,
                });
              }
            });
          } else if (index === 1) {
            nextNext.day = 'On:';
            nextNext.date = dateFromDate(d);
            nextNext.month = monthFromDate(d);
            nextNext.time = timeFromDate(d);
            if (nextNext.available === undefined) nextNext.available = [];
            balancesData.balances.items.forEach((item: Balance) => {
              const nextNextPending = item.pending.find(
                (pending: PendingShape) =>
                  pending.availableTimestamp === availableTimestamp
              );
              if (nextNextPending) {
                nextNext.available.push({
                  currency: item.currency,
                  amount: formatPrice({
                    pence: +nextNextPending.calculation.totalAmount,
                    currency: item.currency,
                  }) as string,
                });
              }
            });
          }
        }
      );
    val.push(today);
    Object.keys(next).length !== 0 && val.push(next);
    Object.keys(nextNext).length !== 0 && val.push(nextNext);
    setAvailableArr(val);
  }, [balancesData]);

  return (
    <div className='Balances'>
      <Card>
        <div
          className='Balances--grid'
          style={{
            gridTemplateColumns: `repeat(${
              isMobile() ? 1 : availableArr?.length
            }, 1fr)`,
          }}
        >
          {!availableArr && (
            <div className='Balances--grid-outer'>
              <div className='Balances--loading'>Loading...</div>
            </div>
          )}
          {!availableArr && error && (
            <div className='Balances--grid-outer'>
              <div className='Balances--error'>{error}</div>
            </div>
          )}
          {!isLoading &&
            !error &&
            availableArr &&
            availableArr.map((balance: availableArrShape, index: number) => (
              <div key={`a-${index}`} className='Balances--grid-outer'>
                {index < 3 && (
                  <div className='Balances--item'>
                    <div className='Balances--item-day'>{balance.day}</div>
                    <div className='Balances--item-when'>
                      <div className='Balances--item-date'>{balance.date}</div>
                      <div className='Balances--item-month'>
                        {balance.month}
                      </div>
                      <div className='Balances--item-time'>@{balance.time}</div>
                    </div>
                    <div>
                      {availableArr &&
                        balance.available.map(
                          (available: any, index2: number) => (
                            <div
                              key={`b-${index2}`}
                              className='Balances--amounts'
                            >
                              <div className='Balances--amounts-title'>
                                {available.currency.code} -{' '}
                              </div>
                              <div className='Balances--amounts-title'>
                                {' '}
                                {available.currency.name}
                              </div>
                              <div className='Balances--amounts-value'>
                                {available.amount}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          <div className='Balances--next-update'>
            <span>*</span>Balances will update again: {tomorrow.getDate()}{' '}
            {monthArr[tomorrow.getMonth()]} @ 00:05am
          </div>
        </div>
      </Card>
    </div>
  );
};
export { Balances };
