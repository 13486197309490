import { ReactElement } from 'react';
import { Currency } from '../../interfaces/state';
import { CardCorners } from '../layouts/CardCorners';
import { NetPaymentsVolumeChart } from './charts/NetPaymentsVolumeChart';
import { PaymentsCapturedChart } from './charts/PaymentsCapturedChart';
import { PaymentsRefundedChart } from './charts/PaymentsRefundedChart';

export interface PropsShape {
  isLoading: boolean;
  currency: Currency;
  captured: {
    categories: string[];
    data: number[];
  };
  refunded: {
    categories: string[];
    data: number[];
  };
  netVolume: {
    categories: string[];
    data: number[];
  };
}

const PaymentsChart = ({
  isLoading,
  currency,
  captured,
  refunded,
  netVolume,
}: PropsShape): ReactElement => {
  return (
    <div>
      <CardCorners title='Payments' minHeight='120px'>
        <div className='PaymentsCharts'>
          <PaymentsCapturedChart
            value={{
              isLoading,
              categories: captured.categories,
              data: captured.data,
              currency: currency,
            }}
          />
          <PaymentsRefundedChart
            value={{
              isLoading,
              categories: refunded.categories,
              data: refunded.data,
              currency: currency,
            }}
          />
          <NetPaymentsVolumeChart
            value={{
              isLoading,
              categories: netVolume.categories,
              data: netVolume.data,
              currency: currency,
            }}
          />
        </div>
      </CardCorners>
    </div>
  );
};

export { PaymentsChart };
