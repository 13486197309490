import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalDataState } from './hooks/useGlobalDataState';
import { coBrandingStateShape } from './interfaces/coBrandingState';
import logo from './images/ryft.svg';
import { isMobile } from './functions/helpers';

export interface PropsShape {
  children: ReactElement;
  image?: any;
  title: ReactElement;
  coBrandedTitle?: ReactElement;
  subTitle?: ReactElement;
  maxWidth?: string;
}

const LayoutIllustration = ({
  children,
  image,
  title,
  coBrandedTitle,
  subTitle,
  maxWidth = '35%',
}: PropsShape): any => {
  const history = useHistory();
  const { coBrandingState, setCoBrandingState } = useGlobalDataState();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const queryParams = Object.fromEntries(urlSearchParams);
    if (queryParams && queryParams.pn) {
      setCoBrandingState((prevState: coBrandingStateShape) => ({
        ...prevState,
        data: {
          name: queryParams.pn,
          websiteUrl: queryParams.pw || '',
          brandImageUrl: queryParams.pi || '',
        },
      }));
    }
  }, []);
  return (
    <div
      className='LayoutIllustration'
      style={
        image && !isMobile()
          ? {
              backgroundImage: `url(${image}), linear-gradient(89.73deg, #f1f0f5 10.07%, rgba(241, 240, 245, 0) 103.98%)`,
            }
          : {
              background: `linear-gradient(89.73deg, #f1f0f5 10.07%, rgba(241, 240, 245, 0) 103.98%)`,
            }
      }
    >
      <div className='LayoutIllustration--center'>
        <div className='LayoutIllustration--logo'>
          {coBrandingState?.data?.brandImageUrl && (
            <img src={coBrandingState?.data?.brandImageUrl} alt='Logo' />
          )}
          {!coBrandingState?.data?.brandImageUrl &&
            coBrandingState?.data?.name && <>{coBrandingState?.data?.name}</>}
          {!coBrandingState?.data?.brandImageUrl &&
            !coBrandingState?.data?.name && <img src={logo} alt='Logo' />}
        </div>
        {!coBrandingState?.data?.brandImageUrl &&
          !coBrandingState?.data?.name && (
            <div className='LayoutIllustration--title'>{title}</div>
          )}
        {coBrandingState?.data?.name && (
          <div className='LayoutIllustration--title'>
            {coBrandedTitle || title}
          </div>
        )}
        {subTitle && (
          <div className='LayoutIllustration--sub-title'>{subTitle}</div>
        )}
        <div
          className='LayoutIllustration--sign-in'
          style={{ maxWidth: isMobile() ? '100%' : maxWidth }}
        >
          {children}
        </div>
        {coBrandingState?.data?.name && (
          <div className='LayoutIllustration--powered-by'>Powered by</div>
        )}
      </div>
    </div>
  );
};

export { LayoutIllustration };
