import {
  AccountAddress,
  AccountDocument,
  AccountIndividual,
  AccountShape,
  VerificationStatus,
} from '../interfaces/accountState';
import { EditedAccountAddress } from '../components/forms/AccountAddressInput';
import { Dispatch, SetStateAction } from 'react';
import { PersonShape } from '../interfaces/personsState';
import {
  BulletItemKeyValue,
  BulletItemText,
} from '../components/layouts/Bullets';
import { personName } from './personHelpers';
import { formatBytes, pascalCaseToWords } from './helpers';
import {
  getMaxFileSizeForCategoryName,
  getMinFileSizeForCategoryName,
} from './documentHelpers';

const entityTypes: {
  [key: string]: {
    accountType: 'Individual' | 'Business';
    displayName: string;
  };
} = {
  Individual: {
    accountType: 'Individual',
    displayName: 'Individual',
  },
  SoleTrader: {
    accountType: 'Individual',
    displayName: 'Sole Trader',
  },
  PublicCompany: {
    accountType: 'Business',
    displayName: 'Public Company',
  },
  PrivateCompany: {
    accountType: 'Business',
    displayName: 'Private Limited Company',
  },
  LimitedPartnership: {
    accountType: 'Business',
    displayName: 'Limited Partnership',
  },
  GeneralPartnership: {
    accountType: 'Individual',
    displayName: 'General Partnership',
  },
  Charity: {
    accountType: 'Business',
    displayName: 'Charity',
  },
  GovernmentEntity: {
    accountType: 'Business',
    displayName: 'Government Entity',
  },
};

const getDocuments = (
  account: AccountShape | undefined
): AccountDocument[] | undefined =>
  account?.business?.documents ?? account?.individual?.documents;

const isEditedAddressValid = (
  maybeEditedAddress?: EditedAccountAddress,
  setCountryError?: Dispatch<SetStateAction<string>>,
  setPostalCodeError?: Dispatch<SetStateAction<string>>,
  setLineOneError?: Dispatch<SetStateAction<string>>,
  setLineTwoError?: Dispatch<SetStateAction<string>>,
  setCityError?: Dispatch<SetStateAction<string>>,
  setRegionError?: Dispatch<SetStateAction<string>>
): boolean => {
  const editedAddress: EditedAccountAddress = maybeEditedAddress ?? {
    country: '',
    postalCode: '',
    city: '',
    lineOne: '',
  };
  setCountryError && setCountryError('');
  setPostalCodeError && setPostalCodeError('');
  setRegionError && setRegionError('');
  setCityError && setCityError('');
  setLineOneError && setLineOneError('');
  setLineTwoError && setLineTwoError('');

  let valid = true;
  if (editedAddress.country.trim().length < 1) {
    valid = false;
    setCountryError && setCountryError('Required');
  }
  if (editedAddress.postalCode.trim().length < 1) {
    valid = false;
    setPostalCodeError && setPostalCodeError('Required');
  }
  if (editedAddress.city.trim().length < 1) {
    valid = false;
    setCityError && setCityError('Required');
  }
  if (editedAddress.lineOne.trim().length < 1) {
    valid = false;
    setLineOneError && setLineOneError('Required');
  }
  if (editedAddress.country === 'US' || editedAddress.country === 'CA') {
    if ((editedAddress.region?.trim().length ?? 0) < 1) {
      valid = false;
      setRegionError && setRegionError('Required');
    }
  }
  return valid;
};

const addressToString = (address: AccountAddress): string =>
  [
    address.lineOne,
    address.lineTwo,
    address.city,
    address.region,
    address.postalCode,
    address.country,
  ]
    .filter((s) => s !== undefined)
    .join(', ');

const accountName = (account: AccountShape): string | undefined =>
  account.business?.name ??
  (account.individual ? individualName(account.individual) : undefined);

const accountType = (account: AccountShape): string | undefined =>
  account.business
    ? pascalCaseToWords(account.business.type)
    : account.individual
    ? 'Individual / Sole Trader'
    : undefined;

const accountEmail = (account: AccountShape): string | undefined =>
  account.business?.contactEmail ?? account.individual?.email ?? account.email;

const overallVerificationStatus = (
  account: AccountShape
): VerificationStatus => {
  if (!account.verification.persons) {
    return account.verification.status;
  }
  const statuses = [
    account.verification.status,
    account.verification.persons.status,
  ];
  return statuses.some((s: VerificationStatus) => s === 'Required')
    ? 'Required'
    : statuses.some((s: VerificationStatus) => s === 'PendingVerification')
    ? 'PendingVerification'
    : statuses.some((s: VerificationStatus) => s === 'Verified')
    ? 'Verified'
    : 'NotRequired';
};

const individualName = (individual: AccountIndividual): string =>
  individual.middleNames
    ? `${individual.firstName} ${individual.middleNames} ${individual.lastName}`
    : `${individual.firstName} ${individual.lastName}`;

const getDocumentDataRequirementsTitle = (categoryName: string): string =>
  categoryName === 'Authorization'
    ? 'The letter must include:'
    : 'The documents must match:';

const getDocumentDataRequirements = (
  categoryName: string,
  account?: AccountShape,
  person?: PersonShape
): BulletItemKeyValue[] => {
  switch (categoryName) {
    case 'Business':
      return [
        { key: 'Legal Name', value: account!.business!.name },
        { key: 'Reg No.', value: account!.business!.registrationNumber ?? '' },
        {
          key: 'Address',
          value: addressToString(account!.business!.registeredAddress),
        },
      ];
    case 'Identity':
      return [
        {
          key: 'Name',
          value: account?.individual
            ? individualName(account.individual)
            : personName(person!),
        },
        {
          key: 'Date of Birth',
          value: account?.individual?.dateOfBirth ?? person!.dateOfBirth,
        },
      ];
    case 'Address':
      return [
        {
          key: 'Name',
          value: account?.individual
            ? individualName(account.individual)
            : personName(person!),
        },
        {
          key: 'Address',
          value: addressToString(
            account?.individual?.address ?? person!.address
          ),
        },
      ];
    default:
      return [{ key: 'Your name', value: personName(person!) }];
  }
};

const getDocumentFileRequirementsTitle = (categoryName: string): string =>
  categoryName === 'Authorization'
    ? 'Ensure the letter:'
    : 'Ensure each document:';

const getDocumentFileRequirements = (
  categoryName: string
): BulletItemText[] => {
  const requirements: BulletItemText[] = [];
  if (categoryName === 'Authorization') {
    requirements.push({
      text: 'Is a printed, completed and scanned copy of this pdf template',
      linkUrl: '/verification/LOA_en.pdf',
    });
    requirements.push({ text: 'Has been signed by a legal representative' });
    requirements.push({ text: 'Is legible and the most current document' });
  } else {
    requirements.push({ text: 'Is legible and the most current document' });
    requirements.push({ text: 'Is of .jpg, .jpeg, .png, .pdf format' });
  }
  requirements.push({
    text: `Is not smaller than ${formatBytes(
      getMinFileSizeForCategoryName(categoryName)
    )}`,
  });
  requirements.push({
    text: `Does not exceed a total of ${formatBytes(
      getMaxFileSizeForCategoryName(categoryName)
    )}`,
  });
  return requirements;
};

const getAddress = (account: AccountShape): AccountAddress =>
  account.business?.registeredAddress ?? account.individual!.address!;

const isPendingVerification = (
  verificationStatus: VerificationStatus
): boolean => verificationStatus === 'PendingVerification';

export {
  entityTypes,
  getDocuments,
  isEditedAddressValid,
  addressToString,
  accountName,
  accountType,
  accountEmail,
  overallVerificationStatus,
  individualName,
  getDocumentDataRequirementsTitle,
  getDocumentDataRequirements,
  getDocumentFileRequirementsTitle,
  getDocumentFileRequirements,
  getAddress,
  isPendingVerification,
};
