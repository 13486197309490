import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { Button } from '../../components/forms/Button';
import { useHistory } from 'react-router-dom';
import { PayoutMethod } from '../../interfaces/payoutMethodsState';
import { useGetPayoutMethods } from '../../hooks/useGetPayoutMethods';
import { useGetAccount } from '../../hooks/useGetAccount';
import { UserPermission } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import {
  PayoutSchedule,
  PayoutScheduleType,
} from '../../interfaces/accountState';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';
import { obfuscatedAccountNumber } from '../../functions/payoutMethodHelpers';
import { ServerError } from '../../components/forms/ServerError';

const Banking = (): ReactElement => {
  const history = useHistory();

  const {
    payoutMethods,
    payoutMethodSettings,
    isLoading: isGetPayoutMethodsLoading,
    error: payoutMethodsError,
    fetchPayoutMethods,
  } = useGetPayoutMethods();
  const [showPayoutMode, setShowPayoutMode] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [showEditAccounts, setShowEditAccounts] = useState(false);
  const { profileState, accountState } = useGlobalDataState();
  const [payoutSchedule, setPayoutSchedule] = useState<PayoutSchedule>();
  const {
    accountData,
    isLoading: isGetAccountLoading,
    error: getAccountError,
    fetchAccount,
  } = useGetAccount();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      PayoutsModify: setShowPayoutMode,
      PayoutMethodsView: setShowAccounts,
      PayoutMethodsModify: setShowEditAccounts,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    const payoutSchedule = accountState?.data?.settings.payouts.schedule;
    if (payoutSchedule) {
      setPayoutSchedule(payoutSchedule);
    } else {
      fetchAccount();
    }
  }, [accountState]);

  useEffect(() => {
    fetchPayoutMethods();
  }, []);

  useEffect(() => {
    const payoutSchedule = accountData?.settings.payouts.schedule;
    if (payoutSchedule) {
      setPayoutSchedule(payoutSchedule);
    }
  }, [accountData]);

  const editBankAccount = (item: PayoutMethod): void => {
    history.push(`/settings/banking/edit-account/${item.id}`);
  };

  const payoutScheduleSubTitle = (type: PayoutScheduleType): string => {
    switch (type) {
      case 'Manual':
        return 'You create payments as you need them';
      case 'Automatic':
        return 'Automated daily payouts are made on your behalf';
    }
  };

  const payoutScheduleTitle = (type: PayoutScheduleType): string => {
    switch (type) {
      case 'Manual':
        return 'Manual';
      case 'Automatic':
        return 'Daily';
    }
  };

  const isLoading = (): boolean =>
    isGetPayoutMethodsLoading || isGetAccountLoading;

  const error = (): string => payoutMethodsError || getAccountError;

  return (
    <>
      <CardBlank>
        {isLoading() && (
          <SettingsItem
            border
            title='Loading'
            subtitle={'......'}
          ></SettingsItem>
        )}
        {!isLoading() && error() && (
          <SettingsItem border title='Oops!' subtitle={error()}></SettingsItem>
        )}
        {showAccounts &&
          !isLoading() &&
          !error() &&
          payoutMethods &&
          payoutMethodSettings && (
            <SettingsItem
              border
              title='Accounts'
              subtitle={
                showEditAccounts
                  ? 'Add accounts to be used with payouts. You can add one account for each currency'
                  : 'Ask an account owner to add accounts to be used with payouts. One can be added for each currency'
              }
              button={showEditAccounts ? 'Add Account' : ''}
              buttonClick={(): void =>
                history.push('/settings/banking/add-account')
              }
              buttonDisabled={
                !payoutMethodSettings.enabled ||
                !payoutMethodSettings.supportedCurrencies.length
              }
            >
              <CardBlank>
                {!payoutMethods.length && (
                  <SettingsItem
                    border
                    inner
                    title='No Accounts'
                    subtitle={
                      showEditAccounts
                        ? 'Please add one for each currency so we know where to send your payouts'
                        : 'Please ask an account owner to add one for each currency so we know where to send your payouts'
                    }
                  />
                )}
                {payoutMethods.map((item: PayoutMethod, index: any) => {
                  return (
                    <div key={index}>
                      <SettingsItem
                        border
                        inner
                        title={`${item.displayName} - ${item.currency}`}
                        subtitle={
                          <div className='BankingRoute--account'>
                            Account: {obfuscatedAccountNumber(item.bankAccount)}
                            {item.bankAccount.bankName
                              ?.split('(')[0]
                              .replace(/^/, ' @ ')}
                            {item.status !== 'Valid' && (
                              <div className='BankingRoute--account-invalid'>
                                <ServerError
                                  error={
                                    item.invalidReason ??
                                    'Bank account invalid - please contact us'
                                  }
                                />
                              </div>
                            )}
                          </div>
                        }
                      >
                        {showEditAccounts && (
                          <Button
                            margin='0'
                            name='Edit'
                            click={(): any => editBankAccount(item)}
                          />
                        )}
                      </SettingsItem>
                    </div>
                  );
                })}
              </CardBlank>
            </SettingsItem>
          )}
        {showPayoutMode && !isLoading() && !error() && payoutSchedule && (
          <SettingsItem
            title='Payout Mode'
            subtitle='Would you like to manually create payouts or schedule them daily?'
          >
            <CardBlank>
              <SettingsItem
                border
                inner
                title={payoutScheduleTitle(payoutSchedule.type)}
                subtitle={payoutScheduleSubTitle(payoutSchedule.type)}
              >
                <Button
                  margin='0'
                  name='Change'
                  click={(): void =>
                    history.push('/settings/banking/edit-payment-mode')
                  }
                />
              </SettingsItem>
            </CardBlank>
          </SettingsItem>
        )}
      </CardBlank>
    </>
  );
};
export { Banking };
