import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AboutRoute } from './routes/about/about';
import { AddBankAccountRoute } from './routes/settings/banking/add-account';
import { EditBankAccountRoute } from './routes/settings/banking/edit-account';
import { AlmostRoute } from './routes/almost';
import { TermsUpdatedRoute } from './routes/terms-updated';
import { ApplePayCertificatesAddRoute } from './routes/developers/apple-pay/ios-certificates/add';
import { ApplePayCertificatesDeleteRoute } from './routes/developers/apple-pay/ios-certificates/delete';
import { ApplePayWebDomainsAddRoute } from './routes/developers/apple-pay/web-domains/add';
import { ApplePayWebDomainsDeleteRoute } from './routes/developers/apple-pay/web-domains/delete';
import { AppRoute } from './routes/app';
import { AppsRoute } from './routes/about/apps';
import { AuthorizedRoute } from './routes/authorized';
import { CareersRoute } from './routes/about/careers';
import { ConfirmRoute } from './routes/confirm';
import { ContactRoute } from './routes/contact';
import { ContactUsRoute } from './routes/about/contact';
import { CookieRoute } from './routes/about/cookie';
import { CustomersRoute } from './routes/customers';
import { SubAccountsRoute } from './routes/sub-accounts';
import { SubAccountRoute } from './routes/accounts/sub-accounts';
import { DeveloperRoute } from './routes/developers';
import { EditPayoutModeRoute } from './routes/settings/banking/edit-payout-mode';
import { EmbeddedRyftRoute } from './routes/embedded/embedded';
import { EmbeddedThreeDsRyftRoute } from './routes/embedded/embedded-3ds';
import { PlatformFeeRoute } from './routes/transactions/platform-fee';
import { FilesRoute } from './routes/files';
import { ForgotRoute } from './routes/forgot';
import { GeneralInquiriesRoute } from './routes/about/help-center/general-inquiries';
import { PayoutsRoute } from './routes/payouts';
import { GettingStartedRoute } from './routes/about/help-center/getting-started';
import { GlobalDataState } from './hooks/useGlobalDataState';
import { GlobalState } from './hooks/useGlobalState';
import { HelpRoute } from './routes/about/help';
import { HelpSubAccountsRoute } from './routes/about/help-center/sub-accounts';
import { HomeRoute } from './routes/home';
import { InviteRoute } from './routes/invite';
import { JoinedRoute } from './routes/joined';
import { JoinRoute } from './routes/join';
import { LastRoute } from './routes/last';
import { PasswordRoute } from './routes/password';
import { PaymentRoute } from './routes/transactions/payment';
import { RefundPaymentRoute } from './routes/transactions/payment/refund-payment';
import { PlatformFeesRoute } from './routes/platform-fees';
import { SplitPaymentsRoute } from './routes/split-payments';
import { PaymentsRoute } from './routes/payments';
import { SplitPaymentRoute } from './routes/transactions/split-payment';
import { PayMeRoute } from './routes/pay-me';
import { PayoutRoute } from './routes/payouts/payout';
import { PrivacyRoute } from './routes/about/privacy';
import { ProfileAccountRoute } from './routes/about/help-center/profile-account';
import { ReportRoute } from './routes/files/reports';
import { SettingsRoute } from './routes/settings';
import { ProfileRoute } from './routes/profile';
import { EditProfileRoute } from './routes/profile/profile/edit-profile';
import { TermsRoute } from './routes/about/terms';
import { CustomerRoute } from './routes/customers/customer';
import { DeleteCustomerRoute } from './routes/customers/customer/delete-customer';
import { DeletePaymentMethodRoute } from './routes/customers/customer/paymentMethod/delete-payment-method';
import { CreateSubscriptionRoute } from './routes/subscriptions/create-subscription';
import { EditSubscriptionRoute } from './routes/subscriptions/edit-subscription';
import { AdjustSubscriptionRoute } from './routes/subscriptions/adjust-subscription';
import { SubscriptionRoute } from './routes/subscriptions';
import { DisputesRoute } from './routes/disputes';
import { AddUserRoute } from './routes/settings/users/add-user';
import { EditUserRoute } from './routes/settings/users/edit-user';
import { InviteUserRoute } from './routes/invite-user';
import { ConfirmUserRoute } from './routes/confirm-user';
import { EditWebhookRoute } from './routes/developers/webhooks/edit-webhook';
import { AddWebhookRoute } from './routes/developers/webhooks/add-webhook';
import { ChangePasswordRoute } from './routes/profile/changePassword';
import { CreatePaymentRoute } from './routes/transactions/payment/create-payment';
import { DashboardRoute } from './routes/dashboard';
import { AcceptDisputeRoute } from './routes/disputes/accept-dispute';
import { AddDisputeEvidenceRoute } from './routes/disputes/add-evidence';
import { ChallengeDisputeRoute } from './routes/disputes/challenge-dispute';
import { CreateCustomerRoute } from './routes/customers/create-customer';
import { AddIndividualRoute } from './routes/settings/individuals/add-individual';
import { AddIndividualContactRoute } from './routes/settings/individuals/add-individual-contact';
import { EditPersonRoute } from './routes/settings/individuals/edit-individual';
import { EditPersonContactRoute } from './routes/settings/individuals/edit-individual-contact';
import { EditPersonDocsRoute } from './routes/settings/individuals/edit-individual-docs';
import { EditAccountDocsRoute } from './routes/settings/account/edit-docs';
import { EditAddressRoute } from './routes/settings/account/edit-address';
import { EditBusinessRoute } from './routes/settings/account/edit-business';
import { EditIndividualRoute } from './routes/settings/account/edit-individual';
import { SignInRoute } from './routes/signin';
import { EmbeddedV2RyftRoute } from './routes/embedded/embedded-v2';
import { EmbeddedThreeDsV2RyftRoute } from './routes/embedded/embedded-3ds-v2';
import { VoidPaymentRoute } from './routes/transactions/payment/void-payment';
import { CapturePaymentRoute } from './routes/transactions/payment/capture-payment';
import { AssignRolesRoute } from './routes/settings/individuals/assign-roles';
import { VerifyRoute } from './routes/settings/account/verify';

const Router = (): React.ReactElement => {
  return (
    <GlobalState>
      <GlobalDataState>
        <BrowserRouter>
          <Route
            render={(): React.ReactElement => (
              <Switch>
                <Route exact path='/settings/account/edit-docs'>
                  <EditAccountDocsRoute />
                </Route>
                <Route exact path='/settings/account/edit-address'>
                  <EditAddressRoute />
                </Route>
                <Route exact path='/settings/account/edit-business'>
                  <EditBusinessRoute />
                </Route>
                <Route exact path='/settings/account/edit-individual'>
                  <EditIndividualRoute />
                </Route>
                <Route exact path='/settings/account/verify'>
                  <VerifyRoute />
                </Route>
                <Route
                  exact
                  path='/settings/individuals/add-individual-contact'
                >
                  <AddIndividualContactRoute />
                </Route>
                <Route exact path='/settings/individuals/add-individual'>
                  <AddIndividualRoute />
                </Route>
                <Route exact path='/settings/individuals/assign-roles'>
                  <AssignRolesRoute />
                </Route>
                <Route
                  exact
                  path='/settings/individuals/edit-individual/:personId'
                >
                  <EditPersonRoute />
                </Route>
                <Route
                  exact
                  path='/settings/individuals/edit-individual/:personId/contact'
                >
                  <EditPersonContactRoute />
                </Route>
                <Route
                  exact
                  path='/settings/individuals/edit-individual/:personId/docs'
                >
                  <EditPersonDocsRoute />
                </Route>
                <Route exact path='/change-password'>
                  <ChangePasswordRoute />
                </Route>
                <Route exact path='/dashboard'>
                  <DashboardRoute />
                </Route>
                <Route exact path='/customers/create'>
                  <CreateCustomerRoute />
                </Route>
                <Route exact path='/customers/:customerId'>
                  <CustomerRoute />
                </Route>
                <Route exact path='/customers/:customerId/delete'>
                  <DeleteCustomerRoute />
                </Route>
                <Route
                  exact
                  path='/customers/:customerId/payment-methods/:paymentMethodId/delete'
                >
                  <DeletePaymentMethodRoute />
                </Route>
                <Route exact path='/customers'>
                  <CustomersRoute />
                </Route>
                <Route exact path='/accounts/:subAccountId'>
                  <SubAccountRoute />
                </Route>
                <Route exact path='/accounts'>
                  <SubAccountsRoute />
                </Route>

                <Route exact path='/invite'>
                  <InviteUserRoute />
                </Route>
                <Route exact path='/invite/confirm'>
                  <ConfirmUserRoute />
                </Route>
                <Route exact path='/'>
                  <HomeRoute />
                </Route>
                <Route exact path='/files/reports/:reportId'>
                  <ReportRoute />
                </Route>
                <Route exact path='/payments/create'>
                  <CreatePaymentRoute />
                </Route>
                <Route exact path='/payments/:paymentId/voids'>
                  <VoidPaymentRoute />
                </Route>
                <Route exact path='/payments/:paymentId/captures'>
                  <CapturePaymentRoute />
                </Route>
                <Route exact path='/payments/:paymentId/refunds'>
                  <RefundPaymentRoute />
                </Route>
                <Route exact path='/payments/:paymentId'>
                  <PaymentRoute />
                </Route>
                <Route exact path='/payments'>
                  <PaymentsRoute />
                </Route>
                <Route exact path='/platform-fees/:platformFeeId'>
                  <PlatformFeeRoute />
                </Route>
                <Route exact path='/split-payments/:splitPaymentId'>
                  <SplitPaymentRoute />
                </Route>
                <Route exact path='/payouts/payout/:payoutId'>
                  <PayoutRoute />
                </Route>
                <Route exact path='/pay-me'>
                  <PayMeRoute />
                </Route>
                <Route exact path='/files'>
                  <FilesRoute />
                </Route>
                <Route exact path='/signin'>
                  <SignInRoute />
                </Route>
                <Route exact path='/forgot'>
                  <ForgotRoute />
                </Route>
                <Route exact path='/password'>
                  <PasswordRoute />
                </Route>
                <Route exact path='/join'>
                  <JoinRoute />
                </Route>
                <Route exact path='/almost'>
                  <AlmostRoute />
                </Route>
                <Route exact path='/terms-updated'>
                  <TermsUpdatedRoute />
                </Route>
                <Route exact path='/confirm'>
                  <ConfirmRoute />
                </Route>
                <Route exact path='/last'>
                  <LastRoute />
                </Route>
                <Route exact path='/joined'>
                  <JoinedRoute />
                </Route>
                <Route exact path='/authorized'>
                  <AuthorizedRoute />
                </Route>
                <Route exact path='/payouts'>
                  <PayoutsRoute />
                </Route>
                <Route exact path='/invite'>
                  <InviteRoute />
                </Route>
                <Route exact path='/developer'>
                  <DeveloperRoute />
                </Route>
                <Route exact path='/developer/:menuItemKey'>
                  <DeveloperRoute />
                </Route>
                <Route exact path='/developer/webhooks/add'>
                  <AddWebhookRoute />
                </Route>
                <Route exact path='/developer/webhooks/edit/:id'>
                  <EditWebhookRoute />
                </Route>
                <Route exact path='/developer/apple-pay/ios-certificates/add'>
                  <ApplePayCertificatesAddRoute />
                </Route>
                <Route
                  exact
                  path='/developer/apple-pay/ios-certificates/delete/:id'
                >
                  <ApplePayCertificatesDeleteRoute />
                </Route>
                <Route exact path='/developer/apple-pay/web-domains/add'>
                  <ApplePayWebDomainsAddRoute />
                </Route>
                <Route exact path='/developer/apple-pay/web-domains/delete/:id'>
                  <ApplePayWebDomainsDeleteRoute />
                </Route>

                <Route exact path='/transactions'>
                  <Redirect to='/payments' />
                </Route>
                <Route exact path='/platform-fees'>
                  <PlatformFeesRoute />
                </Route>
                <Route exact path='/split-payments'>
                  <SplitPaymentsRoute />
                </Route>
                <Route exact path='/contact'>
                  <ContactRoute />
                </Route>
                <Route exact path='/settings/:menuItemKey'>
                  <SettingsRoute />
                </Route>
                <Route exact path='/settings'>
                  <SettingsRoute />
                </Route>
                <Route exact path='/profile/edit'>
                  <EditProfileRoute />
                </Route>
                <Route exact path='/profile/:menuItemKey'>
                  <ProfileRoute />
                </Route>
                <Route exact path='/profile'>
                  <ProfileRoute />
                </Route>
                <Route exact path='/settings/users/add-user'>
                  <AddUserRoute />
                </Route>
                <Route exact path='/settings/users/edit-user/:id'>
                  <EditUserRoute />
                </Route>
                <Route exact path='/settings/banking/edit-payment-mode'>
                  <EditPayoutModeRoute />
                </Route>
                <Route exact path='/settings/banking/add-account'>
                  <AddBankAccountRoute />
                </Route>
                <Route exact path='/settings/banking/edit-account/:id'>
                  <EditBankAccountRoute />
                </Route>
                <Route exact path='/settings/banking/verify'>
                  <Redirect to='/settings/account' />
                </Route>

                <Route exact path='/about'>
                  <AboutRoute />
                </Route>
                <Route exact path='/about/careers'>
                  <CareersRoute />
                </Route>
                <Route exact path='/about/privacy'>
                  <PrivacyRoute />
                </Route>
                <Route exact path='/about/terms'>
                  <TermsRoute />
                </Route>
                <Route exact path='/about/apps'>
                  <AppsRoute />
                </Route>
                <Route exact path='/about/help'>
                  <HelpRoute />
                </Route>
                <Route exact path='/about/contact'>
                  <ContactUsRoute />
                </Route>
                <Route exact path='/about/cookie'>
                  <CookieRoute />
                </Route>
                <Route exact path='/about/help-center/getting-started'>
                  <GettingStartedRoute />
                </Route>
                <Route exact path='/about/help-center/general-inquiry'>
                  <GeneralInquiriesRoute />
                </Route>
                <Route exact path='/about/help-center/profile-account'>
                  <ProfileAccountRoute />
                </Route>
                <Route exact path='/about/help-center/sub-accounts'>
                  <HelpSubAccountsRoute />
                </Route>
                <Route exact path='/embedded'>
                  <EmbeddedRyftRoute />
                </Route>
                <Route exact path='/embedded-v2'>
                  <EmbeddedV2RyftRoute />
                </Route>
                <Route exact path='/embedded/3ds'>
                  <EmbeddedThreeDsRyftRoute />
                </Route>
                <Route exact path='/embedded/3ds-v2'>
                  <EmbeddedThreeDsV2RyftRoute />
                </Route>
                <Route exact path='/disputes'>
                  <DisputesRoute />
                </Route>
                <Route exact path='/disputes/accept/:id'>
                  <AcceptDisputeRoute />
                </Route>
                <Route exact path='/disputes/add-evidence/:id'>
                  <AddDisputeEvidenceRoute />
                </Route>
                <Route exact path='/disputes/challenge/:id'>
                  <ChallengeDisputeRoute />
                </Route>
                <Route exact path='/subscriptions'>
                  <SubscriptionRoute />
                </Route>
                <Route exact path='/subscriptions/create'>
                  <CreateSubscriptionRoute />
                </Route>
                <Route exact path='/subscriptions/edit'>
                  <EditSubscriptionRoute />
                </Route>
                <Route exact path='/subscriptions/adjust'>
                  <AdjustSubscriptionRoute />
                </Route>
                <Route exact path='*'>
                  <AppRoute />
                </Route>
              </Switch>
            )}
          />
        </BrowserRouter>
      </GlobalDataState>
    </GlobalState>
  );
};

export default Router;
