import React, {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { formatBytes } from '../../functions/helpers';

export interface PropsShape {
  children: ReactElement;
  validateFiles?: (
    files: File[],
    setIsInputValid: Dispatch<SetStateAction<boolean>>
  ) => Promise<void>;
  forceClick?: boolean;
  multiple?: boolean;
  accept?: string | undefined;
  disabled?: boolean;
}

const FileInput = ({
  children,
  validateFiles,
  forceClick,
  multiple,
  accept,
  disabled = false,
}: PropsShape): ReactElement => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [areFilesValid, setAreFilesValid] = useState<boolean>(false);
  const [filesDescription, setFilesDescription] = useState<string>('');

  useEffect(() => {
    setFilesDescription(
      areFilesValid && files.length > 0
        ? `${getFilesDescription(files)} (${getFilesSize(files)})`
        : ''
    );
  }, [areFilesValid, files]);

  useEffect(() => {
    if (files.length > 0) {
      validateFiles?.(files, setAreFilesValid);
    }
  }, [files]);

  const getFilesDescription = (files: File[]): string =>
    files.length === 1 ? files[0].name : `${files.length} files attached`;

  const getFilesSize = (files: File[]): string => {
    const bytes = files.reduce(function (sum, file) {
      return sum + file.size;
    }, 0);
    return formatBytes(bytes);
  };

  const dragOver = (): void => {
    setIsDragOver(!disabled);
  };
  const dragLeave = (): void => {
    setIsDragOver(false);
  };
  const dragDrop = (e: React.DragEvent<HTMLInputElement>): void => {
    setIsDragOver(false);
    const filesArray: File[] = Array.from(e.dataTransfer?.files);
    if (filesArray.length > 0) {
      setFiles(filesArray);
    }
  };
  const onChange = (e: ChangeEvent<any>): void => {
    const filesArray: File[] = Array.from(e.target.files);
    if (filesArray.length > 0) {
      setFiles(filesArray);
    }
  };

  return (
    <div>
      <div className='FileInput' onDragOver={dragOver}>
        <label className='FileInput--label' htmlFor='fileInput' />
        <input
          id='fileInput'
          className={
            forceClick
              ? 'FileInput--input-show'
              : isDragOver
              ? 'FileInput--input-show'
              : 'FileInput--input-hide'
          }
          disabled={disabled}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDrop={dragDrop}
          type='file'
          value=''
          ref={fileInputField}
          onChange={onChange}
          multiple={multiple}
          accept={accept}
        />
        {children}
      </div>
      <div className='FileInput--description'>
        <div className='FileInput--description-image' />
        <div className='FileInput--description-text'>{filesDescription}</div>
      </div>
    </div>
  );
};
export { FileInput };
