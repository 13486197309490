import React, { useState, useRef, FC, useEffect } from 'react';

interface StatusTabsProps {
  titleArray: string[];
  completedTitleArray: string[];
  selectedTab?: string; // Making this optional
  setSelectedTab: (tab: string) => void;
}

const StatusTabs: FC<StatusTabsProps> = ({
  titleArray,
  completedTitleArray,
  selectedTab,
  setSelectedTab,
}) => {
  const [selected, setSelected] = useState<string>(
    selectedTab || titleArray[0] || ''
  );
  const [completedTitles, setCompletedTitles] =
    useState<string[]>(completedTitleArray);
  const containerRef = useRef(null);

  useEffect(() => {
    if (selectedTab) {
      setSelected(selectedTab);
      setSelectedTab(selectedTab);
    } else if (titleArray.length > 0) {
      setSelected(titleArray[0]);
      setSelectedTab(titleArray[0]);
    }
  }, [selectedTab, titleArray, setSelectedTab]);

  useEffect(() => {
    setCompletedTitles(completedTitleArray);
  }, [completedTitleArray]);

  const handleClick = (title: string): void => {
    setSelected(title);
    setSelectedTab(title);
  };

  return (
    <div className='StatusTabs' ref={containerRef}>
      {titleArray.map((title, index) => (
        <button
          key={index}
          className={`StatusTabs--tab ${
            completedTitles.includes(title) ? 'StatusTabs--completed' : ''
          } ${selected === title ? 'active' : ''}`}
          onClick={(): void => handleClick(title)}
        >
          {title}
        </button>
      ))}
    </div>
  );
};

export default StatusTabs;
