import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { Button } from '../../components/forms/Button';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { useGetDeveloper } from '../../hooks/useGetDeveloper';
import { useAdjustDeveloper } from '../../hooks/useAdjustDeveloper';
import { UserPermission } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Copy } from '../../components/forms/Copy';
import {
  asteriskWord,
  epochSecondsToDate,
  handleNotSignedIn,
} from '../../functions/helpers';
import { DateTime } from '../../components/layouts/DateTime';

const ApiKeys = (): ReactElement => {
  const history = useHistory();
  const [isLoading, error, developer, reFetchDeveloper] = useGetDeveloper();
  const [cycleRequester, deleteRequester] = useAdjustDeveloper();
  const [isCycling, setIsCycling] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { isSignedIn } = useGlobalState();
  const [canAdjust, setCanAdjust] = useState(false);
  const { profileState } = useGlobalDataState();

  useEffect(() => {
    if (cycleRequester.complete) {
      reFetchDeveloper();
    }
  }, [cycleRequester.complete]);

  useEffect(() => {
    if (deleteRequester.complete) {
      reFetchDeveloper();
    }
  }, [deleteRequester.complete]);

  useEffect(() => {
    if (cycleRequester.isLoading) {
      setIsCycling(true);
    }
  }, [cycleRequester.isLoading]);

  useEffect(() => {
    if (deleteRequester.isLoading) {
      setIsDeleting(true);
    }
  }, [deleteRequester.isLoading]);

  useEffect(() => {
    if (isCycling) {
      setIsCycling(isLoading);
    }
    if (isDeleting) {
      setIsDeleting(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      DeveloperSettingsModify: setCanAdjust,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  const calculateButtonTitle = (): string => {
    if (!canAdjust) {
      return '';
    }
    if (!developer?.apiKeys) {
      return 'Add API Keys';
    }
    return 'Roll API Keys';
  };

  const canAddOrRollKeys = (): boolean => {
    return canAdjust && !developer?.apiKeys?.previousSecretKey;
  };

  return (
    <CardBlank>
      {isLoading && developer === undefined && (
        <SettingsItem border title='Loading' subtitle={'......'}></SettingsItem>
      )}
      {!isLoading && error && (
        <SettingsItem border title='Oops!' subtitle={error}></SettingsItem>
      )}
      {!error && !isLoading && (
        <SettingsItem
          border
          title='Active Keys'
          subtitle={
            <>
              Anyone who has your secret key will be able to perform actions
              against your account. Do not expose the secret key in your
              application code. Do not share it on GitHub or anywhere else
              online.
              <br />
              <br />
              Rolling will expire your current API keys after 48 hours. Any
              applications using them will no longer have access after this
              time.
            </>
          }
          button={calculateButtonTitle()}
          buttonClick={cycleRequester.request}
          buttonDisabled={!canAddOrRollKeys()}
          buttonLoading={isCycling}
        >
          <CardBlank>
            {!developer?.apiKeys?.publicKey?.value && (
              <SettingsItem
                border
                inner
                title='No Active keys'
                subtitle='Please add an API Key'
              />
            )}

            <div>
              {isLoading && developer === undefined && (
                <CardBlank>
                  <SettingsItem
                    border
                    title='Loading'
                    subtitle={'......'}
                  ></SettingsItem>
                </CardBlank>
              )}
              {!isLoading && error && (
                <CardBlank>
                  <SettingsItem
                    border
                    title='Oops!'
                    subtitle={error}
                  ></SettingsItem>
                </CardBlank>
              )}
              {developer?.apiKeys?.publicKey?.value &&
                developer?.apiKeys?.secretKey?.value && (
                  <>
                    <SettingsItem
                      border={false}
                      inner
                      title='Public Key'
                      subtitle={
                        <Copy
                          type='BUTTON_WITH_TEXT'
                          text={developer.apiKeys.publicKey.value}
                          value={developer.apiKeys.publicKey.value}
                        ></Copy>
                      }
                    ></SettingsItem>
                    <SettingsItem
                      border={false}
                      inner
                      title='Secret Key'
                      subtitle={
                        <Copy
                          type='BUTTON_WITH_TEXT'
                          text={asteriskWord(developer.apiKeys.secretKey.value)}
                          value={developer.apiKeys.secretKey.value}
                        ></Copy>
                      }
                    ></SettingsItem>
                    <SettingsItem
                      border={true}
                      inner
                      title='Created'
                      subtitle={
                        <DateTime
                          showTime
                          alwaysShowYear={true}
                          value={epochSecondsToDate(
                            developer.apiKeys.secretKey.createdTimestamp
                          )}
                        />
                      }
                    ></SettingsItem>
                  </>
                )}
              {developer?.apiKeys?.previousPublicKey?.value &&
                developer?.apiKeys?.previousSecretKey?.value && (
                  <>
                    <SettingsItem
                      border={false}
                      inner
                      title='Previous API Keys'
                      subtitle={
                        'The below keys have been rolled and are due to expire. Please make sure you have updated to the latest key pair above ' +
                        'to ensure uninterrupted API access.'
                      }
                    >
                      {canAdjust && (
                        <Button
                          margin='0'
                          name='Delete'
                          click={deleteRequester.request}
                          loading={isDeleting}
                          color={'RED'}
                        />
                      )}
                    </SettingsItem>
                    <SettingsItem
                      border={false}
                      inner
                      title='Public Key'
                      subtitle={
                        <Copy
                          type='BUTTON_WITH_TEXT'
                          text={developer.apiKeys.previousPublicKey.value}
                          value={developer.apiKeys.previousPublicKey.value}
                        ></Copy>
                      }
                    ></SettingsItem>
                    <SettingsItem
                      border={false}
                      inner
                      title='Secret Key'
                      subtitle={
                        <Copy
                          type='BUTTON_WITH_TEXT'
                          text={asteriskWord(
                            developer.apiKeys.previousSecretKey.value
                          )}
                          value={developer.apiKeys.previousSecretKey.value}
                        ></Copy>
                      }
                    ></SettingsItem>
                    <SettingsItem
                      border={
                        !developer.apiKeys.previousSecretKey.expiresTimestamp
                      }
                      inner
                      title='Created'
                      subtitle={
                        <DateTime
                          showTime
                          alwaysShowYear={true}
                          value={epochSecondsToDate(
                            developer.apiKeys.previousSecretKey.createdTimestamp
                          )}
                        />
                      }
                    ></SettingsItem>
                    {developer.apiKeys.previousSecretKey.expiresTimestamp && (
                      <SettingsItem
                        border={true}
                        inner
                        title='Expire On'
                        subtitle={
                          <DateTime
                            showTime
                            alwaysShowYear={true}
                            value={epochSecondsToDate(
                              developer.apiKeys.previousSecretKey
                                .expiresTimestamp
                            )}
                          />
                        }
                      ></SettingsItem>
                    )}
                  </>
                )}
            </div>
          </CardBlank>
        </SettingsItem>
      )}
    </CardBlank>
  );
};
export { ApiKeys };
