import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { SelectInput } from './SelectInput';
import { countries, usStates, caStates } from '../../functions/iso3166-alpha2';
import { TextInput } from './TextInput';
import { AccountAddress } from '../../interfaces/accountState';

export interface PropsShape {
  input?: AccountAddress;
  countryError: string;
  postalCodeError: string;
  lineOneError: string;
  lineTwoError: string;
  cityError: string;
  regionError: string;
  setEditedAddress: Dispatch<SetStateAction<EditedAccountAddress | undefined>>;
}

export interface EditedAccountAddress {
  lineOne: string;
  lineTwo?: string;
  city: string;
  country: string;
  postalCode: string;
  region?: string;
}

const AccountAddressInput = ({
  input,
  countryError,
  postalCodeError,
  lineOneError,
  lineTwoError,
  cityError,
  regionError,
  setEditedAddress,
}: PropsShape): ReactElement => {
  const [country, setCountry] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [lineOne, setLineOne] = useState<string>('');
  const [lineTwo, setLineTwo] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [statesVisible, setStatesVisible] = useState<boolean>(false);

  useEffect(() => {
    setEditedAddress({
      lineOne,
      lineTwo: lineTwo || undefined,
      city,
      region: region || undefined,
      postalCode,
      country,
    });
  }, [lineOne, lineTwo, city, region, postalCode, country]);

  useEffect(() => {
    if (input) {
      setCountry(input.country);
      setPostalCode(input.postalCode);
      setLineOne(input.lineOne);
      setLineTwo(input.lineTwo ?? '');
      setCity(input.city);
      setRegion(input.region ?? '');
    }
  }, [input]);

  useEffect(() => {
    const newStatesVisible = country === 'US' || country === 'CA';
    if (statesVisible != newStatesVisible) {
      setStatesVisible(newStatesVisible);
      setRegion('');
    }
  }, [country]);

  return (
    <>
      <SelectInput
        label
        name='Country'
        value={country}
        setValue={setCountry}
        options={countries()}
        error={countryError}
      />
      <TextInput
        label
        name='Postal Code'
        value={postalCode}
        setValue={setPostalCode}
        error={postalCodeError}
      />
      <TextInput
        label
        name='Line One'
        value={lineOne}
        setValue={setLineOne}
        error={lineOneError}
      />
      <TextInput
        label
        name='Line Two'
        value={lineTwo}
        setValue={setLineTwo}
        error={lineTwoError}
        optional
      />
      <TextInput
        label
        name='Town / City'
        value={city}
        setValue={setCity}
        error={cityError}
      />
      {!statesVisible && (
        <TextInput
          label
          name='County / Region'
          value={region}
          setValue={setRegion}
          error={regionError}
          optional
        />
      )}
      {statesVisible && (
        <SelectInput
          label
          name='State'
          value={region}
          setValue={setRegion}
          options={country === 'US' ? usStates() : caStates()}
          error={regionError}
        />
      )}
    </>
  );
};
export { AccountAddressInput };
