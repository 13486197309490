import { ReactElement } from 'react';

export type StatusShape =
  | 'PENDING'
  | 'PENDINGAUTH'
  | 'PENDINGACTION'
  | 'PENDINGPAYMENT'
  | 'PENDINGREFUND'
  | 'PROCESSING'
  | 'APPROVED'
  | 'VOIDED'
  | 'UNCAPTURED'
  | 'PARTIALLYCAPTURED'
  | 'CAPTURED'
  | 'CAPTURED3DS'
  | 'PARTIALLYREFUNDED'
  | 'REFUNDED'
  | 'CARDVERIFIED'
  | 'CANCELLED'
  | 'FAILED'
  | 'ACTIONREQUIRED'
  | 'UNVERIFIED'
  | 'VERIFICATIONPENDING'
  | 'SUCCESS'
  | 'NONE'
  | 'PENDINGACCOUNTACTION'
  | 'PENDINGACCOUNTVERIFICATION'
  | 'PENDINGPAYOUTMETHODACTION'
  | 'COMPLETED'
  | 'INPROGRESS'
  | 'RECALLED'
  | 'RETURNED'
  | 'EXPIRED'
  | 'DISPUTED'
  | 'DISPUTEEXPIRED'
  | 'DISPUTECANCELLED'
  | 'DISPUTECHALLENGED'
  | 'DISPUTELOST'
  | 'DISPUTEWON'
  | 'NOTREQUIRED'
  | 'REQUIRED'
  | 'PENDINGVERIFICATION'
  | 'VALID'
  | 'INVALID'
  | 'INCREATION'
  | 'DISABLED'
  | 'ENABLED'
  | 'VERIFIED'
  | 'NOTREQUESTED';

export interface PropsShape {
  status: StatusShape;
  size?: 'default' | 'mini';
}

const Status = ({ status, size = 'default' }: PropsShape): ReactElement => {
  const shouldShowText = size !== 'mini';

  return (
    <>
      <div className={`Status ${size === 'mini' ? 'Status--mini' : ''}`}>
        {status === 'ENABLED' && (
          <div className='Status--captured'>{shouldShowText && 'Enabled'}</div>
        )}
        {status === 'NOTREQUESTED' && (
          <div className='Status--pending'>{shouldShowText && 'Available'}</div>
        )}
        {status === 'VALID' && (
          <div className='Status--captured'>{shouldShowText && 'Valid'}</div>
        )}
        {status === 'INVALID' && (
          <div className='Status--failed'>{shouldShowText && 'Invalid'}</div>
        )}
        {status === 'INCREATION' && (
          <div className='Status--pending'>{shouldShowText && 'Creating'}</div>
        )}
        {status === 'DISABLED' && (
          <div className='Status--cancelled'>
            {shouldShowText && 'Disabled'}
          </div>
        )}
        {status === 'VERIFIED' && (
          <div className='Status--captured'>{shouldShowText && 'Verified'}</div>
        )}
        {status === 'PENDINGVERIFICATION' && (
          <div className='Status--pending'>
            {shouldShowText && 'Under Review'}
          </div>
        )}
        {status === 'REQUIRED' && (
          <div className='Status--failed'>{shouldShowText && 'Required'}</div>
        )}
        {status === 'NOTREQUIRED' && (
          <div className='Status--approved'>
            {shouldShowText && 'Not Required'}
          </div>
        )}
        {status === 'DISPUTED' && (
          <div className='Status--disputed'>{shouldShowText && 'Disputed'}</div>
        )}
        {status === 'DISPUTECANCELLED' && (
          <div className='Status--dispute-cancelled'>
            {shouldShowText && 'Dispute Cancelled'}
          </div>
        )}
        {status === 'DISPUTECHALLENGED' && (
          <div className='Status--dispute-challenged'>
            {shouldShowText && 'Dispute Challenged'}
          </div>
        )}
        {status === 'DISPUTEEXPIRED' && (
          <div className='Status--dispute-expired'>
            {shouldShowText && 'Dispute Expired'}
          </div>
        )}
        {status === 'DISPUTELOST' && (
          <div className='Status--dispute-lost'>
            {shouldShowText && 'Dispute Lost'}
          </div>
        )}
        {status === 'DISPUTEWON' && (
          <div className='Status--dispute-won'>
            {shouldShowText && 'Dispute Won'}
          </div>
        )}
        {status === 'PENDINGPAYOUTMETHODACTION' && (
          <div className='Status--pending'>{shouldShowText && 'Pending'}</div>
        )}
        {status === 'PENDINGACCOUNTVERIFICATION' && (
          <div className='Status--pending'>
            {shouldShowText && 'Pending Verification'}
          </div>
        )}
        {status === 'PENDINGACCOUNTACTION' && (
          <div className='Status--pending'>{shouldShowText && 'Pending'}</div>
        )}
        {status === 'PENDING' && (
          <div className='Status--pending'>{shouldShowText && 'Pending'}</div>
        )}
        {status === 'PENDINGPAYMENT' && (
          <div className='Status--pending'>
            {shouldShowText && 'Pending Payment'}
          </div>
        )}
        {status === 'PENDINGAUTH' && (
          <div className='Status--pending-auth'>
            {shouldShowText && 'Pending Auth'}
          </div>
        )}
        {status === 'PENDINGACTION' && (
          <div className='Status--pending-action'>
            {shouldShowText && 'Pending Action'}
          </div>
        )}
        {status === 'PENDINGREFUND' && (
          <div className='Status--pending-refund'>
            {shouldShowText && 'Pending Refund'}
          </div>
        )}
        {status === 'PROCESSING' && (
          <div className='Status--pending'>
            {shouldShowText && 'Processing'}
          </div>
        )}
        {status === 'COMPLETED' && (
          <div className='Status--captured'>{shouldShowText && 'Paid'}</div>
        )}
        {status === 'INPROGRESS' && (
          <div className='Status--approved'>
            {shouldShowText && 'In Progress'}
          </div>
        )}
        {status === 'APPROVED' && (
          <div className='Status--approved'>{shouldShowText && 'Approved'}</div>
        )}
        {status === 'VOIDED' && (
          <div className='Status--refunded'>{shouldShowText && 'Voided'}</div>
        )}
        {status === 'UNCAPTURED' && (
          <div className='Status--uncaptured'>
            {shouldShowText && 'Not Captured'}
          </div>
        )}
        {status === 'PARTIALLYCAPTURED' && (
          <div className='Status--partiallycaptured'>
            {shouldShowText && 'Partial Capture'}
          </div>
        )}
        {status === 'NONE' && (
          <div className='Status--cancelled'>{shouldShowText && 'None'}</div>
        )}
        {status === 'SUCCESS' && (
          <div className='Status--captured'>{shouldShowText && 'Success'}</div>
        )}
        {status === 'CAPTURED' && (
          <div className='Status--captured'>{shouldShowText && 'Captured'}</div>
        )}
        {status === 'CAPTURED3DS' && (
          <div className='Status--captured'>
            {shouldShowText && 'Captured (3ds)'}
          </div>
        )}
        {status === 'PARTIALLYREFUNDED' && (
          <div className='Status--refunded'>
            {shouldShowText && 'Partial Refund'}
          </div>
        )}
        {status === 'CARDVERIFIED' && (
          <div className='Status--captured'>
            {shouldShowText && 'Card Verified'}
          </div>
        )}
        {status === 'RECALLED' && (
          <div className='Status--refunded'>{shouldShowText && 'Recalled'}</div>
        )}
        {status === 'RETURNED' && (
          <div className='Status--refunded'>{shouldShowText && 'Returned'}</div>
        )}
        {status === 'REFUNDED' && (
          <div className='Status--refunded'>{shouldShowText && 'Refunded'}</div>
        )}
        {status === 'EXPIRED' && (
          <div className='Status--cancelled'>{shouldShowText && 'Expired'}</div>
        )}
        {status === 'CANCELLED' && (
          <div className='Status--cancelled'>
            {shouldShowText && 'Cancelled'}
          </div>
        )}
        {status === 'FAILED' && (
          <div className='Status--failed'>{shouldShowText && 'Failed'}</div>
        )}
        {status === 'ACTIONREQUIRED' && (
          <div className='Status--failed'>
            {shouldShowText && 'Action Required'}
          </div>
        )}
        {status === 'UNVERIFIED' && (
          <div className='Status--pending-auth'>
            {shouldShowText && 'Unverified'}
          </div>
        )}
        {status === 'VERIFICATIONPENDING' && (
          <div className='Status--approved'>{shouldShowText && 'Pending'}</div>
        )}
      </div>
    </>
  );
};

export { Status };
