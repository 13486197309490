import { ReactElement, useEffect } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { ReportDetail } from '../../../components/dataGrids/ReportDetail';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useHistory } from 'react-router-dom';

const ReportRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('ReportsView')) {
      history.push('/');
      return;
    }
  }, [profileState]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <ReportDetail />
    </Layout>
  );
};

export { ReportRoute };
