import React from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';

const HelpRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='Help'>
        <div className='Help--center'>
          <header>We're here to help.</header>
          <div className='Help--body'>
            <nav className='Help--grid'>
              <div
                className='Help--getting-started'
                onClick={(): void =>
                  history.push('./help-center/getting-started')
                }
              >
                Getting Started
              </div>
              <div
                className='Help--profile-account'
                onClick={(): void =>
                  history.push('./help-center/profile-account')
                }
              >
                Profile & Account
              </div>
              <div
                className='Help--sub-accounts'
                onClick={(): void => history.push('./help-center/sub-accounts')}
              >
                Sub Accounts
              </div>
              <div
                className='Help--general-inquiries'
                onClick={(): void =>
                  history.push('./help-center/general-inquiry')
                }
              >
                General Inquiries
              </div>
            </nav>
            <div className='Help--still'>
              Still need help?{' '}
              <a onClick={(): void => history.push('./about/contact')}>
                Contact Ryft
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { HelpRoute };
