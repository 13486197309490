import { FormEvent, ReactElement, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/forms/Button';
import { SideMenu } from '../../components/layouts/SideMenu';
import { LayoutIllustration } from '../../layout-illustration';
import { FileBrowser } from '../../components/dataGrids/FileBrowser';
import { useGetFiles } from '../../hooks/useGetFiles';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { FileUpload } from '../../components/forms/FileUpload';
import {
  useAddDisputeEvidence,
  AddDisputeEvidenceRequest,
} from '../../hooks/useAddDisputeEvidence';
import useDeleteDisputeEvidence, {
  EvidenceType,
  AllowedType,
} from '../../hooks/useDeleteDisputeEvidence';
import {
  camelCaseToWords,
  handleNotSignedIn,
  toCamelCase,
} from '../../functions/helpers';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Dispute } from '../../interfaces/disputeState';

const AddDisputeEvidenceRoute = (): ReactElement => {
  const { isSignedIn, profile } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const { deleteEvidence, response } = useDeleteDisputeEvidence();
  const getEvidenceFiles = useGetFiles('Evidence');
  const {
    state: { files, isLoading: isFilesLoading, error: errorFiles },
    reFetchFiles,
    getMore,
  } = getEvidenceFiles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addEvidence, isLoading, isComplete, __, newDispute] =
    useAddDisputeEvidence();
  const { setDisputeState, disputeState } = useGlobalDataState();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [confirmedFile, setConfirmedFile] = useState<string | null>(null);
  const [supportingEvidence, setSupportingEvidence] = useState<string | null>(
    null
  );

  const dispute: Dispute | undefined = disputeState?.data?.selected;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (profile.group && profile.group !== 'STANDARD') {
      return history.push('/');
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!dispute) {
      return history.push('/disputes');
    }
    if (dispute.status !== 'Open' || !permissions.has('DisputesModify')) {
      return history.push(`/disputes?id=${dispute.id}&tab=Evidence`);
    }
  }, [isSignedIn, profile]);

  useEffect(() => {
    const dataToUpdate = response || newDispute;

    if (dataToUpdate) {
      setDisputeState((prevState: any) => {
        const updatedItems =
          prevState.data?.items.map((item: { id: any }) =>
            item.id === dataToUpdate.data.id ? dataToUpdate.data : item
          ) || [];
        return {
          ...prevState,
          data: {
            ...prevState.data,
            items: updatedItems,
            selected: dataToUpdate.data,
          },
        };
      });
    }
  }, [response, newDispute]);

  useEffect(() => {
    if (isComplete) {
      history.push(`/disputes?id=${dispute?.id}&tab=Evidence`);
    }
  }, [isComplete]);

  const useQuery = (): URLSearchParams => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const evidenceType = toCamelCase(query.get('evidenceType') || '');
  const editType = query.get('edit') || '';
  const allowedType = query.get('allowed') || '';
  const history = useHistory();

  const handleCloseSideMenu = (): void => {
    setShowSideMenu(false);
  };

  const handleFileUpload = (): void => {
    reFetchFiles();
  };

  const handleAddEvidence = (): void => {
    if (!supportingEvidence && !confirmedFile) {
      return;
    }
    const body: Partial<AddDisputeEvidenceRequest> = {};
    if (supportingEvidence) {
      body.text = {
        [evidenceType]: supportingEvidence,
      };
    }
    if (confirmedFile) {
      body.files = {
        [evidenceType]: { id: confirmedFile },
      };
    }

    addEvidence(body as AddDisputeEvidenceRequest, dispute?.id);
  };

  const handleRemoveFile = (): void => {
    if (evidenceType && dispute?.id) {
      deleteEvidence(
        dispute.id,
        evidenceType as EvidenceType,
        'files' as AllowedType
      );
    }
    const currentUrl = new URL(window.location.href);
    window.history.replaceState(null, '', currentUrl.toString());
    setConfirmedFile(null);
  };

  useEffect(() => {
    if (editType === 'Edit' && disputeState?.data?.selected?.evidence) {
      const text =
        disputeState.data.selected.evidence.text?.[evidenceType] || '';
      const fileId =
        disputeState.data.selected.evidence.files?.[evidenceType]?.id || '';

      text && setSupportingEvidence(text);
      fileId && setConfirmedFile(fileId);
    }
  }, []);

  return (
    <>
      <LayoutIllustration
        title={
          <>
            {editType} <span>{camelCaseToWords(evidenceType)}</span>
          </>
        }
        subTitle={<>Provide the selected evidence below</>}
      >
        <form
          style={{ padding: '0' }}
          onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
          }}
        >
          <div
            className={
              allowedType === 'both' ? 'AddEvidence--both' : 'AddEvidence'
            }
          >
            {(allowedType === 'file' || allowedType === 'both') && (
              <div style={{ width: '200px' }}>
                <div className='AddEvidence--title'>File</div>
                <div className='AddEvidence--area'>
                  {!confirmedFile && (
                    <div
                      className='AddEvidence--area-select'
                      onClick={(): void => setShowSideMenu(true)}
                    >
                      Select a file...
                    </div>
                  )}
                  {confirmedFile && (
                    <>
                      <div>
                        {files?.find((f) => f.id === confirmedFile)?.name ?? ''}
                      </div>
                      <div
                        className='AddEvidence--area-remove'
                        onClick={handleRemoveFile}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {(allowedType === 'text' || allowedType === 'both') && (
              <div>
                <div className='AddEvidence--title'>Supporting evidence</div>
                <textarea
                  className='AddEvidence--area'
                  value={supportingEvidence || ''}
                  onChange={(e: any): void =>
                    setSupportingEvidence(e.target.value)
                  }
                ></textarea>
              </div>
            )}
          </div>
          <div className='AddEvidence--button-group'>
            <Button
              name='Save'
              click={handleAddEvidence}
              color='BLUE'
              loading={isLoading}
            />
            <Button
              name='Cancel'
              click={(): void =>
                history.push(`/disputes?id=${dispute?.id}&tab=Evidence`)
              }
              color='GREY'
            />
          </div>
        </form>
      </LayoutIllustration>
      <SideMenu
        isOpen={showSideMenu}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div className='AddEvidence--side-menu'>
          <FileBrowser
            files={files}
            setSelectedFile={setSelectedFile}
            isLoading={isFilesLoading}
            error={errorFiles}
            getMore={getMore}
            onDoubleClick={(): void => {
              setConfirmedFile(selectedFile.id);
              setShowSideMenu(false);
            }}
          />
          <FileUpload category='Evidence' onFileUploaded={handleFileUpload} />
          <Button
            name='Select'
            click={(): void => {
              setConfirmedFile(selectedFile.id);
              setShowSideMenu(false);
            }}
            color='BLUE'
          ></Button>
          <Button
            name='Cancel'
            click={(): void => {
              setShowSideMenu(false);
              setSelectedFile(null);
            }}
            color='GREY'
          ></Button>
        </div>
      </SideMenu>
    </>
  );
};

export { AddDisputeEvidenceRoute };
