import { ReactElement } from 'react';

export interface PropsShape {
  message: string | ReactElement;
}

const SuccessMessage = ({ message }: PropsShape): ReactElement => {
  return <>{message && <div className='SuccessMessage'>{message}</div>}</>;
};
export { SuccessMessage };
