import {
  createContext,
  useContext,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { coBrandingStateShape } from '../interfaces/coBrandingState';
import { paymentsStateShape } from '../interfaces/paymentState';
import { splitPaymentsStateShape } from '../interfaces/splitPaymentState';
import { accountsStateShape } from '../interfaces/accountsState';
import { payoutsStateShape } from '../interfaces/payoutsState';
import { reportsStateShape } from '../interfaces/reportsState';
import { platformFeesStateShape } from '../interfaces/feesState';
import { applePayCertificateStateShape } from '../interfaces/applePayCertificateState';
import { applePayWebDomainStateShape } from '../interfaces/applePayWebDomainState';
import { customersStateShape } from '../interfaces/customersState';
import { customersDashStateShape } from '../interfaces/customersDashState';
import { payoutAccountsStateShape } from '../interfaces/payoutAccountsState';
import { balancesStateShape } from '../interfaces/balancesState';
import { subscriptionStateShape } from '../interfaces/subscriptionState';
import { disputeStateShape } from '../interfaces/disputeState';
import { payoutMethodsStateShape } from '../interfaces/payoutMethodsState';
import { profileStateShape } from '../interfaces/profileState';
import { webhooksStateShape } from '../interfaces/developerState';
import { usersStateShape } from '../interfaces/userState';
import { transactionsDashStateShape } from '../interfaces/transactionsDashState';
import { useGlobalState } from './useGlobalState';
import { personsStateShape } from '../interfaces/personsState';
import { accountStateShape } from '../interfaces/accountState';

interface PropsShape {
  children: ReactElement;
}

interface StateShape {
  coBrandingState: coBrandingStateShape;
  setCoBrandingState: Dispatch<SetStateAction<coBrandingStateShape>>;
  paymentsState: paymentsStateShape;
  setPaymentsState: Dispatch<SetStateAction<paymentsStateShape>>;
  splitPaymentsState: splitPaymentsStateShape;
  setSplitPaymentsState: Dispatch<SetStateAction<splitPaymentsStateShape>>;
  accountsState: accountsStateShape;
  setAccountsState: Dispatch<SetStateAction<accountsStateShape>>;
  payoutsState: payoutsStateShape;
  setPayoutsState: Dispatch<SetStateAction<payoutsStateShape>>;
  reportsState: reportsStateShape;
  setReportsState: Dispatch<SetStateAction<reportsStateShape>>;
  transactionsDashState: transactionsDashStateShape;
  setTransactionsDashState: Dispatch<
    SetStateAction<transactionsDashStateShape>
  >;
  feesState: platformFeesStateShape;
  setFeesState: Dispatch<SetStateAction<platformFeesStateShape>>;
  applePayCertificateState: applePayCertificateStateShape;
  setApplePayCertificateState: Dispatch<
    SetStateAction<applePayCertificateStateShape>
  >;
  applePayWebDomainState: applePayWebDomainStateShape;
  setApplePayWebDomainState: Dispatch<
    SetStateAction<applePayWebDomainStateShape>
  >;
  customersState: customersStateShape;
  setCustomersState: Dispatch<SetStateAction<customersStateShape>>;
  customersDashState: customersDashStateShape;
  setCustomersDashState: Dispatch<SetStateAction<customersDashStateShape>>;
  payoutAccountsState: payoutAccountsStateShape;
  setPayoutAccountsState: Dispatch<SetStateAction<payoutAccountsStateShape>>;
  balancesState: balancesStateShape;
  setBalancesState: Dispatch<SetStateAction<balancesStateShape>>;
  subscriptionState: subscriptionStateShape;
  setSubscriptionState: Dispatch<SetStateAction<subscriptionStateShape>>;

  disputeState: disputeStateShape;
  setDisputeState: Dispatch<SetStateAction<disputeStateShape>>;

  payoutMethodsState: payoutMethodsStateShape;
  setPayoutMethodsState: Dispatch<SetStateAction<payoutMethodsStateShape>>;
  profileState: profileStateShape;
  setProfileState: Dispatch<SetStateAction<profileStateShape>>;
  webhooksState: webhooksStateShape;
  setWebhooksState: Dispatch<SetStateAction<webhooksStateShape>>;
  usersState: usersStateShape;
  setUsersState: Dispatch<SetStateAction<usersStateShape>>;
  accountState: accountStateShape;
  setAccountState: Dispatch<SetStateAction<accountStateShape>>;
  personsState: personsStateShape;
  setPersonsState: Dispatch<SetStateAction<personsStateShape>>;
}

const initVal: StateShape = {
  coBrandingState:
    JSON.parse(`${localStorage.getItem('coBrandingState')}`) || {},
  setCoBrandingState: () => {},
  paymentsState: JSON.parse(`${localStorage.getItem('paymentsState')}`) || {},
  setPaymentsState: () => {},
  splitPaymentsState:
    JSON.parse(`${localStorage.getItem('splitPaymentsState')}`) || {},
  setSplitPaymentsState: () => {},
  accountsState: JSON.parse(`${localStorage.getItem('accountsState')}`) || {},
  setAccountsState: () => {},
  payoutsState: JSON.parse(`${localStorage.getItem('payoutsState')}`) || {},
  setPayoutsState: () => {},
  reportsState: JSON.parse(`${localStorage.getItem('reportsState')}`) || {},
  setReportsState: () => {},
  transactionsDashState:
    JSON.parse(`${localStorage.getItem('transactionsDashState')}`) || {},
  setTransactionsDashState: () => {},
  feesState: JSON.parse(`${localStorage.getItem('feesState')}`) || {},
  setFeesState: () => {},
  applePayCertificateState:
    JSON.parse(`${localStorage.getItem('applePayCertificateState')}`) || {},
  setApplePayCertificateState: () => {},
  applePayWebDomainState:
    JSON.parse(`${localStorage.getItem('applePayWebDomainState')}`) || {},
  setApplePayWebDomainState: () => {},
  customersState: JSON.parse(`${localStorage.getItem('customersState')}`) || {},
  setCustomersState: () => {},
  customersDashState:
    JSON.parse(`${localStorage.getItem('customersDashState')}`) || {},
  setCustomersDashState: () => {},
  payoutAccountsState:
    JSON.parse(`${localStorage.getItem('payoutAccountsState')}`) || {},
  setPayoutAccountsState: () => {},
  balancesState: JSON.parse(`${localStorage.getItem('balancesState')}`) || {},
  setBalancesState: () => {},
  subscriptionState:
    JSON.parse(`${localStorage.getItem('subscriptionState')}`) || {},
  setSubscriptionState: () => {},
  payoutMethodsState:
    JSON.parse(`${localStorage.getItem('payoutMethodsState')}`) || {},
  setPayoutMethodsState: () => {},
  profileState: JSON.parse(`${localStorage.getItem('profileState')}`) || {},
  setProfileState: () => {},
  webhooksState: JSON.parse(`${localStorage.getItem('webhooksState')}`) || {},
  setWebhooksState: () => {},
  usersState: JSON.parse(`${localStorage.getItem('usersState')}`) || {},
  setUsersState: () => {},
  accountState: JSON.parse(`${localStorage.getItem('accountState')}`) || {},
  setAccountState: () => {},
  personsState: JSON.parse(`${localStorage.getItem('personsState')}`) || {},
  setPersonsState: () => {},
  disputeState: JSON.parse(`${localStorage.getItem('disputeState')}`) || {},
  setDisputeState: () => {},
};

const GlobalContext = createContext<StateShape>(initVal);

const useGlobalDataState = (): StateShape => {
  return useContext(GlobalContext);
};

const GlobalDataState = ({ children }: PropsShape): ReactElement => {
  const { isSignedIn } = useGlobalState();
  const [coBrandingState, setCoBrandingState] = useState<coBrandingStateShape>(
    initVal.coBrandingState
  );
  const [paymentsState, setPaymentsState] = useState<paymentsStateShape>(
    initVal.paymentsState
  );
  const [splitPaymentsState, setSplitPaymentsState] =
    useState<splitPaymentsStateShape>(initVal.splitPaymentsState);
  const [accountsState, setAccountsState] = useState<accountsStateShape>(
    initVal.accountsState
  );
  const [payoutsState, setPayoutsState] = useState<payoutsStateShape>(
    initVal.payoutsState
  );
  const [reportsState, setReportsState] = useState<reportsStateShape>(
    initVal.reportsState
  );
  const [transactionsDashState, setTransactionsDashState] =
    useState<transactionsDashStateShape>(initVal.transactionsDashState);
  const [feesState, setFeesState] = useState<platformFeesStateShape>(
    initVal.feesState
  );
  const [applePayCertificateState, setApplePayCertificateState] =
    useState<applePayCertificateStateShape>(initVal.applePayCertificateState);
  const [customersState, setCustomersState] = useState<customersStateShape>(
    initVal.customersState
  );
  const [customersDashState, setCustomersDashState] =
    useState<customersDashStateShape>(initVal.customersDashState);
  const [applePayWebDomainState, setApplePayWebDomainState] =
    useState<applePayWebDomainStateShape>(initVal.applePayWebDomainState);

  const [payoutAccountsState, setPayoutAccountsState] =
    useState<payoutAccountsStateShape>(initVal.payoutAccountsState);

  const [balancesState, setBalancesState] = useState<balancesStateShape>(
    initVal.balancesState
  );

  const [subscriptionState, setSubscriptionState] =
    useState<subscriptionStateShape>(initVal.subscriptionState);

  const [payoutMethodsState, setPayoutMethodsState] =
    useState<payoutMethodsStateShape>(initVal.payoutMethodsState);

  const [profileState, setProfileState] = useState<profileStateShape>(
    initVal.profileState
  );

  const [webhooksState, setWebhooksState] = useState<webhooksStateShape>(
    initVal.webhooksState
  );

  const [usersState, setUsersState] = useState<usersStateShape>(
    initVal.usersState
  );
  const [disputeState, setDisputeState] = useState<disputeStateShape>(
    initVal.disputeState
  );

  const [accountState, setAccountState] = useState<accountStateShape>(
    initVal.accountState
  );

  const [personsState, setPersonsState] = useState<personsStateShape>(
    initVal.personsState
  );

  useEffect(() => {
    localStorage.setItem('coBrandingState', JSON.stringify(coBrandingState));
  }, [coBrandingState]);

  useEffect(() => {
    localStorage.setItem('paymentsState', JSON.stringify(paymentsState));
  }, [paymentsState]);

  useEffect(() => {
    localStorage.setItem(
      'splitPaymentsState',
      JSON.stringify(splitPaymentsState)
    );
  }, [splitPaymentsState]);

  useEffect(() => {
    localStorage.setItem('accountsState', JSON.stringify(accountsState));
  }, [accountsState]);

  useEffect(() => {
    localStorage.setItem('payoutsState', JSON.stringify(payoutsState));
  }, [payoutsState]);

  useEffect(() => {
    localStorage.setItem('reportsState', JSON.stringify(reportsState));
  }, [reportsState]);

  useEffect(() => {
    localStorage.setItem('feesState', JSON.stringify(feesState));
  }, [feesState]);

  useEffect(() => {
    localStorage.setItem(
      'applePayCertificateState',
      JSON.stringify(applePayCertificateState)
    );
  }, [applePayCertificateState]);

  useEffect(() => {
    localStorage.setItem(
      'applePayWebDomainState',
      JSON.stringify(applePayWebDomainState)
    );
  }, [applePayWebDomainState]);

  useEffect(() => {
    localStorage.setItem(
      'transactionsDashState',
      JSON.stringify(transactionsDashState)
    );
  }, [transactionsDashState]);

  useEffect(() => {
    localStorage.setItem('customersState', JSON.stringify(customersState));
  }, [customersState]);

  useEffect(() => {
    localStorage.setItem(
      'customersDashState',
      JSON.stringify(customersDashState)
    );
  }, [customersDashState]);

  useEffect(() => {
    localStorage.setItem(
      'payoutAccountsState',
      JSON.stringify(payoutAccountsState)
    );
  }, [payoutAccountsState]);

  useEffect(() => {
    localStorage.setItem('balancesState', JSON.stringify(balancesState));
  }, [balancesState]);

  useEffect(() => {
    localStorage.setItem(
      'subscriptionState',
      JSON.stringify(subscriptionState)
    );
  }, [subscriptionState]);

  useEffect(() => {
    localStorage.setItem(
      'payoutMethodsState',
      JSON.stringify(payoutMethodsState)
    );
  }, [payoutMethodsState]);

  useEffect(() => {
    localStorage.setItem('profileState', JSON.stringify(profileState));
  }, [profileState]);

  useEffect(() => {
    localStorage.setItem('webhooksState', JSON.stringify(webhooksState));
  }, [webhooksState]);

  useEffect(() => {
    localStorage.setItem('usersState', JSON.stringify(usersState));
  }, [usersState]);

  useEffect(() => {
    localStorage.setItem('accountState', JSON.stringify(accountState));
  }, [accountState]);

  useEffect(() => {
    localStorage.setItem('personsState', JSON.stringify(personsState));
  }, [personsState]);

  useEffect(() => {
    localStorage.setItem('disputeState', JSON.stringify(disputeState));
  }, [disputeState]);

  useEffect(() => {
    const coBrandingStateString = localStorage.getItem('coBrandingState');
    coBrandingStateString &&
      setPaymentsState(JSON.parse(coBrandingStateString));
    const paymentsStateString = localStorage.getItem('paymentsState');
    paymentsStateString && setPaymentsState(JSON.parse(paymentsStateString));
    const splitPaymentsStateString = localStorage.getItem('splitPaymentsState');
    splitPaymentsStateString &&
      setSplitPaymentsState(JSON.parse(splitPaymentsStateString));
    const subAccountsString = localStorage.getItem('accountsState');
    subAccountsString && setAccountsState(JSON.parse(subAccountsString));
    const payoutsString = localStorage.getItem('payoutsState');
    payoutsString && setPayoutsState(JSON.parse(payoutsString));
    const reportsString = localStorage.getItem('reportsState');
    reportsString && setReportsState(JSON.parse(reportsString));
    const feesString = localStorage.getItem('feesState');
    feesString && setFeesState(JSON.parse(feesString));
    const transactionsDashString = localStorage.getItem(
      'transactionsDashState'
    );
    transactionsDashString &&
      setTransactionsDashState(JSON.parse(transactionsDashString));
    const applePayCertificateString = localStorage.getItem(
      'applePayCertificateState'
    );
    applePayCertificateString &&
      setApplePayCertificateState(JSON.parse(applePayCertificateString));
    const applePayWebDomainString = localStorage.getItem(
      'applePayWebDomainState'
    );
    applePayWebDomainString &&
      setApplePayWebDomainState(JSON.parse(applePayWebDomainString));
    const customersStateString = localStorage.getItem('customersState');
    customersStateString && setCustomersState(JSON.parse(customersStateString));
    const customersDashString = localStorage.getItem('customersDashState');
    customersDashString &&
      setCustomersDashState(JSON.parse(customersDashString));
    const payoutAccountsString = localStorage.getItem('payoutAccountsState');
    payoutAccountsString &&
      setPayoutAccountsState(JSON.parse(payoutAccountsString));
    const balancesString = localStorage.getItem('balancesState');
    balancesString && setBalancesState(JSON.parse(balancesString));
    const subscriptionStateString = localStorage.getItem('subscriptionState');
    subscriptionStateString &&
      setSubscriptionState(JSON.parse(subscriptionStateString));
    const payoutMethodsStateString = localStorage.getItem('payoutMethodsState');
    payoutMethodsStateString &&
      setPayoutMethodsState(JSON.parse(payoutMethodsStateString));
    const profileStateString = localStorage.getItem('profileState');
    profileStateString && setProfileState(JSON.parse(profileStateString));
    const webhooksStateString = localStorage.getItem('webhooksState');
    webhooksStateString && setWebhooksState(JSON.parse(webhooksStateString));
    const usersStateAsString = localStorage.getItem('usersState');
    usersStateAsString && setUsersState(JSON.parse(usersStateAsString));
    const accountStateAsString = localStorage.getItem('accountState');
    accountStateAsString && setAccountState(JSON.parse(accountStateAsString));
    const personsStateAsString = localStorage.getItem('personsState');
    personsStateAsString && setPersonsState(JSON.parse(personsStateAsString));
    const disputeStateAsString = localStorage.getItem('disputeState');
    disputeStateAsString && setDisputeState(JSON.parse(disputeStateAsString));
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      setCoBrandingState({});
      setPaymentsState({});
      setSplitPaymentsState({});
      setAccountsState({});
      setPayoutsState({});
      setReportsState({});
      setFeesState({});
      setTransactionsDashState({});
      setApplePayCertificateState({});
      setApplePayWebDomainState({});
      setCustomersState({});
      setCustomersDashState({});
      setPayoutAccountsState({});
      setBalancesState({});
      setSubscriptionState({});
      setPayoutMethodsState({});
      setProfileState({});
      setWebhooksState({});
      setUsersState({});
      setAccountState({});
      setPersonsState({});
      setDisputeState({});
    }
  }, [isSignedIn]);

  const value = {
    coBrandingState,
    setCoBrandingState,
    paymentsState,
    setPaymentsState,
    splitPaymentsState,
    setSplitPaymentsState,
    accountsState,
    setAccountsState,
    payoutsState,
    setPayoutsState,
    reportsState,
    setReportsState,
    transactionsDashState,
    setTransactionsDashState,
    feesState,
    setFeesState,
    applePayCertificateState,
    setApplePayCertificateState,
    applePayWebDomainState,
    setApplePayWebDomainState,
    customersState,
    setCustomersState,
    customersDashState,
    setCustomersDashState,
    payoutAccountsState,
    setPayoutAccountsState,
    balancesState,
    setBalancesState,
    subscriptionState,
    setSubscriptionState,
    payoutMethodsState,
    setPayoutMethodsState,
    profileState,
    setProfileState,
    webhooksState,
    setWebhooksState,
    usersState,
    setUsersState,
    accountState,
    setAccountState,
    personsState,
    setPersonsState,
    disputeState,
    setDisputeState,
  };
  return (
    <>
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    </>
  );
};

export { GlobalDataState, useGlobalDataState };
