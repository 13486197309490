import React, { useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { ConfirmUser } from '../components/onboarding/ConfirmUser';
import joinRyft from '../images/illustrations/join-ryft.png';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';

const ConfirmUserRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();

  const inviteName = localStorage.getItem('inviteName');
  const capitalizedInviteName =
    inviteName && inviteName.charAt(0).toUpperCase() + inviteName.slice(1);

  useEffect(() => {
    if (isSignedIn) {
      history.push('/');
    }
  }, []);

  return (
    <LayoutIllustration
      image={joinRyft}
      title={
        <>
          {capitalizedInviteName && (
            <>
              <span>{capitalizedInviteName} </span>we sent you a text message
            </>
          )}
          {!capitalizedInviteName && <>We sent you a text message</>}
        </>
      }
      subTitle={<>Enter the verification code below to complete joining</>}
    >
      <>
        <ConfirmUser />
      </>
    </LayoutIllustration>
  );
};

export { ConfirmUserRoute };
