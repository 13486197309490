import React, { useEffect, useState } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory, useParams } from 'react-router-dom';
import { Users } from './users';
import { Banking } from './banking';
import { Account } from './account';
import { MenuItemShape, MenuLayout } from '../../components/layouts/MenuLayout';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { profileStateShape } from '../../interfaces/profileState';
import { handleNotSignedIn } from '../../functions/helpers';
import { Capabilities } from './capabilities';

const SettingsRoute = (): React.ReactElement => {
  const { profileState } = useGlobalDataState();
  const history = useHistory();
  const { menuItemKey }: any = useParams();
  const { profile, isSignedIn } = useGlobalState();
  const [menuItems, setMenuItems] = useState<MenuItemShape[]>([]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  useEffect(() => {
    setMenuItems(getMenuItems(profileState));
  }, [profileState]);

  const getMenuItems = (profileState?: profileStateShape): MenuItemShape[] => {
    const menuItems = [];
    const permissions = new Set(
      profileState?.data?.user.permissions.map((p) => p.permission)
    );
    menuItems.push({
      key: 'account',
      name: 'Account',
      content: <Account />,
    });
    if (permissions.has('UsersView')) {
      menuItems.push({
        key: 'users',
        name: 'Users',
        content: <Users />,
      });
    }
    if (
      profileState?.data?.account.settings.payoutMethodsDisplayed &&
      permissions.has('PayoutMethodsView')
    ) {
      menuItems.push({
        key: 'banking',
        name: 'Banking',
        content: <Banking />,
      });
    }
    if (
      profile.processingModel !== 'Iso' &&
      permissions.has('CapabilitiesView')
    ) {
      menuItems.push({
        key: 'capabilities',
        name: 'Capabilities',
        content: <Capabilities />,
      });
    }
    return menuItems;
  };

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='SettingsRoute'>
        <div className='SettingsRoute--center'>
          <MenuLayout
            title='Settings'
            path='/settings'
            items={menuItems}
            itemKey={menuItemKey}
          />
        </div>
      </div>
    </Layout>
  );
};

export { SettingsRoute };
