import { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { FilterSubscriptions } from './FilterSubscriptions';
import { Subscriptions } from './Subscriptions';

import { useHistory } from 'react-router-dom';
import { useFilterSubscriptions } from '../../hooks/useFilterSubscriptions';
import { SideMenu } from '../layouts/SideMenu';
import { UserPermission } from '../../interfaces/profileState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';

const SubscriptionDetail = (): ReactElement => {
  const history = useHistory();
  const { profileState, subscriptionState } = useGlobalDataState();
  const [, isLoading, error, setFilter, isMore, getMore, ,] =
    useFilterSubscriptions();
  const [canModifySubscriptions, setCanModifySubscriptions] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleCreateSubscription = (): void => {
    history.push(`/subscriptions/create`);
  };

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      SubscriptionsModify: setCanModifySubscriptions,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  return (
    <div className='LayoutMain'>
      <div className='LayoutMain--grid'>
        <div className='LayoutMain--button'>
          <Button
            name='Create Subscription'
            click={handleCreateSubscription}
            disabled={
              !subscriptionState?.data?.settings?.enabled ||
              !canModifySubscriptions
            }
          />
        </div>
        <div className='LayoutMain--mobile-filter'>
          <Button
            name='Filter'
            color='GREY'
            click={handleMobileFilter}
            margin='0px'
          />
        </div>
        <div className='LayoutMain--filter'>
          <FilterSubscriptions setFilter={setFilter} />
        </div>
        <div className='LayoutMain--data'>
          {error ? (
            <div>Error: {error}</div>
          ) : (
            <Subscriptions
              subscriptionsArr={subscriptionState.data?.items ?? []}
              isMore={isMore}
              getMore={getMore}
              isLoading={isLoading}
            />
          )}
        </div>
        <SideMenu
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          position='LEFT'
          width='SUPER'
        >
          <div style={{ padding: '12px' }}>
            <FilterSubscriptions setFilter={setFilter} />
          </div>
        </SideMenu>
      </div>
    </div>
  );
};

export { SubscriptionDetail };
