import React, { useState, useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { JoinUser } from '../components/onboarding/JoinUser';
import { Expired } from '../components/onboarding/Expired';
import joinRyft from '../images/illustrations/join-ryft.png';
import { useHistory } from 'react-router-dom';

export interface InviteParameters {
  l: string; // the invite link id
  t: number; // expiry timestamp of the link
  ue: string; // the user's email address
  ur: string; // the user's role
  pat: 'Individual' | 'Business'; // the type of account (Individual or Business)
  pan: string; // the name of the account
  ufn: string; // the user's first name
  fufn: string | null; // the first name of the user who invited them, or null
  pn: string; // the platform name (used for cobranding only)
  pw: string; // the platform website URL (used for cobranding only)
  pi: string; // the platform's profile image URL (used for cobranding only)
}

const InviteUserRoute = (): React.ReactElement => {
  const history = useHistory();
  const [isValidParams, setIsValidParams] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<InviteParameters | null>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const rawQueryParams = Object.fromEntries(urlSearchParams);
    const rawPat = rawQueryParams.pat;

    if (rawPat === 'Individual' || rawPat === 'Business') {
      const expiryTimestamp = parseInt(rawQueryParams.t, 10);
      const isBeforeExpiry = Date.now() < expiryTimestamp * 1000; // Assuming the timestamp is in seconds, converting to milliseconds

      setQueryParams({
        l: rawQueryParams.l,
        t: expiryTimestamp,
        ue: rawQueryParams.ue,
        ur: rawQueryParams.ur,
        pat: rawPat,
        pan: rawQueryParams.pan,
        ufn: rawQueryParams.ufn,
        fufn: rawQueryParams.fufn || null,
        pn: rawQueryParams.pn,
        pw: rawQueryParams.pw,
        pi: rawQueryParams.pi,
      });
      localStorage.setItem('inviteName', rawQueryParams.ufn || '');
      setIsValidParams(isBeforeExpiry);
    }
  }, []);

  return (
    <LayoutIllustration
      image={joinRyft}
      title={
        <>
          {queryParams?.ufn}, you&#39;ve been invited to join {queryParams?.pan}{' '}
          {queryParams?.fufn && (
            <>
              {' '}
              by <span>{queryParams?.fufn}</span>
            </>
          )}
        </>
      }
      subTitle={
        <>
          Accepting this offer will grant you access to manage and monitor the
          processing of payments.
        </>
      }
    >
      <>
        {isValidParams && <JoinUser />}
        {!isValidParams && <Expired />}
      </>
    </LayoutIllustration>
  );
};

export { InviteUserRoute };
