import React, { ReactElement, useEffect } from 'react';
import {
  createMaskedPan,
  epochSecondsToDate,
  formatPrice,
  getCardSchemeIcon,
  pascalCaseToWords,
  truncateWord,
} from '../../functions/helpers';
import { useListPaymentTransactions } from '../../hooks/useListPaymentTransactions';
import { PaymentTransaction } from '../../interfaces/paymentState';
import { Copy } from '../forms/Copy';
import { CreateStatus } from '../layouts/CreateStatus';
import { DateTime } from '../layouts/DateTime';
import { getIssuerResponseCodeSummary } from '../../hooks/getIssuerResponseDescriptions';
import { getCurrency } from '../../functions/money-utility';
import { PaymentMethodWallet } from '../layouts/PaymentMethodWallet';
import { getAvsResponseCodeSummary } from '../../hooks/getAvsResponseCodeDescriptions';
import { getCvvResponseCodeDescription } from '../../hooks/getCvvResponseCodeDescriptions';

interface PaymentTransactionsProps {
  paymentSessionId: string;
  subAccountId?: string;
}

const PaymentTransactionsTab: React.FC<PaymentTransactionsProps> = ({
  paymentSessionId,
  subAccountId,
}) => {
  const { transactions, isLoading, error, listPaymentTransactions } =
    useListPaymentTransactions();

  useEffect(() => {
    listPaymentTransactions(paymentSessionId, subAccountId);
  }, [paymentSessionId]);

  const mapStatus = (status: string): ReactElement | undefined => {
    switch (status) {
      case 'Pending':
        return <CreateStatus title='Pending' color='LIGHTGREY' icon='TIME' />;
      case 'Succeeded':
        return <CreateStatus title='Succeeded' color='GREEN' icon='TICK' />;
      case 'Failed':
        return <CreateStatus title='Failed' color='RED' icon='EXCLAMATION' />;
      default:
        return undefined;
    }
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
    );
  }
  if (error) {
    return <div>{error || 'Failed to load'}</div>;
  }
  if (transactions.items.length <= 0) {
    return (
      <div className='PaymentTransactionsTab--empty'>
        {error || 'No transactions could be found'}
      </div>
    );
  }

  return (
    <div className='PaymentTransactionsTab'>
      <div className='PaymentTransactionsTab--items-grid'>
        {transactions.items.map(
          (transaction: PaymentTransaction, index: number) => (
            <div className='PaymentTransactionsTab--body' key={index}>
              <div className='PaymentTransactionsTab--body--header'>
                <h2>{pascalCaseToWords(transaction.type)}</h2>
                <h3>{mapStatus(transaction.status)}</h3>
              </div>
              <div className='PaymentTransactionsTab--body--middle'>
                <div className='PaymentTransactionsTab--body--middle-item'>
                  <div>Amount</div>
                  <div>
                    {formatPrice({
                      pence: transaction.amount,
                      currency: getCurrency(transaction.currency),
                    })}
                  </div>
                </div>
                <div className='PaymentTransactionsTab--body--middle-item'>
                  <div>When</div>
                  <div>
                    <DateTime
                      showTime={true}
                      value={epochSecondsToDate(transaction.createdTimestamp)}
                    />
                  </div>
                </div>
                {transaction.captureType && (
                  <div className='PaymentTransactionsTab--body--middle-item'>
                    <div>Capture Type</div>
                    <div>{transaction.captureType}</div>
                  </div>
                )}
                {transaction.processingDetail?.issuerResponseCode && (
                  <div className='PaymentTransactionsTab--body--middle-item'>
                    <div>Issuer Response Code</div>
                    <div>
                      {transaction.processingDetail.issuerResponseCode} (
                      {
                        getIssuerResponseCodeSummary(
                          transaction.processingDetail.issuerResponseCode
                        ).title
                      }
                      )
                    </div>
                  </div>
                )}
                {transaction.paymentMethod?.card &&
                  transaction.paymentMethod?.checks?.avsResponseCode && (
                    <div className='PaymentTransactionsTab--body--middle-item'>
                      <div>AVS Response Code</div>
                      <div>
                        {transaction.paymentMethod.checks.avsResponseCode} (
                        {getAvsResponseCodeSummary(
                          transaction.paymentMethod.checks.avsResponseCode,
                          transaction.paymentMethod.card.scheme
                        )}
                        )
                      </div>
                    </div>
                  )}
                {transaction.paymentMethod?.checks?.cvvResponseCode && (
                  <div className='PaymentTransactionsTab--body--middle-item'>
                    <div>CVV Response Code</div>
                    <div>
                      {transaction.paymentMethod.checks.cvvResponseCode} (
                      {getCvvResponseCodeDescription(
                        transaction.paymentMethod.checks.cvvResponseCode
                      )}
                      )
                    </div>
                  </div>
                )}
                {transaction.processingDetail?.authorizationCode && (
                  <div className='PaymentTransactionsTab--body--middle-item'>
                    <div>Authorization Code</div>
                    <div>{transaction.processingDetail.authorizationCode}</div>
                  </div>
                )}
              </div>
              {transaction.paymentMethod?.card && (
                <div className='PaymentTransactionsTab--body--middle-item'>
                  <div>Payment method</div>
                  <div className='PaymentTransactionsTab--body--card'>
                    <img
                      src={getCardSchemeIcon(
                        transaction.paymentMethod.card.scheme
                      )}
                    ></img>
                    {transaction.paymentMethod.wallet && (
                      <PaymentMethodWallet
                        value={transaction.paymentMethod.wallet}
                      />
                    )}
                    {createMaskedPan(
                      transaction.paymentMethod.card.scheme,
                      transaction.paymentMethod.card.last4
                    )}
                  </div>
                </div>
              )}
              <div className='PaymentTransactionsTab--body--footer'>
                <div>Id</div>
                <div>
                  <Copy
                    type='BUTTON_WITH_TEXT'
                    buttonSize='MEDIUM'
                    value={transaction.id}
                    text={truncateWord(transaction.id, 10, 20)}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PaymentTransactionsTab;
