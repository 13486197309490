import { ReactElement } from 'react';

export interface PropsShape {
  name?: string;
}

const NavAlert = ({ name }: PropsShape): ReactElement => {
  return <> {name}</>;
};
export { NavAlert };
