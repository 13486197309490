import { ReactElement } from 'react';
import { createMaskedPan, getCardSchemeIcon } from '../../functions/helpers';
import { PaymentMethod } from '../../interfaces/paymentMethodsState';

interface SearchComponentProps {
  paymentMethods: PaymentMethod[] | undefined;
  selected: string;
  setSelected?: (setSelected: string) => void;
}

function SelectCard({
  paymentMethods,
  selected,
  setSelected = (): void => {},
}: SearchComponentProps): ReactElement {
  return (
    <div className='SelectCard'>
      {paymentMethods && paymentMethods.length > 0 && (
        <p>Select a saved card</p>
      )}

      {paymentMethods?.map((methods) => (
        <li
          key={methods.id}
          onClick={(): void => setSelected(methods.id)}
          className={
            selected === methods.id
              ? 'SelectCard--scheme SelectCard--selected'
              : 'SelectCard--scheme'
          }
        >
          <div className='SelectCard--scheme'>
            <img
              src={getCardSchemeIcon(methods.card.scheme)}
              alt={methods.card.scheme}
            />
          </div>
          <div className='SelectCard--last4'>
            {createMaskedPan(methods.card.scheme, methods.card.last4)}
          </div>
          <div className='SelectCard--default'>
            {/* <span>Default</span> */}
          </div>
          <div className='SelectCard--expires'>Expires End</div>
          <div className='SelectCard--date'>
            {methods.card.expiryMonth} / {methods.card.expiryYear}
          </div>
        </li>
      ))}
    </div>
  );
}

export { SelectCard };
