import React, { useEffect, useState } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory, useParams } from 'react-router-dom';
import { Webhooks } from './webhooks';
import { ApiKeys } from './apikeys';
import { EventsRoute } from './events';
import { MenuItemShape, MenuLayout } from '../../components/layouts/MenuLayout';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { ApplePaySettings } from '../../components/layouts/ApplePaySettings';
import { handleNotSignedIn } from '../../functions/helpers';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';
import { DeveloperOverview } from './overview';

const DeveloperRoute = (): React.ReactElement => {
  const history = useHistory();
  const { menuItemKey }: any = useParams();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const [menuItems, setMenuItems] = useState<MenuItemShape[]>([]);

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !permissions.has('DeveloperSettingsView') ||
      (profile.group && profile.group !== 'STANDARD')
    ) {
      history.push('/');
    }
  }, [isSignedIn, profile]);

  useEffect(() => {
    setMenuItems(getMenuItems());
  }, [profile]);

  const getMenuItems = (): MenuItemShape[] => {
    const menuItems = [];

    menuItems.push({
      key: 'overview',
      name: 'Overview',
      content: <DeveloperOverview />,
    });

    menuItems.push({
      key: 'apikeys',
      name: 'API Keys',
      content: <ApiKeys />,
    });

    menuItems.push({
      key: 'webhooks',
      name: 'Webhooks',
      content: <Webhooks />,
    });

    menuItems.push({
      key: 'events',
      name: 'Events',
      content: <EventsRoute />,
    });

    menuItems.push({
      key: 'apple-pay',
      name: 'Apple Pay',
      content: <ApplePaySettings />,
    });

    return menuItems;
  };

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='SettingsRoute'>
        <div className='SettingsRoute--center'>
          <MenuLayout
            title='Developer'
            path='/developer'
            items={menuItems}
            itemKey={menuItemKey}
          />
        </div>
      </div>
    </Layout>
  );
};

export { DeveloperRoute };
