import { ReactElement } from 'react';

export interface PropsShape {
  labels?: boolean;
  goPrevious: () => {};
  goNext: () => {};
  previousDisabled?: boolean;
  nextDisabled?: boolean;
}

const Pagination = ({
  labels = false,
  goPrevious,
  goNext,
  previousDisabled,
  nextDisabled,
}: PropsShape): ReactElement => {
  return (
    <div className={labels ? 'Pagination Pagination--labels' : 'Pagination'}>
      {labels && (
        <div
          onClick={goPrevious}
          className={
            previousDisabled
              ? 'Pagination--labels-disabled'
              : 'Pagination--labels-enabled'
          }
        >
          Previous
        </div>
      )}
      <div
        onClick={goPrevious}
        className={
          previousDisabled
            ? 'Pagination--previous Pagination--previous-disabled'
            : 'Pagination--previous'
        }
      ></div>
      <div
        onClick={goNext}
        className={
          nextDisabled
            ? 'Pagination--next Pagination--next-disabled'
            : 'Pagination--next'
        }
      ></div>
      {labels && (
        <div
          onClick={goNext}
          className={
            nextDisabled
              ? 'Pagination--labels-disabled'
              : 'Pagination--labels-enabled'
          }
        >
          Next
        </div>
      )}
    </div>
  );
};
export { Pagination };
