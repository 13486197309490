import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
import { SplitPaymentsShape } from '../interfaces/splitPaymentState';

export interface SplitPaymentsFilter {
  pageSize: number;
  ascending: boolean;
  splitPaymentsStartAfter: string;
  startTimestamp: string;
  endTimestamp: string;
  splitPaymentId: string;
}

interface ApiResponse {
  splitPayments: SplitPaymentsApiResponse;
}

interface SplitPaymentsApiResponse {
  items: SplitPaymentsShape[];
  paginationToken: string;
}

const useGetSplitPayments = (): [
  boolean,
  string | undefined,
  (filter: SplitPaymentsFilter) => void,
  boolean,
  () => void
] => {
  const { splitPaymentsState, setSplitPaymentsState } = useGlobalDataState();

  const [isMore, setIsMore] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [response, isLoading, apiError, sendRequest] =
    useApiRequest<ApiResponse>();

  const setFilter = (filter: SplitPaymentsFilter): void => {
    setAction('applyFilter');
    let url = '/v1/split-payments';

    if (filter.startTimestamp || filter.endTimestamp) {
      url += `${url.includes('?') ? '&' : '?'}startTimestamp=${
        filter.startTimestamp || 0
      }&endTimestamp=${
        filter.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter.pageSize) {
      url += `${url.includes('?') ? '&' : '?'}pageSize=${filter.pageSize}`;
    }

    if (filter.ascending !== undefined) {
      url += `${url.includes('?') ? '&' : '?'}ascending=${filter.ascending}`;
    }

    if (filter.splitPaymentId !== '') {
      url += `${url.includes('?') ? '&' : '?'}splitPaymentId=${
        filter.splitPaymentId
      }`;
    }

    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('lastSplitPaymentsUrl', url);
  };

  const getMore = (): any => {
    setAction('getMore');
    const lastUrl = localStorage.getItem('lastSplitPaymentsUrl');
    const lastPaginationToken = localStorage.getItem(
      'lastSplitPaymentsPaginationToken'
    );
    if (lastUrl && lastPaginationToken) {
      sendRequest({
        url: `${lastUrl}&splitPaymentsStartAfter=${lastPaginationToken}`,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    if (response) {
      const paginationToken = response.data.splitPayments.paginationToken;
      localStorage.setItem(
        'lastSplitPaymentsPaginationToken',
        paginationToken || ''
      );
      setIsMore(!!paginationToken);
      let updated = response.data.splitPayments.items;
      if (action === 'getMore' && splitPaymentsState.data?.items) {
        updated = splitPaymentsState.data.items.concat(updated);
      }
      setSplitPaymentsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: paginationToken,
          items: updated,
        },
      }));
    }
  }, [response]);

  return [isLoading, apiError, setFilter, isMore, getMore];
};

export { useGetSplitPayments };
