import { ReactElement, Dispatch, SetStateAction, useState } from 'react';

export interface PropsShape {
  label?: boolean;
  name: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeHolder?: string;
  error?: string;
  description?: string;
  focus?: boolean;
  info?: string;
  optional?: boolean;
  upperCase?: boolean;
  strong?: boolean;
  autoComplete?: string;
}

export const AllowedSpecialChars = new Set('!@#$%^&*()_');

const Password = ({
  label = false,
  name,
  value,
  setValue,
  placeHolder,
  error,
  description,
  focus = false,
  info,
  optional = false,
  upperCase = false,
  strong = false,
  autoComplete,
}: PropsShape): ReactElement => {
  const [show, setShow] = useState(false);

  const [lowercase, setLowercase] = useState(false);
  const [special, setSpecial] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [minimum, setMinimum] = useState(false);

  const checkValue = (e: string): void => {
    // (?=.*[a-z])        // use positive look ahead to see if at least one lower case letter exists
    // (?=.*[A-Z])        // use positive look ahead to see if at least one upper case letter exists
    // (?=.*\d)           // use positive look ahead to see if at least one digit exists
    // (?=.*\W])        // use positive look ahead to see if at least one non-word character exists
    const lowercaseTest = new RegExp('(?=.*[a-z])');
    if (lowercaseTest.test(e)) setLowercase(true);
    else setLowercase(false);

    let hasSpecialChar = false;
    // eslint-disable-next-line no-loops/no-loops
    for (const letter of e) {
      if (AllowedSpecialChars.has(letter)) {
        hasSpecialChar = true;
      }
    }
    setSpecial(hasSpecialChar);

    const upperCaseTest = new RegExp('(?=.*[A-Z])');
    if (upperCaseTest.test(e)) setUppercase(true);
    else setUppercase(false);

    const numberTest = new RegExp('(?=.*[0-9])');
    if (numberTest.test(e)) setNumber(true);
    else setNumber(false);

    if (e.length >= 8) setMinimum(true);
    else setMinimum(false);
  };
  return (
    <div className='Password'>
      {info && <>{info}</>}
      {error && <div className='Password--error'>{error}</div>}
      {optional && <div className='Password--optional'>Optional</div>}
      {label && (
        <label className='Password--label' htmlFor={name}>
          {name}
        </label>
      )}
      <input
        type={show ? 'text' : 'password'}
        id={name}
        name={name}
        value={upperCase ? value.toUpperCase() : value}
        placeholder={placeHolder}
        onChange={(e): void => {
          setValue(e.target.value);
          checkValue(e.target.value);
        }}
        className='Password--input'
        autoFocus={focus}
        spellCheck={false}
        autoComplete={autoComplete}
      ></input>
      <div
        onClick={(): void => setShow(!show)}
        className={show ? 'Password--toggle-hide' : 'Password--toggle-show'}
      ></div>
      {strong && (
        <div className='Password--strong'>
          <div
            className={
              lowercase ? 'Password--strong-off' : 'Password--strong-on'
            }
          >
            1 lowercase character
          </div>
          <div
            className={special ? 'Password--strong-off' : 'Password--strong-on'}
          >
            1 special character
          </div>
          <div
            className={
              uppercase ? 'Password--strong-off' : 'Password--strong-on'
            }
          >
            1 uppercase character
          </div>
          <div
            className={number ? 'Password--strong-off' : 'Password--strong-on'}
          >
            1 number
          </div>
          <div
            className={minimum ? 'Password--strong-off' : 'Password--strong-on'}
          >
            8 characters minimum
          </div>
        </div>
      )}
      {description && (
        <small className='TextInput--description'>{description}</small>
      )}
    </div>
  );
};
export { Password };
