import { ReactElement } from 'react';
import { DateTime } from './DateTime';
import { Customer } from '../../interfaces/customersState';
import { Copy } from '../forms/Copy';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { epochSecondsToDate } from '../../functions/helpers';

export interface PropsShape {
  data: Customer;
}

const CustomerDetailLayout = ({ data }: PropsShape): ReactElement => {
  return (
    <div>
      <div className='CustomerDetail--title-grid'></div>
      <table className='CustomerDetail--table'>
        <tbody>
          <tr>
            <td>Email</td>
            <td>
              <Copy
                type='BUTTON_WITH_TEXT'
                text={data.email}
                value={data.email}
              />
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(data?.createdTimestamp!)}
              />
            </td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(data?.lastUpdatedTimestamp!)}
              />
            </td>
          </tr>
          {data.firstName && (
            <tr>
              <td>First name</td>
              <td>{data.firstName}</td>
            </tr>
          )}
          {data.lastName && (
            <tr>
              <td>Last name</td>
              <td>{data.lastName}</td>
            </tr>
          )}
          {data.mobilePhoneNumber && (
            <tr>
              <td>Mobile number</td>
              <td>{formatPhoneNumberIntl(data.mobilePhoneNumber)}</td>
            </tr>
          )}
          {data.homePhoneNumber && (
            <tr>
              <td>Home number</td>
              <td>{formatPhoneNumberIntl(data?.homePhoneNumber)}</td>
            </tr>
          )}
          {data.defaultPaymentMethod && (
            <tr>
              <td>Default Payment Method</td>
              <td>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  text={data.defaultPaymentMethod}
                  value={data.defaultPaymentMethod}
                />
              </td>
            </tr>
          )}
          {data?.metadata &&
            Object.entries(data.metadata).map(([key, value]) => (
              <tr className='meta' key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export { CustomerDetailLayout };
