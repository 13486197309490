import { ReactElement, useEffect, useRef, useState } from 'react';
import { CardCorners } from '../layouts/CardCorners';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { DateTime } from '../layouts/DateTime';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { SideMenu } from '../layouts/SideMenu';
import { Pagination } from '../layouts/Pagination';
import { PayoutDetail } from './PayoutDetail';
import { Button } from '../forms/Button';
import { formatPrice } from '../../functions/helpers';
import { FilterShape } from '../../interfaces/payoutsState';
import { getCurrency } from '../../functions/money-utility';
import { useListPayouts } from '../../hooks/useListPayouts';
import {
  handlePayoutStatus,
  payoutSchemeName,
} from '../../functions/payoutHelpers';
import { obfuscatedAccountNumber } from '../../functions/payoutMethodHelpers';

interface getPayoutsShape {
  page?: 'PREVIOUS' | 'NEXT';
}
interface PageTrackerShape {
  payoutsStartAfter?: string;
}

const Payouts = (): ReactElement => {
  const { payoutsState, setPayoutsState } = useGlobalDataState();
  const history = useHistory();
  const params = window.location.search.split('=')[1];
  const scrollContainer = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<FilterShape>();
  const [filterOn, setFilterOn] = useState<boolean>(false);
  const [page, setPage] = useState<'PREVIOUS' | 'NEXT' | undefined>();
  const [pageTracker, setPageTracker] = useState<PageTrackerShape[]>([]);
  const { payouts, isLoading, error, listPayouts } = useListPayouts();

  useEffect(() => {
    setFilterData(payoutsState.data?.filter);
  }, [payoutsState?.data?.filter?.when]);

  useEffect(() => {
    getPayouts({});
  }, []);

  useEffect(() => {
    if (params?.substring(0, 3) === 'po_') {
      changeSelected(params);
      setIsOpen(true);
    }
  }, [params]);

  useEffect(() => {
    if (!page) {
      setPageTracker([
        {
          payoutsStartAfter: payouts?.paginationToken,
        },
      ]);
    }
    if (page === 'NEXT') {
      const tempPageTracker = [...pageTracker];
      tempPageTracker.push({
        payoutsStartAfter: payouts?.paginationToken,
      });
      setPageTracker(tempPageTracker);
    }
  }, [payouts]);

  useEffect(() => {
    if (
      filterData?.payoutId ||
      filterData?.startTimestamp ||
      filterData?.endTimestamp ||
      filterData?.accountId
    ) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    if (
      filterData?.payoutId ||
      filterData?.accountId ||
      filterData?.startTimestamp ||
      filterData?.endTimestamp
    ) {
      getPayouts({});
    }
    if (payoutsState?.data?.filter?.when !== filterData?.when) {
      getPayouts({});
    }
  }, [JSON.stringify(filterData)]);

  useEffect(() => {
    setPayoutsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        remoteControl: {
          ...prevState?.data?.remoteControl,
          previousDisabled: pageTracker.length === 1,
          nextDisabled:
            pageTracker[pageTracker.length - 1]?.payoutsStartAfter ===
            undefined,
        },
      },
    }));
  }, [pageTracker]);

  useEffect(() => {
    if (isOpen) {
      const index = payoutsState?.data?.items?.findIndex(
        (x) => x === payoutsState?.data?.selected
      );
      if (index !== undefined) {
        scrollContainer?.current?.scroll({
          top: index * 35,
          behavior: 'smooth',
        });
      }
    }
  }, [payoutsState.data?.selected]);

  useEffect(() => {
    if (
      (pageTracker.length !== 1 && page === 'PREVIOUS') ||
      (pageTracker[pageTracker.length - 1]?.payoutsStartAfter !== undefined &&
        page === 'NEXT')
    ) {
      getPayouts({ page });
    }
  }, [payoutsState.data?.remoteControl?.issuedAt]);

  const goBack = (): any => {
    window.history.pushState({ id: 0 }, '', `/payouts/`);
  };

  const getPayouts = async ({ page }: getPayoutsShape): Promise<void> => {
    let payoutsStartAfter = undefined;
    setPage(page);
    if (page === 'NEXT') {
      payoutsStartAfter = pageTracker[pageTracker.length - 1].payoutsStartAfter;
    }
    if (page === 'PREVIOUS') {
      const tempPageTracker = [...pageTracker];
      tempPageTracker.pop();
      if (tempPageTracker.length > 1) {
        payoutsStartAfter =
          tempPageTracker[tempPageTracker.length - 2].payoutsStartAfter;
      }
      setPageTracker(tempPageTracker);
    }
    listPayouts({
      pageSize: 25,
      ascending: false,
      payoutId: filterData?.payoutId,
      subAccountId: filterData?.accountId,
      startTimestamp: filterData?.startTimestamp,
      endTimestamp: filterData?.endTimestamp,
      payoutsStartAfter,
    });
  };

  const changeSelected = (e: string): void => {
    const selectedArr = payoutsState.data?.items.filter((obj) => {
      return obj.id === e;
    });

    if (selectedArr && selectedArr.length !== 0) {
      const selected = selectedArr[0];
      const newURL = `/payouts/payout/${selected.id}`;
      const newState = { id: selected.id };
      window.history.pushState(newState, '', newURL);

      setPayoutsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected,
        },
      }));
      if (isMobile()) {
        history.push(`/payouts/payout/${selected.id}`);
      } else {
        setIsOpen(true);
      }
    }
  };

  return (
    <div className='Payouts'>
      <CardCorners
        bottomLeft={
          <div>Showing {payoutsState.data?.items?.length} Payouts</div>
        }
        bottomRight={
          <Pagination
            goPrevious={(): Promise<void> => getPayouts({ page: 'PREVIOUS' })}
            previousDisabled={pageTracker.length === 1}
            goNext={(): Promise<void> => getPayouts({ page: 'NEXT' })}
            nextDisabled={
              pageTracker[pageTracker.length - 1]?.payoutsStartAfter ===
              undefined
            }
          />
        }
      >
        <>
          {isLoading && !error && (
            <>
              <div className='Payouts--no-results'>
                <h2>Loading</h2>
                <p>Fetching your payouts, one second...</p>
              </div>
            </>
          )}
          {!isLoading && error && (
            <>
              <div className='Payouts--no-results'>
                <h2>Error</h2>
                <p>{error}</p>
              </div>
            </>
          )}
          {!isLoading && !error && (
            <>
              {payoutsState.data?.items &&
                payoutsState.data?.items?.length !== 0 && (
                  <div>
                    <div className='Payouts--titles'>
                      <div>Status</div>
                      <div>Amount</div>
                      <div className='Payouts--titles-mobile'>Bank</div>
                      <div className='Payouts--titles-mobile'>Account</div>
                      <div className='Payouts--titles-mobile'>Scheme</div>
                      <div>When</div>
                    </div>
                    <div className='Payouts--container' ref={scrollContainer}>
                      {payoutsState?.data?.items?.map((item, index) => (
                        <div
                          className={
                            item.id === payoutsState.data?.selected?.id
                              ? 'Payouts--items Payouts--items-selected'
                              : 'Payouts--items'
                          }
                          key={index}
                          onClick={(): void => changeSelected(`${item.id}`)}
                        >
                          <div className='Payouts--items-item'>
                            {handlePayoutStatus(item.status)}
                          </div>
                          <div className='Payouts--items-item'>
                            {formatPrice({
                              pence: +item.amount,
                              currency: getCurrency(item.currency),
                            })}
                          </div>
                          <div className='Payouts--items-item Payouts--items-item-mobile'>
                            {item.payoutMethod?.bankAccount.bankName ||
                              item.payoutMethod?.bankAccount.bankId ||
                              '-'}
                          </div>
                          <div className='Payouts--items-item Payouts--items-item-mobile'>
                            {item.payoutMethod?.bankAccount
                              ? obfuscatedAccountNumber(
                                  item.payoutMethod.bankAccount
                                )
                              : '-'}
                          </div>
                          <div className='Payouts--items-item Payouts--items-item-mobile'>
                            {payoutSchemeName(item.scheme)}
                          </div>
                          <div className='Payouts--items-item'>
                            <DateTime
                              showTime
                              value={epochSecondsToDate(item.createdTimestamp)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {payoutsState?.data?.items?.length === 0 && (
                <div className='Payouts--no-results'>
                  {!filterOn && !isLoading && (
                    <>
                      <h2>No payouts yet</h2>
                      <p>
                        We couldn't find any payouts, check our docs on how to
                        get paid
                      </p>
                      <a
                        href='https://developer.ryftpay.com/docs/reference-guides/admin/getting-paid'
                        target='_blank'
                        rel='nofollow'
                      >
                        <Button name='Open Ryft Docs' />
                      </a>
                    </>
                  )}
                  {filterOn && !isLoading && (
                    <>
                      <h2>No results</h2>
                      <p>Please clear or adjust your filter</p>
                    </>
                  )}
                </div>
              )}
            </>
          )}
          <SideMenu
            isOpen={isOpen}
            onClose={goBack}
            setIsOpen={setIsOpen}
            position='RIGHT'
            width='REGULAR'
          >
            <PayoutDetail />
          </SideMenu>
        </>
      </CardCorners>
    </div>
  );
};
export { Payouts };
