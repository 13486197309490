import { ReactElement, useState, FormEvent } from 'react';
import { Heading } from './Heading';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { useHistory } from 'react-router-dom';
import { get } from '../../functions/callApi';

const SendInvite = (): ReactElement => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const cancel = (): void => {};
  const invite = async (): Promise<void> => {
    setIsLoading(true);
    setEmailError('');

    const callApi = async (): Promise<void> => {
      const getBody = (): string => {
        return JSON.stringify(email);
      };

      const response = await get('/v1/payout-account', 'PATCH', getBody());
      if (response.status === 200) {
        setIsLoading(false);
        history.push('/subaccounts');
      }
    };
    if (email === '') setEmailError('Required');
    if (email) callApi();
  };
  return (
    <div className='ChangePassword'>
      <Heading
        title='Send Invite'
        subtitle='Invite a person or a company to become a sub-account'
      />
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          invite();
        }}
      >
        <TextInput
          label
          name='Email'
          type='email'
          value={email}
          setValue={setEmail}
          error={emailError}
          placeHolder='Email address'
        />
        <Button name='Send' click={invite} loading={isLoading} />
        <Button name='Cancel' click={cancel} loading={isLoading} color='GREY' />
      </form>
    </div>
  );
};
export { SendInvite };
