import { PersonShape } from '../interfaces/personsState';
import {
  BusinessRole,
  RequiredVerificationPerson,
} from '../interfaces/accountState';
import { OptionShape } from '../interfaces/state';

const availableBusinessRoles = (
  isOnboarding: boolean,
  persons?: PersonShape[],
  selectedPerson?: PersonShape
): OptionShape[] => {
  const availableRoles = [];
  const existingRoles = persons?.flatMap((p: PersonShape) => p.businessRoles);
  const businessContactCount =
    existingRoles?.filter((r: BusinessRole) => r === 'BusinessContact')
      .length ?? 0;
  const directorCount =
    existingRoles?.filter((r: BusinessRole) => r === 'Director').length ?? 0;
  const ultimateBeneficialOwnerCount =
    existingRoles?.filter((r: BusinessRole) => r === 'UltimateBeneficialOwner')
      .length ?? 0;
  if (
    !isOnboarding &&
    (businessContactCount === 0 ||
      selectedPerson?.businessRoles?.includes('BusinessContact'))
  ) {
    availableRoles.push({
      name: 'Business Contact',
      value: 'BusinessContact',
    });
  }
  if (
    directorCount < 5 ||
    selectedPerson?.businessRoles?.includes('Director')
  ) {
    availableRoles.push({
      name: 'Director',
      value: 'Director',
    });
  }
  if (
    ultimateBeneficialOwnerCount < 4 ||
    selectedPerson?.businessRoles?.includes('UltimateBeneficialOwner')
  ) {
    availableRoles.push({
      name: 'Ultimate Beneficial Owner',
      value: 'UltimateBeneficialOwner',
    });
  }
  return availableRoles;
};

const hasPersonWithRole = (
  persons: PersonShape[],
  role: BusinessRole
): boolean =>
  persons.some((p: PersonShape) =>
    p.businessRoles.some((r: BusinessRole) => r == role)
  );

const businessRoleRequired = (
  requiredPersons: RequiredVerificationPerson[],
  role: BusinessRole
): boolean =>
  requiredPersons.some(
    (requiredPerson: RequiredVerificationPerson) => requiredPerson.role === role
  );

const businessRolesRequired = (
  requiredPersons: RequiredVerificationPerson[],
  person: PersonShape
): boolean =>
  requiredPersons
    .map((p: RequiredVerificationPerson) => p.role)
    .filter((r: BusinessRole) => !new Set(person.businessRoles).has(r)).length >
  0;

const personName = (person?: PersonShape): string =>
  person?.middleNames
    ? `${person.firstName} ${person.middleNames} ${person.lastName}`
    : `${person?.firstName} ${person?.lastName}`;

const getLetterOfAuthWarningText = (show: boolean): string | undefined =>
  show
    ? 'A letter of authorization will be required when verifying this individual. We recommend assigning the Business Contact role to a Director to avoid delays in verification.'
    : undefined;

const businessRoleToString = (businessRole: BusinessRole): string => {
  switch (businessRole) {
    case 'BusinessContact':
      return 'Business Contact';
    case 'UltimateBeneficialOwner':
      return 'Ultimate Beneficial Owner';
    case 'Director':
      return 'Director';
  }
};

const businessRoleDescription = (businessRole: BusinessRole): string => {
  switch (businessRole) {
    case 'BusinessContact':
      return 'Someone who will act on behalf of the business as a point of contact, we strongly recommend that this is assigned to a Director';
    case 'UltimateBeneficialOwner':
      return 'Someone who owns or controls more than 25% of your business, or exercises significant control of your business';
    case 'Director':
      return 'Someone who is a member of the governing board';
  }
};

export {
  availableBusinessRoles,
  hasPersonWithRole,
  businessRoleRequired,
  businessRolesRequired,
  personName,
  getLetterOfAuthWarningText,
  businessRoleToString,
  businessRoleDescription,
};
