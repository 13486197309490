import { ReactElement, useEffect, useState } from 'react';
import { ConditionalButton } from '../forms/ConditionalButton';
import { Button } from '../forms/Button';
import { DatePicker } from '../forms/DatePicker';
import { TextInput } from '../forms/TextInput';
import { SelectInput } from '../forms/SelectInput';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { SideMenu } from '../layouts/SideMenu';
import { PayoutIneligibleReason } from '../../interfaces/payoutsState';
import { epochSecondsToDate } from '../../functions/helpers';
import { UserPermission } from '../../interfaces/profileState';
import { UserRole } from '../../interfaces/userState';
import { formatPrice } from '../../functions/helpers';

export const getPayoutIneligibleReasonTitle = (
  reason: PayoutIneligibleReason
): string | undefined => {
  switch (reason.reason) {
    case 'PayoutMethodRequired':
      return `No ${reason.currencyObject?.code} bank account`;
    case 'PayoutMethodInvalid':
      return `Invalid ${reason.currencyObject?.code} bank account`;
    case 'VerificationRequired':
      return 'Verification required';
    case 'NotAcceptedTermsOfService':
      return 'Terms of service not accepted';
    case 'VerificationUnderReview':
      return 'Verification under review';
    case 'PayoutsDisabled':
      return 'Payouts disabled';
    case 'AccountInactive':
      return 'Account frozen';
    case 'ProviderDetailsRequired':
      return 'Account setup incomplete';
    case 'InsufficientFundsForPayoutFee':
      return `Insufficient ${reason.currencyObject?.code} balance for fee`;
    case 'BelowMinimumPayoutAmount':
    case 'InsufficientFundsForPayout':
      return `Insufficient ${reason.currencyObject?.code} balance`;
    default:
      return undefined;
  }
};

export const getPayoutIneligibleReasonBody = (
  reason: PayoutIneligibleReason,
  role: UserRole | undefined
): string | undefined => {
  const minPayout =
    reason.minimumPayoutAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.minimumPayoutAmount,
          currency: reason.currencyObject,
        })
      : '';
  const balance =
    reason.balanceAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.balanceAmount,
          currency: reason.currencyObject,
        })
      : '';
  const fee =
    reason.feeAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.feeAmount,
          currency: reason.currencyObject,
        })
      : '';
  switch (reason.reason) {
    case 'PayoutMethodRequired':
      return role === 'Owner'
        ? `Please click here to add a ${reason.currencyObject?.code} bank account to receive payouts in ${reason.currencyObject?.code}`
        : `An account owner needs to add a ${reason.currencyObject?.code} bank account to receive payouts in ${reason.currencyObject?.code}`;
    case 'PayoutMethodInvalid':
      return role === 'Owner'
        ? `Your ${reason.currencyObject?.code} bank account has some invalid details, please click here to update them`
        : `Your ${reason.currencyObject?.code} bank account has some invalid details, please ask an account owner to update them`;
    case 'VerificationRequired':
      return role === 'Owner'
        ? `Please click here and provide a few more details so we can verify your account`
        : `Please ask an account owner to login to provide a few more details so we can verify your account`;
    case 'VerificationUnderReview':
      return `We are currently reviewing your account, please click here to see more detail`;
    case 'PayoutsDisabled':
      return `Your ability to create payouts has been disabled, please click here to contact us`;
    case 'AccountInactive':
      return `Your account has been temporarily frozen, possibly due to fraud screening, please click here to contact us`;
    case 'ProviderDetailsRequired':
      return `Your account setup is incomplete, please click here to contact us`;
    case 'BelowMinimumPayoutAmount':
      return minPayout && balance
        ? `A minimum of ${minPayout} balance is required for a payout, but you only have ${balance}`
        : 'You do not have sufficient funds in your balance to create a payout';
    case 'InsufficientFundsForPayoutFee':
      return fee && balance
        ? `A fee of ${fee} is required for any payout, but you only have ${balance} in your pending balances to cover the fee`
        : 'You do not have sufficient funds in your balance to cover the payout fee';
    case 'InsufficientFundsForPayout':
      return 'You do not have sufficient funds in your balance to create a payout';
    case 'NotAcceptedTermsOfService':
      return role === 'Owner'
        ? 'Please click here to review and accept the terms of service'
        : 'You need to review and accept the terms of service before payouts can be made';
    default:
      return undefined;
  }
};

export const getPayoutIneligibleReasonLink = (
  reason: PayoutIneligibleReason,
  role: UserRole | undefined
): string => {
  switch (reason.reason) {
    case 'PayoutMethodRequired':
      return role === 'Owner'
        ? `/settings/banking/add-account?returnTo=/payouts&currency=${reason.currencyObject?.code}`
        : '';
    case 'PayoutMethodInvalid':
      return role === 'Owner'
        ? `/settings/banking/edit-account/${reason.payoutMethodId}?returnTo=/payouts`
        : '';
    case 'VerificationRequired':
      return role === 'Owner' ? `/settings/account` : '';
    case 'VerificationUnderReview':
      return role === 'Owner' ? `/settings/account` : '';
    case 'PayoutsDisabled':
    case 'AccountInactive':
    case 'ProviderDetailsRequired':
      return '/contact';
    case 'BelowMinimumPayoutAmount':
    case 'InsufficientFundsForPayout':
    case 'InsufficientFundsForPayoutFee':
    default:
      return '';
  }
};

const FilterPayouts = (): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const { profileState, payoutsState, balancesState, setPayoutsState } =
    useGlobalDataState();
  const [accountId, setAccountId] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('myAccount');
  const [startTimestamp, setStartTimestamp] = useState<number | undefined>(
    payoutsState.data?.filter?.startTimestamp
  );
  const [endTimestamp, setEndTimestamp] = useState<number | undefined>(
    payoutsState.data?.filter?.endTimestamp
  );
  const [payoutId, setPayoutId] = useState<string>('');
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [showCreatePayout, setShowCreatePayout] = useState(false);
  const setFilter = (filter: any): void => {
    setPayoutsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        filter,
      },
    }));
  };

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      PayoutsModify: setShowCreatePayout,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  useEffect(() => {
    let fixedPayoutId: string = '';
    let fixedAccountId: string = '';

    if (payoutId.length === 29) {
      fixedPayoutId = payoutId;
    }
    if (accountId.length === 39) {
      fixedAccountId = accountId;
    }

    const filter = {
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      accountId: fixedAccountId,
      payoutId: fixedPayoutId,
      when: Date.now(),
    };
    setFilter(filter);
  }, [endTimestamp, startTimestamp, payoutId, accountId]);

  const clearFilters = (): void => {
    setOpenFilter(false);
    setStartTimestamp(undefined);
    setEndTimestamp(undefined);
    setAccountId('');
    setPayoutId('');
    const filter = {
      startTimestamp: undefined,
      endTimestamp: undefined,
      accountId: '',
      payoutId: '',
      when: Date.now(),
    };
    setFilter(filter);
  };
  const toggleDateRange = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const calcPulse = (): any => {
    const calcPulseItem = (reason: PayoutIneligibleReason): any => {
      return {
        title: getPayoutIneligibleReasonTitle(reason),
        body: getPayoutIneligibleReasonBody(
          reason,
          profileState?.data?.user.role
        ),
        link: getPayoutIneligibleReasonLink(
          reason,
          profileState?.data?.user.role
        ),
      };
    };
    const pulse: any = [];
    balancesState.data?.payoutEligibility?.ineligibleReasons.forEach(
      (currentItem: PayoutIneligibleReason) => {
        pulse.push(calcPulseItem(currentItem));
      }
    );
    return pulse;
  };
  const filterForm = (): ReactElement => {
    return (
      <div>
        <div className='FilterPayouts--title'>Filter By</div>
        <div>
          <div className='FilterPayouts--dropdown-date'>
            <div onClick={toggleDateRange}>
              <DatePicker
                type='START'
                name='Date from'
                value={startTimestamp}
                label
                setValue={setStartTimestamp}
                notAfter={
                  endTimestamp ? epochSecondsToDate(endTimestamp) : new Date()
                }
              />
              <DatePicker
                type='END'
                name='Date to'
                value={endTimestamp}
                label
                setValue={setEndTimestamp}
                notBefore={
                  startTimestamp
                    ? epochSecondsToDate(startTimestamp)
                    : undefined
                }
                notAfter={new Date()}
              />
            </div>
            {accountType === 'myAccount' && (
              <div>
                <TextInput
                  name='Payout id'
                  label
                  value={payoutId}
                  setValue={setPayoutId}
                  placeHolder='po_'
                />
              </div>
            )}
            {profile.group === 'STANDARD' && (
              <div>
                <SelectInput
                  label
                  name='Account type'
                  value={accountType}
                  setValue={setAccountType}
                  options={[
                    { value: 'myAccount', name: 'My Account' },
                    { value: 'subAccounts', name: 'Sub-accounts' },
                  ]}
                />
              </div>
            )}
            {accountType === 'subAccounts' && (
              <div>
                <TextInput
                  name='Account id'
                  label
                  value={accountId}
                  setValue={setAccountId}
                  placeHolder='ac_'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='FilterPayouts'>
      {showCreatePayout && (
        <ConditionalButton
          show={balancesState.data?.payoutEligibility?.showCreatePayout}
          enabled={balancesState.data?.payoutEligibility?.enableCreatePayout}
          conditionalText={balancesState.data?.payoutEligibility?.statusMessage}
          name='Create Payout'
          margin='0px'
          click={(): void => history.push('/pay-me')}
          pulse={calcPulse()}
        />
      )}

      <div className='FilterPayouts--mobile-filter'>
        <Button
          name='Filter'
          color='GREY'
          margin='0'
          click={(): void => setOpenFilter(true)}
        />
        <SideMenu
          isOpen={openFilter}
          setIsOpen={setOpenFilter}
          position='LEFT'
          width='SUPER'
        >
          <div className='FilterPayouts--mobile-form'>
            {filterForm()}
            <Button
              name='Filter'
              color='BLUE'
              margin='12px 0 0 0'
              click={(): void => setOpenFilter(false)}
            />
            <div style={{ marginTop: '24px' }}>
              <Button
                color='GREY'
                name='Clear'
                margin='0px'
                click={clearFilters}
              />
            </div>
          </div>
        </SideMenu>
      </div>

      <div className='FilterPayouts--filter'>
        {filterForm()}
        <div style={{ marginTop: '24px' }}>
          <Button color='GREY' name='Clear' margin='0px' click={clearFilters} />
        </div>
      </div>
    </div>
  );
};
export { FilterPayouts };
