import { useEffect, useRef, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import {
  Customer,
  CustomerWithPaymentMethods,
} from '../interfaces/customersState';
import { PaymentMethod } from '../interfaces/paymentMethodsState';

function useGetCustomerWithPaymentMethodsById(
  customerId?: string
): [
  boolean,
  Customer | undefined,
  PaymentMethod[] | undefined,
  string | undefined
] {
  const [customer, setCustomer] = useState<Customer>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [response, isLoading, error, sendRequest] =
    useApiRequest<CustomerWithPaymentMethods>();

  const prevCustomerIdRef = useRef<string>();

  useEffect(() => {
    if (response && response.data) {
      setCustomer(response.data.customer);
      setPaymentMethods(response.data.paymentMethods?.items);
    }
  }, [response]);

  useEffect(() => {
    if (customerId !== prevCustomerIdRef.current) {
      prevCustomerIdRef.current = customerId;

      if (customerId) {
        sendRequest({
          url: `/v1/customers/${customerId}`,
          method: HttpMethod.GET,
        });
      }
    }
  }, [customerId, sendRequest]);

  return [isLoading, customer, paymentMethods, error];
}

export { useGetCustomerWithPaymentMethodsById };
