import { Params } from '@sentry/react/types/types';
import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../../components/forms/Button';
import { ServerError } from '../../../../components/forms/ServerError';
import { get } from '../../../../functions/callApi';
import { useGlobalDataState } from '../../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../../layout-illustration';
import { handleNotSignedIn } from '../../../../functions/helpers';

const DeletePaymentMethodRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const params: Params = useParams();
  const customerId = params?.customerId;
  const returnTo = query.get('returnTo') || `/customers/${customerId}`;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const [serverError, setServerError] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const paymentMethodId = params?.paymentMethodId;

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!params?.paymentMethodId || !permissions.has('PaymentMethodsModify')) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  const deletePaymentMethod = async (): Promise<void> => {
    if (!paymentMethodId) {
      return;
    }
    try {
      setIsDeleting(true);
      const apiUrl = `/v1/payment-methods/${paymentMethodId}`;
      const response = await get(apiUrl, 'DELETE');
      if (response?.status === 200) {
        history.push(returnTo);
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      {!paymentMethodId && <div className='AppRoute--notfound'>Error 404</div>}
      {paymentMethodId && (
        <LayoutIllustration
          title={
            <>
              Delete <span>Payment Method</span>
            </>
          }
          subTitle={
            <>
              Are you sure you want to delete {paymentMethodId}? This will
              permanently remove the payment method.
            </>
          }
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={serverError} />
            <Button
              name='Delete'
              click={deletePaymentMethod}
              color='RED'
              loading={isDeleting}
            />
            <Button
              name='Cancel'
              click={(): void => history.push(returnTo)}
              color='GREY'
            />
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { DeletePaymentMethodRoute };
