import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
import { AccountShape } from '../interfaces/accountState';

export interface SubAccountFilter {
  pageSize?: number;
  ascending?: boolean;
  subAccountsStartAfter: string;
  startTimestamp: string;
  endTimestamp: string;
  subAccountId: string;
  email: string;
}

interface ApiResponse {
  items: AccountShape[];
  paginationToken: string;
}

const useGetSubAccounts = (): [
  boolean,
  string | undefined,
  (filter?: SubAccountFilter) => void,
  boolean,
  () => void
] => {
  const defaultPageSize: number = 25;
  const defaultAscending: boolean = false;
  const { accountsState, setAccountsState } = useGlobalDataState();

  const [isMore, setIsMore] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [response, isLoading, apiError, sendRequest] =
    useApiRequest<ApiResponse>();

  const getSubAccounts = (filter?: SubAccountFilter): void => {
    setAction('applyFilter');
    let url = `/v1/accounts?pageSize=${
      filter?.pageSize ?? defaultPageSize
    }&ascending=${
      filter?.ascending !== undefined ? filter.ascending : defaultAscending
    }`;

    if (filter?.startTimestamp || filter?.endTimestamp) {
      url += `&startTimestamp=${filter.startTimestamp || 0}&endTimestamp=${
        filter.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter?.subAccountId) {
      url += `&accountId=${filter.subAccountId}`;
    }

    if (filter?.email) {
      url += `&email=${encodeURIComponent(filter.email)}`;
    }

    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('lastSubAccountsUrl', url);
  };

  const getMore = (): void => {
    setAction('getMore');
    const lastUrl = localStorage.getItem('lastSubAccountsUrl');
    const lastPaginationToken = localStorage.getItem(
      'lastSubAccountsPaginationToken'
    );
    if (lastUrl && lastPaginationToken) {
      sendRequest({
        url: `${lastUrl}&accountsStartAfter=${lastPaginationToken}`,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    if (response) {
      const paginationToken = response.data.paginationToken;
      localStorage.setItem(
        'lastSubAccountsPaginationToken',
        paginationToken || ''
      );
      setIsMore(!!paginationToken);
      let updated = response.data.items;
      if (action === 'getMore' && accountsState.data?.items) {
        updated = accountsState.data.items.concat(updated);
      }
      setAccountsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: paginationToken,
          items: updated,
        },
      }));
    }
  }, [response]);

  return [isLoading, apiError, getSubAccounts, isMore, getMore];
};

export { useGetSubAccounts };
