import { ReactElement, useEffect } from 'react';
import { Layout } from '../../../layout';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { SplitPaymentDetail } from '../../../components/dataGrids/SplitPaymentDetail';
import { handleNotSignedIn, isMobile } from '../../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGetSplitPayments } from '../../../hooks/useGetSplitPayments';
import { SplitPaymentsShape } from '../../../interfaces/splitPaymentState';

const SplitPaymentRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState, splitPaymentsState } = useGlobalDataState();
  const [, , setFilter, ,] = useGetSplitPayments();
  const params: any = useParams();
  useEffect(() => {
    if (!isMobile())
      history.push(`/split-payments?id=${params.splitPaymentId}`);
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('TransactionsView') || profile.group !== 'SUB') {
      history.push('/');
    }
  }, [isSignedIn]);

  useEffect(() => {
    setFilter({
      pageSize: 1,
      ascending: false,
      splitPaymentsStartAfter: '',
      startTimestamp: '',
      endTimestamp: '',
      splitPaymentId: params?.splitPaymentId,
    });
  }, []);

  return (
    <Layout isSignedIn={isSignedIn}>
      <SplitPaymentDetail
        splitPayment={splitPaymentsState.data?.items.find(
          (splitPayment: SplitPaymentsShape) =>
            splitPayment.id === params.splitPaymentId
        )}
        hasPrevious={false}
        handlePrevious={(): void => {}}
        hasNext={false}
        handleNext={(): void => {}}
      />
    </Layout>
  );
};

export { SplitPaymentRoute };
