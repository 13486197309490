import { ReactElement, useState, FormEvent, useEffect } from 'react';

import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { isEmail } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';

const ForgotPassword = (): ReactElement => {
  const history = useHistory();
  const { cognito } = useGlobalState();
  const [email, setEmail] = useState<string>(
    localStorage.getItem('email') || ''
  );
  const [emailError, setEmailError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    setServerError(cognito.error);
  }, [cognito.error]);

  useEffect(() => {
    if (email) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [email]);

  const reset = async (): Promise<void> => {
    setEmailError('');
    setServerError('');
    const isValidEmail = await isEmail(email);
    if (!isValidEmail) {
      setEmailError('invalid email');
      return;
    }
    if (isValidEmail) {
      setIsLoading(true);
      const result = await cognito.forgot(email);
      setIsLoading(false);
      if (result) {
        localStorage.setItem('email', email);
        history.push('/password');
      }
    }
  };
  return (
    <div className='ForgotPassword'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          reset();
        }}
      >
        <StepPosition steps={2} position={1} />
        <TextInput
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          placeHolder='Email address'
          focus
        />
        <Button
          name='Reset Password'
          click={reset}
          loading={isLoading}
          disabled={btnDisabled}
        />
      </form>
      <div
        onClick={(): void => history.push('/')}
        className='ForgotPassword--center'
      >
        Remembered your password? <a>Sign In</a>
      </div>
    </div>
  );
};
export { ForgotPassword };
