import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import {
  AccountShape,
  accountStateShape,
  EntityType,
} from '../interfaces/accountState';
import { useGlobalDataState } from './useGlobalDataState';

interface UseEditAccountReturnType {
  accountData: AccountShape | null;
  isLoading: boolean;
  error: string | undefined;
  apiEditAccount: (body: EditAccountRequest) => Promise<void>;
}

export interface EditAccountRequest {
  entityType?: EntityType;
  business?: EditBusinessRequest;
  individual?: EditIndividualRequest;
  settings?: EditSettingsRequest;
  termsOfService?: EditTermsOfServiceRequest;
  capabilities?: CapabilitiesRequest;
}

export interface EditBusinessRequest {
  name?: string | null;
  type?: string | null;
  registrationNumber?: string | null;
  registrationDate?: string;
  registeredAddress?: AccountAddressRequest;
  contactEmail?: string | null;
  phoneNumber?: string | null;
  tradingName?: string;
  tradingCountries?: string[];
  websiteUrl?: string;
  documents?: AccountDocumentRequest[];
}

export interface EditIndividualRequest {
  firstName?: string | null;
  middleNames?: string | null;
  lastName?: string | null;
  email?: string | null;
  dateOfBirth?: string | null;
  countryOfBirth?: string;
  gender?: string | null;
  nationalities?: string[];
  address?: AccountAddressRequest;
  phoneNumber?: string | null;
  documents?: AccountDocumentRequest[];
}

interface EditSettingsRequest {
  payouts: EditPayoutSettingsRequest;
}

interface EditTermsOfServiceRequest {
  acceptance: EditTermsOfServiceAcceptanceRequest;
}

interface EditTermsOfServiceAcceptanceRequest {
  ipAddress: string;
  userAgent: string;
  when?: number;
}

interface CapabilitiesRequest {
  amexPayments: {
    requested: boolean;
  };
}

interface EditPayoutSettingsRequest {
  schedule: EditPayoutScheduleRequest;
}

interface EditPayoutScheduleRequest {
  type: string;
}

export interface AccountAddressRequest {
  lineOne: string;
  lineTwo?: string;
  city: string;
  region?: string;
  postalCode: string;
  country: string;
}

export interface AccountDocumentRequest {
  type: string;
  front: string;
  back?: string;
  country?: string;
}

function useEditAccount(): UseEditAccountReturnType {
  const { setAccountState } = useGlobalDataState();
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<AccountShape>();

  const [accountData, setAccountData] = useState<AccountShape | null>(null);

  const apiEditAccount = async (body: EditAccountRequest): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/account',
        method: HttpMethod.PATCH,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while updating the account:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setAccountData(apiResponse.data);
      setAccountState((prevState: accountStateShape) => ({
        ...prevState,
        data: apiResponse.data,
      }));
    }
  }, [apiResponse]);

  return {
    accountData,
    isLoading,
    error,
    apiEditAccount,
  };
}

export { useEditAccount };
