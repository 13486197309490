import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { DeletedPayoutMethod } from '../interfaces/payoutMethodsState';

interface UseDeletePayoutMethodReturnType {
  deletedPayoutMethod: DeletedPayoutMethod | null;
  isLoading: boolean;
  error: any;
  deletePayoutMethod: (id: string) => Promise<void>;
}

function useDeletePayoutMethod(): UseDeletePayoutMethodReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<DeletedPayoutMethod>();

  const [payoutMethodData, setPayoutMethodData] =
    useState<DeletedPayoutMethod | null>(null);

  const apiDeletePayoutMethod = async (id: string): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/account/payout-methods/${id}`,
        method: HttpMethod.DELETE,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while deleting the payout method:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setPayoutMethodData(apiResponse.data);
    }
  }, [apiResponse]);

  return {
    deletedPayoutMethod: payoutMethodData,
    isLoading,
    error,
    deletePayoutMethod: apiDeletePayoutMethod,
  };
}

export { useDeletePayoutMethod };
