import { ReactElement } from 'react';

export interface PropsShape {
  click?: () => void;
  loading?: boolean;
  type?: 'prev' | 'next';
  disabled: boolean;
}

const PaginationButton = ({
  click,
  loading,
  type = 'prev',
  disabled,
}: PropsShape): ReactElement => {
  return (
    <button
      type='button'
      className={
        loading
          ? `PaginationButton--loading`
          : `PaginationButton--${type.toLowerCase()}`
      }
      disabled={disabled}
      onClick={(): void => (click ? click() : undefined)}
    ></button>
  );
};
export { PaginationButton };
