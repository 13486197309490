import { useState, useEffect } from 'react';
import { Customer } from '../interfaces/customersState';
import { useApiRequest, HttpMethod } from './useApiRequest';

interface GetCustomerByIdReturnType {
  customer: Customer | undefined;
  isLoading: boolean;
  error: string | undefined;
  fetchCustomer: (id: string) => Promise<void>;
}

function useGetCustomerById(): GetCustomerByIdReturnType {
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest();

  const fetchCustomer = async (id: string): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/customers/${id}`,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while fetching the custome:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse?.data?.customer) {
      setCustomer(apiResponse.data.customer);
    }
  }, [apiResponse]);

  return { customer, isLoading, error, fetchCustomer };
}

export { useGetCustomerById };
