import { ReactElement, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import hamburgerImage from '../../images/hamburger.svg';
import closeImage from '../../images/close.svg';

export interface PropsShape {
  options: { value: string; name: string }[];
  value: string;
  setValue: (setValue: string) => void;
}

const NavMain = ({ options, value, setValue }: PropsShape): ReactElement => {
  const menuTransition = useRef(null);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState<boolean>(false);

  const close = (): void => {
    setIsUserMenuVisible(!isUserMenuVisible);
  };
  return (
    <>
      <div
        className='Main'
        onClick={(): void => setIsUserMenuVisible(!isUserMenuVisible)}
      >
        <img src={hamburgerImage} />
      </div>
      <CSSTransition
        nodeRef={menuTransition}
        in={isUserMenuVisible}
        timeout={300}
        classNames='Main--SideMenuTransition'
        unmountOnExit
      >
        <div className='Main--sidemenu' ref={menuTransition}>
          <div className='Main--sidemenu-overlay' onClick={close}></div>
          <div className='Main--sidemenu-background'>
            <ul className='Main--list'>
              <div onClick={close} className='Main--close'>
                {/* TODO: make close button have a grey background and match avatar size */}
                <img src={closeImage} />
              </div>
              {options.map(
                (option: { value: string; name: string }, index: number) => {
                  return (
                    <li
                      onClick={(): void => {
                        if (option.value !== value) {
                          setIsUserMenuVisible(!isUserMenuVisible);
                          setTimeout(function () {
                            setValue(option.value);
                          }, 250);
                        }
                      }}
                      value={option.value.replace(/\s/g, '')}
                      key={index}
                      className={
                        option.value === value
                          ? 'Main--selected'
                          : 'Main--option'
                      }
                    >
                      {option.name}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
export { NavMain };
