import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { isPassword } from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Password } from '../forms/Password';
import { useHistory } from 'react-router-dom';

const ChangePassword = (): ReactElement => {
  const history = useHistory();
  const { cognito } = useGlobalState();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [oldPasswordError, setOldPasswordError] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const returnTo: string = '/profile/security';

  useEffect(() => {
    setServerError(cognito.error);
  }, [cognito.error]);

  useEffect(() => {
    if (oldPassword && newPassword && !oldPasswordError && !newPasswordError) {
      setBtnDisabled(false);
    }
    if (!oldPassword || !newPassword) {
      setBtnDisabled(true);
    }
  }, [oldPassword, newPassword]);

  const change = async (): Promise<void> => {
    setOldPasswordError('');
    setNewPasswordError('');
    setServerError('');
    let valid = true;
    if (!(await isPassword(oldPassword))) {
      setOldPasswordError('Old Password is invalid');
      valid = false;
    }
    if (!(await isPassword(newPassword))) {
      setNewPasswordError('New Password is invalid');
      valid = false;
    }
    if (valid) {
      try {
        setIsLoading(true);
        const result = await cognito.changePassword(oldPassword, newPassword);
        result && history.push(returnTo);
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className='ChangePassword'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          change();
        }}
      >
        <Password
          name='OldPassword'
          value={oldPassword}
          setValue={setOldPassword}
          error={oldPasswordError}
          placeHolder='Old password'
          focus
        />
        <Password
          name='NewPassword'
          value={newPassword}
          setValue={setNewPassword}
          error={newPasswordError}
          placeHolder='New password'
          strong
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '200px 100px',
            gridGap: '12px',
          }}
        >
          <Button
            name='Save'
            click={change}
            loading={isLoading}
            disabled={btnDisabled}
          />
          <Button
            name='Cancel'
            click={(): void => history.push(returnTo)}
            loading={isLoading}
            color='GREY'
          />
        </div>
      </form>
    </div>
  );
};
export { ChangePassword };
