import React, { useEffect, useState } from 'react';
import { useGetPaymentMethodById } from '../../hooks/useGetPaymentMethodById';
import { DateTime } from '../layouts/DateTime';
import {
  createMaskedPan,
  epochSecondsToDate,
  getCardSchemeIcon,
} from '../../functions/helpers';
import { Copy } from '../forms/Copy';
import {
  CardScheme,
  CardWalletShape,
  ExternalApiAddress,
} from '../../interfaces/state';
import { PaymentMethodWallet } from '../layouts/PaymentMethodWallet';
import { MiniMenu } from '../forms/MiniMenu';
import { PaymentMethodChecksShape } from '../../interfaces/paymentState';
import { getAvsResponseCodeSummary } from '../../hooks/getAvsResponseCodeDescriptions';
import { getCvvResponseCodeDescription } from '../../hooks/getCvvResponseCodeDescriptions';

interface PaymentMethodData {
  scheme: CardScheme;
  last4: string;
  expiryMonth: string;
  expiryYear: string;
  name?: string;
  billingAddress?: ExternalApiAddress;
  id?: string;
  stored?: boolean;
  hideStoredField?: boolean;
  wallet?: CardWalletShape;
  createdTimestamp?: number;
  isDefault?: boolean;
  customerId?: string;
  checks?: PaymentMethodChecksShape;
}

interface RenderedData {
  paymentMethod: PaymentMethodData | undefined;
}

interface PaymentMethodProps {
  id?: string;
  data?: PaymentMethodData;
  canDelete?: boolean;
}

const PaymentMethodTab: React.FC<PaymentMethodProps> = ({
  id,
  data,
  canDelete,
}) => {
  const [renderedData, setRenderedData] = useState<RenderedData>({
    paymentMethod: data,
  });
  const { paymentMethod, isLoading, error, fetchPaymentMethod } =
    useGetPaymentMethodById();

  useEffect(() => {
    if (id) {
      fetchPaymentMethod(id);
    }
  }, [id]);

  useEffect(() => {
    if (paymentMethod && paymentMethod.id === id && paymentMethod?.card) {
      setRenderedData({
        paymentMethod: {
          scheme: paymentMethod.card.scheme,
          last4: paymentMethod.card.last4,
          expiryMonth: paymentMethod.card.expiryMonth,
          expiryYear: paymentMethod.card.expiryYear,
          billingAddress: paymentMethod.billingAddress,
          id: paymentMethod.id,
          hideStoredField: true,
          createdTimestamp: paymentMethod.createdTimestamp,
          customerId: paymentMethod.customerId,
          checks: paymentMethod.checks,
        },
      });
    } else {
      setRenderedData({ paymentMethod: data });
    }
  }, [paymentMethod, data]);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
    );
  }
  if (error && !renderedData.paymentMethod) {
    return <div>{error || 'Failed to load payment method'}</div>;
  }
  if (!renderedData.paymentMethod) {
    return <div>{error || 'No payment method'}</div>;
  }

  return (
    <div className='PaymentMethodSharedTab'>
      <div className='PaymentMethodSharedTab--header'>
        <img src={getCardSchemeIcon(renderedData.paymentMethod.scheme)}></img>
        <h2>
          {createMaskedPan(
            renderedData.paymentMethod.scheme,
            renderedData.paymentMethod.last4
          )}
        </h2>
        {renderedData.paymentMethod.isDefault && (
          <div className='PaymentMethodSharedTab--default'></div>
        )}
      </div>
      {canDelete && renderedData.paymentMethod?.customerId && (
        <div className='PaymentMethodSharedTab--menu'>
          <MiniMenu
            options={[
              {
                value: `/customers/${renderedData.paymentMethod.customerId}/payment-methods/${renderedData.paymentMethod.id}/delete`,
                name: 'Delete',
              },
            ]}
          />
        </div>
      )}
      <div className='PaymentMethodSharedTab--middle'>
        {renderedData.paymentMethod.createdTimestamp && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>Created</div>
            <div>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  renderedData.paymentMethod.createdTimestamp
                )}
              />
            </div>
          </div>
        )}
        <div className='PaymentMethodSharedTab--middle-item'>
          <div>Expires</div>
          <div>
            {renderedData.paymentMethod.expiryMonth} /{' '}
            {renderedData.paymentMethod.expiryYear}
          </div>
        </div>
        {renderedData.paymentMethod.wallet && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>Wallet</div>
            <div>
              <PaymentMethodWallet value={renderedData.paymentMethod.wallet} />
            </div>
          </div>
        )}
        {renderedData.paymentMethod.name && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>Name on card</div>
            <div>{renderedData.paymentMethod.name}</div>
          </div>
        )}
        <div className='PaymentMethodSharedTab--middle-item'>
          <>
            <div>Billing Address</div>
            {renderedData.paymentMethod.billingAddress ? (
              <div>
                {renderedData.paymentMethod?.billingAddress?.firstName}{' '}
                {renderedData.paymentMethod?.billingAddress?.lastName}
                {(renderedData.paymentMethod?.billingAddress?.firstName ||
                  renderedData.paymentMethod?.billingAddress?.lastName) && (
                  <br />
                )}
                {renderedData.paymentMethod?.billingAddress?.lineOne}
                {renderedData.paymentMethod?.billingAddress?.lineOne && <br />}
                {renderedData.paymentMethod?.billingAddress?.lineTwo}
                {renderedData.paymentMethod?.billingAddress?.lineTwo && <br />}
                {renderedData.paymentMethod?.billingAddress?.city}
                {renderedData.paymentMethod?.billingAddress?.city && <br />}
                {renderedData.paymentMethod?.billingAddress?.region}
                {renderedData.paymentMethod?.billingAddress?.region && <br />}
                {renderedData.paymentMethod?.billingAddress?.postalCode} <br />
                {renderedData.paymentMethod?.billingAddress?.country}
              </div>
            ) : (
              <div>Not provided</div>
            )}
          </>
        </div>
        {!renderedData.paymentMethod.hideStoredField && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>Stored for future transactions</div>
            <div>{renderedData.paymentMethod.stored ? 'Yes' : 'No'}</div>
          </div>
        )}
        {renderedData.paymentMethod?.checks?.avsResponseCode && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>AVS Response Code</div>
            <div>
              {renderedData.paymentMethod.checks.avsResponseCode} (
              {getAvsResponseCodeSummary(
                renderedData.paymentMethod.checks.avsResponseCode,
                renderedData.paymentMethod.scheme
              )}
              )
            </div>
          </div>
        )}
        {renderedData.paymentMethod?.checks?.cvvResponseCode && (
          <div className='PaymentMethodSharedTab--middle-item'>
            <div>CVV Response Code</div>
            <div>
              {renderedData.paymentMethod.checks.cvvResponseCode} (
              {getCvvResponseCodeDescription(
                renderedData.paymentMethod.checks.cvvResponseCode
              )}
              )
            </div>
          </div>
        )}
      </div>
      {renderedData.paymentMethod.id && (
        <div
          className='PaymentMethodSharedTab--footer'
          style={{ marginTop: '12px' }}
        >
          <div>Id</div>
          <div>
            <Copy
              type='BUTTON_WITH_TEXT'
              buttonSize='MEDIUM'
              value={renderedData.paymentMethod.id}
              text={renderedData.paymentMethod.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodTab;
