import { ReactElement } from 'react';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';

export interface PropsShape {
  children?: any;
  title: any;
  subtitle: any;
  button?: string;
  role?: string;
  lastSeen?: any;
  buttonClick?: any;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  buttonError?: string;
  inner?: boolean;
  border?: boolean;
  borderStyle?: 'LINE' | 'DASHED';
  avatar?: {
    url: string;
    highlighted: boolean;
  };
  layoutSetting?: 'FULL' | 'SIMPLE';
}

const SettingsItem = ({
  children,
  title,
  subtitle,
  button,
  role,
  lastSeen,
  buttonClick,
  buttonDisabled = false,
  buttonLoading = false,
  buttonError,
  inner = false,
  border = false,
  borderStyle,
  avatar,
  layoutSetting = 'FULL',
}: PropsShape): ReactElement => {
  const getBorderStyle = (): string =>
    border === false
      ? 'none'
      : borderStyle === 'DASHED'
      ? '1px dashed #bed9f7'
      : '1px solid #e1e1e1';

  return (
    <div
      className={`SettingsItem--${inner ? 'inner' : 'outer'}`}
      style={{
        borderBottom: getBorderStyle(),
      }}
    >
      <div
        className={
          avatar
            ? `SettingsItem--avatar-grid-${layoutSetting.toLowerCase()}`
            : 'SettingsItem--left'
        }
      >
        {avatar && (
          <img
            src={avatar.url}
            className={
              avatar.highlighted
                ? 'SettingsItem--avatar-blue'
                : 'SettingsItem--avatar'
            }
          />
        )}
        <div className='SettingsItem--title'>{title}</div>
        <div className='SettingsItem--subtitle'>{subtitle}</div>
        {avatar && (
          <>
            <div className='SettingsItem--role'>{role}</div>
            <div className='SettingsItem--seen'>{lastSeen}</div>
          </>
        )}
        {button && (
          <>
            {buttonError && <ServerError error={buttonError} />}
            <Button
              name={button}
              click={buttonClick}
              disabled={buttonDisabled}
              loading={buttonLoading}
            />
          </>
        )}
      </div>
      {children && <div className='SettingsItem--right'>{children}</div>}
    </div>
  );
};
export { SettingsItem };
