import React, { ChangeEvent, useEffect, useState } from 'react';
import { OptionShape } from '../../interfaces/state';

interface RadioButtonsProps {
  name: string | JSX.Element;
  options: OptionShape[];
  onSelect: (value: string) => void;
  layout: 'horizontal' | 'vertical';
  optional?: boolean;
  description?: string;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  name,
  options,
  onSelect,
  layout,
  optional,
  description,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleChange = (value: string): void => {
    setSelectedValue(value);
    onSelect(value);
  };

  useEffect(() => {
    if (options.length === 1) {
      handleChange(options[0].value);
    }
  }, [options]);

  return (
    <div className='RadioButtons'>
      <fieldset className='RadioButtons--fieldset'>
        <legend className='RadioButtons--legend'>{name}</legend>
        {optional && <div>Optional</div>}
        {options.map((option, index) => (
          <label
            key={index}
            className={`RadioButtons--${layout}`}
            htmlFor={option.value}
          >
            <input
              id={option.value}
              type='radio'
              className={'RadioButtons--radio'}
              value={option.value}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                handleChange(e.target.value)
              }
              checked={options.length === 1 || selectedValue === option.value}
            />
            <span className='RadioButtons--label'>{option.name}</span>
          </label>
        ))}
      </fieldset>
      {description && (
        <div className='RadioButtons--description'>{description}</div>
      )}
    </div>
  );
};

export { RadioButtons };
