import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { PersonShape } from '../interfaces/personsState';
import { useGlobalDataState } from './useGlobalDataState';

interface UseDeletePersonReturnType {
  personData: PersonShape | null;
  isLoading: boolean;
  error: any;
  apiDeletePerson: (id: string) => Promise<void>;
}

function useDeletePerson(): UseDeletePersonReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PersonShape>();
  const { personsState, setPersonsState } = useGlobalDataState();
  const [personData, setPersonData] = useState<PersonShape | null>(null);

  const apiDeletePerson = async (id: string): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/account/persons/${id}`,
        method: HttpMethod.DELETE,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while deleting the person:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const deletedPerson = apiResponse.data;
      setPersonData(deletedPerson);
      if (!personsState.data) {
        return;
      }
      const existingPersons = personsState.data.items;
      const existingPersonIndex = existingPersons.findIndex(
        (p: PersonShape) => p.id == deletedPerson.id
      );
      if (existingPersonIndex > -1) {
        existingPersons.splice(existingPersonIndex, 1);
      }
      setPersonsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          items: existingPersons,
        },
      }));
    }
  }, [apiResponse]);

  return {
    personData,
    isLoading,
    error,
    apiDeletePerson,
  };
}

export { useDeletePerson };
