import React from 'react';
import { useGetCustomerWithPaymentMethodsById } from '../../hooks/useGetCustomerWithPaymentMethodsById';
import PaymentMethodTab from './PaymentMethod';

interface PaymentMethodsProps {
  customerId: string;
  canDelete?: boolean;
}

const PaymentMethodsTab: React.FC<PaymentMethodsProps> = ({
  customerId,
  canDelete,
}) => {
  const [isLoading, customer, paymentMethods, error] =
    useGetCustomerWithPaymentMethodsById(customerId);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
    );
  }
  if (error) {
    return <div>{error || 'Failed to load'}</div>;
  }
  if (!paymentMethods || (paymentMethods?.length ?? 0) <= 0) {
    return (
      <div className='PaymentTransactionsTab--empty'>
        {error || 'No saved payment methods for this customer'}
      </div>
    );
  }

  return (
    <div className='PaymentTransactionsTab'>
      <div className='PaymentTransactionsTab--items-grid'>
        {paymentMethods.map((method) => (
          <PaymentMethodTab
            data={{
              id: method.id,
              scheme: method.card.scheme,
              expiryMonth: method.card.expiryMonth,
              expiryYear: method.card.expiryYear,
              last4: method.card.last4,
              billingAddress: method.billingAddress,
              hideStoredField: true,
              isDefault: customer?.defaultPaymentMethod === method.id,
              createdTimestamp: method.createdTimestamp,
              customerId: method.customerId,
              checks: method.checks,
            }}
            canDelete={canDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodsTab;
