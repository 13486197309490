import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import { useGlobalState } from '../hooks/useGlobalState';
import { useHistory } from 'react-router';
import { SplitPayments } from '../components/dataGrids/SplitPayments';
import { useGlobalDataState } from '../hooks/useGlobalDataState';
import { FilterSplitPayments } from '../components/dataGrids/FilterSplitPayments';
import { SideMenu } from '../components/layouts/SideMenu';
import { Button } from '../components/forms/Button';
import { useGetSplitPayments } from '../hooks/useGetSplitPayments';
import { handleNotSignedIn } from '../functions/helpers';
import { stopReplayIfActive } from '../functions/sessionReplayHelpers';

const SplitPaymentsRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { profileState, splitPaymentsState } = useGlobalDataState();
  const [isLoading, error, setFilter, isMore, getMore] = useGetSplitPayments();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  const handleMobileFilter = (): void => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('TransactionsView') || profile.group !== 'SUB') {
      history.push('/');
    }
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='LayoutMain'>
        <div className='LayoutMain--grid LayoutMain--grid-no-button'>
          <div className='LayoutMain--mobile-filter'>
            <Button
              name='Filter'
              color='GREY'
              click={handleMobileFilter}
              margin='0px'
            />
          </div>
          <div className='LayoutMain--filter'>
            <FilterSplitPayments setFilter={setFilter} />
          </div>
          <div className='LayoutMain--data'>
            <SplitPayments
              splitPayments={splitPaymentsState.data?.items ?? []}
              isMore={isMore}
              getMore={getMore}
              isLoading={isLoading}
              error={error}
            />
          </div>
          <SideMenu
            isOpen={isFilterOpen}
            setIsOpen={setIsFilterOpen}
            position='LEFT'
            width='SUPER'
          >
            <div style={{ padding: '12px' }}>
              <FilterSplitPayments setFilter={setFilter} />
            </div>
          </SideMenu>
        </div>
      </div>
    </Layout>
  );
};

export { SplitPaymentsRoute };
