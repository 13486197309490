import React, { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { TextInput } from '../../../components/forms/TextInput';
import { Button } from '../../../components/forms/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { useGlobalState } from '../../../hooks/useGlobalState';
import {
  RefundPaymentRequest,
  useRefundPayment,
} from '../../../hooks/useRefundPayment';
import { Params } from '@sentry/react/types/types';
import { PaymentsShape } from '../../../interfaces/paymentState';
import { formatPrice, handleNotSignedIn } from '../../../functions/helpers';
import { StepPosition } from '../../../components/forms/StepPosition';
import { SuccessMessage } from '../../../components/forms/SuccessMessage';
import {
  canBeRefunded,
  refundsSupported,
} from '../../../functions/paymentHelpers';

const RefundPaymentRoute = (): ReactElement => {
  const { paymentsState, profileState } = useGlobalDataState();
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const [reason, setReason] = useState<string>('');
  const [payment, setPayment] = useState<PaymentsShape>();
  const [pence, setPence] = useState<number>(0);
  const [penceError, setPenceError] = useState<string>('');
  const [reasonError, setReasonError] = useState<string>('');
  const [refundPayment, isLoading, success, error] = useRefundPayment();

  const params: Params = useParams();
  const idFromUrl = params?.paymentId;

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (paymentsState?.data?.items && profile.group) {
      const payment = paymentsState?.data?.items.find(
        (item) => item.id === idFromUrl
      );
      setPayment(payment);
      const paymentCanBeRefunded = canBeRefunded(payment);
      const refundsCurrentlySupported = refundsSupported(
        payment,
        profile,
        profileState
      );
      const canRefund = paymentCanBeRefunded && refundsCurrentlySupported;
      if (!canRefund) {
        history.push(`/payments?id=${idFromUrl}`);
      }
    }
  }, [paymentsState, profile]);

  const handleRefundPayment = async (): Promise<void> => {
    setPenceError('');
    setReasonError('');
    if (!payment) return;
    let valid = true;
    const minPence = 1;
    const maxPence = payment.unRefundedAmount;
    if (pence < minPence || pence > maxPence) {
      const minPrice = formatPrice({
        pence: minPence,
        currency: payment.currency,
      });
      const maxPrice = formatPrice({
        pence: maxPence,
        currency: payment.currency,
      });
      setPenceError(`Amount must be between ${minPrice} and ${maxPrice}`);
      valid = false;
    }
    if (reason.length > 50) {
      setReasonError('Reason must contain at most 50 characters');
      valid = false;
    }
    if (!valid) return;
    const editRequest: RefundPaymentRequest = {
      amount: pence,
      reason: reason || undefined,
    };
    await refundPayment(editRequest, idFromUrl);
  };

  const getStepPosition = (): number => (success ? 2 : 1);

  const handleCancelClicked = (): void => {
    if (isLoading) {
      return;
    }
    history.push('/payments');
  };

  return (
    <LayoutIllustration
      title={<>Refund a payment</>}
      coBrandedTitle={<>Refund a payment</>}
    >
      <div style={{ padding: '0 12px' }}>
        <StepPosition steps={2} position={getStepPosition()} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '12px',
          }}
        >
          {success && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <SuccessMessage
                  message={`Refund Processed - we've processed your ${formatPrice(
                    { pence, currency: payment.currency }
                  )} refund, the payment will be updated shortly if successful.`}
                />
              </div>
            </>
          )}
          {!success && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <TextInput
                  name='Available to refund'
                  value={formatPrice({
                    pence: payment.unRefundedAmount,
                    currency: payment.currency,
                  })}
                  label
                  readOnly={true}
                  disabled={true}
                />
                <CurrencyInput
                  name='Amount to refund'
                  currency={payment.currency}
                  pence={pence}
                  setPence={setPence}
                />
                {penceError && (
                  <div style={{ color: '#ec3f3f', position: 'relative' }}>
                    {penceError}
                  </div>
                )}
                <TextInput
                  name='Reason'
                  value={reason}
                  setValue={setReason}
                  label
                  description={'The reason for refunding'}
                  optional
                />
                {reasonError && (
                  <div
                    style={{
                      color: '#ec3f3f',
                      position: 'relative',
                      top: '-6px',
                    }}
                  >
                    {reasonError}
                  </div>
                )}
                {error && (
                  <div style={{ color: '#ec3f3f', position: 'relative' }}>
                    {error}
                  </div>
                )}
                <Button
                  name='Refund Payment'
                  click={handleRefundPayment}
                  loading={isLoading}
                />
              </div>
            </>
          )}
        </div>
        <Button
          name={success ? 'Back to Payment' : 'Cancel'}
          color='GREY'
          click={(): void => history.push(`/payments?id=${idFromUrl}`)}
        />
        <Button
          name={'View Payments'}
          color='GREY'
          click={handleCancelClicked}
        />
      </div>
    </LayoutIllustration>
  );
};

export { RefundPaymentRoute };
