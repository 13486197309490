import { ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../layout-illustration';
import { useGlobalState } from '../../hooks/useGlobalState';
import { ChangePassword } from '../../components/layouts/ChangePassword';
import { useHistory } from 'react-router-dom';
import { handleNotSignedIn } from '../../functions/helpers';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';

const ChangePasswordRoute = (): ReactElement => {
  const { isSignedIn } = useGlobalState();
  const history = useHistory();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  return (
    <LayoutIllustration
      title={
        <>
          Change your <span> password</span>
        </>
      }
      coBrandedTitle={
        <>
          Change your <span> password</span>
        </>
      }
      subTitle={
        <>
          Changing your password <span>periodically</span> is good security
          practice
        </>
      }
    >
      <ChangePassword />
    </LayoutIllustration>
  );
};

export { ChangePasswordRoute };
