import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { FileCategory, FileResponse } from './useFileUpload';

interface GetFilesReturn {
  files: FileResponse[] | null;
  isLoading: boolean;
  error: string | undefined;
  isMore: boolean;
}

interface ApiResponse {
  paginationToken: string;
  items: FileResponse[];
  data: {
    paginationToken?: string;
    items: FileResponse[];
  };
}

export function useGetFiles(category?: FileCategory): () => {
  state: GetFilesReturn;
  reFetchFiles: () => Promise<void>;
  getMore: () => Promise<void>;
} {
  const [files, setFiles] = useState<FileResponse[] | null>(null);
  const [paginationToken, setPaginationToken] = useState<string | null>(null);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest, cleanup] =
    useApiRequest<ApiResponse>();

  const fetchFiles = async (category?: FileCategory): Promise<void> => {
    let url = '/v1/files';
    if (category) {
      url += `?category=${category}`;
    }
    const options = {
      url,
      method: HttpMethod.GET,
    };
    await sendRequest(options);
  };

  const reFetchFiles = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    fetchFiles(category);
  };

  const getMore = async (): Promise<void> => {
    if (!paginationToken || isFetchingMore) {
      return;
    }
    let url = `/v1/files?filesStartAfter=${paginationToken}`;
    if (category) {
      url += `&category=${category}`;
    }
    setIsFetchingMore(true);
    const options = {
      url,
      method: HttpMethod.GET,
    };
    await sendRequest(options);
    setIsFetchingMore(false);
  };

  useEffect(() => {
    fetchFiles(category);
    return (): void => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (response?.data) {
      setFiles((prevFiles) => {
        const prevFilesMap = new Map();
        if (prevFiles) {
          prevFiles.forEach((file) => {
            prevFilesMap.set(file.id, file);
          });
        }

        // eslint-disable-next-line no-loops/no-loops
        for (const newFile of response.data.items) {
          prevFilesMap.set(newFile.id, newFile);
        }

        return Array.from(prevFilesMap.values()).sort(
          (a, b) => b.createdTimestamp - a.createdTimestamp
        );
      });
      setPaginationToken(response.data.paginationToken);
      setIsMore(!!response.data.paginationToken);
    }
  }, [response]);

  return function (): {
    state: GetFilesReturn;
    reFetchFiles: () => Promise<void>;
    getMore: () => Promise<void>;
  } {
    return {
      state: {
        files,
        isLoading,
        error,
        isMore,
      },
      reFetchFiles,
      getMore,
    };
  };
}
