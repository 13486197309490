import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { LayoutIllustration } from '../../../layout-illustration';
import {
  handleNotSignedIn,
  constructFullPath,
} from '../../../functions/helpers';
import {
  AccountShape,
  EntityType,
  RequiredVerificationDocument,
  RequiredVerificationPerson,
} from '../../../interfaces/accountState';
import { useGetAccount } from '../../../hooks/useGetAccount';
import { getCategoryFriendlyName } from '../../../functions/documentHelpers';
import {
  businessRoleDescription,
  businessRoleToString,
} from '../../../functions/personHelpers';
import { Warning } from '../../../components/forms/Warning';
import { ServerError } from '../../../components/forms/ServerError';

const VerifyRoute = (): ReactElement => {
  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/account';
  const isOnboarding = query.get('onboarding') || false;
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountState, profileState } = useGlobalDataState();
  const { isLoading, error, fetchAccount } = useGetAccount();
  const [account, setAccount] = useState<AccountShape>();

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!permissions.has('AccountModify')) {
      history.push(returnTo);
    }
    if (!accountState.data) {
      fetchAccount();
      return;
    }
    if (
      !accountState.data.verification.requiredDocuments &&
      !accountState.data.verification.persons?.required
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn, accountState]);

  useEffect(() => {
    if (accountState.data) {
      setAccount(accountState.data);
    }
  }, [accountState]);

  const getRequirements = (
    requiredDocuments?: RequiredVerificationDocument[],
    requiredPersons?: RequiredVerificationPerson[]
  ): JSX.Element[] => {
    const requirements: JSX.Element[] = [];
    if (requiredDocuments) {
      requirements.push(
        ...requiredDocuments?.map((d: RequiredVerificationDocument) => (
          <li key={d.category}>
            {d.quantity} x <span>{getCategoryFriendlyName(d.category)}</span>{' '}
            document
          </li>
        ))
      );
    }
    if (requiredPersons) {
      requirements.push(
        <li key='individuals'>
          Details of one or more individuals in your business, covering the{' '}
          following roles:
          <ul>
            {requiredPersons.map((p: RequiredVerificationPerson) => (
              <>
                <li key={p.role}>
                  {p.quantity} x <span>{businessRoleToString(p.role)}</span>
                </li>
                <div className='VerifyRoute--description'>
                  {businessRoleDescription(p.role)}
                </div>
              </>
            ))}
          </ul>
          <div className='VerifyRoute--note'>
            Note: all individuals require{' '}
            <span>{getCategoryFriendlyName('ProofOfIdentity')}</span> and{' '}
            <span>{getCategoryFriendlyName('ProofOfAddress')}</span>
          </div>
        </li>
      );
    }
    return requirements;
  };

  const getIntroText = (entityType: EntityType): string => {
    switch (entityType) {
      case 'Individual':
        return 'In order to adhere to KYC regulations, we require supporting documentation to confirm your identity.';
      default:
        return 'In order to adhere to KYB regulations, we require some additional information to confirm your business.';
    }
  };

  const getDocsReturnTo = (entityType?: EntityType): string => {
    switch (entityType) {
      case 'Business':
        return constructFullPath(
          `/settings/individuals/add-individual`,
          true,
          returnTo
        );
      default:
        return returnTo;
    }
  };

  return (
    <LayoutIllustration
      title={
        <>
          <span>Verify</span> your account
        </>
      }
      subTitle={
        <>
          Verify your account so you can receive your <span>payouts</span>
        </>
      }
      maxWidth='100%'
    >
      <div className='VerifyRoute--body'>
        {isLoading && !error && (
          <div className='VerifyRoute--loading'>Loading...</div>
        )}
        {!isLoading && error && <ServerError error={error} />}
        {account && (
          <>
            <div className='VerifyRoute--intro'>
              {getIntroText(account.entityType)}
            </div>
            <div className='VerifyRoute--requirements'>
              <div className='VerifyRoute--requirements-title'>
                Requirements:
              </div>
              <ul>
                {getRequirements(
                  account.verification.requiredDocuments,
                  account.verification.persons?.required
                )}
              </ul>
            </div>
            <Warning warning='You will not receive payouts until you are verified' />
          </>
        )}
        <div className='VerifyRoute--buttons'>
          <Button
            name='Verify now'
            click={(): void =>
              history.push(
                constructFullPath(
                  '/settings/account/edit-docs',
                  isOnboarding,
                  getDocsReturnTo(account?.entityType)
                )
              )
            }
          />
          <Button
            name={`Skip`}
            click={(): void => history.push(returnTo)}
            color='GREY'
          />
        </div>
      </div>
    </LayoutIllustration>
  );
};

export { VerifyRoute };
