import { ReactElement, useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import closeImage from '../../images/close.svg';
import { DateTime } from '../layouts/DateTime';
import { PulseItem } from './SignedIn';

export interface PropsShape {
  avatarUrl?: string;
  options: { value: string; name: string }[];
  pulse?: PulseItem[];
  value: string;
  name?: string;
  setValue: (setValue: string) => void;
  isPulseOpen: (setValue: boolean) => void;
}

const NavUser = ({
  avatarUrl,
  options,
  pulse,
  value,
  name,
  setValue,
  isPulseOpen,
}: PropsShape): ReactElement => {
  const menuTransition = useRef(null);
  const pulseTransition = useRef(null);
  const sideMenuTransition = useRef(null);
  const [pulseNotSeen, setPulseNotSeen] = useState<number>(0);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState<boolean>(false);
  const [isUserSideMenuVisible, setIsUserSideMenuVisible] =
    useState<boolean>(false);
  const [isPulseMenuVisible, setIsPulseMenuVisible] = useState<boolean>(false);
  const [isPulseSideMenuVisible, setIsPulseSideMenuVisible] =
    useState<boolean>(false);

  const close = (): void => {
    setIsUserSideMenuVisible(!isUserSideMenuVisible);
  };
  const toggleUserMenu = (): void => {
    setIsPulseMenuVisible(false);
    setIsPulseSideMenuVisible(false);
    if (!isMobile()) {
      setIsUserMenuVisible(!isUserMenuVisible);
    } else {
      setIsUserSideMenuVisible(!isUserSideMenuVisible);
    }
  };
  const togglePulseMenu = (): void => {
    setIsUserMenuVisible(false);
    setIsUserSideMenuVisible(false);
    if (!isMobile()) {
      setIsPulseMenuVisible(!isPulseMenuVisible);
    } else {
      setIsPulseSideMenuVisible(!isPulseSideMenuVisible);
    }
  };

  useEffect(() => {
    setPulseNotSeen(
      pulse?.filter((i) => {
        return !i.seen;
      }).length || 0
    );
  }, [pulse]);

  useEffect(() => {
    isPulseOpen(isPulseMenuVisible);
    if (isPulseMenuVisible) localStorage.setItem('pulseShown', `true`);
  }, [isPulseMenuVisible]);

  return (
    <div className='User'>
      <div className='User--pulse' onClick={togglePulseMenu}>
        {pulseNotSeen > 0 && (
          <div className='User--pulse-count'>{pulseNotSeen}</div>
        )}
      </div>
      <div className='User--avatar' onClick={toggleUserMenu}>
        {/* TODO: inline the background image css  */}
        {avatarUrl}
      </div>
      <div className='User--name'>{name}</div>

      <CSSTransition
        nodeRef={pulseTransition}
        in={isPulseMenuVisible}
        timeout={300}
        classNames='User--MenuTransition'
        unmountOnExit
      >
        <div className='User--pulse-dropdown' ref={pulseTransition}>
          <ul className='User--pulse-items'>
            {pulse?.map((option: PulseItem, index: number) => {
              return (
                <div className='User--pulse-item' key={index}>
                  {option.link && (
                    <a href={option.link}>
                      <li key={index} className='User--pulse-item-grid'>
                        <div className='User--pulse-item-title'>
                          {option.title}
                          <span className='User--pulse-item-link'>LINK</span>
                        </div>
                        <div className='User--pulse-item-avatar' />
                        <div className='User--pulse-item-body'>
                          {option.body}
                        </div>
                        <div className='User--pulse-item-created'>
                          <DateTime
                            value={epochSecondsToDate(option.created)}
                          />
                        </div>
                      </li>
                    </a>
                  )}
                  {!option.link && (
                    <li key={index} className='User--pulse-item-grid'>
                      <div className='User--pulse-item-title'>
                        {option.title}
                      </div>
                      <div className='User--pulse-item-avatar' />
                      <div className='User--pulse-item-body'>{option.body}</div>
                      <div className='User--pulse-item-created'>
                        <DateTime value={epochSecondsToDate(option.created)} />
                      </div>
                    </li>
                  )}
                </div>
              );
            })}
          </ul>
        </div>
      </CSSTransition>

      <CSSTransition
        nodeRef={menuTransition}
        in={isUserMenuVisible}
        timeout={300}
        classNames='User--MenuTransition'
        unmountOnExit
      >
        <div className='User--dropdown' ref={menuTransition}>
          <ul className='User--list'>
            {options.map(
              (option: { value: string; name: string }, index: number) => {
                return (
                  <li
                    onClick={(): void => {
                      if (option.value !== value) {
                        setTimeout(function () {
                          setValue(option.value);
                        }, 250);
                        setIsUserMenuVisible(!isUserMenuVisible);
                      }
                    }}
                    value={option.value.replace(/\s/g, '')}
                    key={index}
                    className={
                      option.value === value ? 'User--selected' : 'User--option'
                    }
                  >
                    <div className={`User--${option.value.toLowerCase()}`} />

                    {option.name}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </CSSTransition>

      <CSSTransition
        nodeRef={sideMenuTransition}
        in={isUserSideMenuVisible}
        timeout={300}
        classNames='User--SideMenuTransition'
        unmountOnExit
      >
        <div className='User--sidemenu' ref={sideMenuTransition}>
          <div className='User--sidemenu-overlay' onClick={close}></div>
          <div className='User--sidemenu-background'>
            <ul className='User--list'>
              <div onClick={close} className='User--close'>
                {/* TODO: make close button have a grey background and match avatar size */}
                <img src={closeImage} />
              </div>
              {options.map(
                (option: { value: string; name: string }, index: number) => {
                  return (
                    <li
                      onClick={(): void => {
                        if (option.value !== value) {
                          setIsUserSideMenuVisible(!isUserSideMenuVisible);
                          setTimeout(function () {
                            setValue(option.value);
                          }, 250);
                        }
                      }}
                      value={option.value.replace(/\s/g, '')}
                      key={index}
                      className={
                        option.value === value
                          ? 'User--selected'
                          : 'User--option'
                      }
                    >
                      <div className={`User--${option.value.toLowerCase()}`} />
                      {option.name}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
export { NavUser };
