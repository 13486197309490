import React, { useState, useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import { Join } from '../components/onboarding/Join';
import { Expired } from '../components/onboarding/Expired';
import { isExpired } from '../functions/validators';
import joinRyft from '../images/illustrations/join-ryft.png';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';

const JoinRoute = (): React.ReactElement => {
  const [isValidParams, setIsValidParams] = useState<boolean>(false);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const { isSignedIn, cognito } = useGlobalState();

  useEffect(() => {
    const accountType = localStorage.getItem('accountType');
    const expiryTimestamp = queryParams?.t || 0;
    accountType &&
      localStorage.setItem('accountType', accountType.toUpperCase() || '');
    setIsValidParams(!isExpired(+expiryTimestamp, 60));
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      cognito.signOut();
    }
  }, [isSignedIn]);

  return (
    <LayoutIllustration
      image={joinRyft}
      title={
        <>
          Create your <span>Ryft</span> account
        </>
      }
      subTitle={
        <>Select an entity type and enter your email and desired password</>
      }
    >
      <>
        {isValidParams && <Join />}
        {!isValidParams && <Expired />}
      </>
    </LayoutIllustration>
  );
};

export { JoinRoute };
