import { ReactElement, useEffect } from 'react';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/forms/Button';
import { CardBlank } from '../../components/layouts/CardBlank';
import { handleNotSignedIn } from '../../functions/helpers';

const Security = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  const changePassword = (): void => {
    history.push(`/change-password`);
  };

  return (
    <CardBlank>
      <SettingsItem
        border
        title='Password'
        subtitle='Keeping your password safe and private is important'
      >
        <CardBlank>
          <SettingsItem
            inner
            title='Change Password'
            subtitle='Changing passwords regularly is good security practice'
          >
            <Button margin='0' name='Change' click={changePassword} />
          </SettingsItem>
        </CardBlank>
      </SettingsItem>
    </CardBlank>
  );
};
export { Security };
