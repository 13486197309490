import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { handleNotSignedIn } from '../../functions/helpers';
import { useGetStatusPageSummary } from '../../hooks/useGetStatusPageSummary';
import {
  ComponentStatus,
  StatusPageComponentResponse,
} from '../../interfaces/statusPageState';
import { SystemStatusGroup } from '../../components/layouts/SystemStatusGroup';
import { SystemStatusComponent } from '../../components/layouts/SystemStatusComponent';
import apiReferenceImage from '../../images/api-reference-logo.svg';
import documentationLogo from '../../images/documentation-logo.svg';
import { Button } from '../../components/forms/Button';

interface ComponentGroup {
  group: StatusPageComponentResponse;
  components: StatusPageComponentResponse[];
}

const DeveloperOverview = (): ReactElement => {
  const history = useHistory();
  const statusPageApi = useGetStatusPageSummary();
  const { isSignedIn } = useGlobalState();
  const [componentGroups, setComponentsGroups] = useState<ComponentGroup[]>([]);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    statusPageApi.getStatusPageSummary();
  }, [isSignedIn]);

  useEffect(() => {
    if (statusPageApi.summary) {
      const components = statusPageApi.summary.components;
      const componentsByGroup: ComponentGroup[] = components
        .filter((c) => c.group)
        .map((g): ComponentGroup => {
          return {
            group: g,
            components: components.filter((nc) => nc.group_id == g.id),
          };
        });
      setComponentsGroups(componentsByGroup);
    }
  }, [statusPageApi.summary]);

  const openStatusPage = (): void => {
    window.open('https://status.ryftpay.com/', '_blank');
  };

  const openApiReference = (): void => {
    window.open('https://api-reference.ryftpay.com/', '_blank');
  };

  const openDocumentation = (): void => {
    window.open(
      'https://developer.ryftpay.com/docs/reference-guides/develop/',
      '_blank'
    );
  };

  const getSystemStatusDescription = (status: ComponentStatus): string => {
    switch (status) {
      case 'operational':
        return 'All Systems Operational';
      case 'degraded_performance':
        return 'Degraged performance';
      case 'partial_outage':
        return 'Partial outage';
      case 'major_outage':
        return 'Major outage';
      case 'under_maintenance':
        return 'Under maintenance (no impact)';
    }
  };

  const getOverallSystemStatus = (): ComponentStatus => {
    const notOperational = componentGroups
      .filter((g) => g.group.status !== 'operational')
      .map((g) => g.group.status);
    if (notOperational.includes('major_outage')) {
      return 'major_outage';
    }
    if (notOperational.includes('partial_outage')) {
      return 'partial_outage';
    }
    if (notOperational.includes('degraded_performance')) {
      return 'degraded_performance';
    }
    return notOperational.length <= 0 ? 'operational' : 'under_maintenance';
  };

  const getOverallSystemStatusComponent = (): ReactElement => {
    const status = getOverallSystemStatus();
    return (
      <SystemStatusComponent
        status={status}
        name={getSystemStatusDescription(status)}
      />
    );
  };

  return (
    <>
      <CardBlank>
        {statusPageApi.isLoading && statusPageApi.summary === undefined && (
          <SettingsItem
            border
            title='Loading'
            subtitle={'......'}
          ></SettingsItem>
        )}
        {!statusPageApi.isLoading && statusPageApi.error && (
          <SettingsItem
            border
            title='Oops!'
            subtitle={statusPageApi.error}
          ></SettingsItem>
        )}
        {!statusPageApi.error &&
          !statusPageApi.isLoading &&
          componentGroups &&
          componentGroups.length > 0 && (
            <SettingsItem
              border
              title='System Status'
              subtitle={
                <>
                  View our system status to stay up to date with any planned
                  maintenance or incidents.
                  <br />
                  <br />
                  {getOverallSystemStatusComponent()}
                </>
              }
              button={'View Full Status'}
              buttonClick={(): void => openStatusPage()}
            >
              <CardBlank>
                {componentGroups.map((item: ComponentGroup, index: any) => {
                  return (
                    <div key={index}>
                      <SettingsItem
                        border={false}
                        inner
                        title={item.group.name}
                        subtitle={
                          <>
                            <SystemStatusGroup components={item.components} />
                          </>
                        }
                      ></SettingsItem>
                    </div>
                  );
                })}
              </CardBlank>
            </SettingsItem>
          )}
        <SettingsItem
          border
          title={<>Resources</>}
          subtitle={
            <>
              Here you can find useful resources to help develop your
              integration with Ryft.
            </>
          }
        >
          <CardBlank>
            <SettingsItem
              border={true}
              inner
              title={
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: '5px',
                    fontWeight: 700,
                  }}
                >
                  API Reference <img src={apiReferenceImage}></img>
                </div>
              }
              subtitle={
                <>
                  View and implement the Ryft API. Our API is restful and
                  JSON-based
                </>
              }
            >
              <Button name='View' click={(): void => openApiReference()} />
            </SettingsItem>
            <SettingsItem
              border={false}
              inner
              title={
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: '5px',
                    fontWeight: 700,
                  }}
                >
                  Developer Documentation <img src={documentationLogo}></img>
                </div>
              }
              subtitle={
                <>
                  Use our documentation to help build or monitor your
                  integration with Ryft
                </>
              }
            >
              <Button name='View' click={(): void => openDocumentation()} />
            </SettingsItem>
          </CardBlank>
        </SettingsItem>
      </CardBlank>
    </>
  );
};
export { DeveloperOverview };
