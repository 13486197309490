import { Currencies } from 'ts-money';
import { Currency } from '../interfaces/state';

function getCurrency(currency: string): Currency {
  const moneyCurrency = Currencies[currency];
  return {
    code: moneyCurrency.code,
    name: moneyCurrency.name,
    symbol: moneyCurrency.symbol,
    minorDigits: moneyCurrency.decimal_digits,
  };
}

export { getCurrency };
