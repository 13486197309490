import React from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';

const TermsRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='Terms'>
        <div className='Terms--center'>
          <header>Terms & Policies</header>
          <div className='Terms--grid'>
            <nav>
              <ul>
                <li className='Terms--selected'>Terms and Conditions</li>
                <li
                  className='Terms--option'
                  onClick={(): void => history.push('./privacy')}
                >
                  Privacy Policy
                </li>
              </ul>
            </nav>
            <aside>
              <h2>Terms and Conditions</h2>
              <p>
                This Ryft Services Agreement (“Agreement”) is a legal agreement
                between Butlr Ltd. (“Ryft”, “us”, or “we”) and the entity or
                person (“you”, “your”, or “user”) who registered for Ryft to
                receive certain payment processing, data, technology and
                analytics services, and other business services that may be
                offered by Ryft and its affiliates (each, a “Service”). This
                Agreement describes the terms and conditions that apply to your
                use of the Services.
              </p>
              <p>
                You may not access or use any Services unless you agree to abide
                by all of the terms and conditions in this Agreement.
              </p>
              <p>
                Click on the links below to jump to each section of these terms:
              </p>
              <ul>
                <a href='#Overview_of_this_Agreement'>
                  <li>Overview of this Agreement</li>
                </a>
                <a href='#Your_Ryft_Account'>
                  <li>Your Ryft Account</li>
                </a>
                <a href='#Your_Relationship_with_Your_Customers'>
                  <li>Your Relationship with Your Customers</li>
                </a>
                <a href='#Fees_and_Fines'>
                  <li>Fees and Fines</li>
                </a>
                <a href='#Services_and_Ryft_Account_Support'>
                  <li>Services and Ryft Account Support</li>
                </a>
                <a href='#Taxes_and_Other_Expenses'>
                  <li>Taxes and Other Expenses</li>
                </a>
                <a href='#Service_Requirements_Limitations_and_Restrictions'>
                  <li>Service Requirements, Limitations and Restrictions</li>
                </a>
                <a href='#Suspicion_of_Unauthorised_or_Illegal_Use'>
                  <li>Suspicion of Unauthorised or Illegal Use</li>
                </a>
                <a href='#Termination'>
                  <li>Termination</li>
                </a>
                <a href='#API_and_Dashboard'>
                  <li>API and Dashboard</li>
                </a>
                <a href='#Licence'>
                  <li>Licence</li>
                </a>
                <a href='#Registering_for_Use_of_Payment_Processing_Services'>
                  <li>Registering for Use of Payment Processing Services</li>
                </a>
                <a href='#Processing_Transactions_Disputes_Refunds_Reversals'>
                  <li>Processing Transactions; Disputes, Refunds, Reversals</li>
                </a>
                <a href='#Responsibilities_and_Disclosures_to_Your_Customers'>
                  <li>Responsibilities and Disclosures to Your Customers</li>
                </a>
                <a href='#Payment_Terms_and_Financial_Services_Terms'>
                  <li>Payment Terms and Financial Services Terms</li>
                </a>
                <a href='#Specific_Payment_Methods'>
                  <li>Specific Payment Methods</li>
                </a>
                <a href='#Settlement_and_Payout_Schedule'>
                  <li>Settlement and Payout Schedule</li>
                </a>
                <a href='#Clearing_Funds_and_Reserves'>
                  <li>Clearing Funds and Reserves</li>
                </a>
                <a href='#Security_Interests_Collection_and_Set_Off_Rights'>
                  <li>Security Interests, Collection, and Set-Off Rights</li>
                </a>
                <a href='#Reconciliation_and_Error_Notification'>
                  <li>Reconciliation and Error Notification</li>
                </a>
                <a href='#Dormant_Accounts'>
                  <li>Dormant Accounts</li>
                </a>
                <a href='#Data_Usage_Overview'>
                  <li>Data Usage Overview</li>
                </a>
                <a href='#Data_Protection_and_Privacy'>
                  <li>Data Protection and Privacy</li>
                </a>
                <a href='#Security_and_Fraud_Controls'>
                  <li>Security and Fraud Controls</li>
                </a>
                <a href='#Right_to_Amend'>
                  <li>Right to Amend</li>
                </a>
                <a href='#Assignment'>
                  <li>Assignment</li>
                </a>
                <a href='#Right_to_Audit'>
                  <li>Right to Audit</li>
                </a>
                <a href='#No_Agency_Third_Party_Services'>
                  <li>No Agency; Third-Party Services</li>
                </a>
                <a href='#Force_Majeure'>
                  <li>Force Majeure</li>
                </a>
                <a href='#Your_Liability_For_Third_Party_Claims_Against_Us'>
                  <li>Your Liability For Third-Party Claims Against Us</li>
                </a>
                <a href='#Representations_and_Warranties'>
                  <li>Representations and Warranties</li>
                </a>
                <a href='#Limitation_of_Liability'>
                  <li>Limitation of Liability</li>
                </a>
                <a href='#Responding_to_Legal_Process'>
                  <li>Responding to Legal Process</li>
                </a>
                <a href='#Dispute_Resolution_Agreement_to_Arbitrate'>
                  <li>Dispute Resolution; Agreement to Arbitrate</li>
                </a>
                <a href='#Entire_Agreement'>
                  <li>Entire Agreement</li>
                </a>
                <a href='#Cumulative_Rights_Construction_Waiver'>
                  <li>Cumulative Rights, Construction, Waiver</li>
                </a>
                <a href='#Language'>
                  <li>Language</li>
                </a>
                <a href='#Debiting_your_account'>
                  <li>Debiting your account</li>
                </a>
                <a href='#Your_responsibilities'>
                  <li>Your responsibilities</li>
                </a>
                <a href='#The_Direct_Debit_Guarantee'>
                  <li>The Direct Debit Guarantee</li>
                </a>
                <a href='#Inquiries_Errors_and_Disputes'>
                  <li>Inquiries, Errors, and Disputes</li>
                </a>
                <a href='#Changes_to_this_Direct_Debit_Request_Service_Agreement'>
                  <li>
                    Changes to this Direct Debit Request Service Agreement
                  </li>
                </a>
              </ul>
              <br />
              <br />
              <br />
              <h3>Section A: General Terms</h3>
              <a id='Overview_of_this_Agreement'>
                <h4>Overview of this Agreement</h4>
              </a>
              <p>
                This Agreement provides a general description of the Services
                that Ryft may provide to you, including those that allow you to
                accept payments from purchasers of your goods or services or
                donors to your organization (your “Customers”). We provide you
                with a more detailed description of the Services through
                published software libraries and application programming
                interfaces that may be used to access the Services (the “API”)
                and additional resources we make available to you on our
                website.
              </p>
              <p>
                Before using the Services, you must register with Ryft and
                create an account (a “Ryft Account”).
              </p>
              <a id='Your_Ryft_Account'>
                <h4>Your Ryft Account</h4>
              </a>
              <p>
                a. Registration and Permitted Activities: Only businesses
                (including sole proprietors), bona fide charitable
                organizations, and other entities or persons located in United
                Kingdom are eligible to apply for a Ryft Account to use the
                Services described in this Agreement. Ryft and its affiliates
                may provide Services to you or your affiliates in other
                countries or regions under separate agreements.
              </p>
              <p>
                To register for a Ryft Account, you or the person or people
                submitting the application (your “Representative”) must provide
                us with your business or trade name, physical address, email,
                phone number, business identification number, URL, the nature of
                your business or activities, and certain other information about
                you that we require. We may also collect personal information
                (including name, birth date, and government-issued
                identification number) about your beneficial owners, principals,
                and your Ryft Account administrator. Until you have submitted,
                and we have reviewed and approved, all required information,
                your Ryft Account will be available to you on a preliminary
                basis only, and we may terminate it at any time and for any
                reason.
              </p>
              <p>
                If you use Payment Processing Services, your name (or the name
                used to identify you) and URL may appear on your Customers’ bank
                or other statements. To minimize confusion and avoid potential
                disputes, these descriptors must be recognizable to your
                Customers and must accurately describe your business or
                activities. You may only use Payment Processing Services to
                facilitate Transactions (as defined below) with your Customers.
                You may not use Payment Processing Services to conduct any
                personal transactions or for peer-to-peer money transmission, or
                for any other purposes prohibited by this Agreement.
              </p>
              <p>
                b. Business Representative: You and your Representative
                individually affirm to Ryft that your Representative is
                authorised to provide the information described here on your
                behalf and to bind you to this Agreement. We may require you or
                your Representative to provide additional information or
                documentation demonstrating your Representative’s authority.
                Without the express written consent of Ryft, neither you nor
                your Representative may register or attempt to register for a
                Ryft Account on behalf of a user Ryft previously terminated from
                use of the Services.
              </p>
              <p>
                If you are a sole proprietor, you and your Representative also
                affirm that your Representative is personally responsible and
                liable for your use of the Services and your obligations to
                Customers, including payment of any amounts owed under this
                Agreement.
              </p>
              <p>
                c. Validation and Underwriting: At any time during the term of
                this Agreement and your use of the Services, we may require
                additional information from you to verify beneficial ownership
                or control of the business, validate information you provided,
                verify you or your Representative’s identity, and assess your
                financial condition and the risk associated with your business.
                This additional information may include business invoices,
                copies of government-issued identification, business licences,
                or other information related to your business, its beneficial
                owners or principals. If you use Payment Processing Services, we
                may also request that you provide copies of financial
                statements, reporting and validating documentation that allows
                us to calculate outstanding credit exposure/risk of loss (for
                example, management or internal accounts, your refund and
                shipping policies, data on captured but unfulfilled charges, the
                time between charge capture and fulfillment of your Customer
                orders), or other records pertaining to your compliance with
                this Agreement. We may also require you to provide a personal or
                company guarantee. Your failure to provide this information or
                material may result in suspension or termination of your Ryft
                Account.
              </p>
              <p>
                You authorise us to retrieve information about you from our
                service providers and other third parties, including credit
                reporting agencies and information bureaus and you authorise and
                direct such third parties to compile and provide such
                information to us. You acknowledge that this may include your
                name, addresses, credit history, and other data about you or
                your Representative. You acknowledge that we may use your
                information to verify any other information you provide to us,
                and that any information we collect may affect our assessment of
                your overall risk to our business. You acknowledge that in some
                cases, such information may lead to suspension or termination of
                your Ryft Account. Ryft may periodically update this information
                as part of our underwriting criteria and risk analysis
                procedures.
              </p>
              <p>
                d. Changes to Your Business, Keeping your Ryft Account Current:
                You agree to keep the information in your Ryft Account current.
                You must promptly update your Ryft Account with any changes
                affecting you, the nature of your business activities, your
                Representatives, beneficial owners, principals, or any other
                pertinent information. We may suspend your Ryft Account or
                terminate this Agreement if you fail to keep this information
                current.
              </p>
              <p>
                You also agree to promptly notify us in writing immediately and
                in any event no more than three days after any of the following
                occur: you are the subject of any voluntary or involuntary
                bankruptcy or insolvency application, petition or proceeding,
                receivership, or similar action (any of the foregoing, a
                “Bankruptcy Proceeding”); there is an adverse change in your
                financial condition; there is a planned or anticipated
                liquidation or substantial change in the basic nature of your
                business; you transfer or sell 25% or more of your total assets,
                or there is any change in the control or ownership of your
                business or parent entity; there is a change in the regulatory
                status of your business or your business has been notified that
                it is the subject of an investigation or enforcement action by a
                regulator or law enforcement; or you receive a judgment, writ or
                warrant of attachment or execution, lien or levy against 25% or
                more of your total assets.
              </p>
              <a id='Your_Relationship_with_Your_Customers'>
                <h4>Your Relationship with Your Customers</h4>
              </a>
              <p>
                You may only use the Services for legitimate Transactions with
                your Customers. You know your Customers better than we do, and
                you are responsible for your relationship with them. Ryft is not
                responsible for the products or services you publicize or sell,
                or that your Customers purchase using the Services; or if you
                accept donations, for your communication to your Customers of
                the intended use of such donations. You affirm that you are
                solely responsible for the nature and quality of the products or
                services you provide, and for delivery, support, refunds,
                returns, and for any other ancillary services you provide to
                your Customers.
              </p>
              <p>
                Ryft provides Services to you but we have no way of knowing if
                any particular purchase, sale, donation, order, or other
                transaction (each a “Transaction”) is accurate or complete, or
                typical for your business. You are responsible for knowing
                whether a Transaction initiated by your Customer is erroneous
                (such as a Customer purchasing one item when they meant to order
                another) or suspicious (such as unusual or large purchases, or a
                request for delivery to a foreign country where this typically
                does not occur). If you are unsure if a Transaction is erroneous
                or suspicious, you agree to research the Transaction and, if
                necessary, contact your Customer before fulfilling or completing
                the Transaction. You are solely responsible for any losses you
                incur due to erroneous or fraudulent Transactions in connection
                with your use of the Services.
              </p>
              <a id='Fees_and_Fines'>
                <h4>Fees and Fines</h4>
              </a>
              <p>
                Ryft will provide the Services to you at the rates and for the
                fees (“Fees”) quoted. The Fees include charges for Transactions
                (such as processing a payment) and for other events connected
                with your Ryft Account (such as handling a disputed charge). We
                may revise the Fees at any time. However, we will provide you
                with at least 30 days’ advance notice before revisions become
                applicable to you (or a longer period of notice if this is
                required by applicable Law).
              </p>
              <p>
                In addition to the Fees, you are also responsible for any
                penalties or fines imposed in relation to your Ryft Account on
                you or Ryft by Ryft or any Payment Method Provider or Payment
                Method Acquirer resulting from your use of Payment Processing
                Services in a manner not permitted by this Agreement or a
                Payment Method Provider’s rules and regulations.
              </p>
              <p>
                You request a complete blending of Fees for payment card
                processing for all merchant services charges (MSC) for all
                payment card brands and categories irrespective of the
                underlying differences in interchange fees.
              </p>
              <p>
                You are also obligated to pay all taxes, fees and other charges
                imposed by any governmental authority, including any value added
                tax, goods and services tax, sales tax and applicable indirect
                and transactional taxes (“Taxes”) on the Services provided under
                this Agreement.
              </p>
              <a id='Services_and_Ryft_Account_Support'>
                <h4>Services and Ryft Account Support</h4>
              </a>
              <p>
                We will provide you with support to resolve general issues
                relating to your Ryft Account and your use of the Services. This
                support includes resources and documentation that we make
                available to you through the current versions of Ryft’s API
                documentation. The most efficient way to get answers to your
                questions is to review our Documentation. You are solely
                responsible for providing support to Customers regarding
                Transaction receipts, product or service delivery, support,
                returns, refunds, and any other issues related to your products
                and services and business activities. We are not responsible for
                providing support for the Services to your Customers unless we
                agree to do so in a separate agreement with you or one of your
                Customers.
              </p>
              <a id='Taxes_and_Other_Expenses'>
                <h4>Taxes and Other Expenses</h4>
              </a>
              <p>
                Our fees are exclusive of any applicable Taxes, except as
                expressly stated to the contrary. You have sole responsibility
                and liability for: (i) determining what, if any, Taxes apply to
                the sale of your products and services, acceptance of donations,
                or payments you receive in connection with your use of the
                Services; and (ii) assessing, collecting, reporting, and
                remitting Taxes for your business to the appropriate tax and
                revenue authorities. If we are required to withhold any Taxes,
                or we are unable to validate any tax-related identification
                information you provide to us, we may deduct such Taxes from
                amounts otherwise owed and pay them to the appropriate taxing
                authority. If you are exempt from payment of such Taxes, you
                must provide us with an original certificate that satisfies
                applicable legal requirements attesting to your tax-exempt
                status. Upon our reasonable request, you must provide us with
                information regarding your tax affairs.
              </p>
              <p>
                We may send documents to you and tax authorities for
                Transactions processed using the Services. Specifically,
                pursuant to applicable Law, we may be required to file periodic
                informational return with taxing authorities in relation to your
                use of the Services. If you use Payment Processing Services, you
                acknowledge that we will report the total amount of payments you
                receive each calendar year as required by appropriate tax and
                revenue authorities. We also may, but are not obliged to,
                electronically send you tax-related information.
              </p>
              <a id='Service Requirements, Limitations and Restrictions'>
                <h4>Service Requirements, Limitations and Restrictions</h4>
              </a>
              <p>
                a. Compliance with Applicable Laws: You must use the Services in
                a lawful manner, and must obey all laws, rules, and regulations
                (“Laws”) applicable to your use of the Services and to
                Transactions. As applicable, this may include compliance with
                domestic and international Laws related to the use or provision
                of financial services, notification and consumer protection,
                unfair competition, privacy, and false advertising, and any
                other Laws relevant to Transactions.
              </p>
              <p>
                b. Restricted Businesses and Activities: You may not use the
                Services to enable any person (including you) to benefit from
                any activities Ryft has identified as a restricted business or
                activity (collectively, “Restricted Businesses”). Restricted
                Businesses include use of the Services in or for the benefit of
                a country, organization, entity, or person embargoed or blocked
                by any government, including those on sanctions lists identified
                by the United States Office of Foreign Asset Control (OFAC), the
                European Commission, or United Kingdom.
              </p>
              <p>
                Please review the list of Restricted Businesses thoroughly
                before registering for and opening a Ryft Account. If you are
                uncertain whether a category of business or activity is
                restricted or have questions about how these restrictions apply
                to you, please contact us. We may add to or update the
                Restricted Business List at any time.
              </p>
              <p>
                c. Other Restricted Activities: You may not use the Services to
                facilitate illegal Transactions or to permit others to use the
                Services for personal, family or household purposes. In
                addition, you may not allow, and may not allow others to: (i)
                access or attempt to access non-public Ryft systems, programs,
                data, or services; (ii) copy, reproduce, republish, upload,
                post, transmit, resell, or distribute in any way, any data,
                content, or any part of the Services, Documentation, or our
                website except as expressly permitted by applicable Laws; (iii)
                act as service bureau or pass-through agent for the Services
                with no added value to Customers; (iv) transfer any rights
                granted to you under this Agreement; (v) work around any of the
                technical limitations of the Services or enable functionality
                that is disabled or prohibited; (vi) reverse engineer or attempt
                to reverse engineer the Services except as expressly permitted
                by Laws; (vii) perform or attempt to perform any actions that
                would interfere with the normal operation of the Services or
                affect use of the Services by our other users; or (ix) impose an
                unreasonable or disproportionately large load on the Service.
              </p>
              <a id='Suspicion_of_Unauthorised_or_Illegal_Use'>
                <h4>Suspicion of Unauthorised or Illegal Use</h4>
              </a>
              <p>
                We may refuse, condition, or suspend any Transactions that we
                believe: (i) may violate this Agreement or other agreements you
                may have with Ryft; (ii) are unauthorised, fraudulent or
                illegal; or (iii) expose you, Ryft, or others to risks
                unacceptable to Ryft. If we suspect or know that you are using
                or have used the Services for unauthorised, fraudulent, or
                illegal purposes, we may share any information related to such
                activity with the appropriate financial institution, regulatory
                authority, or law enforcement agency consistent with our legal
                obligations. This information may include information about you,
                your Ryft Account, your Customers, and Transactions made through
                your use of the Services.
              </p>
              <a id='Termination'>
                <h4>Termination</h4>
              </a>
              <p>
                a. Term and Termination: This Agreement is effective upon the
                date you first access or use the Services and continues until
                terminated by you or Ryft. You may terminate this Agreement by
                contacting us at any time by contacting us. If you use the
                Services again or register for another Ryft Account, you are
                consenting to this Agreement. We may terminate this Agreement or
                close your Ryft Account at any time for any reason (including,
                without limitation, for any activity that may create harm or
                loss to the goodwill of a Payment Method) by providing you
                advance Notice (the period of notice as required by applicable
                Law). We may suspend your Ryft Account and your ability to
                access funds in your Ryft Account, or terminate this Agreement,
                if (i) we determine in our sole discretion that you are
                ineligible for the Services because of significant fraud or
                credit risk, or any other risks associated with your Ryft
                Account; (ii) you use the Services in a prohibited manner or
                otherwise do not comply with any of the provisions of this
                Agreement; (iii) any Law, Payment Method Provider or Payment
                Method Acquirer requires us to do so; or (iv) we are otherwise
                entitled to do so under this Agreement. A Payment Method
                Provider or Payment Method Acquirer may terminate your ability
                to accept a Payment Method, at any time and for any reason, in
                which case you will no longer be able to accept the Payment
                Method under this Agreement.
              </p>
              <p>
                b. Effects of Termination: Termination does not immediately
                relieve you of obligations incurred by you under this Agreement.
                Upon termination, you agree to (i) complete all pending
                Transactions, (ii) stop accepting new Transactions, and (iii)
                immediately remove all Ryft and payment network logos from your
                website (unless permitted under a separate licence with the
                payment network). Your continued or renewed use of the Services
                after all pending Transactions have been processed serves to
                renew your consent to the terms of this Agreement. If you
                terminate this Agreement, we will pay out any remaining funds
                owed to you.
              </p>
              <p>
                In addition, upon termination you understand and agree that (i)
                all licences granted to you by Ryft under this Agreement will
                end; (ii) we reserve the right (but have no obligation) to
                delete all of your information and account data stored on our
                servers; (iii) we will not be liable to you for compensation,
                reimbursement, or damages related to your use of the Services,
                or any termination or suspension of the Services or deletion of
                your information or account data; and (iv) you are still liable
                to us for any Fees or fines, or other financial obligation
                incurred by you or through your use of the Services prior to
                termination.
              </p>
              <h3>Section B: Ryft Technology</h3>
              <a id='API_and_Dashboard'>
                <h4>API and Dashboard</h4>
              </a>
              <p>
                Ryft has developed and provides access to the API that may be
                used to access the Services. You may use the API solely as
                described in the Documentation to use the Services on websites
                and through the applications identified in your Ryft Account.
                You may manage your Ryft Account and enable additional features
                through the Ryft web portal (“Dashboard”). Ryft will use the
                Dashboard to provide you with information about your Ryft
                Account.
              </p>
              <p>
                You may not use the API for any purpose, function, or feature
                not described in the Documentation or otherwise communicated to
                you by us. Due to the nature of the Services, we will update the
                API and Documentation from time to time, and may add or remove
                functionality. We will provide you Notice in the event of
                material changes, deprecations, or removal of functionality from
                the API so that you may continue using the Services with minimal
                interruption.
              </p>
              <p>
                We will make publishable and secret API keys for live and test
                Transactions available to you through the Dashboard. Publishable
                keys identify Transactions with your Customers, and secret keys
                permit any API call to your Ryft Account. You are responsible
                for securing your secret keys — do not publish or share them
                with any unauthorised persons. Failure to secure your secret
                keys will increase the likelihood of fraud on your Ryft Account
                and potential losses to you or your Customers. You should
                contact us immediately if you become aware of any unauthorised
                use of your secret key or any other breach of security regarding
                the Services. We provide more details on proper use of
                publishable and secret API keys in the Documentation.{' '}
              </p>
              <a id='Licence'>
                <h4>Licence</h4>
              </a>
              <p>
                You are granted a nonexclusive and nontransferable licence to
                electronically access and use the Ryft only in the manner
                described in this Agreement. Ryft does not sell to you, and you
                do not have the right to sublicence Ryft. We may make updates to
                the Ryft IP or new Services available to you automatically as
                electronically published by Ryft, but we may require action on
                your part before you may use the Ryft IP or new Services
                (including activation through the Dashboard, or acceptance of
                new or additional terms). Ryft may revoke or terminate this
                licence at any time if you use Ryft IP in a manner prohibited by
                this Agreement.
              </p>
              <p>
                You may not: (i) claim or register ownership of Ryft IP on your
                behalf or on behalf of others; (ii) sublicence any rights in
                Ryft IP granted by us; (iii) import or export any Ryft IP to a
                person or country in violation of any country’s export control
                Laws; (iv) use Ryft IP in a manner that violates this Agreement
                or Laws; or (v) attempt to do any of the foregoing.
              </p>
              <a id='Registering for Use of Payment Processing Services'>
                <h4>Registering for Use of Payment Processing Services</h4>
              </a>
              <p>
                When you register for a Ryft Account, you may be asked for
                financial information, or information we use to identify you,
                your Representatives, principals, beneficial owners, and other
                individuals associated with your Ryft Account. Throughout the
                term of this Agreement, we may share information about your Ryft
                Account with Payment Method Providers and Payment Method
                Acquirers in order to verify your eligibility to use the Payment
                Processing Services, establish any necessary accounts or credit
                with Payment Method Providers and Payment Method Acquirers,
                monitor Charges and other activity, and conduct risk management
                and compliance reviews. We may also share your Data (as that
                term is defined below) with Payment Method Providers and Payment
                Method Acquirers for the purpose of facilitating the compliance
                of Ryft, the Payment Method Providers, and the Payment Method
                Acquirers with applicable Laws and Payment Method Rules. We will
                review and may conduct further intermittent reviews of your Ryft
                Account information to determine that you are eligible to use
                the Payment Processing Services.{' '}
              </p>
              <p>
                Ryft is not a bank and we do not accept deposits, provide loans
                or extend credit. If you accept payment for products or services
                (including events such as concerts or other performances) not
                immediately deliverable to the Customer (a “Preorder”), we may,
                in our sole discretion, initiate Reversals or hold Reserves for
                all or a portion of the Charges processed by us for a Preorder.
                If you would like to receive payment for a Preorder, please
                contact us before doing so.
              </p>
              <a id='Processing_Transactions_Disputes_Refunds_Reversals'>
                <h4>Processing Transactions; Disputes, Refunds, Reversals</h4>
              </a>
              <p>
                You may only submit Charges through the Payment Processing
                Services that are authorised by your Customers. To enable us to
                process Transactions for you, you authorise and direct Ryft, the
                Payment Method Providers and Payment Method Acquirers to receive
                and settle any payment processing proceeds owed to you through
                the Payment Processing Services. You may not, other than as
                required by the Financial Services Terms or Payment Terms (each
                as defined below), grant or assign any interest in payment
                processing proceeds to any third party until such time as the
                payment processing proceeds are deposited into your Payout
                Account (as defined below). You appoint SPUKL and as your agent
                for the limited purpose of directing, receiving, holding and
                settling such proceeds. You agree that Ryft’s receipt of such
                proceeds satisfies the relevant end-customer’s obligations to
                make payments to you. We will promptly update your Ryft Account
                balance to reflect any such proceeds that Ryft receives on your
                behalf.
              </p>
              <p>
                Except where Ryft and a Customer have otherwise agreed, you
                maintain the direct relationship with your Customers and are
                responsible for: (i) acquiring appropriate consent to submit
                Charges through the Payment Processing Services on their behalf;
                (ii) providing confirmation or receipts to Customers for each
                Charge; (iii) verifying Customers’ identities; and (iv)
                determining a Customer’s eligibility and authority to complete
                Transactions. However, even authorised Transactions may be
                subject to a Dispute. Ryft is not responsible for or liable to
                you for authorised and completed Charges that are later the
                subject of a Dispute, Refund, or Reversal, are submitted without
                authorisation or in error, or violate any Laws.
              </p>
              <p>
                You are responsible for ensuring that you only submit Charges
                through the Payment Processing Services as MOTO Transactions or
                Merchant Initiated Transactions that are eligible to be treated
                as such in accordance with the terms of this Agreement and, in
                respect of a Merchant Initiated Transaction, that you have an
                appropriate mandate in place with your Customer enabling you to
                initiate the Charge. You must keep a record of the basis on
                which you determined any submitted Charge was eligible to be
                treated as a MOTO Transaction or Merchant Initiated Transaction
                and make such records available to us, our regulators and/or our
                auditors immediately on request.
              </p>
              <p>
                You are immediately responsible to us for all Disputes, Refunds,
                Reversals, Returns, or Fines regardless of the reason or timing.
                We may decline to act upon a Refund instruction, or delay
                execution of the instruction, if: (i) it would cause your Ryft
                Account balance to become negative; (ii) you are the subject of
                Bankruptcy Proceedings; or (iii) where we otherwise believe that
                there is a risk that you will not meet your liabilities under
                this Agreement (including with respect to the Charge that is the
                subject of the Refund instruction).
              </p>
              <p>
                In many but not all cases, you may have the ability to challenge
                a Dispute by submitting evidence through the API or the
                Dashboard. We may request additional information to provide to
                Payment Method Providers and Payment Method Acquirers to assist
                you in contesting the Dispute, but we cannot guarantee that your
                challenge will be successful. Payment Method Providers and
                Payment Method Acquirers may deny your challenge for any reason
                they deem appropriate. Where a challenge is entirely or
                partially successful, your Ryft Account will be credited with
                the funds associated with the Charge that is the subject of the
                Dispute (or a portion thereof). You may not submit a new Charge
                which duplicates a Transaction that is subject to a Dispute.
              </p>
              <p>
                Please keep in mind that you are liable for all losses you incur
                when lost or stolen payment credentials or accounts are used to
                purchase products or services from you. Ryft does not and will
                not insure you against losses caused by fraud under any
                circumstances. For example, if someone pretends to be a
                legitimate buyer but is a fraudster, you will be responsible for
                any resulting costs, including Disputes, even if you do not
                recover the fraudulently purchased product. Even if we work with
                you to assist you or law enforcement in recovering lost funds,
                Ryft is not liable to you, or responsible for your financial
                losses or any other consequences of such fraud.
              </p>
              <p>
                A Reversal for a Charge may be issued if the Charge is made
                without the account owner’s authorisation, or in connection with
                a Restricted Business, violates the applicable Payment Method
                Rules, or for other applicable reasons. If a Reversal is issued,
                we will provide you Notice and a description of the cause of the
                Reversal.
              </p>
              <a id='Responsibilities_and_Disclosures_to_Your_Customers'>
                <h4>Responsibilities and Disclosures to Your Customers</h4>
              </a>
              <p>
                It is very important to us that your Customers understand the
                purpose, amount, and conditions of Charges you submit to us.
                With that in mind, when using the Payment Processing Services
                you agree to: (i) accurately communicate, and not misrepresent,
                the nature of the Transaction, and the amount of the Charge in
                the appropriate currency prior to submitting it to the API; (ii)
                provide a receipt that accurately describes each Transaction to
                Customers; (iii) provide Customers a meaningful way to contact
                you in the event that the product or service is not provided as
                described; (iv) not use Services to sell products or services in
                a manner that is unfair or deceptive, exposes Customers to
                unreasonable risks, or does not disclose material terms of a
                purchase in advance; and (v) inform Customers that Ryft and its
                affiliates process Transactions (including payment Transactions)
                for you. You also agree to maintain and make available to your
                Customers a reasonable return, refund, cancellation, or
                adjustment policy, and clearly explain the process by which
                Customers can receive a Refund.
              </p>
              <p>
                If you engage in Transactions with Customers who are individuals
                (i.e. consumers), you specifically agree to provide consumers
                disclosures required by Law, and to not engage in unfair,
                deceptive, or abusive acts or practices.
              </p>
              <a id='Payment_Terms_and_Financial_Services_Terms'>
                <h4>Payment Terms and Financial Services Terms</h4>
              </a>
              <p>
                Your use of the Payment Processing Services is subject to
                additional terms that apply between you and one or more of Ryft,
                any other Ryft affiliate, and any Payment Method Provider or
                other Payment Method Acquirer. When these additional terms
                relate to any specific Payment Method they are “Payment Terms”,
                and when they relate to specific Payment Processing Services
                they are “Financial Services Terms”. By using the Payment
                Processing Services, you agree to the applicable Payment Terms
                and Financial Services Terms including those that separately
                bind you with our affiliates, Payment Method Providers and/or
                any other Payment Method Acquirers. Additionally, a Payment
                Method Provider may enforce the terms of this Agreement directly
                against you.
              </p>
              <p>
                We may add or remove Payment Method Providers and Payment Method
                Acquirers at any time. The Payment Terms and Financial Services
                Terms may also be amended from time to time. Your continuing use
                of the Payment Processing Services constitutes your consent and
                agreement to such additions, removals and amendments.
              </p>
              <a id='Specific Payment Methods'>
                <h4>Specific Payment Methods</h4>
              </a>
              <p>
                a. Payment Cards: When accepting payment card payments, you must
                comply with all applicable Network Rules, including the Network
                Rules specified by the Visa Rules and Regulations Rules
                specified by Visa International (“Visa”), the Mastercard Rules
                specified by MasterCard International Incorporated
                (“Mastercard”), and Network Rules specified by American Express.
                Collectively, Visa, Mastercard and American Express are referred
                to in this Agreement as the “Networks”.
              </p>
              <p>
                In addition, for Visa and Mastercard Transactions, Visa and
                Mastercard require that you enter into a direct contractual
                relationship with the Payment Method Acquirer for these types of
                Transactions.
              </p>
              <p>
                The Network Rules state that you may only accept payments using
                payment cards for bona fide legal commercial transactions
                between you and your Customers for goods or services that are
                free of liens, claims, and encumbrances. You may only use
                payment network trademarks or service marks consistent with the
                Network Rules, and the Network Rules also limit your ability to
                discriminate by card type or charge surcharges for acceptance of
                payment cards.
              </p>
              <p>
                The Networks may amend the Network Rules at any time without
                notice to you, and Ryft reserves the right to change the Payment
                Processing Services at any time to comply with the Network
                Rules. We may share with the Networks (and the Payment Method
                Acquirer) information you provide to us that we use to identify
                the nature of your products or services, including the
                assignment of your business activities to a particular payment
                network merchant category code (MCC).
              </p>
              <p>
                Customers typically raise payment card network Disputes (also
                known as “chargebacks”) when a merchant fails to provide the
                product or service to the Customer, or where the payment card
                account holder did not authorise the Charge. High Dispute rates
                (typically those exceeding 1%) may result in your inability to
                use the Payment Processing Services. Failure to timely and
                effectively manage Disputes with your Customers may ultimately
                result in your inability to accept payment cards for your
                business.
              </p>
              <p>
                When you accept payment card Transactions, Network Rules
                specifically prohibit you from (i) providing cash refunds for a
                Charge on a credit card, unless required by Laws, (ii)
                initiating a Refund more than five calendar days after issuing a
                credit to your Customer, (iii) accepting cash, its equivalent,
                or any other item of value for a Refund, (iv) acting as a
                payment facilitator, intermediary or aggregator, or otherwise
                reselling Payment Processing Services on behalf of others, (v)
                submitting what you believe or know to be a fraudulent Charge or
                a Charge that has not been authorised by the cardholder, (vi)
                submitting a Charge where the Transaction has not been completed
                or the goods or services have not been shipped or provided
                (except where the cardholder has paid a partial or full
                prepayment or the cardholder’s consent is obtained for a
                recurring transaction), (vii) splitting Transactions into
                multiple Charges unless certain criteria are met, or (vii) using
                Payment Processing Services in a manner that is an abuse of the
                payment card networks or a violation of the Network Rules.
              </p>
              <p>
                If you misuse the Payment Processing Services for payment card
                Transactions or engage in activity the Networks identify as
                damaging to their brand, or if we are required to do so by the
                Network Rules, we may submit information about you,
                Representatives, your beneficial owners and principals, and
                other individuals associated with your Ryft Account, to the
                MATCH terminated merchant listing maintained by MasterCard and
                accessed and updated by Visa and American Express, or to the
                Consortium Merchant Negative File maintained by Discover.
                Addition to one of these lists may result in your inability to
                accept payments from payment cards. You understand and consent
                to our sharing this information and to the listing itself, and
                you will fully reimburse us for any losses we incur from
                third-party claims, and you waive your rights to bring any
                direct claims against us that result from such reporting. Our
                reporting of information under this paragraph is separate from
                any other right that we may exercise under this Agreement, and
                we may separately terminate this Agreement or suspend your Ryft
                Account due to the misuse or damaging activity that caused us to
                make the report.
              </p>
              <p>
                If you engage a third party processor to create and deliver a
                payment card transaction directly to the payment card network,
                then you may be able to specify that the transaction will be
                cleared and settled by Ryft via our Payment Processing Services.
                If you intend to make use of this service with respect to the
                Ryft Payment Processing Services, you first must notify us. If
                you engage a third party to deliver this service, and you are
                permitted to designate Ryft in this manner, then you take the
                risk of the third party properly delivering the transaction to
                the payment card network. You understand and agree that Ryft
                will only clear and settle to you funds for transactions that
                are actually received by the payment card network. You further
                assume responsibility for any failure by such third party to
                comply with the applicable Network Rules.
              </p>
              <p>
                b. American Express conversion: If your American Express
                Transaction volume exceeds a threshold amount set by American
                Express, American Express may convert you to a direct American
                Express merchant, which means that your acceptance of American
                Express Transactions will be governed by American Express’
                then-current Card Acceptance Agreement, and your pricing and
                other fees for the acceptance of American Express Transactions
                will be directly agreed between you and American Express. In the
                event of such conversion, your relationship with Ryft will not
                be otherwise affected, and Ryft will continue to process your
                American Express Transactions in accordance with this Agreement.
              </p>
              <a id='Settlement_and_Payout_Schedule'>
                <h4>Settlement and Payout Schedule</h4>
              </a>
              <p>
                a. Your Payout Account: Ryft will, with its banking partners,
                arrange to settle funds to the bank or other financial
                institution account that you designate (your “Payout Account”).
                You affirm that you are authorised to initiate settlements to
                and debits from the Payout Account, and that the Payout Account
                is owned by you, and administered and managed by a financial
                institution located in the European Union. If you update your
                Payout Account (including via the Dashboard) then you must
                ensure that you continue to comply with the requirements of this
                section. We may require you to provide us with documentary proof
                demonstrating your compliance with this section, and your
                failure to provide such proof will constitute a breach of this
                Agreement.
              </p>
              <p>
                b. Settlement to Your Payout Account: A positive balance in your
                Ryft Account will, subject to the terms of this Agreement,
                result in settlement to your Payout Account and a negative
                balance in your Ryft Account will result in a deduction, set-off
                and/or debit of the amounts owed.{' '}
              </p>
              <p>
                c. Settlement to third party recipients: We may offer you the
                ability to have funds settled to another person (a “third party
                recipient”) as instructed by you to Ryft (including by
                transferring all or part of the positive balance in your Ryft
                Account to the Ryft Account of such third party recipient),
                instead of settling funds to your Payout Account. If Ryft or a
                Payment Method Acquirer settles funds to a third party recipient
                (or transfers all or part of the balance in your Ryft Account to
                the Ryft Account of a third party recipient) as instructed by
                you, you agree that this satisfies Ryft’s obligations (and the
                obligations of any applicable Payment Method Provider and/or any
                Payment Method Acquirer) to settle funds to you, and we will
                promptly update your Ryft Account balance to reflect such
                settlement or transfer.
              </p>
              <p>
                d. Multi-currency Processing: We may offer you the ability to
                have funds settled to your Payout Account in a currency
                different from the one in which you accepted payment from a
                customer (“Multi-Currency Processing”). To use this service, you
                must provide us with a valid Payout Account for each currency
                for which you request settlement, based on our list of available
                settlement currencies. We may add or remove currencies from our
                list of available settlement currencies at any time. If you use
                Multi-Currency Processing, we will identify at the time of the
                Charge (for example, through the API), the conversion rate that
                will apply to the Charge. If you Refund a Charge, the conversion
                rate that will apply will be the rate in effect at the time of
                the Refund, not the Charge. By submitting a Charge or Refund you
                will be deemed to have accepted the applicable conversion rate.
                You may choose not to use the Multi-Currency Processing service
                at any time. You may also change the Payout Account information
                or other settings associated with your use of Multi-Currency
                Processing, but any such changes will only affect subsequent
                Charges.
              </p>
              <p>
                e. Payout Schedule: The term “Payout Schedule” refers to the
                time it takes for us to initiate settlement to your Payout
                Account. Ryft may require a holding period before making initial
                settlement to the Payout Account. After the initial settlement
                of funds, we will settle funds to the Payout Account according
                to the Payout Schedule and the terms of this Agreement. If the
                institution holding your Payout Account is based in the European
                Union, we expect the maximum execution time to execute a
                settlement to your Payout Account will be the next business day
                after we have initiated it; however, please be aware that a
                Payment Method Provider, a Payment Method Acquirer, or the
                financial institution holding your Payout Account, may delay
                settlement for any reason. We are not responsible for any action
                taken by the institution holding your Payout Account to not
                credit the Payout Account or to otherwise not make funds
                available to you as you expected.
              </p>
              <p>
                We reserve the right to change the Payout Schedule or to suspend
                settlement to you. Examples of situations where we may do so
                are: (i) where there are pending, anticipated, or excessive
                Disputes, Refunds, or Reversals; (ii) in the event that we
                suspect or become aware of suspicious activity; or (iii) where
                we are required by Law or court order. We have the right to
                withhold settlement to your Payout Account upon termination of
                this Agreement if we reasonably determine that we may incur
                losses resulting from credit, fraud, or other legal risks
                associated with your Ryft Account. If we exercise our right to
                withhold a Payout for any reason, we will communicate the
                general reason for withholding the Payout and give you a
                timeline for releasing the funds.
              </p>
              <p>
                f. Incorrect Settlement: The information required for settlement
                will depend on the financial institution holding the Payout
                Account. Please make sure that any information about the Payout
                Accounts that you provide to us is accurate and complete. If you
                provide us with incorrect information (i) you understand that
                funds may be settled to the wrong account and that we may not be
                able to recover the funds from such incorrect transactions and
                (ii) you agree that you are solely responsible for any losses
                you or third parties incur due to erroneous settlement
                transactions, you will not make any claims against us related to
                such erroneous settlement transactions, and you will fully
                reimburse us for any losses we incur.
              </p>
              <p>
                You also agree to abide by our Payout Partner’s terms &
                conditions found{' '}
                <a
                  href='https://www.paylution.com/hw2web/consumer/page/legalAgreement.xhtml'
                  target='_blank'
                >
                  here
                </a>{' '}
                and privacy policy found{' '}
                <a
                  href='https://www.paylution.com/hw2web/consumer/page/privacyAgreement.xhtml'
                  target='_blank'
                >
                  here
                </a>
                .
              </p>
              <a id='Clearing_Funds_and_Reserves'>
                <h4>Clearing Funds and Reserves</h4>
              </a>
              <p>
                All funds resulting from Charges are held in pooled clearing
                accounts (the “Clearing Accounts”) with our banking partners.
                Ryft will settle funds to and from the Clearing Accounts in the
                manner described in this Agreement; however, you have no rights
                to the Clearing Accounts or to any funds held in the Clearing
                Accounts, you are not entitled to draw funds from the Clearing
                Accounts, and you will not receive interest from funds
                maintained in the Clearing Accounts.
              </p>
              <p>
                In certain circumstances, we may (or may require you to) place
                funds in reserve or impose conditions on the release of funds to
                you (each a “Reserve”). We may establish a Reserve for any
                reason if we determine that the risk of loss to Ryft, Customers,
                or others associated with your Ryft Account is higher than
                normal. For example, we may hold or establish a Reserve if: (i)
                as a consequence of your or your Customers’ activities, we
                determine there is an unacceptable level of risk or prospect of
                loss to us or to your Customers, (ii) you have violated or are
                likely to violate this Agreement, or (iii) your Ryft Account has
                an elevated or abnormally high number of Disputes. We may at our
                discretion hold all funds in Reserve in a bank account in our
                name. All funds held in Reserve and all rights, title and
                interest to such funds, are at all times exclusively for our
                benefit and vested in us. If we establish a Reserve, we will
                provide you Notice of the amount, timing, and conditions upon
                which the funds in the Reserve will be released to you. In many
                cases, the Reserve amount will be the entire amount of Charges
                processed using the Payment Processing Services. We may change
                the terms of the Reserve or impose additional conditions to the
                Reserve based on our continuous assessment and understanding of
                the risks associated with your Ryft Account, including if
                required to do so by Payment Method Providers or Payment Method
                Acquirers, or for any other reason. We may fund the Reserve with
                funds processed through your use of Payment Processing Services,
                by debiting the Payout Account or another bank account
                associated with your Ryft Account, or by requesting funds
                directly from you.
              </p>
              <a id='Security_Interests_Collection_and_Set_Off_Rights'>
                <h4>Security Interests, Collection, and Set-Off Rights</h4>
              </a>
              <p>
                a. Security Interests: At any time upon our request, you will
                provide us, or you will procure that an entity(ies) or person(s)
                reasonably satisfactory to us will provide us, with security in
                such form and over such assets as we require to secure the
                performance of your obligations and liabilities to us under this
                Agreement, including all amounts that you owe to us or may owe
                in the future. You will execute and deliver any documents and
                pay any associated fees we consider necessary to create,
                perfect, and maintain security in such form and over such assets
                as we may reasonably require.
              </p>
              <p>
                b. Collection and Set-Off Rights: You agree to pay all amounts
                owed to us and to our affiliates on demand. Your failure to pay
                amounts owed to us or to our affiliates under this Agreement is
                a breach and you will be liable for any costs we incur during
                collection in addition to the amount you owe. Collection costs
                may include, attorneys’ fees and expenses, costs of any
                arbitration or court proceeding, collection agency fees, any
                applicable interest, and any other related cost. Where possible,
                we will first attempt to collect or set-off amounts owed to us
                and to our affiliates from balances in your Ryft Accounts from
                your use of the Payment Processing Services or from funds that
                we hold in Reserve. However, we may collect any amounts you owe
                us under this Agreement by deducting or setting-off amounts that
                you owe from the Ryft account balance or debiting the payout
                account for such Ryft account, or any Ryft account that we
                determine, acting reasonably, is associated with your Ryft
                Account. Similarly, we may deduct or set-off amounts from your
                Ryft Account balance (or debit your Payout Accounts) in order to
                collect amounts owed to us in relation to such associated Ryft
                accounts.
              </p>
              <p>
                In certain circumstances, we may require a personal, parent or
                other guarantee (a “Guarantee”) from a user’s principal, owner,
                or other guarantor. A Guarantee consists of a legally binding
                promise by an individual or an entity to pay any amounts the
                user owes in the event that the user is unable to pay. If we
                require you to provide us with a Guarantee, we will specifically
                inform you of the amount of, and the reasons for the Guarantee.
                If you are unable to provide such a Guarantee when required, you
                will not be permitted to use the Services.
              </p>
              <a id='Reconciliation_and_Error_Notification'>
                <h4>Reconciliation and Error Notification</h4>
              </a>
              <p>
                The Dashboard contains details of Charges, Charge history, and
                other activity on your Ryft Account. Except as required by Law,
                you are solely responsible for reconciling the information in
                the Dashboard generated by your use of Payment Processing
                Services with your records of Customer Transactions, and for
                identifying any errors.
              </p>
              <p>
                You agree to review your Ryft Account and immediately notify us
                of any errors. We will investigate any reported errors,
                including any errors made by Ryft or a Payment Method Provider,
                and, when appropriate, attempt to rectify them by crediting or
                debiting the Payout Account identified in the Dashboard.
                However, you should be aware that your ability to recover funds
                you have lost due to an error may be very limited or even
                impossible, particularly if we did not cause the error, or if
                funds are no longer available in any Payout Account.
              </p>
              <p>
                For Transaction errors, we will work with you and our Payment
                Method Providers to correct a Transaction error in accordance
                with the applicable Payment Method Rules. If you fail to
                communicate an error to us for our review without undue delay
                and, in any event, within 13 months after you discovered it and
                flagged it in the Dashboard, you waive your right to make any
                claim against us or our Payment Method Providers for any amounts
                associated with the error.
              </p>
              <a id='Dormant_Accounts'>
                <h4>Dormant Accounts</h4>
              </a>
              <p>
                If you leave any funds dormant in a Ryft Account and you do not
                give us instructions where to send them, we may be required by
                Law to deem the funds to be abandoned by you, and to deliver
                them to various government agencies. To the extent required by
                Law, we will attempt to provide you Notice if we hold funds
                payable to you in an account beyond the applicable dormancy
                period for abandoned property. If we are unable to contact you,
                we will treat the funds in your Ryft Account to be abandoned,
                and will deliver them to the appropriate government authority.
              </p>
              <h3>Section D: Data Usage, Privacy, and Security</h3>
              <a id='Data_Usage_Overview'>
                <h4>Data Usage Overview</h4>
              </a>
              <p>
                Protecting, securing, and maintaining the information processed
                and handled through the Services is one of our top priorities,
                and it should be yours too. This section describes our
                respective obligations when handling and storing information
                connected with the Services. The following terms used in this
                section relate to data provided to Ryft by you or your
                Customers, or received or accessed by you through your use of
                the Services:
              </p>
              <p>
                “Payment Account Details” means the Payment Method account
                details for a Customer, and includes, with respect to credit and
                debit cards, the cardholder’s account number, card expiration
                date, and CVV2.
              </p>
              <p>
                “Payment Data” means Payment Account Details, information
                communicated to or by Payment Method Providers or Payment Method
                Acquirers, financial information specifically regulated by Laws
                and Payment Method Rules, and any other information used with
                the Payment Processing Services to complete a Transaction.
              </p>
              <p>
                “Personal Data” means information that identifies a specific
                living person (not a company, legal entity, or machine) and is
                transmitted to or accessible through the Services.
              </p>
              <p>
                “Ryft Data” means details of the API transactions over Ryft
                infrastructure, information used in fraud detection and
                analysis, aggregated or anonymized information generated from
                Data, and any other information created by or originating from
                Ryft or the Services.
              </p>
              <p>
                “User Data” means information that describes your business and
                its operations, your products or services, and orders placed by
                Customers.
              </p>
              <p>
                The term “Data” used without a modifier means all Personal Data,
                User Data, Payment Data, and Ryft Data.
              </p>
              <p>
                Ryft processes, analyses, and manages Data to: (a) provide
                Services to you, other Ryft users, and Customers; (b) mitigate
                fraud, financial loss, or other harm to users, Customers and
                Ryft; and (c) analyse, develop and improve our products,
                systems, and tools. Ryft provides Data to third-party service
                providers, including Payment Method Providers, Payment Method
                Acquirers, and their respective affiliates, as well as to Ryft’s
                affiliates, to allow us to provide Services to you and other
                users. We do not provide Personal Data to unaffiliated parties
                for marketing their products to you. You understand and consent
                to Ryft’s use of Data for the purposes and in a manner
                consistent with this.
              </p>
              <a id='Data_Protection_and_Privacy'>
                <h4>Data Protection and Privacy</h4>
              </a>
              <p>
                a. Confidentiality: Ryft will only use User Data as permitted by
                this Agreement, by other agreements between you and us, or as
                otherwise directed or authorised by you. You will protect all
                Data you receive through the Services, and you may not disclose
                or distribute any such Data, and you will only use such Data in
                conjunction with the Services and as permitted by this Agreement
                or by other agreements between you and us. Neither party may use
                any Personal Data to market to Customers unless it has received
                the express consent from a specific Customer to do so. You may
                not disclose Payment Data to others except in connection with
                processing Transactions requested by Customers and consistent
                with applicable Laws and Payment Method Rules.
              </p>
              <p>
                b. Privacy: Protection of Personal Data is very important to us.
                You agree to the terms of our Privacy Policy, which we may
                update from time to time.
              </p>
              <p>
                You affirm that you are now and will continue to be compliant
                with all applicable Laws governing the privacy, protection, and
                your use of Data that you provide to us or access through your
                use of the Services. You also affirm that you have obtained all
                necessary rights and consents under applicable Laws to disclose
                to Ryft – or allow Ryft to collect, use, retain, and disclose –
                any Personal Data that you provide to us or authorise us to
                collect, including Data that we may collect directly from
                Customers using cookies or other similar means. As may be
                required by Law and in connection with this Agreement, you are
                solely responsible for disclosing to Customers that Ryft
                processes Transactions (including payment Transactions) for you
                and may receive Personal Data from you. Additionally, where
                required by Law or Payment Method Rules, we may delete or
                disconnect a Customer’s Personal Data from your Ryft Account
                when requested to do so by the Customer.
              </p>
              <p>
                If we become aware of an unauthorised acquisition, disclosure or
                loss of Customer Personal Data on our systems, we will notify
                you consistent with our obligations under applicable Law. We
                will also notify you and provide you sufficient information
                regarding the unauthorised acquisition, disclosure or loss to
                help you mitigate any negative impact on the Customer.
              </p>
              <p>
                c. PCI Compliance: If you use Payment Processing Services to
                accept payment card Transactions, you must comply with the
                Payment Card Industry Data Security Standards (“PCI-DSS”) and,
                if applicable to your business, the Payment Application Data
                Security Standards (PA-DSS) (collectively, the “PCI Standards”).
                The PCI Standards include requirements to maintain materials or
                records that contains payment card or Transaction data in a safe
                and secure manner with access limited to authorised personnel.
                Ryft provides tools to simplify your compliance with the PCI
                Standards, but you must ensure that your business is compliant.
                The specific steps you will need to take to comply with the PCI
                Standards will depend on your implementation of the Payment
                Processing Services. You will promptly provide us, or any
                applicable Payment Method Provider or Payment Method Acquirer,
                with documentation demonstrating your compliance with the PCI
                Standards upon our request. If you are unable to provide
                documentation sufficient to satisfy us, the Payment Method
                Providers, or the applicable Payment Method Acquirers, that you
                are compliant with the PCI Standards, then Ryft, and any
                applicable Payment Method Provider or Payment Method Acquirer,
                may access your business premises on reasonable notice to verify
                your compliance with the PCI Standards.
              </p>
              <p>
                If you elect to store or hold “Account Data”, as defined by the
                PCI Standards (including Customer card account number or
                expiration date), you must maintain a system that is compliant
                with the PCI Standards. If you do not comply with the PCI
                Standards, or if we or any Payment Method Provider or Payment
                Method Acquirer are unable to verify your compliance with the
                PCI Standards, we may suspend your Ryft Account or terminate
                this Agreement. If you intend to use a third party service
                provider to store or transmit Account Data, you must not share
                any data with the service provider until you verify that the
                third party holds sufficient certifications under the PCI
                Standards, and notify us of your intention to share Account Data
                with the service provider. Further, you agree to never store or
                hold any “Sensitive Authentication Data”, as defined by the PCI
                Standards (including CVC or CVV2), at any time. You can find
                information about the PCI Standards on the PCI Council’s
                website.
              </p>
              <p>
                d. Data Processing. You are the data controller and we are the
                data processor in relation to Personal Data processed on your
                behalf under this Agreement, except that we will be a data
                controller in relation to Personal Data where we determine the
                purposes and manner in which the Personal Data is processed
                (including, for example, in complying with any regulations or
                laws imposed upon us through Payment Method Rules or by Payment
                Method Providers or Payment Method Acquirers).
              </p>
              <p>
                We will, to the extent that we are a data processor, process
                Personal Data in accordance with the terms of this Agreement and
                lawful instructions reasonably given by you to us from time to
                time, and we will employ appropriate technical and
                organisational measures to protect such Personal Data. We will
                not be liable for any claim brought by a data subject arising
                from any action or omission by us, to the extent that such
                action or omission resulted from your instructions.
              </p>
              <a id='Security_and_Fraud_Controls'>
                <h4>Security and Fraud Controls</h4>
              </a>
              <p>
                a. Ryft’s Security: Ryft is responsible for protecting the
                security of Data in our possession. We will maintain
                commercially reasonable administrative, technical, and physical
                procedures to protect User Data and Personal Data stored in our
                servers from unauthorised access, accidental loss, modification,
                or breach, and we will comply with applicable Laws and Payment
                Method Rules when we handle User and Personal Data. However, no
                security system is impenetrable and we cannot guarantee that
                unauthorised parties will never be able to defeat our security
                measures or misuse any Data in our possession. You provide User
                Data and Personal Data to Ryft with the understanding that any
                security measures we provide may not be appropriate or adequate
                for your business, and you agree to implement Security Controls
                (as defined below) and any additional controls that meet your
                specific requirements. In our sole discretion, we may take any
                action, including suspension of your Ryft Account, to maintain
                the integrity and security of the Services or Data, or to
                prevent harm to you, us, Customers, or others. You waive any
                right to make a claim against us for losses you incur that may
                result from such actions.
              </p>
              <p>
                b. Your Security: You are solely responsible for the security of
                any Data on your website, your servers, in your possession, or
                that you are otherwise authorised to access or handle. You will
                comply with applicable Laws and Payment Method Rules when
                handling or maintaining User Data and Personal Data, and will
                provide evidence of your compliance to us upon our request. If
                you do not provide evidence of such compliance to our
                satisfaction, we may suspend your Ryft Account or terminate this
                Agreement.
              </p>
              <p>
                c. Security Controls: You are responsible for assessing the
                security requirements of your business, and selecting and
                implementing security procedures and controls (“Security
                Controls”) appropriate to mitigate your exposure to security
                incidents. We may provide Security Controls as part of the
                Services, or suggest that you implement specific Security
                Controls. However, your responsibility for securing your
                business is not diminished by any Security Controls that we
                provide or suggest, and if you believe that the Security
                Controls we provide are insufficient, then you must separately
                implement additional controls that meet your requirements
              </p>
              <p>
                Fraud Risk: While we may provide or suggest Security Controls,
                we cannot guarantee that you or Customers will never become
                victims of fraud. Any Security Controls we provide or suggest
                may include processes or applications developed by Ryft, its
                affiliates, or other companies. You agree to review all the
                Security Controls we suggest and choose those that are
                appropriate for your business to protect against unauthorised
                Transactions and, if appropriate for your business,
                independently implement other security procedures and controls
                not provided by us. If you disable or fail to properly use
                Security Controls, you will increase the likelihood of
                unauthorised Transactions, Disputes, fraud, losses, and other
                similar occurrences. Keep in mind that you are solely
                responsible for losses you incur from the use of lost or stolen
                payment credentials or accounts by fraudsters who engage in
                fraudulent Transactions with you, and your failure to implement
                Security Controls will only increase the risk of fraud. We may
                assist you with recovering lost funds, but you are solely
                responsible for losses due to lost or stolen credentials or
                accounts, compromise of your username or password, changes to
                your Payout Account, and any other unauthorised use or
                modification of your Ryft Account. Ryft is not liable or
                responsible to you and you waive any right to bring a claim
                against us for any losses that result from the use of lost or
                stolen credentials or unauthorised use or modification of your
                Ryft Account, unless such losses result from Ryft’s wilful or
                intentional actions. Further, you will fully reimburse us for
                any losses we incur that result from the use of lost or stolen
                credentials or accounts.
              </p>
              <p>
                We may also provide you with Data regarding the possibility or
                likelihood that a Transaction may be fraudulent. We may
                incorporate any subsequent action or inaction by you into our
                fraud model, for the purpose of identifying future potential
                fraud. You understand that we provide this Data to you for your
                consideration, but that you are ultimately responsible for any
                actions you choose to take or not take in relation to such Data.
              </p>
              <h3>Section E: Additional Legal Terms</h3>
              <a id='Right_to_Amend'>
                <h4>Right to Amend</h4>
              </a>
              <p>
                We have the right to change or add to the terms of this
                Agreement at any time, solely with prospective effect, and to
                change, delete, discontinue, or impose conditions on use of the
                Services by posting such changes on our website. We will provide
                you with Notice of any changes through the Dashboard, via email,
                or through other reasonable means. If you are an existing Ryft
                user, the changes will come into effect on the date we specify
                in the Notice, and your use of the Services, API, or Data after
                a change has taken effect, constitutes your acceptance of the
                terms of the modified Agreement. You can access a copy of the
                current terms of this Agreement on our website at any time.{' '}
              </p>
              <a id='Assignment'>
                <h4>Assignment</h4>
              </a>
              <p>
                You may not assign this Agreement, any rights or licences
                granted in this Agreement, or operation of your Ryft Account to
                others without our prior written consent. If we consent to the
                assignment, the assignee must agree to assume all of your rights
                and obligations owed by you related to the assignment, and must
                agree to comply with the terms of this Agreement. Ryft may
                assign this Agreement without your consent or any other
                restriction. If we make an assignment, we will provide
                reasonable Notice to you.
              </p>
              <a id='Right_to_Audit'>
                <h4>Right to Audit</h4>
              </a>
              <p>
                If we believe that a security breach, leak, loss, or compromise
                of Data has occurred on your systems, website, or app affecting
                your compliance with this Agreement, we may require you to
                permit a third-party auditor approved by us to conduct a
                security audit of your systems and facilities, and you must
                fully cooperate with any requests for information or assistance
                that the auditor makes to you as part of the security audit. The
                auditor will issue a report to us which we may share with our
                Payment Method Providers and Payment Methods Acquirers.
              </p>
              <a id='No_Agency_Third_Party_Services'>
                <h4>No Agency; Third-Party Services</h4>
              </a>
              <p>
                Except as expressly stated in this Agreement, nothing in this
                Agreement serves to establish a partnership, joint venture, or
                other agency relationship between you and us, or with any
                Payment Method Provider. Each party to this Agreement, and each
                Payment Method Provider and Payment Method Acquirer, is an
                independent contractor. Unless a Payment Method Provider or
                Payment Method Acquirer expressly agrees, neither you nor we
                have the ability to bind a Payment Method Provider or Payment
                Method Acquirer to any contract or obligation, and neither party
                will represent that you or we have such an ability.
              </p>
              <p>
                We may reference or provide access to third-party services,
                products, and promotions that utilize, integrate, or provide
                ancillary services to the Services (“Third-Party Services”).
                These Third-Party Services are provided for your convenience
                only and do not constitute our approval, endorsement, or
                recommendation of any such Third-Party Services for you. You
                access and use any Third-Party Service based on your own
                evaluation and at your own risk. You understand that your use of
                any Third-Party Service is not governed by this Agreement. If
                you decide to use a Third-Party Service, you will be responsible
                for reviewing, understanding and accepting the terms and
                conditions associated with its use. We expressly disclaim all
                responsibility and liability for your use of any Third-Party
                Service. Please also remember that when you use a Third-Party
                Service, our Privacy Policy is no longer in effect. Your use of
                a Third-Party Service, including those that have a link on our
                website, is subject to that Third-Party Service’s own terms of
                use and privacy policies.
              </p>
              <a id='Force_Majeure'>
                <h4>Force Majeure</h4>
              </a>
              <p>
                Neither party will be liable for any delays in processing or
                other nonperformance caused by telecommunications, utility
                failures, or equipment failures; labor strife, riots, war, or
                terrorist attacks; nonperformance of our vendors or suppliers,
                fires or acts of nature; or any other event over which the
                respective party has no reasonable control. However, nothing in
                this section will affect or excuse your liabilities or your
                obligation to pay Fees, Fines, Disputes, Refunds, Reversals, or
                Returns under this Agreement.
              </p>
              <a id='Your_Liability_For_Third_Party_Claims_Against_Us'>
                <h4>Your Liability For Third-Party Claims Against Us</h4>
              </a>
              <p>
                Without limiting, and in addition to, any other obligation that
                you may owe under this Agreement, you are at all times
                responsible for the acts and omissions of your employees,
                contractors and agents, to the extent such persons are acting
                within the scope of their relationship with you.
              </p>
              <p>
                You agree to defend Ryft, our affiliates, and their respective
                employees, agents, and service providers (each a “Ryft Entity”)
                against any claim, suit, demand, loss, liability, damage,
                action, or proceeding (each, a “Claim”) brought by a third party
                against a Ryft Entity, and you agree to fully reimburse the Ryft
                Entities for any Claims that result from: (i) your breach of any
                provision of this Agreement; (ii) any Fees, Fines, Disputes,
                Refunds, Reversals, Returns, or any other liability we incur
                that results from your use of the Payment Processing Services;
                (iii) negligent or wilful misconduct of your employees,
                contractors, or agents; or (iv) contractual or other
                relationships between you and Customers.
              </p>
              <p>
                Important Note for Sole Proprietors: If you are using Services
                as a sole proprietor, please keep in mind that the Law and the
                terms of this Agreement consider you and your business to be
                legally one and the same. You are personally responsible and
                liable for your use of the Services, payment of Fees, Refunds,
                Reversals, Fines, losses based on Disputes or fraud, or for any
                other amounts you owe under this Agreement for your failure to
                use Security Controls, and for all other obligations to us and
                to your Customers. You risk personal financial loss if you fail
                to pay any amounts owed. Please take the time to read our
                Documentation and take any measures appropriate to protect
                against such losses.
              </p>
              <a id='Representations_and_Warranties'>
                <h4>Representations and Warranties</h4>
              </a>
              <p>
                By accepting the terms of this Agreement, you represent and
                warrant that: (a) you are eligible to register and use the
                Services and have the authority to execute and perform the
                obligations required by this Agreement; (b) any information you
                provide us about your business, products, or services is
                accurate and complete; (c) any Charges represent a Transaction
                for permitted products, services, or donations, and any related
                information accurately describes the Transaction; (d) you will
                fulfil all of your obligations to Customers and will resolve all
                Disputes with them; (e) you will comply with all Laws applicable
                to your business and use of the Services; (f) your employees,
                contractors and agents will at all times act consistently with
                the terms of this Agreement; (g) you will not use Payment
                Processing Services for personal, family or household purposes,
                for peer-to-peer money transmission, or (except in the normal
                course of business) intercompany Transactions; and (h) you will
                not use the Services, directly or indirectly, for any fraudulent
                or illegal undertaking, or in any manner that interferes with
                the normal operation of the Services.
              </p>
              <a id='Limitation_of_Liability'>
                <h4>Limitation of Liability</h4>
              </a>
              <p>
                Under no circumstances will any Ryft Entity be responsible or
                liable to you for any indirect, punitive, incidental, special,
                consequential, or exemplary damages resulting from your use or
                inability to use the Services or for the unavailability of the
                Services, for lost profits, personal injury, or property damage,
                or for any other damages arising out of, in connection with, or
                relating to this Agreement or your use of the Services, even if
                such damages are foreseeable, and whether or not you or the Ryft
                Entities have been advised of the possibility of such damages.
                The Ryft Entities are not liable, and deny responsibility for,
                any damages, harm, or losses to you arising from or relating to
                hacking, tampering, or other unauthorised access or use of the
                Services, your Ryft Account, or Data, or your failure to use or
                implement anti-fraud measures, Security Controls, or any other
                data security measure. The Ryft Entities further deny
                responsibility for all liability and damages to you or others
                caused by (a) your access or use of the Services inconsistent
                with the Documentation; (b) any unauthorised access of servers,
                infrastructure, or Data used in connection with the Services;
                (c) interruptions to or cessation of the Services; (d) any bugs,
                viruses, or other harmful code that may be transmitted to or
                through the Services; (e) any errors, inaccuracies, omissions,
                or losses in or to any Data provided to us; (f) third-party
                content provided by you; or (g) the defamatory, offensive, or
                illegal conduct of others.
              </p>
              <p>
                You agree to limit any additional liability not disclaimed or
                denied by the Ryft Entities under this Agreement to your direct
                and documented damages; and you further agree that under no
                circumstances will any such liability exceed in the aggregate
                the amount of Fees paid by you to Ryft during the three-month
                period immediately preceding the event that gave rise to your
                claim for damages.
              </p>
              <p>
                These limitations on our liability to you will apply regardless
                of the legal theory on which your claim is based, including
                contract, tort (including negligence), strict liability, or any
                other theory or basis.
              </p>
              <p>
                We provide some of the Services from facilities in the United
                States. We do not claim, and we cannot guarantee that Services
                we provide from the United States are or will be appropriate or
                available for any other location or jurisdiction, comply with
                the Laws of any other location or jurisdiction, or comply with
                Laws governing export, import, or foreign use.
              </p>
              <a id='Responding_to_Legal_Process'>
                <h4>Responding to Legal Process</h4>
              </a>
              <p>
                Ryft may respond to and comply with any writ of attachment,
                lien, levy, subpoena, warrant, or other legal order (“Legal
                Process”) that we believe to be valid. We or any Payment Method
                Provider (or, where applicable, the Payment Method Acquirer for
                the Payment Method) may deliver or hold any funds or, subject to
                the terms of our Privacy Policy, any Data as required under such
                Legal Process, even if you are receiving funds or Data on behalf
                of other parties. Where permitted by Law, we will make
                reasonable efforts to provide you Notice of such Legal Process
                by sending a copy to the email address we have on file for you.
                Ryft is not responsible for any losses, whether direct or
                indirect, that you may incur as a result of our response or
                compliance with a Legal Process.
              </p>
              <a id='Dispute_Resolution_Agreement_to_Arbitrate'>
                <h4>Dispute Resolution; Agreement to Arbitrate</h4>
              </a>
              <p>
                a. Binding Arbitration: Any dispute, controversy or claim
                arising out of or relating to this contract, including the
                formation, interpretation, breach or termination thereof,
                including whether the claims asserted are arbitrable, will be
                referred to and finally determined by arbitration in accordance
                with the JAMS International Arbitration Rules. The tribunal will
                consist of a sole arbitrator. The place of the arbitration will
                be Dublin, Ireland. The language to be used in the arbitral
                proceedings will be English. Judgment upon the award rendered by
                the arbitrator may be entered by any court having jurisdiction.
              </p>
              <p>
                This Agreement and the rights of the parties hereunder shall be
                governed and construed in accordance with the laws of Ireland,
                exclusive of conflict or choice of law rules. Nothing in this
                section will preclude the parties from seeking provisional
                remedies in aid of arbitration from a court of appropriate
                jurisdiction.
              </p>
              <p>
                Either party may commence arbitration by providing to JAMS and
                the other party to the dispute a written demand for arbitration,
                setting forth the subject of the dispute and the relief
                requested.
              </p>
              <p>
                b. Service of Process: Each party hereby irrevocably and
                unconditionally consents to service of process through personal
                service at their corporate headquarters, registered address, or
                primary address (for individuals or sole proprietors). Nothing
                in this Agreement will affect the right of any party to serve
                process in any other manner permitted by Law.
              </p>
              <p>
                c. Class Waiver: To the fullest extent permitted by Law, each of
                the parties agrees that any dispute arising out of or in
                connection with this Agreement, whether in arbitration or in
                court, will be conducted only on an individual basis and not in
                a class, consolidated or representative action. If for any
                reason a claim or dispute proceeds in court rather than through
                arbitration, each party knowingly and irrevocably waives any
                right to trial by jury in any action, proceeding or counterclaim
                arising out of or relating to this Agreement or any of the
                transactions contemplated between the parties.
              </p>
              <p>
                d. Provision of an Award: Subject to the limitations of
                liability identified in this Agreement, the appointed
                arbitrators may award monetary damages and any other remedies
                allowed by Law. In making a determination, the arbitrator will
                not have the authority to modify any term or provision of this
                Agreement. The arbitrator will deliver a reasoned written
                decision with respect to the dispute (the “Award”) to each
                party, who will promptly act in accordance with the Award. Any
                Award (including interim or final remedies) may be confirmed in
                or enforced by any court having jurisdiction over either party
                or its assets. The decision of the arbitrator will be final and
                binding on the parties, and will not be subject to appeal or
                review.
              </p>
              <p>
                e. Fees: Each party will advance one-half of the fees and
                expenses of the arbitrator, the costs of the attendance of the
                court reporter at the arbitration hearing, and the costs of the
                arbitration facility. In any arbitration arising out of or
                related to this Agreement, the arbitrator will award to the
                prevailing party, if any, the costs and attorneys’ fees
                reasonably incurred by the prevailing party in connection with
                those aspects of its claims or defenses on which it prevails,
                and any opposing awards of costs and attorneys’ fees awards will
                be offset.
              </p>
              <p>
                f. Confidentiality: The parties will maintain the confidential
                nature of the arbitration proceeding, the hearing and the Award,
                except (i) as may be necessary to prepare for or conduct the
                arbitration hearing on the merits, (ii) in connection with a
                court application as contemplated above for a preliminary
                remedy, or confirmation of an Award or its enforcement, (iii)
                our disclosure of the Award in confidential settlement
                negotiations, or (iv) as otherwise required by applicable Laws.
                The parties, witnesses, and arbitrator will treat as
                confidential and will not disclose to any third person (other
                than witnesses or experts) any documentary or other evidence
                produced in any arbitration hereunder, except as required by Law
                or except if such evidence was obtained from the public domain
                or was otherwise obtained independently from the arbitration.
              </p>
              <a id='Entire_Agreement'>
                <h4>Entire Agreement</h4>
              </a>
              <p>
                This Agreement and all policies and procedures that are
                incorporated by reference constitute the entire agreement
                between you and Ryft for provision and use of the Services.
                Except where expressly stated otherwise in a writing executed
                between you and Ryft, this Agreement will prevail over any
                conflicting policy or agreement for the provision or use of the
                Services. This Agreement sets forth your exclusive remedies with
                respect to the Services. If any provision or portion of this
                Agreement is held to be invalid or unenforceable under Law, then
                it will be reformed and interpreted to accomplish the objectives
                of such provision to the greatest extent possible, and all
                remaining provisions will continue in full force and effect.
              </p>
              <a id='Cumulative_Rights_Construction_Waiver'>
                <h4>Cumulative Rights, Construction, Waiver</h4>
              </a>
              <p>
                The rights and remedies of the parties under this Agreement are
                cumulative, and either party may enforce any of its rights or
                remedies under this Agreement, along with all other rights and
                remedies available to it at Law, in equity or under the Payment
                Method Rules. No provision of this Agreement will be construed
                against any party on the basis of that party being the drafter.
                Unless expressly stated otherwise, the use of the term
                “including” or “such as” is not to be interpreted as limiting
                the generality of the text preceding the term. The failure of
                either party to enforce any provision of this Agreement will not
                constitute a waiver of that party’s rights to subsequently
                enforce the provision.
              </p>
              <a id='Language'>
                <h4>Language</h4>
              </a>
              <p>
                The parties hereby acknowledge that they have required this
                Agreement and all related documents to be in the English
                language.
              </p>
              <h3>Bacs Direct Debit Request Service Agreement</h3>
              <a id='Debiting_your_account'>
                <h4>Debiting your account</h4>
              </a>
              <p>
                In the event that the net activity in your account on a day is
                negative or Ryft needs you to fund your account for any other
                reason relating to the Ryft Services Agreement, you authorise
                and request Ryft to debit your Bank Account by using the
                Bankers’ Automated Clearing Services (Bacs).
              </p>
              <p>
                You will be sent an e-mail confirmation of the direct debit
                set-up within three working days of you setting up your account.
              </p>
              <p>
                If you’d like details in advance of transfers (both debits and
                credits), Ryft offers this as a service that you can subscribe
                to.
              </p>
              <a id='Your_responsibilities'>
                <h4>Your responsibilities</h4>
              </a>
              <p>It’s your responsibility to:</p>
              <p>
                (a) Check whether or not your Bank Account can accept debit and
                credit transfers through Bacs;
              </p>
              <p>
                (b) Ensure that your account has sufficient funds to allow for a
                debit transfer; and
              </p>
              <p>
                (c) Check your bank statement account details and to verify that
                all amounts are correct.
              </p>
              <p>
                If transfers are returned to Ryft by your bank, we may charge
                you a fee according to your Fee Schedule, in addition to any
                fees your bank may charge. If a debit transfer is returned to
                Ryft because your Bank Account has insufficient funds, it is
                your responsibility to fund the Bank Account so that we can
                re-process the debit transfer.
              </p>
              <a id='The_Direct_Debit_Guarantee'>
                <h4>The Direct Debit Guarantee</h4>
              </a>
              <p>
                This Guarantee is offered by all banks and building societies
                that accept instructions to pay Direct Debits.
              </p>
              <p>
                If there are any changes to the amount, date or frequency of
                your Direct Debit, Ryft will notify you one working day in
                advance of your account being debited or as otherwise agreed. If
                you request Ryft to collect a payment, confirmation of the
                amount and date will be given to you at the time of the request
                If an error is made in the payment of your Direct Debit, by Ryft
                or your bank or building society, you are entitled to a full and
                immediate refund of the amount paid from your bank or building
                society.
              </p>
              <p>
                If you receive a refund you are not entitled to, you must pay it
                back when Ryft asks you to. You can cancel a Direct Debit at any
                time by simply contacting your bank or building society. Written
                confirmation may be required.
              </p>
              <a id='Inquiries_Errors_and_Disputes'>
                <h4>Inquiries, Errors, and Disputes</h4>
              </a>
              <p>
                If you believe there has been an error in debiting your account,
                please get in touch. You can also notify your own Bank.
              </p>
              <p>
                If there has been an error, we’ll arrange with your bank to
                adjust your account and we’ll notify you of the amount of the
                adjustment.
              </p>
              <p>
                If we don’t find an error, we’ll respond to your query with
                evidence of the correctness of the transfer.
              </p>
              <a id='Changes_to_this_Direct_Debit_Request_Service_Agreement'>
                <h4>Changes to this Direct Debit Request Service Agreement</h4>
              </a>
              <p>
                Ryft will provide you with at least 14 days’ notice before any
                changes to this Direct Debit Request Service Agreement. If you’d
                like to amend this Direct Debit Request Service Agreement or to
                request that a transfer be deferred or altered, you can do so
                providing Ryft notice at least 7 days beforehand or by emailing
                us. You can also request that a transfer be stopped, cancelled,
                or deferred through your bank.
              </p>
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { TermsRoute };
