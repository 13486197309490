import { useEffect, useState } from 'react';
import { useApiRequest } from './useApiRequest';

function useGetSubscriptionPayments(subscriptionId: string | null): any {
  const [payments, setPayments] = useState<any>(false);

  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setPayments(response.data.paymentSessions.items);
    }
  }, [response]);

  useEffect(() => {
    if (subscriptionId !== null) {
      const url = `/v1/transactions?subscriptionId=${subscriptionId}&ascending=false&limit=10`;
      sendRequest({ url });
    }
  }, [subscriptionId]);

  return [isLoading, payments, error];
}

export { useGetSubscriptionPayments };
