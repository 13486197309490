import { ReactElement, useEffect, useState } from 'react';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { CustomerDetail } from './CustomerDetail';
import { useHistory } from 'react-router-dom';
import { Customer } from '../../interfaces/customersState';
import { Copy } from '../forms/Copy';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import PaymentMethodsTab from '../sharedTabs/PaymentMethods';

interface CustomersProps {
  customers: Customer[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  error?: string | undefined;
}

const Customers = ({
  customers,
  isMore,
  getMore,
  isLoading,
  error,
}: CustomersProps): ReactElement => {
  const history = useHistory();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const { setCustomersState, customersState } = useGlobalDataState();
  const { profileState } = useGlobalDataState();
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [canDeletePaymentMethods, setCanDeletePaymentMethods] = useState(false);
  const [activeTab, setActiveTab] = useState('Detail');

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    setShowPaymentMethods(permissions.has('PaymentMethodsView'));
    setCanDeletePaymentMethods(permissions.has('PaymentMethodsModify'));
  }, [profileState]);

  const scrollToSelectedItem = (): void => {
    if (selectedCustomer) {
      const selectedItemElement = document.getElementById(
        `customer-${selectedCustomer.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = customers.findIndex(
      (customer) => customer.id === selectedCustomer?.id
    );
    if (currentIndex > 0) {
      handleSelectedCustomer(customers[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = customers.findIndex(
      (customer) => customer.id === selectedCustomer?.id
    );
    if (currentIndex < customers.length - 1) {
      handleSelectedCustomer(customers[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedCustomer) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCustomer]);

  const handleTabChange = (tabName: string): void => setActiveTab(tabName);

  const setSelected = (id: string): void => {
    const customer = customers.find((customer) => customer.id === id);
    setSelectedCustomer(customer || null);
    setCustomersState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: customer,
      },
    }));
  };

  const handleCloseSideMenu = (): void => {
    setSelectedCustomer(null);
    history.push(`/customers`);
  };

  const handleSelectedCustomer = (id: string): void => {
    if (isMobile()) {
      history.push(`/customers/${id}`);
      return;
    }
    setSelected(id);
    history.push(`/customers?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedCustomer(null);
    }
  }, [location.search, customers]);

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='Customers'>
      <div className='Customers--heading'>
        <div>Email</div>
        <div>Name</div>
        <div>Id</div>
        <div className='Customers--heading-mobile'>Created</div>
      </div>
      <div className='Customers--scroll' id='scrollArea'>
        {customers.map((customer) => (
          <div
            id={`customer-${customer.id}`}
            key={customer.id}
            onClick={(): void => handleSelectedCustomer(customer.id)}
            className={
              customer.id === selectedCustomer?.id
                ? 'Customers--item Customers--selected'
                : 'Customers--item'
            }
          >
            <div className='Customers--txt'>{customer.email}</div>
            <div className='Customers--txt'>
              {customer.firstName} {customer.lastName}
            </div>
            {isMobile() && (
              <div className='Customers--txt'>
                {`${customer.id.substring(0, 4)}...${customer.id.substring(
                  customer.id.length - 4,
                  customer.id.length
                )}`}
              </div>
            )}
            {!isMobile() && <div className='Customers--txt'>{customer.id}</div>}
            <div className='Customers--txt Customers--txt-mobile'>
              <DateTime
                showTime
                value={epochSecondsToDate(customer.createdTimestamp)}
              />
            </div>
          </div>
        ))}
        {isLoading && !error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
        )}
        {!isLoading && error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
        )}
      </div>
      <SideMenu
        isOpen={!!selectedCustomer}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div style={{ height: '100%' }}>
          <div className='SideBar--title-grid'>
            <div className='SideBar--title'>
              Customer
              <br />
              <span className='SideBar--id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={selectedCustomer?.id || ''}
                  text={selectedCustomer?.id || ''}
                  direction='DOWN'
                />
              </span>
            </div>
            <div className='SideBar--esc'>Esc</div>
          </div>
          <div className='SideBar--nav-grid'>
            <div className='SideBar--tab-group'>
              <button
                className={
                  activeTab === 'Detail'
                    ? 'SideBar--tab-active'
                    : 'SideBar--tab'
                }
                onClick={(): void => handleTabChange('Detail')}
              >
                Detail
              </button>
              {showPaymentMethods && (
                <button
                  className={
                    activeTab === 'Payment Methods'
                      ? 'SideBar--tab-active'
                      : 'SideBar--tab'
                  }
                  onClick={(): void => handleTabChange('Payment Methods')}
                >
                  Payment Methods
                </button>
              )}
            </div>
            <div>
              <button
                className='SideBar--btn-back'
                onClick={handleBack}
                disabled={
                  !selectedCustomer ||
                  customers.findIndex(
                    (customer) => customer.id === selectedCustomer?.id
                  ) === 0
                }
              />

              <button
                className='SideBar--btn-next'
                onClick={handleNext}
                disabled={
                  !customersState.data?.selected ||
                  customers.findIndex(
                    (customer) =>
                      customer.id === customersState.data?.selected?.id
                  ) ===
                    customers.length - 1
                }
              />
            </div>
          </div>

          <div className='SideBar--display-grid'>
            {activeTab === 'Detail' && customersState.data?.selected && (
              <CustomerDetail customer={selectedCustomer} />
            )}
            {activeTab === 'Payment Methods' && selectedCustomer && (
              <PaymentMethodsTab
                customerId={selectedCustomer.id}
                canDelete={canDeletePaymentMethods}
              />
            )}
          </div>
        </div>
      </SideMenu>
    </div>
  );
};
export { Customers };
