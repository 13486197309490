import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { SelectInput } from '../forms/SelectInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { DateOfBirth } from '../forms/DateOfBirth';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';
import SelectTags from '../forms/SelectTags';
import { countries } from '../../functions/iso3166-alpha2';
import { useGetAccount } from '../../hooks/useGetAccount';
import { entityTypes } from '../../functions/accountHelpers';
import { PhoneNumber } from '../forms/PhoneNumber';

interface AlmostProps {
  accountType: string;
  setAccountType: (accountType: string) => void;
}

const AlmostDone = ({
  accountType,
  setAccountType,
}: AlmostProps): ReactElement => {
  const history = useHistory();
  const [entityType, setEntityType] = useState<string>('');
  // Individual
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [middleNameError, setMiddleNameError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [nationalities, setNationalities] = useState<string[]>([]);
  const [nationalitiesError, setNationalitiesError] = useState<string>('');
  // Business
  const [businessName, setBusinessName] = useState<string>('');
  const [businessNameError, setBusinessNameError] = useState<string>('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState<string>('');
  const [businessRegistrationNumberError, setBusinessRegistrationNumberError] =
    useState<string>('');
  // General
  const [phoneNumber, setPhoneNumber] = useState<any>({});
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    accountData,
    isLoading: isGetAccountLoading,
    error: getAccountError,
    fetchAccount,
  } = useGetAccount();

  const requiredInputsSupplied = (): boolean => {
    const individualDetailsSupplied =
      accountType === 'Individual' &&
      !!firstName &&
      !!lastName &&
      !!dateOfBirth &&
      !!gender &&
      nationalities.length > 0;
    const businessDetailsSupplied =
      accountType === 'Business' &&
      !!businessName &&
      !!businessRegistrationNumber;
    return individualDetailsSupplied || businessDetailsSupplied;
  };

  useEffect(() => {
    setEntityType(localStorage.getItem('businessType') || '');
    setFirstName(localStorage.getItem('firstName') || '');
    setMiddleName(localStorage.getItem('middleName') || '');
    setLastName(localStorage.getItem('lastName') || '');
    setDateOfBirth(localStorage.getItem('dateOfBirth') || '');
    setGender(localStorage.getItem('gender') || '');
    setNationalities(localStorage.getItem('nationalities')?.split(',') || []);
    setBusinessName(localStorage.getItem('businessName') || '');
    setBusinessRegistrationNumber(
      localStorage.getItem('businessRegistrationNumber') || ''
    );
    setPhoneNumber(localStorage.getItem('phoneNumber') || '');
  }, []);

  useEffect(() => {
    fetchAccount();
  }, []);

  useEffect(() => {
    if (accountData) {
      const phoneNumber =
        accountData.individual?.phoneNumber ??
        accountData.business?.phoneNumber ??
        '';
      setFirstName(accountData.individual?.firstName ?? '');
      setMiddleName(accountData.individual?.middleNames ?? '');
      setLastName(accountData.individual?.lastName ?? '');
      setDateOfBirth(accountData.individual?.dateOfBirth ?? '');
      setGender(accountData.individual?.gender ?? '');
      setNationalities(accountData.individual?.nationalities ?? []);
      setBusinessName(accountData.business?.name ?? '');
      setBusinessRegistrationNumber(
        accountData.business?.registrationNumber ?? ''
      );
      if (phoneNumber) {
        setPhoneNumber({ number: phoneNumber, isValid: true });
      }
      localStorage.setItem(
        'accountEmail',
        accountData.business?.contactEmail ??
          accountData.individual?.email ??
          accountData.email!
      );
      const address =
        accountData.business?.registeredAddress ??
        accountData.individual?.address;
      if (!address) {
        return;
      }
      localStorage.setItem('editedAddress', JSON.stringify(address));
    }
  }, [accountData]);

  useEffect(() => {
    if (entityType !== '') {
      setAccountType(entityTypes[entityType].accountType);
    }
  }, [entityType]);

  useEffect(() => {
    setBtnDisabled(!requiredInputsSupplied());
  }, [
    accountType,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    nationalities,
    businessName,
    businessRegistrationNumber,
  ]);

  const getPhoneNumberDescription = (): string => {
    if (accountType === 'Business') {
      return 'The primary phone number for your business';
    }
    return '';
  };

  useEffect(() => {
    localStorage.setItem('accountType', accountType);
  }, [accountType]);

  const almost = async (): Promise<void> => {
    let valid: boolean = true;
    setIsLoading(true);
    if (
      phoneNumber &&
      phoneNumber.number &&
      phoneNumber.number.length > 1 &&
      !phoneNumber.valid
    ) {
      setPhoneNumberError('Phone number is invalid');
      valid = false;
    }
    if (accountType === 'Individual') {
      setFirstNameError('');
      setMiddleNameError('');
      setLastNameError('');
      setDateOfBirthError('');
      setGenderError('');
      setNationalitiesError('');
      setPhoneNumberError('');
      if (firstName === '') {
        setFirstNameError('Required');
        valid = false;
      }
      if (lastName === '') {
        setLastNameError('Required');
        valid = false;
      }
      if (dateOfBirth === '') {
        setDateOfBirthError('Required');
        valid = false;
      }
      if (valid) {
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('middleName', middleName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('dateOfBirth', dateOfBirth);
        localStorage.setItem('gender', gender);
        localStorage.setItem('nationalities', nationalities?.join(','));
        if (phoneNumber) {
          localStorage.setItem('phoneNumber', phoneNumber.number);
        }
        history.push('last');
      } else {
        setIsLoading(false);
      }
    } else if (accountType === 'Business') {
      setBusinessNameError('');
      setBusinessRegistrationNumberError('');
      if (businessName === '') {
        setBusinessNameError('Required');
        valid = false;
      }
      if (businessRegistrationNumber === '') {
        setBusinessRegistrationNumberError('Required');
        valid = false;
      }
      if (valid) {
        localStorage.setItem('businessName', businessName);
        localStorage.setItem('businessType', entityType);
        localStorage.setItem(
          'businessRegistrationNumber',
          businessRegistrationNumber
        );
        if (phoneNumber) {
          localStorage.setItem('phoneNumber', phoneNumber.number);
        }
        history.push('/last');
      } else {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className='AlmostDone'>
      <StepPosition steps={4} position={3} />
      {isGetAccountLoading && !accountData && !getAccountError && (
        <>Loading...</>
      )}
      {!isGetAccountLoading && getAccountError && (
        <ServerError error={getAccountError} />
      )}
      {!isGetAccountLoading && (
        <>
          <form
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
              almost();
            }}
          >
            <SelectInput
              label
              name='Entity Type'
              value={entityType}
              setValue={setEntityType}
              selectPrompt='Please choose an entity type:'
              options={Object.entries(entityTypes).map((v: any) => ({
                value: v[0],
                name: v[1].displayName,
              }))}
            />
            {accountType === 'Individual' && (
              <>
                <TextInput
                  label
                  name='First Name'
                  value={firstName}
                  setValue={setFirstName}
                  error={firstNameError}
                />
                <TextInput
                  label
                  name='Middle Name'
                  value={middleName}
                  setValue={setMiddleName}
                  error={middleNameError}
                  optional
                />
                <TextInput
                  label
                  name='Last Name'
                  value={lastName}
                  setValue={setLastName}
                  error={lastNameError}
                />
                <DateOfBirth
                  value={dateOfBirth}
                  setValue={setDateOfBirth}
                  error={dateOfBirthError}
                  label
                />
                <SelectInput
                  label
                  name='Gender at birth'
                  description='International regulations require either Female or Male'
                  value={gender}
                  setValue={setGender}
                  selectPrompt='Select...'
                  options={[
                    { value: 'Male', name: 'Male' },
                    { value: 'Female', name: 'Female' },
                  ]}
                  error={genderError}
                />
                <SelectTags
                  name='Nationalities'
                  description='Please select all that apply'
                  selectedArray={nationalities}
                  optionsArray={countries()}
                  setSelectedArray={setNationalities}
                  error={nationalitiesError}
                />
              </>
            )}
            {accountType === 'Business' && (
              <>
                <TextInput
                  label
                  name='Business Name'
                  value={businessName}
                  setValue={setBusinessName}
                  error={businessNameError}
                />
                <TextInput
                  label
                  name='Business Registration Number'
                  description="If you don't have a registration number then please register as an Individual / Sole Trader"
                  value={businessRegistrationNumber}
                  setValue={setBusinessRegistrationNumber}
                  error={businessRegistrationNumberError}
                />
              </>
            )}
            <PhoneNumber
              label
              name='Phone Number'
              description={getPhoneNumberDescription()}
              value={phoneNumber.number}
              setValue={setPhoneNumber}
              error={phoneNumberError}
              optional={true}
            />
            <Button
              name='Next'
              click={almost}
              loading={isLoading}
              disabled={btnDisabled}
            />
          </form>
          <div
            onClick={(): void => history.push('/signin')}
            className='AlmostDone--center'
          >
            <a>Back</a>
          </div>
        </>
      )}
    </div>
  );
};
export { AlmostDone };
