import { ReactElement, useEffect, useState } from 'react';
import { Button } from '../forms/Button';
import { DatePicker } from '../forms/DatePicker';
import { SelectInput } from '../forms/SelectInput';
import {
  dateToStartOfDayEpochSeconds,
  epochSecondsToDate,
} from '../../functions/helpers';
import { useHistory } from 'react-router-dom';
import { CheckBox } from '../forms/CheckBox';

export type DashboardDataType = 'Volume' | 'Payments' | 'Customers';

export interface DashboardFilter {
  selectedData: DashboardDataType[];
  startDate: Date;
  endDate: Date;
  currency: string;
}

interface FilterDashboardProps {
  availableCurrencies: string[];
  setFilter: (filter: DashboardFilter) => void;
}

const FilterDashboard = ({
  availableCurrencies,
  setFilter,
}: FilterDashboardProps): ReactElement => {
  const history = useHistory();
  const dateNow = new Date();
  const initialStartDate = new Date();
  initialStartDate.setDate(dateNow.getDate() - 15);
  const allDataTypes: DashboardDataType[] = ['Volume', 'Payments', 'Customers'];
  const [selectedData, setSelectedData] =
    useState<DashboardDataType[]>(allDataTypes);
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(dateNow);
  const [startDateChanged, setStartDateChanged] = useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>('GBP');

  const handleApplyFilter = (): void => {
    const filter: DashboardFilter = {
      selectedData: selectedData,
      startDate: startDate,
      endDate: endDate,
      currency: currency,
    };
    setFilter(filter);
  };

  useEffect(() => {
    const maxEndDate = calculateMaxEndDate();
    if (endDate > maxEndDate && startDateChanged) {
      setStartDateChanged(false);
      setEndDate(maxEndDate);
      return;
    }
    const minStartDate = calculateMinStartDate();
    if (startDate < minStartDate && endDateChanged) {
      setEndDateChanged(false);
      setStartDate(minStartDate);
      return;
    }
    handleApplyFilter();
  }, [startDate, endDate, currency, selectedData]);

  const handleClearFilter = (): void => {
    setStartDateChanged(false);
    setStartDate(initialStartDate);
    setEndDateChanged(false);
    setEndDate(dateNow);
    setCurrency('GBP');
    setSelectedData(allDataTypes);
    history.push('/dashboard');
  };

  const setCheckedValue = (
    checked: any,
    dataType: DashboardDataType
  ): boolean => {
    let updated = selectedData || [];
    if (checked) {
      updated = Array.from(new Set(updated).add(dataType));
    } else {
      updated = updated.filter((item) => item != dataType);
    }
    setSelectedData(updated);
    return checked;
  };

  const calculateMaxEndDate = (): Date => {
    const endDateLimit = new Date(startDate);
    endDateLimit.setDate(startDate.getDate() + 60);
    return endDateLimit > dateNow ? dateNow : endDateLimit;
  };

  const calculateMinStartDate = (): Date => {
    const minStartDate = new Date(endDate);
    minStartDate.setDate(endDate.getDate() - 60);
    return minStartDate;
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Display
      </h2>
      <div>
        <label
          className='TextInput--label'
          style={{
            marginTop: '12px',
            marginBottom: '12px',
          }}
          htmlFor={'dataTypes'}
        >
          Data
        </label>
        {allDataTypes.map((dataType: DashboardDataType, index: number) => {
          return (
            <div key={index}>
              <CheckBox
                value={new Set(selectedData).has(dataType)}
                id={`${index}`}
                key={`${index}`}
                label={dataType}
                setValue={(v): any => setCheckedValue(v, dataType)}
              ></CheckBox>
            </div>
          );
        })}
      </div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <label
        className='TextInput--label'
        style={{
          marginTop: '-2px',
          marginBottom: '12px',
          fontStyle: 'italic',
        }}
      >
        Your end date can be up to 60 days after the chosen start date.
      </label>
      <DatePicker
        type='START'
        name='Start Date'
        value={dateToStartOfDayEpochSeconds(startDate)}
        notAfter={endDate}
        label
        setValue={(e): void => {
          if (e && typeof e === 'number') {
            setEndDateChanged(false);
            setStartDateChanged(true);
            setStartDate(epochSecondsToDate(e));
          }
        }}
      />
      <DatePicker
        label
        type='END'
        name='End Date'
        value={dateToStartOfDayEpochSeconds(endDate)}
        notBefore={startDate}
        notAfter={new Date()}
        setValue={(e): void => {
          if (e && typeof e === 'number') {
            setStartDateChanged(false);
            setEndDateChanged(true);
            setEndDate(epochSecondsToDate(e));
          }
        }}
      />
      <SelectInput
        label={true}
        name='Currency'
        value={currency}
        setValue={setCurrency}
        selectPrompt='Select currency'
        options={availableCurrencies.map((v: string) => ({
          value: v,
          name: v,
        }))}
      />
      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};
export { FilterDashboard };
