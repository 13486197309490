import React, { useState, useEffect, useRef } from 'react';
import { FileCategory, useFileUpload } from '../../hooks/useFileUpload';

interface FileUploadProps {
  category: FileCategory;
  onFileUploaded?: (file: File) => void;
  minFileSizeBytes?: number;
  maxFileSizeBytes?: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
  category,
  onFileUploaded,
  minFileSizeBytes = 10240,
  maxFileSizeBytes = 2097152,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const { uploadStatus, uploadFile, uploadError } = useFileUpload(
    category,
    minFileSizeBytes,
    maxFileSizeBytes
  );
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [displayMessage, setDisplayMessage] = useState('Select a file');

  useEffect(() => {
    function handleOnline(): void {
      setIsOnline(true);
    }

    function handleOffline(): void {
      setIsOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return (): void => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (uploadStatus.status === 'completed') {
      setDisplayMessage('Upload completed!');
      const timerId = setTimeout(() => {
        setDisplayMessage('Select a file');
      }, 3000);

      return (): void => {
        clearTimeout(timerId);
      };
    } else if (uploadStatus.status === 'uploading') {
      setDisplayMessage('Uploading...');
    } else if (uploadStatus.status === 'error') {
      setDisplayMessage('Error');
    }
  }, [uploadStatus.status]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (
        (minFileSizeBytes && selectedFile.size < minFileSizeBytes) ||
        (maxFileSizeBytes && selectedFile.size > maxFileSizeBytes)
      ) {
        setDisplayMessage(
          `File size should be between ${minFileSizeBytes / 1024} KB and ${
            maxFileSizeBytes / 1024
          } KB.`
        );
        return;
      }

      uploadFile(selectedFile);
      if (onFileUploaded) onFileUploaded(selectedFile);
    }

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  return (
    <div className='FileUpload'>
      <label htmlFor='fileUpload' className='upload-button'></label>
      <input
        id='fileUpload'
        type='file'
        onChange={handleFileChange}
        accept='.jpg, .jpeg, .png, .pdf'
        ref={inputFileRef}
      />
      <div className='status-container'>
        {isOnline ? (
          <>
            <div>{displayMessage}</div>
            {uploadStatus.status === 'uploading' && (
              <div>
                <div>
                  Size: {(uploadStatus.uploadedBytes / 1024).toFixed(2)} KB /{' '}
                  {(uploadStatus.totalBytes / 1024).toFixed(2)} KB
                </div>
                <div>Percentage: {uploadStatus.percentage.toFixed(2)}%</div>
                <div className='progress-container'>
                  <div
                    className='progress-bar'
                    style={{ width: `${uploadStatus.percentage}%` }}
                  ></div>
                </div>
              </div>
            )}
            {uploadStatus.status === 'error' && (
              <div style={{ color: 'red' }}>{uploadError}</div>
            )}
          </>
        ) : (
          <div style={{ color: 'red' }}>
            You have lost your internet connection. Please reconnect and try the
            upload again.
          </div>
        )}
      </div>
    </div>
  );
};

export { FileUpload };
