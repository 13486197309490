import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { request } from '../../functions/callApi';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import { Heading } from '../layouts/Heading';
import { TextInput } from '../forms/TextInput';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalState } from '../../hooks/useGlobalState';

export interface PropsShape {
  id?: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setApplePayWebDomainsChange?: Dispatch<SetStateAction<boolean>>;
}

const ApplePayWebDomainsDelete = ({
  id,
  setIsOpen,
  setApplePayWebDomainsChange,
}: PropsShape): ReactElement => {
  const history = useHistory();
  const { profile } = useGlobalState();
  const params: any = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [confirmDelete, setConfirmDelete] = useState<string>('');

  useEffect(() => {
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/settings');
    }
  }, [profile]);

  const closeModal = (): void => {
    setIsOpen ? setIsOpen(false) : history.push('/developer/apple-pay');
  };

  const deleteDomain = async (): Promise<void> => {
    if (params.id !== undefined || id !== undefined) {
      const apiUrl = `/v1/developer/apple-pay/web-domains/${params.id || id}`;
      try {
        setServerError('');
        setLoading(true);
        const response = await request<any>(apiUrl, 'DELETE');
        setLoading(false);
        if (response.status === 200) {
          setApplePayWebDomainsChange?.(true);
          closeModal();
        } else {
          setServerError(response.message);
        }
      } catch (err: any) {
        setLoading(false);
        setServerError(err.message);
      }
    }
  };

  return (
    <div>
      <div className='ApplePayWebDomains--modal-header'>
        <Heading
          title='Delete Web Domain'
          subtitle='Please ensure you are no longer processing payments via this web domain before proceeding.'
          warning={
            <b className='ApplePayWebDomains--modal-warning'>
              This action cannot be undone
            </b>
          }
          showWarning
        />
      </div>
      {serverError && (
        <div className='ApplePayWebDomains--modal-body'>
          <div />
          <ServerError error={serverError} />
        </div>
      )}
      <div className='ApplePayWebDomains--modal-body-input'>
        <TextInput
          label
          name="Type 'delete' below to confirm"
          value={confirmDelete}
          setValue={setConfirmDelete}
          focus
        />
      </div>

      <div className='ApplePayWebDomains--modal-footer'>
        <Button name='Cancel' color='GREY' click={(): void => closeModal()} />
        <Button
          name='Delete'
          click={deleteDomain}
          loading={loading}
          disabled={confirmDelete.toLowerCase() !== 'delete'}
        />
      </div>
    </div>
  );
};
export { ApplePayWebDomainsDelete };
