import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { TextArea } from '../forms/TextArea';
import { Button } from '../forms/Button';
import { SelectInput } from '../forms/SelectInput';
import { ServerError } from '../forms/ServerError';
import { SuccessMessage } from '../forms/SuccessMessage';
import { get } from '../../functions/callApi';

const Ticket = (): ReactElement => {
  const [serverError, setServerError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [subjectError, setSubjectError] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [categoryError, setCategoryError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (
      category &&
      subject &&
      message &&
      !subjectError &&
      !categoryError &&
      !messageError
    ) {
      setBtnDisabled(false);
    }
  }, [category, subject, message]);

  const callApi = async (): Promise<void> => {
    setServerError('');
    setSuccessMessage('');
    setSubjectError('');
    setCategoryError('');
    setMessageError('');
    setIsLoading(true);

    const getBody = (): string => {
      return JSON.stringify({
        subject: subject,
        category: category,
        body: message,
      });
    };
    try {
      const response = await get('/v1/support-tickets', 'POST', getBody());
      setIsLoading(false);
      if (response.status === 200) {
        setSuccessMessage(
          'Thank you for submitting your request, we will be in touch with you soon'
        );
        setCategory('');
        setSubject('');
        setMessage('');
      } else {
        setServerError(response.message);
      }
    } catch (err) {
      setServerError(
        'An unexpected error occured whilst processing your request, please try again'
      );
    }
  };

  return (
    <div className='Ticket'>
      <ServerError error={serverError} />
      <SuccessMessage message={successMessage} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          callApi();
        }}
      >
        <SelectInput
          label
          name='Enquiry Category'
          value={category}
          setValue={setCategory}
          error={categoryError}
          options={[
            { value: 'JustSaying', name: 'Just want to say...' },
            { value: 'NewFeature', name: 'New Feature Suggestion' },
            { value: 'EmailChange', name: 'Email Change Request' },
            { value: 'BankDetails', name: 'Bank Details Issue' },
            { value: 'PayoutIssue', name: 'Where is my money' },
            {
              value: 'Capability',
              name: 'I want to request additional functionality',
            },
            {
              value: 'Disputes',
              name: 'I need help with one or more disputes',
            },
          ]}
        />
        <TextInput
          name='Subject Title'
          value={subject}
          setValue={setSubject}
          error={subjectError}
          label
          placeHolder='Describe your enquiry'
        />

        <TextArea
          name='In your own words'
          value={message}
          setValue={setMessage}
          error={messageError}
          label
        />

        <Button
          name='Open Ticket'
          type={'submit'}
          loading={isLoading}
          disabled={btnDisabled}
        />
      </form>
    </div>
  );
};
export { Ticket };
