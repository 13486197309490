import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { Dispute, DisputeFilterShape } from '../interfaces/disputeState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';

interface ApiResponse {
  items: Dispute[];
  paginationToken: string;
}

const useGetDisputes = (): [
  boolean,
  string | undefined,
  (filter?: DisputeFilterShape) => void,
  boolean,
  () => void
] => {
  const defaultPageSize: number = 25;
  const defaultAscending: boolean = false;
  const { setDisputeState } = useGlobalDataState();
  const [isMore, setIsMore] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] =
    useApiRequest<ApiResponse>();

  const getDisputes = (filter?: DisputeFilterShape): void => {
    let url = `/v1/disputes?pageSize=${
      filter?.pageSize ?? defaultPageSize
    }&ascending=${
      filter?.ascending !== undefined ? filter.ascending : defaultAscending
    }`;

    if (filter?.startTimestamp || filter?.endTimestamp) {
      url += `&startTimestamp=${filter.startTimestamp || 0}&endTimestamp=${
        filter.endTimestamp || dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter?.paymentSessionId) {
      url += `&paymentSessionId=${filter.paymentSessionId}`;
    }

    if (filter?.startsAfter) {
      url += `&startsAfter=${filter.startsAfter}`;
    }

    if (filter?.status) {
      url += `&status=${filter.status}`;
    }

    if (filter?.disputeId) {
      url += `&disputeId=${filter.disputeId}`;
    }
    setDisputeState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        filter: filter,
      },
    }));
    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('savedUrl', url);
  };

  const getMore = (): any => {
    const savedUrl = localStorage.getItem('savedUrl');
    const paginationToken = localStorage.getItem('paginationToken');
    if (savedUrl && paginationToken) {
      sendRequest({
        url: `${savedUrl}&disputesStartAfter=${paginationToken}`,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    if (response) {
      localStorage.setItem(
        'paginationToken',
        response.data?.paginationToken || ''
      );
      setIsMore(!!response.data.paginationToken);
      const items = response.data.items;
      setDisputeState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: response.data.paginationToken,
          items: items,
        },
      }));
    }
  }, [response]);

  return [isLoading, error, getDisputes, isMore, getMore];
};

export { useGetDisputes };
