import { ReactElement, useState, FormEvent, useEffect } from 'react';
import { TextInput } from '../forms/TextInput';
import { Button } from '../forms/Button';
import { ServerError } from '../forms/ServerError';
import {
  isEmail,
  isNNumbers,
  isPassword,
  isPasswordNonAsync,
  isEmailNonAsync,
  isNNumbersNonAsync,
} from '../../functions/validators';
import { useGlobalState } from '../../hooks/useGlobalState';
import { Password } from '../forms/Password';
import { StepPosition } from '../forms/StepPosition';
import { useHistory } from 'react-router-dom';

const SubmitPassword = (): ReactElement => {
  const history = useHistory();
  const { cognito } = useGlobalState();
  const [email, setEmail] = useState<string>(
    localStorage.getItem('email') || ''
  );
  const [emailError, setEmailError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    setServerError(cognito.error);
  }, [cognito.error]);

  useEffect(() => {
    const allValidInputsProvided =
      isPasswordNonAsync(password) &&
      isEmailNonAsync(email) &&
      isNNumbersNonAsync(code, 6);
    setBtnDisabled(!allValidInputsProvided);
  }, [email, code, password]);

  const submit = async (): Promise<void> => {
    setEmailError('');
    setServerError('');
    setCodeError('');
    setPasswordError('');
    const isValidEmail = await isEmail(email);
    const isValidCode = await isNNumbers(code, 6);
    const isValidPassword = await isPassword(password);
    if (isValidEmail && isValidCode && isValidPassword) {
      setIsLoading(true);
      const result = await cognito.setNewPassword(email, code, password);
      setIsLoading(false);
      if (result) {
        history.push(`/`);
      }
    }
  };
  return (
    <div className='ForgotPassword'>
      <ServerError error={serverError} />
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
          submit();
        }}
      >
        <StepPosition steps={2} position={2} />
        <TextInput
          name='Email'
          value={email}
          setValue={setEmail}
          error={emailError}
          placeHolder='Email address'
        />
        <TextInput
          name='Code'
          value={code}
          setValue={setCode}
          error={codeError}
          placeHolder='6 digit code we emailed you'
          type='number'
        />
        <Password
          name='New Password'
          value={password}
          setValue={setPassword}
          error={passwordError}
          placeHolder='New password'
          strong
        />
        <Button
          name='Set New Password'
          click={submit}
          loading={isLoading}
          disabled={btnDisabled}
        />
      </form>
      <div
        onClick={(): void => history.push(`/`)}
        className='ForgotPassword--center'
      >
        Remembered your password? <a>Sign In</a>
      </div>
    </div>
  );
};
export { SubmitPassword };
