import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface AddUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

function useAddUser(): any {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setIsComplete(true);
    }
  }, [response]);

  const addUser = (body: AddUserRequest): void => {
    const url = `/v1/users`;
    const method = HttpMethod.POST;
    sendRequest({ url, method, body });
  };

  return [addUser, isLoading, isComplete, error];
}

export { useAddUser };
