import React, { useEffect, useState } from 'react';
import { useGetCustomerById } from '../../hooks/useGetCustomerById';
import InlineMenu from '../layouts/InlineMenu';
import { DateTime } from '../layouts/DateTime';
import { Copy } from '../forms/Copy';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { epochSecondsToDate } from '../../functions/helpers';

interface CustomerTabData {
  email?: string;
  firstName?: string;
  lastName?: string;
  homePhoneNumber?: string;
  mobilePhoneNumber?: string;
  id?: string;
  createdTimestamp?: number;
}

interface CustomerProps {
  id?: string;
  data?: CustomerTabData;
}

interface RenderedData {
  customer: CustomerTabData | undefined;
}

const Customer: React.FC<CustomerProps> = ({ id, data }) => {
  const [renderedData, setRenderedData] = useState<RenderedData>({
    customer: data,
  });
  const { customer, isLoading, error, fetchCustomer } = useGetCustomerById();

  useEffect(() => {
    if (id) {
      fetchCustomer(id);
    }
  }, [id]);

  useEffect(() => {
    if (customer && customer.id === id) {
      setRenderedData({
        customer: {
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
          homePhoneNumber: customer.homePhoneNumber,
          mobilePhoneNumber: customer.mobilePhoneNumber,
          createdTimestamp: customer.createdTimestamp,
          id: customer.id,
        },
      });
    } else {
      setRenderedData({ customer: data });
    }
  }, [customer, data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error || !renderedData) {
    return <div>{error || 'Failed to get customer details'}</div>;
  }
  if (!renderedData.customer) {
    return <div>{error || 'No customer'}</div>;
  }

  return (
    <div className='CustomerSharedTab'>
      <div className='CustomerSharedTab--header'>
        <h2>{renderedData.customer.email}</h2>
        {renderedData.customer.id && (
          <InlineMenu
            items={[
              {
                label: 'View Customer',
                url: `/customers?id=${renderedData.customer.id}`,
              },
              {
                label: 'View Payments',
                url: `/payments?customerId=${renderedData.customer.id}`,
              },
            ]}
          />
        )}
      </div>
      <div className='CustomerSharedTab--middle'>
        {renderedData.customer.createdTimestamp && (
          <div className='CustomerSharedTab--middle-item'>
            <div>Joined</div>
            <div>
              <DateTime
                showTime
                value={epochSecondsToDate(
                  renderedData.customer.createdTimestamp
                )}
              />
            </div>
          </div>
        )}
        <div className='PaymentsSharedTab--middle-item'>
          <>
            <div>Name</div>
            {renderedData.customer.firstName ? (
              <div>
                {renderedData.customer.firstName}{' '}
                {renderedData.customer.lastName}
              </div>
            ) : (
              <div>Not provided</div>
            )}
          </>
        </div>
        {renderedData.customer.homePhoneNumber && (
          <div className='PaymentsSharedTab--middle-item'>
            <>
              <div>Home phone number</div>
              <div>
                {formatPhoneNumberIntl(renderedData.customer.homePhoneNumber)}
              </div>
            </>
          </div>
        )}
        {renderedData.customer.mobilePhoneNumber && (
          <div className='PaymentsSharedTab--middle-item'>
            <>
              <div>Mobile phone number</div>
              <div>
                {formatPhoneNumberIntl(renderedData.customer.mobilePhoneNumber)}
              </div>
            </>
          </div>
        )}
      </div>
      {renderedData.customer.id && (
        <div className='CustomerSharedTab--footer'>
          <div>Id</div>
          <div>
            <Copy
              type='BUTTON_WITH_TEXT'
              buttonSize='MEDIUM'
              value={renderedData.customer.id}
              text={renderedData.customer.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Customer;
