import { ReactElement } from 'react';
import { epochSecondsToDate, formatPrice } from '../../functions/helpers';
import { DateTime } from './DateTime';
import { PlatformFeesShape } from '../../interfaces/feesState';
import { Copy } from '../forms/Copy';

export interface PropsShape {
  data: PlatformFeesShape;
}

const PlatformFeeDetailLayout = ({ data }: PropsShape): ReactElement => {
  return (
    <div className='PlatformFeeDetailLayout'>
      <div className='PlatformFeeDetailLayout--title'>
        <div className='PlatformFeeDetailLayout--title-desc'>Fee Details</div>
        <div className='PlatformFeeDetailLayout--title-id'>
          <Copy type='BUTTON_WITH_TEXT' text={data?.id} value={data?.id} />
        </div>
      </div>
      <div className='PlatformFeeDetailLayout--container'>
        <div className='PlatformFeeDetailLayout--container-pair'>
          <div className='PlatformFeeDetailLayout--container-pair-key'>
            When:
          </div>
          <div className='PlatformFeeDetailLayout--container-pair-val'>
            <DateTime
              showTime
              value={epochSecondsToDate(data.createdTimestamp)}
            />
          </div>
        </div>
      </div>
      <div className='PlatformFeeDetailLayout--title'>
        <div className='PlatformFeeDetailLayout--title-desc'>
          Fee Calculation
        </div>
        <div className='PlatformFeeDetailLayout--title-id'></div>
      </div>
      <div className='PlatformFeeDetailLayout--container PlatformFeeDetailLayout--container-title-bottom'>
        <div>
          <div className='PlatformFeeDetailLayout--container-pair'>
            <div className='PlatformFeeDetailLayout--container-pair-key'>
              Platform:
            </div>
            <div className='PlatformFeeDetailLayout--container-pair-val'>
              {formatPrice({
                pence: data.platformFeeAmount || 0,
                currency: data.currency,
              })}
            </div>
          </div>
        </div>
        <div className='PlatformFeeDetailLayout--arrow-bottom' />
      </div>
      <div className='PlatformFeeDetailLayout--container'>
        <div className='PlatformFeeDetailLayout--container-pair'>
          <div className='PlatformFeeDetailLayout--container-pair-key'>
            Processing:
          </div>
          <div className='PlatformFeeDetailLayout--container-pair-val'>
            {formatPrice({
              pence: data.processingFeeAmount || 0,
              currency: data.currency,
            })}
          </div>
        </div>

        <div className='PlatformFeeDetailLayout--container-pair'>
          <div className='PlatformFeeDetailLayout--container-pair-key'>
            Refunds:
          </div>
          <div className='PlatformFeeDetailLayout--container-pair-val'>
            {formatPrice({
              pence: data.platformFeeRefundedAmount || 0,
              currency: data.currency,
            })}
          </div>
        </div>
      </div>
      <div className='PlatformFeeDetailLayout--container PlatformFeeDetailLayout--container-title-top'>
        <div>
          <div className='PlatformFeeDetailLayout--container-pair'>
            <div className='PlatformFeeDetailLayout--container-pair-key'>
              Net:
            </div>
            <div className='PlatformFeeDetailLayout--container-pair-val'>
              {formatPrice({
                pence: data.netAmount || 0,
                currency: data.currency,
              })}
            </div>
          </div>
        </div>
        <div className='PlatformFeeDetailLayout--arrow-top' />
      </div>
    </div>
  );
};
export { PlatformFeeDetailLayout };
