import { ReactElement, useEffect, useState } from 'react';
import {
  epochSecondsToDate,
  formatPrice,
  isMobile,
} from '../../functions/helpers';
import { Status, StatusShape } from '../layouts/Status';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { SplitPaymentDetail } from './SplitPaymentDetail';
import { useHistory } from 'react-router-dom';
import { SplitPaymentsShape } from '../../interfaces/splitPaymentState';

interface PaymentsProps {
  splitPayments: SplitPaymentsShape[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  error?: string | undefined;
}

const SplitPayments = ({
  splitPayments,
  isMore,
  getMore,
  isLoading,
  error,
}: PaymentsProps): ReactElement => {
  const history = useHistory();
  const [selectedSplitPayment, setSelectedSplitPayment] =
    useState<SplitPaymentsShape | null>(null);

  const scrollToSelectedItem = (): void => {
    if (selectedSplitPayment) {
      const selectedItemElement = document.getElementById(
        `split-payment-${selectedSplitPayment.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = splitPayments.findIndex(
      (splitPayment) => splitPayment.id === selectedSplitPayment?.id
    );
    if (currentIndex > 0) {
      handleSelectedSplitPayment(splitPayments[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = splitPayments.findIndex(
      (splitPayment) => splitPayment.id === selectedSplitPayment?.id
    );
    if (currentIndex < splitPayments.length - 1) {
      handleSelectedSplitPayment(splitPayments[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedSplitPayment) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSplitPayment]);

  const setSelected = (id: string): void => {
    const splitPayment = splitPayments.find((payment) => payment.id === id);
    setSelectedSplitPayment(splitPayment || null);
  };

  const handleCloseSideMenu = (): void => {
    setSelectedSplitPayment(null);
    history.push(`/split-payments`);
  };

  const handleSelectedSplitPayment = (id: string): void => {
    if (isMobile()) {
      history.push(`/split-payments/${id}`);
      return;
    }
    setSelected(id);
    history.push(`/split-payments?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedSplitPayment(null);
    }
  }, [location.search, splitPayments]);

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='SplitPayments'>
      <div className='SplitPayments--heading'>
        <div>Status</div>
        <div>Gross</div>
        <div>Net</div>
        <div className='SplitPayments--heading-mobile'>Email</div>
        <div>When</div>
      </div>
      <div className='SplitPayments--scroll' id='scrollArea'>
        {splitPayments.map((splitPayment) => (
          <div
            id={`split-payment-${splitPayment.id}`}
            key={splitPayment.id}
            onClick={(): void => handleSelectedSplitPayment(splitPayment.id)}
            className={
              splitPayment.id === selectedSplitPayment?.id
                ? 'SplitPayments--item SplitPayments--selected'
                : 'SplitPayments--item'
            }
          >
            <div className='SplitPayments--status'>
              <Status
                status={splitPayment.status?.toUpperCase() as StatusShape}
              />
            </div>
            <div className='SplitPayments--txt'>
              {formatPrice({
                pence: +splitPayment.totalAmount,
                currency: splitPayment.currency,
              })}
            </div>
            <div className='SplitPayments--txt'>
              {formatPrice({
                pence: +splitPayment.netAmount,
                currency: splitPayment.currency,
              })}
            </div>
            <div className='SplitPayments--txt SplitPayments--txt-mobile'>
              {splitPayment.paymentSessionDetail.customerEmail || ''}
            </div>
            <div className='SplitPayments--txt'>
              <DateTime
                showTime
                value={epochSecondsToDate(splitPayment.createdTimestamp)}
              />
            </div>
          </div>
        ))}
        {isLoading && !error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
        )}
        {!isLoading && error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
        )}
      </div>
      <SideMenu
        isOpen={!!selectedSplitPayment}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <SplitPaymentDetail
          splitPayment={selectedSplitPayment}
          hasPrevious={
            splitPayments.findIndex(
              (payment) => payment.id === selectedSplitPayment?.id
            ) > 0
          }
          handlePrevious={handleBack}
          hasNext={
            splitPayments.findIndex(
              (payment) => payment.id === selectedSplitPayment?.id
            ) <
            splitPayments.length - 1
          }
          handleNext={handleNext}
        />
      </SideMenu>
    </div>
  );
};
export { SplitPayments };
