import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { SelectInput } from './SelectInput';

export interface PropsShape {
  label?: boolean;
  name?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  description?: string;
  error?: string;
  minAge?: number;
  optional?: boolean;
}

const DateOfBirth = ({
  label = false,
  name = 'DateOfBirth',
  value,
  setValue,
  description,
  error,
  minAge = 18,
  optional = false,
}: PropsShape): ReactElement => {
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');

  // TODO: add use memo to gen days and genYears
  const genDays = (start = 0, end = 31): any => {
    const daysArr: { value: string; name: string }[] = [];
    // eslint-disable-next-line no-loops/no-loops
    while (start < end) {
      const count = (start += 1);
      const name = `${count}`;
      let value: string = '';
      if (count < 10) value = `0${name}`;
      else value = name;
      daysArr.push({ value, name });
    }
    return daysArr;
  };

  const genYears = (): any => {
    const d = new Date();
    const YearNow = d.getFullYear();
    const firstYear = YearNow - minAge;
    let lastYear = firstYear - 100;
    const yearsArr: { value: string; name: string }[] = [];
    // eslint-disable-next-line no-loops/no-loops
    while (lastYear < firstYear) {
      const value = `${(lastYear += 1)}`;
      yearsArr.push({ value, name: value });
    }
    const res = yearsArr.reverse();
    return res;
  };

  useEffect(() => {
    if (year && month && day) setValue(`${year}-${month}-${day}`);
  }, [day, month, year]);

  useEffect(() => {
    const dateVal = value.split('-');
    setYear(dateVal[0] || '');
    setMonth(dateVal[1] || '');
    setDay(dateVal[2] || '');
  }, [value]);

  return (
    <div className='DateOfBirth'>
      {optional && <div className='DateOfBirth--optional'>Optional</div>}
      {label && <label className='DateOfBirth--label'>{name}</label>}
      {error && <div className='DateOfBirth--error'>{error}</div>}
      <div className='DateOfBirth--inputs'>
        <div style={{ minWidth: '85px' }}>
          <SelectInput
            name='year'
            value={year}
            setValue={setYear}
            options={genYears()}
            selectPrompt='Year...'
          />
        </div>
        <SelectInput
          name='month'
          value={month}
          setValue={setMonth}
          options={[
            { value: '01', name: 'January' },
            { value: '02', name: 'Febuary' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' },
          ]}
          selectPrompt='Month...'
        />
        <SelectInput
          name='year'
          value={day}
          setValue={setDay}
          options={genDays()}
          selectPrompt='Day...'
        />
      </div>
      {description && (
        <div className='DateOfBirth--description'>{description}</div>
      )}
    </div>
  );
};
export { DateOfBirth };
