import React, { useState, useRef, useEffect } from 'react';

interface MenuItem {
  label: string;
  url: string;
}

interface InlineMenuProps {
  items: MenuItem[];
}

const InlineMenu: React.FC<InlineMenuProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleClickOff = (event: MouseEvent): void => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      if (event.target !== buttonRef.current) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOff);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOff);
    };
  }, []);

  return (
    <div className='inlineMenu'>
      <button ref={buttonRef} onClick={(): void => setIsOpen(!isOpen)} />

      {isOpen && (
        <div className='menu' ref={menuRef}>
          {items.map((item, index) => (
            <a
              key={index}
              href={item.url}
              onClick={(): void => setIsOpen(false)}
            >
              {item.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default InlineMenu;
