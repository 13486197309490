import { ReactElement } from 'react';
import { CardWalletShape } from '../../interfaces/state';
import googlePayIcon from '../../images/googlepay.svg';
import applePayIcon from '../../images/applepay.svg';

export interface PropsShape {
  value: CardWalletShape;
}

const PaymentMethodWallet = ({ value }: PropsShape): ReactElement => {
  const getWalletTypeIcon = (wallet: CardWalletShape): any => {
    switch (wallet.type) {
      case 'GooglePay':
        return googlePayIcon;
      case 'ApplePay':
        return applePayIcon;
    }
  };

  return (
    <div className='PaymentMethodWallet'>
      {value && (
        <div className='PaymentMethodWallet--card'>
          <img src={getWalletTypeIcon(value)}></img>
        </div>
      )}
    </div>
  );
};
export { PaymentMethodWallet };
