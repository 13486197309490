import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { useGlobalDataState } from './useGlobalDataState';
import { dateToEndOfDayEpochSeconds } from '../functions/helpers';
import {
  PaymentSessionSettings,
  PaymentsShape,
} from '../interfaces/paymentState';
import { PlatformFeesShape } from '../interfaces/feesState';

export interface TransactionsFilter {
  pageSize: number;
  ascending: boolean;
  paymentSessionsStartAfter: string;
  paymentSessionsStartTimestamp: string;
  paymentSessionsEndTimestamp: string;
  platformFeesStartAfter: string;
  platformFeesStartTimestamp: string;
  platformFeesEndTimestamp: string;
  paymentId: string;
  platformFeeId: string;
  accountId: string;
  customerId: string;
}

interface ApiResponse {
  paymentSessions: PaymentSessionsApiResponse;
  paymentSessionsError: boolean;
  paymentSessionSettings: PaymentSessionSettings;
  platformFees: PlatformFeesApiResponse;
  platformFeesError: boolean;
}

interface PaymentSessionsApiResponse {
  items: PaymentsShape[];
  paginationToken: string;
}

interface PlatformFeesApiResponse {
  items: PlatformFeesShape[];
  paginationToken: string;
}

const useGetTransactions = (): [
  boolean,
  string | undefined,
  string | undefined,
  (filter: TransactionsFilter) => void,
  boolean,
  boolean,
  () => void
] => {
  const { paymentsState, setPaymentsState, feesState, setFeesState } =
    useGlobalDataState();

  const [isMorePayments, setIsMorePayments] = useState<boolean>(false);
  const [isMorePlatformFees, setIsMorePlatformFees] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [paymentsError, setPaymentsError] = useState<string | undefined>();
  const [platformFeesError, setPlatformFeesError] = useState<
    string | undefined
  >();
  const [response, isLoading, apiError, sendRequest] =
    useApiRequest<ApiResponse>();

  const setFilter = (filter: TransactionsFilter): void => {
    setAction('applyFilter');
    let url = '/v1/transactions';

    if (filter.customerId) {
      url += `?customerId=${filter.customerId}`;
    }

    if (
      filter.paymentSessionsStartTimestamp ||
      filter.paymentSessionsEndTimestamp
    ) {
      url += `${url.includes('?') ? '&' : '?'}startTimestampPaymentSessions=${
        filter.paymentSessionsStartTimestamp || 0
      }&endTimestampPaymentSessions=${
        filter.paymentSessionsEndTimestamp ||
        dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter.platformFeesStartTimestamp || filter.platformFeesEndTimestamp) {
      url += `${url.includes('?') ? '&' : '?'}startTimestampPlatformFees=${
        filter.platformFeesStartTimestamp || 0
      }&endTimestampPlatformFees=${
        filter.platformFeesEndTimestamp ||
        dateToEndOfDayEpochSeconds(new Date())
      }`;
    }

    if (filter.pageSize) {
      url += `${url.includes('?') ? '&' : '?'}pageSize=${filter.pageSize}`;
    }

    if (filter.ascending !== undefined) {
      url += `${url.includes('?') ? '&' : '?'}ascending=${filter.ascending}`;
    }

    if (filter.paymentId !== '') {
      url += `${url.includes('?') ? '&' : '?'}paymentSessionId=${
        filter.paymentId
      }`;
    }

    if (filter.platformFeeId !== '') {
      url += `${url.includes('?') ? '&' : '?'}platformFeeId=${
        filter.platformFeeId
      }`;
    }

    if (filter.accountId !== '') {
      url += `${url.includes('?') ? '&' : '?'}accountId=${filter.accountId}`;
    }

    sendRequest({ url, method: HttpMethod.GET });
    localStorage.setItem('lastTransactionsUrl', url);
  };

  const getMore = (): any => {
    setAction('getMore');
    const lastUrl = localStorage.getItem('lastTransactionsUrl');
    const lastPaymentsPaginationToken = localStorage.getItem(
      'lastPaymentsPaginationToken'
    );
    const lastPlatformFeesPaginationToken = localStorage.getItem(
      'lastPlatformFeesPaginationToken'
    );
    if (
      lastUrl &&
      (lastPaymentsPaginationToken || lastPlatformFeesPaginationToken)
    ) {
      let url = lastUrl;
      if (lastPaymentsPaginationToken) {
        url += `&paymentSessionsStartAfter=${lastPaymentsPaginationToken}`;
      }
      if (lastPlatformFeesPaginationToken) {
        url += `&platformFeesStartAfter=${lastPlatformFeesPaginationToken}`;
      }
      sendRequest({
        url: url,
        method: HttpMethod.GET,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (response && isMounted) {
      const paymentsPaginationToken =
        response.data.paymentSessions.paginationToken;
      const platformFeesPaginationToken =
        response.data.platformFees.paginationToken;
      localStorage.setItem(
        'lastPaymentsPaginationToken',
        paymentsPaginationToken || ''
      );
      localStorage.setItem(
        'lastPlatformFeesPaginationToken',
        platformFeesPaginationToken || ''
      );
      setIsMorePayments(!!paymentsPaginationToken);
      setIsMorePlatformFees(!!platformFeesPaginationToken);
      let updatedPayments = response.data.paymentSessions.items;
      let updatedPlatformFees = response.data.platformFees.items;
      if (action === 'getMore' && paymentsState.data?.items) {
        updatedPayments = paymentsState.data.items.concat(updatedPayments);
      }
      if (action === 'getMore' && feesState.data?.items) {
        updatedPlatformFees = feesState.data.items.concat(updatedPlatformFees);
      }
      setPaymentsError(
        response.data.paymentSessionsError
          ? 'An unexpected error occurred, please try again or contact us if it persists'
          : undefined
      );
      setPlatformFeesError(
        response.data.platformFeesError
          ? 'An unexpected error occurred, please try again or contact us if it persists'
          : undefined
      );
      setPaymentsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          settings: response.data.paymentSessionSettings,
          paginationToken: paymentsPaginationToken,
          items: updatedPayments,
        },
      }));
      setFeesState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: platformFeesPaginationToken,
          items: updatedPlatformFees,
        },
      }));
    }
    return (): void => {
      isMounted = false;
    };
  }, [response]);

  useEffect(() => {
    setPaymentsError(apiError);
    setPlatformFeesError(apiError);
  }, [apiError]);

  return [
    isLoading,
    paymentsError,
    platformFeesError,
    setFilter,
    isMorePayments,
    isMorePlatformFees,
    getMore,
  ];
};

export { useGetTransactions };
