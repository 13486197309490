import React, { useState, useEffect } from 'react';
import { Currency } from '../../interfaces/state';

interface CurrencyInputProps {
  currency?: Currency;
  pence?: number | string;
  setPence: (pence: number) => void;
  name?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  currency,
  pence = 0,
  setPence,
  name = 'Amount',
}) => {
  const [amount, setAmount] = useState<string>('');

  const formatAmount = (amount: string, minorDigits: number): string => {
    const numericAmount = parseFloat(amount) || 0;
    const formattedAmount = numericAmount.toFixed(minorDigits);

    return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleBlur = (): void => {
    if (currency) {
      setAmount(formatAmount(amount, currency.minorDigits));
      setPence(Math.round(+amount * Math.pow(10, currency.minorDigits)));
    }
  };

  useEffect(() => {
    if (currency && pence !== undefined) {
      setAmount(
        (+pence / Math.pow(10, currency.minorDigits)).toFixed(
          currency.minorDigits
        )
      );
    } else {
      setAmount('');
    }
  }, [currency, pence]);

  const placeholder = currency
    ? Number(0).toFixed(currency.minorDigits)
    : '0.00';

  return (
    <div className='CurrencyInput'>
      <label htmlFor='Amount'>{name}</label>
      <div className='CurrencyInput--anchor'>
        <span className='currency-symbol'>{currency?.symbol}</span>
        <input
          type='text'
          id='Amount'
          name='Amount'
          value={amount}
          onChange={(e): void => setAmount(e.target.value)}
          onBlur={handleBlur}
          placeholder={placeholder}
          disabled={!currency}
          autoFocus={true}
        />
      </div>
    </div>
  );
};

export { CurrencyInput };
