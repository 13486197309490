import {
  AccountDocument,
  AccountDocumentCategory,
  AccountDocumentType,
  RequiredVerificationDocument,
  VerificationError,
} from '../interfaces/accountState';
import { camelCaseToWords, pascalCaseToWords, sum } from './helpers';
import { AccountDocumentRequest } from '../hooks/useEditAccount';
import { OptionShape } from '../interfaces/state';
import React, { ReactElement } from 'react';

export interface EditedDocument {
  type: string;
  front?: string;
  back?: string;
  country?: string;
}

const getCategoryName = (category: AccountDocumentCategory): string =>
  category.replace('ProofOf', '');

const getCategoryFriendlyName = (category: AccountDocumentCategory): string => {
  switch (category) {
    case 'ProofOfAddress':
      return 'Proof of Address';
    case 'ProofOfIdentity':
      return 'Proof of ID';
    case 'ProofOfBusiness':
      return 'Proof of Business Registration';
    default:
      return 'Letter of Authorization';
  }
};

const getRequiredDocumentCategories = (
  requiredDocuments?: RequiredVerificationDocument[] | null
): string[] =>
  requiredDocuments?.map((d: RequiredVerificationDocument) =>
    getCategoryName(d.category)
  ) ?? [];

const getExistingDocumentCategories = (
  documents?: AccountDocument[] | null
): string[] =>
  documents
    ?.filter((d: AccountDocument) => d.status === 'Invalid')
    ?.map((d: AccountDocument) => getCategoryName(d.category)) ?? [];

const getDocumentCategories = (
  requiredDocuments?: RequiredVerificationDocument[] | null,
  documents?: AccountDocument[] | null
): string[] =>
  getRequiredDocumentCategories(requiredDocuments).concat(
    getExistingDocumentCategories(documents)
  );

const calculateSelectedDocumentTypes = (
  availableDocumentTypes: OptionShape[],
  editedDocuments: EditedDocument[],
  selectedCategory?: string
): string[] => {
  if (availableDocumentTypes.length === 1) {
    return availableDocumentTypes.map((option: OptionShape) => option.value);
  }
  return editedDocuments
    .filter(
      (d: EditedDocument) =>
        getCategoryName(getCategoryForType(d.type)) == selectedCategory
    )
    .map((d: EditedDocument) => d.type);
};

const updateEditedDocuments = (
  editedDocuments: EditedDocument[],
  newEditedDocument: EditedDocument
): EditedDocument[] => {
  const newEditedDocuments = editedDocuments.filter(
    (d) => d.type !== newEditedDocument.type
  );
  const existingType = editedDocuments.find(
    (d) => d.type === newEditedDocument.type
  );
  newEditedDocuments.push({
    type: newEditedDocument.type,
    front: newEditedDocument.front ?? existingType?.front,
    back: newEditedDocument.back ?? existingType?.back,
    country: newEditedDocument.country ?? existingType?.country,
  });
  return newEditedDocuments;
};

const isEditedDocumentValid = (d?: EditedDocument): boolean =>
  !!d?.front &&
  (!documentTypeRequiresBack(d.type) || !!d.back) &&
  (!documentTypeRequiresCountry(d.type) || !!d.country);

const enableSubmitDocuments = (
  editedDocuments: EditedDocument[],
  completedDocumentCategories: string[],
  documentCategories: string[],
  requiredDocuments?: RequiredVerificationDocument[] | null
): boolean => {
  const requiredDocumentCategories =
    getRequiredDocumentCategories(requiredDocuments);
  const completedDocumentCategoriesSet = new Set(completedDocumentCategories);
  if (requiredDocumentCategories.length === 0) {
    return (
      completedDocumentCategoriesSet.size === documentCategories.length &&
      editedDocuments.length > 0
    );
  }
  return (
    completedDocumentCategoriesSet.size >= requiredDocumentCategories.length
  );
};

const calculateCompletedDocumentCategories = (
  editedDocuments: EditedDocument[]
): string[] => {
  return editedDocuments
    .filter((d: EditedDocument) => isEditedDocumentValid(d))
    .map((d: EditedDocument) => getCategoryName(getCategoryForType(d.type)));
};

const getInvalidDocumentError = (
  selectedCategory: string,
  documents?: AccountDocument[]
): string | undefined => {
  const invalidDocument = documents?.find(
    (d: AccountDocument) =>
      getCategoryName(d.category) === selectedCategory && d.status === 'Invalid'
  );
  return invalidDocument
    ? `The ${pascalCaseToWords(
        invalidDocument.type
      ).toLowerCase()} previously uploaded was invalid due to the following reason: ${
        invalidDocument.invalidReason
      }`
    : undefined;
};

const editedDocumentToDocumentRequest = (
  editedDocument: EditedDocument
): AccountDocumentRequest => {
  return {
    type: editedDocument.type,
    front: editedDocument.front!,
    back: editedDocument.back || undefined,
    country: editedDocument.country || undefined,
  };
};

const availableDocumentTypesForCategoryName = (
  categoryName: string
): AccountDocumentType[] => {
  switch (categoryName) {
    case 'Address':
      return [
        'BankStatement',
        'CreditCardStatement',
        'OfficialGovernmentLetter',
        'PropertyTaxAssessment',
        'TaxReturn',
        'UtilityBill',
      ];
    case 'Business':
      return ['ArticlesOfIncorporation', 'BusinessRegistration'];
    case 'Identity':
      return ['DriversLicense', 'NationalId', 'Passport'];
    default:
      return ['LetterOfAuthorization'];
  }
};

const getAvailableDocumentTypes = (categoryName: string): OptionShape[] =>
  availableDocumentTypesForCategoryName(categoryName).map(
    (d: AccountDocumentType) => {
      return { name: pascalCaseToWords(d), value: d };
    }
  );

const getSelectedDocumentTypeLimit = (
  categoryName: string,
  requiredDocuments?: RequiredVerificationDocument[],
  documents?: AccountDocument[],
  editedDocuments?: EditedDocument[]
): number | undefined => {
  const requiredDocumentsForCategory = requiredDocuments?.find(
    (d) => categoryName === getCategoryName(d.category)
  );
  if (requiredDocumentsForCategory) {
    return requiredDocumentsForCategory.quantity;
  }
  const uneditedInvalidDocuments =
    documents?.filter(
      (d) =>
        d.status === 'Invalid' &&
        getCategoryName(d.category) === categoryName &&
        !editedDocuments?.some((ed) => ed.type === d.type)
    ) ?? [];
  return 1 + uneditedInvalidDocuments.length;
};

const getCategoryForType = (type: string): AccountDocumentCategory => {
  switch (type) {
    case 'BankStatement':
    case 'CreditCardStatement':
    case 'OfficialGovernmentLetter':
    case 'PropertyTaxAssessment':
    case 'TaxReturn':
    case 'UtilityBill':
      return 'ProofOfAddress';
    case 'ArticlesOfIncorporation':
    case 'BusinessRegistration':
      return 'ProofOfBusiness';
    case 'DriversLicense':
    case 'NationalId':
    case 'Passport':
      return 'ProofOfIdentity';
    default:
      return 'Authorization';
  }
};

const documentTypeRequiresBack = (type: string): boolean =>
  type === 'DriversLicense' || type === 'NationalId';

const documentTypeRequiresCountry = (type: string): boolean =>
  type === 'DriversLicense' || type === 'NationalId' || type === 'Passport';

const getMinFileSizeForType = (type: string): number =>
  getMinFileSizeForCategoryName(getCategoryName(getCategoryForType(type)));

const getMinFileSizeForCategoryName = (categoryName: string): number => {
  switch (categoryName) {
    case 'Identity':
      return 409600; // 400KB
    case 'Address':
      return 30720; // 30KB
    default:
      return 10240; // 10KB
  }
};

const getMaxFileSizeForType = (type: string): number =>
  getMaxFileSizeForCategoryName(getCategoryName(getCategoryForType(type)));

const getMaxFileSizeForCategoryName = (categoryName: string): number => {
  switch (categoryName) {
    case 'Authorization':
      return 2097152; // 2MB
    default:
      // HW can't tell us an exact limit so we're guessing a bit here
      // it's supposed to be 4MB but values between 4000000 and 4194304 have been failing
      // trialing 4000000 as a limit to see if this covers it
      return 4000000; // 4MB
  }
};

const getCategoryQuantity = (
  categoryName: string,
  requiredDocuments?: RequiredVerificationDocument[]
): number | undefined =>
  requiredDocuments?.find((d) => categoryName === getCategoryName(d.category))
    ?.quantity;

const getDocumentsPageSubTitle = (
  requiredDocuments?: RequiredVerificationDocument[],
  errors?: VerificationError[]
): ReactElement => {
  if (requiredDocuments) {
    return (
      <>
        We need{' '}
        <span>
          {sum(
            requiredDocuments?.map(
              (d: RequiredVerificationDocument) => d.quantity
            )
          )}
        </span>{' '}
        document(s)
      </>
    );
  }
  if (errors) {
    return (
      <>
        There was a problem with{'  '}
        <span>
          {
            errors.filter(
              (e: VerificationError) => e.code === 'InvalidDocument'
            ).length
          }
        </span>{' '}
        of your documents
      </>
    );
  }
  return <>Edit your documents</>;
};

const getCategorySubTitle = (
  categoryName: string,
  requiredDocuments?: RequiredVerificationDocument[],
  errors?: VerificationError[]
): ReactElement => {
  const quantity = getCategoryQuantity(categoryName, requiredDocuments);
  if (quantity) {
    return <>Choose the document type:</>;
  }
  if (errors) {
    return (
      <>
        Update your invalid <b style={{ fontWeight: 700 }}>{categoryName}</b>{' '}
        documents or choose a new document
      </>
    );
  }
  return (
    <>
      Update your <b style={{ fontWeight: 700 }}>{categoryName}</b> documents
    </>
  );
};

const onFirstOfMultipleTabs = (
  documentCategories: string[],
  selectedCategory: string
): boolean =>
  documentCategories.length > 1 && documentCategories[0] === selectedCategory;

const onSubsequentTab = (
  documentCategories: string[],
  selectedCategory: string
): boolean =>
  documentCategories.length > 1 && documentCategories[0] !== selectedCategory;

const getVerificationErrorText = (
  error: VerificationError,
  documents?: AccountDocument[]
): string => {
  if (error.code === 'InvalidField') {
    return `Invalid ${error.id}: ${error.description}`;
  }
  const invalidDocument = documents?.find(
    (doc: AccountDocument) => doc.front === error.id
  );
  if (invalidDocument) {
    return `Invalid ${camelCaseToWords(invalidDocument.type)}: ${
      error.description
    }`;
  }
  return `Invalid document: ${error.description}`;
};

export {
  getCategoryName,
  getCategoryFriendlyName,
  getDocumentCategories,
  calculateSelectedDocumentTypes,
  updateEditedDocuments,
  isEditedDocumentValid,
  enableSubmitDocuments,
  calculateCompletedDocumentCategories,
  getInvalidDocumentError,
  editedDocumentToDocumentRequest,
  getAvailableDocumentTypes,
  getCategoryForType,
  documentTypeRequiresBack,
  documentTypeRequiresCountry,
  getMinFileSizeForType,
  getMinFileSizeForCategoryName,
  getMaxFileSizeForType,
  getMaxFileSizeForCategoryName,
  getCategorySubTitle,
  getDocumentsPageSubTitle,
  getSelectedDocumentTypeLimit,
  onFirstOfMultipleTabs,
  onSubsequentTab,
  getVerificationErrorText,
};
