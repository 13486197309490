import { ReactElement, useRef, useEffect, useState, useCallback } from 'react';
import { FileResponse } from '../../hooks/useFileUpload';
import { formatBytes, formatDate } from '../../functions/helpers';
import { ServerError } from '../forms/ServerError';

type FileBrowserProps = {
  files: FileResponse[] | null;
  setSelectedFile: (selectedFile: FileResponse | null) => void;
  getMore: () => void;
  isLoading?: boolean;
  error?: string;
  onDoubleClick?: () => void;
};

const FileBrowser = ({
  files,
  setSelectedFile,
  getMore,
  isLoading = false,
  error,
  onDoubleClick,
}: FileBrowserProps): ReactElement => {
  const fileBrowserRef = useRef<HTMLDivElement>(null);
  const fileAreaRef = useRef<HTMLDivElement>(null);
  const [totalRows, setTotalRows] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null
  );
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (fileBrowserRef.current) {
      const height = fileBrowserRef.current.clientHeight;
      setTotalRows(height / 27);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const current = fileAreaRef.current;
    if (!current || fetching) return;

    const scrollPosition = current.scrollTop + current.clientHeight;
    const scrollHeight = current.scrollHeight;
    if (scrollPosition / scrollHeight >= 0.8) {
      setPreviousScrollTop(current.scrollTop);
      setFetching(true);
      getMore();
      setFetching(false);
    }
  }, [getMore, fetching]);

  useEffect(() => {
    if (fileAreaRef.current && previousScrollTop) {
      fileAreaRef.current.scrollTop = previousScrollTop;
    }
  }, [files]);

  useEffect(() => {
    const current = fileAreaRef.current;
    if (current) {
      current.addEventListener('scroll', handleScroll);
    }
    return (): void => {
      if (current) {
        current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleFileClick = (index: number): void => {
    setSelectedFileIndex(index);
    if (files) {
      const selectedFile = files[index];
      setSelectedFile(selectedFile);
    }
  };

  const remainingRows = files ? totalRows - files.length : totalRows;

  return (
    <div className='FileBrowser' ref={fileBrowserRef}>
      {error && !isLoading && <ServerError error={error} />}
      {isLoading && !files && (
        <div className='FileBrowser--loading'>Loading...</div>
      )}
      {!isLoading && files && (
        <>
          <div className='titleBar'>
            <div className='column'>Name</div>
            <div className='column'>Date Added</div>
            <div className='column'>Size</div>
          </div>
          <div className='fileArea' ref={fileAreaRef}>
            {files &&
              files.map((file, index) => (
                <div
                  key={index}
                  className={`fileRow ${index % 2 === 0 ? 'even' : 'odd'} ${
                    index === selectedFileIndex ? 'selected' : ''
                  }`}
                  onClick={(): void => handleFileClick(index)}
                  onDoubleClick={onDoubleClick}
                >
                  <div className={`file-name ${file.name ? 'has-name' : ''}`}>
                    <span className='file-name-text'>{file.name}</span>
                  </div>
                  <span>{formatDate(file.createdTimestamp)}</span>
                  <span>{formatBytes(file.sizeInBytes)}</span>
                </div>
              ))}
            {files &&
              Array.from({ length: remainingRows }).map((_, index) => (
                <div
                  key={index + files.length}
                  className={`fileRow ${index % 2 !== 0 ? 'even' : 'odd'}`}
                ></div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
export { FileBrowser };
