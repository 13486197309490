import { useEffect, useState } from 'react';
import { ShippingDetailsRequest } from '../interfaces/requestState';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface CreateSubscriptionRequest {
  customer: {
    id: string;
  };
  price: {
    amount: number;
    currency: string;
    interval: {
      unit: string;
      count: number;
      times?: number;
    };
  };
  paymentMethod?: {
    id: string;
  };
  description?: string;
  billingCycleTimestamp?: number;
  metadata?: { [key: string]: string };
  shippingDetails?: ShippingDetailsRequest;
  paymentSettings?: {
    statementDescriptor: {
      descriptor: string;
      city: string;
    };
  };
}

function useCreateSubscription(): any {
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setIsCreated(true);
    }
  }, [response]);

  const createSubscription = (body: CreateSubscriptionRequest): void => {
    const url = `/v1/subscriptions`;
    const method = HttpMethod.POST;
    sendRequest({ url, method, body });
  };

  return [createSubscription, isLoading, isCreated, error];
}

export { useCreateSubscription };
