import { useState, useEffect } from 'react';
import { Dispute } from '../interfaces/disputeState';
import { toCamelCase } from '../functions/helpers';

enum RecommendedEvidence {
  BillingAddress = 'BillingAddress',
  ShippingAddress = 'ShippingAddress',
  DuplicateTransaction = 'DuplicateTransaction',
  Uncategorised = 'Uncategorised',
  ProofOfDelivery = 'ProofOfDelivery',
  CustomerSignature = 'CustomerSignature',
  Receipt = 'Receipt',
  ShippingConfirmation = 'ShippingConfirmation',
  CustomerCommunication = 'CustomerCommunication',
  RefundPolicy = 'RefundPolicy',
  RecurringPaymentAgreement = 'RecurringPaymentAgreement',
}
export type EvidenceItem = {
  type: RecommendedEvidence;
  text?: string;
  fileId?: string;
  isAdded: boolean;
  allowed: 'text' | 'file' | 'both';
};

type SimplifiedDispute = {
  optionalEvidence: EvidenceItem[];
  recommendedEvidence: EvidenceItem[];
};

function useTransformDispute(dispute: Dispute): SimplifiedDispute {
  const [simplifiedDispute, setSimplifiedDispute] = useState<SimplifiedDispute>(
    {
      optionalEvidence: [],
      recommendedEvidence: [],
    }
  );

  const textEvidenceTypes = [
    RecommendedEvidence.BillingAddress,
    RecommendedEvidence.ShippingAddress,
    RecommendedEvidence.DuplicateTransaction,
    RecommendedEvidence.Uncategorised,
  ];

  const fileEvidenceTypes = [
    RecommendedEvidence.ProofOfDelivery,
    RecommendedEvidence.CustomerSignature,
    RecommendedEvidence.Receipt,
    RecommendedEvidence.ShippingConfirmation,
    RecommendedEvidence.CustomerCommunication,
    RecommendedEvidence.RefundPolicy,
    RecommendedEvidence.RecurringPaymentAgreement,
    RecommendedEvidence.Uncategorised,
  ];

  const generateEvidenceList = (type: RecommendedEvidence): EvidenceItem => {
    const text = dispute.evidence?.text?.[toCamelCase(type)] || null;
    const fileId = dispute.evidence?.files?.[toCamelCase(type)]?.id || null;

    const evidenceItem: EvidenceItem = {
      type,
      isAdded: false,
      allowed: 'text',
    };

    const isTextType = textEvidenceTypes.includes(type);
    const isFileType = fileEvidenceTypes.includes(type);

    if (isTextType && isFileType) {
      evidenceItem.allowed = 'both';
    } else if (isFileType) {
      evidenceItem.allowed = 'file';
    }

    if (isTextType && text) {
      evidenceItem.text = text;
      evidenceItem.isAdded = true;
    }

    if (isFileType && fileId) {
      evidenceItem.fileId = fileId;
      evidenceItem.isAdded = true;
    }

    return evidenceItem;
  };

  useEffect(() => {
    const evidenceTypes = Object.values(RecommendedEvidence);

    const evidenceList = evidenceTypes.map((type) =>
      generateEvidenceList(type)
    );

    const optionalEvidence = evidenceList.filter(
      (item) => !dispute.recommendedEvidence.includes(item.type)
    );

    const recommendedEvidence = evidenceList.filter((item) =>
      dispute.recommendedEvidence.includes(item.type)
    );

    setSimplifiedDispute({
      optionalEvidence,
      recommendedEvidence,
    });
  }, [dispute]);

  return simplifiedDispute;
}

export default useTransformDispute;
