import React, { useEffect, useState } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory, useParams } from 'react-router-dom';
import { Security } from './security';
import { ProfileScreen } from './profile';
import { MenuItemShape, MenuLayout } from '../../components/layouts/MenuLayout';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { handleNotSignedIn } from '../../functions/helpers';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';

const ProfileRoute = (): React.ReactElement => {
  const { profileState } = useGlobalDataState();
  const history = useHistory();
  const { menuItemKey }: any = useParams();
  const { isSignedIn } = useGlobalState();
  const [menuItems, setMenuItems] = useState<MenuItemShape[]>([]);

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  useEffect(() => {
    getMenuItems();
  }, [profileState]);

  const getMenuItems = (): void => {
    const menuItems = [];

    menuItems.push({
      key: '',
      name: 'Profile',
      content: <ProfileScreen />,
    });

    menuItems.push({
      key: 'security',
      name: 'Security',
      content: <Security />,
    });

    setMenuItems(menuItems);
  };

  return (
    <Layout isSignedIn={isSignedIn}>
      <div className='SettingsRoute'>
        <div className='SettingsRoute--center'>
          <MenuLayout
            title='Profile'
            path='/profile'
            items={menuItems}
            itemKey={menuItemKey}
          />
        </div>
      </div>
    </Layout>
  );
};

export { ProfileRoute };
