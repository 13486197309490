import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface PauseSubscriptionRequest {
  reason?: string;
  resumeAtTimestamp?: number;
}
export interface CancelSubscriptionRequest {
  reason?: string;
}

function useAdjustSubscription(): any {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setIsComplete(true);
    }
  }, [response]);

  const cancelSubscription = (
    body: CancelSubscriptionRequest,
    subscriptionId: string
  ): void => {
    const url = `/v1/subscriptions/${subscriptionId}/cancel`;
    const method = HttpMethod.DELETE;
    sendRequest({ url, method, body });
  };

  const pauseSubscription = (
    body: PauseSubscriptionRequest,
    subscriptionId: string
  ): void => {
    const url = `/v1/subscriptions/${subscriptionId}/pause`;
    const method = HttpMethod.PATCH;
    sendRequest({ url, method, body });
  };

  return [cancelSubscription, pauseSubscription, isLoading, isComplete, error];
}

export { useAdjustSubscription };
