import React, { ReactElement, useState, useEffect } from 'react';

import { DatePicker } from '../forms/DatePicker';
import { SelectInput } from '../forms/SelectInput';
import { Button } from '../forms/Button';
import { EmailToCustomer } from '../forms/EmailToCustomer';
import { epochSecondsToDate } from '../../functions/helpers';
import { TextInput } from '../forms/TextInput';
import { Customer } from '../../interfaces/customersState';

export interface SubscriptionFilter {
  ascending: boolean;
  endTimestamp: string;
  pageSize: number;
  paymentMethodId: string;
  startsAfter: string;
  startTimestamp: string;
  subscriptionId: string;
  status: string;
  customerId: string;
}

interface FilterSubscriptionsProps {
  setFilter: (filter: SubscriptionFilter) => void;
}

const FilterSubscriptions = ({
  setFilter,
}: FilterSubscriptionsProps): ReactElement => {
  const [customer, setCustomer] = useState<Customer>();
  const [startTimestamp, setStartTimestamp] = useState<string>('');
  const [endTimestamp, setEndTimestamp] = useState<string>('');
  const [startsAfter, setStartsAfter] = useState<string>('');
  const [subscriptionId, setSubscriptionId] = useState<string>('');
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [ascending, setAscending] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  const handleApplyFilter = (): void => {
    const filter: SubscriptionFilter = {
      ascending,
      endTimestamp,
      pageSize: 25,
      paymentMethodId,
      startsAfter,
      startTimestamp,
      subscriptionId,
      status,
      customerId: customer?.id || '',
    };
    setFilter(filter);
  };

  useEffect(() => {
    handleApplyFilter();
  }, [
    ascending,
    endTimestamp,
    paymentMethodId,
    startsAfter,
    startTimestamp,
    subscriptionId,
    status,
    customer?.id,
  ]);

  useEffect(() => {
    handleApplyFilter();
  }, []);

  const handleClearFilter = (): void => {
    setStatus('');
    setAscending(false);
    setCustomer(undefined);
    setEndTimestamp('');
    setPaymentMethodId('');
    setStartsAfter('');
    setStartTimestamp('');
    setSubscriptionId('');
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <SelectInput
        label
        name='Status'
        value={status}
        setValue={setStatus}
        options={[
          { value: '', name: 'Any' },
          { value: 'Pending', name: 'Pending' },
          { value: 'Active', name: 'Active' },
          { value: 'Cancelled', name: 'Cancelled' },
          { value: 'PastDue', name: 'Past Due' },
          { value: 'Ended', name: 'Ended' },
        ]}
      />

      <EmailToCustomer
        title='Customers email'
        customer={customer}
        setCustomer={setCustomer}
      />

      <TextInput
        name='Subscription id'
        label
        value={subscriptionId}
        setValue={setSubscriptionId}
        placeHolder='sub_'
      />

      <DatePicker
        type='START'
        name='Start Date'
        value={parseInt(startTimestamp)}
        notAfter={
          endTimestamp ? epochSecondsToDate(parseInt(endTimestamp)) : new Date()
        }
        label
        setValue={(e): void => {
          if (e) {
            setStartTimestamp(e.toString());
          }
        }}
      />

      <DatePicker
        label
        type='END'
        name='End Date'
        value={parseInt(endTimestamp)}
        notBefore={
          startTimestamp
            ? epochSecondsToDate(parseInt(startTimestamp))
            : undefined
        }
        notAfter={new Date()}
        setValue={(e): void => {
          if (e) {
            setEndTimestamp(e.toString());
          }
        }}
      />
      <h2
        style={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
        }}
      >
        Sort order
      </h2>
      <SelectInput
        name='Sort Order'
        value={ascending ? 'true' : 'false'}
        setValue={(e): void => setAscending(e === 'true')}
        options={[
          { value: 'true', name: 'Ascending' },
          { value: 'false', name: 'Descending' },
        ]}
      />

      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};

export { FilterSubscriptions };
