import { ReactElement, useEffect } from 'react';
import { Layout } from '../../layout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { SubscriptionDetail } from '../../components/dataGrids/SubscriptionDetail';
import { handleNotSignedIn, isMobile } from '../../functions/helpers';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { stopReplayIfActive } from '../../functions/sessionReplayHelpers';

const SubscriptionRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();
  const params: any = useParams();

  useEffect(() => {
    stopReplayIfActive();
  }, []);

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !profileState?.data?.account?.settings?.subscriptionsDisplayed ||
      !permissions.has('SubscriptionsView')
    ) {
      return history.push('/');
    }
    if (!isMobile() && params.id)
      history.push(`/subscriptions?id=${params.id}`);
  }, [isSignedIn]);

  return (
    <Layout isSignedIn={isSignedIn}>
      <SubscriptionDetail />
    </Layout>
  );
};

export { SubscriptionRoute };
