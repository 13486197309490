import { ReactElement, useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface PropsShape {
  title: string;
  children?: ReactElement;
  toggle: number;
}

const DropDown = ({ title, children, toggle }: PropsShape): ReactElement => {
  const menuTransition = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (toggle) setIsOpen(!isOpen);
  }, [toggle]);

  return (
    <div className='DropDown'>
      {isOpen && (
        <div
          className='DropDown--overlay'
          onClick={(): void => setIsOpen(!isOpen)}
        ></div>
      )}
      <button
        className='DropDown--button'
        onClick={(): void => setIsOpen(!isOpen)}
      >
        {title}
      </button>
      <CSSTransition
        nodeRef={menuTransition}
        in={isOpen}
        timeout={300}
        classNames={`DropDown--transition`}
        unmountOnExit
      >
        <div className='DropDown--menu' ref={menuTransition}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
export { DropDown };
