import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { LayoutIllustration } from '../layout-illustration';
import yourAccount from '../images/illustrations/your-account.png';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../hooks/useGlobalState';
import { TermsUpdated } from '../components/onboarding/TermsUpdated';

const TermsUpdatedRoute = (): React.ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const isAuthLink = queryParams && queryParams.authLink;

  useEffect(() => {
    async function checkUser(): Promise<void> {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        Sentry.captureException(err);
        history.push('/');
      }
    }
    if (isSignedIn) {
      history.push(isAuthLink ? '/authorized' : '/');
    } else {
      checkUser();
    }
  }, [isSignedIn]);

  return (
    <LayoutIllustration
      image={yourAccount}
      title={<>New Terms</>}
      subTitle={<>We've updated our Terms of Service</>}
    >
      <TermsUpdated />
    </LayoutIllustration>
  );
};

export { TermsUpdatedRoute };
