import { ReactElement } from 'react';
import Chart from 'react-apexcharts';
import { formatPrice } from '../../../functions/helpers';
import { ApexOptions } from 'apexcharts';
import { Currency } from '../../../interfaces/state';

export interface PropsShape {
  value: {
    isLoading: boolean;
    categories: string[];
    data: number[];
    currency: Currency;
  };
}

const NetPaymentsVolumeChart = ({ value }: PropsShape): ReactElement => {
  const options: ApexOptions = {
    title: {
      text: 'Net volume',
      align: 'center',
    },
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      colors: ['#2581e3'],
      width: 3,
    },
    noData: value.isLoading
      ? {
          text: 'Loading...',
        }
      : {
          text: 'No data for selection',
        },
    yaxis: {
      labels: {
        formatter: (val: number): string => {
          return formatPrice({
            pence: val,
            currency: value.currency,
          });
        },
      },
    },
    xaxis: {
      categories: value.categories,
      tickAmount: 4,
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  const series = [
    {
      name: 'net volume',
      data: value.data,
    },
  ];

  return (
    <div className='NetPaymentsVolumeChart'>
      <div id='chart'>
        <Chart
          options={options}
          series={series}
          type='line'
          height='300'
          width='100%'
        />
      </div>
    </div>
  );
};

export { NetPaymentsVolumeChart };
