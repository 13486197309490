import React, { ReactElement } from 'react';
import { Button } from '../forms/Button';
import {
  AccountDocument,
  BusinessRole,
  VerificationError,
  VerificationStatus,
} from '../../interfaces/accountState';
import { CreateStatus } from './CreateStatus';
import { getVerificationErrorText } from '../../functions/documentHelpers';

export interface PropsShape {
  name: string;
  email?: string;
  status: VerificationStatus;
  roles: BusinessRole[];
  documents?: AccountDocument[];
  verificationErrors?: VerificationError[];
  buttonText?: string;
  showButton?: boolean;
  buttonDisabled?: boolean;
  buttonClicked?: () => void;
}

const IndividualItem = ({
  name,
  email,
  status,
  roles,
  documents,
  verificationErrors,
  buttonText = 'Edit',
  showButton = true,
  buttonDisabled = false,
  buttonClicked,
}: PropsShape): ReactElement => {
  const handleVerificationStatus = (
    status: VerificationStatus
  ): ReactElement | undefined => {
    switch (status) {
      case 'Verified':
        return <CreateStatus title='Verified' color='GREEN' icon='TICK' />;
      case 'Required':
        return <CreateStatus title='Required' color='RED' icon='EXCLAMATION' />;
      case 'PendingVerification':
        return <CreateStatus title='Under Review' color='ORANGE' icon='TIME' />;
      default:
        return undefined;
    }
  };

  return (
    <div className='IndividualItem'>
      <div className='IndividualItem--grid'>
        <div className='IndividualItem--grid-inner'>
          <div>
            <div className='IndividualItem--name'>{name}</div>
            {email && <div className='IndividualItem--email'>{email}</div>}
          </div>
          <div className='IndividualItem--status'>
            {handleVerificationStatus(status)}
          </div>
        </div>
        <div className='IndividualItem--positions'>
          {roles.map((position, index) => (
            <div key={index}>• {position}</div>
          ))}
        </div>
        {verificationErrors && (
          <div className='IndividualItem--errors'>
            {verificationErrors.map(
              (error: VerificationError, index: number) => (
                <div key={index}>
                  {getVerificationErrorText(error, documents)}
                </div>
              )
            )}
          </div>
        )}
      </div>
      {showButton && (
        <div className='IndividualItem--grid'>
          <Button
            margin='0'
            name={buttonText}
            click={buttonClicked}
            disabled={buttonDisabled}
          />
        </div>
      )}
    </div>
  );
};

export { IndividualItem };
