import { DisputeStatus } from '../interfaces/disputeState';

function getDisputeStatusTitle(status: DisputeStatus): string {
  if (status === 'Accepted') {
    return 'You accepted this dispute';
  }
  if (status === 'Expired') {
    return 'The deadline to challenge this dispute has passed.';
  }
  if (status === 'Lost') {
    return 'This dispute was ruled in favour of the cardholder.';
  }
  if (status === 'Won') {
    return 'This dispute was ruled in your favour.';
  }
  if (status === 'Challenged') {
    return 'Dispute has been challenged. We will notify you of the outcome (Won or Lost) at a later date.';
  }
  return '';
}

export { getDisputeStatusTitle };
