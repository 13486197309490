import { Params } from '@sentry/react/types/types';
import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { get } from '../../../functions/callApi';
import { isValidHttpsUrl } from '../../../functions/validators';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { WebhookEndpoint } from '../../../interfaces/developerState';
import { LayoutIllustration } from '../../../layout-illustration';
import { handleNotSignedIn } from '../../../functions/helpers';
import { SelectCheckBox } from '../../../components/forms/SelectCheckBox';
import { OptionShape } from '../../../interfaces/state';

const EditWebhookRoute = (): ReactElement => {
  interface EditedDetails {
    url?: string;
    eventTypes?: string[];
    active?: boolean;
  }

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const returnTo = query.get('returnTo') || '/developer/webhooks';
  const history = useHistory();
  const params: Params = useParams();
  const { isSignedIn } = useGlobalState();
  const { webhooksState, profileState } = useGlobalDataState();
  const [serverError, setServerError] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [eventTypesError, setEventTypesError] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [active, setActive] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const [editedDetails, setEditedDetails] = useState<EditedDetails>({});

  const webhookId = params?.id;
  const webhook = webhooksState.data?.webhooks.find((w) => w.id == webhookId);
  const allEventTypes: OptionShape[] = (
    webhooksState.data?.eventTypes || []
  ).map((eventType: string) => ({ name: eventType, value: eventType }));

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (
      !params?.id ||
      !webhooksState?.data ||
      !webhook ||
      !permissions.has('DeveloperSettingsModify')
    ) {
      history.push(returnTo);
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (webhook) {
      setUrl(webhook.url);
      setEventTypes(webhook.eventTypes);
      setActive(webhook.active);
    }
  }, [webhook]);

  useEffect(() => {
    setEditedDetails({
      url,
      eventTypes,
      active,
    });
  }, [url, eventTypes, active]);

  const isBlank = (value?: string): boolean => {
    return (value?.trim().length || 0) <= 0;
  };

  useEffect(() => {
    if (!webhook) {
      return;
    }
    const detailsBlank =
      isBlank(editedDetails.url) &&
      editedDetails.active === undefined &&
      (editedDetails.eventTypes || []).length < 0;
    const detailsDiffer = detailsHaveBeenEdited(webhook, editedDetails);
    setBtnDisabled(!detailsDiffer || detailsBlank);
  }, [editedDetails]);

  const detailsHaveBeenEdited = (
    webhook: WebhookEndpoint,
    edited: EditedDetails
  ): boolean => {
    const disjunctEvents: string[] = [];
    const editedSet = new Set(edited.eventTypes);
    webhook.eventTypes.forEach((eventType) => {
      if (!editedSet.has(eventType)) {
        disjunctEvents.push(eventType);
      }
    });
    const eventTypesDiffer =
      edited.eventTypes?.length != webhook.eventTypes.length ||
      disjunctEvents.length > 0;
    return (
      edited.url != webhook.url ||
      eventTypesDiffer ||
      edited.active != webhook.active
    );
  };

  const editWebhook = async (): Promise<void> => {
    if (!webhook) {
      return;
    }
    setServerError('');
    let validated = true;
    if (editedDetails.url) {
      const isValidUrl = isValidHttpsUrl(editedDetails.url);
      validated = isValidUrl;
      if (!isValidUrl) {
        setUrlError(
          `The URL you have provided is not valid. Please check if is well formed and has protocol 'https'`
        );
      }
    }
    if (editedDetails.eventTypes) {
      if (editedDetails.eventTypes.length <= 0) {
        validated = false;
        setEventTypesError(
          'Webhook must have at least one type of event. If you want to stop receiving events consider disabling or deleting the webhook instead.'
        );
      }
    }
    if (validated) {
      setIsEditing(true);
      const apiUrl = `/v1/developer/webhooks/${webhook.id}`;
      const body = JSON.stringify({
        url: isBlank(editedDetails.url) ? null : editedDetails.url,
        active: editedDetails.active,
        eventTypes:
          editedDetails.eventTypes && editedDetails.eventTypes.length > 0
            ? editedDetails.eventTypes
            : null,
      });
      try {
        const response = await get(apiUrl, 'PATCH', body);
        if (response?.status === 200) {
          history.push(returnTo);
        } else {
          setServerError(response.message);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsEditing(false);
      }
    }
  };

  const deleteWebhook = async (): Promise<void> => {
    if (!webhook?.id) {
      return;
    }
    try {
      setIsDeleting(true);
      const apiUrl = `/v1/developer/webhooks/${webhook.id}`;
      const response = await get(apiUrl, 'DELETE');
      if (response?.status === 200) {
        history.push(returnTo);
      } else {
        setServerError(response.message);
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      {!webhook && <div className='AppRoute--notfound'>Error 404</div>}
      {webhook && (
        <LayoutIllustration
          title={
            <>
              Edit your <span>Webhook</span>
            </>
          }
          subTitle={<>Make changes to your existing webhook</>}
        >
          <form
            style={{ padding: '0 24px' }}
            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
              e.preventDefault();
            }}
          >
            <ServerError error={serverError} />
            <TextInput
              label
              name='Webhook Id'
              value={webhook.id}
              type={'String'}
              disabled={true}
            />
            <TextInput
              label
              name='URL'
              value={editedDetails.url || ''}
              setValue={setUrl}
              description='The URL at which to receive events (must be https)'
              focus
            />
            <div
              style={{
                color: '#ec3f3f',
                fontSize: '11px',
                position: 'relative',
                top: '-6px',
              }}
            >
              {urlError}
            </div>
            <SelectInput
              label
              name='Status'
              value={active ? 'Active' : 'Disabled'}
              setValue={(v): any => setActive(v === 'Active')}
              options={[
                { value: 'Active', name: 'Active' },
                { value: 'Disabled', name: 'Disabled' },
              ]}
            />
            <SelectCheckBox
              name='Events'
              grid='TWO_COLUMN'
              allValues={allEventTypes}
              selectedValues={eventTypes}
              setSelectedValues={setEventTypes}
              error={eventTypesError}
            />
            <div className='EditWebhookRoute--button-container'>
              <Button
                name='Save'
                click={editWebhook}
                loading={isEditing}
                disabled={btnDisabled}
              />
              <Button
                name='Cancel'
                click={(): void => history.push(returnTo)}
                color='GREY'
              />
              <>
                <div className='EditWebhookRoute--delete'>
                  {!deletedSelected && (
                    <span>
                      I no longer wish to use this webhook.
                      <a
                        onClick={(): any => {
                          setDeleteSelected(true);
                        }}
                      >
                        &nbsp;Delete Webhook
                      </a>
                    </span>
                  )}
                  {deletedSelected && (
                    <div className='EditWebhookRoute--delete-button'>
                      <Button
                        name='Delete'
                        click={deleteWebhook}
                        color='RED'
                        loading={isDeleting}
                        disabled={!deletedSelected}
                      />
                    </div>
                  )}
                </div>
              </>
            </div>
          </form>
        </LayoutIllustration>
      )}
    </>
  );
};

export { EditWebhookRoute };
