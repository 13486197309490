import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { AccountShape } from '../interfaces/accountState';

interface UseGetSubAccountByIdReturnType {
  subAccount?: AccountShape;
  isLoading: boolean;
  error?: string;
  fetchSubAccount: (id: string) => Promise<void>;
}

function useGetSubAccountById(): UseGetSubAccountByIdReturnType {
  const [subAccount, setSubAccount] = useState<AccountShape | undefined>(
    undefined
  );
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest();

  const fetchSubAccount = async (id: string): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/sub-accounts?accountId=${id}`,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while fetching the account:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse?.data.subAccounts.items[0]) {
      setSubAccount(apiResponse.data.subAccounts.items[0]);
    }
  }, [apiResponse]);

  return { subAccount: subAccount, isLoading, error, fetchSubAccount };
}

export { useGetSubAccountById };
