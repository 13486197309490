import { ReactElement } from 'react';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Pagination } from '../layouts/Pagination';
import { DateTime } from '../layouts/DateTime';
import {
  epochSecondsToDate,
  isMobile,
  pascalCaseToWords,
  truncateWord,
} from '../../functions/helpers';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { Copy } from '../forms/Copy';
import { formatPrice } from '../../functions/helpers';
import { getCurrency } from '../../functions/money-utility';
import { Payout, PayoutCalculation } from '../../interfaces/payoutsState';
import {
  payoutSchemeName,
  expectedPayoutCompletion,
  handlePayoutStatus,
} from '../../functions/payoutHelpers';
import {
  getAccountNumberTypeName,
  getBankIdTypeName,
  obfuscatedAccountNumber,
} from '../../functions/payoutMethodHelpers';

const PayoutDetail = (): ReactElement => {
  const history = useHistory();
  const { payoutsState, setPayoutsState } = useGlobalDataState();
  const payoutIndex = payoutsState.data?.items?.findIndex(
    (i) => JSON.stringify(i) === JSON.stringify(payoutsState.data?.selected)
  );

  const goNavigate = (direction: string): void => {
    if (
      (payoutIndex! === 0 && direction === 'PREVIOUS') ||
      (payoutIndex! === payoutsState.data?.items.length! - 1 &&
        direction === 'NEXT')
    ) {
      setPayoutsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          remoteControl: {
            direction,
            issuedAt: +Date.now(),
          },
        },
      }));
    } else if (payoutIndex! < payoutsState.data?.items.length!) {
      const selected =
        direction === 'NEXT'
          ? payoutsState.data?.items[payoutIndex! + 1]
          : payoutsState.data?.items[payoutIndex! - 1];

      const newURL = `/payouts/payout/${selected?.id}`;
      const newState = { id: selected?.id };
      window.history.pushState(newState, '', newURL);
      setPayoutsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          selected,
        },
      }));
    }
  };

  const getTransactionsPeriod = (payout: Payout): string => {
    const text = `Transactions taken from ${payout.paymentsTakenDateFrom}`;
    if (payout.paymentsTakenDateFrom === payout.paymentsTakenDateTo) {
      return text;
    }
    return `${text} to ${payout.paymentsTakenDateTo}`;
  };

  const showBreakdown = (calculation: PayoutCalculation): boolean =>
    !!calculation.paymentsCapturedAmount ||
    !!calculation.paymentsRefundedAmount ||
    !!calculation.paymentsSplitAmount ||
    !!calculation.paymentsSplitRefundedAmount ||
    !!calculation.splitPaymentsAmount ||
    !!calculation.splitPaymentsRefundedAmount ||
    !!calculation.platformFeesCollectedAmount ||
    !!calculation.platformFeesRefundedAmount ||
    !!calculation.platformFeesPaidAmount ||
    !!calculation.processingFeesPaidAmount ||
    !!calculation.chargebacksAmount ||
    !!calculation.chargebackReversalsAmount ||
    !!calculation.transferredInAmount ||
    !!calculation.transferredOutAmount ||
    !!calculation.platformChargebacksAmount ||
    !!calculation.platformChargebackReversalsAmount ||
    !!calculation.feeAmount;

  const calculateTotalCreditsPrice = (
    calculation: PayoutCalculation
  ): string => {
    const amount =
      calculation.paymentsCapturedAmount +
      calculation.paymentsSplitRefundedAmount +
      calculation.splitPaymentsAmount +
      calculation.platformFeesCollectedAmount +
      calculation.chargebackReversalsAmount +
      calculation.transferredInAmount +
      calculation.platformChargebackReversalsAmount;
    return formatPrice({
      pence: amount,
      currency: getCurrency(calculation.currency),
    });
  };

  const calculateTotalDebitsPrice = (
    calculation: PayoutCalculation
  ): string => {
    const amount =
      calculation.paymentsRefundedAmount +
      calculation.paymentsSplitAmount +
      calculation.splitPaymentsRefundedAmount +
      calculation.platformFeesRefundedAmount +
      calculation.platformFeesPaidAmount +
      calculation.processingFeesPaidAmount +
      calculation.chargebacksAmount +
      calculation.transferredOutAmount +
      calculation.platformChargebacksAmount +
      calculation.feeAmount;
    return formatPrice({
      pence: amount,
      currency: getCurrency(calculation.currency),
    });
  };

  return (
    <div className='PayoutDetail'>
      <div className='PayoutDetail--title-container'>
        <div className='PayoutDetail--title'>Payout</div>
        {!isMobile() && <div className='PayoutDetail--esc'>Esc</div>}
      </div>
      {!isMobile() && (
        <div className='PayoutDetail--pagination'>
          <Pagination
            labels
            goPrevious={(): any => goNavigate('PREVIOUS')}
            goNext={(): any => goNavigate('NEXT')}
            previousDisabled={
              payoutsState.data?.remoteControl?.previousDisabled &&
              payoutIndex === 0
            }
            nextDisabled={
              payoutsState.data?.remoteControl?.nextDisabled &&
              payoutIndex === payoutsState?.data?.items?.length - 1
            }
          />
        </div>
      )}
      {payoutsState.data?.selected && (
        <>
          <div className='PayoutDetail--data-container'>
            <div className='PaymentDetailLayout--title'>
              <div className='PaymentDetailLayout--title-desc'>Details</div>
              <div className='PaymentDetailLayout--title-id'></div>
            </div>
            <div className='PaymentDetailLayout--container'>
              <div>
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Id:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val PaymentDetailLayout--container-pair-status'>
                    <Copy
                      type='BUTTON_WITH_TEXT'
                      buttonSize='MEDIUM'
                      value={`${payoutsState.data.selected.id}`}
                      text={`${payoutsState.data.selected.id}`}
                    />
                  </div>
                </div>
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Status:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val PaymentDetailLayout--container-pair-status'>
                    {handlePayoutStatus(payoutsState.data.selected.status)}
                  </div>
                </div>
                {payoutsState.data.selected.failureReason && (
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      Failure Reason:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {pascalCaseToWords(
                        payoutsState.data.selected.failureReason
                      )}
                    </div>
                  </div>
                )}
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Amount:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {formatPrice({
                      pence: +payoutsState.data.selected.amount,
                      currency: getCurrency(
                        payoutsState.data.selected.currency
                      ),
                    })}
                  </div>
                </div>
                {payoutsState.data.selected.scheme && (
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      Scheme:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {payoutSchemeName(payoutsState.data.selected.scheme)}
                    </div>
                  </div>
                )}
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Created At:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    <DateTime
                      showTime
                      value={epochSecondsToDate(
                        payoutsState.data.selected.createdTimestamp
                      )}
                    />
                  </div>
                </div>
                {payoutsState.data.selected.completedTimestamp && (
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      Completed At:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      <DateTime
                        showTime
                        value={epochSecondsToDate(
                          payoutsState.data.selected.completedTimestamp
                        )}
                      />
                    </div>
                  </div>
                )}
                {payoutsState.data.selected.status === 'InProgress' && (
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      Expected Completion:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {expectedPayoutCompletion(
                        payoutsState.data.selected.createdTimestamp,
                        payoutsState.data.selected.scheme
                      )}
                    </div>
                  </div>
                )}
                <div className='PaymentDetailLayout--container-pair'>
                  <div className='PaymentDetailLayout--container-pair-key'>
                    Schedule type:
                  </div>
                  <div className='PaymentDetailLayout--container-pair-val'>
                    {payoutsState.data.selected.scheduleType}
                  </div>
                </div>
                {payoutsState.data.selected.metadata &&
                  Object.entries(payoutsState.data.selected.metadata).map(
                    ([key, value]) => (
                      <div
                        className='PaymentDetailLayout--container-pair'
                        key={key}
                      >
                        <div className='PaymentDetailLayout--container-pair-key'>
                          <div className='PaymentDetailLayout--meta'>
                            {key}:
                          </div>
                        </div>
                        <div className='PaymentDetailLayout--container-pair-val'>
                          {truncateWord(value, 40, 40)}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          {payoutsState.data.selected.payoutMethod && (
            <div className='PayoutDetail--data-container'>
              <div className='PaymentDetailLayout--title'>
                <div className='PaymentDetailLayout--title-desc'>
                  Bank details
                </div>
                <div className='PaymentDetailLayout--title-id'></div>
              </div>
              <div className='PaymentDetailLayout--container'>
                <div>
                  {payoutsState.data.selected.payoutMethod.bankAccount
                    .bankName && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        Name:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val'>
                        {
                          payoutsState.data.selected.payoutMethod.bankAccount
                            .bankName
                        }
                      </div>
                    </div>
                  )}
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      {getBankIdTypeName(
                        payoutsState.data.selected.payoutMethod.bankAccount
                          .bankIdType
                      )}
                      :
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {
                        payoutsState.data.selected.payoutMethod.bankAccount
                          .bankId
                      }
                    </div>
                  </div>
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key'>
                      {`${getAccountNumberTypeName(
                        payoutsState.data.selected.payoutMethod.bankAccount
                          .accountNumberType
                      )}:`}
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {obfuscatedAccountNumber(
                        payoutsState.data.selected.payoutMethod.bankAccount
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showBreakdown(payoutsState.data.selected.payoutCalculation) && (
            <div className='PayoutDetail--data-container'>
              <div className='PaymentDetailLayout--title'>
                <div className='PaymentDetailLayout--title-desc'>Breakdown</div>
                <div className='PaymentDetailLayout--title-id'></div>
              </div>
              <div className='PaymentDetailLayout--area'>
                <div className='PaymentDetailLayout--area-desc'>
                  {getTransactionsPeriod(payoutsState.data.selected)}
                </div>
                <div className='PaymentDetailLayout--area-id'></div>
              </div>
              <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-bottom'>
                <div>
                  {!!payoutsState.data.selected.payoutCalculation
                    .paymentsCapturedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPaymentsCaptured
                        }{' '}
                        payments captured:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .paymentsCapturedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .splitPaymentsAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfSplitPayments
                        }{' '}
                        split payments captured:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .splitPaymentsAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .paymentsSplitRefundedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPaymentsSplitRefunded
                        }{' '}
                        payments split refunded:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .paymentsSplitRefundedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .platformFeesCollectedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPlatformFeesCollected
                        }{' '}
                        platform fees collected:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .platformFeesCollectedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .transferredInAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfTransfersIn
                        }{' '}
                        transfers in:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .transferredInAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .chargebackReversalsAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfChargebackReversals
                        }{' '}
                        chargeback reversals
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .chargebackReversalsAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .platformChargebackReversalsAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPlatformChargebackReversals
                        }{' '}
                        platform chargeback reversals
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .platformChargebackReversalsAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key-bold'>
                      Total Credits:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {calculateTotalCreditsPrice(
                        payoutsState.data.selected.payoutCalculation
                      )}
                    </div>
                  </div>
                </div>
                <div className='PaymentDetailLayout--arrow-bottom' />
              </div>
              <div className='PaymentDetailLayout--container'>
                <div>
                  {!!payoutsState.data.selected.payoutCalculation
                    .paymentsRefundedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPaymentsRefunded
                        }{' '}
                        payments refunded:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .paymentsRefundedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .splitPaymentsRefundedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfSplitPaymentsRefunded
                        }{' '}
                        split payments refunded:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .splitPaymentsRefundedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .paymentsSplitAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPaymentsSplit
                        }{' '}
                        payments split:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .paymentsSplitAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .platformFeesRefundedAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPlatformFeesRefunded
                        }{' '}
                        platform fees refunded:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .platformFeesRefundedAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .platformFeesPaidAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPaymentsCaptured
                        }{' '}
                        platform fees paid:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .platformFeesPaidAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .processingFeesPaidAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        Processing fees paid:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .processingFeesPaidAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .transferredOutAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfTransfersOut
                        }{' '}
                        transfers out:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .transferredOutAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .chargebacksAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfChargebacks
                        }{' '}
                        chargebacks:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .chargebacksAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation
                    .platformChargebacksAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        {
                          payoutsState.data.selected.payoutCalculation
                            .numberOfPlatformChargebacks
                        }{' '}
                        platform chargebacks:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .platformChargebacksAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  {!!payoutsState.data.selected.payoutCalculation.feeAmount && (
                    <div className='PaymentDetailLayout--container-pair'>
                      <div className='PaymentDetailLayout--container-pair-key'>
                        Payout fee:
                      </div>
                      <div className='PaymentDetailLayout--container-pair-val-light'>
                        {formatPrice({
                          pence:
                            payoutsState.data.selected.payoutCalculation
                              .feeAmount,
                          currency: getCurrency(
                            payoutsState.data.selected.payoutCalculation
                              .currency
                          ),
                        })}
                      </div>
                    </div>
                  )}
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key-bold'>
                      Total Debits:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {calculateTotalDebitsPrice(
                        payoutsState.data.selected.payoutCalculation
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='PaymentDetailLayout--container PaymentDetailLayout--container-title-top'>
                <div>
                  <div className='PaymentDetailLayout--container-pair'>
                    <div className='PaymentDetailLayout--container-pair-key-bold'>
                      Total Paid:
                    </div>
                    <div className='PaymentDetailLayout--container-pair-val'>
                      {formatPrice({
                        pence:
                          payoutsState.data.selected.payoutCalculation
                            .payoutAmount,
                        currency: getCurrency(
                          payoutsState.data.selected.payoutCalculation.currency
                        ),
                      })}
                    </div>
                  </div>
                </div>
                <div className='PaymentDetailLayout--arrow-top' />
              </div>
            </div>
          )}
          {isMobile() && (
            <Button
              name='Back'
              color='GREY'
              click={(): any => history.push(`/payouts`)}
            />
          )}
        </>
      )}
    </div>
  );
};
export { PayoutDetail };
