import { ReactElement, FormEvent, useState, useEffect } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Button } from '../../../components/forms/Button';
import { SelectInput } from '../../../components/forms/SelectInput';
import { useHistory } from 'react-router-dom';
import { ServerError } from '../../../components/forms/ServerError';
import { useEditAccount } from '../../../hooks/useEditAccount';
import { handleNotSignedIn } from '../../../functions/helpers';

const EditPayoutModeRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn } = useGlobalState();
  const { accountState, profileState } = useGlobalDataState();
  const [payoutScheduleType, setPayoutScheduleType] = useState<string>(
    accountState?.data?.settings.payouts.schedule.type || ''
  );
  const {
    accountData: updated,
    isLoading,
    error,
    apiEditAccount,
  } = useEditAccount();

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    } else if (
      !accountState?.data?.settings.payouts.schedule.type ||
      !permissions.has('PayoutsModify')
    ) {
      history.push('/settings/banking');
      return;
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (updated) {
      history.push('/settings/banking');
    }
  }, [updated]);

  const editSchedule = async (): Promise<void> => {
    apiEditAccount({
      settings: {
        payouts: {
          schedule: {
            type: payoutScheduleType,
          },
        },
      },
    });
  };

  return (
    <LayoutIllustration
      title={
        <>
          Edit your <span>payout mode</span>
        </>
      }
      subTitle={
        <>
          Would you like to manually create <span>payouts</span> or schedule
          them daily?
        </>
      }
    >
      <form
        style={{ padding: '0 24px' }}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault();
        }}
      >
        <ServerError error={error} />

        <SelectInput
          label
          name='Payment Mode'
          value={payoutScheduleType}
          setValue={setPayoutScheduleType}
          options={[
            { value: 'Automatic', name: 'Daily' },
            { value: 'Manual', name: 'Manual' },
          ]}
        />

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '200px 100px',
            gridGap: '12px',
          }}
        >
          <Button name='Save' click={editSchedule} loading={isLoading} />
          <Button
            name='Cancel'
            click={(): void => history.push('/settings/banking')}
            color='GREY'
          />
        </div>
      </form>
    </LayoutIllustration>
  );
};

export { EditPayoutModeRoute };
