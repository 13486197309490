import { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../../layout-illustration';
import { TextInput } from '../../../components/forms/TextInput';
import { Button } from '../../../components/forms/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { CurrencyInput } from '../../../components/forms/CurrencyInput';
import { useGlobalState } from '../../../hooks/useGlobalState';
import { Params } from '@sentry/react/types/types';
import { PaymentsShape } from '../../../interfaces/paymentState';
import { formatPrice, handleNotSignedIn } from '../../../functions/helpers';
import { StepPosition } from '../../../components/forms/StepPosition';
import { SuccessMessage } from '../../../components/forms/SuccessMessage';
import {
  CapturePaymentRequest,
  useCapturePaymentSession,
} from '../../../hooks/useCapturePaymentSession';
import {
  canBeCaptured,
  capturableAmount,
  voidsAndCapturesSupported,
} from '../../../functions/paymentHelpers';

const CapturePaymentRoute = (): ReactElement => {
  const { paymentsState, profileState } = useGlobalDataState();
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const [payment, setPayment] = useState<PaymentsShape>();
  const [pence, setPence] = useState<number>(0);
  const [penceError, setPenceError] = useState<string>('');
  const captureApi = useCapturePaymentSession();

  const params: Params = useParams();
  const idFromUrl = params?.paymentId;

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (!permissions.has('CapturesModify')) {
      history.push('/payments');
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (paymentsState?.data?.items && profile.group) {
      const payment = paymentsState?.data?.items.find(
        (item) => item.id === idFromUrl
      );
      setPayment(payment);
      const paymentCanBeCaptured = canBeCaptured(payment);
      const capturesCurrentlySupported = voidsAndCapturesSupported(
        payment,
        profile
      );
      const canCapture = paymentCanBeCaptured && capturesCurrentlySupported;
      if (!canCapture) {
        history.push(`/payments?id=${payment?.id}`);
      }
    }
  }, [paymentsState, profile]);

  useEffect(() => {
    if (payment) {
      setPence(capturableAmount(payment));
    }
  }, [payment]);

  const handleCapturePayment = async (): Promise<void> => {
    setPenceError('');
    if (!payment) {
      return;
    }
    let valid = true;
    const minPence = 1;
    const maxPence = capturableAmount(payment);
    if (pence < minPence || pence > maxPence) {
      const minPrice = formatPrice({
        pence: minPence,
        currency: payment.currency,
      });
      const maxPrice = formatPrice({
        pence: maxPence,
        currency: payment.currency,
      });
      setPenceError(`Amount must be between ${minPrice} and ${maxPrice}`);
      valid = false;
    }
    if (!valid) {
      return;
    }
    const requestBody: CapturePaymentRequest = {
      amount: pence,
      captureType: 'NotFinal',
    };
    await captureApi.request(payment.id, requestBody);
  };

  const handleCancelClicked = (): void => {
    if (captureApi.isLoading) {
      return;
    }
    history.push('/payments');
  };

  const getStepPosition = (): number => (captureApi.data ? 2 : 1);

  return (
    <LayoutIllustration
      title={<>Capture a payment</>}
      coBrandedTitle={<>Capture a payment</>}
      subTitle={<>Confirm and clear authorized funds</>}
    >
      <div style={{ padding: '0 12px' }}>
        <StepPosition steps={2} position={getStepPosition()} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '12px',
          }}
        >
          {captureApi.data && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <SuccessMessage
                  message={`Capture Processed - we've processed your ${formatPrice(
                    { pence, currency: payment.currency }
                  )} capture request, the payment will be updated shortly.`}
                />
              </div>
            </>
          )}
          {!captureApi.data && payment && (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridGap: '12px',
                }}
              >
                <TextInput
                  name='Available to capture'
                  value={formatPrice({
                    pence: capturableAmount(payment),
                    currency: payment.currency,
                  })}
                  label
                  readOnly={true}
                  disabled={true}
                />
                <CurrencyInput
                  name='Amount to capture'
                  currency={payment.currency}
                  pence={pence}
                  setPence={setPence}
                />
                {penceError && (
                  <div style={{ color: '#ec3f3f', position: 'relative' }}>
                    {penceError}
                  </div>
                )}
                {captureApi.error && (
                  <div style={{ color: '#ec3f3f', position: 'relative' }}>
                    {captureApi.error}
                  </div>
                )}
                <Button
                  name='Capture Payment'
                  click={handleCapturePayment}
                  loading={captureApi.isLoading}
                />
              </div>
            </>
          )}
        </div>
        <Button
          name={captureApi.data ? 'Back to Payment' : 'Cancel'}
          color='GREY'
          click={(): void => history.push(`/payments?id=${idFromUrl}`)}
        />
        <Button
          name={'View Payments'}
          color='GREY'
          click={handleCancelClicked}
        />
      </div>
    </LayoutIllustration>
  );
};

export { CapturePaymentRoute };
