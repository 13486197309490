import { ReactElement } from 'react';
import { Pagination } from '../layouts/Pagination';
import { isMobile } from '../../functions/helpers';
import { Button } from '../forms/Button';
import { useHistory } from 'react-router-dom';
import { SplitPaymentDetailLayout } from '../layouts/SplitPaymentDetailLayout';
import { SplitPaymentsShape } from '../../interfaces/splitPaymentState';

interface SplitPaymentDetailProps {
  splitPayment?: SplitPaymentsShape | null;
  hasPrevious: boolean;
  handlePrevious: () => void;
  hasNext: boolean;
  handleNext: () => void;
}

const SplitPaymentDetail = ({
  splitPayment,
  hasPrevious,
  handlePrevious,
  hasNext,
  handleNext,
}: SplitPaymentDetailProps): ReactElement => {
  const history = useHistory();

  return (
    <div className='SplitPaymentDetail'>
      <div className='SplitPaymentDetail--top'>
        <div className='SplitPaymentDetail--title-container'>
          <div className='SplitPaymentDetail--title'>Payment</div>
          {!isMobile() && <div className='SplitPaymentDetail--esc'>Esc</div>}
        </div>
        {!isMobile() && (
          <div className='SplitPaymentDetail--pagination'>
            <Pagination
              labels
              goPrevious={(): any => handlePrevious()}
              goNext={(): any => handleNext()}
              previousDisabled={!splitPayment || !hasPrevious}
              nextDisabled={!splitPayment || !hasNext}
            />
          </div>
        )}
        <div className='SplitPaymentDetail--data-container'>
          {splitPayment && <SplitPaymentDetailLayout data={splitPayment} />}
        </div>
      </div>
      <div className='SplitPaymentDetail--middle'></div>
      <div className='SplitPaymentDetail--bottom'>
        <div className='SplitPaymentDetail--buttons'>
          {isMobile() && (
            <Button
              name='Back'
              color='GREY'
              click={(): any => history.push(`/split-payments`)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { SplitPaymentDetail };
