import { FormEvent, ReactElement, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button } from '../../../components/forms/Button';
import { ServerError } from '../../../components/forms/ServerError';
import { TextInput } from '../../../components/forms/TextInput';
import { useGlobalDataState } from '../../../hooks/useGlobalDataState';
import { useGlobalState } from '../../../hooks/useGlobalState';
import {
  BankAccountNumberType,
  BankIdType,
  InternalPayoutMethod,
} from '../../../interfaces/payoutMethodsState';
import { Currency } from '../../../interfaces/state';
import { LayoutIllustration } from '../../../layout-illustration';
import { handleNotSignedIn } from '../../../functions/helpers';
import { getCurrency } from '../../../functions/money-utility';
import { useGetPayoutMethodById } from '../../../hooks/useGetPayoutMethodById';
import {
  EditPayoutMethodRequest,
  useEditPayoutMethod,
} from '../../../hooks/useEditPayoutMethod';
import { useDeletePayoutMethod } from '../../../hooks/useDeletePayoutMethod';
import {
  getAccountNumberTypeName,
  getBankIdTypeName,
  validateAccountNumber,
  validateBankId,
} from '../../../functions/payoutMethodHelpers';

const EditBankAccountRoute = (): ReactElement => {
  interface EditedDetails {
    displayName?: string;
    accountNumber?: string;
    bankId?: string;
  }

  const useQuery = (): any => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const returnTo = query.get('returnTo') || '/settings/banking';
  const history = useHistory();
  const params: any = useParams();
  const { isSignedIn } = useGlobalState();
  const { coBrandingState, profileState } = useGlobalDataState();
  const [displayName, setDisplayName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<string>('');
  const [bankId, setBankId] = useState<string>('');
  const [bankIdError, setBankIdError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [currency, setCurrency] = useState<Currency>();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [deletedSelected, setDeleteSelected] = useState<boolean>(false);
  const [accountNumberType, setAccountNumberType] =
    useState<BankAccountNumberType>();
  const [bankIdType, setBankIdType] = useState<BankIdType>();
  const [editedDetails, setEditedDetails] = useState<EditedDetails>({});

  const payoutMethodId = params?.id;
  const {
    payoutMethod,
    isLoading: getPayoutMethodLoading,
    error: getPayoutMethodError,
    fetchPayoutMethod,
  } = useGetPayoutMethodById();
  const {
    payoutMethod: updatedPayoutMethod,
    isLoading: editPayoutMethodLoading,
    error: editPayoutMethodError,
    editPayoutMethod,
  } = useEditPayoutMethod();
  const {
    deletedPayoutMethod,
    isLoading: deletePayoutMethodLoading,
    error: deletePayoutMethodError,
    deletePayoutMethod,
  } = useDeletePayoutMethod();

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    } else if (!payoutMethodId || !permissions.has('PayoutMethodsModify')) {
      history.push(returnTo);
      return;
    }
    fetchPayoutMethod(payoutMethodId);
  }, [isSignedIn]);

  useEffect(() => {
    if (payoutMethod) {
      setCountry(payoutMethod.countryCode);
      setAccountNumberType(payoutMethod.bankAccount.accountNumberType);
      setBankIdType(payoutMethod.bankAccount.bankIdType);
      setCurrency(getCurrency(payoutMethod.currency));
      setDisplayName(payoutMethod.displayName ?? '');
      if (!accountNumberRequiresResubmission(payoutMethod)) {
        setAccountNumber(payoutMethod.bankAccount.accountNumber);
        setBankId(payoutMethod.bankAccount.bankId);
      }
    }
  }, [payoutMethod]);

  useEffect(() => {
    setEditedDetails({
      displayName,
      accountNumber,
      bankId,
    });
  }, [displayName, accountNumber, bankId]);

  useEffect(() => {
    if (editPayoutMethodError) {
      setServerError(editPayoutMethodError);
    }
  }, [editPayoutMethodError]);

  useEffect(() => {
    if (deletePayoutMethodError) {
      setServerError(deletePayoutMethodError);
    }
  }, [deletePayoutMethodError]);

  useEffect(() => {
    if (updatedPayoutMethod) {
      history.push(returnTo);
    }
  }, [updatedPayoutMethod]);

  useEffect(() => {
    if (deletedPayoutMethod) {
      history.push(returnTo);
    }
  }, [deletedPayoutMethod]);

  useEffect(() => {
    if (!payoutMethod) {
      return;
    }
    const detailsBlank = detailsAreBlank(editedDetails);
    const detailsDiffer = detailsHaveBeenEdited(payoutMethod, editedDetails);
    setBtnDisabled(!detailsDiffer || detailsBlank);
  }, [editedDetails, payoutMethod]);

  const accountNumberRequiresResubmission = (
    payoutMethod: InternalPayoutMethod
  ): boolean => payoutMethod.bankAccount.accountNumber.startsWith('*');

  const isBlank = (value?: string): boolean => {
    return (value?.trim().length || 0) <= 0;
  };

  const detailsAreBlank = (edited: EditedDetails): boolean => {
    return (
      isBlank(edited.displayName) &&
      isBlank(edited.accountNumber) &&
      isBlank(edited.bankId)
    );
  };

  const detailsHaveBeenEdited = (
    payoutMethod: InternalPayoutMethod,
    edited: EditedDetails
  ): boolean => {
    return (
      edited.displayName != payoutMethod.displayName ||
      (!isBlank(edited.accountNumber) &&
        edited.accountNumber != payoutMethod.bankAccount.accountNumber) ||
      (!isBlank(edited.bankId) &&
        edited.bankId != payoutMethod.bankAccount.bankId)
    );
  };

  const editAccount = async (): Promise<void> => {
    if (!payoutMethod || detailsAreBlank(editedDetails)) {
      return;
    }
    setAccountNumberError('');
    setBankIdError('');
    if (editedDetails.accountNumber && !editedDetails.bankId) {
      setBankIdError(`${bankIdType} must also be provided`);
      return;
    }
    if (editedDetails.bankId && !editedDetails.accountNumber) {
      setAccountNumberError(`Account Number / IBAN must also be provided`);
      return;
    }
    if (
      editedDetails.accountNumber &&
      !validateAccountNumber(
        editedDetails.accountNumber,
        accountNumberType!,
        setAccountNumberError
      )
    ) {
      return;
    }
    if (
      editedDetails.bankId &&
      !validateBankId(editedDetails.bankId, bankIdType!, setBankIdError)
    ) {
      return;
    }
    const body: EditPayoutMethodRequest = {
      displayName: editedDetails.displayName || undefined,
      bankAccount: {
        bankIdType: bankIdType!,
        bankId: editedDetails.bankId!,
        accountNumberType: accountNumberType!,
        accountNumber: editedDetails.accountNumber!,
      },
    };
    await editPayoutMethod(payoutMethod.id, body);
  };

  const deleteBankAccount = async (): Promise<void> => {
    if (!payoutMethod) {
      return;
    }
    await deletePayoutMethod(payoutMethod.id);
  };

  return (
    <>
      <LayoutIllustration
        title={
          <>
            Edit your <span>bank account</span>
          </>
        }
        coBrandedTitle={
          <>
            Make changes to your bank account so you can continue to receive
            your payouts from <span>{coBrandingState?.data?.name}</span>
          </>
        }
        subTitle={<>Make changes to your bank account</>}
      >
        <form
          style={{ padding: '0 24px' }}
          onSubmit={(e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
          }}
        >
          {getPayoutMethodLoading && !payoutMethod && <div>Loading...</div>}
          {!getPayoutMethodLoading && !payoutMethod && (
            <ServerError
              error={getPayoutMethodError || 'Failed to load bank details'}
            />
          )}
          {!getPayoutMethodLoading && payoutMethod && (
            <>
              <ServerError error={serverError} />
              <TextInput
                label
                name='Account Name'
                value={editedDetails.displayName || ''}
                setValue={setDisplayName}
                description='A friendly name to help you recognise this account'
                focus
              />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr',
                  gridGap: '12px',
                }}
              >
                <TextInput
                  label
                  name={getAccountNumberTypeName(accountNumberType)}
                  value={editedDetails.accountNumber || ''}
                  placeHolder={
                    accountNumberRequiresResubmission(payoutMethod)
                      ? payoutMethod.bankAccount.accountNumber
                      : ''
                  }
                  setValue={setAccountNumber}
                  type={accountNumberType === 'Iban' ? 'String' : 'number'}
                />
                <TextInput
                  label
                  name={getBankIdTypeName(bankIdType)}
                  value={editedDetails.bankId || ''}
                  placeHolder={
                    accountNumberRequiresResubmission(payoutMethod)
                      ? payoutMethod.bankAccount.bankId
                      : ''
                  }
                  setValue={setBankId}
                  type={bankIdType === 'SwiftCode' ? 'String' : 'number'}
                />
              </div>
              <div
                style={{
                  color: '#ec3f3f',
                  fontSize: '11px',
                  position: 'relative',
                  top: '-6px',
                }}
              >
                {accountNumberError || bankIdError}
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '300px',
                  gridGap: '12px',
                }}
              >
                <TextInput
                  label
                  name='Country'
                  value={country}
                  type={'String'}
                  disabled={true}
                />
                <TextInput
                  label
                  name='Currency'
                  value={`${currency?.code} - ${currency?.name}`}
                  type={'String'}
                  disabled={true}
                />
              </div>
              <div className='EditAccountRoute--button-container'>
                <Button
                  name='Edit Account'
                  click={editAccount}
                  loading={editPayoutMethodLoading}
                  disabled={btnDisabled}
                />
                <Button
                  name='Cancel'
                  click={(): void => history.push(returnTo)}
                  color='GREY'
                />
                <>
                  <div className='EditAccountRoute--delete'>
                    {!deletedSelected && (
                      <span>
                        I no longer wish to use this account.
                        <a
                          onClick={(): any => {
                            setDeleteSelected(true);
                          }}
                        >
                          &nbsp;Delete Account
                        </a>
                      </span>
                    )}
                    {deletedSelected && (
                      <div className='EditAccountRoute--delete-button'>
                        <Button
                          name='Delete'
                          click={deleteBankAccount}
                          color='RED'
                          loading={deletePayoutMethodLoading}
                          disabled={!deletedSelected}
                        />
                      </div>
                    )}
                  </div>
                </>
              </div>
            </>
          )}
        </form>
      </LayoutIllustration>
    </>
  );
};

export { EditBankAccountRoute };
