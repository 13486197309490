import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import {
  BankAccountNumberType,
  BankIdType,
  PayoutMethod,
  PayoutMethodType,
} from '../interfaces/payoutMethodsState';
import { AccountAddressRequest } from './useEditAccount';

interface UseCreatePayoutMethodReturnType {
  payoutMethod: PayoutMethod | null;
  isLoading: boolean;
  error: any;
  createPayoutMethod: (body: CreatePayoutMethodRequest) => Promise<void>;
}

export interface CreatePayoutMethodRequest {
  type: PayoutMethodType;
  displayName?: string;
  currency: string;
  country: string;
  bankAccount: CreateBankAccountRequest;
}

interface CreateBankAccountRequest {
  bankIdType: BankIdType;
  bankId: string;
  accountNumberType: BankAccountNumberType;
  accountNumber: string;
  address?: AccountAddressRequest;
}

function useCreatePayoutMethod(): UseCreatePayoutMethodReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PayoutMethod>();

  const [payoutMethodData, setPayoutMethodData] = useState<PayoutMethod | null>(
    null
  );

  const apiCreatePayoutMethod = async (
    body: CreatePayoutMethodRequest
  ): Promise<void> => {
    try {
      await sendRequest({
        url: '/v1/account/payout-methods',
        method: HttpMethod.POST,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while creating the payout method:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setPayoutMethodData(apiResponse.data);
    }
  }, [apiResponse]);

  return {
    payoutMethod: payoutMethodData,
    isLoading,
    error,
    createPayoutMethod: apiCreatePayoutMethod,
  };
}

export { useCreatePayoutMethod };
