import React, { Dispatch, SetStateAction, ReactElement } from 'react';

interface PropsShape {
  value?: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  onText?: string;
  offtext?: string;
  marginBottom?: number;
}

function Toggle({
  value = false,
  setValue,
  onText = 'on',
  offtext = 'off',
  marginBottom = 0,
}: PropsShape): ReactElement {
  const doToggle = (): void => {
    setValue(!value);
  };
  return (
    <div className='Toggle' style={{ marginBottom: `${marginBottom}px` }}>
      <div
        className={value ? 'Toggle--slider-green' : 'Toggle--slider-red'}
        onClick={doToggle}
        data-testid='click'
      >
        <div className={value ? 'on' : 'off'}></div>
      </div>
      <div className='Toggle--label'>{value ? onText : offtext}</div>
    </div>
  );
}

export { Toggle };
