import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../../functions/callApi';
import { epochSecondsToDate, isMobile } from '../../functions/helpers';
import { FileItem } from '../layouts/FileItem';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { DateTime } from '../layouts/DateTime';
import { Button } from '../forms/Button';

const ReportDetail = ({ folder }: any): ReactElement => {
  const [folderData, setFolderData] = useState<any>();
  const [fileSize, setFileSize] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { setReportsState } = useGlobalDataState();
  const params: any = useParams();

  useEffect(() => {
    if (params?.reportId?.substring(0, 3) === 'rp_') {
      const callApi = async (): Promise<void> => {
        const apiUrl = `/v1/reports?reportId=${params?.reportId}`;
        const response = await request<any>(apiUrl);
        if (response.status === 200) {
          setFolderData(response.reports.items[0]);
        }
      };
      callApi();
    }
  }, [params]);

  useEffect(() => {
    if (folder) {
      setFolderData(folder);
      const callApi = async (): Promise<void> => {
        const apiUrl = `/v1/files/${folder.fileIds[0]}`;
        const response = await request<any>(apiUrl);
        if (response.status === 200) {
          setFileSize(response.sizeInBytes);
        }
      };
      folder.fileIds[0] && callApi();
    }
  }, [folder]);

  const doClick = async (): Promise<any> => {
    const apiUrl = `/v1/files/${folderData.fileIds[0]}/download`;
    const response = await request<any>(apiUrl, 'POST');
    window.open(response.downloadUrl);
  };
  const doDelete = async (): Promise<any> => {
    setLoading(true);
    const apiUrl = `/v1/reports/${folderData?.id}`;
    await request<any>(apiUrl, 'DELETE');
    setLoading(false);
    setReportsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        remoteControl: { close: Date.now() },
      },
    }));
  };

  return (
    <div className='ReportDetail'>
      <div className='ReportDetail--heading'>
        <div className='ReportDetail--title'>Report: {folderData?.name}</div>
        <div className='ReportDetail--esc'>{isMobile() ? '' : 'Esc'}</div>
      </div>

      <div className='ReportDetail--details-container'>
        <div className='ReportDetail--details-container-key'>Id</div>
        <div className='ReportDetail--details-container-value'>
          {folderData?.id}
        </div>

        <div className='ReportDetail--details-container-key'>Report range</div>
        <div className='ReportDetail--details-container-value'>
          <DateTime
            value={epochSecondsToDate(folderData?.parameters?.fromTimestamp!)}
          />{' '}
          -{' '}
          <DateTime
            value={epochSecondsToDate(folderData?.parameters?.toTimestamp!)}
          />
        </div>
        <div className='ReportDetail--details-container-key'>Created</div>
        <div className='ReportDetail--details-container-value'>
          <DateTime value={epochSecondsToDate(folderData?.createdTimestamp!)} />
        </div>
        <div className='ReportDetail--details-container-key'>Updated</div>
        <div className='ReportDetail--details-container-value'>
          <DateTime
            value={epochSecondsToDate(folderData?.lastUpdatedTimestamp!)}
          />
        </div>
        <div className='ReportDetail--details-container-key'>
          Number of files
        </div>
        <div className='ReportDetail--details-container-value'>
          {folderData?.status === 'Failed' ? 0 : 1}
        </div>
        <div className='ReportDetail--details-container-key'>
          Total file size
        </div>
        <div className='ReportDetail--details-container-value'>
          {(fileSize / 1000).toFixed(2)} KB
        </div>
        {folderData?.status === 'Generating' && (
          <>
            <div className='ReportDetail--details-container-key'>Status</div>
            <div className='ReportDetail--details-container-value'>
              {folderData?.status}
            </div>
          </>
        )}
      </div>

      <div className='ReportDetail--files'>
        {folderData?.status === 'Generated' && (
          <FileItem
            file={folderData}
            selected={false}
            click={doClick}
            hideButtons
          />
        )}
      </div>

      <div className='ReportDetail--actions'>
        {folderData?.status !== 'Generating' && (
          <Button
            name='Delete Report'
            color='RED'
            click={doDelete}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};
export { ReportDetail };
