import React, { useEffect } from 'react';
import { useGetPaymentSessionById } from '../../hooks/useGetPaymentSessionById';
import InlineMenu from '../layouts/InlineMenu';
import { DateTime } from '../layouts/DateTime';
import {
  createMaskedPan,
  epochSecondsToDate,
  formatPrice,
  getCardSchemeIcon,
} from '../../functions/helpers';
import { Copy } from '../forms/Copy';

interface PaymentsProps {
  id: string;
  subAccountId?: string;
}

const Payments: React.FC<PaymentsProps> = ({ id, subAccountId }) => {
  const { paymentSession, isLoading, error, fetchPaymentSession } =
    useGetPaymentSessionById();

  useEffect(() => {
    fetchPaymentSession(id, subAccountId);
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
    );
  }
  if (error || !paymentSession) {
    return <div>{error || 'Failed to get payment details'}</div>;
  }

  return (
    <div className='PaymentsSharedTab'>
      <div className='PaymentsSharedTab--header'>
        <h2>
          {formatPrice({
            pence: paymentSession.totalAmount,
            currency: paymentSession.currency,
          })}
        </h2>
        <InlineMenu
          items={[
            {
              label: 'View Payment',
              url: `/payments?id=${paymentSession.id}${
                subAccountId
                  ? `&accountId=${subAccountId}&accountType=subAccounts`
                  : ''
              }`,
            },
          ]}
        />
      </div>
      <div className='PaymentsSharedTab--middle'>
        <div className='PaymentsSharedTab--middle-item'>
          <div>Statement Descriptor</div>
          <div>
            {paymentSession.statementDescriptor.descriptor}
            {' / '}
            {paymentSession.statementDescriptor.city}
          </div>
        </div>
        <div className='PaymentsSharedTab--middle-item'>
          {paymentSession.paymentMethod?.card && (
            <>
              <div>Card</div>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <img
                  src={getCardSchemeIcon(
                    paymentSession.paymentMethod.card.scheme
                  )}
                ></img>
                {createMaskedPan(
                  paymentSession.paymentMethod.card.scheme,
                  paymentSession.paymentMethod.card.last4
                )}
              </div>
            </>
          )}
        </div>
        <div className='PaymentsSharedTab--middle-item'>
          <div>When</div>
          <div>
            <DateTime
              showTime
              value={epochSecondsToDate(paymentSession.createdTimestamp)}
            />
          </div>
        </div>
        <div className='PaymentsSharedTab--middle-item'>
          {paymentSession.paymentMethod?.card && (
            <>
              <div>Expires</div>
              <div>
                {paymentSession.paymentMethod.card.expiryMonth} /
                {paymentSession.paymentMethod.card.expiryYear}
              </div>
            </>
          )}
        </div>
      </div>
      <div className='PaymentsSharedTab--footer' style={{ marginTop: '12px' }}>
        <div>Id</div>
        <div>
          <Copy
            type='BUTTON_WITH_TEXT'
            buttonSize='MEDIUM'
            value={paymentSession.id}
            text={paymentSession.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Payments;
