import { ReactElement, useEffect, useState } from 'react';
import { DateTime } from '../layouts/DateTime';
import { SideMenu } from '../layouts/SideMenu';
import { useHistory } from 'react-router-dom';
import {
  AccountCapability,
  AccountDocument,
  AccountShape,
  OnboardingFlow,
  RequiredField,
  RequiredVerificationDocument,
  RequiredVerificationPerson,
  VerificationError,
} from '../../interfaces/accountState';
import { Copy } from '../forms/Copy';
import {
  epochSecondsToDate,
  epochToDateString,
  pascalCaseToWords,
} from '../../functions/helpers';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { Status, StatusShape } from '../layouts/Status';
import { useGetBalances } from '../../hooks/useGetBalances';
import { useListPayouts } from '../../hooks/useListPayouts';
import { formatPrice, isMobile } from '../../functions/helpers';
import { useGetPayoutMethods } from '../../hooks/useGetPayoutMethods';
import { useGetPersons } from '../../hooks/useGetPersons';
import { PayoutMethod } from '../../interfaces/payoutMethodsState';
import { PersonShape } from '../../interfaces/personsState';
import { ConditionalButton } from '../forms/ConditionalButton';
import { getPayoutIneligibleReasonTitle } from './FilterPayouts';
import { Payout, PayoutIneligibleReason } from '../../interfaces/payoutsState';
import {
  accountEmail,
  accountName,
  accountType,
  getDocuments,
  overallVerificationStatus,
} from '../../functions/accountHelpers';
import {
  getCategoryFriendlyName,
  getVerificationErrorText,
} from '../../functions/documentHelpers';
import { Balances, PendingShape } from '../../interfaces/balancesState';
import { Currency } from '../../interfaces/state';
import { UserPermission } from '../../interfaces/profileState';
import { handlePayoutStatus } from '../../functions/payoutHelpers';
import { CreateStatus } from '../layouts/CreateStatus';

interface PulseItem {
  title: string;
  body: string;
}

interface SubAccountsProps {
  subAccounts: AccountShape[];
  isMore: boolean;
  getMore: () => void;
  isLoading: boolean;
  error?: string | undefined;
}

interface SubAccountTabProps {
  selectedSubAccount: AccountShape;
}

interface CapabilityItemProps {
  capability: AccountCapability;
  title: string;
  description: string;
}

interface BalanceItem {
  currency: Currency;
  availableNow: number;
  availableNextTimestamp: number;
  availableNext: number;
  availableNextNextTimestamp: number;
  availableNextNext: number;
}

const getSubPayoutIneligibleReasonBody = (
  reason: PayoutIneligibleReason,
  onboardingFlow: OnboardingFlow
): string | undefined => {
  const minPayout =
    reason.minimumPayoutAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.minimumPayoutAmount,
          currency: reason.currencyObject,
        })
      : '';
  const balance =
    reason.balanceAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.balanceAmount,
          currency: reason.currencyObject,
        })
      : '';
  const fee =
    reason.feeAmount !== undefined && reason.currencyObject
      ? formatPrice({
          pence: reason.feeAmount,
          currency: reason.currencyObject,
        })
      : '';
  switch (reason.reason) {
    case 'PayoutMethodRequired':
      return `A sub-account owner needs to add a ${reason.currencyObject?.code} bank account to receive payouts in ${reason.currencyObject?.code}`;
    case 'PayoutMethodInvalid':
      return `The sub-account ${reason.currencyObject?.code} bank account has some invalid details, please ask the sub-account owner to update them`;
    case 'VerificationRequired':
      return `Please ask the sub-account owner ${
        onboardingFlow === 'Hosted' ? 'to login' : ''
      } to provide a few more details so we can verify their account`;
    case 'VerificationUnderReview':
      return `We are currently reviewing this sub-account`;
    case 'PayoutsDisabled':
      return `The sub-accounts ability to create payouts has been disabled`;
    case 'AccountInactive':
      return `The sub-account has been temporarily frozen, possibly due to fraud screening, please click here to contact us`;
    case 'ProviderDetailsRequired':
      return `This sub-account setup is incomplete`;
    case 'BelowMinimumPayoutAmount':
      return minPayout && balance
        ? `A minimum of ${minPayout} balance is required for a payout, this sub-account only has ${balance}`
        : 'This sub-account does not have sufficient funds in their balance to create a payout';
    case 'InsufficientFundsForPayoutFee':
      return fee && balance
        ? `A fee of ${fee} is required for any payout, but this sub-account only has ${balance} in their pending balances to cover the fee`
        : 'This sub-account does not have sufficient funds in their balance to cover the payout fee';
    case 'InsufficientFundsForPayout':
      return 'This sub-account does not have sufficient funds in their balance to create a payout';
    case 'NotAcceptedTermsOfService':
      return 'The sub-account needs to review and accept the terms of service before payouts can be made';
    default:
      return undefined;
  }
};

const CapabilityItem = ({
  capability,
  title,
  description,
}: CapabilityItemProps): ReactElement => (
  <div className='BankingTab--balance'>
    <div className='BankingTab--account'>
      <div className='BankingTab--header'>
        <h2>{title}</h2>
      </div>

      <div className='BankingTab--group'>
        <div className='BankingTab--title'>Status</div>
        <div className='BankingTab--value'>
          <Status status={capability.status.toUpperCase() as StatusShape} />
        </div>
      </div>

      <div className='BankingTab--group'>
        <div className='BankingTab--title'>Description</div>
        <div className='BankingTab--value'>{description}</div>
      </div>

      {capability.enabledTimestamp && (
        <div className='BankingTab--group'>
          <div className='BankingTab--title'>Date Enabled</div>
          <div className='BankingTab--value'>
            <DateTime
              showTime
              value={epochSecondsToDate(capability.enabledTimestamp)}
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

const CapabilitiesTab = ({
  selectedSubAccount,
}: SubAccountTabProps): ReactElement => {
  const capabilities: any = selectedSubAccount.capabilities;

  const paymentCapabilities = [
    {
      key: 'amexPayments',
      title: 'Amex Payments',
      description: 'Accept Amex payments',
    },
    {
      key: 'mastercardPayments',
      title: 'Mastercard Payments',
      description: 'Accept Mastercard payments',
    },
    {
      key: 'visaPayments',
      title: 'Visa Payments',
      description: 'Accept Visa payments',
    },
  ];

  return (
    <div className='BalanceBankingTabTab'>
      <div className='BankingTab--grid'>
        {paymentCapabilities.map(
          ({ key, title, description }, index: number) =>
            capabilities[key]?.status && (
              <CapabilityItem
                key={`capability-${index}`}
                capability={capabilities[key]}
                title={title}
                description={description}
              />
            )
        )}
      </div>
    </div>
  );
};

const IndividualsTab = ({
  selectedSubAccount,
}: SubAccountTabProps): ReactElement => {
  const { personsData, isLoading, error, fetchPersons } = useGetPersons();

  useEffect(() => {
    fetchPersons(selectedSubAccount.id);
  }, [selectedSubAccount]);

  const getDocumentListItem = (document: AccountDocument): ReactElement => {
    let status;
    let suffixText;
    if (document.status === 'Invalid') {
      status = <CreateStatus title='' icon='CROSS' color='RED' size='mini' />;
      suffixText = document.invalidReason;
    }
    if (document.status === 'Pending') {
      status = (
        <CreateStatus title='' icon='TIME' color='LIGHTGREY' size='mini' />
      );
      suffixText = 'under review';
    }
    if (document.status === 'Valid') {
      status = <CreateStatus title='' icon='CHECK' color='GREEN' size='mini' />;
    }
    return (
      <>
        - {status} {pascalCaseToWords(document.type)}{' '}
        {suffixText ? `(${suffixText})` : ''}
      </>
    );
  };

  return (
    <div className='BalanceBankingTabTab'>
      {isLoading && !error && (
        <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
      )}
      {!isLoading && error && (
        <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
      )}
      {!isLoading && personsData && (
        <div className='BankingTab--grid'>
          {personsData.length === 0 ? (
            <div
              className='NoBankAccountsMessage'
              style={{
                padding: '12px 0',
                gridColumn: 'span 2',
              }}
            >
              This sub-account has no individuals yet
            </div>
          ) : (
            personsData.map((person: PersonShape, index: number) => (
              <div key={`person-${index}`} className='BankingTab--balance'>
                <div className='BankingTab--account'>
                  <div className='BankingTab--header'>
                    <h2>
                      {person?.firstName} {person?.lastName}
                    </h2>
                  </div>

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>verification status</div>
                    <div className='BankingTab--value'>
                      <Status
                        status={
                          person.verification.status.toUpperCase() as StatusShape
                        }
                      />
                    </div>
                  </div>

                  {person.verification.errors && (
                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>
                        verification errors
                      </div>
                      <div className='BankingTab--value'>
                        {person.verification.errors
                          .map((e: VerificationError) =>
                            getVerificationErrorText(
                              e,
                              getDocuments(selectedSubAccount)
                            )
                          )
                          .join(', ')}
                      </div>
                    </div>
                  )}

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>Roles</div>
                    <div className='BankingTab--value'>
                      {person.businessRoles.join(', ')}
                    </div>
                  </div>

                  <div className='BankingTab--twocolumns'>
                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>email</div>
                      <div className='BankingTab--value'>{person.email}</div>
                    </div>

                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>Created</div>
                      <div className='BankingTab--value'>
                        <DateTime
                          showTime
                          value={epochSecondsToDate(person.createdTimestamp)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>Date of Birth</div>
                    <div className='BankingTab--value'>
                      {person.dateOfBirth}
                    </div>
                  </div>

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>Documents</div>
                    {person.documents
                      ? person.documents.map((d) => (
                          <div className='BankingTab--value-document-item'>
                            {getDocumentListItem(d)}
                          </div>
                        ))
                      : '-'}
                  </div>

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>Individual ID</div>
                    <Copy
                      text={person.id}
                      value={person.id}
                      textStyle={'BOLD'}
                      type={'BUTTON_WITH_TEXT'}
                      customTextClass='BankingTab--value'
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const BankingTab = ({
  selectedSubAccount,
}: SubAccountTabProps): ReactElement => {
  const { payoutMethods, isLoading, error, fetchPayoutMethods } =
    useGetPayoutMethods();

  useEffect(() => {
    fetchPayoutMethods(selectedSubAccount.id);
  }, [selectedSubAccount]);

  return (
    <div className='BalanceBankingTabTab'>
      {isLoading && !error && (
        <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
      )}
      {!isLoading && error && (
        <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
      )}
      {!isLoading && payoutMethods && (
        <div className='BankingTab--grid'>
          {payoutMethods.length === 0 ? (
            <div
              className='NoBankAccountsMessage'
              style={{
                padding: '12px 0',
                gridColumn: 'span 2',
              }}
            >
              This sub-account has no bank accounts yet
            </div>
          ) : (
            payoutMethods.map((method: PayoutMethod, index: number) => (
              <div key={`banking-${index}`} className='BankingTab--balance'>
                <div className='BankingTab--account'>
                  <div className='BankingTab--header'>
                    <h2>
                      {method.bankAccount.bankName
                        ? `${method.bankAccount.bankName} - `
                        : ''}
                      {method.displayName || `${method.currency} Account`}
                    </h2>
                  </div>

                  <div className='BankingTab--twocolumns'>
                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>Currency</div>
                      <div className='BankingTab--value'>{method.currency}</div>
                    </div>

                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>Country</div>
                      <div className='BankingTab--value'>
                        {method.countryCode}
                      </div>
                    </div>
                  </div>

                  <div className='BankingTab--twocolumns'>
                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>Status</div>
                      <div className='BankingTab--value'>
                        <Status
                          status={method.status.toUpperCase() as StatusShape}
                        />
                      </div>
                    </div>

                    {method.status !== 'Valid' && (
                      <div className='BankingTab--group'>
                        <div className='BankingTab--title'>Invalid Reason</div>
                        <div className='BankingTab--value'>
                          {method.invalidReason ??
                            'Bank account invalid - please contact us'}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='BankingTab--twocolumns'>
                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>
                        {pascalCaseToWords(method.bankAccount.bankIdType)}
                      </div>
                      <div className='BankingTab--value'>
                        {method.bankAccount.bankId}
                      </div>
                    </div>

                    <div className='BankingTab--group'>
                      <div className='BankingTab--title'>Account Number</div>
                      <div className='BankingTab--value'>
                        **** {method.bankAccount.last4}
                      </div>
                    </div>
                  </div>

                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>Payout Method Id</div>
                    <div className='BankingTab--value'>{method.id}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const BalancesTab = ({
  selectedSubAccount,
}: SubAccountTabProps): ReactElement => {
  const {
    balancesData,
    isLoading: isLoadingBalances,
    error: balancesError,
    fetchBalances,
  } = useGetBalances();
  const { payouts, listPayouts } = useListPayouts();

  const [previousPayouts, setPreviousPayouts] = useState<Payout[]>([]);
  const [balanceItems, setBalanceItems] = useState<BalanceItem[]>([]);

  useEffect(() => {
    fetchBalances(selectedSubAccount.id);
    listPayouts({ subAccountId: selectedSubAccount.id });
  }, [selectedSubAccount]);

  useEffect(() => {
    if (payouts && payouts.items) {
      setPreviousPayouts(payouts.items);
    }
  }, [payouts]);

  useEffect(() => {
    if (balancesData) {
      const balanceItems = balancesData?.balances.items.map((balance) => {
        const availableNow = balance.available.amount;
        let availableNext = 0;
        let availableNextTimestamp = 0;
        let availableNextNext = 0;
        let availableNextNextTimestamp = 0;

        const availableTimestamps = Array.from(
          new Set(
            balance.pending.map(
              (pending: PendingShape) => pending.availableTimestamp
            )
          )
        ).sort();

        balance.pending.forEach((pendingItem) => {
          const availableTimestamp = pendingItem.availableTimestamp;
          if (availableTimestamps.indexOf(availableTimestamp) == 0) {
            availableNextTimestamp = availableTimestamp;
            availableNext += pendingItem.calculation.totalAmount;
          }
          if (availableTimestamps.indexOf(availableTimestamp) == 1) {
            availableNextNextTimestamp = availableTimestamp;
            availableNextNext += pendingItem.calculation.totalAmount;
          }
        });

        return {
          currency: balance.currency,
          availableNow,
          availableNextTimestamp,
          availableNext,
          availableNextNextTimestamp,
          availableNextNext,
        } as BalanceItem;
      });
      setBalanceItems(balanceItems);
    }
  }, [balancesData]);

  return (
    <div className='BalanceTab'>
      {isLoadingBalances && !balancesError && (
        <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
      )}
      {!isLoadingBalances && balancesError && (
        <div style={{ textAlign: 'center', padding: '12px' }}>
          {balancesError}
        </div>
      )}
      {!isLoadingBalances && balanceItems && (
        <div className='BalanceTab--grid'>
          {balanceItems.map((balance: BalanceItem, index: number) => (
            <div key={`balance-${index}`} className='BalanceTab--balance'>
              <div className='BalanceTab--account'>
                <div className='BankingTab--header'>
                  <h2>{balance.currency.code} Balance</h2>
                </div>

                <div className='BalanceTab--twocolumns'>
                  <div className='BalanceTab--group'>
                    <div className='BalanceTab--title'>Available Now</div>
                    <div className='BalanceTab--value'>
                      {formatPrice({
                        pence: +balance.availableNow,
                        currency: balance.currency,
                      })}
                    </div>
                  </div>

                  {balance.availableNext !== 0 && (
                    <div className='BalanceTab--group'>
                      <div className='BalanceTab--title'>
                        On{' '}
                        {epochToDateString(
                          balance.availableNextTimestamp,
                          true
                        )}
                      </div>
                      <div className='BalanceTab--value'>
                        {formatPrice({
                          pence: +balance.availableNext,
                          currency: balance.currency,
                        })}
                      </div>
                    </div>
                  )}
                </div>

                {balance.availableNextNext !== 0 && (
                  <div className='BankingTab--group'>
                    <div className='BankingTab--title'>
                      On{' '}
                      {epochToDateString(
                        balance.availableNextNextTimestamp,
                        true
                      )}
                    </div>
                    <div className='BankingTab--value'>
                      {formatPrice({
                        pence: +balance.availableNextNext,
                        currency: balance.currency,
                      })}
                    </div>
                  </div>
                )}

                {previousPayouts &&
                  previousPayouts.filter(
                    (payout) => payout.currency === balance.currency.code
                  ).length > 0 && (
                    <div>
                      <div className='BalanceTab--title'>
                        {previousPayouts.filter(
                          (payout) => payout.currency === balance.currency.code
                        ).length === 1
                          ? 'Previous Payout'
                          : 'Previous Five Payouts'}
                      </div>
                      {previousPayouts
                        .filter(
                          (payout) => payout.currency === balance.currency.code
                        )
                        .slice(0, 5)
                        .map((payout: Payout, payoutIndex: number) => (
                          <div
                            key={`payout-${payoutIndex}`}
                            className='BalanceTab--threecolumns'
                          >
                            <div>
                              <DateTime
                                value={epochSecondsToDate(
                                  payout.createdTimestamp
                                )}
                              />
                            </div>
                            <div className='BalanceTab--value'>
                              {formatPrice({
                                pence: +payout.amount,
                                currency: balance.currency,
                              })}
                            </div>
                            <div>{handlePayoutStatus(payout.status)}</div>
                          </div>
                        ))}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const DetailsTab = ({
  selectedSubAccount,
}: SubAccountTabProps): ReactElement => {
  const { profileState } = useGlobalDataState();
  const {
    balancesData,
    isLoading: isBalancesLoading,
    error: balancesError,
    fetchBalances,
  } = useGetBalances();

  const calcPulse = (balances: Balances): PulseItem[] => {
    const calcPulseItem = (reason: PayoutIneligibleReason): PulseItem => {
      return {
        title: getPayoutIneligibleReasonTitle(reason) || '',
        body:
          getSubPayoutIneligibleReasonBody(
            reason,
            selectedSubAccount.onboardingFlow
          ) || '',
      };
    };

    const pulse: PulseItem[] = [];
    balances.payoutEligibility.ineligibleReasons.forEach(
      (currentItem: PayoutIneligibleReason) => {
        pulse.push(calcPulseItem(currentItem));
      }
    );

    return pulse;
  };

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (permissions.has('PayoutsView')) {
      fetchBalances(selectedSubAccount.id);
    }
  }, [selectedSubAccount]);

  return (
    <div className='SubscriptionTab'>
      <div className='SubscriptionTab--title-grid'></div>
      <table className='SubscriptionTab--table'>
        <tbody>
          <tr>
            <td>Onboarded</td>
            <td>
              {`${selectedSubAccount.entityType ? 'Yes' : 'No'} (${
                selectedSubAccount.onboardingFlow
              } flow)`}
            </td>
          </tr>

          {accountEmail(selectedSubAccount) && (
            <tr>
              <td>Email</td>
              <td>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={accountEmail(selectedSubAccount) || ''}
                  text={accountEmail(selectedSubAccount) || ''}
                  direction='DOWN'
                />
              </td>
            </tr>
          )}
          <tr>
            <td>Created</td>
            <td>
              <DateTime
                showTime
                value={epochSecondsToDate(selectedSubAccount.createdTimestamp)}
              />
            </td>
          </tr>
          <tr>
            <td>Payout schedule</td>
            <td>{selectedSubAccount.settings.payouts.schedule.type}</td>
          </tr>
          <tr>
            <td>
              {selectedSubAccount.entityType === 'Business'
                ? 'Business Verification Status'
                : 'Verification Status'}
            </td>
            <td>
              <Status
                status={
                  selectedSubAccount.verification.status.toUpperCase() as StatusShape
                }
              />
            </td>
          </tr>
          {selectedSubAccount.verification.requiredFields && (
            <tr>
              <td>Required Data</td>
              <td>
                {selectedSubAccount.verification.requiredFields
                  .map((f: RequiredField) => f.name)
                  .join(', ')}
              </td>
            </tr>
          )}
          {selectedSubAccount.verification.requiredDocuments && (
            <tr>
              <td>Required Documents</td>
              <td>
                {selectedSubAccount.verification.requiredDocuments
                  .map(
                    (d: RequiredVerificationDocument) =>
                      `${getCategoryFriendlyName(d.category)}`
                  )
                  .join(', ')}
              </td>
            </tr>
          )}
          {selectedSubAccount.verification.errors && (
            <tr>
              <td>Verification Errors</td>
              <td>
                {selectedSubAccount.verification.errors
                  .map((e: VerificationError) =>
                    getVerificationErrorText(
                      e,
                      getDocuments(selectedSubAccount)
                    )
                  )
                  .toString()}
              </td>
            </tr>
          )}
          {selectedSubAccount.verification.persons && (
            <tr>
              <td>Individuals Verification Status</td>
              <td>
                <Status
                  status={
                    selectedSubAccount.verification.persons.status.toUpperCase() as StatusShape
                  }
                />
              </td>
            </tr>
          )}
          {selectedSubAccount.verification.persons?.required && (
            <tr>
              <td>Required Persons</td>
              <td>
                {selectedSubAccount.verification.persons.required
                  .map((p: RequiredVerificationPerson) =>
                    pascalCaseToWords(p.role)
                  )
                  .toString()}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(isBalancesLoading || balancesError || balancesData) && (
        <>
          <div className='SubscriptionTab--title-grid'>
            Eligibility for payouts:
            <div></div>
          </div>

          {isBalancesLoading && !balancesError && (
            <div style={{ textAlign: 'center', padding: '12px' }}>
              Loading...
            </div>
          )}
          {!isBalancesLoading && balancesError && (
            <div style={{ textAlign: 'center', padding: '12px' }}>
              {balancesError}
            </div>
          )}
          {!isBalancesLoading && balancesData && (
            <ConditionalButton
              name='Create Payout'
              margin='0px'
              pulse={calcPulse(balancesData)}
              card={false}
            />
          )}
        </>
      )}
    </div>
  );
};

const SubAccounts = ({
  subAccounts,
  isMore,
  getMore,
  isLoading,
  error,
}: SubAccountsProps): ReactElement => {
  const history = useHistory();
  const [selectedSubAccount, setSelectedSubAccount] =
    useState<AccountShape | null>(null);
  const { setAccountsState, accountsState, profileState } =
    useGlobalDataState();
  const [canViewPayouts, setCanViewPayouts] = useState(false);
  const [canViewPayoutMethods, setCanViewPayoutMethods] = useState(false);
  const [canViewPersons, setCanViewPersons] = useState(false);
  const [activeTab, setActiveTab] = useState('Detail');

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      PayoutsView: setCanViewPayouts,
      PayoutMethodsView: setCanViewPayoutMethods,
      PersonsView: setCanViewPersons,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const scrollToSelectedItem = (): void => {
    if (selectedSubAccount) {
      const selectedItemElement = document.getElementById(
        `subAccount-${selectedSubAccount.id}`
      );
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  };

  const handleBack = (): void => {
    const currentIndex = subAccounts.findIndex(
      (subAccount) => subAccount.id === selectedSubAccount?.id
    );
    if (currentIndex > 0) {
      handleSelectedSubAccount(subAccounts[currentIndex - 1].id);
    }
  };

  const handleNext = (): void => {
    const currentIndex = subAccounts.findIndex(
      (subAccount) => subAccount.id === selectedSubAccount?.id
    );
    if (currentIndex < subAccounts.length - 1) {
      handleSelectedSubAccount(subAccounts[currentIndex + 1].id);
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && selectedSubAccount) {
      handleCloseSideMenu();
    }
  };

  useEffect(() => {
    scrollToSelectedItem();
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSubAccount]);

  const handleTabChange = (tabName: string): void => setActiveTab(tabName);

  const setSelected = (id: string): void => {
    const subAccount = subAccounts.find((subAccount) => subAccount.id === id);

    setSelectedSubAccount(subAccount || null);
    setAccountsState((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        selected: subAccount,
      },
    }));
  };

  const handleCloseSideMenu = (): void => {
    setSelectedSubAccount(null);
    history.push(`/accounts`);
  };

  const handleSelectedSubAccount = (id: string): void => {
    if (isMobile()) {
      history.push(`/accounts/${id}`);
      return;
    }
    setSelected(id);
    history.push(`/accounts?id=${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    if (id) {
      setSelected(id);
    } else {
      setSelectedSubAccount(null);
    }
  }, [location.search, subAccounts]);

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate: boolean
  ): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (...args: Parameters<T>): void => {
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          func(...args);
        }
      };

      const callNow = immediate && !timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func(...args);
      }
    };
  }

  useEffect(() => {
    const scrollArea = document.getElementById('scrollArea');
    const debouncedGetMore = debounce(
      () => {
        getMore();
      },
      1000,
      true
    );

    const handleScroll = (): void => {
      if (!scrollArea) return;
      const { scrollTop, clientHeight, scrollHeight } = scrollArea;
      if (
        isMore &&
        scrollTop &&
        scrollTop + clientHeight >= scrollHeight - 150
      ) {
        debouncedGetMore();
      }
    };

    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
      return (): void => {
        scrollArea.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMore]);

  return (
    <div className='SubAccounts'>
      <div className='SubAccounts--heading'>
        <div>Verification Status</div>
        <div>Name</div>
        <div>Email</div>
        <div className='SubAccounts--heading-mobile'>Created</div>
      </div>
      <div className='SubAccounts--scroll' id='scrollArea'>
        {subAccounts.map((subAccount, index) => (
          <div
            id={`subAccount-${subAccount.id}`}
            key={`subAccount-${index}`}
            onClick={(): void => handleSelectedSubAccount(subAccount.id)}
            className={
              subAccount.id === selectedSubAccount?.id
                ? 'SubAccounts--item SubAccounts--selected'
                : 'SubAccounts--item'
            }
          >
            <div className='SubAccounts--txt'>
              <Status
                status={
                  overallVerificationStatus(
                    subAccount
                  ).toUpperCase() as StatusShape
                }
              />
            </div>
            <div className='SubAccounts--txt'>{accountName(subAccount)}</div>
            <div className='SubAccounts--txt'>{accountEmail(subAccount)}</div>
            <div className='SubAccounts--txt SubAccounts--txt-mobile'>
              <DateTime
                showTime
                value={epochSecondsToDate(subAccount.createdTimestamp)}
              />
            </div>
          </div>
        ))}
        {isLoading && !error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>Loading...</div>
        )}
        {!isLoading && error && (
          <div style={{ textAlign: 'center', padding: '12px' }}>{error}</div>
        )}
      </div>
      <SideMenu
        isOpen={!!selectedSubAccount}
        onRequestClose={handleCloseSideMenu}
        position='RIGHT'
        width='REGULAR'
      >
        <div style={{ height: '100%' }}>
          <div className='SideBar--title-grid'>
            <div className='SideBar--title'>
              {selectedSubAccount && selectedSubAccount.entityType
                ? `${accountName(selectedSubAccount)} (${accountType(
                    selectedSubAccount
                  )})`
                : 'Sub-Account'}
              <br />
              <span className='SideBar--id'>
                <Copy
                  type='BUTTON_WITH_TEXT'
                  buttonSize='SMALL'
                  value={selectedSubAccount?.id || ''}
                  text={selectedSubAccount?.id || ''}
                  direction='DOWN'
                />
              </span>
            </div>
            <div className='SideBar--esc'>Esc</div>
          </div>
          <div className='SideBar--nav-grid'>
            <div className='SideBar--tab-group'>
              <button
                className={
                  activeTab === 'Detail'
                    ? 'SideBar--tab-active'
                    : 'SideBar--tab'
                }
                onClick={(): void => handleTabChange('Detail')}
              >
                Detail
              </button>
              {canViewPayouts && (
                <button
                  className={
                    activeTab === 'Balances'
                      ? 'SideBar--tab-active'
                      : 'SideBar--tab'
                  }
                  onClick={(): void => handleTabChange('Balances')}
                >
                  Balances
                </button>
              )}
              {canViewPayoutMethods && (
                <button
                  className={
                    activeTab === 'Banking'
                      ? 'SideBar--tab-active'
                      : 'SideBar--tab'
                  }
                  onClick={(): void => handleTabChange('Banking')}
                >
                  Banking
                </button>
              )}
              <button
                className={
                  activeTab === 'Capabilities'
                    ? 'SideBar--tab-active'
                    : 'SideBar--tab'
                }
                onClick={(): void => handleTabChange('Capabilities')}
              >
                Capabilities
              </button>
              {selectedSubAccount?.entityType === 'Business' && canViewPersons && (
                <button
                  className={
                    activeTab === 'Individuals'
                      ? 'SideBar--tab-active'
                      : 'SideBar--tab'
                  }
                  onClick={(): void => handleTabChange('Individuals')}
                >
                  Individuals
                </button>
              )}
            </div>
            <div>
              <button
                className='SideBar--btn-back'
                onClick={handleBack}
                disabled={
                  !selectedSubAccount ||
                  subAccounts.findIndex(
                    (subAccount) => subAccount.id === selectedSubAccount?.id
                  ) === 0
                }
              />

              <button
                className='SideBar--btn-next'
                onClick={handleNext}
                disabled={
                  !accountsState.data?.selected ||
                  subAccounts.findIndex(
                    (subAccount) =>
                      subAccount.id === accountsState.data?.selected?.id
                  ) ===
                    subAccounts.length - 1
                }
              />
            </div>
          </div>

          <div className='SideBar--display-grid'>
            {activeTab === 'Detail' && selectedSubAccount && (
              <DetailsTab selectedSubAccount={selectedSubAccount} />
            )}
            {activeTab === 'Balances' &&
              selectedSubAccount &&
              canViewPayouts && (
                <BalancesTab selectedSubAccount={selectedSubAccount} />
              )}
            {activeTab === 'Banking' &&
              selectedSubAccount &&
              canViewPayoutMethods && (
                <BankingTab selectedSubAccount={selectedSubAccount} />
              )}
            {activeTab === 'Capabilities' && selectedSubAccount && (
              <CapabilitiesTab selectedSubAccount={selectedSubAccount} />
            )}
            {activeTab === 'Individuals' &&
              selectedSubAccount &&
              selectedSubAccount.entityType === 'Business' &&
              canViewPersons && (
                <IndividualsTab selectedSubAccount={selectedSubAccount} />
              )}
          </div>
        </div>
      </SideMenu>
    </div>
  );
};

export { SubAccounts };
