import {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { Calendar } from 'react-date-range';
import {
  dateToEndOfDayEpochSeconds,
  dateToStartOfDayEpochSeconds,
  epochSecondsToDate,
  locale,
} from '../../functions/helpers';

export interface PropsShape {
  label?: boolean;
  name: string;
  value: number | undefined;
  type: 'START' | 'END';
  setValue: Dispatch<SetStateAction<number | undefined>>;
  placeHolder?: string;
  error?: string;
  description?: string;
  optional?: boolean;
  notBefore?: Date;
  notAfter?: Date;
}

const DatePicker = ({
  label = false,
  name,
  value,
  type,
  setValue,
  placeHolder,
  error,
  description,
  optional,
  notBefore,
  notAfter,
}: PropsShape): ReactElement => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string>('Select...');
  const [date, setDate] = useState<Date>();

  const formattedDate = (date: Date): string => {
    const month = date?.toLocaleDateString(locale(), {
      month: 'short',
    });
    const day = date?.toLocaleDateString(locale(), {
      day: 'numeric',
    });
    const isCurrentYear =
      `${new Date().getFullYear()}` ===
      date?.toLocaleDateString(locale(), { year: 'numeric' });
    const year = !isCurrentYear
      ? `${date?.toLocaleDateString(locale(), { year: 'numeric' })}`
      : '';
    return `${month} ${day}${year && ','} ${year}`;
  };

  useEffect(() => {
    if (value) {
      const date = epochSecondsToDate(value);
      setFormattedValue(formattedDate(date));
      setDate(date);
    } else {
      setFormattedValue('Select...');
      setDate(new Date());
    }
  }, [value]);

  const handleSelect = (date: Date): void => {
    let epochSeconds;
    if (type == 'END') {
      epochSeconds = dateToEndOfDayEpochSeconds(date);
    } else {
      epochSeconds = dateToStartOfDayEpochSeconds(date);
    }
    setValue(epochSeconds);
    setShowDatePicker(!showDatePicker);
  };
  return (
    <div className='DatePicker'>
      {error && <div className='DatePicker--error'>{error}</div>}
      {optional && <div className='DatePicker--optional'>Optional</div>}
      {label && (
        <label className='DatePicker--label' htmlFor={name}>
          {name}
        </label>
      )}
      <input
        readOnly
        onClick={(): any => setShowDatePicker(!showDatePicker)}
        type='text'
        id={name}
        name={name}
        value={formattedValue}
        placeholder={placeHolder}
        className='DatePicker--input'
      />
      {description && (
        <small className='DatePicker--description'>{description}</small>
      )}
      {showDatePicker && (
        <div
          className='DatePicker--background'
          onClick={(): any => setShowDatePicker(!showDatePicker)}
        />
      )}
      {showDatePicker && (
        <div className='DatePicker--picker'>
          <Calendar
            onChange={handleSelect}
            minDate={notBefore}
            maxDate={notAfter}
            date={date || new Date()}
          />
        </div>
      )}
    </div>
  );
};
export { DatePicker };
