import { ReactElement, useEffect, useState } from 'react';
import { CardBlank } from '../../components/layouts/CardBlank';
import { SettingsItem } from '../../components/layouts/SettingsItem';
import { Button } from '../../components/forms/Button';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useHistory } from 'react-router-dom';
import { useGetDeveloper } from '../../hooks/useGetDeveloper';
import { Copy } from '../../components/forms/Copy';
import {
  asteriskWord,
  epochSecondsToDate,
  handleNotSignedIn,
} from '../../functions/helpers';
import { WebhookEndpoint } from '../../interfaces/developerState';
import { DateTime } from '../../components/layouts/DateTime';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermission } from '../../interfaces/profileState';

const Webhooks = (): ReactElement => {
  const history = useHistory();
  const [isLoading, error, developer] = useGetDeveloper();
  const [canAdjust, setCanAdjust] = useState(false);
  const { isSignedIn } = useGlobalState();
  const { profileState } = useGlobalDataState();

  const canAddWebhook = (): boolean => {
    return (
      canAdjust &&
      developer !== undefined &&
      developer.webhookDetail?.webhooks !== undefined &&
      developer.webhookDetail.webhooks.length < developer.webhookSettings.limit
    );
  };

  const addWebhook = (): void => {
    history.push('/developer/webhooks/add');
  };

  useEffect(() => {
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
  }, [isSignedIn]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      DeveloperSettingsModify: setCanAdjust,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const editWebhook = (item: WebhookEndpoint): void => {
    history.push(`/developer/webhooks/edit/${item.id}`);
  };

  return (
    <>
      <CardBlank>
        {isLoading && developer === undefined && (
          <SettingsItem
            border
            title='Loading'
            subtitle={'......'}
          ></SettingsItem>
        )}
        {!isLoading && error && (
          <SettingsItem border title='Oops!' subtitle={error}></SettingsItem>
        )}
        {!error && !isLoading && (
          <SettingsItem
            border
            title='Webhooks'
            subtitle={
              <>
                Configure your webhook(s) to receive notifications when events
                occur in your account.
                <br />
                Contact support if you require further webhook endpoints.
              </>
            }
            button={canAdjust ? 'Add Webhook' : ''}
            buttonDisabled={!canAddWebhook()}
            buttonClick={(): void => addWebhook()}
          >
            <CardBlank>
              {!developer?.webhookDetail?.webhooks.length && (
                <SettingsItem
                  border
                  inner
                  title='No Webhooks'
                  subtitle='Add your own webhook endpoints to get notified whenever events happen that are important to you'
                />
              )}
              {developer?.webhookDetail?.webhooks.map(
                (item: WebhookEndpoint, index: any) => {
                  return (
                    <div key={index}>
                      <SettingsItem
                        border={false}
                        inner
                        title='Id'
                        subtitle={
                          <Copy
                            type='BUTTON_WITH_TEXT'
                            text={item.id}
                            value={item.id}
                          ></Copy>
                        }
                      >
                        {canAdjust && (
                          <Button
                            margin='0'
                            name='Edit'
                            click={(): any => editWebhook(item)}
                          />
                        )}
                      </SettingsItem>
                      <SettingsItem
                        border={false}
                        inner
                        title='Secret'
                        subtitle={
                          <Copy
                            type='BUTTON_WITH_TEXT'
                            text={asteriskWord(item.secret)}
                            value={item.secret}
                          ></Copy>
                        }
                      ></SettingsItem>
                      <SettingsItem
                        border={false}
                        inner
                        title='Url'
                        subtitle={
                          <Copy
                            type='BUTTON_WITH_TEXT'
                            text={item.url}
                            value={item.url}
                          ></Copy>
                        }
                      ></SettingsItem>
                      <SettingsItem
                        border={false}
                        inner
                        title='Status'
                        subtitle={item.active ? 'Active' : 'Disabled'}
                      ></SettingsItem>
                      <SettingsItem
                        border={false}
                        inner
                        title='Created'
                        subtitle={
                          <DateTime
                            showTime
                            alwaysShowYear={true}
                            value={epochSecondsToDate(item.createdTimestamp)}
                          />
                        }
                      ></SettingsItem>
                      <SettingsItem
                        border={true}
                        inner
                        title='Events'
                        subtitle={
                          <>
                            <div className='WebhookRoute--event-grid'>
                              {item.eventTypes
                                .sort()
                                .map((eventType: string) => {
                                  return (
                                    <div key={`${item.id}-${eventType}`}>
                                      {eventType}
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        }
                      ></SettingsItem>
                    </div>
                  );
                }
              )}
            </CardBlank>
          </SettingsItem>
        )}
      </CardBlank>
    </>
  );
};
export { Webhooks };
