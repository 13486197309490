import React, { ReactElement, useState, ChangeEvent, FormEvent } from 'react';

interface KeyValue {
  key: string;
  value: string;
}

interface Props {
  metaData?: KeyValue[];
  setMetaData?: (metaData: KeyValue[]) => void;
  optional?: boolean;
}

const MetaData = ({
  metaData = [],
  setMetaData,
  optional = false,
}: Props): ReactElement => {
  const [keyValue, setKeyValue] = useState<KeyValue>({ key: '', value: '' });
  const [keyValues, setKeyValues] = useState<KeyValue[]>(metaData);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setKeyValue({ ...keyValue, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    setKeyValues([...keyValues, keyValue]);
    setKeyValue({ key: '', value: '' });
    if (setMetaData) {
      setMetaData([...keyValues, keyValue]);
    }
  };

  const handleDelete = (index: number): void => {
    setKeyValues(keyValues.filter((_, i) => i !== index));
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLLIElement>,
    index: number
  ): void => {
    event.dataTransfer.setData('text/plain', index.toString());
    event.dataTransfer.dropEffect = 'move';
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (
    event: React.DragEvent<HTMLLIElement>,
    index: number
  ): void => {
    event.preventDefault();
    const fromIndex = Number(event.dataTransfer.getData('text/plain'));
    if (fromIndex === index) {
      return;
    }
    const newKeyValues = [...keyValues];
    const [removed] = newKeyValues.splice(fromIndex, 1);
    newKeyValues.splice(index, 0, removed);
    setKeyValues(newKeyValues);
    if (setMetaData) {
      setMetaData(newKeyValues);
    }
  };

  return (
    <div className='MetaData'>
      <div className='MetaData--title'>
        <h3>Meta Data</h3>
        {optional && <span>optional</span>}
      </div>
      <div className='MetaData--container'>
        <ul>
          {keyValues.map((kv, index) => (
            <li
              key={index}
              draggable
              onDragStart={(event): void => handleDragStart(event, index)}
              onDragOver={handleDragOver}
              onDrop={(event): void => handleDrop(event, index)}
            >
              <div>{kv.key}</div> <div>{kv.value}</div>
              <button type='button' onClick={(): void => handleDelete(index)}>
                -
              </button>
            </li>
          ))}
        </ul>
        <form onSubmit={handleSubmit} className='MetaData--form'>
          <input
            required
            placeholder='Key'
            type='text'
            id='key'
            name='key'
            value={keyValue.key}
            onChange={handleChange}
          />

          <input
            required
            placeholder='Value'
            type='text'
            id='value'
            name='value'
            value={keyValue.value}
            onChange={handleChange}
          />
          <button type='submit'>+</button>
        </form>
      </div>
    </div>
  );
};

export { MetaData };
export type { KeyValue };
