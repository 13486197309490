import { PayoutScheme, PayoutStatus } from '../interfaces/payoutsState';
import { epochSecondsToDate } from './helpers';
import React, { ReactElement } from 'react';
import { CreateStatus } from '../components/layouts/CreateStatus';

const payoutSchemeName = (scheme?: PayoutScheme): string => {
  switch (scheme) {
    case 'Ach':
      return 'ACH';
    case 'Bacs':
      return 'BACS';
    case 'Chaps':
      return 'CHAPS';
    case 'Fps':
      return 'Faster Payments';
    case 'Swift':
      return 'Swift';
    case 'Sepa':
      return 'SEPA';
    case 'SepaInstant':
      return 'SEPA Instant';
    default:
      return '-';
  }
};

const handlePayoutStatus = (status: PayoutStatus): ReactElement | undefined => {
  switch (status) {
    case 'Failed':
      return <CreateStatus title='Failed' color='RED' icon='CROSS' />;
    case 'PendingAccountVerification':
      return (
        <CreateStatus
          title='Pending Verification'
          color='ORANGE'
          icon='CLOCK'
        />
      );
    case 'PendingPayoutMethodAction':
      return (
        <CreateStatus title='Pending Bank Action' color='ORANGE' icon='CLOCK' />
      );
    case 'PendingAccountAction':
      return (
        <CreateStatus title='Pending Action' color='ORANGE' icon='CLOCK' />
      );
    case 'Expired':
      return <CreateStatus title='Expired' color='DARKGREY' icon='CLOCK' />;
    case 'InProgress':
      return <CreateStatus title='In Progress' color='BLUE' icon='COG' />;
    case 'Recalled':
      return <CreateStatus title='Recalled' color='DARKGREY' icon='RETURNED' />;
    case 'Cancelled':
      return (
        <CreateStatus title='Cancelled' color='DARKGREY' icon='CANCELLED' />
      );
    case 'Completed':
      return <CreateStatus title='Paid' color='GREEN' icon='TICK' />;
    case 'Returned':
      return <CreateStatus title='Returned' color='DARKGREY' icon='RETURNED' />;
    default:
      return undefined;
  }
};

const expectedPayoutCompletion = (
  createdTimestamp: number,
  scheme?: PayoutScheme
): string => {
  switch (scheme) {
    case 'Ach':
      return 'Within 1-3 business days';
    case 'Bacs':
      return 'Within 1-3 business days';
    case 'Chaps': {
      const createdDate = epochSecondsToDate(createdTimestamp);
      const day = createdDate.getDay();
      const isWeekday = day !== 0 && day !== 6;
      if (createdDate.getHours() < 14 && isWeekday) {
        return 'By 5pm same day';
      }
      return 'By 5pm next business day';
    }
    case 'Fps':
      return 'Within 5 minutes';
    case 'Swift':
      return 'Within 1-4 business days';
    case 'Sepa':
      return 'Within 1 business day';
    case 'SepaInstant':
      return 'Within 5 minutes';
    default:
      return '-';
  }
};

export { payoutSchemeName, handlePayoutStatus, expectedPayoutCompletion };
