import { useState, useEffect } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import { PersonsResponse, PersonShape } from '../interfaces/personsState';
import { useGlobalDataState } from './useGlobalDataState';

interface UseGetPersonsReturn {
  personsData: PersonShape[] | null;
  isLoading: boolean;
  error: string | undefined;
  fetchPersons: (id?: string) => Promise<void>;
}

export const useGetPersons = (): UseGetPersonsReturn => {
  const { setPersonsState } = useGlobalDataState();
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PersonsResponse>();

  const [personsData, setPersonsData] = useState<PersonShape[] | null>(null);

  const fetchPersons = async (id?: string): Promise<void> => {
    try {
      const url = id
        ? `/v1/account/persons?accountId=${id}`
        : '/v1/account/persons';
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('an error occurred while fetching persons:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      const updatedItems = apiResponse.data.items;
      setPersonsData(updatedItems);
      setPersonsState((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          paginationToken: apiResponse.data.paginationToken,
          items: updatedItems,
        },
      }));
    }
  }, [apiResponse]);

  return {
    personsData,
    isLoading,
    error,
    fetchPersons,
  };
};
