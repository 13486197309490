import { useState, ReactElement, useEffect } from 'react';
import { LayoutIllustration } from '../../layout-illustration';
import { MetaData, KeyValue } from '../../components/forms/MetaData';
import { EmailToCustomer } from '../../components/forms/EmailToCustomer';
import { TextInput } from '../../components/forms/TextInput';
import { Button } from '../../components/forms/Button';
import { CheckBox } from '../../components/forms/CheckBox';
import { DatePicker } from '../../components/forms/DatePicker';
import { SelectInput } from '../../components/forms/SelectInput';
import { useHistory } from 'react-router-dom';
import { useGetCustomerWithPaymentMethodsById } from '../../hooks/useGetCustomerWithPaymentMethodsById';
import { countries } from '../../functions/iso3166-alpha2';
import { usStates } from '../../functions/usStates';
import { caStates } from '../../functions/caStates';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { CurrencyInput } from '../../components/forms/CurrencyInput';
import { useGlobalState } from '../../hooks/useGlobalState';
import {
  useCreateSubscription,
  CreateSubscriptionRequest,
} from '../../hooks/useCreateSubscription';
import { SelectCard } from '../../components/forms/SelectCard';
import { formatPrice, handleNotSignedIn } from '../../functions/helpers';
import { Currency } from '../../interfaces/state';
import { Customer } from '../../interfaces/customersState';

const CreateSubscriptionRoute = (): ReactElement => {
  const history = useHistory();
  const { isSignedIn, profile } = useGlobalState();
  const { subscriptionState, profileState } = useGlobalDataState();
  const [createSubscription, isLoading, isCreated, error] =
    useCreateSubscription();
  const [customer, setCustomer] = useState<Customer>();
  const [, , paymentMethods] = useGetCustomerWithPaymentMethodsById(
    customer?.id
  );
  const [paymentMethod, setPaymentMethod] = useState<string>('customer');
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [currencyOptions, setCurrencyOptions] = useState<any>();
  const [currency, setCurrency] = useState<string>('');
  const [currencyObj, setCurrencyObj] = useState<Currency>();
  const [pence, setPence] = useState<number>(0);
  const [unit, setUnit] = useState<string>('Months');
  const [count, setCount] = useState<string>('1');
  const [times, setTimes] = useState<string>('');
  const [billingCycleTimestamp, setBillingCycleTimestamp] = useState<
    number | undefined
  >();
  const [metaData, setMetaData] = useState<KeyValue[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lineOne, setLineOne] = useState<string>('');
  const [lineTwo, setLineTwo] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [descriptor, setDescriptor] = useState<string>('');
  const [descriptorCity, setDescriptorCity] = useState<string>('');
  const [isShippingAddrVis, setIsShippingAddrVis] = useState<boolean>(false);
  const [isStatementDescVis, setIsStatementDescVis] = useState<boolean>(false);
  const [isMetaDataVis, setIsMetaDataVis] = useState<boolean>(false);

  const [penceError, setPenceError] = useState<string>('');
  const [currencyError, setCurrencyError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');

  useEffect(() => {
    const permissions = new Set(
      profileState.data?.user.permissions.map((p) => p.permission)
    );
    if (!isSignedIn) {
      return handleNotSignedIn(history);
    }
    if (profile.group && profile.group !== 'STANDARD') {
      history.push('/');
      return;
    } else if (
      !subscriptionState?.data?.settings.enabled ||
      !permissions.has('SubscriptionsModify')
    ) {
      history.push('/subscriptions');
      return;
    }
  }, [isSignedIn, profile]);

  useEffect(() => {
    isCreated && history.push('/subscriptions');
  }, [isCreated]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(error);
  }, [error]);

  useEffect(() => {
    setCurrencyError('');
  }, [currency]);

  useEffect(() => {
    const enabledCurrencies =
      subscriptionState?.data?.settings.enabledCurrencies;
    const selectedCurrency = currency;

    const curObj = enabledCurrencies?.find(
      (currency) => currency.code === selectedCurrency
    );

    setCurrencyObj(curObj);
  }, [currency]);

  useEffect(() => {
    const enabledCurrencies =
      subscriptionState?.data?.settings.enabledCurrencies.map(
        (currency: Currency) => ({
          name: currency.name,
          value: currency.code,
        })
      ) ?? [];
    setCurrencyOptions(enabledCurrencies);
    enabledCurrencies.length === 1 && setCurrency(enabledCurrencies[0].value);
  }, []);

  const maxStartDate = (): Date => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
  };

  const handleCreateSubscription = async (): Promise<void> => {
    let valid = true;
    const minPence = 30;
    const maxPence = 100000;
    if (currency && currencyObj && (pence < minPence || pence > maxPence)) {
      const minPrice = formatPrice({ pence: minPence, currency: currencyObj });
      const maxPrice = formatPrice({ pence: maxPence, currency: currencyObj });
      setPenceError(`Amount must be between ${minPrice} and ${maxPrice}`);
      valid = false;
    }
    if (!currency) {
      setCurrencyError('Currency must be selected');
      valid = false;
    }
    if (description.length < 5) {
      setDescriptionError('Must contain 5 or more characters');
      valid = false;
    }
    if (!valid || !customer) {
      return;
    }

    const subscription: CreateSubscriptionRequest = {
      customer: {
        id: customer?.id,
      },
      price: {
        amount: pence,
        currency,
        interval: {
          unit,
          count: parseInt(count),
          times: parseInt(times),
        },
      },
      paymentMethod:
        paymentMethod === 'card' ? { id: paymentMethodId } : undefined,
      description: description || undefined,
      billingCycleTimestamp: billingCycleTimestamp || undefined,
      metadata:
        metaData && metaData.length
          ? Object.fromEntries(metaData.map((item) => [item.key, item.value]))
          : undefined,
      shippingDetails: isShippingAddrVis
        ? {
            address: {
              firstName: firstName || undefined,
              lastName: lastName || undefined,
              lineOne: lineOne || undefined,
              lineTwo: lineTwo || undefined,
              city: city || undefined,
              country,
              postalCode,
              region: region || undefined,
            },
          }
        : undefined,
      paymentSettings:
        isStatementDescVis && descriptor && descriptorCity
          ? {
              statementDescriptor: {
                descriptor,
                city: descriptorCity,
              },
            }
          : undefined,
    };

    await createSubscription(subscription);
  };

  return (
    <LayoutIllustration
      title={
        <>
          Create a <span>Ryft</span> subscription
        </>
      }
      coBrandedTitle={<>Create a Subscription</>}
    >
      <div style={{ padding: '0 12px' }}>
        <EmailToCustomer
          title='Customers email'
          customer={customer}
          setCustomer={setCustomer}
        />
        {customer && paymentMethods && (
          <>
            {paymentMethods && paymentMethods?.length > 0 && (
              <SelectInput
                label
                name='Payment method'
                value={paymentMethod}
                setValue={setPaymentMethod}
                options={[
                  { value: 'card', name: 'Select a saved card' },
                  {
                    value: 'customer',
                    name: 'Customer will enter payment details',
                  },
                ]}
              />
            )}
            {paymentMethod === 'card' && (
              <SelectCard
                paymentMethods={paymentMethods}
                selected={paymentMethodId}
                setSelected={setPaymentMethodId}
              />
            )}

            <TextInput
              name='Description'
              value={description}
              setValue={setDescription}
              label
              description={
                'The description of a subscription can serve as its unique identifier'
              }
            />
            {descriptionError && (
              <div
                style={{ color: '#ec3f3f', position: 'relative', top: '-6px' }}
              >
                {descriptionError}
              </div>
            )}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 120px',
                gridGap: '12px',
              }}
            >
              <SelectInput
                label
                name='Currency'
                value={currency}
                setValue={setCurrency}
                options={currencyOptions}
                selectPrompt='Select...'
              />
              {currency && (
                <CurrencyInput
                  currency={currencyObj}
                  pence={pence}
                  setPence={setPence}
                />
              )}
            </div>

            {penceError && currency && (
              <div
                style={{ color: '#ec3f3f', position: 'relative', top: '-6px' }}
              >
                {penceError}
              </div>
            )}
            {currencyError && (
              <div
                style={{ color: '#ec3f3f', position: 'relative', top: '-6px' }}
              >
                {currencyError}
              </div>
            )}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridGap: '12px',
              }}
            >
              <SelectInput
                label
                name='Interval'
                value={unit}
                setValue={setUnit}
                options={[
                  { value: 'Days', name: 'Daily' },
                  { value: 'Months', name: 'Monthly' },
                ]}
                selectPrompt='Interval...'
              />
              <TextInput
                name='Count'
                value={count}
                setValue={setCount}
                label
                placeHolder='0'
              />
              <TextInput
                name='Times'
                value={times}
                setValue={setTimes}
                label
                optional
              />
            </div>
            <small
              style={{
                fontSize: '11px',
                marginBottom: '24px',
                display: 'block',
                color: '#909090',
              }}
            >
              Billed every {+count > 1 ? count : ''}{' '}
              {unit === 'Months' ? 'month' : 'day'}
              {+count > 1 ? 's' : ''}{' '}
              {times === ''
                ? 'until canceled'
                : 'for a total of ' + times + ' payments'}
            </small>
            <p>Start date</p>
            <DatePicker
              type='START'
              name='Start Date'
              value={billingCycleTimestamp}
              setValue={setBillingCycleTimestamp}
              notBefore={new Date()}
              notAfter={maxStartDate()}
            />
            <div>
              <p style={{ margin: '20px 0' }}>Additional options</p>
              <CheckBox
                setValue={setIsShippingAddrVis}
                value={isShippingAddrVis}
                id='1'
                label='Shipping details'
              />
              <CheckBox
                setValue={setIsStatementDescVis}
                value={isStatementDescVis}
                id='2'
                label='Statement descriptor'
              />
              <CheckBox
                setValue={setIsMetaDataVis}
                value={isMetaDataVis}
                id='3'
                label='Metadata'
              />
            </div>
            {isMetaDataVis && (
              <MetaData
                metaData={metaData}
                setMetaData={setMetaData}
                optional
              />
            )}
            {isStatementDescVis && (
              <>
                <div>
                  <TextInput
                    name='Statement Descriptor'
                    value={descriptor}
                    setValue={setDescriptor}
                    label
                    optional
                  />
                  <TextInput
                    name='City'
                    value={descriptorCity}
                    setValue={setDescriptorCity}
                    label
                  />
                </div>
                <div>
                  Statement descriptor and city are shown on the customer's bank
                  statement
                </div>
              </>
            )}
            {isShippingAddrVis && (
              <div>
                <SelectInput
                  label
                  name='Country'
                  value={country}
                  setValue={setCountry}
                  options={countries()}
                />

                <TextInput
                  name='Postal Code'
                  value={postalCode}
                  setValue={setPostalCode}
                  label
                />

                <TextInput
                  name='First Name'
                  value={firstName}
                  setValue={setFirstName}
                  label
                />
                <TextInput
                  name='Last Name'
                  value={lastName}
                  setValue={setLastName}
                  label
                />

                <TextInput
                  name='Address Line 1'
                  value={lineOne}
                  setValue={setLineOne}
                  label
                />

                <TextInput
                  name='Address Line 2'
                  value={lineTwo}
                  setValue={setLineTwo}
                  label
                />

                <TextInput
                  name='Town / City'
                  value={city}
                  setValue={setCity}
                  label
                />

                {country !== 'US' && country !== 'CA' && (
                  <TextInput
                    name='Region'
                    value={region}
                    setValue={setRegion}
                    label
                  />
                )}
                {country === 'US' && (
                  <SelectInput
                    label
                    name='US State'
                    value={region}
                    setValue={setRegion}
                    options={usStates()}
                  />
                )}
                {country === 'CA' && (
                  <SelectInput
                    label
                    name='CA Province'
                    value={region}
                    setValue={setRegion}
                    options={caStates()}
                  />
                )}
              </div>
            )}
            {error && (
              <div style={{ color: '#ec3f3f', position: 'relative' }}>
                {error}
              </div>
            )}
            <Button
              name='Create Subscription'
              click={handleCreateSubscription}
              loading={isLoading}
            />
          </>
        )}
        <Button
          name='Cancel'
          color='GREY'
          click={(): void => history.push('/subscriptions')}
        />
      </div>
    </LayoutIllustration>
  );
};

export { CreateSubscriptionRoute };
