import { useState, useEffect } from 'react';
import { useApiRequest, HttpMethod } from './useApiRequest';
import { PaymentsShape } from '../interfaces/paymentState';

interface UseGetPaymentSessionByIdReturnType {
  paymentSession?: PaymentsShape;
  isLoading: boolean;
  error?: string;
  fetchPaymentSession: (id: string, subAccountId?: string) => Promise<void>;
}

function useGetPaymentSessionById(): UseGetPaymentSessionByIdReturnType {
  const [paymentSession, setPaymentSession] = useState<
    PaymentsShape | undefined
  >(undefined);
  const [apiResponse, isLoading, error, sendRequest] = useApiRequest();

  const fetchPaymentSession = async (
    id: string,
    subAccountId?: string
  ): Promise<void> => {
    try {
      let url = `/v1/transactions?paymentSessionId=${id}`;
      if (subAccountId) {
        url += `&accountId=${subAccountId}`;
      }
      await sendRequest({
        url,
        method: HttpMethod.GET,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while fetching the payment:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse?.data?.paymentSessions?.items[0]) {
      setPaymentSession(apiResponse.data.paymentSessions.items[0]);
    }
  }, [apiResponse]);

  return { paymentSession, isLoading, error, fetchPaymentSession };
}

export { useGetPaymentSessionById };
