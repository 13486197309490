import * as Sentry from '@sentry/react';

const startReplay = (): void => {
  const replay = Sentry.getCurrentHub()
    .getClient()
    ?.getIntegration(Sentry.Replay);
  if (replay?.getReplayId()) {
    return;
  }
  replay?.start();
};

const stopReplayIfActive = (): void => {
  const replay = Sentry.getCurrentHub()
    .getClient()
    ?.getIntegration(Sentry.Replay);
  if (replay?.getReplayId()) {
    replay.stop();
  }
};

export { startReplay, stopReplayIfActive };
