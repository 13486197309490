import { ReactElement, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Calendar } from 'react-date-range';
import { DateTime } from './DateTime';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export interface PropsShape {
  value: Date | undefined;
  setValue: ((d: Date) => void) | undefined;
}

const DateFilter = ({ value, setValue }: PropsShape): ReactElement => {
  const filterTransition = useRef(null);
  const [isFilterMenuVisible, setIsFilterMenuVisible] =
    useState<boolean>(false);

  const toggleFilterMenu = (): void => {
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const newValue = (e: Date): void => {
    toggleFilterMenu();
    // Update local time so it matches UTC
    e.setTime(e.getTime() - e.getTimezoneOffset() * 60 * 1000);
    if (setValue) setValue(e);
  };

  return (
    <div className='DateFilter'>
      <button onClick={toggleFilterMenu} className='DateFilter--button'>
        {value && (
          <div>
            <DateTime value={value} />
          </div>
        )}
      </button>
      <CSSTransition
        nodeRef={filterTransition}
        in={isFilterMenuVisible}
        timeout={300}
        classNames='DateFilter--MenuTransition'
        unmountOnExit
      >
        <div className='DateFilter--dropdown' ref={filterTransition}>
          <Calendar date={value} onChange={newValue} maxDate={new Date()} />
        </div>
      </CSSTransition>
    </div>
  );
};
export { DateFilter };
