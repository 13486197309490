import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface MenuItemShape {
  key: string;
  name: string;
  content: ReactElement;
}

export interface PropsShape {
  title: string;
  path: string;
  items: MenuItemShape[];
  itemKey?: string;
}

const MenuLayout = ({
  title,
  path,
  items,
  itemKey,
}: PropsShape): ReactElement => {
  const history = useHistory();
  const defaultItemKey = items[0]?.key;
  const [selectedItemKey, setSelectedItemKey] =
    useState<string>(defaultItemKey);

  useEffect(() => {
    setSelectedItemKey(
      itemKey && items.find((item: MenuItemShape) => item.key === itemKey)
        ? itemKey
        : defaultItemKey
    );
  }, [items, itemKey]);

  return (
    <div className='MenuLayout'>
      <div className='MenuLayout--title'>{title}</div>
      <div className='MenuLayout--body'>
        <div className='MenuLayout--menu-container'>
          <ul className='MenuLayout--menu'>
            {items.map((item: MenuItemShape, index: number) => {
              return (
                <li
                  onClick={(): void => {
                    if (item.key !== selectedItemKey) {
                      setSelectedItemKey(item.key);
                      history.push(`${path}/${item.key}`);
                    }
                  }}
                  value={item.key}
                  key={index}
                  className={
                    item.key === selectedItemKey
                      ? 'MenuLayout--menu selected-item'
                      : undefined
                  }
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className='MenuLayout--menu-selected-item-content'>
          {
            items.find((item: MenuItemShape) => item.key == selectedItemKey)
              ?.content
          }
        </div>
      </div>
    </div>
  );
};
export { MenuLayout };
