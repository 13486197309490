import React, { ReactElement } from 'react';

export interface PropsShape {
  children: ReactElement;
  tabs: string[];
  selected: string;
  click?: (e: React.MouseEvent) => void;
  topRight?: ReactElement;
  bottomLeft?: ReactElement;
  bottomRight?: ReactElement;
}

const Tabs = ({
  children,
  tabs,
  selected,
  click,
  topRight,
  bottomLeft,
  bottomRight,
}: PropsShape): ReactElement => {
  return (
    <div className='Tabs'>
      <div className='Tabs--title'>
        <ul>
          {tabs.map((tab, index) => (
            <li
              id={tab}
              key={index}
              onClick={click}
              className={
                selected === tab ? 'Tabs--selected' : 'Tabs--not-selected'
              }
            >
              {tab}
            </li>
          ))}
        </ul>
        <div className='Tabs--title-fade' />
        {topRight}
      </div>
      <div className='Tabs--body'> {children}</div>
      <div className='Tabs--footer'>
        <div>{bottomLeft}</div>
        <div>{bottomRight}</div>
      </div>
    </div>
  );
};
export { Tabs };
