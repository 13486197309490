import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';

export interface RefundPaymentRequest {
  amount: number;
  reason?: string;
}

function useRefundPayment(): any {
  const [success, setSuccess] = useState<boolean>(false);
  const [response, isLoading, error, sendRequest] = useApiRequest<any>();

  useEffect(() => {
    if (response && response.data) {
      setSuccess(true);
    }
  }, [response]);

  const refundPayment = (
    body: RefundPaymentRequest,
    paymentId: string
  ): void => {
    const url = `/v1/payment-sessions/${paymentId}/refunds`;
    const method = HttpMethod.POST;
    sendRequest({ url, method, body });
  };

  return [refundPayment, isLoading, success, error];
}

export { useRefundPayment };
