import { useEffect, useState } from 'react';
import { HttpMethod, useApiRequest } from './useApiRequest';
import {
  BankAccountNumberType,
  BankIdType,
  PayoutMethod,
} from '../interfaces/payoutMethodsState';
import { AccountAddressRequest } from './useEditAccount';

interface UseEditPayoutMethodReturnType {
  payoutMethod: PayoutMethod | null;
  isLoading: boolean;
  error: any;
  editPayoutMethod: (
    id: string,
    body: EditPayoutMethodRequest
  ) => Promise<void>;
}

export interface EditPayoutMethodRequest {
  displayName?: string;
  bankAccount: EditBankAccountRequest;
}

interface EditBankAccountRequest {
  bankIdType: BankIdType;
  bankId: string;
  accountNumberType: BankAccountNumberType;
  accountNumber: string;
  address?: AccountAddressRequest;
}

function useEditPayoutMethod(): UseEditPayoutMethodReturnType {
  const [apiResponse, isLoading, error, sendRequest] =
    useApiRequest<PayoutMethod>();

  const [payoutMethodData, setPayoutMethodData] = useState<PayoutMethod | null>(
    null
  );

  const apiEditPayoutMethod = async (
    id: string,
    body: EditPayoutMethodRequest
  ): Promise<void> => {
    try {
      await sendRequest({
        url: `/v1/account/payout-methods/${id}`,
        method: HttpMethod.PATCH,
        body: body,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while updating the payout method:', e);
    }
  };

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      setPayoutMethodData(apiResponse.data);
    }
  }, [apiResponse]);

  return {
    payoutMethod: payoutMethodData,
    isLoading,
    error,
    editPayoutMethod: apiEditPayoutMethod,
  };
}

export { useEditPayoutMethod };
