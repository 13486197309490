import { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NavUser } from './User';
import { NavMain } from './Main';
import { useGlobalState } from '../../hooks/useGlobalState';
import { useGlobalDataState } from '../../hooks/useGlobalDataState';
import { UserPermission } from '../../interfaces/profileState';

export interface PropsShape {
  openUserMenu: boolean;
}

export interface PulseItem {
  created: number;
  title: string;
  body: string;
  link?: string;
  avatar: 'ryft';
  seen: boolean;
}

const NavSignedIn = (): ReactElement => {
  const {
    profileState,
    accountState,
    payoutMethodsState,
    paymentsState,
    splitPaymentsState,
    feesState,
  } = useGlobalDataState();
  const history = useHistory();
  const navLocation = history.location.pathname.replace(/\//g, '');
  const { profile, cognito } = useGlobalState();
  const [showCustomers, setShowCustomers] = useState(false);
  const [showDisputes, setShowDisputes] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [showPayouts, setShowPayouts] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [showDeveloper, setShowDeveloper] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [pulseData, setPulseData] = useState<PulseItem[]>([]);

  useEffect(() => {
    const userPermissions = profileState?.data?.user?.permissions;
    const permissionToShowSetterMap: {
      [key in UserPermission]?: (value: boolean) => void;
    } = {
      DisputesView: setShowDisputes,
      CustomersView: setShowCustomers,
      TransactionsView: setShowTransactions,
      SubscriptionsView: setShowSubscriptions,
      PayoutsView: setShowPayouts,
      SubAccountsView: setShowAccounts,
      DeveloperSettingsView: setShowDeveloper,
      ReportsView: setShowFiles,
    };

    userPermissions?.forEach((permissionDetail) => {
      const setter = permissionToShowSetterMap[permissionDetail.permission];
      if (setter) {
        setter(true);
      }
    });
  }, [profileState]);

  const getUserOptions = (): { name: string; value: string }[] => {
    const arr = [];
    arr.push({ name: 'Profile', value: 'PROFILE' });
    if (showFiles) {
      arr.push({ name: 'My Files', value: 'FILES' });
    }
    arr.push({ name: 'Settings', value: 'SETTINGS' });
    if (profile.group === 'STANDARD' && showDeveloper) {
      arr.push({ name: 'Developer', value: 'DEVELOPER' });
    }
    arr.push({ name: 'Contact', value: 'CONTACT' });

    arr.push({ name: 'Log Out', value: 'LOGOUT' });
    return arr;
  };

  const setPulse = (): void => {
    const pulseShown = localStorage.getItem('pulseShown') || 'false';
    const amexCapabilityViewed =
      localStorage.getItem('amexCapabilityViewed') || 'false';
    const pulse: PulseItem[] = [];

    pulse.push({
      created: profileState.data?.user.registeredTimestamp || 0,
      title: 'Welcome to Ryft',
      body: 'A warm welcome from the Ryft team!',
      avatar: 'ryft',
      seen: pulseShown === 'true',
    });
    if (accountState.data?.verification.status === 'Required') {
      pulse.push({
        created: profileState.data?.user.registeredTimestamp || 0,
        title: 'Verify your account',
        body: "It's now time to verify your account so we can get you paid!",
        link: '/settings/account',
        avatar: 'ryft',
        seen: pulseShown === 'true',
      });
    }
    if (profileState?.data?.user.role === 'Owner') {
      if (
        !paymentsState.data?.items?.length &&
        !feesState.data?.items?.length &&
        !splitPaymentsState.data?.items?.length
      ) {
        pulse.push({
          created: profileState.data?.user.registeredTimestamp || 0,
          title: 'Make some transactions',
          body: "Let's go! Make some transactions so you can verify your account.",
          avatar: 'ryft',
          seen: pulseShown === 'true',
        });
      }
      if (!payoutMethodsState.data?.items?.length) {
        pulse.push({
          created: profileState.data?.user.registeredTimestamp || 0,
          title: 'Add bank details',
          body: 'We need to know where we are sending your money! Add your bank details.',
          link: '/settings/banking',
          avatar: 'ryft',
          seen: pulseShown === 'true',
        });
      }
      if (
        profileState?.data?.account.settings.newCapabilities &&
        profileState.data.account.settings.newCapabilities.find(
          (i) => i === 'AmexPayments'
        )
      ) {
        pulse.push({
          created: 1716800400,
          title: 'Request American Express',
          body: 'Enable card payments from Amex cardholders',
          link: '/settings/capabilities',
          avatar: 'ryft',
          seen: amexCapabilityViewed === 'true',
        });
      }
    }

    setPulseData(pulse);
  };

  const getMainOptions = (): { name: string; value: string }[] => {
    const arr = [{ name: 'Dashboard', value: 'DASHBOARD' }];

    if (profile.group === 'STANDARD' && showCustomers) {
      arr.push({ name: 'Customers', value: 'CUSTOMERS' });
    }
    if (showTransactions) {
      arr.push({ name: 'Payments', value: 'PAYMENTS' });
    }

    if (
      profile.group === 'STANDARD' &&
      showDisputes &&
      profile.processingModel === 'PayFac'
    ) {
      arr.push({ name: 'Disputes', value: 'DISPUTES' });
    }

    if (
      showTransactions &&
      profileState?.data?.account.settings.subAccountsDisplayed
    ) {
      arr.push({ name: 'Platform Fees', value: 'PLATFORM-FEES' });
    }
    if (showTransactions && profile.group === 'SUB') {
      arr.push({ name: 'Split Payments', value: 'SPLIT-PAYMENTS' });
    }
    if (
      showSubscriptions &&
      profileState?.data?.account.settings.subscriptionsDisplayed
    ) {
      arr.push({ name: 'Subscriptions', value: 'SUBSCRIPTIONS' });
    }
    if (showPayouts && profileState?.data?.account.settings.payoutsDisplayed) {
      arr.push({ name: 'Payouts', value: 'PAYOUTS' });
    }
    if (
      showAccounts &&
      profileState?.data?.account.settings.subAccountsDisplayed
    ) {
      arr.push({ name: 'Accounts', value: 'ACCOUNTS' });
    }
    return arr;
  };

  return (
    <>
      <div className='SignedIn'>
        <div className='SignedIn--center'>
          {/* Mobile nav */}
          <div className='SignedIn--hamburger'>
            <NavMain
              value={history.location.pathname.substring(1).toUpperCase()}
              setValue={(value: string): void => {
                history.push(`/${value.toLowerCase()}`);
              }}
              options={getMainOptions()}
            />
          </div>
          <h1
            className='SignedIn--name'
            onClick={(): void => history.push('/payouts')}
          >
            {process.env.REACT_APP_NAME?.replace('-', ' ')}
          </h1>
          <div className='SignedIn--user-nav'>
            <NavUser
              name={profileState.data?.user.firstName}
              value={history.location.pathname.substring(1).toUpperCase()}
              setValue={(value: string): void => {
                if (value === 'LOGOUT') {
                  cognito.signOut();
                  history.push(`/`);
                } else {
                  history.push(`/${value.toLowerCase()}`);
                }
              }}
              options={getUserOptions()}
              pulse={pulseData}
              isPulseOpen={setPulse}
            />
          </div>
        </div>
      </div>

      <div
        className={
          history.location.pathname.split('/')[1] === 'about'
            ? 'SignedIn--main-nav SignedIn--main-nav-about'
            : 'SignedIn--main-nav'
        }
      >
        <div className='SignedIn--main-nav-center'>
          <ul>
            <li
              onClick={(): void => history.push('/dashboard')}
              className={
                navLocation === 'dashboard' ? 'SignedIn--selected' : ''
              }
            >
              Dashboard
            </li>
            {profile.group === 'STANDARD' && showCustomers && (
              <li
                onClick={(): void => history.push('/customers')}
                className={
                  navLocation === 'customers' ? 'SignedIn--selected' : ''
                }
              >
                Customers
              </li>
            )}
            {showTransactions && (
              <li
                onClick={(): void => history.push('/payments')}
                className={
                  navLocation === 'payments' ? 'SignedIn--selected' : ''
                }
              >
                Payments
              </li>
            )}
            {profile.group === 'STANDARD' &&
              showDisputes &&
              profile.processingModel === 'PayFac' && (
                <li
                  onClick={(): void => history.push('/disputes')}
                  className={
                    navLocation === 'disputes' ? 'SignedIn--selected' : ''
                  }
                >
                  Disputes
                </li>
              )}

            {showTransactions &&
              profileState?.data?.account.settings.subAccountsDisplayed && (
                <li
                  onClick={(): void => history.push('/platform-fees')}
                  className={
                    navLocation === 'platform-fees' ? 'SignedIn--selected' : ''
                  }
                >
                  Platform Fees
                </li>
              )}
            {showTransactions && profile.group === 'SUB' && (
              <li
                onClick={(): void => history.push('/split-payments')}
                className={
                  navLocation === 'split-payments' ? 'SignedIn--selected' : ''
                }
              >
                Split Payments
              </li>
            )}
            {showSubscriptions &&
              profileState?.data?.account.settings.subscriptionsDisplayed && (
                <li
                  onClick={(): void => history.push('/subscriptions')}
                  className={
                    navLocation === 'subscriptions' ? 'SignedIn--selected' : ''
                  }
                >
                  Subscriptions
                </li>
              )}
            {showPayouts &&
              profileState?.data?.account.settings.payoutsDisplayed && (
                <li
                  onClick={(): void => history.push('/payouts')}
                  className={
                    navLocation === 'payouts' ? 'SignedIn--selected' : ''
                  }
                >
                  Payouts
                </li>
              )}
            {showAccounts &&
              profileState?.data?.account.settings.subAccountsDisplayed && (
                <li
                  onClick={(): void => history.push('/accounts')}
                  className={
                    navLocation === 'accounts' ? 'SignedIn--selected' : ''
                  }
                >
                  Accounts
                </li>
              )}
          </ul>
        </div>
      </div>
    </>
  );
};
export { NavSignedIn };
