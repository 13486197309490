import React, { ReactElement, useState, useEffect } from 'react';

import { DatePicker } from '../forms/DatePicker';
import { SelectInput } from '../forms/SelectInput';
import { Button } from '../forms/Button';
import { epochSecondsToDate } from '../../functions/helpers';
import { TextInput } from '../forms/TextInput';
import { DisputeFilterShape } from '../../interfaces/disputeState';
import { useHistory } from 'react-router-dom';

interface FilterDisputesProps {
  getDisputes: (filter?: DisputeFilterShape) => void;
}

const FilterDisputes = ({ getDisputes }: FilterDisputesProps): ReactElement => {
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [startTimestamp, setStartTimestamp] = useState<string>('');
  const [endTimestamp, setEndTimestamp] = useState<string>('');
  const [startsAfter, setStartsAfter] = useState<string>('');
  const [disputeId, setDisputeId] = useState<string>(
    searchParams.get('id') || ''
  );
  const [paymentSessionId, setPaymentSessionId] = useState<string>('');
  const [ascending, setAscending] = useState<boolean>();
  const [status, setStatus] = useState<string>('');
  const [cleared, setCleared] = useState<boolean>(false);

  const handleApplyFilter = (): void => {
    const filter: DisputeFilterShape = {
      ascending,
      endTimestamp,
      paymentSessionId,
      startsAfter,
      startTimestamp,
      disputeId,
      status,
    };
    getDisputes(filter);
  };

  useEffect(() => {
    // call API on change but not on page load
    if (
      ascending !== undefined ||
      startsAfter ||
      startTimestamp ||
      endTimestamp ||
      paymentSessionId ||
      disputeId ||
      status ||
      cleared
    ) {
      setCleared(false);
      handleApplyFilter();
    }
  }, [
    ascending,
    startsAfter,
    startTimestamp,
    endTimestamp,
    paymentSessionId,
    disputeId,
    status,
    cleared,
  ]);

  const handleClearFilter = (): void => {
    setStatus('');
    setAscending(undefined);
    setPaymentSessionId('');
    setStartsAfter('');
    setDisputeId('');
    setStartTimestamp('');
    setEndTimestamp('');
    history.push('/disputes');
    setCleared(true);
  };

  return (
    <div>
      <h2
        style={{
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '0 0 24px 0',
          fontWeight: 600,
        }}
      >
        Filter by
      </h2>
      <SelectInput
        label
        name='Status'
        value={status}
        setValue={setStatus}
        options={[
          { value: '', name: 'Any' },
          { value: 'Open', name: 'Open' },
          { value: 'Cancelled', name: 'Cancelled' },
          { value: 'Accepted', name: 'Accepted' },
          { value: 'Challenged', name: 'Challenged' },
          { value: 'Lost', name: 'Lost' },
          { value: 'Won', name: 'Won' },
          { value: 'Expired', name: 'Expired' },
        ]}
      />

      <TextInput
        name='Payment Session Id'
        label
        value={paymentSessionId}
        setValue={setPaymentSessionId}
        placeHolder='ps_'
      />

      <TextInput
        name='Dispute Id'
        label
        value={disputeId}
        setValue={setDisputeId}
        placeHolder='dsp_'
      />

      <DatePicker
        type='START'
        name='Start Date'
        value={startTimestamp ? parseInt(startTimestamp) : undefined}
        notAfter={
          endTimestamp ? epochSecondsToDate(parseInt(endTimestamp)) : new Date()
        }
        label
        setValue={(e): void => {
          if (e) {
            setStartTimestamp(e.toString());
          }
        }}
      />

      <DatePicker
        label
        type='END'
        name='End Date'
        value={endTimestamp ? parseInt(endTimestamp) : undefined}
        notBefore={
          startTimestamp
            ? epochSecondsToDate(parseInt(startTimestamp))
            : undefined
        }
        notAfter={new Date()}
        setValue={(e): void => {
          if (e) {
            setEndTimestamp(e.toString());
          }
        }}
      />
      <h2
        style={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          margin: '24px 0',
        }}
      >
        Sort order
      </h2>
      <SelectInput
        name='Sort Order'
        value={ascending ? 'true' : 'false'}
        setValue={(e): void => setAscending(e === 'true')}
        options={[
          { value: 'true', name: 'Ascending' },
          { value: 'false', name: 'Descending' },
        ]}
      />

      <Button name='Clear' color='GREY' click={handleClearFilter} />
    </div>
  );
};

export { FilterDisputes };
